import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  place-self: ${props => props.placeself};
  
  @media (min-width: 992px) {
    width: ${props => props.width};
  }

  @media (max-width: 992px) {
    gap: 10px;

    ${props => props.mobile && `
      flex-direction: ${props.mobile};
      place-self: center;
    `}
  }

  @media (min-width: 1920px) {
    ${props => props.largeGap && `
      gap: ${props.largeGap};
    `}
  }
`

export default function FlexInRow( props ) {
  return (
    <Wrapper 
      className={props.className}
      gap={props.gap}
      largeGap={props.largeGap}
      placeself={props.placeself}
      width={props.width}
      mobile={props.mobile}
    >
      {props.children}
    </Wrapper>
  )
}