import styled from 'styled-components'

const Wrapper = styled.h2`
  text-align: left;
  font: normal normal bold 22px/20px Roboto;
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #494D7D;
`

export default function Title ( props ) {
  return(
    <Wrapper id={props.id} className={props.className}>
      {props.children}
    </Wrapper>
  )
}