import React, { useState, useContext, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Card from "../Card";
import Title from "../Title";
import {
  MinusCircleOutlined,
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
  UserOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { HolderOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ReactSortable } from "react-sortablejs";
import {
  Button,
  Divider,
  Space,
  Switch,
  Upload,
  Input,
  Radio,
  Table,
  Select,
  Drawer,
  Col,
  Modal,
  Row,
  DatePicker,
  notification,
  Form,
  InputNumber,
  TreeSelect,
} from "antd";
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 4,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 20,
    },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 20,
      offset: 4,
    },
  },
};
import CardList from "../CardList";
import { ReactComponent as IconOk } from "../../../Assets/Images/Backoffice/Ok.svg";
import { ReactComponent as IconCalendar } from "../../../Assets/Images/Backoffice/Calendar.svg";
import { ReactComponent as IconClose } from "../../../Assets/Images/Backoffice/Close.svg";
import { ReactComponent as IconEdit } from "../../../Assets/Images/Backoffice/Edit.svg";
import { ReactComponent as IconHours } from "../../../Assets/Images/Backoffice/Hours.svg";
import { ReactComponent as IconUpload } from "../../../Assets/Images/Backoffice/Upload.svg";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);
import {
  CloseCircleOutlined,
  SearchOutlined,
  PlusOutlined,
  LinkOutlined,
  UploadOutlined,
  StarOutlined,
} from "@ant-design/icons";
import axios, { all } from "axios";
import DrawerVouchers from "../../Backoffice/DrawerVouchers";
import DrawerEventPayments from "../DrawerEventPayments";
import ImageResize from "quill-image-resize-module-react";
Quill.register("modules/imageResize", ImageResize);

const { RangePicker } = DatePicker;
const { Dragger } = Upload;
const { TextArea } = Input;
const { Search } = Input;

// import { useDrag } from 'react-dnd'
// import { ItemTypes } from './Constants'

const WrapperCardList = styled.section`
  // display: flex;
  width: 100% !important;
  flex-direction: row;

  .event__content p {
    margin-bottom: 6px !important;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 38px !important;
  }

  .tag-evento-pago {
    background: rgb(248, 132, 54);
    color: #fff;
    font-size: 11px;
    padding: 2px 10px;
    border-radius: 10px;
    margin-left: 10px;
  }

  p {
    color: #c2c4d4;
    font-size: 14px !important;
    letter-spacing: 0px;
    line-height: 20px;
    margin-bottom: 0% !important;
    text-align: left;
  }

  .text {
    color: #809fb8;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0px;
  }

  .text-small {
    color: #809fb8;
    text-align: left;
    font-size: 12px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0px;
  }

  .css-dev-only-do-not-override-j0nf2s .text {
    margin-bottom: 0 !important;
  }

  .icon-close {
    cursor: pointer;
  }

  .action-edit {
    cursor: pointer;
    color: #494d7d;
  }

  .flex-wrap {
    flex-wrap: wrap !important;
  }

  #input-search {
    .ant-input-suffix {
      background: none;
    }
  }
`;

const WrapperCardEvento = styled.section`
  display: flex;
  flex-direction: column;


  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 38px !important;
  }


  .voucher-btn {
    border: 1px solid #494D7D !important;
    color: #494D7D !important;
    height: 45px;
    background: transparent !important;
    padding: 0 50px;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 20px;
  }

  .icon {
    width: 25px;
  }

  .size-eye svg {
    width: 25px;
  }

  .ant-upload-btn {
  min-height: 190px;
}

  .ant-radio-button-wrapper {
    height: 45px;
    border: 1px solid rgb(128, 159, 184);
  }

  .ant-radio-button-wrapper span {
    line-height: 45px; 
    font-size: 14px;s
  }
  .text {
    margin-bottom: 0% !important;
  }

  h4 {
    text-align: center;
    font: normal normal bold 20px/20px Roboto;
    letter-spacing: 0px;
    color: #050505;
  }
  

  .text {
    color: #809FB8;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0px;
  }

  .center {
    text-align: center;
  }

  .ant-upload-wrapper .ant-upload-drag {
    max-height: -webkit-fill-available;
    height: 91%;
    display: flex;
    justify-content: center;
  }

  .ant-upload-wrapper .ant-upload-drag .ant-upload-btn  {
    height: -webkit-fill-available;
  }

  .div-sortable {
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
  }

  .div-sortable .ant-col {
    padding: 5px;
  }

  .div-is-pago {
    border: 1px solid rgb(248, 132, 54);
  }

  .div-evento{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 18px 18px 10px 18px !important; 
  }

  .btn-div-evento {
    cursor: pointer;
  }

  .btn-div-evento:hover {
    background: #ccc;
    border-radius: 2px;
  }

  .border-divider {
    border-right: 1px solid #ccc;
  }

  .evento-excerpt p, .evento-excerpt h1 {
    font-size: 14px !important;
    color: rgb(194, 196, 212);
    font-family: 'Roboto';
  }

  .cta {
    display: block !important;
    font-size: 15px;
    font-weight: bold;
    height: 45px !important;
    line-height: 18px;

    i { margin: 0 3px; }

    a { text-decoration: none; }
  }

  


  .cta.publish {
    margin-right: 0;

    svg path {
      fill: #FFF;
    }
  }
`;

const ContentFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    display: block !important;
    font-size: 15px;
    font-weight: bold;
    height: 45px;
    line-height: 18px;

    i {
      margin: 0 3px;
    }
  }

  a {
    text-decoration: none;
  }
`;

var styles = {
  h6_label: {
    color: "#809FB8",
    fontFamily: "Roboto",
    fontSize: "15px",
    marginTop: "1%",
  },
  input_class_disabled: {
    marginBottom: "2%",
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
    opacity: 0.6,
    pointerEvents: "none",
  },
  input_class: {
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
    width: "100%",
  },
  select_class: {
    borderRadius: "6px",
    height: "45px",
    color: "#0C1151",
    width: "100%",
  },
  textarea_class: {
    borderRadius: "6px",
    border: "1px solid #809FB8",
    color: "#0C1151",
  },
  switch_label: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    height: "45px",
    borderRadius: "8px",
    padding: "0px 16px",
    color: "#809FB8",
    border: "1px solid #809FB8",
    textAlign: "center",
  },
  upload: {
    width: "100%",
  },
};

const ManageVoucher = styled.div`
  .voucher-btn,
  .ant-btn {
    border: 1px solid #494d7d !important;
    color: #494d7d !important;
    height: 45px !important;
    background: transparent !important;
    width: 100% !important;
    display: flex;
    align-iems: center;
    justify-content: center;
  }

  .ant-form {
    width: 100% !important;
  }

  * {
    color: #494d7d;
  }

  h5 {
    font-size: 20px !important;
    margin-bottom: 0;
  }

  .apply-code-btn {
    border-radius: 12px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .input-voucher,
  .input-voucher .ant-input,
  .input-voucher.ant-input-affix-wrapper,
  .input-voucher input::placeholder {
    background: #494d7d !important;
    border: 1px solid #494d7d !important;
    color: #fff !important;
  }

  ::placeholder {
    color: #fff !important;
    opacity: 1 !important; /* Firefox */
  }

  .apply-code-btn,
  .inputCode {
    border: 1px solid #494d7d !important;
    color: #494d7d;
    height: 35px;
  }

  .qtd-tickets {
    background: "#E8E9F3";
    padding: "15px";
    border-radius: "16px";
  }

  .promo-div {
    margin-top: 10px;
  }

  .upload-file-div {
    margin-top: 40px;
  }

  .text-right {
    text-align: right;
  }

  .anexar-title {
    font-size: 14px;
    color: #494d7d;
    margin-bottom: 0;
  }

  .anexar-subtitle {
    font-size: 10px;
    color: #809fb8;
    margin-bottom: 0;
  }

  .ant-upload-select {
    width: 100%;
  }

  .btn-upload
    .ant-upload-list-item-actions
    .ant-upload-list-item-action.ant-btn {
    border: none !important;
  }

  .view-file-btn a {
    padding-top: 20px !important;
    text-decoration: underline;
  }

  .inputDados .ant-select,
  .inputDados .ant-tree-select,
  .inputDados.ant-select-single {
    height: 45px !important;
  }
  .inputDados:not(.ant-select) {
    border: 1px solid #dfdfe4 !important;
    color: #494d7d;
    height: 45px !important;
    width: 100%;
  }

  .inputDados .ant-select-selector {
    border: 1px solid #dfdfe4 !important;
    color: #494d7d;
    min-height: 45px !important;
    width: 100%;
    height: auto !important;
  }

  .inputDados .ant-select-selection-placeholder {
    line-height: 35px !important;
    height: 45px !important;
  }

  .total {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
  }

  h6 {
    font-size: 16px;
  }

  .subtitle {
    font-size: 17px;
  }

  .view-btn {
    color: #fff;
    background: #494d7d;
    padding: 10px 30px;
    border-radius: 5px;
    text-decoration: none !important;
    margin-top: 20px;
  }

  .view-more {
    text-decoration: underline;
  }

  .delete-icon {
    font-size: 15px;
  }

  .margin-vertical-auto {
    margin: auto 0;
  }

  .next-btn,
  .next-btn span {
    background: #494d7d !important;
    color: #ffffff !important;
  }

  .pre-btn,
  .pre-btn span {
    background: #ffffff !important;
    color: #494d7d !important;
  }

  .ant-input-suffix {
    background: #494d7d !important;
    color: #fff !important;
  }

  .next-btn,
  pre-btn {
    height: auto !important;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .rangePicker {
    height: 45px;
    border: 1px solid #dfdfe4 !important;
    width: 100%;
  }

  .voucher-manage {
    padding: 0 !important;
    background: #fff !important;
  }

  .inputDados .ant-input-number-input-wrap input {
    height: 45px !important;
  }
`;

const CardHeader = styled.div`
  align-items: top;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr auto auto;
`;

export default function CardEventos(props) {
  const [form] = Form.useForm();
  const [eventForm] = Form.useForm();

  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const [fileEventList, setfileEventList] = useState([]);
  const [drawerVouchers, setDrawerVouchers] = useState(false);
  const [dataFilter, setDataFilter] = useState([]);
  const [uploading, setUploading] = useState([]);
  const [showEventDrawer, setShowEventDrawer] = useState(false);
  const [drawerPayments, setDrawerPayments] = useState(false);
  const [eventPaymentId, setEventPaymentId] = useState("");
  const [eventPaymentTitle, setEventPaymentTitle] = useState("");
  // editar evento
  const [fileEventListEdit, setfileEventListEdit] = useState([]);
  const [eventIdEdit, setEventIdEdit] = useState("");
  const [dataEditEvent, setDataEditEvent] = useState([]);
  const [descricaoEventEdit, setDescricaoEventEdit] = useState("");
  const [tituloEventEdit, setTituloEventEdit] = useState("");
  const [tipoEventEdit, setTipoEventEdit] = useState(null);
  const [localizacaoEdit, setLocalizacaoEdit] = useState("");
  const [areaEdit, setAreaEdit] = useState([]);
  const [imagemDestaqueEdit, setImagemDestaqueEdit] = useState("");
  const [removeEventModalOpen, setRemoveEventModalOpen] = useState(false);
  const [idToRemove, setIdToRemove] = useState(false);
  const [titleToRemove, setTitleToRemove] = useState(false);
  const [createEvent, setCreateEvent] = useState(false);
  const [eventoPago, setEventoPago] = useState(false);
  const [openEventoPagoDrawer, setOpenEventoPagoDrawer] = useState(false);
  const [isPromocao, setIsPromocao] = useState(false);
  const [dataInicioPromocao, setDataInicioPromocao] = useState("");
  const [dataFechoPromocao, setDataFechoPromocao] = useState("");
  const [valorPromocao, setValorPromocao] = useState("");
  const [tipoValorPromocao, setTipoValorPromocao] = useState("");
  const [qtdBilhetes, setQtdBilhetes] = useState(0);
  const [fileEventPayment, setFileEventPayment] = useState([]);
  const [defaultTickets, setDefaultTickets] = useState([]);
  const [savedFile, setSavedFile] = useState([]);
  const [isSorting, setIsSorting] = useState(false);

  const dateFormat = "DD/MM/YYYY";
  const rangeFormat = "DD/MM/YYYY HH:mm";
  const [allItems, setAllItems] = useState([]);
  const [allVouchers, setAllVouchers] = useState([]);
  const [allEventPayments, setAllEventPayments] = useState([]);
  const [allPurchases, setAllPurchases] = useState([]);

  const [eventos, setEventos] = useState([]);
  const [bolsasEPremios, setBolsasEPremios] = useState([]);
  const [noticias, setNoticias] = useState([]);
  const [naoDefinidos, setNaoDefinidos] = useState([]);
  const [eventoIsEvent, setEventoIsEvent] = useState(false);
  const [eventoIsNoticia, setEventoIsNoticia] = useState(false);
  const [eventoIsBolsaPremio, setEventoIsBolsaPremio] = useState(false);
  const [editModeDescricao, setEditModeDescricao] = useState(false);
  const [eventTypeFilter, setEventTypeFilter] = useState([]);

  const quillRef = useRef();

  const handleEventos = (allEventos) => {
    let eventosToShow = [];
    allEventos?.map((evento) => {
      if (evento != null) {
        if (evento.data_inicio != null) {
          const [dateInicioValues, timeInicioValues] =
            evento.data_inicio?.split(" ");
          const [dayInicio, monthInicio, yearInicio] =
            dateInicioValues?.split("/");

          evento.date = dateInicioValues;
          evento.time = timeInicioValues;
        }

        eventosToShow.push(evento);
      }
    });

    let noticias = eventosToShow.filter((evento) => {
      if (evento.is_noticia == 1) return evento;
    });
    if (noticias?.length > 0) {
      noticias.sort((a, b) => a.ordem - b.ordem);
    }
    setNoticias(noticias);

    let bolsasEPremios = eventosToShow.filter((evento) => {
      if (evento.is_bolsa_premio == 1) return evento;
    });
    if (bolsasEPremios?.length > 0) {
      bolsasEPremios.sort((a, b) => a.ordem - b.ordem);
    }

    setBolsasEPremios(bolsasEPremios);
    let eventos = eventosToShow.filter((evento) => {
      if (evento.is_event == 1) return evento;
    });
    if (eventos?.length > 0) {
      eventos.sort((a, b) => a.ordem - b.ordem);
    }
    setEventos(eventos);
    let naoDefinidos = eventosToShow.filter((evento) => {
      if (
        evento.is_event == 0 &&
        evento.is_bolsa_premio == 0 &&
        evento.is_noticia == 0
      )
        return evento;
    });
    if (naoDefinidos?.length > 0) {
      naoDefinidos.sort((a, b) => a.ordem - b.ordem);
    }
    setNaoDefinidos(naoDefinidos);
  };

  const getEventos = (eventos) => {
    let eventosToShow = [];

    eventos.map((evento) => {
      if (evento != null) {
        //if(evento.club_id == null) {
        if (evento.data_inicio != null) {
          const [dateInicioValues, timeInicioValues] =
            evento.data_inicio.split(" ");
          const [dayInicio, monthInicio, yearInicio] =
            dateInicioValues.split("/");

          evento.date = dateInicioValues;
          evento.time = timeInicioValues;
        }
        //}

        eventosToShow.push(evento);
      }
    });

    return eventosToShow;
  };

  useEffect(() => {
    console.log("abri");
    if (eventos.length == 0) {
      fetch(`https://api.spap.pt/api/all-eventos`, {
        method: "GET",
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Erro ao buscar eventos");
          }
          return response.json();
        })
        .then((data) => {
          setAllVouchers(data.vouchers);
          setAllEventPayments(data.eventPayments);
          setAllPurchases(data.purchases);
          setAllItems(data.eventos);
          handleEventos(data.eventos);
        })
        .catch((error) => console.error(error));
    }
  }, []);

  const radioGroupRef = useRef(null);

  const [currentItemsEvent, setCurrentItemsEvent] = useState(
    allItems?.slice(0, 2)
  );

  const [itemOffsetEvent, setItemOffsetEvent] = useState(0);
  const itemsPerPageEvent = 2;

  const handlePageEvent = (page) => {
    const newOffset = ((page - 1) * itemsPerPageEvent) % allItems.length;
    setItemOffsetEvent(newOffset);
  };

  useEffect(() => {
    // const endOffset = itemOffsetEvent + itemsPerPkageEvent;
    setCurrentItemsEvent(allItems);
  }, [itemOffsetEvent, allItems]);

  notification.config({
    placement: "bottomRight",
    bottom: 10,
    duration: 5,
    rtl: false,
  });

  const propsFileEventoPago = {
    onRemove: (file) => {
      const index = fileEventPayment.indexOf(file);
      const newFileList = fileEventPayment.slice();
      newFileList.splice(index, 1);
      setFileEventPayment(newFileList);
    },
    beforeUpload: (file) => {
      const isLt10M = file.size / 1024 / 1024 <= 10;
      if (!isLt10M) {
        notification.error({
          description: "Apenas é possível fazer upload de ficheiros até 10MB",
        });

        return false || Upload.LIST_IGNORE;
      }
      setFileEventPayment([file]);
      return false;
    },
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  const clickToEventDrawer = (item) => {
    setCreateEvent(false);
    setEventIdEdit(item.id);
    setDataEditEvent([item.data_inicio, item.data_fecho]);
    setDescricaoEventEdit(item.descricao);
    setTituloEventEdit(item.titulo);
    setTipoEventEdit(item.is_spap);
    setLocalizacaoEdit(item.localizacao);
    setImagemDestaqueEdit(item.imagem_destaque);
    setAreaEdit(item.area_subespecializacao);
    setEventoPago(item.is_pago);
    setEventoIsBolsaPremio(item.is_bolsa_premio);
    setEventoIsNoticia(item.is_noticia);

    setEventoIsEvent(item.is_event);

    eventForm.setFieldsValue({
      titulo: item.titulo,
      localizacao: item.localizacao,
      area: item.area_subespecializacao,
      descricao: item.descricao,
    });
    // eventForm.resetFields();
    if (item.imagem_destaque != "") {
      setfileEventListEdit([
        {
          uid: "-1",
          name: item.imagem_destaque,
          status: "done",
          url:
            "https://api.spap.pt/eventos/" +
            eventIdEdit +
            "/" +
            item.imagem_destaque,
        },
      ]);
    }
    setShowEventDrawer(true);
  };

  useEffect(() => {
    console.log("aqui1");
    // if (showEventDrawer && quillRef.current) {
    //   const quillEditor = quillRef.current.getEditor();
    //   quillEditor.setText("");
    //   quillEditor.clipboard.dangerouslyPasteHTML(-0, descricaoEventEdit);
    // }

    setTipoEventEdit(tipoEventEdit || 0);
    if (radioGroupRef.current && radioGroupRef.current.children) {
      const radioChildren = radioGroupRef.current.children;
      for (let i = 0; i < radioChildren.length; i++) {
        if (i === (tipoEventEdit == 1 ? 0 : 1)) {
          radioChildren[i].classList.add("ant-radio-button-wrapper-checked");
          radioChildren[i].children[0].classList.add(
            "ant-radio-button-checked"
          );
        } else {
          radioChildren[i].classList.remove("ant-radio-button-wrapper-checked");
          radioChildren[i].children[0].classList.remove(
            "ant-radio-button-checked"
          );
        }
      }
    }
  }, [showEventDrawer]);

  const propEvent = {
    onRemove: (file) => {
      const index = fileEventList.indexOf(file);
      const newfileEventList = fileEventList.slice();
      newfileEventList.splice(index, 1);
      setfileEventList(newfileEventList);
    },
    beforeUpload: (file) => {
      const isImage =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml";
      if (!isImage) {
        notification.error({
          description: "Apenas é possível fazer upload de imagens.",
        });

        return isImage || Upload.LIST_IGNORE;
      }
      setfileEventList([...fileEventList, file]);
      return false;
    },
    fileEventList,
  };

  const t = 1;
  const data = [];

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      ["clean"],
    ],
    imageResize: {
      // parchment: Quill.import('parchment'),
      modules: ["Resize", "DisplaySize"],
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
    "video",
  ];

  const onChangeRangeEdit = (dates, dateString) => {
    if (!dates) {
      setDataEditEvent([]);
    } else {
      setDataEditEvent([dateString[0], dateString[1]]);
    }
  };

  const propEventEdit = {
    onRemove: (file) => {
      const index = fileEventListEdit.indexOf(file);
      const newFileList = fileEventListEdit.slice();
      newFileList.splice(index, 1);
      setfileEventListEdit(newFileList);
    },
    beforeUpload: (file) => {
      const isImage =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml";
      if (!isImage) {
        notification.error({
          description: "Apenas é possível fazer upload de imagens.",
        });

        return false || Upload.LIST_IGNORE;
      }
      setfileEventListEdit([file]);
      return false;
    },
  };

  const handleSubmitEvent = () => {
    let allFields = eventForm.getFieldsValue(true);
    if (
      allFields.localizacao == null ||
      descricaoEventEdit.length == 0 ||
      allFields.titulo.length == 0 ||
      dataEditEvent.length == 0 ||
      fileEventListEdit.length == 0
    ) {
      let descriptionMessage = createEvent ? "criar" : "editar";
      notification.error({
        description:
          "Para " +
          descriptionMessage +
          " o evento, preencha os campos respetivos.",
      });
      return;
    }

    let data_inicio = dataEditEvent[0];
    let data_fecho = dataEditEvent[1];

    const formData = new FormData();

    if (fileEventListEdit.length) {
      formData.append("file", fileEventListEdit[0]);
    }
    formData.append("titulo", allFields.titulo);
    formData.append("eventId", eventIdEdit);
    formData.append("dataInicio", data_inicio);
    formData.append("dataFecho", data_fecho);
    formData.append("descricao", descricaoEventEdit);
    formData.append("localizacao", allFields.localizacao);
    formData.append("clubeId", "");
    formData.append("area", allFields.area);
    formData.append("is_spap", tipoEventEdit);
    formData.append("is_pago", eventoPago == 1 || eventoPago == true ? 1 : 0);
    formData.append(
      "is_event",
      eventoIsEvent == 1 || eventoIsEvent == true ? 1 : 0
    );
    formData.append(
      "is_noticia",
      eventoIsNoticia == 1 || eventoIsNoticia == true ? 1 : 0
    );
    formData.append(
      "is_bolsa_premio",
      eventoIsBolsaPremio == 1 || eventoIsBolsaPremio == true ? 1 : 0
    );

    let url = createEvent ? "store-event" : "update-event";

    // You can use any AJAX library you
    fetch("https://api.spap.pt/api/admin/" + url, {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            setAllItems(data.eventos);
            handleEventos(data.eventos);

            if (createEvent) setShowEventDrawer(false);

            if (!createEvent) {
              setEditModeDescricao(false);
              let thisEvento = data.eventos.filter((evento) => {
                return evento.id == eventIdEdit;
              });
              if (thisEvento[0].imagem_destaque != "") {
                setfileEventListEdit([
                  {
                    uid: "-1",
                    name: thisEvento[0].imagem_destaque,
                    status: "done",
                    url:
                      "https://api.spap.pt/eventos/" +
                      eventIdEdit +
                      "/" +
                      thisEvento[0].imagem_destaque,
                  },
                ]);
              }
            }
          })
          .catch((err) => {});
      })
      .then(() => {
        let descriptionMessage = createEvent ? "criado" : "atualizado";
        notification.success({
          description: "O evento foi " + descriptionMessage + " com sucesso!",
        });
      })
      .catch(() => {
        notification.error({
          description: "Já existe um evento com o mesmo nome neste clube!",
        });
      })
      .finally(() => {});
  };

  const onChangeEventNoticia = (checked, id) => {
    let status = checked ? 1 : 0;

    axios
      .post(
        "https://api.spap.pt/api/admin/set-event-noticia",
        { status, id },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        handleEventos(res.data.eventos);
      })
      .catch((error) => {});
  };

  const onChangeEventBolsaPremio = (checked, id) => {
    let status = checked ? 1 : 0;

    axios
      .post(
        "https://api.spap.pt/api/admin/set-event-bolsa-premio",
        { status, id },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        handleEventos(res.data.eventos);
      })
      .catch((error) => {});
  };

  const onChangeIsEvent = (checked, id) => {
    let status = checked ? 1 : 0;

    axios
      .post(
        "https://api.spap.pt/api/admin/set-is-event",
        { status, id },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        handleEventos(res.data.eventos);
      })
      .catch((error) => {});
  };

  const handleRemoveEvent = () => {
    axios
      .post(
        "https://api.spap.pt/api/admin/remove-event",
        { idToRemove },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        setIdToRemove("");
        setRemoveEventModalOpen(false);
        handleEventos(res.data.eventos);
        notification.success({
          description: "O evento foi removido com sucesso!",
        });
      })
      .catch((error) => {
        // notification.error({description: 'Erro ao remover a imagem da galeria! Tente novamente.'});
      });
  };

  const openRemoveEventModal = (id, titulo) => {
    setIdToRemove(id);
    setTitleToRemove(titulo);
    setRemoveEventModalOpen(true);
  };

  const handleOrdem = (array, tipo, teste) => {
    if (!isSorting) return;
    setIsSorting(false);
    if (tipo == "noticias") {
      setNoticias(array);
    } else if (tipo == "eventos") {
      setEventos(array);
    } else if (tipo == "premios") {
      setBolsasEPremios(array);
    } else if (tipo == "nao-definidos") {
      setNaoDefinidos(array);
    }

    let ordemEventos = [];
    array.map(function (item, index) {
      ordemEventos.push({ ordem: index, id: item.id });
    });

    var bodyFormData = new FormData();

    bodyFormData.append("array", JSON.stringify(ordemEventos));

    fetch("https://api.spap.pt/api/admin/set-ordem-evento", {
      method: "POST",
      body: bodyFormData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    }).then((response) => {
      response.json().then((data) => {
        handleEventos(data.eventos);
      });
    });
  };

  /* CAMPO DE BUSCA */
  const [valueSearch, setValueSearch] = useState("");

  const handleSearchChange = (e) => {
    //setValueSearch(e.target.value);
    actionFilterItens(e.target.value, dataEditEvent, eventTypeFilter);
  };

  const handleEventoPago = (value) => {
    var bodyFormData = new FormData();
    let newStatus = value ? 1 : 0;
    bodyFormData.append("is_pago", newStatus);
    bodyFormData.append("id", eventIdEdit);

    fetch("https://api.spap.pt/api/admin/payment-event-status", {
      method: "POST",
      body: bodyFormData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    }).then((response) => {
      response.json().then((data) => {
        setAllItems(data.eventos);
        setEventoPago(value);
        handleEventos(data.eventos);
        let newStatus = value ? "pago" : "não pago";
        notification.success({
          description: "O evento passou ao estado " + newStatus,
        });
      });
    });
  };

  function actionFilterItens(text, date, type) {
    let filteredArray = allItems;
    // if (date?.length !== 0) {
    //   const filteredEvents = allItems.filter((event) => {
    //     const eventStart = dayjs(event.data_inicio, "DD/MM/YYYY");
    //     const eventEnd = dayjs(event.data_fecho, "DD/MM/YYYY");
    //     const rangeStart = date[0] ? dayjs(date[0], "DD/MM/YYYY") : null;
    //     const rangeEnd = date[1] ? dayjs(date[1], "DD/MM/YYYY") : null;

    //     return (
    //       (!rangeStart || eventStart >= rangeStart) &&
    //       (!rangeEnd || eventEnd <= rangeEnd)
    //     );
    //   });

    //   filteredArray = filteredEvents;
    // }

    console.log(text);

    if (text.length > 0) {
      console.log(text);
      filteredArray = filteredArray.filter((item) => {
        return item.titulo.toLowerCase().includes(text.toLowerCase());
      });
    }
    if (type.length > 0 && type != "all") {
      filteredArray = filteredArray.filter((event) => {
        return event.is_pago == type;
      });
    }

    handleEventos(filteredArray);
  }

  const handleDatePickerEventoPago = (date, dateString) => {
    setDataInicioPromocao(dateString[0]);
    setDataFechoPromocao(dateString[1]);
  };

  const handleSearchClear = () => {
    setValueSearch("");
    handleEventos(allItems);
  };

  const createNewEvent = () => {
    setCreateEvent(true);
    setEventIdEdit("");
    setDataEditEvent("");
    setDescricaoEventEdit("");
    setTituloEventEdit("");
    setTipoEventEdit("");
    setLocalizacaoEdit("");
    setImagemDestaqueEdit("");
    setAreaEdit([]);
    setEventoPago(false);
    setEventoIsEvent(false);
    setEventoIsBolsaPremio(false);
    setEventoIsNoticia(false);
    setfileEventListEdit([]);

    eventForm.resetFields();

    setShowEventDrawer(true);
  };

  const handleDetailsEventoPago = (eventPayments) => {
    setFileEventPayment([]);
    let thisEventPayment = eventPayments.filter((item) => {
      return item.event_id == eventIdEdit;
    });
    if (thisEventPayment?.length > 0) {
      setDataInicioPromocao(thisEventPayment[0].data_inicio);
      setDataFechoPromocao(thisEventPayment[0].data_fecho);
      setValorPromocao(thisEventPayment[0].valor);
      if (thisEventPayment[0].tipo_valor.length > 0) {
        setTipoValorPromocao(thisEventPayment[0].tipo_valor);
      } else {
        setTipoValorPromocao([]);
      }
      setIsPromocao(thisEventPayment[0].is_promo == 1 ? true : false);
      setDefaultTickets(JSON.parse(thisEventPayment[0].tickets));
      form.setFieldValue("tickets", JSON.parse(thisEventPayment[0].tickets));
      let qtd = 0;
      JSON.parse(thisEventPayment[0].tickets)?.map((ticket) => {
        if (ticket != undefined) {
          if (ticket.qtd != "") qtd += Number(ticket.qtd) ? ticket.qtd : 0;
        }
      });

      let thisFile = [];
      if (thisEventPayment[0].file != "") {
        setFileEventPayment([
          {
            uid: "-1",
            name: thisEventPayment[0].file,
            status: "done",
            url:
              "https://api.spap.pt/eventos/" +
              eventIdEdit +
              "/payment/" +
              thisEventPayment[0].file,
          },
        ]);
      }

      setQtdBilhetes(qtd);
    } else {
      form.setFieldValue("tickets", [
        {
          cargo: "todos",
          preco: "",
          qtd: "",
        },
      ]);

      setFileEventPayment([]);
    }
  };

  const handleEditDadosPagamento = () => {
    handleDetailsEventoPago(allEventPayments);
    setOpenEventoPagoDrawer(true);
  };

  const openDrawerEventPayments = (event) => {
    setEventPaymentId(event.id);
    setEventPaymentTitle(event.titulo);
    setDrawerPayments(true);
  };

  const onTextChange = (text) => {
    console.log(quillRef.current);
  };

  const handleSubmitEventoPago = () => {
    const tickets = form.getFieldsValue(true).tickets;

    if (
      !tickets.length ||
      (isPromocao && dataInicioPromocao == "") ||
      (isPromocao && dataFechoPromocao == "") ||
      (isPromocao && valorPromocao == "") ||
      (isPromocao && tipoValorPromocao == "")
    ) {
      let descriptionMessage = createEvent ? "criar" : "editar";
      notification.error({
        description:
          "Para editar os detalhes do evento, preencha os campos respetivos.",
      });
      return;
    }

    const formData = new FormData();
    formData.append("eventId", eventIdEdit);
    formData.append("file", fileEventPayment[0] ?? "");
    formData.append("tickets", JSON.stringify(tickets));
    formData.append("isPromocao", isPromocao ? 1 : 0);
    formData.append("dataInicio", dataInicioPromocao);
    formData.append("dataFecho", dataFechoPromocao);
    formData.append("valor", valorPromocao);
    formData.append("tipoValor", tipoValorPromocao);

    // You can use any AJAX library you
    fetch("https://api.spap.pt/api/admin/store-event-payment", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    }).then((response) => {
      response.json().then((data) => {
        setAllEventPayments(data.eventPayments);
        handleDetailsEventoPago(data.eventPayments);
        notification.success({
          description: "Dados do evento pago foram atualizados com sucesso.",
        });
      });
    });
  };

  const editDescricao = () => {
    setDescricaoEventEdit(descricaoEventEdit);
    setEditModeDescricao(true);
  };

  const updateQtd = () => {
    const tickets = form.getFieldsValue(true)?.tickets;
    let qtd = 0;
    tickets.map((ticket) => {
      if (ticket != undefined) {
        if (ticket.qtd != "") qtd += Number(ticket.qtd) ? ticket.qtd : 0;
      }
    });

    setQtdBilhetes(qtd);
  };

  useEffect(() => {
    if (eventTypeFilter.length > 0) {
      let text = document.querySelector('input[name="input-search-title"]');
      actionFilterItens(text, dataEditEvent, eventTypeFilter);
    }
  }, [eventTypeFilter]);

  return (
    <WrapperCardEvento>
      <WrapperCardList>
        <Row gutter={12} style={{ marginTop: "40px" }} align={"bottom"}>
          <Col span={10} id={"input-search"}>
            <label style={{ marginBottom: "5px", color: "rgb(73, 77, 125)" }}>
              Filtrar por título:
            </label>
            <Input
              name="input-search-title"
              placeholder="Procure por notícias e eventos"
              suffix={
                valueSearch ? (
                  <CloseCircleOutlined
                    onClick={handleSearchClear}
                    style={{ color: "#EE5555" }}
                  />
                ) : (
                  <SearchOutlined style={{ color: "#555" }} />
                )
              }
              // value={valueSearch}
              onChange={handleSearchChange}
            />
          </Col>
          <Col span={6}>
            <label style={{ marginBottom: "5px", color: "rgb(73, 77, 125)" }}>
              Filtrar por pagamento:
            </label>
            <Select
              key={"area-evento-" + eventIdEdit}
              style={styles.select_class}
              className="select-event"
              placeholder="Escolha o tipo de evento"
              value={eventTypeFilter}
              onChange={(e) => {
                setEventTypeFilter(e);
              }}
              options={[
                { value: "all", label: "Todos" },
                { value: "1", label: "Eventos pagos" },
                { value: "0", label: "Eventos não pagos" },
              ]}
            />
          </Col>
          {/* <Col span={5}>
            <label style={{ marginBottom: "5px", color: "rgb(73, 77, 125)" }}>
              Filtrar por data:
            </label>
            <RangePicker
              placeholder={["Dia de inicio", "Dia de fecho"]}
              key={"range-data-event"}
              format={"DD/MM/YYYY"}
              showTime={false}
              value={
                dataFilter.length
                  ? [
                      dayjs(dataFilter[0], "DD/MM/YYYY"),
                      dayjs(dataFilter[1], "DD/MM/YYYY"),
                    ]
                  : undefined
              }
              onChange={(date, dateString) =>
                onChangeRangeFilter(date, dateString)
              }
              style={styles.input_class}
              placement="bottomRight"
            />
          </Col> */}
          <Col align="right" span={8}>
            <Space size={10}>
              <Button
                onClick={() => setDrawerVouchers(true)}
                className="voucher-btn hide-event"
              >
                Vouchers
              </Button>
              <Button
                onClick={() => createNewEvent()}
                style={{
                  background: "#494D7D",
                  color: "#fff",
                  borderRadius: "6px",
                  height: "45px",
                  display: "flex",
                  fontWeight: "bold",
                  alignItems: "center",
                  padding: "0 30px",
                }}
              >
                <PlusOutlined /> Criar Novo Evento
              </Button>
            </Space>
          </Col>
        </Row>

        {naoDefinidos?.length > 0 && (
          <>
            <Divider>Não definidos</Divider>

            <ReactSortable
              list={naoDefinidos}
              onStart={() => setIsSorting(true)}
              setList={(e) => handleOrdem(e, "nao-definidos")}
              className="div-sortable"
            >
              {naoDefinidos.map((item, index) => {
                let textPreview = item.descricao
                  .replace(/<(h2|h3|img|b|strong)[^>]*>/g, "<p>")
                  .slice(0, 120);
                if (item.descricao.length > 120) textPreview += " (...)";
                return (
                  <>
                    <Col span={8}>
                      <Card
                        key={"naoDefinidos-" + item.id}
                        className={
                          item.is_pago == 0
                            ? "div-evento"
                            : "div-evento div-is-pago"
                        }
                        style={{ height: "100%" }}
                      >
                        <div>
                          <CardHeader>
                            <Title>{item.titulo}</Title>
                            <IconClose
                              className="icon icon-close"
                              onClick={() =>
                                openRemoveEventModal(item.id, item.titulo)
                              }
                            />
                          </CardHeader>
                          <div
                            className="evento-excerpt"
                            dangerouslySetInnerHTML={{ __html: textPreview }}
                          ></div>
                          <Space
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              alignContent: "center",
                            }}
                          >
                            <Space justifyContent="space-between">
                              <Space size={0}>
                                <IconCalendar className="icon icon-calendar" />
                                <span className="text-small">{item.date}</span>
                                {item.is_pago == true && (
                                  <>
                                    <span className="tag-evento-pago">
                                      EVENTO PAGO
                                    </span>
                                  </>
                                )}
                              </Space>
                            </Space>
                          </Space>
                        </div>
                        <div>
                          <Space
                            direction="vertical"
                            style={{ width: "100%", marginBottom: "10px" }}
                          >
                            <Space
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                alignContent: "center",
                              }}
                            >
                              <Space size={5}>
                                <Space>
                                  {item.is_event == 1 ? (
                                    <>
                                      {" "}
                                      <Switch
                                        defaultChecked
                                        onChange={(e) =>
                                          onChangeIsEvent(e, item.id)
                                        }
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <Switch
                                        onChange={(e) =>
                                          onChangeIsEvent(e, item.id)
                                        }
                                      />
                                    </>
                                  )}
                                  <span className="text">Eventos</span>
                                </Space>
                                <Space>
                                  {item.is_bolsa_premio == 1 ? (
                                    <>
                                      {" "}
                                      <Switch
                                        defaultChecked
                                        onChange={(e) =>
                                          onChangeEventBolsaPremio(e, item.id)
                                        }
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <Switch
                                        onChange={(e) =>
                                          onChangeEventBolsaPremio(e, item.id)
                                        }
                                      />
                                    </>
                                  )}
                                  <span className="text">Bolsas/Prémios</span>
                                </Space>
                                <Space>
                                  {item.is_noticia == 1 ? (
                                    <>
                                      {" "}
                                      <Switch
                                        defaultChecked
                                        onChange={(e) =>
                                          onChangeEventNoticia(e, item.id)
                                        }
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <Switch
                                        onChange={(e) =>
                                          onChangeEventNoticia(e, item.id)
                                        }
                                      />
                                    </>
                                  )}
                                  <span className="text">Notícias</span>
                                </Space>
                              </Space>
                              <div></div>
                            </Space>
                          </Space>
                          <Row>
                            <Col
                              span={item.is_pago ? 8 : 12}
                              align="center"
                              className="border-divider btn-div-evento"
                              key={"editEvent-" + index}
                              onClick={() => clickToEventDrawer(item)}
                            >
                              <SettingOutlined />
                            </Col>
                            {item.is_pago == true && (
                              <Col
                                span={item.is_pago ? 8 : 12}
                                align="center"
                                className="border-divider btn-div-evento"
                                key={"editEventNaoDefinidosPayment-" + index}
                                onClick={(e) => {
                                  e.preventDefault();
                                  openDrawerEventPayments(item);
                                }}
                              >
                                <UserOutlined />
                              </Col>
                            )}
                            <Col
                              span={item.is_pago ? 8 : 12}
                              align="center"
                              onClick={() => {
                                window.open(
                                  "https://spap.pt/agenda/evento/" + item.id,
                                  "_blank"
                                );
                              }}
                              className="btn-div-evento"
                            >
                              <EyeOutlined />
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    </Col>
                  </>
                );
              })}
            </ReactSortable>
          </>
        )}
        <Divider>Eventos</Divider>
        {eventos?.length == 0 && (
          <p style={{ textAlign: "center" }}>
            Não existem eventos disponíveis.
          </p>
        )}
        {eventos?.length > 0 && (
          <>
            <ReactSortable
              list={eventos}
              onStart={() => setIsSorting(true)}
              setList={(e) => handleOrdem(e, "eventos")}
              className="div-sortable"
            >
              {eventos.map((item, index) => {
                let textPreview = item.descricao
                  .replace(/<(h2|h3|img|b|strong)[^>]*>/g, "<p>")
                  .slice(0, 120);
                if (item.descricao.length > 120) textPreview += " (...)";
                return (
                  <Col span={8}>
                    <Card
                      key={"naoDefinidos-" + item.id}
                      className={
                        item.is_pago == 0
                          ? "div-evento"
                          : "div-evento div-is-pago"
                      }
                      style={{ height: "100%" }}
                    >
                      <div>
                        <CardHeader>
                          <Title>{item.titulo}</Title>
                          <IconClose
                            className="icon icon-close"
                            onClick={() =>
                              openRemoveEventModal(item.id, item.titulo)
                            }
                          />
                        </CardHeader>
                        <div
                          className="evento-excerpt"
                          dangerouslySetInnerHTML={{ __html: textPreview }}
                        ></div>
                        <Space
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            alignContent: "center",
                          }}
                        >
                          <Space justifyContent="space-between">
                            <Space size={0}>
                              <IconCalendar className="icon icon-calendar" />
                              <span className="text-small">{item.date}</span>
                              {item.is_pago == true && (
                                <>
                                  <span className="tag-evento-pago">
                                    EVENTO PAGO
                                  </span>
                                </>
                              )}
                            </Space>
                          </Space>
                        </Space>
                      </div>
                      <div>
                        <Space
                          direction="vertical"
                          style={{ width: "100%", marginBottom: "10px" }}
                        >
                          <Space
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              alignContent: "center",
                            }}
                          >
                            <Space size={5}>
                              <Space>
                                {item.is_event == 1 ? (
                                  <>
                                    {" "}
                                    <Switch
                                      defaultChecked
                                      onChange={(e) =>
                                        onChangeIsEvent(e, item.id)
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Switch
                                      onChange={(e) =>
                                        onChangeIsEvent(e, item.id)
                                      }
                                    />
                                  </>
                                )}
                                <span className="text">Eventos</span>
                              </Space>
                              <Space>
                                {item.is_bolsa_premio == 1 ? (
                                  <>
                                    {" "}
                                    <Switch
                                      defaultChecked
                                      onChange={(e) =>
                                        onChangeEventBolsaPremio(e, item.id)
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Switch
                                      onChange={(e) =>
                                        onChangeEventBolsaPremio(e, item.id)
                                      }
                                    />
                                  </>
                                )}
                                <span className="text">Bolsas/Prémios</span>
                              </Space>
                              <Space>
                                {item.is_noticia == 1 ? (
                                  <>
                                    {" "}
                                    <Switch
                                      defaultChecked
                                      onChange={(e) =>
                                        onChangeEventNoticia(e, item.id)
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Switch
                                      onChange={(e) =>
                                        onChangeEventNoticia(e, item.id)
                                      }
                                    />
                                  </>
                                )}
                                <span className="text">Notícias</span>
                              </Space>
                            </Space>
                            <div></div>
                          </Space>
                        </Space>
                        <Row>
                          <Col
                            span={item.is_pago ? 8 : 12}
                            align="center"
                            className="border-divider btn-div-evento"
                            key={"editEvent-" + index}
                            onClick={() => clickToEventDrawer(item)}
                          >
                            <SettingOutlined />
                          </Col>
                          {item.is_pago == true && (
                            <Col
                              span={item.is_pago ? 8 : 12}
                              align="center"
                              className="border-divider btn-div-evento"
                              key={"editEventNaoDefinidosPayment-" + index}
                              onClick={(e) => {
                                e.preventDefault();
                                openDrawerEventPayments(item);
                              }}
                            >
                              <UserOutlined />
                            </Col>
                          )}
                          <Col
                            span={item.is_pago ? 8 : 12}
                            align="center"
                            onClick={() => {
                              window.open(
                                "https://spap.pt/agenda/evento/" + item.id,
                                "_blank"
                              );
                            }}
                            className="btn-div-evento"
                          >
                            <EyeOutlined />
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                );
              })}
            </ReactSortable>
          </>
        )}
        <Divider>Notícias</Divider>
        {noticias?.length == 0 && (
          <p style={{ textAlign: "center" }}>
            Não existem notícias disponíveis.
          </p>
        )}
        {noticias?.length > 0 && (
          <>
            <ReactSortable
              list={noticias}
              onStart={() => setIsSorting(true)}
              setList={(e) => handleOrdem(e, "noticias")}
              className="div-sortable"
            >
              {noticias.map((item, index) => {
                let textPreview = item.descricao
                  .replace(/<(h2|h3|img|b|strong)[^>]*>/g, "<p>")
                  .slice(0, 120);
                if (item.descricao.length > 120) textPreview += " (...)";
                return (
                  <Col span={8}>
                    <Card
                      key={"naoDefinidos-" + item.id}
                      className={
                        item.is_pago == 0
                          ? "div-evento"
                          : "div-evento div-is-pago"
                      }
                      style={{ height: "100%" }}
                    >
                      <div>
                        <CardHeader>
                          <Title>{item.titulo}</Title>
                          <IconClose
                            className="icon icon-close"
                            onClick={() =>
                              openRemoveEventModal(item.id, item.titulo)
                            }
                          />
                        </CardHeader>
                        <div
                          className="evento-excerpt"
                          dangerouslySetInnerHTML={{ __html: textPreview }}
                        ></div>
                        <Space
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            alignContent: "center",
                          }}
                        >
                          <Space justifyContent="space-between">
                            <Space size={0}>
                              <IconCalendar className="icon icon-calendar" />
                              <span className="text-small">{item.date}</span>
                              {item.is_pago == true && (
                                <>
                                  <span className="tag-evento-pago">
                                    EVENTO PAGO
                                  </span>
                                </>
                              )}
                            </Space>
                          </Space>
                        </Space>
                      </div>
                      <div>
                        <Space
                          direction="vertical"
                          style={{ width: "100%", marginBottom: "10px" }}
                        >
                          <Space
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              alignContent: "center",
                            }}
                          >
                            <Space size={5}>
                              <Space>
                                {item.is_event == 1 ? (
                                  <>
                                    {" "}
                                    <Switch
                                      defaultChecked
                                      onChange={(e) =>
                                        onChangeIsEvent(e, item.id)
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Switch
                                      onChange={(e) =>
                                        onChangeIsEvent(e, item.id)
                                      }
                                    />
                                  </>
                                )}
                                <span className="text">Eventos</span>
                              </Space>
                              <Space>
                                {item.is_bolsa_premio == 1 ? (
                                  <>
                                    {" "}
                                    <Switch
                                      defaultChecked
                                      onChange={(e) =>
                                        onChangeEventBolsaPremio(e, item.id)
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Switch
                                      onChange={(e) =>
                                        onChangeEventBolsaPremio(e, item.id)
                                      }
                                    />
                                  </>
                                )}
                                <span className="text">Bolsas/Prémios</span>
                              </Space>
                              <Space>
                                {item.is_noticia == 1 ? (
                                  <>
                                    {" "}
                                    <Switch
                                      defaultChecked
                                      onChange={(e) =>
                                        onChangeEventNoticia(e, item.id)
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Switch
                                      onChange={(e) =>
                                        onChangeEventNoticia(e, item.id)
                                      }
                                    />
                                  </>
                                )}
                                <span className="text">Notícias</span>
                              </Space>
                            </Space>
                            <div></div>
                          </Space>
                        </Space>
                        <Row>
                          <Col
                            span={item.is_pago ? 8 : 12}
                            align="center"
                            className="border-divider btn-div-evento"
                            key={"editEvent-" + index}
                            onClick={() => clickToEventDrawer(item)}
                          >
                            <SettingOutlined />
                          </Col>
                          {item.is_pago == true && (
                            <Col
                              span={item.is_pago ? 8 : 12}
                              align="center"
                              className="border-divider btn-div-evento"
                              key={"editEventNaoDefinidosPayment-" + index}
                              onClick={(e) => {
                                e.preventDefault();
                                openDrawerEventPayments(item);
                              }}
                            >
                              <UserOutlined />
                            </Col>
                          )}
                          <Col
                            span={item.is_pago ? 8 : 12}
                            align="center"
                            onClick={() => {
                              window.open(
                                "https://spap.pt/agenda/evento/" + item.id,
                                "_blank"
                              );
                            }}
                            className="btn-div-evento"
                          >
                            <EyeOutlined />
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                );
              })}
            </ReactSortable>
          </>
        )}
        <Divider>Bolsas & Prémios</Divider>
        {bolsasEPremios?.length == 0 && (
          <p style={{ textAlign: "center" }}>
            Não existem bolsas e prémios disponíveis.
          </p>
        )}
        {bolsasEPremios?.length > 0 && (
          <>
            <ReactSortable
              list={bolsasEPremios}
              onStart={() => setIsSorting(true)}
              setList={(e) => handleOrdem(e, "premios")}
              className="div-sortable"
            >
              {bolsasEPremios.map((item, index) => {
                let textPreview = item.descricao
                  .replace(/<(h2|h3|img|b|strong)[^>]*>/g, "<p>")
                  .slice(0, 120);
                if (item.descricao.length > 120) textPreview += " (...)";
                return (
                  <Col span={8}>
                    <Card
                      key={"naoDefinidos-" + item.id}
                      className={
                        item.is_pago == 0
                          ? "div-evento"
                          : "div-evento div-is-pago"
                      }
                      style={{ height: "100%" }}
                    >
                      <div>
                        <CardHeader>
                          <Title>{item.titulo}</Title>
                          <IconClose
                            className="icon icon-close"
                            onClick={() =>
                              openRemoveEventModal(item.id, item.titulo)
                            }
                          />
                        </CardHeader>
                        <div
                          className="evento-excerpt"
                          dangerouslySetInnerHTML={{ __html: textPreview }}
                        ></div>
                        <Space
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            alignContent: "center",
                          }}
                        >
                          <Space justifyContent="space-between">
                            <Space size={0}>
                              <IconCalendar className="icon icon-calendar" />
                              <span className="text-small">{item.date}</span>
                              {item.is_pago == true && (
                                <>
                                  <span className="tag-evento-pago">
                                    EVENTO PAGO
                                  </span>
                                </>
                              )}
                            </Space>
                          </Space>
                        </Space>
                      </div>
                      <div>
                        <Space
                          direction="vertical"
                          style={{ width: "100%", marginBottom: "10px" }}
                        >
                          <Space
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              alignContent: "center",
                            }}
                          >
                            <Space size={5}>
                              <Space>
                                {item.is_event == 1 ? (
                                  <>
                                    {" "}
                                    <Switch
                                      defaultChecked
                                      onChange={(e) =>
                                        onChangeIsEvent(e, item.id)
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Switch
                                      onChange={(e) =>
                                        onChangeIsEvent(e, item.id)
                                      }
                                    />
                                  </>
                                )}
                                <span className="text">Eventos</span>
                              </Space>
                              <Space>
                                {item.is_bolsa_premio == 1 ? (
                                  <>
                                    {" "}
                                    <Switch
                                      defaultChecked
                                      onChange={(e) =>
                                        onChangeEventBolsaPremio(e, item.id)
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Switch
                                      onChange={(e) =>
                                        onChangeEventBolsaPremio(e, item.id)
                                      }
                                    />
                                  </>
                                )}
                                <span className="text">Bolsas/Prémios</span>
                              </Space>
                              <Space>
                                {item.is_noticia == 1 ? (
                                  <>
                                    {" "}
                                    <Switch
                                      defaultChecked
                                      onChange={(e) =>
                                        onChangeEventNoticia(e, item.id)
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Switch
                                      onChange={(e) =>
                                        onChangeEventNoticia(e, item.id)
                                      }
                                    />
                                  </>
                                )}
                                <span className="text">Notícias</span>
                              </Space>
                            </Space>
                            <div></div>
                          </Space>
                        </Space>
                        <Row>
                          <Col
                            span={item.is_pago ? 8 : 12}
                            align="center"
                            className="border-divider btn-div-evento"
                            key={"editEvent-" + index}
                            onClick={() => clickToEventDrawer(item)}
                          >
                            <SettingOutlined />
                          </Col>
                          {item.is_pago == true && (
                            <Col
                              span={item.is_pago ? 8 : 12}
                              align="center"
                              className="border-divider btn-div-evento"
                              key={"editEventNaoDefinidosPayment-" + index}
                              onClick={(e) => {
                                e.preventDefault();
                                openDrawerEventPayments(item);
                              }}
                            >
                              <UserOutlined />
                            </Col>
                          )}
                          <Col
                            span={item.is_pago ? 8 : 12}
                            align="center"
                            onClick={() => {
                              window.open(
                                "https://spap.pt/agenda/evento/" + item.id,
                                "_blank"
                              );
                            }}
                            className="btn-div-evento"
                          >
                            <EyeOutlined />
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                );
              })}
            </ReactSortable>
          </>
        )}
        <Drawer
          key={"editar-evento-editDrawer" + eventIdEdit}
          title={createEvent ? "Criar evento" : "Editar Evento"}
          width={1100}
          onClose={() => {
            setEditModeDescricao(false);
            setShowEventDrawer(false);
          }}
          open={showEventDrawer}
          bodyStyle={{
            paddingBottom: 80,
          }}
          extra={
            <Space>
              <Button onClick={() => setShowEventDrawer(false)}>
                Cancelar
              </Button>
              <Button
                onClick={handleSubmitEvent}
                type="submit"
                style={{
                  borderRadius: "6px",
                  background: "#494D7D",
                  color: "#fff",
                }}
              >
                Guardar
              </Button>
            </Space>
          }
        >
          <Col>
            <strong>
              Este evento está {eventoPago ? "habilitado" : "desabilitado"} a
              pagamentos
            </strong>
            . Para alterar o estado, clique no botão seguinte{" "}
            <Space size={5}>
              <Switch
                key={"switch-event-pago" + eventIdEdit}
                onChange={(e) => {
                  createEvent ? setEventoPago(e) : handleEventoPago(e);
                }}
                checked={eventoPago == 0 || eventoPago == false ? false : true}
              />
              {!createEvent && (
                <Button
                  onClick={handleEditDadosPagamento}
                  disabled={
                    eventoPago == 0 || eventoPago == false ? true : false
                  }
                >
                  Editar dados de pagamento
                </Button>
              )}
            </Space>
            <Divider></Divider>
            <Row align={"middle"}>
              <Col span={12}>
                <h5 style={{ marginBottom: "10px", fontSize: "18px" }}>
                  Upload da imagem
                </h5>
                <p>
                  Poderá fazer o upload de uma nova imagem de destaque clicando
                  na imagem do lado direito.
                </p>
              </Col>
              <Col span={12}>
                <Dragger
                  {...propEventEdit}
                  fileList={fileEventListEdit ?? []}
                  style={{
                    marginBottom: "5px",
                    width: "100%",
                    backgroundImage:
                      "url(https://api.spap.pt/eventos/" +
                      eventIdEdit +
                      "/" +
                      imagemDestaqueEdit +
                      ")",
                    backgroundSize: "cover",
                  }}
                >
                  <div style={{ opacity: 0 }}>
                    <p className="ant-upload-drag-icon">
                      <IconUpload />
                    </p>
                    <p className="ant-upload-text text">Upload da imagem </p>
                    <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                  </div>
                </Dragger>
              </Col>
            </Row>
            <Divider></Divider>
            <Row gutter={[16]}>
              <Col>
                <p className="mb-0">
                  <b>Este evento será mostrado em: </b>
                </p>
              </Col>
              <Col>
                <Space
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignContent: "center",
                  }}
                  key={"switches-" + eventIdEdit}
                >
                  <Space size={5}>
                    {/* <Space>
                      <Switch
                        defaultChecked={!!eventoIsEvent}
                        onChange={(e) => setEventoIsEvent(e)}
                      />
                      <span className="text">Eventos</span>
                    </Space>
                    <Space>
                      <Switch
                        defaultChecked={!!eventoIsBolsaPremio}
                        onChange={(e) => setEventoIsBolsaPremio(e)}
                      />
                      <span className="text">Bolsas/Prémios</span>
                    </Space> */}
                    <Space>
                      {eventoIsEvent == 1 || eventoIsEvent == true ? (
                        <>
                          {" "}
                          <Switch
                            checked
                            onChange={(e) => setEventoIsEvent(e)}
                          />
                        </>
                      ) : (
                        <>
                          <Switch onChange={(e) => setEventoIsEvent(e)} />
                        </>
                      )}
                      <span className="text">Eventos</span>
                    </Space>
                    <Space>
                      {eventoIsBolsaPremio == 1 ||
                      eventoIsBolsaPremio == true ? (
                        <>
                          {" "}
                          <Switch
                            checked
                            onChange={(e) => setEventoIsBolsaPremio(e)}
                          />
                        </>
                      ) : (
                        <>
                          <Switch onChange={(e) => setEventoIsBolsaPremio(e)} />
                        </>
                      )}
                      <span className="text">Bolsas/Prémios</span>
                    </Space>
                    <Space>
                      {eventoIsNoticia == 1 || eventoIsNoticia == true ? (
                        <>
                          {" "}
                          <Switch
                            checked
                            onChange={(e) => setEventoIsNoticia(e)}
                          />
                        </>
                      ) : (
                        <>
                          <Switch onChange={(e) => setEventoIsNoticia(e)} />
                        </>
                      )}
                      <span className="text">Notícias</span>
                    </Space>
                  </Space>
                </Space>
              </Col>
            </Row>
            <Divider></Divider>
            <Form
              form={eventForm}
              key={"form-event-" + eventIdEdit}
              initialValues={{
                titulo: tituloEventEdit,
                localizacao: localizacaoEdit,
                area: areaEdit,
                descricao: descricaoEventEdit,
              }}
            >
              <Row
                gutter={[16, 16]}
                style={{ flexFlow: "column !important" }}
                align={"bottom"}
              >
                <Col span={18}>
                  <h6 style={styles.h6_label}>Título</h6>

                  <Form.Item noStyle name={"titulo"}>
                    <Input
                      placeholder="Título"
                      name="titulo"
                      style={styles.input_class}
                      key={"titulo-evento-" + eventIdEdit}
                      required
                    />
                  </Form.Item>
                </Col>
                <Col span={6} align="bottom">
                  <h6 style={styles.h6_label}>Onde vai ser inserido?</h6>
                  <Radio.Group
                    className="radio-event"
                    key={"tipo-evento-" + eventIdEdit}
                    defaultValue={tipoEventEdit}
                    size="large"
                    onChange={(e) => {
                      setTipoEventEdit(e.target.value);
                    }}
                    ref={radioGroupRef}
                  >
                    <Radio.Button value={"1"}>SPAP</Radio.Button>
                    <Radio.Button value={"0"}>NISPAP</Radio.Button>
                  </Radio.Group>
                </Col>
                <Col span={8}>
                  <h6 style={styles.h6_label}>Data</h6>
                  {dataEditEvent?.length == 0 ||
                  dataEditEvent[0] == "Invalid Date" ||
                  dataEditEvent[1] == "Invalid Date" ? (
                    <>
                      <RangePicker
                        placeholder={["Dia de inicio", "Dia de fecho"]}
                        format={"DD/MM/YYYY"}
                        showTime={false}
                        onChange={onChangeRangeEdit}
                        style={styles.input_class}
                        placement="bottomRight"
                        value={undefined}
                      />
                    </>
                  ) : (
                    <>
                      <RangePicker
                        placeholder={["Dia de inicio", "Dia de fecho"]}
                        format={"DD/MM/YYYY"}
                        showTime={false}
                        onChange={onChangeRangeEdit}
                        style={styles.input_class}
                        placement="bottomRight"
                        value={
                          dataEditEvent?.length > 0
                            ? [
                                dayjs(dataEditEvent[0], "DD/MM/YYYY"),
                                dayjs(dataEditEvent[1], "DD/MM/YYYY"),
                              ]
                            : undefined
                        }
                      />
                    </>
                  )}
                </Col>
                <Col span={8}>
                  <h6 style={styles.h6_label}>Localização</h6>
                  <Form.Item noStyle name={"localizacao"}>
                    <Input
                      key={"localizacao-evento-" + eventIdEdit}
                      placeholder="Localização"
                      style={styles.input_class}
                      required
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <h6 style={styles.h6_label}>Área de Diferenciação</h6>
                  <Form.Item noStyle name={"area"}>
                    <Select
                      key={"area-evento-" + eventIdEdit}
                      style={styles.select_class}
                      className="select-event"
                      placeholder="Área de Diferenciação"
                      options={[
                        { value: "Patologia geral", label: "Patologia geral" },
                        { value: "Citologia", label: "Citologia" },
                        {
                          value: "Patologia cutânea",
                          label: "Patologia cutânea",
                        },
                        {
                          value: "Patologia da cabeça e pescoço",
                          label: "Patologia da cabeça e pescoço",
                        },
                        {
                          value: "Patologia das partes moles",
                          label: "Patologia das partes moles",
                        },
                        {
                          value: "Patologia do sistema nervosa",
                          label: "Patologia do sistema nervoso",
                        },
                        {
                          value: "Patologia digital",
                          label: "Patologia digital",
                        },
                        {
                          value: "Patologia endócrina",
                          label: "Patologia endócrina",
                        },
                        {
                          value: "Patologia fetoplacentar",
                          label: "Patologia fetoplacentar",
                        },
                        {
                          value: "Patologia gastrointestinal",
                          label: "Patologia gastrointestinal",
                        },
                        {
                          value: "Patologia ginecológica",
                          label: "Patologia ginecológica",
                        },
                        {
                          value: "Patologia hematolinfoide",
                          label: "Patologia hematolinfoide",
                        },
                        {
                          value: "Patologia hepatobiliar",
                          label: "Patologia hepatobiliar",
                        },
                        {
                          value: "Patologia mamária",
                          label: "Patologia mamária",
                        },
                        {
                          value: "Patologia molecular",
                          label: "Patologia molecular",
                        },
                        {
                          value: "Patologia osteoarticular",
                          label: "Patologia osteoarticular",
                        },
                        {
                          value: "Patologia pancreática",
                          label: "Patologia pancreática",
                        },
                        {
                          value: "Patologia pulmonar",
                          label: "Patologia pulmonar",
                        },
                        {
                          value: "Patologia urogenital",
                          label: "Patologia urogenital",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <h6 style={styles.h6_label}>Descrição</h6>
                  {createEvent && (
                    <ReactQuill
                      key={"descricao-evento"}
                      modules={modules}
                      formats={formats}
                      theme="snow"
                      name="descricao"
                      value={descricaoEventEdit}
                      onChange={(e) => setDescricaoEventEdit(e)}
                    />
                  )}
                  {!createEvent && !editModeDescricao && (
                    <>
                      <Button onClick={() => editDescricao()}>Editar</Button>
                      <br />
                      <br />
                      <div
                        className="event__content"
                        dangerouslySetInnerHTML={{ __html: descricaoEventEdit }}
                      ></div>
                    </>
                  )}
                  {!createEvent && editModeDescricao && (
                    <ReactQuill
                      key={"descricao-evento"}
                      modules={modules}
                      formats={formats}
                      theme="snow"
                      name="descricao"
                      value={descricaoEventEdit}
                      onChange={(e) => setDescricaoEventEdit(e)}
                    />
                  )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Drawer>
        <DrawerVouchers
          show={drawerVouchers}
          onChange={(e) => setDrawerVouchers(e)}
          events={allItems}
          vouchers={allVouchers}
        />
        <Modal
          key={"remove-event-" + idToRemove}
          title="Está prestes a eliminar um evento"
          open={removeEventModalOpen}
          onOk={handleRemoveEvent}
          onCancel={() => setRemoveEventModalOpen(false)}
          okText="Confirmar"
          cancelText="Cancelar"
        >
          <p>
            Tem a certeza que pretende remover o evento <b>'{titleToRemove}'</b>
            ?
          </p>
        </Modal>

        <Drawer
          key={"detalhes-evento-pago" + eventIdEdit}
          title={"Detalhes do evento pago"}
          width={800}
          onClose={() => {
            setOpenEventoPagoDrawer(false);
          }}
          open={openEventoPagoDrawer}
          extra={
            <Space>
              <Button
                onClick={() => {
                  setOpenEventoPagoDrawer(false);
                }}
              >
                Cancelar
              </Button>
              <Button
                onClick={handleSubmitEventoPago}
                type="submit"
                style={{
                  borderRadius: "6px",
                  background: "#494D7D",
                  color: "#fff",
                }}
              >
                Guardar
              </Button>
            </Space>
          }
        >
          <ManageVoucher>
            <Row gutter={[16, 16]} className="mb-20">
              <Col
                className="qtd-tickets"
                style={{
                  background: "#E8E9F3",
                  padding: "15px",
                  borderRadius: "16px",
                }}
                span={24}
              >
                <Col span={24}>
                  <Form
                    form={form}
                    name="dynamic_form_item"
                    {...formItemLayoutWithOutLabel}
                    initialValues={{
                      tickets: defaultTickets,
                    }}
                  >
                    <Form.List name="tickets">
                      {(fields, { add, remove }, { errors }) => (
                        <>
                          <Row className="w-100" gutter={[8, 8]}>
                            <Col span={5}>
                              <Button
                                type="dashed"
                                onClick={() => add()}
                                style={{
                                  width: "100%",
                                  margin: 0,
                                }}
                                icon={<PlusOutlined />}
                              >
                                Adicionar Cargo
                              </Button>
                            </Col>
                            <Col span={19}>
                              {fields.map((field, index) => (
                                <Row
                                  gutter={[8, 8]}
                                  style={{ marginBottom: "10px" }}
                                >
                                  <Col span={11}>
                                    <Form.Item
                                      noStyle
                                      name={[field.name, "cargo"]}
                                    >
                                      <TreeSelect
                                        showSearch
                                        treeDefaultExpandAll
                                        className="inputDados"
                                        placeholder="Cargo"
                                        treeData={[
                                          {
                                            title: "Todos",
                                            value: "todos",
                                          },
                                          {
                                            title: "Não sócio",
                                            value: "nao_socio",
                                          },
                                          {
                                            title: "Sócio Titular",
                                            value: "titular",
                                            children: [
                                              {
                                                title: "Interno",
                                                value: "interno",
                                              },
                                              {
                                                title: "Especialista",
                                                value: "especialista",
                                              },
                                            ],
                                          },
                                          {
                                            title: "Sócio Honorário",
                                            value: "honorário",
                                          },
                                        ]}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={7}>
                                    <Form.Item
                                      noStyle
                                      name={[field.name, "preco"]}
                                    >
                                      <InputNumber
                                        className="inputDados"
                                        placeholder="Preço por bilhete"
                                        min={1}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={5}>
                                    <Form.Item
                                      noStyle
                                      name={[field.name, "qtd"]}
                                    >
                                      <InputNumber
                                        className="inputDados"
                                        placeholder="Quantidade"
                                        onChange={() => {
                                          updateQtd();
                                        }}
                                        min={1}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={1}>
                                    {fields.length > 1 ? (
                                      <MinusCircleOutlined
                                        className="dynamic-delete-button"
                                        onClick={() => {
                                          remove(field.name);
                                          updateQtd();
                                        }}
                                      />
                                    ) : null}
                                  </Col>
                                </Row>
                              ))}
                            </Col>
                          </Row>
                        </>
                      )}
                    </Form.List>
                    <Row>
                      <Col offset={5}>
                        <p className="text-right">
                          Total de bilhetes: <b>{qtdBilhetes}</b>
                        </p>
                      </Col>
                    </Row>
                  </Form>
                </Col>
                <Col span={24} align="center" className="promo-div">
                  <Row align={"middle"} gutter={[8, 8]}>
                    <Col span={5}>
                      <Switch
                        defaultChecked
                        onChange={(e) => setIsPromocao(e)}
                        checked={!!isPromocao}
                      />{" "}
                      Promoção
                    </Col>
                    <Col span={10}>
                      <RangePicker
                        className="rangePicker"
                        placeholder={["Dia de inicio", "Dia de fecho"]}
                        key={"range-data-event"}
                        format={"DD/MM/YYYY"}
                        showTime={false}
                        onChange={(date, dateString) =>
                          handleDatePickerEventoPago(date, dateString)
                        }
                        value={
                          dataInicioPromocao?.length > 0 &&
                          dataFechoPromocao?.length > 0
                            ? [
                                dayjs(dataInicioPromocao, "DD/MM/YYYY"),
                                dayjs(dataFechoPromocao, "DD/MM/YYYY"),
                              ]
                            : undefined
                        }
                        placement="bottomRight"
                        disabled={isPromocao ? false : true}
                      />
                    </Col>
                    <Col span={4}>
                      <InputNumber
                        value={valorPromocao}
                        className="inputDados"
                        placeholder="Valor"
                        onChange={(e) => setValorPromocao(e)}
                        min={1}
                        max={tipoValorPromocao == "perc" ? "100" : ""}
                        disabled={isPromocao ? false : true}
                      />
                    </Col>
                    <Col span={5}>
                      <Select
                        showSearch
                        value={tipoValorPromocao}
                        className="inputDados"
                        placeholder="Tipo de Valor"
                        allowClear
                        style={{
                          width: "100%",
                          height: "45px",
                        }}
                        onChange={(e) => {
                          setTipoValorPromocao(e);
                          if (e == "perc" && valorPromocao > 100) {
                            setValorPromocao(100);
                          }
                        }}
                        options={[
                          { value: "perc", label: "Percentagem (%)" },
                          { value: "euro", label: "Euro (€)" },
                        ]}
                        disabled={isPromocao ? false : true}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={24} align="center" className="upload-file-div">
                  <Row align={"middle"} gutter={[8, 8]}>
                    <Col span={5}>
                      <p className="anexar-title">
                        <LinkOutlined /> Anexar Ficheiro
                      </p>
                      <p className="anexar-subtitle">
                        Anexe aqui documentos PDF (máx. 10MB)
                      </p>
                    </Col>
                    <Col span={19}>
                      <Upload
                        {...propsFileEventoPago}
                        className="btn-upload"
                        accept={".pdf"}
                        fileList={fileEventPayment ?? []}
                        // maxCount={1}
                      >
                        <Button icon={<UploadOutlined />}>
                          Upload ficheiro (máx. 10MB)
                        </Button>
                      </Upload>
                      {/* if () */}
                      {/* <a
                        href={""}
                        style={{
                          paddingTop: "10px",
                        }}
                      >
                        Ver Ficheiro atual
                      </a> */}
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Row>
            <Divider />
          </ManageVoucher>
        </Drawer>
        <DrawerEventPayments
          key={"drawerPayment" + eventPaymentId}
          eventTitle={eventPaymentTitle}
          eventId={eventPaymentId}
          show={drawerPayments}
          onChange={(e) => setDrawerPayments(e)}
          purchases={allPurchases}
          events={eventos}
          onUpdate={(purchases) => setAllPurchases(purchases)}
        />
      </WrapperCardList>
    </WrapperCardEvento>
  );
}
