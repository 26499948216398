import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Card from "../Card";
import Title from "../Title";
import DrawerEvento from "../../../Pages/Backoffice/Eventos/drawerEvento";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import dayjs from "dayjs";
import {
  Button,
  Divider,
  Space,
  message,
  Upload,
  Input,
  Form,
  Pagination,
  Drawer,
  Col,
  Row,
  DatePicker,
  notification,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { ReactComponent as IconCalendar } from "../../../Assets/Images/Backoffice/Calendar.svg";
import { ReactComponent as IconClose } from "../../../Assets/Images/Backoffice/Close.svg";
import { ReactComponent as IconEdit } from "../../../Assets/Images/Backoffice/Edit.svg";
import { ReactComponent as IconHours } from "../../../Assets/Images/Backoffice/Hours.svg";
import { ReactComponent as IconUpload } from "../../../Assets/Images/Backoffice/Upload.svg";
const { RangePicker } = DatePicker;
const { Dragger } = Upload;
const Wrapper = styled.section`
  display: flex;
  flex-direction: column;

  p {
    color: #c2c4d4;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 20px;
    text-align: left;
  }

  .text {
    color: #809fb8;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0px;
  }

  .icon-close {
    cursor: pointer;
  }

  .action-edit {
    cursor: pointer;
    color: #494d7d;
  }
`;

const CardHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const ContentFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    display: block !important;
    font-size: 15px;
    font-weight: bold;
    height: 45px;
    line-height: 18px;

    i {
      margin: 0 3px;
    }
  }

  a {
    text-decoration: none;
  }
`;
var styles = {
  h6_label: {
    color: "#809FB8",
    fontFamily: "Roboto",
    fontSize: "15px",
    marginBottom: "1px",
  },
  input_class: {
    width: "100%",
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
  },
  switch_label: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    height: "45px",
    borderRadius: "8px",
    padding: "0px 16px",
    color: "#809FB8",
    border: "1px solid #809FB8",
    textAlign: "center",
  },
  upload: {
    width: "100%",
  },
  input_class2: {
    width: "100%",
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #ccc",
    color: "#0C1151",
  },
};

export default function CardList(props) {
  const clubeId = props.clubeId;
  const session_storage = props.session;
  const [fileListEdit, setFileListEdit] = useState([]);

  // const [uploading, setUploading] = useState(false);
  // const [eventIdEdit, setEventIdEdit] = useState('');
  // const [dataInicioEdit, setDataInicioEdit] = useState('');
  // const [dataFechoEdit, setDataFechoEdit] = useState('');
  // const [descricaoEdit, setDescricaoEdit] = useState('');
  // const [tituloEdit, setTituloEdit] = useState('');
  // const [localizacaoEdit, setLocalizacaoEdit] = useState('');
  // const [imagemDestaqueEdit, setImagemDestaqueEdit] = useState('');
  // const [eventDrawer, setEventDrawer] = useState('');
  // const [showEventDrawer, setShowEventDrawer] = useState(false);
  // const clickToDrawer = (item) => {
  //   setEventIdEdit(item.id);
  //   setDataInicioEdit(item.data_inicio);
  //   setDataFechoEdit(item.data_fecho);
  //   setDescricaoEdit(item.descricao);
  //   setTituloEdit(item.titulo);
  //   setLocalizacaoEdit(item.localizacao);
  //   setImagemDestaqueEdit(item.imagem_destaque);
  //   setShowEventDrawer(true);
  // };

  const handleCloseDrawer = () => {
    props.onChange(false);
  };

  const getEventos = () => {
    let allEventos = session_storage?.all_data.eventos;

    allEventos.map((evento) => {
      if (evento.club_id == clubeId) {
        const [dateInicioValues, timeInicioValues] =
          evento.data_inicio.split(" ");
        const [dayInicio, monthInicio, yearInicio] =
          dateInicioValues.split("/");

        evento.date = dateInicioValues;
        evento.time = timeInicioValues;
      }
    });

    return allEventos;
  };

  const [eventos, setEventos] = useState(getEventos);

  const [allItems, setAllItems] = useState(eventos);
  const [currentItems, setCurrentItems] = useState(eventos.slice(0, 2));
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 2;

  const handlePage = (page) => {
    const newOffset = ((page - 1) * itemsPerPage) % allItems.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(eventos.slice(itemOffset, endOffset));
  }, [itemOffset, allItems]);

  const handleEditEvent = () => {
    if (
      !localizacaoEdit.length ||
      !descricaoEdit.length ||
      !tituloEdit.length
    ) {
      notification.error({
        description: "Para editar o evento, preencha os campos respetivos.",
      });
      return;
    }

    const formData = new FormData();

    if (fileListEdit.length) {
      formData.append("file", fileListEdit[0]);
    }

    formData.append("titulo", tituloEdit);
    formData.append("eventId", eventIdEdit);
    formData.append("dataInicio", dataInicioEdit);
    formData.append("dataFecho", dataFechoEdit);
    formData.append("descricao", descricaoEdit);
    formData.append("localizacao", localizacaoEdit);

    setUploading(true);

    // You can use any AJAX library you like
    fetch("https://api.spap.pt/api/admin/update-event", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            session_storage.all_data.eventos = data.eventos;
            localStorage.setItem(
              "session_data",
              JSON.stringify(session_storage)
            );
            session_storage.all_data.eventos.map((evento) => {
              setFileListEdit([]);
              if (evento.id == props.event.id) {
                const newImagemDestaque = evento.imagem_destaque;
                setImagemDestaqueEdit(newImagemDestaque);
              }
            });
          })
          .catch((err) => {});
      })
      .then(() => {
        setFileListEdit([]);
        notification.success({
          description: "O evento foi atualizado com sucesso!",
        });
      })
      .catch(() => {
        notification.error({
          description: "Já existe um evento com o mesmo nome neste clube!",
        });
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const onChangeRange = (value, dateString) => {
    setDataInicioEdit(dateString[0]);
    setDataFechoEdit(dateString[1]);
  };

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const prop = {
    onRemove: (file) => {
      const index = fileListEdit.indexOf(file);
      const newFileList = fileListEdit.slice();
      newFileList.splice(index, 1);
      setFileListEdit(newFileList);
    },
    beforeUpload: (file) => {
      const isImage =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml";
      if (!isImage) {
        notification.error({
          description: "Apenas é possível fazer upload de imagens.",
        });
      }
      setFileListEdit([...fileListEdit, file]);
      return false;
    },
    fileListEdit,
  };

  return (
    allItems.length > 0 && (
      <Wrapper>
        {currentItems.map((item, index) => {
          let textPreview = item.descricao
            .replace(/<(h2|h3|img|b|strong)[^>]*>/g, "<p>")
            .slice(0, 120);
          if (item.descricao.length > 120) textPreview += " (...)";
          return (
            <Card key={"eventos-" + item.id}>
              <CardHeader>
                <Title>{item.titulo}</Title>
                <IconClose
                  className="icon icon-close"
                  onClick={(e) => alert("excluir?")}
                />
              </CardHeader>
              <Space direction="vertical">
                <div
                  style={{ fontSize: "12px", lineHeight: "1.4" }}
                  dangerouslySetInnerHTML={{ __html: textPreview }}
                ></div>
                <Space size={30}>
                  <Space>
                    <IconCalendar className="icon icon-calendar" />
                    <span className="text">{item.date}</span>
                  </Space>
                  <Space
                    className="action-edit"
                    key={index}
                    onClick={() => clickToDrawer(item)}
                  >
                    <IconEdit className="icon icon-edit" />
                    <span>Editar</span>
                  </Space>
                  <Space className="action-edit">
                    {item.agendar_post != null && (
                      <>
                        <span>Este evento será publicado a </span>
                        {item.agendar_post}
                      </>
                    )}
                  </Space>
                </Space>
              </Space>
            </Card>
          );
        })}
        <Drawer
          key={"editar-evento-" + eventIdEdit}
          title="Editar Evento"
          width={500}
          onClose={() => setShowEventDrawer(false)}
          open={showEventDrawer}
          bodyStyle={{
            paddingBottom: 80,
          }}
          extra={
            <Space>
              <Button>Cancelar</Button>
              <Button
                onClick={handleEditEvent}
                type="submit"
                style={{
                  borderRadius: "6px",
                  background: "#494D7D",
                  color: "#fff",
                }}
              >
                Guardar
              </Button>
            </Space>
          }
        >
          <form layout="vertical" id="update-event-form">
            <Col>
              <Row align={"middle"}>
                <Col span={12}>
                  <h5 style={{ marginBottom: "10px", fontSize: "18px" }}>
                    Upload da imagem
                  </h5>
                  <p>
                    Poderá fazer o upload de uma nova imagem de destaque
                    clicando na imagem do lado direito.
                  </p>
                </Col>
                <Col span={12}>
                  <Dragger
                    {...prop}
                    style={{
                      marginBottom: "5px",
                      width: "100%",
                      backgroundImage:
                        "url(https://api.spap.pt/eventos/" +
                        eventIdEdit +
                        "/" +
                        imagemDestaqueEdit +
                        ")",
                      backgroundSize: "cover",
                    }}
                  >
                    <div style={{ opacity: 0 }}>
                      <p className="ant-upload-drag-icon">
                        <IconUpload />
                      </p>
                      <p className="ant-upload-text text">Upload da imagem </p>
                      <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                    </div>
                  </Dragger>
                </Col>
              </Row>
              <Divider
                style={{ background: "#fff", margin: "12px 0" }}
              ></Divider>
              <Row
                className="row-drawer"
                gutter={[8, 8]}
                style={{ flexFlow: "column !important" }}
              >
                <Col span={24}>
                  <h6 style={styles.h6_label}>Título</h6>
                  <Input
                    placeholder="Título"
                    name="titulo"
                    style={styles.input_class}
                    defaultValue={tituloEdit}
                    onChange={(e) => setTituloEdit(e.target.value)}
                    required
                  />
                </Col>
                <Col span={24}>
                  <h6 style={styles.h6_label}>Data</h6>
                  <RangePicker
                    placeholder={["Dia de inicio", "Dia de fecho"]}
                    format={"DD/MM/YYYY"}
                    showTime
                    defaultValue={[
                      dayjs(dataInicioEdit, "DD/MM/YYYY"),
                      dayjs(dataFechoEdit, "DD/MM/YYYY"),
                    ]}
                    onChange={onChangeRange}
                    style={styles.input_class}
                    placement="bottomRight"
                  />
                </Col>
                <Col span={24}>
                  <h6 style={styles.h6_label}>Localização</h6>
                  <Input
                    placeholder="Localização"
                    name="localizacao"
                    style={styles.input_class}
                    defaultValue={localizacaoEdit}
                    onChange={(e) => setLocalizacaoEdit(e.target.value)}
                    required
                  />
                </Col>
                <Col span={24}>
                  <h6 style={styles.h6_label}>Descrição</h6>
                  <ReactQuill
                    modules={modules}
                    formats={formats}
                    theme="snow"
                    name="descricao"
                    onChange={setDescricaoEdit}
                    defaultValue={descricaoEdit}
                  />
                </Col>
              </Row>
            </Col>
          </form>
        </Drawer>
        <ContentFooter>
          <Space size={32}>
            <Link to="/dashboard/eventos">
              <Button type="primary" className="cta">
                Ver lista de eventos completa
              </Button>
            </Link>
          </Space>
          <Pagination
            defaultCurrent={1}
            total={allItems.length}
            pageSize={itemsPerPage}
            onChange={handlePage}
          />
        </ContentFooter>
      </Wrapper>
    )
  );
}
