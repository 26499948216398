import { React, useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Container from "../../../Components/Container";
import FlexInRow from "../../../Components/FlexInRow";
import FlexInColumn from "../../../Components/FlexInColumn";
import PageTitle from "../../../Components/PageTitle";
import urlSlug from "url-slug";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin: 7.813vw 0 4.792vw;

  > .content {
    padding-bottom: 4vw;
  }

  img {
    width: 100%;
  }

  p {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0;
  }

  ul {
    padding-left: 1rem;
  }

  li {
    text-align: left;
    font-size: 1.354vw;
    font-weight: medium;
    line-height: 1.771vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .item__title {
    text-align: left;
    font-size: 4.167vw;
    line-height: 5vw;
    font-weight: bold;
    letter-spacing: 0px;
    color: #c2c4d4;
    opacity: 1;
    margin-top: 0.885vw;
  }

  .item__text {
    text-align: left;
    font-size: 1.563vw;
    line-height: 2.031vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 0.729vw;
  }

  .item__cta {
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #0c1151;
    border-radius: 0.521vw;
    opacity: 1;
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    text-decoration: none;
    color: #ffffff;
    opacity: 1;
    width: 8.021vw;
    height: 2.396vw;
    transition: 0.3s ease;
    margin-top: 1.458vw;
  }

  .item__cta:hover {
    background-color: #8688a8;
    color: #e5e5e5;
  }

  .item__date {
    text-align: left;
    font-size: 1.146vw;
    line-height: 1.51vw;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.521vw;
  }

  .item__date svg {
    font-size: 1.302vw;
  }

  .bar {
    background-color: #707070;
    height: 1px;
  }

  .prize__title {
    text-align: left;
    font-size: 1.667vw;
    font-weight: bold;
    line-height: 1.979vw;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    margin: 0.625vw 0 1.198vw;
  }

  .prize__text {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0;
  }

  .link {
    text-align: left;
    text-decoration: underline;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #f88436;
    opacity: 1;
    transition: 0.3s ease-out;
  }

  .link:hover {
    text-decoration: none;
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    p {
      font-size: 3.222vw;
      line-height: 4.333vw;
    }

    .item__date,
    .item__date svg,
    .item__text,
    .prize__date,
    .prize__date svg,
    .prize__text {
      font-size: 3.333vw;
      line-height: 4.444vw;
    }

    .prize__title,
    .link {
      font-size: 18px;
      line-height: 26px;
    }

    .link {
      margin-top: 50px;
    }

    .item__cta {
      border-radius: 8px;
      font-size: 3.333vw;
      line-height: 4.444vw;
      height: 45px;
      width: 220px;
    }
  }

  @media (min-width: 1920px) {
    p,
    .item__cta,
    .prize__text {
      font-size: 18px;
      line-height: 24px;
    }

    .item__cta {
      border-radius: 10px;
      margin-top: 25px;
      padding: 12px 25px;
    }

    .item__date,
    .item__date svg {
      font-size: 22px;
      line-height: 29px;
    }

    .item__title {
      font-size: 62px;
      line-height: 96px;
    }

    .item__text {
      font-size: 30px;
      line-height: 39px;
    }
  }
`;

const Title = styled.h2`
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;
  margin: 0;

  @media (max-width: 992px) {
    font-size: 20px;
    line-height: 24px;
  }

  @media (min-width: 1920px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

const CardPicture = styled.picture`
  border: none;
  border-top-left-radius: 0.521vw;
  border-top-right-radius: 0.521vw;
  overflow: hidden;
  position: relative;
  min-width: ${(props) => props.width};
  max-width: ${(props) => props.width};

  img {
    height: 100%;
    object-fit: cover;
    object-position: top;
  }

  .overlay {
    background-color: #f88436;
    opacity: 0.2;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
  }

  @media (max-width: 992px) {
    min-width: 100%;
    max-width: 100%;
  }

  @media (min-width: 1920px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    min-width: 792px;
    max-width: 792px;

    &.item__image {
      min-width: 100%;
      max-width: 100%;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 11.25vw;

  .item__image {
    height: 17.656vw;
  }

  .item__image img {
    object-position: center;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    gap: 30px;

    .item__image {
      height: 50vw;
    }
  }

  @media (min-width: 1920px) {
    gap: 123px 215px;

    .item__image {
      height: 339px;
    }

    .prize__title {
      font-size: 32px;
      line-height: 38px;
      margin: 12px 0 60px;
    }
  }
`;

export default function BolsasPremios(props) {
  const ListPosts = [
    {
      imageDesktop: require("../../../Assets/Images/premios-01.png"),
      imageMobile: require("../../../Assets/Images/premios-01.png"),
      imageAlt: "Melhor Comunicação Oral na área de Histologia",
      date: "2017",
      title: "Melhor Comunicação Oral na área de Histologia",
      text: "<i>“Limb Body Wall Complex”</i>: Definição E Discussão Da Etiopatogenia. Um Estudo Tri-Cêntrico De 9 Anos. Limb Body (…)",
      link: "/spap/noticia/melhor-comunicacao-na-area-de-histologia",
    },
    {
      imageDesktop: require("../../../Assets/Images/premios-02.png"),
      imageMobile: require("../../../Assets/Images/premios-02.png"),
      imageAlt: "Melhor Comunicação Oral na área de Histologia",
      date: "2017",
      title: "Melhor Comunicação Oral na área de Histologia",
      text: "Culturas 3D De Carcinoma Colorretal: Um Modelo Exequível Que Poderá Ser A Chave Do Tratamento Oncológico Dirigido(…)",
      link: "/spap/noticia/melhor-comunicacao-na-area-de-histologia",
    },
    {
      imageDesktop: require("../../../Assets/Images/premios-03.png"),
      imageMobile: require("../../../Assets/Images/premios-03.png"),
      imageAlt: "Melhor Caso Clínico",
      date: "2017",
      title: "Melhor Caso Clínico",
      text: "Neoplasia Maligna Primitiva Do Sistema Nervoso Central Com Monossomia 22 (Consequente Deleção Do Gene Smarcb1) (…)",
      link: "/spap/noticia/melhor-comunicacao-na-area-de-histologia",
    },
    {
      imageDesktop: require("../../../Assets/Images/premios-04.png"),
      imageMobile: require("../../../Assets/Images/premios-04.png"),
      imageAlt: "Melhor Comunicação Oral - 1.º Prémio",
      date: "2018",
      title: "Melhor Comunicação Oral - 1.º Prémio",
      text: "Assimetria Radial Dos Achados Histológicos De Refluxo Ao Nível Da Junção Gastro-Esofágica: Maior Sensibilidade (…)",
      link: "/spap/noticia/melhor-comunicacao-na-area-de-histologia",
    },
    {
      imageDesktop: require("../../../Assets/Images/premios-05.png"),
      imageMobile: require("../../../Assets/Images/premios-05.png"),
      imageAlt: "Melhor Comunicação Oral - Menção Honrosa",
      date: "2018",
      title: "Melhor Comunicação Oral - Menção Honrosa",
      text: "O Uso Da Telecitologia Para Segunda Opinião: Reproducibilidade Na Avaliação De Citologia Aspirativa Por Agulha Fina Em (…)",
      link: "/spap/noticia/melhor-comunicacao-na-area-de-histologia",
    },
    {
      imageDesktop: require("../../../Assets/Images/premios-06.png"),
      imageMobile: require("../../../Assets/Images/premios-06.png"),
      imageAlt: "Melhor Poster - 1º Prémio",
      date: "2018",
      title: "Melhor Poster - 1º Prémio",
      text: "Retinoblastoma Na Infância: A Perspectiva No Chuc Maria Beatriz Pimentão(1); Carlos Couto Faria(1); Rui Caetano Oliveira (…)",
      link: "/spap/noticia/melhor-comunicacao-na-area-de-histologia",
    },
  ];

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [eventos, setEventos] = useState([]);

  const month = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const getEventos = (allEventos) => {
    let eventosToShow = [];

    let today = new Date().toLocaleDateString();

    if (allEventos?.length > 0) {
      allEventos.map((evento) => {
        evento.slug = urlSlug(evento.titulo);

        evento.slug += "-" + evento.id;

        let mostraEvento = true;

        if (evento.agendar_post != null) {
          let date = evento.agendar_post.trim();

          /*
          if(date > today) {
            mostraEvento = false;
          }
          */
        }

        if (mostraEvento) {
          if (evento.data_inicio && evento.is_bolsa_premio == 1) {
            const [dateInicioValues, timeInicioValues] =
              evento.data_inicio.split(" ");
            const [dayInicio, monthInicio, yearInicio] =
              dateInicioValues.split("/");
            const [dateFechoValues, timeFechoValues] =
              evento.data_fecho.split(" ");
            const [dayFecho, monthFecho, yearFecho] =
              dateFechoValues.split("/");

            const monthInicioToString = month[Number(monthInicio - 1)];
            const monthFechoToString = month[Number(monthFecho - 1)];

            evento.monthInicio = monthInicio;
            evento.yearInicio = yearInicio;

            evento.url = evento.imagem_destaque
              ? "https://api.spap.pt/eventos/" +
                evento.id +
                "/" +
                evento.imagem_destaque
              : "https://api.spap.pt/clubes/clube-estudo-default.svg";

            if (
              monthInicio == monthFecho &&
              yearInicio === yearFecho &&
              dayInicio === dayFecho
            ) {
              evento.date =
                dayInicio + " " + monthInicioToString + " " + yearInicio;
            } else if (monthInicio == monthFecho && yearInicio === yearFecho) {
              evento.date =
                dayInicio +
                "-" +
                dayFecho +
                " " +
                monthInicioToString +
                " " +
                yearInicio;
            } else if (
              dayInicio == dayFecho &&
              monthInicio == monthFecho &&
              yearInicio === yearFecho
            ) {
              evento.date =
                dayInicio + " " + monthInicioToString + " " + yearInicio;
            } else if (monthInicio != monthFecho) {
              evento.date =
                dayInicio +
                " " +
                monthInicioToString +
                " a " +
                dayFecho +
                " " +
                monthFechoToString +
                " " +
                yearFecho;
            }

            eventosToShow.push(evento);
          }
        }
      });
    }

    eventosToShow.sort(function (a, b) {
      return new Date(a.data_inicio) - new Date(b.data_inicio);
    });

    setEventos(eventosToShow);
  };

  useEffect(() => {
    fetch(`https://api.spap.pt/api/all-eventos`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0) {
          console.log("nenhum evento");
        }
        getEventos(data.eventos);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const eventoDestaqueId = 33;

  const indexDestaque = eventos.findIndex((e) => e.id == eventoDestaqueId);

  if (indexDestaque !== -1) {
    const itemToMove = eventos.splice(indexDestaque, 1)[0];
    eventos.unshift(itemToMove);
  }

  let eventoDestaque = eventos.shift();

  let destaqueTitlePreview = eventoDestaque?.titulo.slice(0, 40);
  if (eventoDestaque?.titulo.length > 40) destaqueTitlePreview += " (...)";
  let destaqueTextPreview = eventoDestaque?.descricao
    .replace(/<(h2|h3|img|b|strong)[^>]*>/g, "<p>")
    .slice(0, 120);
  if (eventoDestaque?.descricao.length > 120) destaqueTextPreview += " (...)";

  eventos?.sort((a, b) => parseInt(a.ordem) - parseInt(b.ordem));

  let destaqueTitlePreview2 = eventos[0]?.titulo.slice(0, 40);
  if (eventos[0]?.titulo.length > 40) destaqueTitlePreview2 += " (...)";
  let destaqueTextPreview2 = eventos[0]?.descricao
    .replace(/<(h2|h3|img|b|strong)[^>]*>/g, "<p>")
    .slice(0, 120);
  if (eventos[0]?.descricao.length > 120) destaqueTextPreview2 += " (...)";

  return (
    <Wrapper>
      <Container className="content">
        <PageTitle
          title="Bolsas e Prémios"
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "SPAP",
              link: "/spap",
            },
            {
              text: "Bolsas e Prémios",
            },
          ]}
          imageDesktop={require("../../../Assets/Images/img-bolsas-2022@2x.png")}
          imageMobile={require("../../../Assets/Images/img-bolsas-2022.png")}
          imageAlt="Bolsas e Prémios"
        />
        <FlexInRow gap="50px">
          {eventos?.length > 0 && (
            <>
              <FlexInColumn gap="70px" mobile="column">
                <CardPicture width="41.250vw">
                  <source
                    srcSet={eventoDestaque?.url}
                    media="(min-width: 990px)"
                  />
                  <img src={eventoDestaque?.url} alt={eventoDestaque.titulo} />
                </CardPicture>
                <FlexInRow gap="0">
                  <p className="item__date">
                    <FontAwesomeIcon icon={faCalendarDay} />
                    {eventoDestaque.date}
                  </p>
                  <h3 className="item__title">{destaqueTitlePreview}</h3>
                  <p className="item__text">
                    <div
                      dangerouslySetInnerHTML={{ __html: destaqueTextPreview }}
                    ></div>
                  </p>
                  <Link
                    to={"/spap/bolsas-e-premios/" + eventoDestaque?.slug}
                    className="item__cta"
                  >
                    Saber mais »
                  </Link>
                </FlexInRow>
              </FlexInColumn>

              {eventos?.length > 1 && (
                <>
                  <div className="bar"></div>

                  <FlexInColumn gap="70px" mobile="column">
                    <CardPicture width="41.250vw">
                      <source
                        srcSet={eventos[0]?.url}
                        media="(min-width: 990px)"
                      />
                      <img src={eventos[0]?.url} alt={eventos[0].titulo} />
                    </CardPicture>
                    <FlexInRow gap="0">
                      <p className="item__date">
                        <FontAwesomeIcon icon={faCalendarDay} />
                        {eventos[0].date}
                      </p>
                      <h3 className="item__title">{destaqueTitlePreview2}</h3>
                      <p className="item__text">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: destaqueTextPreview2,
                          }}
                        ></div>
                      </p>
                      <Link
                        to={"/spap/bolsas-e-premios/" + eventos[0]?.slug}
                        className="item__cta"
                      >
                        Saber mais »
                      </Link>
                    </FlexInRow>
                  </FlexInColumn>
                </>
              )}
            </>
          )}
          {eventos?.length > 2 && (
            <>
              <Title>
                Veja aqui os últimos prêmios conquistados pelos nosso sócios:
              </Title>

              <Grid>
                {eventos.slice(1, 7).map((item, index) => {
                  let titlePreview = item.titulo.slice(0, 40);
                  if (item.titulo.length > 40) titlePreview += " (...)";
                  let textPreview = item.descricao
                    .replace(/<(h2|h3|img|b|strong)[^>]*>/g, "<p>")
                    .slice(0, 80);
                  if (item.descricao.length > 80) textPreview += " (...)";
                  return (
                    <FlexInRow gap="20px" className={"item item-" + index}>
                      <CardPicture width="100%" className="item__image">
                        <source srcSet={item.url} media="(min-width: 990px)" />
                        <img src={item.url} alt={item.titulo} />
                        <div className="overlay" />
                      </CardPicture>
                      <FlexInRow gap="0">
                        <p className="item__date">
                          <FontAwesomeIcon icon={faCalendarDay} />
                          {item.date}
                        </p>
                        <h3 className="prize__title">{titlePreview}</h3>
                        <p className="prize__text">
                          <div
                            dangerouslySetInnerHTML={{ __html: textPreview }}
                          ></div>
                        </p>
                        <Link
                          to={"/spap/bolsas-e-premios/" + item.slug}
                          className="item__cta"
                        >
                          Saber mais »
                        </Link>
                      </FlexInRow>
                    </FlexInRow>
                  );
                })}
              </Grid>
            </>
          )}

          {eventos?.length > 8 && (
            <Link to="/spap/bolsas-e-premios/ver-todas" className="link">
              Ver Todos
            </Link>
          )}
        </FlexInRow>
      </Container>
    </Wrapper>
  );
}
