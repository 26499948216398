import styled from "styled-components";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Divider,
  Checkbox,
  Modal,
  Result,
  Input,
  Space,
  notification,
  Switch,
  Select,
  Row,
  Col,
  Button,
} from "antd";
const { TextArea } = Input;
import frontcss from "../../../../src/Assets/css/front.css";
import urlSlug from "url-slug";
import IconWarning from "../../../Assets/Images/Pagamentos/Icon-warning.svg";

import Container from "../../../Components/Container";
import FlexInRow from "../../../Components/FlexInRow";
import FlexInColumn from "../../../Components/FlexInColumn";
import PageTitle from "../../../Components/PageTitle";
import SidebarMenu from "../../../Components/SidebarMenu";
import RecommendedLinks from "../../../Components/RecommendedLinks";

import BgAreaSocio from "../../../Assets/Images/bg-area-de-socio@2x.png";
import PageText from "../../../Components/PageText";
import Pagination from "../../../Components/Pagination";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import TableDocuments from "../../../Components/TableDocuments";
import UserUploadPayment from "../../../Components/UserUploadPayment/UserUploadPayment";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #000000;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  width: 100%;
  margin-top: 7.813vw;

  .mb-10 {
    margin-bottom: 10px;
  }

  img {
    width: 100%;
  }

  .ant-space-item {
    color: #000;
  }

  @media (max-width: 992px) {
    margin-top: 120px;
  }

  h5 {
    text-align: left;
  }
`;

const LoginMessage = styled.span`
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;
`;

const SectionHighlightsCards = styled.section`
  padding: 3.646vw 0;

  @media (max-width: 992px) {
    .sidebar-menu {
      margin: auto;
      place-self: center;
    }
  }
`;

const Title = styled.h2`
  color: #0c1151;
  font-size: 2.083vw;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 2.5vw;
  opacity: 1;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 992px) {
    font-size: 5.556vw;
    line-height: 6.667vw;
  }
`;

const HighlightsCards = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 5.938vw;
  grid-row-gap: 4.167vw;

  .card {
    border: none;
    border-radius: 1.042vw;
    overflow: hidden;
    position: relative;
    /* max-height: 17.188vw; */
  }

  .card .item__hover {
    opacity: 0;
    transition: 0.5s ease;
    width: 100%;
    padding: 0;
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
  }

  .card:hover .item__hover {
    opacity: 1;
  }
`;

const PaymentAlert = styled.div`
  background: #fcc29b 0% 0% no-repeat padding-box;
  border-radius: 16px;
  padding: 12px 18px;
  margin-bottom: -30px;
  z-index: 9;
  width: 100%;

  * {
    text-align: left;
    font: italic normal bold 17px/21px Fira Sans;
    letter-spacing: 0px;
    color: #0c1151;
    margin: auto;
  }
`;

const StudyActions = styled.section`
  background-image: url(${BgAreaSocio});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 11.198vw 0;
  margin-top: -11.198vw;

  .title {
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    line-height: inherit;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    margin-bottom: 1.042vw;
    padding-top: 5.599vw;
  }

  .text {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 1.875vw;
    width: 53.125vw;
  }

  a {
    background: #eeeeee 0% 0% no-repeat padding-box;
    border: 3px solid #0c1151;
    border-radius: 20px;
    padding: 20px;
    opacity: 1;
    text-align: center;
    font-size: 1.042vw;
    font-weight: bold;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #0c1151;
    text-decoration: none;
    text-transform: uppercase;
    flex-direction: column;
    opacity: 1;
    width: 13.542vw;
    min-height: 10.417vw;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.938vw;
    transition: 0.3s ease;

    img {
      width: 4.583vw;
    }
  }

  a.orange {
    background: #f88436 0% 0% no-repeat padding-box;
    border: 3px solid #0c1151;
    border-radius: 20px;
    opacity: 1;
  }

  a:hover {
    color: #0c1151;
    box-shadow: 0 8px 24px #00000055;
  }

  @media (max-width: 992px) {
    .text {
    }
  }

  @media (min-width: 1920px) {
    .text {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  ${(props) =>
    props.gap
      ? `
    gap: ${props.gap};
  `
      : `
    gap: 5.208vw;
  `}

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    gap: 50px;
  }
`;

const SectionCards = styled.div`
  display: grid;
  gap: 2.604vw;
  grid-template-columns: 1fr;
  margin-top: 10px;
  margin-bottom: 30px;

  .evento-excerpt {
    height: min-content !important;
    margin-bottom: 5px;
  }

  .evento-excerpt p,
  .evento-excerpt h1 {
    font-size: 16px !important;
    color: #000;
  }

  .item {
    border: 0.156vw solid #494d7d;
    border-radius: 1.042vw;
    justify-content: space-between;
    overflow: hidden;
    display: grid;
    grid-template-columns: 2fr 1fr !important;
  }

  .item__content {
    padding: 1.042vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  // .item__image {
  //   border: none;
  //   overflow: hidden;
  //   width: 13.542vw;

  //   img {
  //     height: 100%;
  //     object-fit: cover;
  //     object-position: top;
  //   }
  // }

  .item__title {
    text-align: left;
    font-size: 16px;
    line-height: inherit;
    font-weight: bold;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    margin: 10px 0;
  }

  .item__text {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 0.729vw;
  }

  .item__cta_invoice {
    background: #c2c4d4 !important;
  }

  .item__cta_detail {
    background: #8688a8 !important;
  }

  .item__cta {
    align-items: center;
    justify-content: center;
    display: flex;
    background: #0c1151 0% 0% no-repeat padding-box;
    border-radius: 0.521vw;
    opacity: 1;
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    text-decoration: none;
    color: #ffffff;
    opacity: 1;
    width: 8.021vw;
    height: 2.396vw;
    transition: 0.3s ease;
  }

  .item__cta:hover {
    background-color: #8688a8;
    color: #e5e5e5;
  }

  .item__date {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.521vw;
  }
  .tag-evento-spap {
    background: rgb(12, 17, 81);
    color: #fff;
    font-size: 12px;
    padding: 2px 10px;
    border-radius: 4px;
  }
  .tag-evento-nispap {
    background: #000;
    color: #fff;
    font-size: 12px;
    padding: 2px 10px;
    border-radius: 4px;
  }

  .tag-evento-pago {
    background: rgb(248, 132, 54);
    color: #fff;
    font-size: 11px;
    padding: 2px 10px;
    border-radius: 10px;
  }
  .tag-evento-adquirido {
    background: #59b46e;
    color: #fff;
    font-size: 11px;
    padding: 2px 10px;
    border-radius: 10px;
  }
  .item__date svg {
    font-size: 1.302vw;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    gap: 20px;

    .item__title {
      font-size: 4.333vw;
      line-height: 5.444vw;
    }

    .item__text,
    .item__cta,
    .item__date,
    .item__date svg,
    p {
      font-size: 3.333vw;
      line-height: 4.444vw;
    }

    .item__cta {
      border-radius: 8px;
      font-size: 12px;
      line-height: 16px;
      width: 155px;
      padding: 15px 20px;
    }
  }

  @media (min-width: 1920px) {
    .item__text,
    .item__cta {
      font-size: 18px;
      line-height: 24px;
    }

    .item__date,
    .item__date svg {
      font-size: 22px;
      line-height: 29px;
    }

    .item__title {
      font-size: 24px;
      line-height: 28px;
      margin: 10px 0;
    }

    .item__cta {
      border-radius: 10px;
      padding: 15px 30px;
      width: 220px;
    }
  }
`;

var styles = {
  h6_label: {
    color: "#809FB8",
    fontFamily: "Roboto",
    fontSize: "15px",
    marginBottom: "0",
  },
  h5_label: {
    fontFamily: "Fira Sans, Condensed",
    fontSize: "18px",
    color: "#0C1151",
    marginBottom: "0",
  },
  input_class: {
    width: "100%",
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #707070",
    color: "#0C1151",
  },
  switch_label: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    height: "45px",
    borderRadius: "8px",
    padding: "0px 16px",
    color: "#809FB8",
    border: "1px solid #809FB8",
    textAlign: "center",
  },
  upload: {
    width: "100%",
  },
  colPadding: {
    padding: "0px 10px",
  },
  input_class2: {
    width: "100%",
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #ccc",
    color: "#0C1151",
  },
};

export default function SociosEventos(props) {
  const [api, contextHolder] = notification.useNotification();
  const [myEvents, setMyEvents] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [listEventosToShow, setListEventosToShow] = useState([]);

  notification.config({
    placement: "bottomRight",
    bottom: 10,
    duration: 5,
    rtl: false,
  });

  const session_storage = JSON.parse(localStorage.getItem("session_data"));

  const month = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  useEffect(() => {
    fetch(
      `https://api.spap.pt/api/events-by-user/` +
        session_storage.all_data.user_session.id,
      {
        method: "GET",
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.purchases.length == 0) {
          setIsEmpty(true);
          return;
        }
        // setIsEmpty(true);
        // return;
        handleData(data);
      })
      .catch((error) => console.error(error));
  }, []);

  const handleData = (data) => {
    let eventPurchases = data.purchases.map((a) => a.event_id);
    let myEvents = data.events.filter((event) => {
      return eventPurchases.includes(event.id);
    });
    console.log(myEvents);
    myEvents.map((evento) => {
      let purchase = data.purchases.filter((purchase) => {
        return purchase.event_id == evento.id;
      });
      let paymentDetails = data.paymentDetails.filter((detail) => {
        return detail.event_id == evento.id;
      });
      evento.detail_file = paymentDetails[0].file;
      evento.invoice_file = purchase[0].invoice_file;
      evento.slug = urlSlug(evento.titulo);

      evento.slug += "-" + evento.id;

      evento.purchased = false;
      evento.soldOut = false;
      evento.isSpap = false;
      const [dateInicioValues, timeInicioValues] =
        evento.data_inicio.split(" ");
      const [dayInicio, monthInicio, yearInicio] = dateInicioValues.split("/");
      const [dateFechoValues, timeFechoValues] = evento.data_fecho.split(" ");
      const [dayFecho, monthFecho, yearFecho] = dateFechoValues.split("/");

      evento.dataAOrdenar = monthInicio + "/" + dayInicio + "/" + yearInicio;

      if (evento.is_spap != 0) {
        evento.isSpap = true;
      }

      const monthInicioToString = month[Number(monthInicio - 1)];
      const monthFechoToString = month[Number(monthFecho - 1)];
      evento.monthInicio = monthInicio;
      evento.yearInicio = yearInicio;
      evento.url = evento.imagem_destaque
        ? "https://api.spap.pt/eventos/" +
          evento.id +
          "/" +
          evento.imagem_destaque
        : "https://spap.pt/clubes/clube-estudo-default.svg";

      if (
        monthInicio == monthFecho &&
        yearInicio === yearFecho &&
        dayInicio === dayFecho
      ) {
        evento.date = dayInicio + " " + monthInicioToString + " " + yearInicio;
      } else if (monthInicio == monthFecho && yearInicio === yearFecho) {
        evento.date =
          dayInicio +
          "-" +
          dayFecho +
          " " +
          monthInicioToString +
          " " +
          yearInicio;
      } else if (
        dayInicio == dayFecho &&
        monthInicio == monthFecho &&
        yearInicio === yearFecho
      ) {
        evento.date = dayInicio + " " + monthInicioToString + " " + yearInicio;
      } else if (monthInicio != monthFecho) {
        evento.date =
          dayInicio +
          " " +
          monthInicioToString +
          " a " +
          dayFecho +
          " " +
          monthFechoToString +
          " " +
          yearFecho;
      }
    });

    console.log(myEvents);

    setMyEvents(myEvents);
  };

  const removeHTMLTags = (htmlString) => {
    // Create a new DOMParser instance
    const parser = new DOMParser();
    // Parse the HTML string into a DOM document
    const doc = parser.parseFromString(htmlString, "text/html");
    // Extract the text content from the parsed document
    const textContent = doc.body.textContent || "";
    return textContent.trim(); // Trim any leading or trailing whitespace
  };

  const SidebarMenuLinks = [
    {
      title: "Área pessoal",
      link: "/socios/area-de-socio",
    },
    {
      title: "Dados de perfil",
      link: "/socios/dados-de-perfil/",
    },
    {
      title: "Quotas",
      link: "/socios/quotas",
    },
    {
      title: "Pagamentos",
      link: "/socios/pagamentos",
    },
    {
      title: "Meus Eventos",
      link: "/socios/meus-eventos",
    },
    {
      title: "Mensagens",
      link: "/socios/mensagens",
    },
  ];

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <Wrapper>
      <Container>
        <Row gutter={[24]}>
          <Col xl={19} xs={24}>
            <PageTitle
              title="Meus eventos"
              path={[
                {
                  text: "Homepage",
                  link: "/",
                },
                {
                  text: "Sócios",
                  link: "/socios",
                },
                {
                  text: "Área de sócio",
                  link: "/socios/area-de-socio",
                },
                {
                  text: "Meus eventos",
                },
              ]}
              imageDesktop={require("../../../Assets/Images/img-certificados@2x.png")}
              imageMobile={require("../../../Assets/Images/img-certificados@2x.png")}
              imageAlt="Sócios - Certificados"
              // sidebar={<SidebarMenu navItens={SidebarMenuLinks} alert={1} margin={'-60px'} />}
            />
            {isEmpty == false ? (
              <>
                <SectionCards>
                  {myEvents.map((item, index) => {
                    let titlePreview = item.titulo.slice(0, 60);
                    if (item.titulo.length > 60) titlePreview += " (...)";
                    let textPreview = removeHTMLTags(item.descricao).slice(
                      0,
                      120
                    );
                    if (item.descricao.length > 120) textPreview += " (...)";
                    return (
                      <div className={"item item-" + index}>
                        <div className="item__content">
                          <div className="mb-10">
                            <div className="item__date">
                              <FontAwesomeIcon icon={faCalendarDay} />
                              {item.date}
                              {item.purchased && (
                                <>
                                  <span className="tag-evento-adquirido hide-event">
                                    ESTE EVENTO JÁ FOI ADQUIRIDO
                                  </span>
                                </>
                              )}
                              {!item.purchased && item.soldOut && (
                                <>
                                  <span className="tag-evento-pago hide-event">
                                    ESTE EVENTO ENCONTRA-SE ESGOTADO
                                  </span>
                                </>
                              )}
                              {item.is_pago && !item.purchased && (
                                <>
                                  <span className="tag-evento-pago hide-event">
                                    EVENTO PAGO
                                  </span>
                                </>
                              )}
                            </div>
                            <h3 className="item__title">{titlePreview}</h3>
                            <div
                              className="evento-excerpt"
                              dangerouslySetInnerHTML={{ __html: textPreview }}
                            ></div>
                            {!item.isSpap ? (
                              <>
                                <span className="tag-evento-nispap">
                                  NISPAP
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="tag-evento-spap">SPAP</span>
                              </>
                            )}
                          </div>
                          <div>
                            <Space>
                              <Link
                                to={"/agenda/evento/" + item.slug}
                                className="item__cta"
                              >
                                Saber mais »
                              </Link>
                              {item.detail_file?.length > 0 && (
                                <Link
                                  target="_blank"
                                  to={
                                    "https://api.spap.pt/eventos/" +
                                    item.id +
                                    "/payment/" +
                                    item.detail_file
                                  }
                                  className="item__cta item__cta_detail"
                                >
                                  Programa
                                </Link>
                              )}
                              {item.invoice_file?.length > 0 && (
                                <Link
                                  target="_blank"
                                  to={
                                    "https://api.spap.pt/eventos/" +
                                    item.id +
                                    "/purchases/" +
                                    item.invoice_file
                                  }
                                  className="item__cta item__cta_invoice"
                                >
                                  Ver recibo
                                </Link>
                              )}
                            </Space>
                          </div>
                        </div>
                        <div
                          className="item__image"
                          style={{
                            backgroundImage: "url(" + item.url + ")",
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                          }}
                        >
                          {/* <picture style={{ opacity: "0" }}>
                        <source srcSet={item.url} media="(min-width: 990px)" />
                        {/* <img src={item.url} alt={item.alt} />
                      </picture> 
                      */}
                        </div>
                      </div>
                    );
                  })}
                </SectionCards>
              </>
            ) : (
              <>
                <div style={{ paddingBottom: "60px" }}>
                  <PaymentAlert
                    style={{
                      marginBottom: "30px",
                    }}
                  >
                    <Space size={18}>
                      <img
                        src={IconWarning}
                        alt="Pagamento pendente"
                        style={{ width: "26px" }}
                      />
                      <p>Você não adquiriu nenhum evento.</p>
                    </Space>
                  </PaymentAlert>
                </div>
              </>
            )}
          </Col>
          <Col xl={5} xs={24}>
            <SidebarMenu navItens={SidebarMenuLinks} alert={1} />
          </Col>
        </Row>
      </Container>
      <RecommendedLinks
        title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
        links={[
          {
            title: "Lista de sócios",
            link: "/socios/lista-de-socios",
          },
          {
            title: "Pedir uma 2ª opinião",
            link: "/ferramentas-uteis/pedir-uma-segunda-opiniao",
          },
          {
            title: "Área pessoal",
            link: "/socios/area-de-socio",
          },
          {
            title: "Contactos",
            link: "/spap/contactos",
          },
        ]}
      />
    </Wrapper>
  );
}
