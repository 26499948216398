import styled from "styled-components";

import Container from "../../../../Components/Container";
import FlexInRow from "../../../../Components/FlexInRow";
import FlexInColumn from "../../../../Components/FlexInColumn";
import PageTitle from "../../../../Components/PageTitle";
import PageText from "../../../../Components/PageText";
import SidebarMenu from "../../../../Components/SidebarMenu";
import Congress from "../../../../Components/HistoriaCongress";
import { Helmet } from "react-helmet";
import { Image } from "antd";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin: 7.813vw 0 4.792vw;

  > .content {
    padding-bottom: 4vw;
  }

  .image {
    margin-left: auto;
    max-width: 32.292vw;
  }

  img {
    width: 100%;
  }

  p {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0;
  }

  .small {
    text-align: left;
    font-size: 0.729vw;
    line-height: 0.989vw;
    letter-spacing: 0px;
    color: #000000;
  }

  ul {
    padding-left: 1rem;
  }

  li {
    text-align: left;
    font-size: 1.354vw;
    font-weight: medium;
    line-height: 1.771vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .ant-image-mask-info {
    display: none;
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    p {
      font-size: 3.333vw;
      line-height: 4.444vw;
    }

    .small {
      font-size: 12px;
      line-height: 16px;
    }
  }

  @media (min-width: 1920px) {
    p {
      font-size: 18px;
      line-height: 24px;
    }

    .small {
      font-size: 14px;
      line-height: 19px;
    }
  }
`;

const GridMainPicture = styled.div`
  display: grid;
  grid-template-columns: 50% 1fr;
  gap: 20px;

  #mainPicture {
    display: grid;
    gap: 20px;
  }

  @media (min-width: 992px) {
    #mainPicture {
      grid-template-columns: auto auto;

      .image-01 {
        width: 26.458vw;
      }

      .image-02 {
        width: 13.489vw;
      }
    }

    #mainPicture img {
      height: auto;
    }
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;

    ${(props) =>
      props.reverse &&
      `  
      display: flex;
      flex-direction: column-reverse;
    `}
  }

  @media (min-width: 1920px) {
    #mainPicture {
      grid-template-columns: 1.5fr 1fr;

      .image-01 {
        width: 508px;
      }

      .image-02 {
        width: 259px;
      }

      #mainPicture img {
        height: 356px;
      }
    }
  }
`;

export default function Congressos(props) {
  const SidebarMenuLinks = [
    {
      title: "História",
      link: "/spap/historia",
    },
    {
      title: "Presidentes",
      link: "/spap/presidentes",
    },
    {
      title: "Congressos...",
      link: "/spap/congressos",
    },
  ];

  return (
    <Wrapper>
      <Helmet>
        <title>
          Reuniões, encontros e Congressos | Sociedade Portuguesa de Anatomia
          Patológica - SPAP
        </title>
        <meta
          name="description"
          content="Como já se recordou foi da Sociedade Anatómica Portuguesa que nasceu a Sociedade de Anatomia Patológica. Durante muitos anos as nossas comunicações foram ali integradas e numa dessas reuniões o volume dessas comunicações era tal que permitiu reunirmo-nos numa sala à parte, no Hospital de Santa Maria..."
        />
        <meta
          name="keywords"
          content="spap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      <FlexInRow gap="4.792vw">
        <Container className="content">
          <PageTitle
            title="REUNIÕES, ENCONTROS E CONGRESSOS"
            path={[
              {
                text: "Homepage",
                link: "/",
              },
              {
                text: "SPAP",
                link: "/spap",
              },
              {
                text: "História",
                link: "/spap/historia",
              },
              {
                text: "Congressos",
              },
            ]}
            imageDesktop={require("../../../../Assets/Images/img-titulo-historia@2x.png")}
            imageMobile={require("../../../../Assets/Images/img-titulo-historia@2x.png")}
            imageAlt="Congressos"
            sidebar={
              <SidebarMenu navItens={SidebarMenuLinks} className="sidebar" />
            }
          />

          <FlexInRow gap="40px">
            <FlexInColumn gap="126px" mobile="column">
              <FlexInRow gap="10px">
                <p>
                  <i>
                    “Como já se recordou foi da Sociedade Anatómica Portuguesa
                    que nasceu a Sociedade de Anatomia Patológica. Durante
                    muitos anos as nossas comunicações foram ali integradas e
                    numa dessas reuniões o volume dessas comunicações era tal
                    que permitiu reunirmo-nos numa sala à parte, no Hospital de
                    Santa Maria. Foi o grito de Ipiranga. Embora muitos de nós
                    continuemos, e com muita hora, ligados à Sociedade
                    Anatómica, foi a partir desse facto que se desencadeou o
                    processo de criação da nossa Sociedade. Nasceram enfim os
                    Estatutos e seguiram-se ao longo destes anos sucessivas
                    direcções e diversas iniciativas.”
                  </i>
                </p>
                <p className="small">
                  <b>Autoria:</b> Prof. Renato Trincão, excerto de: Sociedade
                  Portuguesa de Anatomia Patológica: 25 anos de existência;
                  Arquivos de Patologia Geral e Anatomia Patológica; Imprensa de
                  Coimbra, 1989.
                </p>
              </FlexInRow>
            </FlexInColumn>
            <GridMainPicture>
              <FlexInRow gap="40px">
                <p>
                  Depois da inauguração em 1963, a primeira reunião realizou-se
                  em Março de 1964 em Lisboa, e a segunda reunião realizou-se em
                  Novembro de 1964 no Porto (descrição do Prof. Daniel Serrão).
                  Isto significa que a decisão de três reuniões anuais (Lisboa,
                  Coimbra e Porto) começou logo por não ser cumprida no primeiro
                  ano.
                </p>
                <p>
                  A minha <i>“primeira”</i> reunião da SPAP realizou-se no
                  Porto, em Junho de 1973, e refere que foi a 16ª reunião da
                  SPAP. A minha <i>“segunda”</i> reunião da SPAP foi a 21º no
                  Porto, em Abril de 1976 e a terceira foi a 26º no Porto, em
                  Dezembro de 1978. Penso que a I Reunião Luso-Espanhola de
                  Anatomia Patológica terá sido em Madrid em 1970 (a minha
                  primeira reunião Luso-Espanhola da AP foi em Lisboa em Outubro
                  de 1971).
                </p>
                <p>
                  Depois, a III Reunião Luso-Espanhola de AP foi em Sevilha, em
                  Outubro de 1972. A IV Reunião Luso-Espanhola AP foi em Viana
                  do Castelo, em Outubro de 1974. A V Reunião Luso-Espanhola de
                  AP – talvez tenha correspondido ao I Congresso Luso-Hispano
                  Brasileiro que decorreu Dezembro de 1980 - a tal do desastre
                  aéreo com Francisco Sá Carneiro. A VII Reunião Luso Espanhola
                  AP, foi em Madrid em Outubro de 1984. Não encontrei vestígios
                  de alguma reunião Luso-Hispano-Alemã mas penso que existiu e
                  terei até participado.
                </p>
                <p className="small">
                  <b>Autoria:</b> Prof. Sobrinho-Simões
                </p>
              </FlexInRow>
              <div id="mainPicture">
                <FlexInRow gap="20px">
                  <Image
                    className="image-01"
                    src={require("../../../../Assets/Images/img-congresso-01@2x.png")}
                    alt="Congresso"
                  />
                  <p className="small">
                    Jornadas Luso-Galaicas, Setembro de 1970, Coimbra
                  </p>
                </FlexInRow>
                <FlexInRow gap="20px">
                  <Image
                    className="image-02"
                    src={require("../../../../Assets/Images/img-congresso-032@2x.png")}
                    alt="Jornadas Luso-Galaicas, Setembro de 1970, Coimbra"
                  />
                </FlexInRow>
              </div>
            </GridMainPicture>

            <GridMainPicture reverse={true}>
              <FlexInRow gap="20px">
                <FlexInColumn gap="16px">
                  <Image
                    src={require("../../../../Assets/Images/img-congresso-03@2x.png")}
                    alt="Livro de Resumos do VII Encontro Anual dos Patologistas Portugueses e Reunião Anual da Sociedade Portuguesa de Citologia, Tomar, 23 a 25 de Novembro de 1995"
                  />
                  <Image
                    src={require("../../../../Assets/Images/img-congresso-04@2x.png")}
                    alt="Livro de Resumos do VII Encontro Anual dos Patologistas Portugueses e Reunião Anual da Sociedade Portuguesa de Citologia, Tomar, 23 a 25 de Novembro de 1995"
                  />
                </FlexInColumn>
                <p className="small">
                  Livro de Resumos do VII Encontro Anual dos Patologistas
                  Portugueses e Reunião Anual da Sociedade Portuguesa de
                  Citologia, Tomar, 23 a 25 de Novembro de 1995
                </p>
              </FlexInRow>
              <FlexInRow gap="40px">
                <p>
                  A partir de 1989 a SPAP designou a sua reunião anual por{" "}
                  <i>“Encontro Nacional dos Patologistas Portugueses”</i> e o
                  Luso foi o local onde se realizaram muitos e inolvidáveis
                  encontros com boa qualidade científica e, também, com
                  programas sociais e de animação excelentes. Nunca mais
                  esqueceremos o Prof. Matos Beja a fazer de Ribeirinho e a
                  dançar com a artista que havia contracenado com o verdadeiro
                  Ribeirinho num filme dos anos 40 que se chamava O Pátio das
                  Cantigas.
                </p>
                <p>
                  Mas em 1997 a Prof. Isabel Soares era a secretária da SPAP e
                  eu o Presidente. Nesse ano ela sugeriu que se acabasse com
                  designação{" "}
                  <i>“Encontro Nacional dos Patologistas Portuguesas”</i> e
                  passássemos a usar <i>“Congresso Nacional”</i>{" "}
                  <i>
                    “ E foi assim que em Tomar, de 17 a 19 de Abril de 1997 se
                    realizou o 2º Congresso Nacional de Anatomia Patológica.
                  </i>
                </p>
                <p className="small">
                  <b>Autoria:</b> Prof. Carlos Lopes
                </p>
              </FlexInRow>
            </GridMainPicture>
          </FlexInRow>
        </Container>

        <Congress />
      </FlexInRow>
    </Wrapper>
  );
}
