import styled from "styled-components"
import Container from "../../Container"

import ImageRal from '../../../Assets/Images/Footer/RAL.png'
import ImageAnacom from '../../../Assets/Images/Footer/Anacom.png'
import ImageReclamacoes from '../../../Assets/Images/Footer/Livro-Reclamacoes.png'

const Wrapper = styled.section`
  padding: 60px 0;

  >.content {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 54px;
  }

  @media (max-width: 992px) {
    >.content {
      grid-template-columns: 1fr;
      gap: 30px;
    }
  }
`

const Card = styled.div`
  display: grid;
  grid-template-areas:
  "image title"
  "image text"
  "image button";
  grid-column-gap: 30px;
  grid-row-gap: 10px;

  .card__image {
    grid-area: image;
    width: 120px;
    place-self: flex-start flex-end;
  }

  .card__title {
    grid-area: title;
    text-align: left;
    font: normal normal bold 20px/24px Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    place-self: flex-start;
    margin: 0;
  }

  .card__text {
    grid-area: text;
    text-align: left;
    font: normal normal normal 12px/14px Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    place-self: flex-start;
    margin: 0;
  }

  .card__button {
    align-items: center;
    justify-content: center;
    grid-area: button;
    text-align: left;
    font: normal normal normal 14px/17px Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    display: inline-flex;
    text-decoration: none;
    place-self: flex-start;
    transition: .3s ease-out;
    text-decoration: underline;

    :hover {
      color: #FEFEFE;
    }
  }

  @media (max-width: 1920px) and (min-width: 992px) {
    .card__image {
      width: 120px;
    }
   
    .card__title {
      font-size: 14px;
      line-height: 18px;
    }
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    grid-template-areas:
    "title"
    "image"
    "text"
    "button";
    grid-row-gap: 20px;
  }
`

export default function FooterCards () {
  return (
    <Wrapper>
      <Container className='content'>
        <Card>
          <img src={ImageRal} className="card__image" />
          <h3 className="card__title">Centro de Arbitragem e RAL</h3>
          <p className="card__text">(Resolução Alternativa de Litígios de Consumo)</p>
          <a href="https://www.consumidor.gov.pt/parceiros/sistema-de-defesa-do-consumidor/entidades-de-resolucao-alternativa-de-litigios-de-consumo.aspx" target="_blank" className="card__button">Saber mais »</a>
        </Card>
        <Card>
          <img src={ImageAnacom} className="card__image" />
          <h3 className="card__title">Direito de livre resolução ou direito ao arrependimento</h3>
          <p className="card__text">Direito legal reconhecido aos consumidores (pessoas singulares que utilizam os serviços para fins...</p>
          <a href="https://www.anacom-consumidor.pt/-/sabe-o-que-e-o-direito-de-arrependimento-que-lhe-permite-cancelar-um-contrato-celebrado-no-seu-domicilio-atraves-de-um-agente-porta-a-porta-" target="_blank" className="card__button">Saber mais »</a>
        </Card>
        <Card>
          <img src={ImageReclamacoes} className="card__image" />
          <h3 className="card__title">Livro de Reclamações</h3>
          <p className="card__text">Um instrumento de cidadania legalmente consagrado: Fazer reclamação, Pedir informação, consultar reclamação e Elogiar ou sugerir</p>
          <a href="https://www.consumidor.gov.pt/livro-de-reclamacoes.aspx" target="_blank" className="card__button">Saber mais »</a>
        </Card>
      </Container>
    </Wrapper>
  )  
}