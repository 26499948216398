import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ReactGA from "react-ga4";

// const TRACKING_ID = "UA-133416812-1"; // OUR_TRACKING_ID
const TRACKING_ID = "G-4NC181KTL5"; // OUR_TRACKING_ID

ReactGA.initialize(TRACKING_ID);
ReactGA.send({ hitType: "pageview", page: window.location.pathname });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  <App />
);
reportWebVitals();
