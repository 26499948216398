import { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Card from "../Card";
import Title from "../Title";
import { ReactSortable } from "react-sortablejs";
import urlSlug from "url-slug";
import QuillResizeImage from "quill-resize-image";

import {
  Button,
  Divider,
  Space,
  Switch,
  Upload,
  Input,
  Radio,
  Select,
  Drawer,
  Col,
  Modal,
  Row,
  DatePicker,
  notification,
  Tag,
} from "antd";
import CardList from "../CardList";
import { ReactComponent as IconOk } from "../../../Assets/Images/Backoffice/Ok.svg";
import { ReactComponent as IconCalendar } from "../../../Assets/Images/Backoffice/Calendar.svg";
import { ReactComponent as IconClose } from "../../../Assets/Images/Backoffice/Close.svg";
import { ReactComponent as IconEdit } from "../../../Assets/Images/Backoffice/Edit.svg";
import { ReactComponent as IconHours } from "../../../Assets/Images/Backoffice/Hours.svg";
import { ReactComponent as IconUpload } from "../../../Assets/Images/Backoffice/Upload.svg";

import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
Quill.register("modules/imageResize", ImageResize);
import dayjs from "dayjs";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
import { EyeOutlined, MailOutlined } from "@ant-design/icons";
import axios, { all } from "axios";

const { RangePicker } = DatePicker;
const { Dragger } = Upload;
const { TextArea } = Input;

// import { useDrag } from 'react-dnd'
// import { ItemTypes } from './Constants'

const WrapperCardList = styled.section`
  // display: flex;
  width: 100% !important;
  flex-direction: row;

  .p-body > p {
    margin-bottom: 0 !important;
  }

  p {
    color: #c2c4d4;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 20px;
    margin-bottom: 0% !important;
    text-align: left;
  }

  .text {
    color: #809fb8;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0px;
    margin-bottom: 0px !important;
  }

  .text-small {
    color: #809fb8;
    text-align: left;
    font-size: 12px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0px;
  }

  .css-dev-only-do-not-override-j0nf2s .text {
    margin-bottom: 0 !important;
  }

  .icon-close {
    cursor: pointer;
  }

  .action-edit {
    cursor: pointer;
    color: #494d7d;
  }

  .flex-wrap {
    flex-wrap: wrap !important;
  }
`;

const WrapperCardEmail = styled.section`
  display: flex;
  flex-direction: column;

  .grid {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 20px;
  }

  .icon {
    width: 25px;
  }

  .size-eye svg {
    width: 25px;
  }

  .ant-upload-btn {
  min-height: 190px;
}

  .ant-radio-button-wrapper {
    height: 45px;
    border: 1px solid rgb(128, 159, 184);
  }

  .ant-radio-button-wrapper span {
    line-height: 45px; 
    font-size: 14px;s
  }
  .text {
    margin-bottom: 0% !important;
  }

  h4 {
    text-align: center;
    font: normal normal bold 20px/20px Roboto;
    letter-spacing: 0px;
    color: #050505;
  }
  

  .text {
    color: #809FB8;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0px;
  }

  .center {
    text-align: center;
  }

  .ant-upload-wrapper .ant-upload-drag {
    max-height: -webkit-fill-available;
    height: 91%;
    display: flex;
    justify-content: center;
  }

  .ant-upload-wrapper .ant-upload-drag .ant-upload-btn  {
    height: -webkit-fill-available;
  }

  .div-sortable {
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
  }

  .div-sortable .ant-col {
    padding: 5px;
  }

  .div-Email{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .cta {
    display: block !important;
    font-size: 15px;
    font-weight: bold;
    height: 45px !important;
    line-height: 18px;

    i { margin: 0 3px; }

    a { text-decoration: none; }
  }

  


  .cta.publish {
    margin-right: 0;

    svg path {
      fill: #FFF;
    }
  }
`;

const ContentFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    display: block !important;
    font-size: 15px;
    font-weight: bold;
    height: 45px;
    line-height: 18px;

    i {
      margin: 0 3px;
    }
  }

  a {
    text-decoration: none;
  }
`;

var styles = {
  h6_label: {
    color: "#809FB8",
    fontFamily: "Roboto",
    fontSize: "15px",
    marginTop: "1%",
  },
  input_class_disabled: {
    marginBottom: "2%",
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
    opacity: 0.6,
    pointerEmails: "none",
  },
  input_class: {
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
    width: "100%",
  },
  select_class: {
    borderRadius: "6px",
    height: "45px",
    color: "#0C1151",
    width: "100%",
  },
  textarea_class: {
    borderRadius: "6px",
    border: "1px solid #809FB8",
    color: "#0C1151",
  },
  switch_label: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    height: "45px",
    borderRadius: "8px",
    padding: "0px 16px",
    color: "#809FB8",
    border: "1px solid #809FB8",
    textAlign: "center",
  },
  upload: {
    width: "100%",
  },
};

const CardHeader = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto auto;
`;

// Quill.register("modules/resize", window.QuillResizeImage);

export default function CardEmails(props) {
  const session_storage = JSON.parse(localStorage.getItem("session_data"));

  const [fileEmailList, setfileEmailList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [tipoEmail, setTipoEmail] = useState("");
  const [descricaoEmail, setBodyEmail] = useState("");
  const [subjectEmail, setSubjectEmail] = useState("");
  const [showEmailDrawer, setShowEmailDrawer] = useState(false);
  // editar Email
  const [fileEmailListEdit, setfileEmailListEdit] = useState([]);
  const [EmailIdEdit, setEmailIdEdit] = useState("");
  const [descricaoEmailEdit, setBodyEmailEdit] = useState("");
  const [subjectEmailEdit, setSubjectEmailEdit] = useState("");
  const [imagemDestaqueEdit, setImagemDestaqueEdit] = useState("");
  const [emailToShow, setEmailToShow] = useState([]);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [emailTest, setEmailTest] = useState(false);

  const [allItems, setAllItems] = useState([]);
  const [clubes, setClubes] = useState([]);
  const [clube, setClube] = useState([]);
  const [emails, setEmails] = useState([]);
  const [selectDisabled, setSelectDisabled] = useState(false);

  const handleEmails = (allEmails, allClubes) => {
    let emailsToShow = [];
    allEmails?.map((email) => {
      if (email != null) {
        let criadoEm = new Date(email.created_at);
        email.created_at =
          criadoEm.toLocaleDateString() + " " + criadoEm.toLocaleTimeString();
        email.clubeName = '';
        if (email.club_id) {
          let clube = allClubes.filter((clube) => clube.id == email.club_id);
          if (clube) {
            email.clubeName = clube[0].name;
          }
        }
      }
    });

    setEmails(
      allEmails.sort((a, b) => {
        return b.id - a.id;
      })
    );
  };

  useEffect(() => {
    fetch(`https://api.spap.pt/api/get-emails`, {
      method: "GET",
    })
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          throw new Error("Erro ao buscar emails");
        }
        return response.json();
      })
      .then((data) => {
        setClubes(data.clubes);
        handleEmails(data.emails, data.clubes);
      })
      .catch((error) => console.error(error));
  }, []);

  const [currentItemsEmail, setCurrentItemsEmail] = useState(
    allItems.slice(0, 2)
  );

  const [itemOffsetEmail, setItemOffsetEmail] = useState(0);
  const itemsPerPageEmail = 2;

  const handlePageEmail = (page) => {
    const newOffset = ((page - 1) * itemsPerPageEmail) % allItems.length;
    setItemOffsetEmail(newOffset);
  };

  useEffect(() => {
    const endOffset = itemOffsetEmail + itemsPerPageEmail;
    setCurrentItemsEmail(allItems.slice(itemOffsetEmail, endOffset));
  }, [itemOffsetEmail, allItems]);

  notification.config({
    placement: "bottomRight",
    bottom: 10,
    duration: 5,
    rtl: false,
  });

  const clickToEmailDrawer = (item) => {
    setEmailIdEdit(item.id);
    setBodyEmailEdit(item.descricao);
    setSubjectEmailEdit(item.titulo);
    setImagemDestaqueEdit(item.imagem_destaque);
    setShowEmailDrawer(true);
  };

  const propEmail = {
    onRemove: (file) => {
      const index = fileEmailList.indexOf(file);
      const newfileEmailList = fileEmailList.slice();
      newfileEmailList.splice(index, 1);
      setfileEmailList(newfileEmailList);
    },
    beforeUpload: (file) => {
      setfileEmailList([...fileEmailList, file]);
      return false;
    },
    fileEmailList,
  };

  const t = 1;
  const data = [];

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],

      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] },
      ],
      ["link", "image"],

      ["clean"], // remove formatting button
    ],
    imageResize: {
      // parchment: Quill.import('parchment'),
      modules: ["Resize", "DisplaySize"],
    },
  };

  const formatEmails = [
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];

  const handleEmail = (e) => {
    if (subjectEmail.length == 0 || descricaoEmail.length == 0) {
      notification.error({
        description: "Para criar um novo email, preencha todos os campos.",
      });
      return;
    }

    const formData = new FormData();

    console.log(clube);
    if (clube == undefined) {
      setClube([]);
    }

    formData.append("subject", subjectEmail);
    formData.append("file", fileEmailList[0]);
    formData.append("body", descricaoEmail);
    formData.append("draft", emailTest);
    formData.append("clubId", clube);

    notification.info({
      description: "O email vai ser enviado dentro de breves momentos.",
    });

    // You can use any AJAX library you like
    fetch("https://api.spap.pt/api/admin/send-email", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            setSubjectEmail("");
            setBodyEmail("");
            setClube([]);
            setfileEmailList([]);
            setEmailTest(false);
            handleEmails(data.emails);
            notification.success({
              description: "O email foi enviado com sucesso!",
            });
          })
          .catch((err) => {});
      })
      .then(() => {})
      .catch(() => {})
      .finally(() => {});
  };

  const propEmailEdit = {
    onRemove: (file) => {
      fileEmailListEdit([]);
    },
    beforeUpload: (file) => {
      const isImage =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml";
      if (!isImage) {
        notification.error({
          description: "Apenas é possível fazer upload de imagens.",
        });
        setfileEmailListEdit((current) => []);
        return Upload.LIST_IGNORE;
      }
      setfileEmailListEdit([...fileEmailListEdit, file]);
    },
    fileEmailListEdit,
  };

  const openShowEmailModel = (item) => {
    setEmailToShow(item);
    setEmailModalOpen(true);
  };

  const onChangeEmailTest = (value) => {
    if (value == true) {
      setClube([]);
      setSelectDisabled(true);
    } else {
      setSelectDisabled(false);
    }
    setEmailTest(value);
  };

  return (
    <WrapperCardEmail>
      <Card>
        <CardHeader>
          <Row justify={"space-between"}>
            <Col>
              <Space direction="vertical" size={5}>
                <Title>Comunicação via email aos sócios</Title>
                <p className="text">
                  Poderá gerar um novo email para todos os sócios inscritos na
                  plataforma preenchendo os dados a baixo (ficheiro opcional)
                </p>
              </Space>
            </Col>
            <Col>
              <span className="text">Email de teste</span>{" "}
              {emailTest == true ? (
                <Switch defaultChecked onChange={(e) => onChangeEmailTest(e)} />
              ) : (
                <Switch onChange={(e) => onChangeEmailTest(e)} />
              )}
            </Col>
          </Row>
        </CardHeader>
        <Divider />
        <Row gutter={[12, 18]} align="bottom">
          <Col span={16}>
            <label className="text">Assunto</label>
            <Input
              key={"titulo-Email"}
              placeholder="Assunto"
              name="subjectEmail"
              style={styles.input_class}
              value={subjectEmail}
              onChange={(e) => setSubjectEmail(e.target.value)}
            />
          </Col>
<Col span={8}>
                <Select
                  style={{
                    width: "100%",
                    maxHeight: "45px",
                    height: "45px",

                  }}
                  onChange={(e) => setClube(e)}
                  name="clube"
                  placeholder="Selecione um clube de estudo"
                  value={clube}
                  showSearch
                  optionFilterProp="children"
                  required
                  allowClear
                  disabled={selectDisabled}
                >
                  {Array.isArray(clubes) &&
                    clubes.map((clube) => (
                      <Select.Option value={clube.id}>
        
                        {clube.name}
                      </Select.Option>
                    ))}
                </Select>
                </Col>
        </Row>
        <Row gutter={[12, 12]} style={{ marginTop: "10px" }} align={"top"}>
          <Col span={6}>
            <p className="text" style={{ marginBottom: "0px" }} align="center">
              Tamanho máximo de upload (10 MB)
            </p>
            <Dragger
              {...propEmail}
              style={{ height: "100%" }}
              fileList={[...fileEmailList]}
            >
              <p className="ant-upload-drag-icon">
                <IconUpload />
              </p>
              <p className="ant-upload-text text">
                Upload da imagem da apresentação
              </p>
              <p className="ant-upload-hint">Faça Drag and drop aqui</p>
            </Dragger>
          </Col>
          <Col span={18}>
            <label className="text" style={{ marginBottom: "0px" }}>
              Mensagem
            </label>

            <ReactQuill
              key={"descricao-Email"}
              placeholder="Mensagem"
              modules={modules}
              formats={[
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "list",
                "bullet",
                "indent",
                "link",
                "image",
              ]}
              className="desc-p"
              theme="snow"
              name="descricao"
              onChange={(e) => setBodyEmail(e)}
              value={descricaoEmail}
            />
          </Col>
        </Row>
        <Divider style={{ margin: "8px 0" }} />
        <Row justify={"end"}>
          <Button
            onClick={handleEmail}
            type="primary"
            className="cta publish"
            align="end"
          >
            Enviar email
          </Button>
        </Row>
      </Card>
      <WrapperCardList>
        <Divider>Emails</Divider>
        {emails?.length == 0 && (
          <p style={{ textAlign: "center" }}>Não existem emails enviados.</p>
        )}
        {emails?.length > 0 && (
          <>
            <Row gutter={[16, 16]}>
              {emails.map((item, index) => {
                let textPreview = item.body
                  .replace(/<(h2|h3|img|b|strong)[^>]*>/g, "<p>")
                  .slice(0, 100);
                if (item.body.length > 120) textPreview += " (...)";
                return (
                  <Col span={12}>
                    <Card
                      key={"emails-" + item.id}
                      className="div-Email"
                      style={{ height: "100%" }}
                    >
                      <div>
                        <CardHeader>
                          <Title>{item.subject}</Title>
                          <EyeOutlined
                            className="icon icon-close"
                            onClick={() => openShowEmailModel(item)}
                          />
                        </CardHeader>
                        <div
                          style={{ fontSize: "12px", lineHeight: "1.4" }}
                          dangerouslySetInnerHTML={{ __html: textPreview }}
                        ></div>
                      </div>
                      <Space direction="vertical" style={{ width: "100%" }}>
                        <Space
                          size={5}
                          type={"flex"}
                          style={{
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          { item.clubeName != '' && 
                          <Tag color="default">Clube {item.clubeName}</Tag>}
                          <Space size={0}>
                            <IconCalendar className="icon icon-calendar" />
                            <span className="text-small">
                              Enviado em {item.created_at}
                            </span>
                          </Space>
                          {item.draft == "true" && (
                            <Tag color="blue">EMAIL TESTE</Tag>
                          )}
                        </Space>
                      </Space>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </>
        )}

        <Modal
          key={"remove-Email-" + emailToShow.id}
          title="Detalhes de envio do email para os sócios"
          open={emailModalOpen}
          onCancel={() => setEmailModalOpen(false)}
          footer={[]}
        >
          <p className="mb-0" style={{ marginTop: "20px" }}>
            <u>
              <b>Destinatário</b>
            </u>
          </p>
          { emailToShow.clubeName != '' ? <>
          <p>Clube {emailToShow.clubeName}</p></> :
          <>
          <p>Todos os sócios</p></>}
          <p className="mb-0" style={{ marginTop: "20px" }}>
            <u>
              <b>Assunto</b>
            </u>
          </p>
          <p> {emailToShow.subject}</p>
          <p className="mb-0">
            <u>
              <b>Mensagem</b>
            </u>
          </p>{" "}
          <p
            className="p-body"
            dangerouslySetInnerHTML={{ __html: emailToShow.body }}
          ></p>
          <p className="mb-0">
            <u>
              <b>Data de envio</b>
            </u>
          </p>
          <p> {emailToShow.created_at}</p>
          <p className="mb-0">
            <u>
              <b>Anexo</b>
            </u>
          </p>
          {emailToShow.attachment ? (
            <>
              <p>
                <a
                  target="_blank"
                  href={
                    "https://api.spap.pt/emails/" +
                    emailToShow.id +
                    "/" +
                    emailToShow.attachment
                  }
                >
                  {emailToShow.attachment}
                </a>
              </p>
            </>
          ) : (
            <>Sem anexo</>
          )}
        </Modal>
      </WrapperCardList>
    </WrapperCardEmail>
  );
}
