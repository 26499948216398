import styled from 'styled-components'

import Sidebar from '../../../Components/Backoffice/Sidebar'
import Card from '../../../Components/Backoffice/Card'
import UserService from '../../../Services/user.service'

const Wrapper = styled.main`
  background-color: #E8E9F3;
  display: grid;
  grid-template-columns: 7.083vw 1fr;
  min-height: 100vh;

  @media (max-width: 992px) {
    
  }
`

const Content = styled.div`
  padding: 1.771vw;
`

const Title = styled.h1`
  color: ${props => props.theme.blue};
`

export default function Patologistas ( props ) {
  document.title = 'SPAP - Patologistas'
  const user = JSON.parse(localStorage.getItem('session_data'));
  
  const profile = UserService.getUserProfile();
  
  return(
    <Wrapper>
      <div>
        <Sidebar />
      </div>
      <Content>
        <Card>
          <Title>Patologistas</Title>
        </Card>
      </Content>
    </Wrapper>
  )
}