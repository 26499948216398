import styled from "styled-components";

import Container from "../../../Components/Container";
import FlexInRow from "../../../Components/FlexInRow";
import FlexInColumn from "../../../Components/FlexInColumn";
import PageTitle from "../../../Components/PageTitle";
import SidebarMenu from "../../../Components/SidebarMenu";
import PatologistaCards from "../../../Components/PatologistaCards";
import PatologistaForm from "../../../Components/PatologistaForm";
import PageText from "../../../Components/PageText";
import { Helmet } from "react-helmet";
const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  img {
    width: 100%;
  }

  .content {
    padding-bottom: 2.344vw;
  }

  .sidebar > section {
    max-width: 16.354vw;
    margin-left: auto;
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    .sidebar > section {
      max-width: 100%;
    }
  }

  @media (min-width: 1920px) {
    .content {
      padding-bottom: 50px;
    }
  }
`;

export default function ConhecaUmPatologista(props) {
  const SidebarMenuLinks = [
    {
      title: "Anatomia patológica",
      link: "/spap/anatomia-patologica",
    },
    {
      title: "Glossário",
      link: "/spap/glossario",
    },
    {
      title: "Conheça um patologista",
      link: "/spap/conheca-um-patologista",
    },
  ];

  return (
    <Wrapper>
      <Helmet>
        <title>
          Conheça um patologista | Sociedade Portuguesa de Anatomia Patológica -
          SPAP
        </title>
        <meta
          name="description"
          content="Professor Manuel Sobrinho Simões | Professor Vitor Sousa | Dr. António Alves"
        />
        <meta
          name="keywords"
          content="spap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      <Container className="content">
        <PageTitle
          title="Conheça um Patologista"
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "SPAP",
              link: "/spap",
            },
            {
              text: "O que é anatomia patológica?",
              link: "/spap/anatomia-patologica",
            },
            {
              text: "Conheça um Patologista",
            },
          ]}
          imageDesktop={require("../../../Assets/Images/icon-conheca-patologista@2x.png")}
          imageMobile={require("../../../Assets/Images/icon-conheca-patologista.png")}
          imageAlt="Conheça um Patologista"
          sidebar={<SidebarMenu navItens={SidebarMenuLinks} />}
        />
      </Container>

      <PatologistaCards />

      <PatologistaForm />
    </Wrapper>
  );
}
