import styled from 'styled-components'

import Container from '../Container'

const Wrapper = styled.div`
  align-items: center;  
  justify-content: center;
  left: 0;
  display: grid;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100%;
  grid-template-columns: 80%;
  z-index: 888888;
`

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000000DD;
`

export default function Popup( props ) {
  return(
    <>
      {
        props.show &&
        <Wrapper>
          <Overlay onClick={() => {props.onChange(false)}} />
          <Container>
            {props.children}
          </Container>
        </Wrapper>
      }
    </>
  )
}
