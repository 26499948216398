import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Divider, Space, Button, Avatar, Typography } from "antd";
import Card from "../../../Components/Backoffice/Card";
import Title from "../../../Components/Backoffice/Title";
import UserService from "../../../Services/user.service";
import DrawerPerfil from "../../../Pages/Backoffice/Socios/drawerPerfil";
import authService from "../../../Services/auth.service";
import {
  EditOutlined,
  DownOutlined,
  UserOutlined,
  CheckOutlined,
  LoadingOutlined,
  PlusOutlined,
  InboxOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import { ReactComponent as IconOptions } from "../../../Assets/Images/Backoffice/Options.svg";
import { ReactComponent as IconSearch } from "../../../Assets/Images/Backoffice/Search.svg";
import { ReactComponent as IconMessage } from "../../../Assets/Images/Backoffice/Message.svg";
import { ReactComponent as IconSettings } from "../../../Assets/Images/Backoffice/Settings.svg";

const { Text } = Typography;

const CardHeader = styled.div`
  align-items: center;
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr auto;
`;

const Search = styled.div`
  position: relative;

  input {
    background-color: #f1f5f700;
    border: 2px solid #d9e1e7;
    border-radius: 16px;
    color: #8688a8;
    font-size: 16px;
    font-style: italic;
    letter-spacing: 0px;
    line-height: 18px;
    margin: 0;
    padding: 12px 58px 12px 26px;
    outline: 0;
    width: 100%;
  }

  .icon {
    position: absolute;
    right: 26px;
    top: 8px;
  }
`;

const CardContent = styled.div`
  padding-top: 20px;

  .item {
    align-items: center;
    display: grid;
    gap: 14px;
    grid-template-columns: auto 1fr auto;
  }

  .item__name {
    color: #494d7d;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 22px;
    text-align: left;
  }

  .item__number {
    color: #8688a8;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 22px;
    text-align: left;
  }

  a {
    text-decoration: none;
  }

  button {
    font-size: 15px;
    font-weight: bold;
    height: 45px;
    line-height: 18px;
    margin: auto;
  }
`;

export default function PreviewListSocios() {
  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const profile = UserService.getUserProfile();

  const [usersList, setUsersList] = useState(session_storage.all_data.users);
  const [userDrawer, setUserDrawer] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [listUsersToShow, setListUsersToShow] = useState([]);
  const [listUsersBkp, setListUsersBkp] = useState([]);

  useEffect(() => {
    fetch(`https://api.spap.pt/api/all-users`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar users");
        }
        return response.json();
      })
      .then((data) => {
        if (data.users.length === 0) {
          return;
        }

        const socios = data.users.filter((user) => {
          if (user.tipo_utilizador == "socio_aprovado") {
            return user;
          }
        });

        for (let i = 0; i <= socios.length - 1; i++) {
          socios[i].number = socios[i].numero_socio
            ? socios[i].numero_socio
            : "N/A";
        }
        setListUsersBkp(socios);

        setListUsersToShow(socios);
      })
      .catch((error) => console.error(error));
  }, []);

  const clickToDrawer = (id) => {
    let userData = usersList.filter(function (item) {
      if (item.id == id) {
        return item;
      }
    });
    setUserDrawer(userData[0]);
    setShowDrawer(true);
  };

  const handleSearch = (event) => {
    let allUsers = getDataToShow();

    if (event.length == 0) {
      setListUsersToShow(allUsers);
      return;
    }

    let input = event.toLowerCase();

    let novosUsersAListar = allUsers.filter((user) => {
      if (
        (user.name && user.name.toLowerCase().includes(input)) ||
        (user.email && user.email.toLowerCase().includes(input)) ||
        (user.numero_socio &&
          user.numero_socio.toLowerCase().includes(input)) ||
        (user.telefone && user.telefone.toLowerCase().includes(input)) ||
        (user.orcid && user.orcid.toLowerCase().includes(input)) ||
        (user.socio_titular && user.socio_titular.toLowerCase().includes(input))
      ) {
        return user;
      }
    });

    setListUsersToShow(novosUsersAListar);
  };

  const handleUsers = (userUpdated) => {
    if (session_storage.all_data.user_session.id == userUpdated.id) {
      session_storage.all_data.user_session = userUpdated;
    }

    session_storage.all_data.users.map((person, index) => {
      if (person.id == userUpdated.id) {
        session_storage.all_data.users[index] = userUpdated;
      }
    });

    localStorage.setItem("session_data", JSON.stringify(session_storage));

    setListUsersToShow(() => getDataToShow());
  };

  return (
    <Card id="list-socios">
      <Space.Compact direction="vertical">
        <Space direction="vertical">
          <CardHeader>
            <Title>Sócios SPAP</Title>
            <IconOptions />
          </CardHeader>
          <Search>
            <input
              type="text"
              placeholder="Procurar..."
              onChange={(e) => handleSearch(e.target.value)}
            />
            <IconSearch className="icon icon-search" />
          </Search>
        </Space>
        <CardContent>
          <Space direction="vertical" size={20}>
            {listUsersToShow.slice(0, 8).map((item, index) => {
              return (
                <div
                  className={"item item-" + item.id}
                  align="center"
                  size={14}
                >
                  {item.avatar && (
                    <Avatar
                      src={
                        <img
                          src={"https://api.spap.pt/avatars/" + item.avatar}
                        />
                      }
                    />
                  )}
                  {!item.avatar && <Avatar icon={<UserOutlined />} />}
                  <Space.Compact direction="vertical">
                    <Text strong className="item__name">
                      <a>{item.name}</a>
                    </Text>
                    <Text className="item__number"> Sócio: {item.number}</Text>
                  </Space.Compact>
                  <Space className="item__icons">
                    <IconMessage className="icon icon-message" />
                    <IconSettings
                      className="icon icon-settings"
                      onClick={() => clickToDrawer(item.id)}
                    />
                  </Space>
                </div>
              );
            })}
          </Space>
          <Divider />
          <Link to="/dashboard/socios">
            <Button type="primary" className="cta">
              Ver lista de sócios completa
            </Button>
          </Link>
        </CardContent>
        <DrawerPerfil
          onUpdate={(e) => handleUsers(e)}
          user={userDrawer}
          show={showDrawer}
          onChange={(e) => setShowDrawer(e)}
        />
      </Space.Compact>
    </Card>
  );
}
