import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

const Wrapper = styled.form`
  background-color: #D6CDF7;
  border-radius: 1.042vw;
  color: #000000;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 1.484vw;
  margin: 0 auto 2.969vw;
  position: relative;
  z-index: 5;

  ${props => props.width && `width: ${props.width}`}
`

const Title = styled.h2`
  background-color: #0C1151;
  padding: 1.563vw;
  text-align: center;
  font-size: 2.083vw;
  font-weight: bold;
  line-height: 2.500vw;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;

  @media (max-width: 992px) {
    
    
  }
`

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.563vw;
  padding: 2.083vw 2.604vw;
`

const Submit = styled.button`
  max-width: 11.406vw;
  margin: auto auto -2.969vw;
  background-color: #F88436;
  border: none;
  border-radius: 0.521vw;
  font-size: 1.146vw;
  font-weight: bold;
  line-height: 1.510vw;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  transition: .3s ease;
  padding: 0.781vw 1.563vw;

  :hover {
    background-color: #E87426;
    color: #ECECEC;
  }
`

export default function Filter( props ) {

  const [selectedType, setSelectedType] = useState('')
  const [selectedLocal, setSelectedLocal] = useState('')
  const [selectedYear, setSelectedYear] = useState([])
  const [selectedMonth, setSelectedMonth] = useState('')

  const [currentFilter, setCurrentFilter] = useState(null)

  useEffect(() => {    
    if (currentFilter) {
      let itemsPosFilter = props.items.filter(item => {
        return (
          ((item.attr.type === currentFilter.type) || !currentFilter.type) &&
          ((item.attr.local === currentFilter.local) || !currentFilter.local) &&
          ((currentFilter.year.includes(item.attr.year)) || !currentFilter.year.length) &&
          ((item.attr.month === currentFilter.month) || !currentFilter.month)
        )
      });
      props.onchange(itemsPosFilter)
    }
  }, [currentFilter])
  
  const optionsType = [
    { value: '', label: 'Todos os eventos' },
    { value: 'new', label: 'Novos eventos' }
  ]

  const optionsLocal = [
    { value: 'national', label: 'Nacional' },
    { value: 'international', label: 'Internacional' }
  ]

  const optionsYear = [
    { value: '2022', label: '2022' },
    { value: '2023', label: '2023' }
  ]
  
  const optionsMonth = [
    { value: '', label: 'Todos os meses' },
    { value: '01', label: 'Janeiro' },
    { value: '02', label: 'Fevereiro' },
    { value: '03', label: 'Março' },
    { value: '04', label: 'Abril' },
    { value: '05', label: 'Maio' },
    { value: '06', label: 'Junho' },
    { value: '07', label: 'Julho' },
    { value: '08', label: 'Agosto' },
    { value: '09', label: 'Setembro' },
    { value: '10', label: 'Outubro' },
    { value: '11', label: 'Novembro' },
    { value: '12', label: 'Dezembro' }
  ]
  
  const animatedComponents = makeAnimated()

  return(
    <Wrapper width={props.width} onSubmit={(e) => {
      e.preventDefault()
      return [selectedType,selectedLocal,selectedYear,selectedMonth];
    }}>
      <Title>{props.title}</Title>

      <Content>
        <Select
          id="select-1"
          className="content__select"
          placeholder="Tipo de evento"
          closeMenuOnSelect={true}
          components={animatedComponents}
          options={optionsType}
          onChange={(e) => {setSelectedType(e.value)}}
        />
        <Select
          id="select-2"
          className="content__select"
          placeholder="Local do evento"
          closeMenuOnSelect={true}
          components={animatedComponents}
          options={optionsLocal}
          isClearable
          onChange={(e) => setSelectedLocal((e) ? e.value : '')}
        />
        <Select
          id="select-3"
          className="content__select"
          placeholder="Ano do evento"
          closeMenuOnSelect={true}
          components={animatedComponents}
          options={optionsYear}
          isMulti
          hideSelectedOptions={true}
          onChange={(e) => {setSelectedYear(e.map(item => {return item = item.value}))}}
        />
        <Select
          id="select-4"
          className="content__select"
          placeholder="Mês do evento"
          closeMenuOnSelect={true}
          components={animatedComponents}
          options={optionsMonth}
          onChange={(e) => setSelectedMonth(e.value)}
        />
      </Content>

      <Submit type="submit">Pesquisar</Submit>   
    </Wrapper>
  )
}
