import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import Container from "../../../../Components/Container";
import FlexInRow from "../../../../Components/FlexInRow";
import FlexInColumn from "../../../../Components/FlexInColumn";
import PageTitle from "../../../../Components/PageTitle";
import RecommendedLinks from "../../../../Components/RecommendedLinks";

const Wrapper = styled.main`
  background-color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  > .content {
    padding-bottom: 4vw;
  }

  img {
    width: 100%;
  }

  .item__title {
    text-align: left;
    font-size: 4.167vw;
    line-height: 5vw;
    font-weight: bold;
    letter-spacing: 0px;
    color: #c2c4d4;
    opacity: 1;
    margin-top: 0.885vw;
  }

  .item__text {
    text-align: left;
    font-size: 1.563vw;
    line-height: 2.031vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 0.729vw;
  }

  .item__cta {
    align-items: center;
    justify-content: center;
    display: flex;
    background: #0c1151 0% 0% no-repeat padding-box;
    border-radius: 0.521vw;
    opacity: 1;
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    text-decoration: none;
    color: #ffffff;
    opacity: 1;
    width: 8.021vw;
    height: 2.396vw;
    transition: 0.3s ease;
    margin-top: 1.458vw;
  }

  .item__cta:hover {
    background-color: #8688a8;
    color: #e5e5e5;
  }

  .item__date {
    text-align: left;
    font-size: 1.146vw;
    line-height: 1.51vw;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.521vw;
  }

  .item__date svg {
    font-size: 1.302vw;
  }

  #select-year {
    color: #000;
    cursour: pointer;
    z-index: 8;
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    .item__text,
    .item__cta,
    .item__date,
    .item__date svg {
    }
  }

  @media (min-width: 1920px) {
    .item__cta {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

const Title = styled.h2`
  text-align: left;
  font-size: 1.25vw;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;

  @media (max-width: 992px) {
  }
`;

const SectionCards = styled.div`
  display: grid;
  grid-gap: 3.021vw;
  grid-template-columns: 1fr 1fr 1fr;

  .item {
    border: 0.156vw solid #0c1151;
    border-top: 0.625vw solid #0c1151;
    border-radius: 0px 0px 1.042vw 1.042vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .item__image {
    border: none;
    overflow: hidden;
    position: relative;
    height: 11.979vw;
    margin-bottom: 1.042vw;

    picture {
      z-index: 1;
    }

    .overlay {
      background: transparent
        linear-gradient(180deg, #452aa71a 0%, #452aa720 100%) 0% 0% no-repeat
        padding-box;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      z-index: 2;
    }

    img {
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .item__title {
    text-align: left;
    font-size: 1.25vw;
    line-height: inherit;
    font-weight: bold;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    margin: 1.094vw 0;
    padding: 0 1.042vw;
  }

  .item__text {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    padding: 0 1.042vw;
    opacity: 1;
    margin-bottom: 0.729vw;
    height: 4.01vw;
  }

  .item__cta {
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #0c1151;
    border: none;
    border-radius: 0;
    opacity: 1;
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    text-decoration: none;
    color: #ffffff;
    opacity: 1;
    width: 100%;
    height: 2.396vw;
    transition: 0.3s ease;
    margin: 0;
  }

  .item__cta:hover {
    background-color: #8688a8;
    color: #e5e5e5;
  }

  .item__date {
    text-align: left;
    font-size: 2.031vw;
    font-weight: bold;
    line-height: 2.708vw;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.521vw;
    position: absolute;
    bottom: 1.042vw;
    left: 1.042vw;
    z-index: 3;
  }

  .item__date svg {
    font-size: 2.708vw;
  }

  @media (max-width: 992px) {
    .item__text,
    .item__cta,
    .item__date,
    .item__date svg {
    }
  }

  @media (min-width: 1920px) {
    .item__text,
    .item__cta {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

const NoResults = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  * {
    text-align: center;
    font-size: 1.25vw;
    line-height: inherit;
    font-weight: bold;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    margin: 1.094vw 0;
  }

  @media (max-width: 992px) {
    * {
    }
  }
`;

export default function FormacaoCursosCongressos(props) {
  const ListCards = [
    {
      title: "Vincent Academy of Pathology",
      text: "One of the main purposes of the academy is to organize a variety of interactive diagnostic courses for practicing pathologists as well as pathologists in training (…)",
      desktop: require("../../../../Assets/Images/img-agenda-01@2x.png"),
      mobile: require("../../../../Assets/Images/img-agenda-01.png"),
      alt: "21st Meeting of the EAHP",
      link: "/ferramentas-uteis/formacao/21st-meeting-of-the-eahp",
      attr: {
        year: "2022",
      },
    },
    {
      title: "Cursos USCAP",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do incididunt consectetur adipiscing elit, sed do eiusmod tempor labore et dolore (…)",
      desktop: require("../../../../Assets/Images/img-agenda-01@2x.png"),
      mobile: require("../../../../Assets/Images/img-agenda-01.png"),
      alt: "21st Meeting of the EAHP",
      link: "/ferramentas-uteis/formacao/21st-meeting-of-the-eahp",
      attr: {
        year: "2022",
      },
    },
    {
      title: "THE PEZCOLLER SEMINARS",
      text: "A series of oncologic educational meetings for Doctors and Health Professionals (…)",
      desktop: require("../../../../Assets/Images/img-agenda-01@2x.png"),
      mobile: require("../../../../Assets/Images/img-agenda-01.png"),
      alt: "21st Meeting of the EAHP",
      link: "/ferramentas-uteis/formacao/21st-meeting-of-the-eahp",
      attr: {
        year: "2022",
      },
    },
    {
      title: "Test 1",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do incididunt consectetur adipiscing elit, sed do eiusmod tempor labore et dolore (…)",
      desktop: require("../../../../Assets/Images/img-agenda-01@2x.png"),
      mobile: require("../../../../Assets/Images/img-agenda-01.png"),
      alt: "21st Meeting of the EAHP",
      link: "/ferramentas-uteis/formacao/21st-meeting-of-the-eahp",
      attr: {
        year: "2022",
      },
    },
    {
      title: "Test 2",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do incididunt consectetur adipiscing elit, sed do eiusmod tempor labore et dolore (…)",
      desktop: require("../../../../Assets/Images/img-agenda-01@2x.png"),
      mobile: require("../../../../Assets/Images/img-agenda-01.png"),
      alt: "21st Meeting of the EAHP",
      link: "/ferramentas-uteis/formacao/21st-meeting-of-the-eahp",
      attr: {
        year: "2022",
      },
    },
    {
      title: "Test 3",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do incididunt consectetur adipiscing elit, sed do eiusmod tempor labore et dolore (…)",
      desktop: require("../../../../Assets/Images/img-agenda-01@2x.png"),
      mobile: require("../../../../Assets/Images/img-agenda-01.png"),
      alt: "21st Meeting of the EAHP",
      link: "/ferramentas-uteis/formacao/21st-meeting-of-the-eahp",
      attr: {
        year: "2023",
      },
    },
  ];

  const [allItems, setAllItems] = useState(ListCards);
  const [currentItems, setCurrentItems] = useState(ListCards.slice(0, 6));
  const [selectedYear, setSelectedYear] = useState([]);

  useEffect(() => {
    if (selectedYear.length > 0) {
      let itemsPosFilter = allItems.filter((item) => {
        return selectedYear.includes(item.attr.year);
      });
      setAllItems(itemsPosFilter);
    } else {
      setAllItems(ListCards);
    }
  }, [selectedYear]);

  useEffect(() => {
    setCurrentItems(allItems.slice(0, 6));
  }, [allItems]);

  const optionsYear = [
    { value: "2017", label: "2017" },
    { value: "2018", label: "2018" },
    { value: "2019", label: "2019" },
    { value: "2020", label: "2020" },
    { value: "2021", label: "2021" },
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
  ];

  const animatedComponents = makeAnimated();

  return (
    <Wrapper>
      <Container className="content">
        <PageTitle
          title="Formação"
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "Ferramentas úteis",
              link: "/ferramentas-uteis",
            },
            {
              text: "Formação",
              link: "/ferramentas-uteis/formacao",
            },
            {
              text: "Cursos e Congressos",
            },
          ]}
          imageDesktop={require("../../../../Assets/Images/img-top-guidelines@2x.png")}
          imageMobile={require("../../../../Assets/Images/img-top-guidelines.png")}
          imageAlt="Formação"
        />

        <FlexInRow gap="4.688vw">
          <FlexInRow gap="4.167vw">
            <FlexInColumn gap="1.563vw">
              <Title>Anteriores</Title>
              <Select
                id="select-year"
                placeholder="Selecione um ano"
                closeMenuOnSelect={true}
                components={animatedComponents}
                options={optionsYear}
                isMulti
                hideSelectedOptions={true}
                onChange={(e) => {
                  setSelectedYear(
                    e.map((item) => {
                      return (item = item.value);
                    })
                  );
                }}
              />
            </FlexInColumn>
            {currentItems.length > 0 ? (
              <SectionCards>
                {currentItems.map((item, index) => {
                  return (
                    <div className={"item item-" + index}>
                      <div className="item__content">
                        <div className="item__image">
                          <picture>
                            <source
                              srcSet={item.desktop}
                              media="(min-width: 990px)"
                            />
                            <img src={item.mobile} alt={item.alt} />
                          </picture>
                          <div className="overlay" />
                        </div>
                        <h3 className="item__title">{item.title}</h3>
                        <p className="item__text">{item.text}</p>
                        <Link to={item.link} className="item__cta">
                          Saber mais
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </SectionCards>
            ) : (
              <NoResults>
                <h3>Nenhuma formação encontrada..</h3>
              </NoResults>
            )}
          </FlexInRow>
        </FlexInRow>
      </Container>

      <RecommendedLinks
        title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
        links={[
          {
            title: "História",
            link: "/spap/historia",
          },
          {
            title: "Órgãos sociais",
            link: "/spap/orgaos-sociais",
          },
          {
            title: "Bolsas e prémios",
            link: "/spap/bolsas-e-premios",
          },
          {
            title: "Vantagens de ser sócio",
            link: "/socios/conheca-as-vantagens",
          },
        ]}
      />
    </Wrapper>
  );
}
