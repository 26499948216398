import styled from "styled-components";
import { Link } from "react-router-dom";
import Container from "../Container";
import FlexInRow from "../FlexInRow";
import FlexInColumn from "../FlexInColumn";
import axios from "axios";
import {
  Button,
  Modal,
  Result,
  Select,
  DatePicker,
  Row,
  Col,
  Upload,
} from "antd";
import { useState } from "react";
import authService from "../../Services/auth.service";
const { RangePicker } = DatePicker;
import { UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const Wrapper = styled.section`
  background-color: #CBE0EA;

  label {
    display: ruby !important;
  }

  .check-png {
    margin-top: 20px;
    margin-bottom: 60px;
    width: 100px !important;
  }

  .success-div button, #contact-form .button {
    background:#F88436;
    color: #fff;
    padding: 10px 20px;
    border: 1px solid #F88436 !important;
    display: block;
    font-weight: bold;
    font-size: 16px;
    margin: 0 auto;
    width: min-content;
  }
  .success-div button:hover {
    background:#fff !important;
    color: #F88436 !important;
    border: 1px solid #F88436 !important;
  }

  .success-div {
    position: relative;
    display: flex;
    padding: 100px 0;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .success-div .msg {
    color: #225E79;
    text-align: center;
    font-size: 35px;
    font-weight: bold;
  }

  .success-div p {
    color: #2F83AA;
    font-size: 16px;
    text-align: center;
    max-width: 650px;
  }

  .medica-ok {
    max-width: 500px;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  #contact-form {
    margin-bottom: 75px;
  }

  .img-medica {
    position: absolute;
    max-width: 350px !important;
    margin-top: -230px !important;
    margin-bottom: 100px !important;
  }

  .opacity-0 {
    opacity: 0 !important;
  }

  .padding-section {
    padding: 0 0 0 0;
  }

  .ant-upload-list-item-name {
    text-align: left;
  }

  .ant-picker-range {
    width: 100%;
  }

  .ant-modal-content .ant-modal-footer {
    display: none !important;
  }

  .button-voltar {
    background: #f88436;
    color: #fff;
    border-radius: 10px;
    padding: 10px 30px;
    line-height: 24px;
    display: block;
    margin-left: auto !important;
    margin-right: auto !important;
    border: none;
  }

  .ant-btn {
    height: auto;
  }

  .title {
    margin-top: 50px !important;
    text-align: left;
    font-size: 2.083vw;
    font-weight: bold;
    line-height: 2.5vw;
    letter-spacing: 0px;
    color: #225E79;
    text-transform: uppercase;
    opacity: 1;
    margin: 0;
  }

  @media (max-width: 992px) {

    input, textarea {
      border-radius: 5px !important;
    }

    .title {
      margin-top: 30px !important;
      font-size: 5.556vw;
      line-height: 6.667vw;
      margin-bottom: 4.944vw;
    }
  }

  @media (min-width: 1920px) {
    .title {
      font-size: 40px;
      line-height: 48px;
    }
  }
`;

const Form = styled.form`
  opacity: 1;
  padding: 1.823vw 0;

  .upload-btn {
    height: 45px;
    width: 100%;
    text-align: left;
    font-size: 12px;
    color: #757575 !important;
  }

  .ant-select-selection-item {
    line-height: 45px !important;
    font-size: 12px;
    text-align: left;
  }

  .ant-select-selection-placeholder,
  .ant-picker .ant-picker-input > input:placeholder-shown,
  .ant-picker .ant-picker-input > input {
    color: #757575 !important;
    font-size: 12px;
    text-align: left;
  }

  .ant-upload-wrapper .ant-upload-select {
    width: 100%;
  }

  .ant-select-selector,
  .ant-select {
    height: 45px;
    border: none !important;
  }

  input[type="text"],
  input[type="email"],
  input[type="select"],
  textarea {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 0.521vw;
    opacity: 1;
    height: 45px;
    padding: 10px 10px;
    font-size: 12px;
    width: 100%;
    outline: 0;
    transition: 0.3s ease;
  }

  input[type="text"]:focus,
  input[type="email"]:focus,
  select:focus,
  textarea:focus {
    box-shadow: 0 8px 24px #00000025;
  }

  textarea {
    height: 13.75vw;
  }

  input[type="checkbox"] {
    background-color: #ffffff;
    border: none;
    cursor: pointer;
    opacity: 1;
    height: 1.302vw;
    width: 1.302vw;
  }

  a {
    font-size: 14px !important;
  }

  label {
    text-align: left;
    font-size: 14px !important;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    display: inline-flex;
    gap: 2.5px;
    transition: 0.3s ease;
    align-items: center;

    a {
      ${(props) =>
        props.nispap
          ? `
        color: ${props.theme.blue};
      `
          : `
        color: ${props.theme.orange};
      `}
      text-decoration: underline;
      transition: 0.3s ease-out;
    }

    a:hover {
      ${(props) =>
        props.nispap
          ? `
        color: ${props.theme.blueHover};
      `
          : `
        color: ${props.theme.orangeHover};
      `}
      text-decoration: none;
    }
  }

  input[type="submit"] {
    background-color: #f88436;
    border: none;
    border-radius: 0.521vw;
    opacity: 1;
    text-align: center;
    font-size: 1.146vw;
    font-weight: bold;
    line-height: 1.51vw;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    padding: 0.729vw 1.563vw;
    place-self: center;
    margin-bottom: -2.968vw;
    transition: 0.3s ease;
  }

  .select-form .ant-select-selector {
    min-height: -webkit-fill-available;
    border: none !important;
  }

  .select-form
    .ant-select-single
    .ant-select-selector
    .ant-select-selection-item {
    line-height: max-content !important;
  }

  input[type="submit"]:hover {
    background-color: #e87426;
    color: #ececec;
  }

  @media (max-width: 992px) {

    textarea {
      height: 43.611vw;
    }

    input[type="checkbox"] {
      height: 20px;
      width: 20px;
    }

    label,
    label a {
      font-size: 2.778vw;
      line-height: 3.611vw;
      display: initial;
    }

    input[type="submit"] {
      border-radius: 2.778vw;
      font-size: 3.611vw;
      line-height: 5vw;
      padding: 2.778vw 4.167vw;
      margin-top: 5.556vw;
    }
  }

  @media (min-width: 1920px) {
    label,
    label * {
      font-size: 18px;
      line-height: 24px;
    }

    input[type="text"],
    input[type="email"],
    textarea {
      font-size: 18px;
      line-height: 24px;
      padding: 25px;
    }

    input[type="checkbox"] {
      height: 20px;
      width: 20px;
    }

    input[type="submit"] {
      border-radius: 10px;
      font-size: 22px;
      line-height: 29px;
      padding: 15px 30px;
    }
  }
`;

export default function PatologistaExplicaForm(props) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [subject, setSubject] = useState("");
  const [fileList, setFileList] = useState([]);
  const [dataEvent, setDataEvent] = useState([]);
  const [email, setEmail] = useState("");
  const [nome, setNome] = useState("");
  const [areaEvent, setAreaEvent] = useState([]);
  const [localizacaoEvent, setLocalizacaoEvent] = useState("");
  const [tipoEvent, setTipoEvent] = useState([]);
  const [descricaoEvent, setDescricaoEvent] = useState("");
  const [tituloEvent, setTituloEvent] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [cidade, setCidade] = useState("");
  const [telefone, setTelefone] = useState("");
  const [numeroSocio, setNumeroSocio] = useState("");
  const [success, setSuccess] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 10000);
  };

  const onChangeRangeEvent = (value, dateString) => {
    console.log(value);
    console.log(dateString);
    setDataEvent([dateString[0], dateString[1]]);
  };

  const handleContact = () => {

    if (document.querySelector('input[type="checkbox"]') == false || 
      email.length == 0 || nome.length == 0 || subject.length == 0 || descricaoEvent.length == 0) {
        return;
      }

    var bodyFormData = new FormData();
    bodyFormData.append("email", email);
    bodyFormData.append("nome", nome);
    bodyFormData.append("subject", subject);
    bodyFormData.append("descricao", descricaoEvent);
    if (fileList.length) {
      bodyFormData.append("file", fileList[0]);
    }

    setTimeout(() => {
      // document.querySelector('.img-medica').classList.add('opacity-0');
      setSuccess(true);
      document.getElementById("contact-form").reset();
    }, 3000);



    axios
      .post("https://api.spap.pt/api/send-patologista-explica", bodyFormData)
      .then((res) => {
      })
      .catch((error) => {
      });
  };

  const beforeUpload = (file) => {
    const image =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/svg";
    if (!image) {
      notification.error({
        description: "Apenas pode fazer upload de imagens.",
      });
      return image || Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      notification.error({
        description: "Faça o upload de imagens menos pesadas.",
      });
      return isLt2M || Upload.LIST_IGNORE;
    }

    setFileList(file);
    return image && isLt2M;
  };

  const prop = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        return false;
      }
      setFileList([...fileList, file]);
      return true;
    },
    fileList,
  };

  const sendNewMsg = () => {
    setSuccess(false);
  }

  return (
    <Wrapper id={props.id} className={props.className}>
      <Container className="padding-section">
        <FlexInRow gap="1.167vw" placeself="center" className="w-100"> 
          { success == false && 
          <>
          <h2 className="title">ESCREVA AQUI A SUA DÚVIDA</h2>
          <Form
            method="post"
            action="#"
            id="contact-form"
            nispap={props.nispap}
            onSubmit={(e) => handleContact(e)}
          >
            <FlexInRow gap="8px">
              <Row gutter={[20,20]}>
                <Col md={8} sm={24} xs={24}>
                  <input
                    type="text"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    name="subject"
                    placeholder="Assunto*"
                    required
                  />
                </Col>
                <Col md={8} sm={24} xs={24}>
                  <input
                    type="text"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                    name="name"
                    placeholder="Nome*"
                    required
                  />
                </Col>
                <Col md={8} sm={24} xs={24}>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="E-mail*"
                    required
                  />
                </Col>
              </Row>
                  <textarea
                    className="mt-10"
                    name="descricao"
                    placeholder="Descrição*"
                    value={descricaoEvent}
                    onChange={(e) => setDescricaoEvent(e.target.value)}
                    required
                  />
                  <Row gutter={[8, 8]}
                    className="mt-10">
                    <Col md={8} sm={24} xs={24}>
                      <Upload {...prop} fileList={fileList}>
                        <Button
                          className="upload-btn"
                          icon={<UploadOutlined />}
                        >
                          Anexar ficheiro (Upload Máx. 10MB)
                        </Button>
                      </Upload>
                    </Col>
                  </Row>
              
              <FlexInColumn gap="5px">
                <input type="checkbox" name="confirm" required />
                <FlexInRow gap="0">
                  <label>
As respostas fornecidas através de "O Patologista Explica" são de caráter informativo e não substituem uma consulta com o seu médico assistente ou especialista.<br/>Este serviço não constitui uma atividade de segunda opinião. Esta iniciativa é promovida pela Sociedade Portuguesa de Anatomia Patológica e não está afiliada a nenhum hospital ou instituição.                
                      {/* política de privacidade
                    </a> deste website bem como os <a href="https://spap.pt/termos-e-condicoes" target="_blank">
                      termos e condições
                    </a> do mesmo. */}
                  </label>
                  <br/>
                  {/* <label>* Campos de preenchimento obrigatório.</label> */}
                </FlexInRow>
              </FlexInColumn>
              <Button name="submit" className="button" loading={loadings[1]} onClick={() => { enterLoading(1); handleContact()}}>Enviar Mensagem</Button>
            </FlexInRow>
          </Form>
          </>}
          { success == true && 
          <>
          <div className="success-div">
          <h2 className="msg">DÚVIDA ENVIADA COM SUCESSO</h2>
          <p>Assim que possível, um médico patologista experiente irá responder às suas questões de forma clara e acessível. <br/><br/>Fique atento(a) ao seu email.</p>
          <img src={require("../../Assets/Images/OK.png")} className="check-png"/>
          <Button onClick={() => sendNewMsg()}>Enviar outra mensagem</Button>
          </div>
          </>}
        </FlexInRow>
      </Container>
      {/* {showResult &&  */}

      <Modal
        centered
        open={isModalOpen}
        footer={null}
        onCancel={() => setModalOpen(false)}
      >
        <Result
          style={{ background: "#fff" }}
          status="success"
          title="Mensagem enviada com sucesso!"
          subTitle="Obrigado pela sua mensagem. Entraremos em contacto consigo assim que possível!"
        />
      </Modal>
      {/* } */}
    </Wrapper>
  );
}
