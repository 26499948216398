import React, { useState } from 'react'
import styled from 'styled-components'

import Container from '../../Components/Container'
import Login from '../../Components/Backoffice/LoginForm'
import Register from '../../Components/Backoffice/RegisterForm'
import Recaptcha from '../../Components/Recaptcha'

import { ReactComponent as LogoSpap } from '../../Assets/Images/Backoffice/Login/SPAP-Sociedade-Portuguesa-de-Anatomia-Patologica.svg'
import { Space, Divider } from 'antd'

const Wrapper = styled.main`
  background-image: url(${require('../../Assets/Images/Backoffice/Login/Background-Login@2x.png')});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 20px 0;

  @media (max-width: 992px) {
    background-image: url(${require('../../Assets/Images/Backoffice/Login/Background-Login.png')});
  }

  @media (min-width: 1920px) {
    padding-bottom: 200px;
  }

  .container-login {
    min-height: calc(100vh - 250px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const Header = styled.header`
  align-items: center;  
  display: flex;
  justify-content: flex-start;

  .logo {
    width: 250px;
  }

  @media (max-width: 992px) {
    .logo {
      width: 220px;
    }
  }
`

const Footer = styled.footer`
  background-color: ${props => props.theme.blueLight};
  padding: 1.563vw 0;
  color: #FFFFFF;

  .footer__content {
    display: grid;
    align-items: center;
    justify-content: center;
  }

  .footer__column {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;

    a {
      color: #FFFFFF;
    }
  }

  @media (min-width: 992px) {
    .item.bar {
      border-left: 1px solid #EFEFEFAA;
      border-right: 1px solid #EFEFEFAA;
      padding: 0 20px;
    }

    .footer__column {
      margin-right: 30px;
    }
  }

  @media (max-width: 992px) {
    .footer__column {
      padding: 20px 0;
      grid-template-columns: auto;
      text-align: center;
    }
  }
`

const Form = styled.div`
  background-color: #FFFFFF;
  border-radius: 0.833vw;
  margin: 0 auto;
  max-width: 500px;
  width: 500px;
  padding: 40px;

  @media (min-width: 1920px) {
    max-width: 550px;
    margin-top: 30px;
  }

  // @media (max-width: 1180px) {
  //   max-width: 60vw;
  // }

  @media (max-width: 600px) {
    max-width: 400px;
    padding: 40px 30px 20px;
  }
`

const FormTitle = styled.h1`
  color: ${props => props.theme.blue};
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: 0px;
`

const FormChange = styled.div`
  background-color: #C2C4D4;
  border-radius: 0.625vw;  
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0.260vw 0.208vw;
  margin: 30px 0px;

  @media (max-width: 992px) {
    margin: 30px;
  }
`

const FormChangeButton = styled.button`
  background-color: transparent;  
  border: none;  
  border-radius: 0.625vw;
  color: ${props => props.theme.blue};
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  padding: 12px 30px;
  min-height: 50px;
  transition: .3s ease;

  ${props => props.selected && `
    background-color: ${props.theme.white};
  `}

  @media (max-width: 992px) {
    font-size: 18px;
    line-height: 22px;
    padding: 10px 20px;
  }
`

export default function Backoffice ( props ) {

  const [formSelected, setFormSelected] = useState(props.form)

  const handleToken = (token) => {
  };

  return(
    <>
      <Wrapper>
        <Container>
          <Header>
            <a href="/">
              <LogoSpap className='logo'/>
            </a>
          </Header>
        </Container>
        <Container className="container-login">
          <Form style={{ height: 'min-content'}}>
            <FormTitle>Login & Inscrição</FormTitle>
            <FormChange>
              <FormChangeButton
                selected={formSelected === 'register' ? true : false}
                onClick={() => {setFormSelected('register')}}
              >Inscrição</FormChangeButton>
              <FormChangeButton
                selected={formSelected !== 'register' ? true : false}
                onClick={() => {setFormSelected('login')}}
              >Login</FormChangeButton>
            </FormChange>
            {
              formSelected === 'register' ?
                <Register />
              :
                <Login />
            }
            <Recaptcha sitekey="6Lcfpy8mAAAAAMsUWvFiFerTMe9IdnDZP5KljRUu" onToken={handleToken} />
          </Form> 
        </Container>
      </Wrapper>
      <Footer>
        <Container className='footer__content'>
          <Space direction='vertical' align="center" size={40}>
            <div className='footer__column'>
              <span className='item'>Todos os direitos reservados</span>
              <span className='item bar'><a href="/termos-e-condicoes" target="_blank">Termos e condições</a></span>
              <span className='item'><a href="/politica-de-privacidade" target="_blank">Política de privacidade</a></span>
            </div>
            <Space> 
              <span>SPAP © Copyright 2023</span>
            </Space>
          </Space>
        </Container>
      </Footer>
    </>
  )
}