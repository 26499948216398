import styled from "styled-components";
import { Link, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faUser } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import * as urlSlug from "url-slug";

import Container from "../../../../Components/Container";
import FlexInColumn from "../../../../Components/FlexInColumn";
import FlexInRow from "../../../../Components/FlexInRow";
import PageTitle from "../../../../Components/PageTitle";

import IconFile from "../../../../Assets/Images/icon-awesome-file-alt.svg";
import IconSubscribe from "../../../../Assets/Images/icon-partilhar.svg";
import IconSeeMore from "../../../../Assets/Images/icon-saber-mais.svg";
import IconShare from "../../../../Assets/Images/icon-partilhar.svg";
import IconReturn from "../../../../Assets/Images/icon-voltar.svg";
import { Button, Radio, Space, notification } from "antd";
import { Input } from "antd";
import Item from "antd/es/list/Item";
import axios from "axios";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #000000;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: left;
  width: 100%;
  margin-top: 7.813vw;

  img {
    width: 100%;
  }

  ul {
    list-style: none;
    width: 8.333vw;
    padding: 0;
  }

  li {
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
    gap: 0.729vw;
    text-align: left;
    font-size: 1.146vw;
    font-weight: bold;
    line-height: 1.51vw;
    letter-spacing: 0px;
    opacity: 1;
    margin: 1.042vw 0;

    img {
      width: 1.667vw;
    }
  }

  li a {
    color: #f88436;
    text-decoration: none;
  }

  .event__content a {
    display: initial;
  }

  .event__content img {
    max-width: 60%;
    display: block;
    margin-right: auto;
  }

  .event__content iframe {
    height: 30vw;
    width: 60%;
  }

  .event__content ul {
    width: 100%;
  }

  .icon {
    margin-right: 8px;
  }

  .icon-file {
    width: 3.438vw;
  }

  .share {
    cursor: pointer;
    color: ${(props) => props.theme.orange};
  }

  .event-content {
    width: 100%;
  }

  .correta {
    background-color: green;
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    .event__content iframe {
      height: 400px;
      width: 100%;
    }

    .event__content p {
      font-size: 16px;
      line-height: 22px;
    }

    ul {
      width: auto;
    }

    li {
      font-size: 14px;
      line-height: 20px;
      margin: 20px 0;

      img {
        width: 20px;
      }
    }
  }

  @media (min-width: 1920px) {
    li {
      font-size: 22px;
      line-height: 29px;
      margin: 20px 0;
    }
  }
`;

const Tab = styled.div`
  margin-bottom: 30px;
  width: 100%;

  span {
    text-align: left;
    font-size: 1.146vw;
    font-weight: bold;
    line-height: 1.51vw;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
  }

  @media (max-width: 992px) {
    margin-bottom: 10px;

    span {
      font-size: 3.333vw;
      line-height: 4.444vw;
    }
  }

  @media (min-width: 1920px) {
    span {
      font-size: 24px;
      line-height: 28px;
    }
  }
`;

const SectionShareContent = styled.section`
  margin-top: 30px;
  padding: 3.646vw 0;
  background: #d6d7e2 0% 0% no-repeat padding-box;
  opacity: 1;

  .title {
    text-align: center;
    font-size: 2.083vw;
    font-weight: bold;
    line-height: 2.5vw;
    letter-spacing: 0px;
    color: #0c1151;
    text-transform: uppercase;
    opacity: 1;
  }

  .text {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    line-height: inherit;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    margin-bottom: 4.167vw;
  }

  @media (max-width: 992px) {
    .title {
      font-size: 24px;
      line-height: 30px;
    }

    .text {
      font-size: 18px;
      line-height: 24px;
    }
  }

  @media (min-width: 1920px) {
    .title {
      font-size: 40px;
      line-height: 48px;
    }

    .text {
      font-size: 24px;
      line-height: 28px;
    }
  }
`;

const SectionMembers = styled.section``;

const SectionMembersContent = styled.div`
  background-color: #ffffff;
  border: 3px solid #0c1151;
  border-radius: 1.042vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "image title"
    "image text"
    "image cta";
  opacity: 1;
  overflow: hidden;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "title"
      "image"
      "text"
      "cta";
    gap: 10px;
    padding: 10px 0;
    border-radius: 8px;
  }

  @media (min-width: 1920px) {
    border-radius: 10px;
  }
`;

const SectionMembersImage = styled.div`
  border-radius: 1.042vw 0px 0px 1.042vw;
  grid-area: image;
  overflow: hidden;

  @media (max-width: 992px) {
    border-radius: 0;
    height: 220px;
  }

  @media (min-width: 1920px) {
    border-radius: 10px 0px 0px 10px;
  }
`;

const SectionMembersTitle = styled.h3`
  grid-area: title;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;
  margin: 1.042vw;
  place-self: flex-end flex-start;

  @media (max-width: 992px) {
    font-size: 18px;
    line-height: 24px;
  }

  @media (min-width: 1920px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

const SectionMembersText = styled.p`
  grid-area: text;
  text-align: left;
  font-size: 0.938vw;
  line-height: 1.25vw;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin: 0 1.042vw;
  place-self: center flex-start;

  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 22px;
  }

  @media (min-width: 1920px) {
    font-size: 22px;
    line-height: 29px;
  }
`;

const SectionMembersCta = styled.a`
  grid-area: cta;
  text-align: left;
  font-size: 0.938vw;
  font-weight: bold;
  line-height: 1.25vw;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  background: #090d3d 0% 0% no-repeat padding-box;
  border-radius: 0.417vw;
  opacity: 1;
  transition: 0.3s ease;
  margin: 1.042vw;
  place-self: flex-end flex-start;
  padding: 0.521vw 1.563vw;
  text-decoration: none;
  align-items: center;
  justify-content: center;

  :hover {
    background-color: #8688a8;
    color: #e5e5e5;
  }

  @media (max-width: 992px) {
    font-size: 14px;
    line-height: 20px;
    padding: 5px 10px;
    border-radius: 10px;
  }

  @media (min-width: 1920px) {
    border-radius: 10px;
    font-size: 22px;
    line-height: 29px;
    margin: 10px;
  }
`;

const CardQuestoes = styled.div`
  background-color: #dadafa;
  padding: 20px;
  border-radius: 10px;

  .ant-space-vertical {
    width: 100%;
  }

  .correta,
  .errada {
    padding: 5px;
    margin-left: -5px;
    border-radius: 5px;
    width: 100%;
  }

  .correta {
    background-color: #aaeeaa;
  }

  .errada {
    background-color: #eeaaaa;
  }
`;

export default function CasoDoMesPage(props) {
  const session_storage = JSON.parse(localStorage.getItem("session_data"));

  const { slug } = useParams();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [evento, setEvento] = useState([]);

  const month = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const getPost = (array, id) => {
    const casoDoMes = array.find((a) => a.id == id);
    let eventoAMostrar = casoDoMes;

    if (eventoAMostrar.data_inicio) {
      const [dateInicioValues, timeInicioValues] =
        eventoAMostrar.data_inicio.split(" ");
      const [dayInicio, monthInicio, yearInicio] = dateInicioValues.split("/");
      const [dateFechoValues, timeFechoValues] =
        eventoAMostrar.data_fecho.split(" ");
      const [dayFecho, monthFecho, yearFecho] = dateFechoValues.split("/");

      const monthInicioToString = month[Number(monthInicio - 1)];
      const monthFechoToString = month[Number(monthFecho - 1)];
      eventoAMostrar.monthInicio = monthInicio;
      eventoAMostrar.yearInicio = yearInicio;

      eventoAMostrar.url = eventoAMostrar.imagem_destaque
        ? "https://api.spap.pt/caso_do_mes/" +
          eventoAMostrar.id +
          "/" +
          eventoAMostrar.imagem_destaque
        : "https://api.spap.pt/clubes/clube-estudo-default.svg";

      if (
        monthInicio == monthFecho &&
        yearInicio === yearFecho &&
        dayInicio === dayFecho
      ) {
        eventoAMostrar.date =
          dayInicio + " " + monthInicioToString + " " + yearInicio;
      } else if (monthInicio == monthFecho && yearInicio === yearFecho) {
        eventoAMostrar.date =
          dayInicio +
          "-" +
          dayFecho +
          " " +
          monthInicioToString +
          " " +
          yearInicio;
      } else if (
        dayInicio == dayFecho &&
        monthInicio == monthFecho &&
        yearInicio === yearFecho
      ) {
        eventoAMostrar.date =
          dayInicio + " " + monthInicioToString + " " + yearInicio;
      } else if (monthInicio != monthFecho) {
        eventoAMostrar.date =
          dayInicio +
          " " +
          monthInicioToString +
          " a " +
          dayFecho +
          " " +
          monthFechoToString +
          " " +
          yearFecho;
      }
    }

    setEvento(eventoAMostrar);
  };

  useEffect(() => {
    fetch(`https://api.spap.pt/api/all-casos-do-mes`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0) {
          console.log("nenhum evento");
        }
        getPost(data.caso_do_mes, slug.split("-").pop());
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    fetch(`https://api.spap.pt/api/get-respostas/` + slug.split("-").pop(), {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        if (data?.length === 0) {
          return;
        }

        const buscaRespostaUsuario = data.respostas.find(
          (r) => r.id_usuario === session_storage.all_data.user_session.id
        );
        if (buscaRespostaUsuario) {
          setValue(buscaRespostaUsuario.resposta);
          setRespostaUsuario(buscaRespostaUsuario.resposta);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  const handleShare = (link) => {
    navigator.clipboard.writeText(link);
    notification.info({ description: "Link copiado: " + link });
  };

  const [value, setValue] = useState(1);
  const onChange = (e) => {
    if (respostaUsuario) {
      return false;
    }
    setValue(e.target.value);
  };

  const [respostaUsuario, setRespostaUsuario] = useState("");
  const enviarResposta = (value) => {
    if (respostaUsuario) {
      return false;
    }
    const busca = JSON.parse(evento.respostas).find(
      (r) => r["texto"] === value
    );

    axios
      .post("https://api.spap.pt/api/set-resposta", {
        id_caso_do_mes: slug.split("-").pop(),
        id_usuario: session_storage.all_data.user_session.id,
        resposta: value,
        resposta_correta: busca["resposta"],
      })
      .then((res) => {
        if (res.status == 200) {
          setRespostaUsuario(value);
        }
      })
      .catch((error) => {
        console.log("erro ao responder");
      });
  };

  return (
    <Wrapper>
      <Container>
        <PageTitle
          title={evento.titulo}
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "Ferramentas úteis",
              link: "/ferramentas-uteis",
            },
            {
              text: "Caso do mês",
              link: "/ferramentas-uteis/caso-do-mes",
            },
            {
              text: evento.titulo,
            },
          ]}
          imageDesktop={
            "https://api.spap.pt/caso_do_mes/" +
            evento.id +
            "/" +
            evento.imagem_destaque
          }
          imageMobile={
            "https://api.spap.pt/caso_do_mes/" +
            evento.id +
            "/" +
            evento.imagem_destaque
          }
          imageAlt={evento.titulo}
        />

        <Tab>
          <FlexInColumn gap="1.823vw">
            <FlexInColumn gap="0.833vw">
              <span>
                <FontAwesomeIcon icon={faUser} className="icon icon-location" />
                {evento.autor}
              </span>
            </FlexInColumn>
            <FlexInColumn gap="0.833vw">
              <span>
                <FontAwesomeIcon
                  icon={faLocationDot}
                  className="icon icon-location"
                />
                {evento.instituicao}
              </span>
            </FlexInColumn>
          </FlexInColumn>
        </Tab>

        <FlexInRow gap="3.646vw">
          <FlexInColumn gap="230px" mobile="column">
            <FlexInRow className="event-content">
              <div
                className="event__content"
                dangerouslySetInnerHTML={{ __html: evento.descricao }}
              ></div>
              {evento.questao && JSON.parse(evento.respostas)?.length > 0 && (
                <CardQuestoes>
                  <h3>{evento.questao}</h3>
                  <Radio.Group onChange={onChange} value={value}>
                    <Space direction="vertical">
                      {JSON.parse(evento.respostas).map((option, index) => (
                        <Radio
                          key={index}
                          value={option.texto}
                          className={
                            respostaUsuario !== "" && option.resposta
                              ? "correta"
                              : respostaUsuario === option.texto &&
                                !option.resposta &&
                                "errada"
                          }
                        >
                          {option.texto}
                          {respostaUsuario !== "" && option.resposta ? (
                            <small style={{ marginLeft: "10px" }}>
                              <i> (Resposta correta!)</i>
                            </small>
                          ) : (
                            respostaUsuario === option.texto &&
                            !option.resposta && (
                              <small style={{ marginLeft: "10px" }}>
                                <i> (Resposta errada!)</i>
                              </small>
                            )
                          )}
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                  <div style={{ marginTop: "30px" }}>
                    <Button
                      type="primary"
                      className="cta"
                      onClick={() => enviarResposta(value)}
                      disabled={respostaUsuario ? true : false}
                    >
                      Responder
                    </Button>
                  </div>
                </CardQuestoes>
              )}
              {/*
                #TODO: Proxima entrega trabalhar mais as paginas de ver todos
                props.isBolsa &&
                <div className="event__content">
                  <Link to="/spap/bolsas-e-premios/ver-todas" className="link">
                    <p>
                      Veja mais sobre todas as bolsas e prémios aqui
                    </p>
                    </Link>
                </div>
                */}
            </FlexInRow>
            <div>
              <ul>
                {/*}
                <li className='link-disabled'>
                  <Link to="/">
                    <img src={IconSubscribe} alt="Icon Subscribe" className="icon icon-subscribe" />
                    Inscrever
                  </Link>
                </li>
                <li className='link-disabled'>
                  <Link to="/">
                    <img src={IconSeeMore} alt="Icon See More" className="icon icon-see-more" />
                    Saber mais
                  </Link>
                </li>
                {*/}
                <li>
                  <span
                    className="share"
                    onClick={() => handleShare(window.location.href)}
                  >
                    <img
                      src={IconShare}
                      alt="Icon Share"
                      className="icon icon-share"
                    />
                    Partilhar
                  </span>
                </li>
                <li>
                  <a href="javascript:history.back()">
                    <img
                      src={IconReturn}
                      alt="Icon Return"
                      className="icon icon-return"
                    />
                    Voltar
                  </a>
                </li>
              </ul>
            </div>
          </FlexInColumn>

          {/* <FlexInRow>
            <Title>
              <FontAwesomeIcon icon={faFileArchive} className="icon icon-file-archive" />
              Trabalhos Realizados por Sócios
            </Title>
            <FlexInRow gap="3.646vw">
              <FlexInColumn>
                <Text>
                  <img src={IconFile} alt="Icon File" className="icon icon-file" />
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam uiof nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
                    <Link to="/">Clique para visualizar</Link>
                  </p>
                </Text>
                <Text>
                  <img src={IconFile} alt="Icon File" className="icon icon-file" />
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam uiof nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
                    <Link to="/">Clique para visualizar</Link>
                  </p>
                </Text>
              </FlexInColumn>
            </FlexInRow>
          </FlexInRow> */}
        </FlexInRow>
      </Container>

      <SectionShareContent>
        <Container>
          <FlexInRow gap="0">
            <h2 className="title">AJUDE-NOS A DIVULGAR EVENTOS E CONTEÚDOS</h2>
            <p className="text">
              Contribua para a divulgação de eventos científicos
            </p>

            <FlexInColumn gap="2.500vw" mobile="column">
              <SectionMembers>
                <SectionMembersContent>
                  <SectionMembersImage>
                    <picture>
                      <source
                        srcSet={require("../../../../Assets/Images/img-anatomia-04@2x.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../../Assets/Images/img-anatomia-04.png")}
                        alt="Seja um Membro"
                      />
                    </picture>
                  </SectionMembersImage>
                  <SectionMembersTitle>
                    Pretende divulgar um evento?
                  </SectionMembersTitle>
                  <SectionMembersText>
                    Tem conhecimento de um evento que poderá ter interesse para
                    os nossos associados? Partilhe connosco.
                  </SectionMembersText>
                  <SectionMembersCta href="#">
                    Preencher formulário »
                  </SectionMembersCta>
                </SectionMembersContent>
              </SectionMembers>

              <SectionMembers>
                <SectionMembersContent>
                  <SectionMembersImage>
                    <picture>
                      <source
                        srcSet={require("../../../../Assets/Images/img-anatomia-04@2x.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../../Assets/Images/img-anatomia-04.png")}
                        alt="Seja um Membro"
                      />
                    </picture>
                  </SectionMembersImage>
                  <SectionMembersTitle>
                    Quer partilhar um trabalho?
                  </SectionMembersTitle>
                  <SectionMembersText>
                    Pretende divulgar um trabalho que apresentou num evento?
                    Preencha o formulário e submeta o seu trabalho.
                  </SectionMembersText>
                  <SectionMembersCta href="#">
                    Preencher formulário »
                  </SectionMembersCta>
                </SectionMembersContent>
              </SectionMembers>
            </FlexInColumn>
          </FlexInRow>
        </Container>
      </SectionShareContent>
    </Wrapper>
  );
}
