import React, { useRef, useState, useEffect } from "react";
import { DatePicker, Divider, Row, Col, Input, Upload, Select,  Typography } from 'antd'
const { Dragger } = Upload;
const { Text } = Typography;
const { TextArea } = Input;
import dayjs from 'dayjs';

var styles = {
    h6_label: {
      color: '#809FB8',
      fontFamily: 'Roboto',
      fontSize: '15px',
       marginBottom: '1%'
    },
    input_class: {
      width: '100%', borderRadius: '6px', height: '45px', border: '1px solid #809FB8', color: '#0C1151'
    },
    switch_label: {
      fontFamily: 'Roboto' ,fontSize: '16px' ,fontWeight: 'bold', height: '45px', borderRadius: '8px', padding: '0px 16px', color: '#809FB8', border: '1px solid #809FB8', textAlign: 'center'
    },
    upload: {
      width: '100%'
    },
    input_class2: {
      width: '100%', borderRadius: '6px', height: '45px', border: '1px solid #ccc', color: '#0C1151'
    },
  }

export default function SecondTab ( props ) {
  const ref = useRef(null);
  
  let userDrawer = props.user;
  const session_storage = JSON.parse(localStorage.getItem('session_data'));

  const [cidade, setCidade] = useState('');
  const [dataNascimento, setDataNascimento] = useState(null); 
  const onChangeData = (date, dateString) => {
    setDataNascimento(dateString)
  };

  useEffect(() => {
    setCidade(userDrawer?.cidade)

    if (
      userDrawer?.data_nascimento != null && userDrawer?.data_nascimento !== ''
     ) {
      let parsedDate = dayjs(userDrawer?.data_nascimento, 'DD/MM/YYYY', true);

      if (parsedDate.isValid()) {
        setDataNascimento(userDrawer?.data_nascimento) 
      } else {
        setDataNascimento(dayjs(userDrawer?.data_nascimento).format("DD/MM/YYYY"))
      }
     } else {
      setDataNascimento(null)
     }
  }, [props.user])

  return (
    <>
          <Col style={{ padding: '30px', borderRadius: '16px', background: '#D6D7E2'}}>
                <h5 style={{ marginBottom: '10px', fontSize: '18px' }}>Dados Administrativos</h5>
                <p>Esta secção é privada, e apenas a administração SPAP tem acesso a ver os seus dados</p>
                <Divider style={{ background: '#fff'}}></Divider>
              <Row style={{ padding: '6px 0' }}  gutter={[8, 8]}>
                  <Col span={12}>
                  <h6 style={styles.h6_label}>Nome/Entidade para faturação</h6>
                  <Input 
                   key={'nomefaturacao-' + userDrawer.id} name="nome_faturacao" placeholder="Nome/Entidade para faturação" style={styles.input_class } defaultValue={userDrawer.nome_faturacao} />
                              </Col>
                                  <Col span={12}>
                  <h6 style={styles.h6_label}>NIF para faturação</h6>
                  <Input key={'nif_instituicao-' + userDrawer.id} name='nif_instituicao'  placeholder="NIF para faturação" style={styles.input_class } defaultValue={userDrawer.nif_instituicao} />

                </Col>
                  </Row>
              <Row style={{ padding: '6px 0' }} align="bottom" gutter={[8, 8]}>
                  <Col span={12}> 
                  <h6 style={styles.h6_label}>Nº. Célula Profissional</h6>
                    <Input key={'numerocelula-' + userDrawer.id} name='numero_celula' placeholder="Nº. Célula Profissional" style={styles.input_class } defaultValue={userDrawer.numero_celula}/>
                  </Col>
                  <Col span={12} >
                    <h6 style={styles.h6_label}>Nº. de Sócio ESP</h6>
                    <Input key={'numeroesp-' + userDrawer.id} name='numero_esp' placeholder="Nº. de Sócio ESP" style={styles.input_class } defaultValue={userDrawer.numero_esp}/>
                  </Col>
                </Row>

                <Row style={{ padding: '6px 0' }} align="bottom" gutter={[8, 8]}>
                  <Col span={12}> 
                  <h6 style={styles.h6_label}>Cartão de cidadão</h6>
                    <Input key={'cartao_cidadao-' + userDrawer.id} name='cartao_cidadao'  placeholder="Cartão de cidadão" style={styles.input_class } defaultValue={userDrawer.cartao_cidadao} />
                  </Col>
                  <Col span={12} >
                    <h6 style={styles.h6_label}>Data de nascimento</h6>
                    {
                      /*
                    userDrawer.data_nascimento ?
                      <Input 
                      key={'data_nascimento-' + userDrawer.id}  name='data_nascimento' type="date" placeholder="Data de nascimento" style={styles.input_class } defaultValue={userDrawer.data_nascimento}  />
                      :
                      <Input
                                          
                                          key={'data_nascimento-' + userDrawer.id}
                                          name="data_nascimento"
                                          placeholder="Data de nascimento"
                                          type="text"
                                          onFocus={(e) => (e.target.type = "date")}
                                          onBlur={(e) => (e.target.value != '' ? e.target.type = "date" : e.target.type = "text")}
                                          style={styles.input_class } 
                                          defaultValue={userDrawer.data_nascimento ? userDrawer.data_nascimento : ''} />   
                    */}
                    <DatePicker
                    ref={ref}
      key={'data_nascimento-' + userDrawer.id}
      name="data_nascimento"
      onChange={onChangeData}
      format="DD/MM/YYYY"
      placeholder="Data de nascimento"
      style={styles.input_class}
      value={dataNascimento ? dayjs(dataNascimento, "DD/MM/YYYY") : null}
    />
                  </Col>
                  </Row>
                  <Row style={{ padding: '6px 0' }} gutter={[8, 8]}>
                  <Col span={12} >
                    <h6 style={styles.h6_label}>Morada</h6>
                    <Input key={'morada-' + userDrawer.id} name='morada'  placeholder="Morada" style={styles.input_class } defaultValue={userDrawer.morada} />
                  </Col>
                  <Col span={12} >
                    <h6 style={styles.h6_label}>Cidade</h6>
                    <Select 
                      style={{ lineheight: '45px' }}
                      key={'cidade-' + userDrawer.id}
                      className="cidade-select"
                        onChange={(e) => {setCidade(e)}}
                        name="cidade"
                        showSearch 
                        placeholder="Selecione uma Cidade" 
                        value={cidade ? cidade : ''}
                        optionFilterProp="children"
                        required>
                        {session_storage.cidades.map((cidade) => <Select.Option value={cidade.nome}> {cidade.nome} </Select.Option>)}
                      </Select>                  </Col>
                </Row>
                <Row style={{ padding: '6px 0' }} align="bottom" gutter={[8, 8]}>
                  <Col span={12}> 
                    <h6 style={styles.h6_label}>Código Postal</h6>
                    <Input key={'codigo_postal-' + userDrawer.id} name='codigo_postal'  placeholder="Código Postal" style={styles.input_class } defaultValue={userDrawer.codigo_postal} />
                  </Col>
                  <Col span={12}> 
                    <h6 style={styles.h6_label}>País</h6>
                    <Input key={'pais-' + userDrawer.id}  name='pais'  placeholder="País" style={styles.input_class } defaultValue={userDrawer.pais} />
                  </Col>
                </Row>
                <Row style={{ padding: '6px 0' }} align="bottom" gutter={[8, 8]}>
                  <Col span={12}> 
                    <h6 style={styles.h6_label}>Nome Completo</h6>
                    <Input key={'codigo_postal-' + userDrawer.id} name='nome_completo'  placeholder="Código Postal" style={styles.input_class } defaultValue={userDrawer.nome_completo} />
                  </Col>
                  <Col span={12}> 
                    <h6 style={styles.h6_label}>NIF Pessoal</h6>
                    <Input key={'pais-' + userDrawer.id}  name='numero_contribuinte'  placeholder="NIF Pessoal" style={styles.input_class } defaultValue={userDrawer.numero_contribuinte} />
                  </Col>
                </Row>
          </Col>
    </>
  );
};