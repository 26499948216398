import axios from "axios";

class AuthService {
  // SERVER_URL = "https://api.spap.pt";

  async login(email, password) {
    const response = await axios.post("https://api.spap.pt/api/login", {
      email,
      password,
    });
    if (response.data.token) {
      const cidades = await this.getCidades();
      cidades.map((cidade) => {
        delete cidade.freguesias;
      });
      response.data.cidades = cidades;
      response.session_time = new Date();

      this.handleMessages(response.data);

      localStorage.setItem("session_data", JSON.stringify(response.data));
    }
    return response.data;
  }

  async recuperarPassword(email) {
    const response = await axios.post(
      "https://api.spap.pt/api/forgot-password",
      {
        email,
      }
    );

    return response;
  }

  async alteraPassword(email, token, password) {
    const response = await axios.post("https://api.spap.pt/api/set-password", {
      email,
      token,
      password,
    });

    return response;
  }

  async getCidades() {
    try {
      const response = await fetch(
        "https://json.geoapi.pt/municipios/freguesias"
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error("Erro ao buscar dados: ", error);
      return [
        { nome: "Lisboa" },
        { nome: "Porto" },
        { nome: "Aves" },
        { nome: "Sintra" },
        { nome: "Vila Nova de Gaia" },
        { nome: "Cascais" },
        { nome: "Loures" },
        { nome: "Braga" },
        { nome: "Almada" },
        { nome: "Matosinhos" },
        { nome: "Amadora" },
        { nome: "Oeiras" },
        { nome: "Gondomar" },
        { nome: "Seixal" },
        { nome: "Guimarães" },
        { nome: "Odivelas" },
        { nome: "Coimbra" },
        { nome: "Vila Franca de Xira" },
        { nome: "Maia" },
        { nome: "Famalicão" },
        { nome: "Leiria" },
        { nome: "Setúbal" },
        { nome: "Barcelos" },
        { nome: "Funchal" },
        { nome: "Viseu" },
        { nome: "Viana do Castelo" },
        { nome: "Barreiro" },
        { nome: "Aveiro" },
        { nome: "Queluz" },
        { nome: "Ponta Delgada" },
        { nome: "Faro" },
        { nome: "Santarém" },
        { nome: "Évora" },
        { nome: "Castelo Branco" },
        { nome: "Vila Real" },
        { nome: "Rio Tinto" },
        { nome: "Amora" },
        { nome: "Corroios" },
        { nome: "Guarda" },
        { nome: "Mafamude" },
        { nome: "Ermezinde" },
        { nome: "Beja" },
        { nome: "Agualva" },
        { nome: "Bragança" },
        { nome: "Espinho" },
        { nome: "Póvoa de Santa Iria" },
        { nome: "Senhora da Hora" },
        { nome: "Massamá" },
        { nome: "Águas Santas" },
        { nome: "Estoril" },
        { nome: "Portalegre" },
        { nome: "São João da Madeira" },
        { nome: "Entroncamento" },
        { nome: "Feira" },
      ]; // Retornando um array vazio como valor padrão
    }
  }

  logout() {
    localStorage.removeItem("session_data");
  }

  isAuthenticated() {
    return JSON.parse(localStorage.getItem("session_data"));
  }

  handleMessages = (data) => {
    data.messages = data.messages.filter((msg) => {
      if (!msg.deleted_by) return msg;
      if (!JSON.parse(msg.deleted_by).includes(data.all_data.user_session.id))
        return msg;
    });
    data.messages.forEach((message) => {
      let otherUser =
        message.to_user != data.all_data.user_session.id
          ? message.to_user
          : message.from_user;
      message.subjectName =
        data.all_data.user_session.id + "-" + otherUser + "-" + message.subject;
    });
    let myMessages = Object.groupBy(data.messages, ({ subjectName }) => [
      subjectName,
    ]);
    let arrayNames = Object.keys(myMessages);
    let listMessages = [];

    let count = 0;
    arrayNames.forEach((array) => {
      let thread = myMessages[array];
      let sorted = thread.sort((b, a) => b.id - a.id)[
        myMessages[array].length - 1
      ];

      if (sorted.to_user == data.all_data.user_session.id) {
        count += 1;
      }
    });
    localStorage.setItem("pendingMsg", count);
  };

  async register(
    tipoUtilizador,
    tipoSocio,
    email,
    nomeCompleto,
    nome,
    apelido,
    password,
    telefone,
    area,
    area2,
    instituicao,
    grau,
    cidade,
    dataNascimento,
    celulaProfissional,
    sociosProponentes,
    nomeFaturacao,
    contribuinte,
    categoriaProfissional
  ) {
    const response = await axios.post("https://api.spap.pt/api/register", {
      tipoUtilizador,
      tipoSocio,
      email,
      nome,
      apelido,
      nomeCompleto,
      password,
      telefone,
      area,
      area2,
      instituicao,
      grau,
      cidade,
      dataNascimento,
      celulaProfissional,
      sociosProponentes,
      nomeFaturacao,
      contribuinte,
      categoriaProfissional,
    });

    if (response.data.token) {
      const cidades = await this.getCidades();
      cidades.map((cidade) => {
        delete cidade.freguesias;
      });
      response.data.cidades = cidades;
      localStorage.setItem("session_data", JSON.stringify(response.data));
    }

    return response.data;
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("session_data"));
  }
}

export default new AuthService();
