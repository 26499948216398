import styled from "styled-components";
import { Link } from "react-router-dom";
import FlexInColumn from "../FlexInColumn";
import FlexInRow from "../FlexInRow";

import { Breadcrumb } from "antd";

const Wrapper = styled.section`
  padding: 45px 0px 20px 0px;

  @media (max-width: 992px) {
    padding: 00px 0px 10px 0px;
  }
  ${(props) =>
    props.sidebar &&
    `
    align-items: flex-start;  
    display: grid;
    grid-template-columns: 1fr auto;
  `}

  // picture {
  //   width: 7.813vw;
  // }

  .blank {
    border: 3px solid ${(props) => props.theme.blue};
    border-radius: 100%;
    height: 7.813vw;
    width: 7.813vw;
  }

  img {
    ${(props) =>
      props.border &&
      `
      border: 3px solid ${(props) => props.theme.blue};
      border-radius: 100%;
      overflow: hidden;
    `}

    height: 100px;
    width: 100px;
    border-radius: 50px;
  } 

  .image-clube {
    background-repeat: no-repeat;
    background-size: cover;
    width: 120px;
    height: 120px; 
    background-position: center center;  
    border-radius: 100px;
    border: 3px solid ${(props) => props.theme.blue};
 
    ${(props) =>
      props.clubes &&
      `
      background-size: 200%;
      background-position: center -5px;
    `}

    @media (max-width: 992px) {
      width: 150px;
      height: 150px;
      margin: auto;
    }
  }

  #breadcrumb {
    margin-left: -4px;
  }

  #breadcrumb ol {
    gap: 0px;
    justify-content: flex-start;
    @media (max-width: 992px) {
    justify-content: center;
    }
  }

  #breadcrumb li {
    align-items: center;
    display: inline-flex;
    gap: 8px;
  }

  #breadcrumb li * {
    text-align: left;
    font-size: 0.9375vw;
    font-weight: normal;
    line-height: 1.562vw;
    letter-spacing: 0px;
    color: ${(props) => props.theme.blue};
    opacity: 1;
    margin: 0;

    @media (max-width: 992px) {
      font-size: 2.778vw;
      line-height: 8.333vw;
      text-align: center;
    }
  }

  #breadcrumb li a {
    text-decoration: underline;
    transition: 0.3s ease;
  }

  #breadcrumb li:hover a {
    text-decoration: none;
  }

  @media (max-width: 992px) {
    picture,
    .blank {
      margin: auto;
      height: min-content;
      width: 180px;
    }

    #breadcrumb li * {
      font-size: 13px;
      line-height: 19px;
    }

    ${(props) =>
      props.sidebar &&
      `
      grid-template-columns: 1fr;
    `}
  }

  @media (min-width: 1920px) {
    padding: 50px 0;
    width: 1640px;
    ${(props) =>
      props.sidebar &&
      `
      gap: 30px;
      grid-template-columns: 1fr auto;
    `}

    #breadcrumb li * {
      font-size: 16px;
      line-height: 30px;
    }

    picture,
    .blank,
    .image-clube {
      height: 150px;
      width: 150px;
    }
  }
`;

const Title = styled.h1`
  text-align: left;
  font-size: 3.125vw;
  font-weight: bold;
  line-height: 3.75vw;
  letter-spacing: 0px;
  color: ${(props) => props.theme.blue};
  text-transform: uppercase;
  opacity: 1;
  margin: 0;

  @media (max-width: 992px) {
    font-size: 36px;
    line-height: 42px;
    text-align: center;
  }

  @media (min-width: 1920px) {
    font-size: 55px;
    line-height: 72px;
  }
`;

export default function PageTitle(props) {
  if (props.title)
    document.title = (props.nispap ? "NISPAP - " : "SPAP - ") + props.title;

  let listPath = "";
  if (Array.isArray(props.path)) {
    listPath = props.path.reduce((acc, item, index) => {
      acc.push(
        <Breadcrumb.Item
          key={"breadcrumb" + index}
          className={"item item-" + index}
        >
          {item.link ? (
            <Link key={"breadcrumbLink" + index} to={item.link}>
              {item.text}
            </Link>
          ) : (
            item.text
          )}
        </Breadcrumb.Item>
      );
      return acc;
    }, []);
  }

  return (
    <Wrapper
      border={props.border}
      sidebar={props.sidebar && "true"}
      clubes={props.clubes && "true"}
    >
      <FlexInColumn mobile="column">
        {props.imageDesktop || props.imageMobile ? (
          <picture>
            <source srcSet={props.imageDesktop} media="(min-width: 990px)" />
            <div
              className="image-clube"
              style={{ backgroundImage: "url(" + props.imageMobile + ")" }}
              alt={props.imageAlt}
            ></div>
          </picture>
        ) : (
          <div className="blank"></div>
        )}
        <FlexInRow gap="0" placeself="center">
          <Title nspap={props.nspap}>{props.title}</Title>
          {listPath.length && (
            <Breadcrumb id="breadcrumb" separator="•">
              {listPath}
            </Breadcrumb>
          )}
        </FlexInRow>
      </FlexInColumn>
      {props.sidebar && props.sidebar}
    </Wrapper>
  );
}
