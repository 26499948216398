import styled from "styled-components";

import Container from "../../../Components/Container";
import PageTitle from "../../../Components/PageTitle";
import PageText from "../../../Components/PageText";
import FlexInColumn from "../../../Components/FlexInColumn";
import FlexInRow from "../../../Components/FlexInRow";
import ContactForm from "../../../Components/ContactForm";
import { Helmet } from "react-helmet";
import { Row } from "antd";
import { useEffect } from "react";
const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  > .content {
    margin-bottom: 2.188vw;
  }

  .sidebar__image {
    margin-left: auto;
    max-width: 27.917vw;
  }

  img {
    width: 100%;
  }

  a {
    color: #000000;
    place-self: flex-start;
    text-align: left;
    text-decoration: none;
    transition: 0.3s ease;

    :hover {
      color: ${(props) => props.theme.blueHover};
    }
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    a {
      font-size: 3.333vw;
      line-height: 4.444vw;
      place-self: center;
    }

    .sidebar__image {
      margin: auto;
      max-width: 80%;
    }
  }
`;

export default function Instagram(props) {

  return (
    <Wrapper>
      <Helmet>
        <title>
          Instagram NISPAP | Núcleo de Internos Sociedade Portuguesa de Anatomia
          Patológica - NISPAP
        </title>
        <meta
          name="description"
          content="O Núcleo de Internos está sempre ao seu dispor. Entre em contacto e coloque-nos as suas dúvidas."
        />
        <meta
          name="keywords"
          content="nispap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      <Container className="content">
        <PageTitle
          title="Instagram"
          path={[
            {
              text: "NISPAP",
              link: "/nispap",
            },
            {
              text: "Instagram",
            },
          ]}
          imageDesktop={require("../../../Assets/Images/img-contactos@2x.png")}
          imageMobile={require("../../../Assets/Images/img-contactos.png")}
          imageAlt="Contactos"
        />

        <Row className="mb-negative">
          <div class="elfsight-app-66f3e0be-16e8-48b0-87d0-931c5879c6d5" data-elfsight-app-lazy></div>
        </Row>
      </Container>

    </Wrapper>
  );
}
