import React from 'react'
import styled from 'styled-components'
import { Form, Input, Button, Select, Upload, Space, Row } from 'antd'

import { ReactComponent as IconUpload } from '../../Assets/Images/Backoffice/Upload-off.svg'

const { Option } = Select

const Wrapper = styled.div`
  .input-upload {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 6px 11px;
  }

  .icon {
    height: 32px;
  }

  .input-submit {
    align-items: center;
    justify-content: flex-end;
    display: flex;
  }

  .cta {
    background-color: #F88436 !important;
  }
`

export default function UserUploadPayment(props) {
  const onFinish = (values) => {
    console.log('Form values:', values)
  }

  return (
    <Wrapper>
      <Form onFinish={onFinish}>
        <Space size={16} align='stretch' style={{width: '100%'}}>
          <Form.Item
            name='select'
            rules={[{ required: true, message: 'Selecione uma opção' }]}
            style={{width: '100%'}}
          >
            <Select
              placeholder='Selecione o tipo de comprovativo a enviar'
            >
              <Option value='option1'>Option 1</Option>
              <Option value='option2'>Option 2</Option>
              <Option value='option3'>Option 3</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name='upload'
            valuePropName='fileList'
            getValueFromEvent={(e) => e.fileList}
            rules={[{ required: true, message: 'Submeta um arquivo' }]}
          >
            <Upload name='logo' action='/upload.do' listType='picture'>
              <Space size={20} align='center' className='input-upload'>
                Faça upload do ficheiro (PDF/jpeg/png/word)
                <IconUpload className='icon icon-upload' />
              </Space>
            </Upload>
          </Form.Item>
        </Space>

        <Form.Item
          name='description'
        >
          <Input.TextArea rows={4} placeholder='Mensagem adicional...' />
        </Form.Item>

        <Form.Item className='input-submit'>
          <Button type='primary' htmlType='submit' className='cta'>
            Enviar
          </Button>
        </Form.Item>
      </Form>
    </Wrapper>
  )
}
