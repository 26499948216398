import styled from "styled-components";
import { Link } from "react-router-dom";
import Container from "../../../Components/Container";
import FlexInRow from "../../../Components/FlexInRow";
import FlexInColumn from "../../../Components/FlexInColumn";
import PageTitle from "../../../Components/PageTitle";
import PageText from "../../../Components/PageText";
import ContactForm from "../../../Components/ContactForm";
import { Helmet } from "react-helmet";
const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  > .content {
    padding-bottom: 4vw;
  }

  img {
    width: 100%;
  }

  h3 {
    text-align: left;
    letter-spacing: 0px;
    font-size: 16px;
    line-height: 1.45vw;
    font-weight: bold;
    color: #0c1151;
    display: inline-flex;
    gap: 4px;

    a {
      color: ${(props) => props.theme.orange};
      font-weight: normal;
    }
  }

  h3:first-of-type {
    flex-direction: column;

    a {
      margin-right: auto;
    }
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    h3 {
      font-size: 4.444vw;
      line-height: 5.555vw;
    }
  }
`;

const Image = styled.picture`
  @media (min-width: 992px) {
    margin-left: auto;
    margin-bottom: -200px;
    width: 28.072vw;
  }

  @media (max-width: 992px) {
    margin: auto;
  }
`;

const Faq = styled.ul`
  padding-left: 10px;

  > li {
    text-align: left;
    font-size: 1.042vw;
    font-weight: bold;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    margin: 0 0 2.604vw;

    p {
      font-size: 0.938vw;
      font-weight: normal;
      line-height: 1.25vw;
      color: #000000;
      margin-top: 1.042vw;
    }

    a {
      color: #f88436;
      text-decoration: none;
    }
  }

  @media (min-width: 992px) {
    li:nth-child(1),
    li:nth-child(3) {
      margin-right: 34.072vw;
    }
  }

  @media (max-width: 992px) {
    > li,
    p {
      font-size: 4.444vw;
      line-height: 5.555vw;
    }

    > li p {
      font-size: 3.444vw;
      line-height: 4.555vw;
    }
  }
`;

const Bar = styled.div`
  background-color: #d6d7e2;
  height: 1px;

  @media (min-width: 992px) {
    margin-bottom: 2.604vw;
    margin-left: 17.968vw;
    margin-right: 34.072vw;
    width: 35.104vw;
  }

  @media (max-width: 992px) {
    margin: 0 auto 20px;
    width: 60vw;
  }
`;

export default function PerguntasFrequentes(props) {
  return (
    <Wrapper>
      <Helmet>
        <title>
          Perguntas Frequentes | Sociedade Portuguesa de Anatomia Patológica -
          SPAP
        </title>
        <meta
          name="description"
          content="Não encontra a resposta à sua questão? Precisa de saber mais detalhes?"
        />
        <meta
          name="keywords"
          content="spap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      <Container className="content">
        <PageTitle
          title="Perguntas Frequentes"
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "Sócios",
              link: "/socios",
            },
            {
              text: "Perguntas frequentes",
            },
          ]}
          imageDesktop={require("../../../Assets/Images/img-perguntas-frequentes-top@2x.png")}
          imageMobile={require("../../../Assets/Images/img-perguntas-frequentes-top@2x.png")}
          imageAlt="Perguntas frequentes"
        />

        <FlexInRow gap="3.646vw">
          <FlexInColumn gap="1.563vw" mobile="column">
            <FlexInRow>
              <h3>
                Não encontra a resposta à sua questão? Precisa de saber mais
                detalhes? <Link to="/spap/contactos">Contacte-nos</Link>
              </h3>
              <h3>
                através dos emails ou do <a href="#form">formulário abaixo</a>
              </h3>
            </FlexInRow>
            <Image>
              <source
                srcSet={require("../../../Assets/Images/img-perguntas-frequentes@2x.png")}
                media="(min-width: 990px)"
              />
              <img
                src={require("../../../Assets/Images/img-perguntas-frequentes.png")}
                alt="Seja um Membro"
              />
            </Image>
          </FlexInColumn>

          <Faq>
            <li>
              Qual o objetivo e missão da SPAP?
              <p>
                A SPAP é uma sociedade científica sem fins lucrativos que reúne
                profissionais de saúde da área de Anatomia Patológica. Saiba
                mais <Link to="/spap/quem-somos">aqui</Link>.
              </p>
            </li>
            <Bar />
            <li>
              Qual a relação entre a SPAP e o Colégio da Especialidade?
              <p>
                A SPAP é uma sociedade que se propõe a dinamizar atividades e
                iniciativas de carácter científico com o objetivo da formação
                contínua dos seus sócios. O Colégio da Especialidade, parte
                integrante da Ordem dos Médicos, procura formalizar e
                regulamentar a atividade assistencial e a formação em Anatomia
                Patológica.
              </p>
            </li>
            <Bar />
            <li>
              Quem pode integrar a SPAP e como posso tornar-me sócio?
              <p>
                Podem integrar a SPAP, na qualidade de sócios titulares, Médicos
                Especialistas em Anatomia Patológica e Médicos Internos da
                Formação Específica em Anatomia Patológica; cada novo sócio terá
                de ser proposto por dois sócios (Médicos Especialistas) à
                Direção da SPAP e a sua admissão será submetida a voto em
                Assembleia Geral. <br />A SPAP prevê ainda a existência de
                sócios agregados e de sócios honorários.
              </p>
            </li>
            <Bar />
            <li>
              Qual o valor da quota anual?
              <p>
                Atualmente, a quota da SPAP é de 30€. <br />
                Enquanto Médico Especialista, pode ainda optar-se pela
                liquidação da quota enquanto collective member, que inclui o
                valor da quota da European Society of Pathology/ESP, perfazendo
                um total de 70€.
              </p>
            </li>
            <Bar />
            <li>
              Quais as vantagens e benefícios de ser sócio da SPAP?
              <p>
                Ser sócio da SPAP é sinónimo de tomar parte ativa num grupo
                dinâmico com experiências e visões diversificadas sobre todas as
                áreas de Anatomia Patológica. <br />
                Ser sócio da SPAP permite ainda obter descontos no valor da
                inscrição em eventos científicos nacionais e internacionais.{" "}
                <br />
                Consulte mais informação{" "}
                <Link to="/socios/conheca-as-vantagens">aqui</Link>.
              </p>
            </li>
            <Bar />
            <li>
              A SPAP tem uma sede física?
              <p>
                A SPAP tem sede social na Avenida Dom Afonso Henriques, n.º 39,
                3000-011 Coimbra.
              </p>
            </li>
            <Bar />
            <li>
              Qual a duração de cada mandato dos órgãos sociais da SPAP?
              <p>Cada mandato tem a duração de 3 anos.</p>
            </li>
            <Bar />
            <li>
              A votação tem de ser sempre presencial?
              <p>
                Não; o voto para a eleição de corpos sociais pode também ser
                realizado por via telemática, por correspondência enviada à
                morada da SPAP e endereçada o Presidente da Mesa da Assembleia
                Geral ou por nomeação de uma procuração.
              </p>
            </li>
            <Bar />
            <li>
              A SPAP atribui bolsas de formação?
              <p>
                A SPAP atribui anualmente uma bolsa de formação com valor
                pecuniário de 500€ a um(a) Médico(a) Interno(a) criteriosamente
                selecionado após um processo de candidatura estruturado. <br />
                Saiba mais <Link to="/spap/bolsas-e-premios">aqui</Link>.
              </p>
            </li>
            <Bar />
            <li>
              A SPAP patrocina acesso a fontes bibliográficas?
              <p>
                Atualmente, a SPAP não prevê apoio financeiro na aquisição de
                livros, jornais ou revistas, quer impressos quer online.
                Contudo, visite regularmente este site para eventuais novidades.
              </p>
            </li>
            <Bar />
            <li>
              A SPAP tem grupos de trabalho?
              <p>
                A SPAP integra o Núcleo de Internos (NI-SPAP, consulte{" "}
                <Link to="/nispap">aqui</Link>) e prevê a existência de Clubes
                de patologia (saiba mais{" "}
                <Link to="/socios/clubes-de-estudo">aqui</Link>).
              </p>
            </li>
            <Bar />
            <li>
              Existe alguma plataforma/base de dados com informação acerca de
              projectos de investigação inter-institucionais?
              <p>
                Sim. Consulte a secção{" "}
                <i>
                  “<Link to="/ferramentas-uteis/parcerias">Parcerias</Link>”
                </i>
                .
              </p>
            </li>
            <Bar />
            <li>
              Que sociedades científicas colaboram com a SPAP?
              <p>
                A SPAP colabora com várias sociedades científicas, nacionais e
                internacionais, entre elas: <br />▪ European Society of
                Pathology (
                <a
                  href="https://www.esp-pathology.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.esp-pathology.org/
                </a>
                ) <br />▪ Sociedade Portuguesa de Citologia (
                <a
                  href="https://spcitologia.pt/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://spcitologia.pt/
                </a>
                )
              </p>
            </li>
            <Bar />
            <li>
              A SPAP tem alguma publicação?
              <p>
                A SPAP envia aos seus sócios uma newsletter semestral e está
                atualmente a preparar a edição da sua Revista (saiba mais{" "}
                <Link to="/ferramentas-uteis/revista">aqui</Link>).
              </p>
            </li>
            <Bar />
            <li>
              Quais os eventos organizados pela SPAP?
              <p>
                A SPAP promove a organização de um congresso nacional a cada 2
                anos e apoia a realização de vários cursos científicos. <br />
                Consulte a calendarização <Link to="/agenda">aqui</Link>.
              </p>
            </li>
          </Faq>
        </FlexInRow>
      </Container>

      <ContactForm id="form" />
    </Wrapper>
  );
}
