import React from 'react';
import { Result, Button } from 'antd';
import { ToolOutlined } from '@ant-design/icons';
import { ReactComponent as IconMaintenance } from "../../../Assets/Images/maintenance.svg";

export default function MaintenancePage(props) {
    return (
        <div style={{ height: '80vh', marginTop: '20vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Result
                icon={<IconMaintenance  className="main-img"/>}
                title="Estamos em manutenção"
                subTitle="Desculpe-nos pelo inconveniente. Estamos a melhorar os pagamentos das quotas e voltaremos em breve!"
                extra={
                    <Button type="primary" href="https://spap.pt" size="large" className='bg-button-main'>
                        Voltar à página inicial
                    </Button>
                }
            />
        </div>
    );
};
