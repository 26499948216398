import { Link } from "react-router-dom";
import styled from "styled-components";

import Container from "../../Components/Container";
import FlexInRow from "../../Components/FlexInRow";
import { Helmet } from "react-helmet";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #000000;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;
  overflow-x: hidden;

  img {
    width: 100%;
  }

  h2 {
    text-align: left;
    font-size: 22px;
  }

  li {
    margin: 5px 0;
  }

  .row {
    margin-bottom: 30px;
  }

  @media (max-width: 992px) {
    margin-top: 12.222vw;
  }
`;

export default function PoliticaPrivacidade(props) {
  return (
    <Wrapper>
      <Helmet>
        <title>
          Política Privacidade | Sociedade Portuguesa de Anatomia Patológica -
          SPAP
        </title>
        <meta
          name="description"
          content="Anatomia Patológica, Nacionais | Anatomia Patológica, Internacionais | Clínicas, Nacionais"
        />
        <meta
          name="keywords"
          content="spap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      <Container>
        <FlexInRow className="row">
          <h1 style={{ marginBottom: "30px" }}>Política Privacidade</h1>
          <p>
            A Sociedade Portuguesa de Anatomia Patológica, doravante designada
            por SPAP, fundada em 25 de outubro de 1963, por tempo indeterminado,
            é uma Associação de direito privado, de índole científica, sem fins
            lucrativos, que visa o aperfeiçoamento técnico-científico da
            especialidade de Anatomia Patológica, bem como o dos seus
            associados. A SPAP, pessoa coletiva 503 277 223, sedeada na Avenida
            Dom Afonso Henriques, nº 39 – 3000-011 Coimbra, integra a Divisão
            Portuguesa da International Academy of Pathology, sendo membro da
            mesma e sua representante exclusiva em Portugal.
          </p>
          <p>
            No âmbito da sua atividade a SPAP procede ao tratamento de dados
            pessoais de vários titulares de dados designadamente associados,
            formadores, formandos, subscritores da newsletter, candidatos,
            participantes de estudos e projetos de investigação científica e/ou
            de intervenção comunitária e/ou internacional e pessoas que a
            contactam, garantindo o respeito pelas melhores práticas no domínio
            da proteção de dados pessoais. Nesse sentido, a presente Política de
            Privacidade destina-se a transmitir aos titulares dos dados pessoais
            os termos do tratamento dos seus dados, os quais são tratados no
            estrito cumprimento do disposto na legislação em vigor,
            designadamente o Regulamento (UE) 2016/679 do Parlamento Europeu e
            do Conselho, de 27 de abril de 2016 que entrou em vigor a 25 de maio
            de 2018 (adiante, RGPD), e a Lei n.º 58/2019, de 8 de agosto de
            2019, que assegura, na ordem jurídica nacional, a execução do
            referido Regulamento.
          </p>
        </FlexInRow>
        <FlexInRow className="row">
          <h2>
            1. RESPONSÁVEL PELO TRATAMENTO E ENCARREGADO DE PROTEÇÃO DE DADOS
          </h2>
          <p>
            A SPAP é a entidade responsável pelo tratamento dos seus dados
            pessoais.
          </p>
          <p>
            A SPAP designou um Encarregado de Proteção de Dados que poderá ser
            contactado mediante endereço eletrónico:
          </p>
          <p>
            <a href="mailto:rgpd@spap.pt">rgpd@spap.pt</a>
          </p>
          <p>
            ou Carta: <br />
            Ao cuidado de Encarregado de Proteção de Dados
            <br />
            Avenida Dom Afonso Henriques, N.º 39
            <br />
            3000-011 Coimbra
          </p>
        </FlexInRow>
        <FlexInRow className="row">
          <h2>2. DADOS PESSOAIS E RESPETIVO TRATAMENTO</h2>
          <p>
            Por dados pessoais entende-se toda a informação relativa a uma
            pessoa singular identificada ou identificável, como por exemplo, o
            nome, a morada, email, idade, estado civil, o género, dados de
            localização, económicos, culturais ou sociais. É identificável a
            pessoa que possa ser determinada quanto à sua identidade, em
            especial, por referência a um identificador: nome, número de
            identificação, civil, social, fiscal, profissional ou outro, dados
            de localização, etc. Por tratamento de dados entende-se qualquer
            operação ou conjunto de operações efetuadas sobre dados pessoais,
            tais como a recolha, o registo, a organização, a conservação, a
            adaptação ou alteração, a recuperação, a consulta, a utilização, a
            divulgação por transmissão, por difusão ou por qualquer outra forma
            de disponibilização, a comparação ou interconexão, bem como a
            limitação, apagamento ou destruição.
          </p>
          <p>
            <b>Titular dos dados - Dados Pessoais</b>
          </p>
          <p>
            <b>Associados - Dados Pessoais</b>
            <ul>
              <li>Dados de identificação pessoal</li>
              <li>Dados de identificação fiscal</li>
              <li>Dados bancários</li>
              <li>Dados de contacto</li>
            </ul>
          </p>
          <p>
            <b>
              Subscritores da <i>newsletter</i> - Dados Pessoais
            </b>
            <ul>
              <li>Dados de identificação pessoal</li>
              <li>Dados de contacto</li>
            </ul>
          </p>
          <p>
            <b>Formandos - Dados Pessoais</b>
            <ul>
              <li>Dados de identificação pessoal</li>
              <li>Dados de identificação fiscal</li>
              <li>Dados de contacto</li>
              <li>Dados profissionais e académicos</li>
            </ul>
          </p>
          <p>
            <b>Candidatos - Dados Pessoais</b>
            <ul>
              <li>Dados de identificação pessoal</li>
              <li>Dados de contacto</li>
              <li>Dados profissionais e académicos</li>
            </ul>
          </p>
          <p>
            <b>
              Participantes de estudos e projetos científicos e/ou de
              intervenção comunitária
            </b>
            <ul>
              <li>
                Dados de identificação pessoal (se necessário para o
                acompanhamento dos participantes ao longo do estudo/projeto)
              </li>
              <li>Dados de contacto (se necessário)</li>
            </ul>
          </p>
          <p>
            *Os dados pessoais tratados no âmbito de estudos/projetos de
            investigação científica e/ou de intervenção comunitária são os
            estritamente necessários para atingir os objetivos do
            estudo/projeto.
          </p>
        </FlexInRow>
        <FlexInRow className="row">
          <h2>3. QUANDO RECOLHEMOS OS SEUS DADOS PESSOAIS</h2>
          <p>
            A SPAP procede à recolha dos seus dados pessoais de acordo com
            finalidades previamente definidas. Não é obrigado a fornecer à SPAP
            os dados pessoais que solicitamos, mas, caso entenda não o fazer,
            poderemos não conseguir oferecer-lhe os nossos serviços,
            transmitir-lhe determinadas informações ou responder a alguma
            questão que possa ter. Os seus dados pessoais podem ser recolhidos
            diretamente pelos nossos colaboradores ou, indiretamente, pelos
            nossos prestadores de serviços que lhe prestam serviços em nosso
            nome ou de terceiras entidades que transmitem dados à SPAP. Nesse
            sentido, a SPAP procede à recolha dos seus dados pessoais de forma
            direta, designadamente através:
          </p>
          <p>
            - Da SPAP, de forma presencial e telefonicamente;
            <br />
            - Online, através da www.spap.pt; <br />
            - De email através de geral@spap.pt
            <br />
            - De iniciativas da SPAP (Formações e Eventos organizados ou que
            participa);
            <br />
          </p>
        </FlexInRow>
        <FlexInRow className="row">
          <h2>
            4. FUNDAMENTO E FINALIDADES PARA TRATAR OS SEUS DADOS PESSOAIS
          </h2>
          <p>
            O tratamento dos seus dados pessoais é sempre realizado de acordo
            com finalidades previamente determinadas. A SPAP procede ao
            tratamento dos seus dados pessoais para uma ou mais das seguintes
            finalidades:
          </p>
          <p>
            <ol type="a">
              <li>Investigação científica;</li>
              <li>
                Garantir o exercício dos direitos e deveres dos associados;{" "}
              </li>
              <li>Garantir a proteção e segurança dos titulares dos dados; </li>
              <li>Cumprimento de obrigações legais;</li>
              <li>Gestão contabilística, fiscal e financeira; </li>
              <li>Gestão de contencioso;</li>
              <li>Gestão da relação do titular dos dados com a SPAP; </li>
              <li>Gestão de recursos humanos;</li>
              <li>
                Fins ligados à prestação de serviços formativos dirigidos a
                profissionais,{" "}
              </li>
              <li>
                Envio de newsletters, revistas, informações sobre formações e
                outras informações institucionais da SPAP-(ex. eventos dos
                Clubes ou dos Núcleos da SPAP, atividades promovidas pela SPAP,
                entre outros);
              </li>
              <li>
                Divulgação de eventos promovidos pela SPAP nos meios de
                comunicação da SPAP{" "}
              </li>
              <li>
                Divulgação e convite para a participação em estudos e projetos
                de investigação científica e/ou de intervenção comunitária e
                internacional;
              </li>
            </ol>
          </p>
          <p>
            Os dados pessoais dos respetivos titulares são objeto de um
            tratamento lícito, pelo que, apenas procedemos ao seu tratamento
            quando se verifique, pelo menos, uma das seguintes situações:
          </p>
          <p>
            <ol type="i">
              <li>
                Obtemos o seu consentimento para o tratamento, nos casos em que
                este é necessário. Tal sucede, por exemplo, com a subscrição da
                nossa newsletter;
              </li>
              <li>
                Executamos diligências pré-contratuais e contratuais a seu
                pedido, designadamente serviços de acção formativa e serviços
                inerentes à relação contratual entre a SPAP e os seus
                associados;
              </li>
              <li>Para efeitos de investigação científica;</li>
              <li>
                Cumprimos obrigações legais, por exemplo, quando temos que
                transmitir os seus dados pessoais a entidades policiais,
                judiciais, fiscais ou reguladoras;
              </li>
              <li>Protegemos e defendemos os seus direitos;</li>
              <li>
                Para efeitos de interesses legítimos da SPAP em contribuir para
                o desenvolvimento da literacia em saúde e dos cuidados na mesma
                e assumir um papel relevante na comunidade
              </li>
            </ol>
          </p>
          <p>
            Para efeitos de interesses legítimos da SPAP para divulgação de
            eventos realizados, nos meios de comunicação da SPAP e preservação
            coletiva da memória da sua atividade cultural. Tal acontece, por
            exemplo, para divulgação de seminários, congressos, conferências e
            outras iniciativas desenvolvidas pela SPAP;
            <br />
            Para efeitos de interesses legítimos da SPAP em contribuir para o
            desenvolvimento científico na área da anatomia patológica, através
            do incentivo e sensibilização para a participação em estudos e
            projectos de investigação, realizados individualmente pela SPAP ou
            em associação com outras instituições (científicas ou comunitárias).
            Os estudos e projectos de investigação científica e/ou de
            intervenção na comunidade objetivam a produção de avanços
            científicos na área da anatomia patológica, melhoria dos cuidados em
            saúde e prevenção de doenças.
          </p>
        </FlexInRow>
        <FlexInRow className="row">
          <h2>5. QUANDO TRANSMITIMOS OS SEUS DADOS PESSOAIS</h2>
          <p>
            Não partilhamos os seus dados pessoais com pessoas singulares e
            coletivas externas à SPAP, exceto quando:
          </p>
          <p>
            <ol type="i">
              <li>Obtemos o seu consentimento, quando aplicável;</li>
              <li>
                Estamos perante entidades a que a SPAP recorra para prestação de
                determinados serviços, nomeadamente entidades que prestem
                serviços de suporte informático, serviços de arquivo e de
                destruição de documentos em suporte manual ou digital, serviços
                de tecnologias de informação e comunicação;
              </li>
              <li>
                Temos de cumprir obrigações legais, nomeadamente transmissão de
                dados a entidades policiais, judiciais, fiscais e reguladoras;
              </li>
              <li>Protegemos e defendemos os seus direitos;</li>
              <li>
                Estamos perante o interesse legítimo da SPAP de apresentar ações
                ou queixas no interesse dos nossos direitos ou para proteger os
                seus;
              </li>
              <li>
                Baseamo-nos no interesse legítimo da SPAP em divulgar eventos
                realizados e preservar a memória da sua atividade cultural
              </li>
            </ol>
          </p>
          <p>
            Todas as entidades a quem possam ser comunicados os seus dados estão
            contratualmente obrigadas a guardar sigilo, bem como à garantia de
            adoção de medidas técnicas e organizativas adequadas a fim de
            assegurar o cumprimento do RGPD.
          </p>
        </FlexInRow>
        <FlexInRow className="row">
          <h2>6. PRAZOS DE CONSERVAÇÃO</h2>
          <p>
            O período de tempo durante o qual os dados são conservados varia de
            acordo com a finalidade para a qual a informação é tratada. De
            qualquer modo, o período de tempo durante o qual os seus dados
            pessoais são conservados é o estritamente necessário para a
            realização da finalidade definida ou, quando se aplique, até que
            exerça o seu direito de oposição, direito ao apagamento ou retire o
            consentimento.
          </p>
          <p>
            Contudo, casos existem em que a SPAP está legalmente obrigada a
            conservar os dados pessoais por um período de tempo determinado. Tal
            sucede, por exemplo, com os dados necessários para informação à
            Autoridade Tributária para efeitos contabilísticos, na medida em que
            há que respeitar a legislação contabilística e fiscal, e com os
            dados de saúde, na medida em que há que respeitar a legislação
            referente à conservação dos arquivos relativos àqueles dados
            pessoais.
          </p>
          <p>
            Depois de decorrido o período de conservação, a SPAP eliminará ou
            procederá à sua anonimização
          </p>
        </FlexInRow>
        <FlexInRow className="row">
          <h2>7. DIREITOS DO TITULAR DE DADOS</h2>
          <p>
            Os titulares dos dados têm o direito de solicitar, nos termos da
            legislação vigente, o acesso aos dados pessoais que lhes digam
            respeito, a sua retificação, o seu apagamento, a limitação do
            tratamento no que disser respeito ao titular dos dados, a oposição
            ao tratamento, bem como a portabilidade dos dados. Quando o
            tratamento de dados pessoais se basear no seu consentimento pode,
            ainda, em qualquer altura, retirar o seu consentimento sem que tal
            comprometa a licitude do tratamento efetuado com base no
            consentimento anteriormente dado.
          </p>
          <p>
            Por uma questão de segurança, precaução, privacidade e proteção dos
            dados pessoais, para a realização de qualquer pedido à SPAP é
            necessário que o titular de dados ou alguém que atue em seu nome
            confirme, pelo menos, <b>3</b> dos seguintes dados do titular: Nome
            completo, Data de Nascimento, NIF, CC/BI ou Nº SNS.
          </p>
          <p>
            O exercício dos seus direitos pode ser efetuado presencialmente na
            SPAP ou através do contacto de email e/ou morada do Encarregado de
            Proteção de Dados da SPAP, mencionados no ponto 1 deste documento.
          </p>
          <p>
            Especificamente os pedidos de retificação/atualização de dados podem
            ser efetuados por telefone, presencialmente, para os contactos de
            email da SPAP ou através do site www.spap.pt. Se presencialmente ou
            por telefone o pedido será executado de imediato, após confirmação
            dos dados de identificação do titular.
          </p>
          <p>
            O acesso aos seus dados pessoais, bem como qualquer outro pedido
            relativo aos seus dados pessoais, pode ser solicitado por si,
            enquanto titular dos dados, por alguém por si autorizado, ou por
            alguém que legalmente o represente, designadamente o seu
            representante legal, ou herdeiro legal, com a devida comprovação
            legal.
          </p>
          <p>
            Os pedidos relativos a pessoas falecidas apenas são aceites por
            escrito (email ou carta), através do email{" "}
            <a href="mailto:rgpd@spap.pt">rgpd@spap.pt</a> ou dos emails dos
            serviços administrativos da SPAP e/ou da morada da SPAP. Qualquer
            pedido relativo a pessoas falecidas deve ser acompanhado de cópia
            dos seguintes documentos comprovativos: <br />
            - Habilitação de Herdeiros; <br />- Assinatura de declaração por
            parte de todos os herdeiros, sob compromisso de honra, autorizando a
            entrega do pedido solicitado ao requerente, fazendo menção ao motivo
            do pedido.
          </p>
          <p>
            Caso entenda que os seus dados não estão a ser objeto de tratamento
            lícito por parte da SPAP, o titular dos dados tem o direito de
            apresentar reclamação à Comissão Nacional de Proteção de Dados
            (CNPD) ou a outra entidade de controlo competente nos termos da Lei.
          </p>
          <p>
            O exercício dos direitos é gratuito, exceto se se tratar de um
            pedido manifestamente infundado, excessivo ou injustificadamente
            reiterado, caso em que poderá ser cobrada uma taxa razoável tendo em
            conta os custos administrativos
          </p>
          <p>
            A resposta aos pedidos deverá ser prestada, sem demora
            injustificada, no prazo de um mês a contar da receção do pedido,
            salvo se for um pedido especialmente complexo ou ocorrer em
            circunstâncias excecionais. Esse prazo pode ser prorrogado até dois
            meses, quando for necessário, tendo em conta a complexidade do
            pedido e o número de pedidos recebidos.
          </p>
        </FlexInRow>
        <FlexInRow className="row">
          <h2>8. SEGURANÇA DOS DADOS PESSOAIS</h2>
          <p>
            A SPAP está empenhada em assegurar a confidencialidade, proteção e
            segurança dos seus dados pessoais, através da implementação das
            medidas técnicas e organizativas adequadas para proteger os dados
            pessoais contra a sua perda, destruição ou danificação, acidental ou
            ilícita, assim como para assegurar que os dados facultados sejam
            protegidos contra o acesso ou utilização por parte de terceiros não
            autorizados. Nesse sentido, a SPAP compromete-se a implementar as
            melhores práticas de segurança de informação.
          </p>
          <p>
            A SPAP não se responsabiliza e não presta garantias relativamente a
            outros websites que possam ser acedidos através de hiperligações no
            nosso website. Adicionalmente cabe ao utilizador tomar precauções e
            assegurar-se que a informação que retire não contenha
            características de natureza prejudicial.
          </p>
        </FlexInRow>
        <FlexInRow className="row">
          <h2>9. COOKIES</h2>
          <p>
            O que são? Cookies são pequenas etiquetas de software que são
            armazenadas no computador através do navegador (browser), retendo
            apenas informação relacionada com preferências, não incluindo, como
            tal, dados pessoais.
          </p>
          <p>
            A SPAP utiliza Cookies para determinar a utilidade, interesse e o
            número de utilizações do seu website, permitindo uma navegação mais
            rápida, eliminando a necessidade de introduzir repetidamente as
            mesmas informações.
            <br />A utilização de Cookies é uma prática normal dos websites da
            Internet, sendo que a maioria dos navegadores da Internet permite
            que um Utilizador possa aceitar, recusar ou apagar cookies através
            da seleção das definições apropriadas.
          </p>
        </FlexInRow>
        <FlexInRow className="row">
          <h2>10. ALTERAÇÕES À POLÍTICA DE PRIVACIDADE</h2>
          <p>
            A SPAP reserva-se ao direito de, a qualquer momento, proceder a
            alterações ou atualizações à presente política de privacidade, sendo
            essas eventuais alterações publicitadas no site da SPAP.
          </p>
          <p>
            Recomendamos que consulte periodicamente a nossa política de
            privacidade para se manter informado sobre o modo como a SPAP
            protege os seus Dados Pessoais e se manter atualizado sobre as
            informações e direitos que lhe assistem.
          </p>
        </FlexInRow>
        <FlexInRow className="row">
          <p>Atualização: 24 de outubro de 2023.</p>
        </FlexInRow>
      </Container>
    </Wrapper>
  );
}
