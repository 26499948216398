import React, { useEffect, useState, useRef } from 'react';
import lottie from 'lottie-web';

const Loading = () => {
  const animationContainer = useRef(null);
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('../../../Assets/Json/load-spap.json'),
    });

    const displayTime = 100;
    const removeTime = 2000;

    const displayTimer = setTimeout(() => {
      animation.play();
    }, displayTime);

    const removeTimer = setTimeout(() => {
      setShowLoading(false);
    }, removeTime);

    return () => {
      clearTimeout(displayTimer);
      clearTimeout(removeTimer);
    };
  }, []);

  return showLoading ? <div 
  className='pre-loading-div'
  style={{
    backgroundColor: 'rgb(228 228 228 / 77%)',
    position: 'fixed',
    top: '0',
    height: '100%',
    width: '100%',
    zIndex: '999999999',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }} ref={animationContainer} /> : null;
};

export default Loading;
