import styled from 'styled-components'
import { Col, Space, Row } from 'antd'
import UserService from '../../../Services/user.service'
import Sidebar from '../../../Components/Backoffice/Sidebar'
import DashboardCard from '../../../Components/Backoffice/DashboardCard'
import Header from '../../../Components/Backoffice/Header'
import Footer from '../../../Components/Backoffice/Footer'
import DashboardMessages from '../../../Components/Backoffice/DashboardMessages'
import PreviewListSocios from '../../../Components/Backoffice/PreviewListSocios'
import PreviewClubes from '../../../Components/Backoffice/PreviewClubes'
import FlexInRow from '../../../Components/FlexInRow'


const Wrapper = styled.main`
  background-color: #E8E9F3;
  display: grid;
  grid-template-columns: 7.083vw 1fr;
  min-height: 100vh;

  @media (max-width: 992px) {
    
  }
`

const Content = styled.div`
  padding: 1.771vw;
`

const Grid = styled.div`
  align-items: flex-start;  
  display: grid;
  grid-template-columns: 1fr 310px;
  gap: 30px;

  @media (max-width: 1280px) {
    grid-template-columns: 1fr;

    #list-socios {
      width: 310px;
    }
  }
`

export default function Dashboard ( props ) {  
  document.title = 'SPAP - Dashboard'

  console.log(JSON.parse(localStorage.getItem('session_data')));
  
  return(
    <Wrapper>
      <div>
        <Sidebar />
      </div>
      <Content>
        <Header />
        <FlexInRow gap='30px'>
          <DashboardCard />
            {/*
            TODO: voltar desenvolvimento jan/24
          <Grid>
            <DashboardMessages />
             <PreviewListSocios /> 
          </Grid>
             */}
          <PreviewClubes /> 
        </FlexInRow>
        <Footer />
      </Content>
    </Wrapper>
  )
}