import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

import Container from "../../../../Components/Container";
import FlexInRow from "../../../../Components/FlexInRow";
import FlexInColumn from "../../../../Components/FlexInColumn";
import PageTitle from "../../../../Components/PageTitle";
import PageText from "../../../../Components/PageText";
import SupportedBySpap from "../../../../Components/SupportedBySpap";
import urlSlug from "url-slug";

import IconFile from "../../../../Assets/Images/icon-awesome-file-alt.svg";
import { Button, Divider, Space, Image, Row, Col } from "antd";
import JuntarseClube from "../../../../Components/JuntarseClube";
import SidebarMenu from "../../../../Components/SidebarMenu";

import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #000000;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: left;
  width: 100%;
  margin-top: 7.813vw;

  .row-wrap {
    flex-wrap: wrap !important;
  }

  > .content {
    padding-bottom: 5.208vw;
  }

  img {
    width: 100%;
  }

  .link {
    color: ${(props) => props.theme.orange};
    transition: 0.3s ease;
  }

  .link:hover {
    color: ${(props) => props.theme.orangeHover};
  }

  .cta {
    background-color: ${(props) => props.theme.orange};
    border: none;
    color: ${(props) => props.theme.white};
    transition: 0.3s ease;
  }

  .cta:hover {
    background-color: ${(props) => props.theme.orangeHover};
    color: ${(props) => props.theme.whiteHover};
  }

  .sidebar__cta {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0.521vw;
    color: #0c1151;
    opacity: 1;
    text-decoration: none;
    text-align: center;
    letter-spacing: 0px;
    transition: 0.3s ease;
    font-size: 1.146vw;
    font-weight: bold;
    line-height: 1.51vw;
    border: 0.156vw solid #0c1151;
    padding: 0.781vw 1.563vw;
  }

  .sidebar__cta:hover {
    background-color: #0c1151;
    border-color: transparent;
    color: #e5e5e5;
  }

  .sidebar__cta.blue {
    background: #0c1151 0% 0% no-repeat padding-box;
    border: 0.156vw solid transparent;
    color: #ffffff;
  }

  .sidebar__cta.blue:hover {
    background-color: #8688a8;
    color: #e5e5e5;
  }

  .bar {
    height: 1px;
    width: 66.854vw;
    background-color: #d6cdf7;
    opacity: 1;
    margin: auto;
  }

  .manager__image {
    display: block;
    width: 89px;
  }

  .item__image {
    overflow: hidden;
    // height: 216px;
    width: 100%;

    margin: 0 auto;

    img {
      object-fit: fill;
    }
  }

  .item__title {
    font-weight: bold;
    font-size: 1.0416vw;
    line-height: 1.25vw;
    font-family: "Fira Sans";
    letter-spacing: 0px;
    color: #0c1151;
    text-align: center;
    margin-top: 10px;

    * {
      color: #0c1151;
      text-decoration: none;
    }
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    .item__title {
      font-size: 11px;
      line-height: 16px;
    }
  }
`;

const Title = styled.h2`
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;

  @media (max-width: 992px) {
    font-size: 3.333vw;
    line-height: 4.444vw;
  }
`;

const SectionCards = styled.div`
  display: grid;
  gap: 2.604vw;
  grid-template-columns: 1fr 1fr;

  .item {
    border: 0.156vw solid #494d7d;
    border-radius: 1.042vw;
    justify-content: space-between;
    overflow: hidden;
    display: grid;
    grid-template-columns: 2fr 1fr;
  }

  .item__content {
    padding: 1.042vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
  }

  // .item__image {
  //   border: none;
  //   overflow: hidden;
  //   width: 13.542vw;

  //   img {
  //     height: 100%;
  //     object-fit: cover;
  //     object-position: top;
  //   }
  // }

  .item__title {
    text-align: left;
    font-size: 16px;
    line-height: inherit;
    font-weight: bold;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    margin: 1.094vw 0;
  }

  .item__text {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 0.729vw;
  }

  .item__cta {
    align-items: center;
    justify-content: center;
    display: flex;
    background: #0c1151 0% 0% no-repeat padding-box;
    border-radius: 0.521vw;
    opacity: 1;
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    text-decoration: none;
    color: #ffffff;
    opacity: 1;
    width: 8.021vw;
    height: 2.396vw;
    transition: 0.3s ease;
  }

  .item__cta:hover {
    background-color: #8688a8;
    color: #e5e5e5;
  }

  .item__date {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.521vw;
  }

  .item__date svg {
    font-size: 1.302vw;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;

    .item__title {
      font-size: 22px !important;
      line-height: 26px !important;
    }

    .item__text,
    .item__cta,
    .item__date,
    .item__date svg {
      font-size: 16px !important;
      line-height: 20px !important;
    }

    .item__cta {
      border-radius: 10px;
      height: 40px;
      width: 185px;
    }
  }

  @media (min-width: 1920px) {
    .item__text,
    .item__cta,
    .item__date,
    .item__date svg {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

const NoResults = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  * {
    text-align: center;
    font-size: 16px;
    line-height: inherit;
    font-weight: bold;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    margin: 1.094vw 0;
  }
`;

const Grid = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 50px;

  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export default function ClubeNovoEventos(props) {
  const { slug } = useParams();
  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const [clube, setClube] = useState("");
  const [eventosClube, setEventosClube] = useState("");

  const month = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  useEffect(() => {
    fetch(
      `https://api.spap.pt/api/get-eventos-clube/` + slug.split("-").pop(),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + session_storage.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.clube?.length === 0) {
          return;
        }

        console.log(data);

        getClube(data.clube);
        getEventosClube(data.eventos);
      })
      .catch((error) => console.error(error));
  }, []);

  const [usersList, setUsersList] = useState(session_storage.all_data.users);

  const getClube = (thisClub) => {
    console.log(thisClub);
    let myClube = thisClub[0];
    myClube.url = myClube.url
      ? "https://api.spap.pt/clubes/" + myClube.url
      : "https://api.spap.pt/clubes/clube-estudo-default.svg";
    setClube(myClube);
  };

  const getEventosClube = (eventos) => {
    let eventosToShow = [];

    let today = new Date().toLocaleDateString();

    eventos.map((evento) => {
      let mostraEvento = true;

      evento.slug = urlSlug(evento.titulo);

      evento.slug += "-" + evento.id;

      if (evento.club_id == slug.split("-").pop()) {
        if (evento.agendar_post != null) {
          let date = evento.agendar_post.trim();

          if (date > today) {
            mostraEvento = false;
          }
        }

        if (mostraEvento) {
          const [dateInicioValues, timeInicioValues] =
            evento.data_inicio.split(" ");
          const [dayInicio, monthInicio, yearInicio] =
            dateInicioValues.split("/");
          const [dateFechoValues, timeFechoValues] =
            evento.data_fecho.split(" ");
          const [dayFecho, monthFecho, yearFecho] = dateFechoValues.split("/");

          const monthInicioToString = month[Number(monthInicio - 1)];
          const monthFechoToString = month[Number(monthFecho - 1)];

          evento.url = evento.imagem_destaque
            ? "https://api.spap.pt/eventos/" +
              evento.id +
              "/" +
              evento.imagem_destaque
            : "https://api.spap.pt/clubes/clube-estudo-default.svg";

          if (
            monthInicio == monthFecho &&
            yearInicio === yearFecho &&
            dayInicio === dayFecho
          ) {
            evento.date =
              dayInicio + " " + monthInicioToString + " " + yearInicio;
          } else if (monthInicio == monthFecho && yearInicio === yearFecho) {
            evento.date =
              dayInicio +
              "-" +
              dayFecho +
              " " +
              monthInicioToString +
              " " +
              yearInicio;
          } else if (
            dayInicio == dayFecho &&
            monthInicio == monthFecho &&
            yearInicio === yearFecho
          ) {
            evento.date =
              dayInicio + " " + monthInicioToString + " " + yearInicio;
          } else if (monthInicio != monthFecho) {
            evento.date =
              dayInicio +
              " " +
              monthInicioToString +
              " a " +
              dayFecho +
              " " +
              monthFechoToString +
              " " +
              yearFecho;
          }

          eventosToShow.push(evento);
        }
      }
    });

    setEventosClube(eventosToShow);
  };

  const SidebarMenuLinks = [
    {
      title: clube.name,
      link: "/socios/clubes-de-estudo/" + slug,
    },
    {
      title: "Membros",
      link: "/socios/clubes-de-estudo/" + slug + "/membros",
    },
    {
      title: "Eventos",
      link: "/socios/clubes-de-estudo/" + slug + "/eventos",
    },
    {
      title: "Galeria",
      link: "/socios/clubes-de-estudo/" + slug + "/galeria",
    },
    {
      title: "Documentos",
      link: "/socios/clubes-de-estudo/" + slug + "/documentos",
    },
  ];

  return (
    <Wrapper>
      <Container className="content">
        <PageTitle
          title={clube.name}
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "Sócios",
              link: "/socios",
            },
            {
              text: "Clubes de estudo",
              link: "/socios/clubes-de-estudo",
            },
            {
              text: clube.name,
              link: "/socios/clubes-de-estudo/" + slug + "",
            },
            {
              text: "Eventos",
            },
          ]}
          imageDesktop={clube.url}
          imageMobile={clube.url}
          imageAlt={clube.name}
          border={false}
          sidebar={
            <SidebarMenu navItens={SidebarMenuLinks} className="sidebar" />
          }
          clubes={true}
        />

        <FlexInRow>
          <Row gutter={[30, 8]}>
            <Col span={24}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Divider orientation="left" orientationMargin="0">
                  <Title>
                    {eventosClube?.length > 0
                      ? "Eventos"
                      : "Nenhum evento disponível"}
                  </Title>
                </Divider>
                <Row align="initial" className="row-wrap" gutter={[20, 20]}>
                  {eventosClube?.length > 0 ? (
                    <>
                      <SectionCards>
                        {eventosClube.map((item, index) => {
                          let titlePreview = item.titulo.slice(0, 60);
                          if (item.titulo?.length > 60)
                            titlePreview += " (...)";
                          let textPreview = item.descricao
                            .replace(/<(h2|h3|img|b|strong)[^>]*>/g, "<p>")
                            .slice(0, 120);
                          if (item.descricao?.length > 120)
                            textPreview += " (...)";
                          return (
                            <div className={"item item-" + index}>
                              <div className="item__content">
                                <div>
                                  <div className="item__date">
                                    <FontAwesomeIcon icon={faCalendarDay} />
                                    {item.date}
                                  </div>
                                  <h3 className="item__title">
                                    {titlePreview}
                                  </h3>
                                  <p className="item__text">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: textPreview,
                                      }}
                                    ></div>
                                  </p>
                                </div>
                                <Link
                                  to={"/agenda/evento/" + item.slug}
                                  className="item__cta"
                                >
                                  Saber mais »
                                </Link>
                              </div>
                              <div
                                className="item__image"
                                style={{
                                  backgroundImage: "url(" + item.url + ")",
                                  backgroundSize: "cover",
                                  backgroundPosition: "center center",
                                }}
                              >
                                <picture style={{ opacity: "0" }}>
                                  <source
                                    srcSet={item.url}
                                    media="(min-width: 990px)"
                                  />
                                  <img src={item.url} alt={item.alt} />
                                </picture>{" "}
                              </div>
                            </div>
                          );
                        })}
                      </SectionCards>
                    </>
                  ) : (
                    ""
                  )}
                </Row>
              </Space>
            </Col>
          </Row>
        </FlexInRow>
      </Container>

      <SupportedBySpap title="Veja Também" />
    </Wrapper>
  );
}
