import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faCalendarDay,
  faFileArchive,
  faFileCirclePlus,
} from "@fortawesome/free-solid-svg-icons";

import Container from "../../../../Components/Container";
import FlexInColumn from "../../../../Components/FlexInColumn";
import FlexInRow from "../../../../Components/FlexInRow";
import PageTitle from "../../../../Components/PageTitle";
import RecommendedLinks from "../../../../Components/RecommendedLinks";

import IconSubscribe from "../../../../Assets/Images/icon-partilhar.svg";
import IconSeeMore from "../../../../Assets/Images/icon-saber-mais.svg";
import IconShare from "../../../../Assets/Images/icon-partilhar.svg";
import IconReturn from "../../../../Assets/Images/icon-voltar.svg";
import { notification } from "antd";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #000000;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: left;
  width: 100%;
  margin-top: 7.813vw;

  img {
    width: 100%;
  }

  ul {
    list-style: none;
    width: 8.333vw;
    padding: 0;
  }

  li {
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
    gap: 0.729vw;
    text-align: left;
    font-size: 1.146vw;
    font-weight: bold;
    line-height: 1.51vw;
    letter-spacing: 0px;
    opacity: 1;
    margin: 1.042vw 0;

    img {
      width: 1.667vw;
    }
  }

  li a {
    color: #f88436;
    text-decoration: none;
  }

  .icon {
    margin-right: 8px;
  }

  .icon-file {
    width: 3.438vw;
  }

  .share {
    cursor: pointer;
    color: ${(props) => props.theme.orange};
  }

  @media (max-width: 992px) {
    margin-top: 120px;
  }
`;

const Title = styled.h3`
  text-align: left;
  font-size: 1.25vw;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;

  @media (max-width: 992px) {
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  p {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  a {
    justify-content: flex-start;
    margin-top: 0.938vw;
    font-size: 0.938vw;
    font-weight: bold;
    line-height: 1.25vw;
    text-align: left;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    text-decoration: none;
  }

  @media (max-width: 992px) {
    p,
    a {
    }
  }

  @media (min-width: 1920px) {
    p,
    a {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

const ButtonCta = styled.a`
  background-color: #f88436;
  border-radius: 0.521vw;
  color: #ffffff;
  font-size: 1.042vw;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: inherit;
  opacity: 1;
  padding: 0.781vw 0;
  text-align: center;
  text-decoration: none;
  transition: 0.3s ease;
  width: 7.917vw;
  margin-bottom: 5.208vw;

  :hover {
    background-color: #e87426;
    color: #ececec;
  }

  @media (max-width: 992px) {
  }
`;

export default function FormacaoPost(props) {
  const handleShare = (link) => {
    navigator.clipboard.writeText(link);
    notification.info({ description: "Link copiado: " + link });
  };

  return (
    <Wrapper>
      <Container>
        <PageTitle
          title="Teste de Formação (nome aqui)"
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "Agenda",
              link: "/agenda",
            },
            {
              text: "(nome aqui)",
            },
          ]}
          imageDesktop={require("../../../../Assets/Images/img-top-agenda@2x.png")}
          imageMobile={require("../../../../Assets/Images/img-top-agenda.png")}
          imageAlt="Alt"
        />

        <FlexInRow gap="3.646vw">
          <FlexInColumn gap="15.417vw">
            <picture>
              <source
                srcSet={require("../../../../Assets/Images/img-formacao-cursos-e-congressos-vincent-academy-of-pathology@2x.png")}
                media="(min-width: 990px)"
              />
              <img
                src={require("../../../../Assets/Images/img-formacao-cursos-e-congressos-vincent-academy-of-pathology.png")}
                alt="Banner Formação"
              />
            </picture>
            <div>
              <ul>
                <li className="link-disabled">
                  <Link to="/">
                    <img
                      src={IconSubscribe}
                      alt="Icon Subscribe"
                      className="icon icon-subscribe"
                    />
                    Inscrever
                  </Link>
                </li>
                <li className="link-disabled">
                  <Link to="/">
                    <img
                      src={IconSeeMore}
                      alt="Icon See More"
                      className="icon icon-see-more"
                    />
                    Saber mais
                  </Link>
                </li>
                <li>
                  <span
                    className="share"
                    onClick={() => handleShare(window.location.href)}
                  >
                    <img
                      src={IconShare}
                      alt="Icon Share"
                      className="icon icon-share"
                    />
                    Partilhar
                  </span>
                </li>
                <li>
                  <a href="javascript:history.back()">
                    <img
                      src={IconReturn}
                      alt="Icon Return"
                      className="icon icon-return"
                    />
                    Voltar
                  </a>
                </li>
              </ul>
            </div>
          </FlexInColumn>

          <FlexInRow>
            <Title>Junte-se a nós neste evento!</Title>
            <Text>
              The annual pathology academic meeting, organized by IAP-Thailand
              Association. The meeting is for promoting knowledge for
              pathologists. Since the uncertainty of the COVID 19 pandemic, the
              meeting will be set as virtual (online) meeting only. All academic
              sessions will be conducted in English. We are very welcome for all
              audiences over the world.
            </Text>
            <ButtonCta href="#">Inscrever</ButtonCta>
          </FlexInRow>
        </FlexInRow>
      </Container>

      <RecommendedLinks
        title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
        links={[
          {
            title: "História",
            link: "/spap/historia",
          },
          {
            title: "Órgãos sociais",
            link: "/spap/orgaos-sociais",
          },
          {
            title: "Bolsas e prémios",
            link: "/spap/bolsas-e-premios",
          },
          {
            title: "Vantagens de ser sócio",
            link: "/socios/conheca-as-vantagens",
          },
        ]}
      />
    </Wrapper>
  );
}
