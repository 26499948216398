import styled from "styled-components";

import Container from "../../../Components/Container";
import PageTitle from "../../../Components/PageTitle";
import PageText from "../../../Components/PageText";
import FlexInColumn from "../../../Components/FlexInColumn";
import FlexInRow from "../../../Components/FlexInRow";
import ContactForm from "../../../Components/ContactForm";
import { Helmet } from "react-helmet";
const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  > .content {
    margin-bottom: 2.188vw;
  }

  .sidebar__image {
    margin-left: auto;
    max-width: 27.917vw;
  }

  img {
    width: 100%;
  }

  a {
    color: #000000;
    place-self: flex-start;
    text-align: left;
    text-decoration: none;
    transition: 0.3s ease;

    :hover {
      color: ${(props) => props.theme.blueHover};
    }
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    a {
      font-size: 3.333vw;
      line-height: 4.444vw;
      place-self: center;
    }

    .sidebar__image {
      margin: auto;
      max-width: 80%;
    }
  }
`;

export default function Estagios(props) {
  return (
    <Wrapper>
      <Helmet>
        <title>
          Contactos NISPAP | Núcleo de Internos Sociedade Portuguesa de Anatomia
          Patológica - NISPAP
        </title>
        <meta
          name="description"
          content="O Núcleo de Internos está sempre ao seu dispor. Entre em contacto e coloque-nos as suas dúvidas."
        />
        <meta
          name="keywords"
          content="nispap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      <Container className="content">
        <PageTitle
          title="Contactos"
          path={[
            {
              text: "NISPAP",
              link: "/nispap",
            },
            {
              text: "Contactos",
            },
          ]}
          imageDesktop={require("../../../Assets/Images/img-contactos@2x.png")}
          imageMobile={require("../../../Assets/Images/img-contactos.png")}
          imageAlt="Contactos"
        />

        <FlexInRow gap="6.146vw">
          <FlexInColumn gap="12.396vw" mobile="column">
            <FlexInRow gap="5.208vw">
              <FlexInRow gap="0">
                <PageText title="O Núcleo de Internos está sempre ao seu dispor." />
                <PageText title="Entre em contacto e coloque-nos as suas dúvidas" />
              </FlexInRow>

              <a href="mailto:nucleointernosspap@gmail.com">
                <u>nucleointernosspap@gmail.com</u>
              </a>
            </FlexInRow>

            <picture className="sidebar__image">
              <source
                srcSet={require("../../../Assets/Images/img-contacte-nos2@2x.png")}
                media="(min-width: 990px)"
              />
              <img
                src={require("../../../Assets/Images/img-contacte-nos2.png")}
                alt="Imagem Contacte-nos"
              />
            </picture>
          </FlexInColumn>
        </FlexInRow>
      </Container>

      <ContactForm nispap={true} />
    </Wrapper>
  );
}
