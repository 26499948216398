import styled from "styled-components";
import { Link } from "react-router-dom";

import Container from "../../../Components/Container";
import FlexInRow from "../../../Components/FlexInRow";
import FlexInColumn from "../../../Components/FlexInColumn";
import PageTitle from "../../../Components/PageTitle";
import PageText from "../../../Components/PageText";
import RecommendedLinks from "../../../Components/RecommendedLinks";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.main`
  background-color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  > .content {
    padding-bottom: 4vw;
  }

  img {
    width: 100%;
  }

  .item__title {
    text-align: left;
    font-size: 4.167vw;
    line-height: 5vw;
    font-weight: bold;
    letter-spacing: 0px;
    color: #c2c4d4;
    opacity: 1;
    margin-top: 0.885vw;
  }

  .item__text {
    text-align: left;
    font-size: 1.563vw;
    line-height: 2.031vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 0.729vw;
  }

  .item__cta {
    align-items: center;
    justify-content: center;
    display: flex;
    background: #0c1151 0% 0% no-repeat padding-box;
    border-radius: 0.521vw;
    opacity: 1;
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    text-decoration: none;
    color: #ffffff;
    opacity: 1;
    width: 8.021vw;
    height: 2.396vw;
    transition: 0.3s ease;
    margin-top: 1.458vw;
  }

  .item__cta:hover {
    background-color: #8688a8;
    color: #e5e5e5;
  }

  .item__date {
    text-align: left;
    font-size: 1.146vw;
    line-height: 1.51vw;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.521vw;
  }

  .item__date svg {
    font-size: 1.302vw;
  }

  #select-year {
    color: #000;
    cursour: pointer;
    z-index: 8;
  }

  @media (max-width: 992px) {
    margin-top: 120px;
  }

  @media (min-width: 1920px) {
    .item__cta {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

const Text = styled.p`
  text-align: left;
  font-size: 1.25vw;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;

  @media (max-width: 992px) {
    font-size: 2.222vw;
    line-height: 3.333vw;
  }
`;

const SectionCards = styled.div`
  display: grid;
  grid-column-gap: 2.656vw;
  grid-row-gap: 3.021vw;
  grid-template-columns: 1fr 1fr 1fr;

  .item {
    border: 0.156vw solid #0c1151;
    border-top: 0.625vw solid #0c1151;
    border-radius: 0px 0px 1.042vw 1.042vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    text-decoration: none;
  }

  .item__image {
    border: none;
    overflow: hidden;
    position: relative;
    height: 20.156vw;

    picture {
      z-index: 1;
    }

    .overlay {
      background: transparent
        linear-gradient(180deg, #452aa71a 0%, #452aa720 100%) 0% 0% no-repeat
        padding-box;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      z-index: 2;
    }

    img {
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .item__cta {
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #0c1151;
    border: none;
    border-radius: 0;
    opacity: 1;
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    text-decoration: none;
    color: #ffffff;
    opacity: 1;
    width: 100%;
    height: 2.396vw;
    transition: 0.3s ease;
    margin: 0;
  }

  .item:hover .item__cta {
    background-color: #8688a8;
    color: #e5e5e5;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;

    .item__image {
      height: 260px;
    }

    .item__cta {
      font-size: 16px;
      line-height: 22px;
      padding: 20px 0;
    }
  }

  @media (min-width: 1920px) {
    .item__cta {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

export default function Formacao(props) {
  const ListCards = [
    {
      title: "Cursos e Congressos",
      desktop: require("../../../Assets/Images/btn-formacao-cursos-e-congressos@2x.png"),
      mobile: require("../../../Assets/Images/btn-formacao-cursos-e-congressos.png"),
      alt: "Cursos e Congressos",
      link: "/ferramentas-uteis/formacao/cursos-congressos",
    },
    {
      title: "Formação Online",
      desktop: require("../../../Assets/Images/btn-formacao-foracao-online@2x.png"),
      mobile: require("../../../Assets/Images/btn-formacao-foracao-online.png"),
      alt: "Formação Online",
      link: "/ferramentas-uteis/formacao/formacao-online",
    },
    {
      title: "Links Úteis",
      desktop: require("../../../Assets/Images/btn-formacao-links-uteis@2x.png"),
      mobile: require("../../../Assets/Images/btn-formacao-links-uteis.png"),
      alt: "Links Úteis",
      link: "/ferramentas-uteis/formacao/links-uteis",
    },
  ];

  return (
    <Wrapper>
      <Helmet>
        <title>
          Formação | Sociedade Portuguesa de Anatomia Patológica - SPAP
        </title>
        <meta
          name="description"
          content="Cursos e congressos | Formação Online | Links úteis"
        />
        <meta
          name="keywords"
          content="spap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      <Container className="content">
        <PageTitle
          title="Formação"
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "Ferramentas úteis",
              link: "/ferramentas-uteis",
            },
            {
              text: "Formação",
            },
          ]}
          imageDesktop={require("../../../Assets/Images/Formacao@2x.png")}
          imageMobile={require("../../../Assets/Images/Formacao.png")}
        />

        <FlexInRow gap="3.542vw">
          {/* #TODO Inserir conteudo de titulo e texto aqui
          <div>
            <Text>Ut enim ad minim veniam, quis nostrud exercitation ul.</Text>
            <Text>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore (…)</Text>
          </div>
  */}

          {ListCards.length > 0 && (
            <SectionCards>
              {ListCards.map((item, index) => {
                return (
                  <Link to={item.link} className={"item item-" + index}>
                    <div className="item__content">
                      <div className="item__image">
                        <picture>
                          <source
                            srcSet={item.desktop}
                            media="(min-width: 990px)"
                          />
                          <img src={item.mobile} alt={item.alt} />
                        </picture>
                        <div className="overlay" />
                      </div>
                      <div className="item__cta">{item.title}</div>
                    </div>
                  </Link>
                );
              })}
            </SectionCards>
          )}
        </FlexInRow>
      </Container>

      <RecommendedLinks
        title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
        links={[
          {
            title: "História",
            link: "/spap/historia",
          },
          {
            title: "Órgãos sociais",
            link: "/spap/orgaos-sociais",
          },
          {
            title: "Bolsas e prémios",
            link: "/spap/bolsas-e-premios",
          },
          {
            title: "Vantagens de ser sócio",
            link: "/socios/conheca-as-vantagens",
          },
        ]}
      />
    </Wrapper>
  );
}
