import styled from 'styled-components'
import {
  Link
} from "react-router-dom"

import FlexInColumn from '../FlexInColumn'

import BgImage from '../../Assets/Images/img-extra-pags-interesse@2x.png'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4.167vw;
  background-color: #EEEEEE;
  padding: 4.167vw 0;
  position: relative;

  .content {
    align-center: center;
    justify-content: center;
    gap: 2.604vw;
  }

  .content>a {
    text-align: left;
    font-size: 1.146vw;
    font-style: initial;
    line-height: 1.510vw;
    font-weight: bold;
    letter-spacing: 0px;
    padding: 0.677vw 1.563vw;
    color: #FFFFFF;
    background-color: #F88436;
    text-decoration: none;
    border-radius: 0.521vw;
    opacity: 1;
    transition: .3s ease;
    z-index: 3;
  }

  .content>a:hover {
    background-color: #E87426;
    color: #ECECEC;
  }

  #bgImage {
    position: absolute;
    margin: 0;
    max-width: 18.229vw;
    bottom: 0;
    right: 0;
    z-index: 2;
  }

  @media (max-width: 992px) {
    padding: 8.333vw 0 45.833vw;

    .content {
      gap: 40px;
    }

    .content>a {
      border-radius: 2.778vw;
      font-size: 4.444vw;
      line-height: 5.277vw;
      padding: 18px 30px;
      place-self: center;
    }

    #bgImage {
      max-width: 50vw;
    }
  }

  @media (min-width: 1920px) {
    gap: 80px;
    padding: 80px;

    .content>a {
      font-size: 22px;
      line-height: 29px;
      padding: 15px 30px;
    }

    #bgImage {
      max-width: 350px;
    }
  }
` 

const Title = styled.h2`
  text-align: center;
  font-size: 2.083vw;
  line-height: 2.500vw;
  font-weight: bold;
  letter-spacing: 0px;
  color: #0C1151;
  text-transform: uppercase;
  opacity: 1;

  @media (max-width: 992px) {
    font-size: 5.556vw;
    line-height: 6.667vw;
    margin: auto;
    max-width: 65vw;
  }

  @media (min-width: 1920px) {
    font-size: 40px;
    line-height: 48px;
  }
`

export default function RecommendedLinks( props ) {
    return (
      <Wrapper 
          className={props.className}
          gap={props.gap}
          placeself={props.placeself}
          width={props.width}
        >
        <Title>{props.title}</Title>
        <FlexInColumn className="content" mobile="column">
          {
            props.links.map((item, index) => {
              return (  
                <Link key={'recommendedLink' + index} to={item.link} className={index}>
                  {item.title}
                </Link>
              )
            })
          }
        </FlexInColumn>
        <img id="bgImage" src={BgImage} alt="/" />
      </Wrapper>
    )
}