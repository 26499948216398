import React, { useState, useEffect } from "react";
import {
  Button,
  Drawer,
  Space,
  Steps
} from 'antd';
import { useForm } from "antd/es/form/Form";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import 'react-quill/dist/quill.snow.css';
import ChooseColumns from "./ChooseColumns";
import ExportData from "./ExportData";
import dayjs from "dayjs";

function DrawerExportTable({ open, close, data, type, columns }) {

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [dataToExport, setDataToExport] = useState([]);
  const [columnsToExport, setColumnsToExport] = useState(columns);
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    let aux = [];
    console.log(columns)
    for (let i = 0; i < data.length; i++) {
        delete data[i].actions
        delete data[i].key
        aux.push(data[i]);
    }
    


    setDataToExport(aux);
  }, [data]);

  const [form] = useForm();

  function handleClose() {
    form.resetFields();
    close();
    setCurrent(0);
  }


  function handleExport() {
    let fileName = `${dayjs().format('YYYY-MM-DD')}_${dayjs().format('HHmmss')}_${type}_Socios-SPAP.xlsx`;
    let exportData = [];

    const headers = columnsToExport.map(column => column.title);
    exportData.push(headers);

    dataToExport.forEach(row => {
      let rowData = columnsToExport.map(column => {
        let value = row[column.dataIndex];
        if (typeof value === 'object' && value?.props && value.props?.children) {
          value = value?.props?.children;
        }
        return value;
      });
      exportData.push(rowData);
    });


    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(exportData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

    const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
    const file = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    saveAs(file, fileName);
    close();
    setCurrent(0);
  };

  function handleChooseColumns(e) {
    let auxColumns = e.columns;
    console.log(auxColumns);
    setColumnsToExport(auxColumns);
    setCurrent(1);
  }

  function handleChangeStep(e) {
    setCurrent(e);
  }


  return (
    <Drawer
      className="drawer-banner"
      key={`drawer-create-banner`}
      title="Exportar"
      width={1200}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={
        <>
          <Space>
          {current === 0 &&
          <>
            <Button className="mr-10" onClick={handleClose}>Cancelar</Button>
            <Button type="primary" onClick={form.submit} className="bg-blue-btn">
              Seguinte
            </Button></>
          }
          {current === 1 &&
            <>
              <Button onClick={() => setCurrent(0)}>
                Anterior
              </Button>
              <Button type="primary" onClick={handleExport} className="bg-blue-btn">
                Exportar
              </Button>
            </>
          }</Space>
        </>
      }
    >
      <Steps
        className="step-export"
        current={current}
        onChange={handleChangeStep}
        items={[
          {
            title: 'Escolher colunas',
          },
          {
            title: 'Exportar dados',
          },
        ]}
      />
      {current === 0 &&
        <ChooseColumns columns={columns} form={form} handleSubmit={handleChooseColumns} data={dataToExport} type={type} />
      }
      {current === 1 &&
        <ExportData data={dataToExport} columns={columnsToExport} type={type} />
      }
    </Drawer>
  );
};

export default DrawerExportTable;