import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";

import Container from "../../../Components/Container";
import FlexInColumn from "../../../Components/FlexInColumn";
import FlexInRow from "../../../Components/FlexInRow";
import PageTitle from "../../../Components/PageTitle";
import PageText from "../../../Components/PageText";
import SidebarMenu from "../../../Components/SidebarMenu";
import RecommendedLinks from "../../../Components/RecommendedLinks";
import { Helmet } from "react-helmet";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  img {
    width: 100%;
  }

  .content {
    padding-bottom: 4.688vw;
  }

  p.text {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 2px;

    a {
      color: ${(props) => props.theme.orange};
      display: contents;
      text-decoration: underline;
    }
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    p.text {
      font-size: 3.333vw;
      line-height: 4.444vw;
    }
  }

  @media (min-width: 1920px) {
    p.text {
      font-size: 18px;
      line-height: 24px;
    }

    .sidebar > section {
      margin-left: auto;
    }
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 3.021vw;
  grid-row-gap: 2.604vw;

  .card {
    border: none;
    text-align: center;
    font-size: 1.042vw;
    letter-spacing: 0px;
    line-height: inherit;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0;
    border-radius: 0.521vw;
    overflow: hidden;
    position: relative;
    transition: 0.3s ease;
    height: 15.111vw;

    picture {
      height: 100%;
    }
  }

  .card:hover {
    box-shadow: 0 8px 24px #00000055;
  }

  .card__content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease;
    background: rgba(12, 17, 81, 0.1);
    padding: 10px;
  }

  .card:hover .card__content {
    background-color: #fbefc2;
  }

  .card__image {
    height: 100%;
    object-fit: cover;
  }

  .card__title {
    text-align: center;
    font-size: 1.25vw;
    font-weight: bold;
    line-height: inherit;
    letter-spacing: 0px;
    color: #ffffff;
    text-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    margin: 0;
  }

  .card__icon {
    color: #ffffff;
    height: 2.604vw;
    transition: 0.3s ease;
  }

  .card__text,
  .card:hover .card__icon {
    display: none;
  }

  .card__text {
    font-size: 1vw;
    line-height: 1.25vw;
    text-align: center;
  }

  .card:hover .card__title {
    color: ${(props) => props.theme.orange};
  }

  .card:hover .card__text {
    display: initial;
  }

  @media (max-width: 992px) {
    grid-row-gap: 6.944vw;
    grid-template-columns: 1fr;
    margin-top: 6.944vw;

    .card {
    }

    .card__content {
      height: 100%;
    }

    .card__title {
      font-size: 3.889vw;
      line-height: 4.722vw;
    }

    .card__icon {
      height: 8.306vw;
    }
  }

  @media (min-width: 1920px) {
    grid-column-gap: 58px;
    grid-row-gap: 50px;

    .card {
      height: 236px;
    }

    .card__title {
      font-size: 24px;
      line-height: 28px;
    }

    .card__text {
      font-size: 18px;
      line-height: 22px;
    }

    .card__icon {
      height: 50px;
    }
  }
`;

export default function Glossario(props) {
  const SidebarMenuLinks = [
    {
      title: "Anatomia patológica",
      link: "/spap/anatomia-patologica",
    },
    {
      title: "Glossário",
      link: "/spap/glossario",
    },
    {
      title: "Conheça um patologista",
      link: "/spap/conheca-um-patologista",
    },
  ];

  const ContentItens = [
    {
      desktop: require("../../../Assets/Images/icon-glossario-01@2x.png"),
      mobile: require("../../../Assets/Images/icon-glossario-01.png"),
      alt: "Card 1",
      title: "Exame anatomopatológico",
      text: "É uma análise laboratorial de base morfológica de órgãos ou de parte deles, de tecidos e de células, de indivíduos vivos ou de cadáver, para o despiste e a caracterização de lesões, contribuindo para a prevenção, o diagnóstico e o prognóstico das doenças, a decisão terapêutica sobre as mesmas ou a elucidação dos processos mórbidos",
    },
    {
      desktop: require("../../../Assets/Images/icon-glossario-02@2x.png"),
      mobile: require("../../../Assets/Images/icon-glossario-02.png"),
      alt: "Card 2",
      title: "Relatório de exame anatomopatológico",
      text: "Documento escrito, validado por especialista em anatomia patológica, que contém, obrigatoriamente, as conclusões dos procedimentos de análise efectuados, sob a forma de diagnóstico, e que são acompanhadas de comentários quando for julgado conveniente",
    },
    {
      desktop: require("../../../Assets/Images/icon-glossario-03@2x.png"),
      mobile: require("../../../Assets/Images/icon-glossario-03.png"),
      alt: "Card 3",
      title: "Amostra biológica",
      text: "Amostra obtida por um acto de colheita e sobre a qual vão ser efectuados um ou vários exames laboratoriais do âmbito da anatomia patológica",
    },
    {
      desktop: require("../../../Assets/Images/icon-glossario-04@2x.png"),
      mobile: require("../../../Assets/Images/icon-glossario-04.png"),
      alt: "Card 4",
      title: "Biópsia",
      text: "Fragmento de órgão ou de tecido colhido por meios cirúrgicos, por meio de instrumentação endoscópica ou por meio de agulha",
    },
    {
      desktop: require("../../../Assets/Images/icon-glossario-05@2x.png"),
      mobile: require("../../../Assets/Images/icon-glossario-05.png"),
      alt: "Card 5",
      title: "Peça operatória",
      text: "Parte ou a totalidade de um órgão ou de estrutura anatómica a examinar",
    },
    {
      desktop: require("../../../Assets/Images/icon-glossario-06@2x.png"),
      mobile: require("../../../Assets/Images/icon-glossario-06.png"),
      alt: "Card 6",
      title: "Exame extemporâneo ou per-operatório",
      text: "Amostra biológica examinada durante um procedimento cirúrgico para determinar a natureza de um tecido ou lesão ou o estádio das margens da excisão",
    },
    {
      desktop: require("../../../Assets/Images/icon-glossario-07@2x.png"),
      mobile: require("../../../Assets/Images/icon-glossario-07.png"),
      alt: "Card 7",
      title: "Citologia esfoliativa",
      text: "Amostra constituída por células e outros componentes recolhidos por procedimento abrasivo da superfície ou lume de um órgão ou cavidade ou contidos em produto de secreção ou excreção",
    },
    {
      desktop: require("../../../Assets/Images/icon-glossario-08@2x.png"),
      mobile: require("../../../Assets/Images/icon-glossario-08.png"),
      alt: "Card 8",
      title: "Citologia aspirativa",
      text: "Amostra constituída por células e outros componentes colhidos por agulha em estruturas internas, superficiais ou profundas, sujeitas a uma pressão negativa",
    },
    {
      desktop: require("../../../Assets/Images/icon-glossario-09@2x.png"),
      mobile: require("../../../Assets/Images/icon-glossario-09.png"),
      alt: "Card 9",
      title: "Autópsia clínica",
      text: "Exame macroscópico do hábito externo e do hábito interno, dos órgãos in situ e após dissecção, desejavelmente complementado, por exame microscópico e outros exames laboratoriais, com o objectivo de elucidar a causa da morte e os processos mórbidos naturais envolvidos",
    },
  ];

  return (
    <Wrapper>
      <Helmet>
        <title>
          Glossário | Sociedade Portuguesa de Anatomia Patológica - SPAP
        </title>
        <meta
          name="description"
          content="Conheça aqui os principais termos utilizados na área da anatomia patológica."
        />
        <meta
          name="keywords"
          content="spap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>

      <Container className="content">
        <PageTitle
          title="Glossário"
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "SPAP",
              link: "/spap",
            },
            {
              text: "O que é anatomia patológica?",
              link: "/spap/anatomia-patologica",
            },
            {
              text: "Glossário",
            },
          ]}
          imageDesktop={require("../../../Assets/Images/iccon-glossario@2x.png")}
          imageMobile={require("../../../Assets/Images/iccon-glossario.png")}
          imageAlt="Glossário"
          sidebar={<SidebarMenu navItens={SidebarMenuLinks} />}
        />

        <FlexInRow gap="95px">
          <FlexInColumn gap="238px" mobile="column">
            <FlexInRow gap="0">
              <PageText title="Conheça aqui os principais termos utilizados na área da anatomia patológica." />
              <p className="text">
                Se quiser saber o significado dos principais termos, carregue
                nas imagens.{" "}
              </p>
              <p className="text">
                Encontre também{" "}
                <a
                  href="https://www.mypathologyreport.ca/pathology-dictionary/"
                  target="_blank"
                >
                  aqui
                </a>{" "}
                uma explicação detalhada dos termos habitualmente utilizados
                pelos médicos anatomo-patologistas (em inglês).
              </p>
            </FlexInRow>
          </FlexInColumn>

          <Content>
            {ContentItens.map((item, index) => {
              return (
                <div className={"card card-" + index}>
                  <picture>
                    <source srcSet={item.desktop} media="(min-width: 990px)" />
                    <img
                      src={item.mobile}
                      alt={item.alt}
                      className="card__image"
                    />
                  </picture>
                  <div className="card__content">
                    <FlexInRow gap="1.172vw">
                      <h3 className="card__title">{item.title}</h3>
                      <FontAwesomeIcon
                        icon={faCirclePlus}
                        className="card__icon"
                      />
                      <p className="card__text">{item.text}</p>
                    </FlexInRow>
                  </div>
                </div>
              );
            })}
          </Content>
        </FlexInRow>
      </Container>

      <RecommendedLinks
        title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
        links={[
          {
            title: "História",
            link: "/spap/historia",
          },
          {
            title: "Órgãos sociais",
            link: "/spap/orgaos-sociais",
          },
          {
            title: "Bolsas e prémios",
            link: "/spap/bolsas-e-premios",
          },
          {
            title: "Vantagens de ser sócio",
            link: "/socios/conheca-as-vantagens",
          },
        ]}
      />
    </Wrapper>
  );
}
