import styled from "styled-components";

import Container from "../../../../Components/Container";
import PageTitle from "../../../../Components/PageTitle";
import PageText from "../../../../Components/PageText";
import FlexInColumn from "../../../../Components/FlexInColumn";
import FlexInRow from "../../../../Components/FlexInRow";
import SidebarMenu from "../../../../Components/SidebarMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

import { ReactComponent as IconTwitter } from "../../../../Assets/Images/NISPAP/Icon-social-twitter-botton.svg";
import { Link } from "react-router-dom";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin: 7.813vw 0 5.208vw;

  img {
    width: 100%;
  }

  .link {
    color: ${(props) => props.theme.orange};
    font-weight: bold;
    margin-bottom: 0.854vw;
    place-self: flex-start;
    transition: 0.3s ease;
    text-decoration: none;
    text-align: left;
  }

  .link:hover {
    color: ${(props) => props.theme.orangeHover};
  }

  .link .icon {
    color: ${(props) => props.theme.blue};
    font-size: 1.354vw;
    margin-right: 5px;
    width: 1.667vw;
  }

  .link .icon path {
    fill: ${(props) => props.theme.blue};
  }

  .link:hover .icon {
    color: ${(props) => props.theme.blueHover};
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    .link {
      font-size: 3.333vw;
      line-height: 4.444vw;
    }
  }
`;

const SectionGroups = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2.604vw;

  .grid {
    gap: 1.563vw;
    display: grid;
    grid-template-columns: auto 1fr;
  }

  .title {
    text-align: left;
    color: ${(props) => props.theme.blue};
    opacity: 1;
    font-size: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: inherit;
    margin: 0;
  }

  .bar {
    background-color: #97c1d5;
    height: 1px;
    margin: auto 0;
  }

  .image {
    width: 11.979vw;
  }

  p {
    color: #000000;
    font-size: 0.938vw;
    letter-spacing: 0px;
    line-height: 1.25vw;
    text-align: left;
  }

  .gallery-item {
    cursor: pointer;
    position: relative;
    min-width: 27.917vw;
    max-width: 27.917vw;

    .cta {
      background-color: #f88436;
      bottom: 1.042vw;
      border-radius: 360px;
      left: 1.042vw;
      opacity: 0.8;
      padding: 0.417vw 0.625vw;
      position: absolute;
      text-transform: uppercase;
    }
  }

  @media (max-width: 992px) {
    margin-top: 40px;

    p {
      font-size: 3.333vw;
      line-height: 4.444vw;
    }

    .bar {
      width: auto;
    }

    .title {
      font-size: 4.333vw;
      line-height: 5.444vw;
    }
  }
`;

const GroupCards = styled.div`
  display: grid;
  grid-column-gap: 3.125vw;
  grid-row-gap: 3.646vw;
  grid-template-columns: 1fr 1fr 1fr;

  .item {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
  }

  .item__title {
    color: #ffffff;
    position: absolute;
    text-align: center;
    font-size: 2.083vw;
    font-weight: bold;
    line-height: 2.5vw;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 1;
    text-shadow: 0px 8px 20px #00000055;
  }

  @media (max-width: 992px) {
    grid-row-gap: 20px;
    grid-template-columns: 1fr;

    .item__title {
      font-size: 4.333vw;
      line-height: 5.444vw;
    }
  }
`;

export default function ReunioesIntersevicos(props) {
  const SidebarMenuLinks = [
    {
      title: "Estágios",
      link: "/nispap/utilitarios/estagios",
    },
    {
      title: "Reuniões interserviços",
      link: "/nispap/utilitarios/reunioes-interservicos",
    },
    {
      title: "Links úteis",
      link: "/nispap/utilitarios/links-uteis",
    },
  ];

  const ListCards = [
    {
      title: "Junho 2022",
      link: "https://drive.google.com/drive/folders/16hPidI7R4Gp-rMKwlG3DERcTqqJK3sjY",
      imageDesktop: require("../../../../Assets/Images/NISPAP/Card-caso-clinico-01@2x.png"),
      imageMobile: require("../../../../Assets/Images/NISPAP/Card-caso-clinico-01.png"),
      imageAlt: "Junho 2022",
    },
    {
      title: "Abril 2022",
      link: "https://drive.google.com/drive/folders/1q7bo6zIauVOwJ-m431Imfu1L6V_98Mu5",
      imageDesktop: require("../../../../Assets/Images/NISPAP/Card-caso-clinico-02@2x.png"),
      imageMobile: require("../../../../Assets/Images/NISPAP/Card-caso-clinico-02.png"),
      imageAlt: "Abril 2022",
    },
    {
      title: "Outubro 2021",
      link: "https://drive.google.com/drive/folders/1DzrU__Ff8DM68V7vkv3m0WzKHhwMMx8r",
      imageDesktop: require("../../../../Assets/Images/NISPAP/Card-caso-clinico-03@2x.png"),
      imageMobile: require("../../../../Assets/Images/NISPAP/Card-caso-clinico-03.png"),
      imageAlt: "Outubro 2021",
    },
    {
      title: "Maio 2021",
      link: "https://drive.google.com/drive/folders/1PVFS_zhWUpK2ZprlXiuj0OuGh3rUjxw2",
      imageDesktop: require("../../../../Assets/Images/NISPAP/Card-caso-clinico-04@2x.png"),
      imageMobile: require("../../../../Assets/Images/NISPAP/Card-caso-clinico-04.png"),
      imageAlt: "Maio 2021",
    },
    {
      title: "Março 2021",
      link: "https://drive.google.com/drive/folders/1TFWxJHT3Tw2SArnJseWBUije485sYLh_",
      imageDesktop: require("../../../../Assets/Images/NISPAP/Card-caso-clinico-05@2x.png"),
      imageMobile: require("../../../../Assets/Images/NISPAP/Card-caso-clinico-05.png"),
      imageAlt: "Março 2021",
    },
  ];

  return (
    <Wrapper>
      <Helmet>
        <title>
          Reuniões Interserviços NISPAP | Núcleo de Internos Sociedade
          Portuguesa de Anatomia Patológica - NISPAP
        </title>
        <meta
          name="description"
          content="A reunião inter-serviços de Anatomia Patológica é uma reunião de âmbito nacional, com periodicidade mensal, na qual são apresentados casos anatomo-clínicos com interesse pedagógico, ocorrendo discussão sobre os diferentes diagnósticos diferenciais e meios de diagnóstico utilizados, acompanhada de uma breve revisão da literatura sobre cada tema."
        />
        <meta
          name="keywords"
          content="nispap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      <Container>
        <PageTitle
          title="Reunião Interserviços"
          path={[
            {
              text: "NISPAP",
              link: "/nispap",
            },
            {
              text: "Utilitários",
              link: "/nispap/utilitarios",
            },
            {
              text: "Reunião Interserviços",
            },
          ]}
          imageDesktop={require("../../../../Assets/Images/NISPAP/img-reuniao-interservicos@2x.png")}
          imageMobile={require("../../../../Assets/Images/NISPAP/img-reuniao-interservicos.png")}
          imageAlt="Reunião Interserviços"
          sidebar={<SidebarMenu navItens={SidebarMenuLinks} nispap={true} />}
        />

        <FlexInRow gap="6.146vw">
          <FlexInColumn gap="12.396vw" mobile="column">
            <FlexInRow gap="0">
              <PageText text="A reunião inter-serviços de Anatomia Patológica é uma reunião de âmbito nacional, com periodicidade mensal, na qual são apresentados casos anatomo-clínicos com interesse pedagógico, ocorrendo discussão sobre os diferentes diagnósticos diferenciais e meios de diagnóstico utilizados, acompanhada de uma breve revisão da literatura sobre cada tema. " />
              <PageText text="Nesta secção estão disponíveis os diferentes casos e respectivas apresentações das reuniões inter-serviços de Anatomia Patológica. " />
              <PageText text="Enviem-nos as vossas apresentações para:" />
              <a href="mailto:nucleointernosspap@gmail.com" className="link">
                <FontAwesomeIcon icon={faEnvelope} className="icon" />{" "}
                nucleointernosspap@gmail.com
              </a>
              <a
                href="https://twitter.com/SPAPatologia"
                target="_blank"
                className="link"
              >
                <IconTwitter className="icon" /> SPAPatologia
              </a>
            </FlexInRow>
          </FlexInColumn>

          <SectionGroups>
            <FlexInRow gap="3.646vw">
              <div className="grid">
                <h3 className="title">
                  Reve aqui os casos da reunião interserviços
                </h3>
                <div className="bar"></div>
              </div>
              {ListCards.length > 0 && (
                <GroupCards>
                  {ListCards.map((item, index) => {
                    return (
                      <Link
                        to={item.link}
                        target="_blank"
                        className={"item item-" + index}
                      >
                        <picture className="item__image">
                          <source
                            srcSet={item.imageDesktop}
                            media="(min-width: 990px)"
                          />
                          <img src={item.imageMobile} alt={item.imageAlt} />
                        </picture>
                        <h3 className="item__title">{item.title}</h3>
                      </Link>
                    );
                  })}
                </GroupCards>
              )}
            </FlexInRow>
          </SectionGroups>
        </FlexInRow>
      </Container>
    </Wrapper>
  );
}
