import { React, useState, useEffect } from "react";
import styled from "styled-components";

import Container from "../../../Components/Container";
import FlexInColumn from "../../../Components/FlexInColumn";
import FlexInRow from "../../../Components/FlexInRow";
import PageTitle from "../../../Components/PageTitle";
import PageText from "../../../Components/PageText";
import SidebarMenu from "../../../Components/SidebarMenu";
import RecommendedLinks from "../../../Components/RecommendedLinks";
import { Helmet } from "react-helmet";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  img {
    width: 100%;
  }

  .content {
    padding-bottom: 4.688vw;
  }

  @media (max-width: 992px) {
    margin-top: 120px;
  }

  @media (min-width: 1920px) {
    .sidebar > section {
      margin-left: auto;
    }
  }
`;

const Title = styled.h2`
  font-size: 2.083vw;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 2.5vw;
  letter-spacing: 0px;
  color: #0c1151;
  text-transform: uppercase;
  opacity: 1;
  text-align: center;

  @media (max-width: 992px) {
    font-size: 22px;
    line-height: 28px;
  }

  @media (min-width: 1920px) {
    font-size: 40px;
    line-height: 48px;
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 2.5vw;

  .card {
    border: none;
    text-align: left;
    font-size: 15px;
    letter-spacing: 0px;
    line-height: 1.5;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0;
  }

  @media (min-width: 992px) {
    grid-column-gap: 5vw;
    margin: 0 12.292vw;
  }

  @media (max-width: 992px) {
    grid-row-gap: 6.944vw;
    grid-template-columns: 1fr;

    .card {
      font-size: 3.333vw;
      line-height: 4.444vw;
    }

    .card > div {
      gap: 20px;
    }

    .card > p {
      margin-bottom: 0;
    }
  }

  @media (min-width: 1920px) {
    grid-column-gap: 96px;
    margin: 0 376px;
  }
`;

export default function AnatomiaPatologica(props) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const SidebarMenuLinks = [
    {
      title: "Anatomia patológica",
      link: "/spap/anatomia-patologica",
    },
    {
      title: "Glossário",
      link: "/spap/glossario",
    },
    {
      title: "Conheça um patologista",
      link: "/spap/conheca-um-patologista",
    },
  ];

  const ContentItens = [
    {
      desktop: require("../../../Assets/Images/img-anatomia-01@2x.png"),
      mobile: require("../../../Assets/Images/img-anatomia-01.png"),
      alt: "Card 1",
      text: "Está presente no início da vida, ao examinar a placenta de recém-nascidos e orientar a atuação médica neonatal e obstétrica. No caso de abortamento, o médico anatomopatologista pode determinar o diagnóstico de possíveis doenças do desenvolvimento, com implicações no planeamento de futura gravidez.",
    },
    {
      desktop: require("../../../Assets/Images/img-anatomia-02@2x.png"),
      mobile: require("../../../Assets/Images/img-anatomia-02.png"),
      alt: "Card 2",
      text: "Está presente em intervenções de biópsia de difícil acesso, como as biópsias endoscópicas ecoguiadas. Durante o procedimento, o médico anatomopatologista faz uma rápida avaliação do material obtido, confirmando ao médico intervencionista que foi colhida uma amostra adequada para análise.",
    },
    {
      desktop: require("../../../Assets/Images/img-anatomia-03@2x.png"),
      mobile: require("../../../Assets/Images/img-anatomia-03.png"),
      alt: "Card 3",
      text: "Está presente nos laboratórios de anatomia patológica, a examinar a amostra obtida para uma caracterização precisa da patologia de cada doente, emitindo um relatório que transmite toda a informação necessária à equipa médica que acompanha o doente.",
    },
    {
      desktop: require("../../../Assets/Images/img-anatomia-04@2x.png"),
      mobile: require("../../../Assets/Images/img-anatomia-04.png"),
      alt: "Card 4",
      text: "Está presente nas reuniões multidisciplinares de decisão terapêutica, esclarecendo dúvidas e contribuindo na determinação do plano de tratamento de cada doente.",
    },
    {
      desktop: require("../../../Assets/Images/img-anatomia-05@2x.png"),
      mobile: require("../../../Assets/Images/img-anatomia-05.png"),
      alt: "Card 5",
      text: "Está presente nas intervenções cirúrgicas, para apoiar a equipa médica sempre que seja necessário realizar um estudo intraoperatório que determinará o curso da cirurgia.",
    },
    {
      desktop: require("../../../Assets/Images/img-anatomia-06@2x.png"),
      mobile: require("../../../Assets/Images/img-anatomia-06.png"),
      alt: "Card 6",
      text: "Está presente no Rastreio Nacional de Cancro do Colo do Útero, ao avaliar as amostras citológicas colhidas no âmbito do rastreio, contribuindo para a prevenção do cancro.",
    },
    {
      desktop: require("../../../Assets/Images/img-anatomia-07@2x.png"),
      mobile: require("../../../Assets/Images/img-anatomia-07.png"),
      alt: "Card 7",
      text: "Poderá conhecer o seu médico anatomopatologista se precisar de realizar uma biópsias aspirativa de agulha fina, para a colheita de uma amostra da lesão, num serviço de Anatomia Patológica.",
    },
    {
      desktop: require("../../../Assets/Images/img-anatomia-08@2x.png"),
      mobile: require("../../../Assets/Images/img-anatomia-08.png"),
      alt: "Card 8",
      text: "Está presente no caso de um desfecho fatal da doença, procedendo a uma autópsia anatomoclínica que dê resposta a questões que podem ter ficado por explicar ainda em vida, esclarecendo a equipa médica e os familiares do doente.",
    },
  ];

  return (
    <Wrapper>
      <Helmet>
        <title>
          O que é a anatomia patológica? | Sociedade Portuguesa de Anatomia
          Patológica - SPAP
        </title>
        <meta
          name="description"
          content="A Anatomia Patológica é uma especialidade médica dedicada ao diagnóstico das patologias. É a especialidade médica com foco no diagnóstico das patologias através do estudo macroscópico, microscópico e molecular."
        />
        <meta
          name="keywords"
          content="spap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      <Container className="content">
        <PageTitle
          title="O QUE É ANATOMIA PATOLÓGICA?"
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "SPAP",
              link: "/spap",
            },
            {
              text: "O que é anatomia patológica?",
            },
          ]}
          imageDesktop={require("../../../Assets/Images/img-o-que-e-anatomia@2x.png")}
          imageMobile={require("../../../Assets/Images/img-o-que-e-anatomia.png")}
          imageAlt="Patologista"
          sidebar={<SidebarMenu navItens={SidebarMenuLinks} />}
        />

        <FlexInRow gap="3.333vw">
          <FlexInColumn gap="238px" mobile="column">
            <FlexInRow gap="0">
              <PageText
                title="A Anatomia Patológica é uma especialidade médica dedicada ao diagnóstico das patologias."
                text="É a especialidade médica com foco no diagnóstico das patologias através do estudo macroscópico, microscópico e molecular. Fornece também informação sobre fatores prognósticos e preditivos de resposta terapêutica."
              />
              <PageText text="Este estudo requer a colheita de uma amostra (histológica ou citológica) ou de uma peça cirúrgica, que são avaliadas por um médico anatomopatologista. É uma atividade que segue procedimentos criteriosos de análise macroscópica e microscópica, utilizando técnicas especializadas de histoquímica, imunohistoquímica e de biologia molecular. O médico anatomopatologista é responsável por determinar o diagnóstico para que a restante equipa médica estabeleça o plano terapêutico." />
            </FlexInRow>
          </FlexInColumn>

          <FlexInRow gap="40px">
            <Title>ONDE PODE ENCONTRAR UM MÉDICO ANATOMOPATOLOGISTA?</Title>
            <Content>
              {ContentItens.map((item, index) => {
                return (
                  <div className={"card card-" + index}>
                    <FlexInRow>
                      <picture>
                        <source
                          srcSet={item.desktop}
                          media="(min-width: 990px)"
                        />
                        <img src={item.mobile} alt={item.alt} />
                      </picture>
                      <p>{item.text}</p>
                    </FlexInRow>
                  </div>
                );
              })}
            </Content>
          </FlexInRow>
        </FlexInRow>
      </Container>

      <RecommendedLinks
        title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
        links={[
          {
            title: "História",
            link: "/spap/historia",
          },
          {
            title: "Órgãos sociais",
            link: "/spap/orgaos-sociais",
          },
          {
            title: "Bolsas e prémios",
            link: "/spap/bolsas-e-premios",
          },
          {
            title: "Vantagens de ser sócio",
            link: "/socios/conheca-as-vantagens",
          },
        ]}
      />
    </Wrapper>
  );
}
