import { Divider } from "antd";
import styled from "styled-components";
import Card from "../../Components/Backoffice/Card";
import Title from "../../Components/Backoffice/Title";
import { Table } from "antd";
import React, { useEffect, useState } from "react";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;

  .card {
    background-color: #d6d7e2;
    border: none;
  }

  .ant-table-thead .ant-table-cell,
  .ant-table-tbody .ant-table-cell {
    text-align: center !important;
  }

  p {
    color: #8688a8;
    text-align: left;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0px;
    margin: 0;
  }

  .ant-table-thead {
    .ant-table-cell {
      background-color: #d6d7e2;
      text-align: left;
      color: #0c1151;
    }

    .ant-table-cell::before {
      display: none !important;
    }
  }

  .ant-table-tbody {
    .ant-table-row {
      font-weight: bold;
    }

    .ant-table-row:nth-child(odd),
    .ant-table-row:nth-child(odd) .ant-table-cell {
      background-color: #0c115180;
      color: #ffffff;
    }

    .ant-table-row:nth-child(even),
    .ant-table-row:nth-child(even) .ant-table-cell {
      background-color: #d6d7e2;
      color: #050708;
    }

    .ant-table-cell {
      border-bottom: none;
    }
  }

  .compras .ant-table-row:nth-child(odd),
  .compras .ant-table-row:nth-child(odd) .ant-table-cell {
    background-color: #8688a8 !important;
    color: #050708;
  }

  .icon-download {
    cursor: pointer;
    height: 2.395vw;
    width: 2.395vw;
  }
`;

export default function TableDocuments(props) {
  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const [quotas, setQuotas] = useState([]);
  const [dataToShow, setDataToShow] = useState([]);

  const getQuotas = (allQuotas) => {
    allQuotas.forEach((quota) => {
      if ((quota.status = "pago")) {
        quota.status = "Pago";
      } else {
        quota.status = "Não pago";
      }
    });
    const quotasOrdenadas = allQuotas?.sort((b, a) => a.ano - b.ano);
    setDataToShow(() => getDataToShow(quotasOrdenadas));
  };

  useEffect(() => {
    fetch(
      `https://api.spap.pt/api/get-quotas-user/` +
        session_storage.all_data.user_session.id,
      {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          Authorization: "Bearer " + session_storage.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar quotas");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data.length === 0) {
          console.log("nenhumas quotas");
        }
        getQuotas(data.quotas);
      })
      .catch((error) => console.error(error));
  }, []);

  const getDataToShow = (quotasAListar = []) => {
    console.log(props);

    if (quotasAListar?.length == 0) {
      return [];
    }

    let data = [];
    for (let i = 0; i < quotasAListar.length; i++) {
      data.push({
        key: i,
        tipo: <b>{quotasAListar[i].tipo}</b>,
        ano: <b>{quotasAListar[i].ano}</b>,
        data: (
          <b>
            {quotasAListar[i].payment_valid_date
              ? quotasAListar[i].payment_valid_date
              : "-"}
          </b>
        ),
        valor: <b>{quotasAListar[i].valor}</b>,
        status: quotasAListar[i].status,
        observacoes: <b>{quotasAListar[i].obs ? quotasAListar[i].obs : "-"}</b>,
      });
    }

    return data;
  };

  const thisColumns = [
    {
      title: "Tipo",
      dataIndex: "tipo",
    },
    {
      title: "Ano",
      dataIndex: "ano",
    },
    {
      title: "Valor",
      dataIndex: "valor",
    },
    {
      title: "Estado",
      dataIndex: "status",
    },
    {
      title: "Observacoes",
      dataIndex: "observacoes",
    },
  ];

  return (
    <Wrapper>
      {props.option === "quotas" && (
        <Card className="card">
          <Title>Quotas</Title>
          <p>
            <i>Histórico das quotas ao longo dos anos.</i>
          </p>
          <Divider />
          {props.dataSource?.length > 0 ? (
            <>
              <Table
                columns={props.columns}
                dataSource={props.dataSource}
                pagination={{ position: ["none", "none"] }}
              />
            </>
          ) : (
            <>
              <p style={{ textAlign: "center" }}>Sem quotas por mostrar.</p>
            </>
          )}
        </Card>
      )}
      {/* {
        (props.option === 'certificados') &&
        <Card className='card'>
          <Title>Certificados</Title>
          <p><i>Histórico de certificados obtidos, com os recibos em PDF para consulta</i></p>
          <Divider />
          <Table columns={columns} dataSource={data} pagination={{position: ['none', 'none']}} />
        </Card>
      }
      {
        (props.option === 'compras') &&
        <Card className='card compras'>
          <Title>Compras</Title>
          <p><i>Histórico de pagamento de compras, com os recibos em PDF para consulta</i></p>
          <Divider />
          <Table columns={columns} dataSource={data} pagination={{position: ['none', 'none']}} />
        </Card>
      } */}
    </Wrapper>
  );
}
