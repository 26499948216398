import styled from "styled-components";
import { Link, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faCalendarDay,
  faFileArchive,
  faFileCirclePlus,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import * as urlSlug from "url-slug";
import {
  ShareAltOutlined,
  CreditCardOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import { Col, Row, notification } from "antd";
import EventShoppingCart from "../../../Components/EventShoppingCart";

import Container from "../../../Components/Container";
import FlexInColumn from "../../../Components/FlexInColumn";
import FlexInRow from "../../../Components/FlexInRow";
import PageTitle from "../../../Components/PageTitle";

import IconFile from "../../../Assets/Images/icon-awesome-file-alt.svg";
import IconSubscribe from "../../../Assets/Images/icon-partilhar.svg";
import IconSeeMore from "../../../Assets/Images/icon-saber-mais.svg";
import IconShare from "../../../Assets/Images/icon-partilhar.svg";
import IconReturn from "../../../Assets/Images/icon-voltar.svg";
import Item from "antd/es/list/Item";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #000000;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: left;
  width: 100%;
  margin-top: 7.813vw;

  .event__content p {
    margin-bottom: 8px !important;
  }

  img {
    width: 100%;
  }

  .tag-evento-pago {
    background: rgb(248, 132, 54);
    color: #fff;
    font-size: 14px;
    padding: 4px 20px;
    border-radius: 10px;
    margin-top: -4px;
  }
  .tag-evento-adquirido {
    background: #59b46e;
    color: #fff;
    font-size: 14px;
    padding: 4px 20px;
    border-radius: 10px;
    margin-top: -4px;
  }

  .event-btns ul {
    width: 100% !important;
  }

  .event-btns ul a  {
    text-align: center;
  }

  ul {
    list-style: none;
    width: 8.333vw;
    padding: 0;
  }

  li {
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
    gap: 0.729vw;
    text-align: left;
    font-size: 1.146vw;
    font-weight: bold;
    line-height: 1.51vw;
    letter-spacing: 0px;
    opacity: 1;
    margin: 1.042vw 0;

    img {
      width: 1.667vw;
    }
  }

  .event-btns li a,
  .event-btns .share {
    color: #fff;
    text-decoration: none;
    background: rgb(12, 17, 81);
    padding: 10px 20px;
    border-radius: 6px;
    text-align: center;
    width: 100%;
  }

  .event-btns li a.inscrever {
    background: rgb(248, 132, 54) !important;
  }

  .event-btns li a.disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  .event__content a {
    display: initial;
  }

  .event__content img {
    max-width: 60%;
    display: block;
    margin-right: auto;
  }

  .event__content iframe {
    height: 30vw;
    width: 60%;
  }

  .event__content ul {
    width: 100%;
  }

  .icon {
    margin-right: 8px;
  }

  .icon-file {
    width: 3.438vw;
  }

  .share {
    cursor: pointer;
    color: ${(props) => props.theme.orange};
  }

  .event-content {
    width: 100%;
  }

  .event-content li a,
  .event-btns li a {
    gap: 15px;
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    .event__content iframe {
      height: 400px;
      width: 100%;
    }

    .event__content p {
      font-size: 16px;
      line-height: 22px;
    }

    ul {
      width: auto;
    }

    li {
      font-size: 14px;
      line-height: 20px;
      margin: 20px 0;

      img {
        width: 20px;
      }
    }
  }

  @media (min-width: 1920px) {
    li {
      font-size: 22px;
      line-height: 29px;
      margin: 20px 0;
    }
  }

  @media (min-width: 900px) {
    .event-btns {
      position: sticky;
      top: 200px;
    }
  }
`;

const Tab = styled.div`
  margin-bottom: 30px;
  width: 100%;

  span {
    text-align: left;
    font-size: 1.146vw;
    font-weight: bold;
    line-height: 1.51vw;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
  }

  @media (max-width: 992px) {
    margin-bottom: 10px;

    span {
      font-size: 3.333vw;
      line-height: 4.444vw;
    }
  }

  @media (min-width: 1920px) {
    span {
      font-size: 24px;
      line-height: 28px;
    }
  }
`;

const Title = styled.h3`
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;

  @media (max-width: 992px) {
    font-size: 3.333vw;
    line-height: 4.444vw;
  }

  @media (min-width: 1920px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

const SectionShareContent = styled.section`
  margin-top: 30px;
  padding: 3.646vw 0;
  background: #d6d7e2 0% 0% no-repeat padding-box;
  opacity: 1;

  .title {
    text-align: center;
    font-size: 2.083vw;
    font-weight: bold;
    line-height: 2.5vw;
    letter-spacing: 0px;
    color: #0c1151;
    text-transform: uppercase;
    opacity: 1;
  }

  .text {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    line-height: inherit;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    margin-bottom: 4.167vw;
  }

  @media (max-width: 992px) {
    .title {
      font-size: 24px;
      line-height: 30px;
    }

    .text {
      font-size: 18px;
      line-height: 24px;
    }
  }

  @media (min-width: 1920px) {
    .title {
      font-size: 40px;
      line-height: 48px;
    }

    .text {
      font-size: 24px;
      line-height: 28px;
    }
  }
`;

const SectionMembers = styled.section``;

const SectionMembersContent = styled.div`
  background-color: #ffffff;
  border: 3px solid #0c1151;
  border-radius: 1.042vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "image title"
    "image text"
    "image cta";
  opacity: 1;
  overflow: hidden;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "title"
      "image"
      "text"
      "cta";
    gap: 10px;
    padding: 10px 0;
    border-radius: 8px;
  }

  @media (min-width: 1920px) {
    border-radius: 10px;
  }
`;

const SectionMembersImage = styled.div`
  border-radius: 1.042vw 0px 0px 1.042vw;
  grid-area: image;
  overflow: hidden;

  @media (max-width: 992px) {
    border-radius: 0;
    height: 220px;
  }

  @media (min-width: 1920px) {
    border-radius: 10px 0px 0px 10px;
  }
`;

const SectionMembersTitle = styled.h3`
  grid-area: title;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;
  margin: 1.042vw;
  place-self: flex-end flex-start;

  @media (max-width: 992px) {
    font-size: 18px;
    line-height: 24px;
  }

  @media (min-width: 1920px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

const SectionMembersText = styled.p`
  grid-area: text;
  text-align: left;
  font-size: 0.938vw;
  line-height: 1.25vw;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin: 0 1.042vw;
  place-self: center flex-start;

  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 22px;
  }

  @media (min-width: 1920px) {
    font-size: 22px;
    line-height: 29px;
  }
`;

const SectionMembersCta = styled.a`
  grid-area: cta;
  text-align: left;
  font-size: 0.938vw;
  font-weight: bold;
  line-height: 1.25vw;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  background: #090d3d 0% 0% no-repeat padding-box;
  border-radius: 0.417vw;
  opacity: 1;
  transition: 0.3s ease;
  margin: 1.042vw;
  place-self: flex-end flex-start;
  padding: 0.521vw 1.563vw;
  text-decoration: none;
  align-items: center;
  justify-content: center;

  :hover {
    background-color: #8688a8;
    color: #e5e5e5;
  }

  @media (max-width: 992px) {
    font-size: 14px;
    line-height: 20px;
    padding: 5px 10px;
    border-radius: 10px;
  }

  @media (min-width: 1920px) {
    border-radius: 10px;
    font-size: 22px;
    line-height: 29px;
    margin: 10px;
  }
`;

export default function Evento(props) {
  const { slug } = useParams();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [openShoppingCart, setOpenShoppingCart] = useState(false);
  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const [allowedToSubscribe, setAllowedToSubscribe] = useState(false);
  const [evento, setEvento] = useState([]);

  const month = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const getEvento = (myEvento, purchases, paymentDetails) => {
    let eventoAMostrar = myEvento[0];

    eventoAMostrar.purchased = false;
    eventoAMostrar.soldOut = false;

    if (eventoAMostrar.data_inicio) {
      const [dateInicioValues, timeInicioValues] =
        eventoAMostrar.data_inicio.split(" ");
      const [dayInicio, monthInicio, yearInicio] = dateInicioValues.split("/");
      const [dateFechoValues, timeFechoValues] =
        eventoAMostrar.data_fecho.split(" ");
      const [dayFecho, monthFecho, yearFecho] = dateFechoValues.split("/");

      const monthInicioToString = month[Number(monthInicio - 1)];
      const monthFechoToString = month[Number(monthFecho - 1)];
      eventoAMostrar.monthInicio = monthInicio;
      eventoAMostrar.yearInicio = yearInicio;

      eventoAMostrar.url = eventoAMostrar.imagem_destaque
        ? "https://api.spap.pt/eventos/" +
          eventoAMostrar.id +
          "/" +
          eventoAMostrar.imagem_destaque
        : "https://api.spap.pt/clubes/clube-estudo-default.svg";

      if (
        monthInicio == monthFecho &&
        yearInicio === yearFecho &&
        dayInicio === dayFecho
      ) {
        eventoAMostrar.date =
          dayInicio + " " + monthInicioToString + " " + yearInicio;
      } else if (monthInicio == monthFecho && yearInicio === yearFecho) {
        eventoAMostrar.date =
          dayInicio +
          "-" +
          dayFecho +
          " " +
          monthInicioToString +
          " " +
          yearInicio;
      } else if (
        dayInicio == dayFecho &&
        monthInicio == monthFecho &&
        yearInicio === yearFecho
      ) {
        eventoAMostrar.date =
          dayInicio + " " + monthInicioToString + " " + yearInicio;
      } else if (monthInicio != monthFecho) {
        eventoAMostrar.date =
          dayInicio +
          " " +
          monthInicioToString +
          " a " +
          dayFecho +
          " " +
          monthFechoToString +
          " " +
          yearFecho;
      }

      if (purchases.length > 0 && purchases.includes(eventoAMostrar.id)) {
        eventoAMostrar.purchased = true;
      }

      if (paymentDetails != null && Object.keys(paymentDetails)?.length) {
        if (
          !checkIfReachedMaxTickets(
            paymentDetails.count_tickets,
            JSON.parse(paymentDetails.tickets)
          )
        ) {
          eventoAMostrar.soldOut = true;
        }

        let info = getInfoAbouTickets(JSON.parse(paymentDetails.tickets));
        console.log(info);
        setAllowedToSubscribe(info);
      }
    }

    setEvento(eventoAMostrar);
  };

  const getInfoAbouTickets = (tickets) => {
    if (tickets.some((e) => e.cargo == "todos")) {
      return true;
    }
    if (!session_storage && tickets.some((e) => e.cargo == "nao_socio")) {
      return true;
    }
    if (
      (session_storage &&
        tickets.some(
          (e) =>
            e.cargo ==
            session_storage.all_data.user_session?.tipo_socio?.toLowerCase()
        )) ||
      tickets.some(
        (e) =>
          e.cargo ==
          session_storage.all_data.user_session?.tipo_utilizador?.toLowerCase()
      )
    ) {
      return true;
    }
    if (
      session_storage &&
      tickets.some(
        (e) =>
          e.cargo == session_storage.all_data.user_session?.grau?.toLowerCase()
      )
    ) {
      return true;
    }

    return false;
  };

  const checkIfReachedMaxTickets = (count_tickets, tickets) => {
    let qtt = tickets.reduce((n, { qtd }) => n + qtd, 0);
    if (count_tickets >= qtt) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    fetch(
      `https://api.spap.pt/api/get-evento/` +
        slug.split("-").pop() +
        `/` +
        session_storage?.all_data?.user_session?.id ?? "",
      {
        method: "GET",
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data.length === 0) {
          console.log("nenhum evento");
        }
        getEvento(data.evento, data.purchases, data.paymentDetails);
      })
      .catch((error) => console.error(error));
  }, []);

  const handleShare = (link) => {
    navigator.clipboard.writeText(link);
    notification.info({ description: "Link copiado: " + link });
  };

  const addToShoppingCart = () => {
    if (localStorage.getItem("cartList") == null) {
      localStorage.setItem("cartList", JSON.stringify([evento.id]));
      setOpenShoppingCart(true);
    } else {
      let myEvents = JSON.parse(localStorage.getItem("cartList"));
      if (!myEvents.includes(evento.id)) {
        myEvents.push(evento.id);
        localStorage.setItem("cartList", JSON.stringify(myEvents));
        notification.success({
          description: "O evento foi adicionado com sucesso ao seu carrinho.",
        });
        setOpenShoppingCart(true);
      } else {
        notification.info({
          description: "Este evento já se encontra no seu carrinho.",
        });
      }
    }
  };

  return (
    <Wrapper>
      <Container>
        <PageTitle
          title={evento.titulo}
          path={
            props.isBolsa
              ? [
                  {
                    text: "Homepage",
                    link: "/",
                  },
                  {
                    text: "SPAP",
                    link: "/spap",
                  },
                  {
                    text: "Bolsas e Prémios",
                    link: "/spap/bolsas-e-premios",
                  },
                  {
                    text: evento.titulo,
                  },
                ]
              : [
                  {
                    text: "Homepage",
                    link: "/",
                  },
                  {
                    text: "Agenda",
                    link: "/agenda",
                  },
                  {
                    text: evento.titulo,
                  },
                ]
          }
          imageDesktop={evento.url}
          imageMobile={evento.url}
          imageAlt={evento.titulo}
        />

        <Tab>
          <FlexInColumn gap="1.823vw">
            <FlexInColumn gap="0.833vw">
              <span>
                <FontAwesomeIcon
                  icon={faLocationDot}
                  className="icon icon-location"
                />
                {evento.localizacao}
              </span>
            </FlexInColumn>
            <FlexInColumn gap="0.833vw">
              <span>
                <FontAwesomeIcon
                  icon={faCalendarDay}
                  className="icon icon-location"
                />
                {evento.date}
              </span>
              {evento.purchased == true && (
                <>
                  <span className="tag-evento-adquirido">
                    ESTE EVENTO JÁ FOI ADQUIRIDO
                  </span>
                </>
              )}
              {evento.purchased == false && evento.soldOut == true && (
                <>
                  <span className="tag-evento-pago">
                    ESTE EVENTO ENCONTRA-SE ESGOTADO
                  </span>
                </>
              )}
              {evento.is_pago == true && evento.purchased == false && (
                <>
                  <span className="tag-evento-pago">EVENTO PAGO</span>
                </>
              )}
              {!allowedToSubscribe && evento.is_pago == true && (
                <>
                  <span>
                    A compra deste evento não está habilitada para o seu tipo de
                    utilizador.
                  </span>
                </>
              )}
            </FlexInColumn>
          </FlexInColumn>
        </Tab>

        <Row gutter={[24, 24]}>
          <Col xl={19} xs={24}>
            <div
              className="event__content"
              dangerouslySetInnerHTML={{ __html: evento.descricao }}
            ></div>
          </Col>
          <Col xl={5} xs={24}>
            <div className="event-btns">
              <ul>
                {!allowedToSubscribe &&
                  (evento.purchased == 1 || evento.soldOut) &&
                  session_storage != null &&
                  evento.is_pago != 0 && (
                    <li className="hide-event">
                      <Link
                        className="inscrever disabled "
                        onClick={addToShoppingCart}
                      >
                        <CreditCardOutlined /> Inscrever
                      </Link>
                    </li>
                  )}
                {allowedToSubscribe &&
                  !evento.purchased &&
                  !evento.soldOut &&
                  evento.is_pago != 0 && (
                    <li className="hide-event">
                      <Link className="inscrever" onClick={addToShoppingCart}>
                        <CreditCardOutlined /> Inscrever
                      </Link>
                    </li>
                  )}
                <li>
                  <span
                    className="share"
                    onClick={() => handleShare(window.location.href)}
                  >
                    <ShareAltOutlined /> Partilhar
                  </span>
                </li>
                <li>
                  <a href="javascript:history.back()">
                    <RollbackOutlined /> Voltar
                  </a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>

      <SectionShareContent>
        <Container>
          <FlexInRow gap="0">
            <h2 className="title">AJUDE-NOS A DIVULGAR EVENTOS E CONTEÚDOS</h2>
            <p className="text">
              Contribua para a divulgação de eventos científicos
            </p>

            <FlexInColumn gap="2.500vw" mobile="column">
              <SectionMembers>
                <SectionMembersContent>
                  <SectionMembersImage>
                    <picture>
                      <source
                        srcSet={require("../../../Assets/Images/img-anatomia-04@2x.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/img-anatomia-04.png")}
                        alt="Seja um Membro"
                      />
                    </picture>
                  </SectionMembersImage>
                  <SectionMembersTitle>
                    Pretende divulgar um evento?
                  </SectionMembersTitle>
                  <SectionMembersText>
                    Tem conhecimento de um evento que poderá ter interesse para
                    os nossos associados? Partilhe connosco.
                  </SectionMembersText>
                  <SectionMembersCta href="#">
                    Preencher formulário »
                  </SectionMembersCta>
                </SectionMembersContent>
              </SectionMembers>

              <SectionMembers>
                <SectionMembersContent>
                  <SectionMembersImage>
                    <picture>
                      <source
                        srcSet={require("../../../Assets/Images/img-anatomia-04@2x.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/img-anatomia-04.png")}
                        alt="Seja um Membro"
                      />
                    </picture>
                  </SectionMembersImage>
                  <SectionMembersTitle>
                    Quer partilhar um trabalho?
                  </SectionMembersTitle>
                  <SectionMembersText>
                    Pretende divulgar um trabalho que apresentou num evento?
                    Preencha o formulário e submeta o seu trabalho.
                  </SectionMembersText>
                  <SectionMembersCta href="#">
                    Preencher formulário »
                  </SectionMembersCta>
                </SectionMembersContent>
              </SectionMembers>
            </FlexInColumn>
          </FlexInRow>
        </Container>
      </SectionShareContent>
      <EventShoppingCart
        show={openShoppingCart}
        onChange={(e) => setOpenShoppingCart(e)}
      />
    </Wrapper>
  );
}
