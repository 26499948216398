import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Container from "../../../../Components/Container";
import PageTitle from "../../../../Components/PageTitle";
import PageText from "../../../../Components/PageText";
import FlexInColumn from "../../../../Components/FlexInColumn";
import FlexInRow from "../../../../Components/FlexInRow";
import SidebarMenu from "../../../../Components/SidebarMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import ImageGallery from "react-image-gallery";
import GalleryStyle from "react-image-gallery/styles/css/image-gallery.css";
import Popup from "../../../../Components/Popup";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin: 7.813vw 0 5.208vw;

  img {
    width: 100%;
  }

  .link {
    color: ${(props) => props.theme.orange};
    font-weight: bold;
    place-self: flex-start;
    transition: 0.3s ease;
    text-decoration: none;
    text-align: left;
  }

  .link:hover {
    color: ${(props) => props.theme.orangeHover};
  }

  .link .icon {
    color: ${(props) => props.theme.blue};
    margin-right: 5px;
  }

  .link:hover .icon {
    color: ${(props) => props.theme.blueHover};
  }

  @media (max-width: 992px) {
    margin-top: 120px;
  }
`;

const SectionGroups = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2.604vw;

  .grid {
    gap: 1.563vw;
    display: grid;
    grid-template-columns: auto 1fr;
  }

  .title {
    text-align: left;
    color: ${(props) => props.theme.blue};
    opacity: 1;
    font-size: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: inherit;
    margin: 0;
  }

  .bar {
    background-color: #97c1d5;
    height: 1px;
    margin: auto 0;
  }

  .image {
    width: 11.979vw;
  }

  p {
    color: #000000;
    font-size: 0.938vw;
    letter-spacing: 0px;
    line-height: 1.25vw;
    text-align: left;
  }

  .gallery-item {
    cursor: pointer;
    position: relative;
    min-width: 27.917vw;
    max-width: 27.917vw;
    height: 18vw;
    border-radius: 10px;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;

    img {
      height: 100%;
    }

    .cta {
      background-color: #f88436;
      bottom: 1.042vw;
      border-radius: 360px;
      left: 1.042vw;
      opacity: 0.8;
      padding: 0.417vw 0.625vw;
      position: absolute;
      text-transform: uppercase;
    }
  }

  @media (max-width: 992px) {
    p,
    .title {
      font-size: 3.333vw;
      line-height: 4.444vw;
    }

    .gallery-item {
      min-width: 300px;
      max-width: 300px;

      .cta {
        font-size: 12px;
        line-height: 16px;
        padding: 5px 10px;
        bottom: 10px;
        left: 10px;
      }
    }
  }

  @media (min-width: 1920px) {
    p,
    .title {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

export default function GaleriaDeFotos(props) {
  const [statusPopup1, setStatusPopup1] = useState(false);
  const [statusPopup2, setStatusPopup2] = useState(false);
  const [statusPopup3, setStatusPopup3] = useState(false);

  const SidebarMenuLinks = [
    {
      title: "Eventos NISPAP",
      link: "/nispap/novidades/eventos-nispap",
    },
    {
      title: "Galeria de fotos",
      link: "/nispap/novidades/galeria-de-fotos",
    },
  ];

  const images = [
    {
      original: require("../../../../Assets/Images/NISPAP/img-galeeria@2x.png"),
      thumbnail: require("../../../../Assets/Images/NISPAP/img-galeeria.png"),
    },
    {
      original: require("../../../../Assets/Images/NISPAP/img-galeeria@2x.png"),
      thumbnail: require("../../../../Assets/Images/NISPAP/img-galeeria.png"),
    },
    {
      original: require("../../../../Assets/Images/NISPAP/img-galeeria@2x.png"),
      thumbnail: require("../../../../Assets/Images/NISPAP/img-galeeria.png"),
    },
  ];

  const images3 = [
    {
      original: require("../../../../Assets/Images/NISPAP/GaleriaDeFotos/1-Curso-de-Autopsia-Clinica-de-Adulto/01-Autopsia-Clinica.png"),
    },
    {
      original: require("../../../../Assets/Images/NISPAP/GaleriaDeFotos/1-Curso-de-Autopsia-Clinica-de-Adulto/02-Autopsia-Clinica.png"),
    },
    {
      original: require("../../../../Assets/Images/NISPAP/GaleriaDeFotos/1-Curso-de-Autopsia-Clinica-de-Adulto/03-Autopsia-Clinica.png"),
    },
    {
      original: require("../../../../Assets/Images/NISPAP/GaleriaDeFotos/1-Curso-de-Autopsia-Clinica-de-Adulto/04-Autopsia-Clinica.png"),
    },
    {
      original: require("../../../../Assets/Images/NISPAP/GaleriaDeFotos/1-Curso-de-Autopsia-Clinica-de-Adulto/05-Autopsia-Clinica.png"),
    },
    {
      original: require("../../../../Assets/Images/NISPAP/GaleriaDeFotos/1-Curso-de-Autopsia-Clinica-de-Adulto/06-Autopsia-Clinica.png"),
    },
    {
      original: require("../../../../Assets/Images/NISPAP/GaleriaDeFotos/1-Curso-de-Autopsia-Clinica-de-Adulto/07-Autopsia-Clinica.png"),
    },
    {
      original: require("../../../../Assets/Images/NISPAP/GaleriaDeFotos/1-Curso-de-Autopsia-Clinica-de-Adulto/08-Autopsia-Clinica.png"),
    },
    {
      original: require("../../../../Assets/Images/NISPAP/GaleriaDeFotos/1-Curso-de-Autopsia-Clinica-de-Adulto/09-Autopsia-Clinica.png"),
    },
  ];

  return (
    <Wrapper>
      <Container>
        <PageTitle
          title="Eventos NISPAP"
          nispap={true}
          path={[
            {
              text: "NISPAP",
              link: "/nispap",
            },
            {
              text: "Novidades",
              link: "/nispap/novidades",
            },
            {
              text: "Eventos NISPAP",
            },
          ]}
          imageDesktop={require("../../../../Assets/Images/NISPAP/img-galeria-fotos@2x.png")}
          imageMobile={require("../../../../Assets/Images/NISPAP/img-galeria-fotos.png")}
          imageAlt="Eventos NISPAP"
          sidebar={<SidebarMenu navItens={SidebarMenuLinks} nispap={true} />}
        />

        <FlexInRow gap="3.333vw">
          <FlexInColumn gap="12.396vw" mobile="column">
            <FlexInRow gap="0">
              <PageText text="Nesta galeria podes encontrar fotografias dos eventos organizados pelo NI-SPAP, bem como de outros eventos, congressos, cursos, reuniões e encontros onde os internos de anatomia patológica marcaram presença. " />
              <PageText text="Se tiveres fotos que gostasses de acrescentar às nossas memórias de internato, não hesites em enviar!" />
            </FlexInRow>
          </FlexInColumn>

          <SectionGroups>
            <FlexInRow gap="2.083vw">
              <div className="grid">
                <h3 className="title">
                  1º Curso de Autópsia Clínica do adulto
                </h3>
                <div className="bar"></div>
              </div>
              <FlexInColumn gap="1.667vw" mobile="column">
                <div
                  className="gallery-item"
                  onClick={() => setStatusPopup3(true)}
                  style={{
                    backgroundImage:
                      "url(" +
                      require("../../../../Assets/Images/NISPAP/GaleriaDeFotos/1-Curso-de-Autopsia-Clinica-de-Adulto/Course.png") +
                      ")",
                  }}
                >
                  <div className="cta">
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      className="icon"
                    />{" "}
                    Ver galeria
                  </div>
                </div>
                <Popup
                  show={statusPopup3}
                  onChange={() => {
                    setStatusPopup3(false);
                  }}
                >
                  <ImageGallery items={images3} showPlayButton={false} />
                </Popup>
                <FlexInRow gap="0">
                  <p>
                    O <b>1º Curso de Autópsia Clínica do adulto</b> decorreu na{" "}
                    <b>Ordem dos Médicos da Região do Sul</b> no{" "}
                    <b>dia 28 de Abril de 2023</b>.
                  </p>
                  <p>
                    O curso destinou-se a <b>Médicos</b> de Anatomia Patológica
                    e de Medicina Legal, <b>Técnicos</b> de Tanatologia e{" "}
                    <b>Estudantes</b> das faculdades de Medicina.
                  </p>
                  <p>
                    O curso teve a <b>duração de 1 dia</b> e englobou:
                    <br />- Enquadramento histórico da Autópsia;
                    <br />- Aspetos macroscópicos e de dissecção importantes na
                    execução de uma Autópsia Clínica;
                    <br />- Elaboração de um Relatório de Autópsia;
                    <br />- E a patologia dos 5 principais sistemas: Sistema
                    Nervoso Central, Cardíaco, Pulmonar, Hepático e Renal.
                  </p>
                </FlexInRow>
              </FlexInColumn>
            </FlexInRow>
            <FlexInRow gap="2.083vw">
              <div className="grid">
                <h3 className="title">
                  2º Encontro Nacional de Internos da Sociedade Portuguesa de
                  Anatomia Patológica
                </h3>
                <div className="bar"></div>
              </div>
              <FlexInColumn gap="1.667vw" mobile="column">
                <div className="gallery-item">
                  <picture onClick={() => setStatusPopup1(true)}>
                    <source
                      srcSet={require("../../../../Assets/Images/NISPAP/img-galeria-01@2x.png")}
                      media="(min-width: 990px)"
                    />
                    <img
                      src={require("../../../../Assets/Images/NISPAP/img-galeria-01.png")}
                      alt={"Gallery 01"}
                    />
                  </picture>
                  <Popup
                    show={statusPopup1}
                    onChange={() => {
                      setStatusPopup1(false);
                    }}
                  >
                    <ImageGallery items={images} showPlayButton={false} />
                  </Popup>
                  <div className="cta">
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      className="icon"
                    />{" "}
                    Ver galeria
                  </div>
                </div>
                <FlexInRow gap="0">
                  <p>
                    <b>
                      2º Encontro Nacional de Internos da Sociedade Portuguesa
                      de Anatomia Patológica
                    </b>
                    , que decorreu no,{" "}
                    <b>
                      IPO do Porto, no passado dias 16 e 17 de dezembro de 2022
                    </b>
                  </p>
                  <p>Obrigada pela vossa participação!</p>
                </FlexInRow>
              </FlexInColumn>
            </FlexInRow>
            <FlexInRow gap="2.083vw">
              <div className="grid">
                <h3 className="title">1º Curso de Patologia Fetoplacentar</h3>
                <div className="bar"></div>
              </div>
              <FlexInColumn gap="1.667vw" mobile="column">
                <div className="gallery-item">
                  <picture onClick={() => setStatusPopup1(true)}>
                    <source
                      srcSet={require("../../../../Assets/Images/NISPAP/img-galeria-02@2x.png")}
                      media="(min-width: 990px)"
                    />
                    <img
                      src={require("../../../../Assets/Images/NISPAP/img-galeria-02.png")}
                      alt={"Gallery 02"}
                    />
                  </picture>
                  <Popup
                    show={statusPopup1}
                    onChange={() => {
                      setStatusPopup1(false);
                    }}
                  >
                    <ImageGallery items={images} showPlayButton={false} />
                  </Popup>
                  <div className="cta">
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      className="icon"
                    />{" "}
                    Ver galeria
                  </div>
                </div>
                <FlexInRow gap="0">
                  <p>
                    <b>Primeiro Curso de Patologia Fetoplacentar</b>, que
                    decorreu no{" "}
                    <b>
                      Auditório Principal do Centro Hospitalar e Univesitário de
                      Coimbra
                    </b>
                    , no passado dia <b>3 de Junho de 2022</b>.
                  </p>
                  <p>Obrigada pela vossa participação!</p>
                </FlexInRow>
              </FlexInColumn>
            </FlexInRow>
            <FlexInRow gap="2.083vw">
              <div className="grid">
                <h3 className="title">Curso Nefropatologia</h3>
                <div className="bar"></div>
              </div>
              <FlexInColumn gap="1.667vw" mobile="column">
                <div className="gallery-item">
                  <picture onClick={() => setStatusPopup2(true)}>
                    <source
                      srcSet={require("../../../../Assets/Images/NISPAP/img-galeria-03@2x.png")}
                      media="(min-width: 990px)"
                    />
                    <img
                      src={require("../../../../Assets/Images/NISPAP/img-galeria-03.png")}
                      alt={"Gallery 03"}
                    />
                  </picture>
                  <Popup
                    show={statusPopup2}
                    onChange={() => {
                      setStatusPopup2(false);
                    }}
                  >
                    <ImageGallery items={images} showPlayButton={false} />
                  </Popup>
                  <div className="cta">
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      className="icon"
                    />{" "}
                    Ver galeria
                  </div>
                </div>
                <FlexInRow gap="0">
                  <p>
                    <b>Curso de Morfologia Renal</b>, que decorreu no{" "}
                    <b>Auditório Principal do Hospital Fernando Fonseca</b>, no
                    passado dia <b>8 de Abril de 2022</b>.
                  </p>
                  <p>
                    O curso foi <b>gratuito</b>.
                  </p>
                  <p>Obrigada pela vossa participação!</p>
                </FlexInRow>
              </FlexInColumn>
            </FlexInRow>
          </SectionGroups>
        </FlexInRow>
      </Container>
    </Wrapper>
  );
}
