import styled from "styled-components";
import { Link } from "react-router-dom";
import Container from "../Container";
import FlexInRow from "../FlexInRow";
import FlexInColumn from "../FlexInColumn";
import axios from "axios";
import {
  Button,
  Modal,
  Result,
  Select,
  DatePicker,
  Row,
  Col,
  Upload,
} from "antd";
import { useState } from "react";
import authService from "../../Services/auth.service";
const { RangePicker } = DatePicker;
import { UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const Wrapper = styled.section`
  background-color: #d6d7e2;

  .padding-section {
    padding: 4.167vw 0;
  }

  .ant-upload-list-item-name {
    text-align: left;
  }

  .ant-picker-range {
    width: 100%;
  }

  .ant-modal-content .ant-modal-footer {
    display: none !important;
  }

  .button-voltar {
    background: #f88436;
    color: #fff;
    border-radius: 10px;
    padding: 10px 30px;
    line-height: 24px;
    display: block;
    margin-left: auto !important;
    margin-right: auto !important;
    border: none;
  }

  .ant-btn {
    height: auto;
  }

  .title {
    text-align: center;
    font-size: 2.083vw;
    font-weight: bold;
    line-height: 2.5vw;
    letter-spacing: 0px;
    color: ${(props) => props.theme.blue};
    text-transform: uppercase;
    opacity: 1;
    margin: 0;
  }

  @media (max-width: 992px) {
    padding: 9.444vw 0;

    .title {
      font-size: 5.556vw;
      line-height: 6.667vw;
      margin-bottom: 4.944vw;
    }
  }

  @media (min-width: 1920px) {
    .title {
      font-size: 40px;
      line-height: 48px;
    }
  }
`;

const Form = styled.form`
  opacity: 1;
  padding: 1.823vw;

  .upload-btn {
    height: 45px;
    width: 100%;
    text-align: left;
    font-size: 12px;
    color: #757575 !important;
  }

  .ant-select-selection-item {
    line-height: 45px !important;
    font-size: 12px;
    text-align: left;
  }

  .ant-select-selection-placeholder,
  .ant-picker .ant-picker-input > input:placeholder-shown,
  .ant-picker .ant-picker-input > input {
    color: #757575 !important;
    font-size: 12px;
    text-align: left;
  }

  .ant-upload-wrapper .ant-upload-select {
    width: 100%;
  }

  .ant-select-selector,
  .ant-select {
    height: 45px;
    border: none !important;
  }

  input[type="text"],
  input[type="email"],
  input[type="select"],
  textarea {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 0.521vw;
    opacity: 1;
    height: 45px;
    padding: 10px 10px;
    font-size: 12px;
    width: 100%;
    outline: 0;
    transition: 0.3s ease;
  }

  input[type="text"]:focus,
  input[type="email"]:focus,
  select:focus,
  textarea:focus {
    box-shadow: 0 8px 24px #00000025;
  }

  textarea {
    height: 13.75vw;
  }

  input[type="checkbox"] {
    background-color: #ffffff;
    border: none;
    cursor: pointer;
    opacity: 1;
    height: 1.302vw;
    width: 1.302vw;
  }

  label {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    display: inline-flex;
    gap: 2.5px;
    transition: 0.3s ease;
    align-items: center;

    a {
      ${(props) =>
        props.nispap
          ? `
        color: ${props.theme.blue};
      `
          : `
        color: ${props.theme.orange};
      `}
      text-decoration: underline;
      transition: 0.3s ease-out;
    }

    a:hover {
      ${(props) =>
        props.nispap
          ? `
        color: ${props.theme.blueHover};
      `
          : `
        color: ${props.theme.orangeHover};
      `}
      text-decoration: none;
    }
  }

  input[type="submit"] {
    background-color: #f88436;
    border: none;
    border-radius: 0.521vw;
    opacity: 1;
    text-align: center;
    font-size: 1.146vw;
    font-weight: bold;
    line-height: 1.51vw;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    padding: 0.729vw 1.563vw;
    place-self: center;
    margin-bottom: -2.968vw;
    transition: 0.3s ease;
  }

  .select-form .ant-select-selector {
    min-height: -webkit-fill-available;
    border: none !important;
  }

  .select-form
    .ant-select-single
    .ant-select-selector
    .ant-select-selection-item {
    line-height: max-content !important;
  }

  input[type="submit"]:hover {
    background-color: #e87426;
    color: #ececec;
  }

  @media (max-width: 992px) {
    input[type="text"],
    input[type="email"],
    textarea {
      border-radius: 2.778vw;
      font-size: 3.889vw;
      line-height: 5.278vw;
      padding: 3.333vw 4.167vw;
    }

    textarea {
      height: 43.611vw;
    }

    input[type="checkbox"] {
      height: 6.944vw;
      width: 6.944vw;
    }

    label,
    label a {
      font-size: 2.778vw;
      line-height: 3.611vw;
      display: initial;
    }

    input[type="submit"] {
      border-radius: 2.778vw;
      font-size: 3.611vw;
      line-height: 5vw;
      padding: 2.778vw 4.167vw;
      margin-top: 5.556vw;
    }
  }

  @media (min-width: 1920px) {
    label,
    label * {
      font-size: 18px;
      line-height: 24px;
    }

    input[type="text"],
    input[type="email"],
    textarea {
      font-size: 18px;
      line-height: 24px;
      padding: 25px;
    }

    input[type="checkbox"] {
      height: 25px;
      width: 25px;
    }

    input[type="submit"] {
      border-radius: 10px;
      font-size: 22px;
      line-height: 29px;
      padding: 15px 30px;
    }
  }
`;

export default function ContactForm(props) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [subject, setSubject] = useState("outro");
  const [fileList, setFileList] = useState([]);
  const [dataEvent, setDataEvent] = useState([]);
  const [email, setEmail] = useState("");
  const [nome, setNome] = useState("");
  const [areaEvent, setAreaEvent] = useState([]);
  const [localizacaoEvent, setLocalizacaoEvent] = useState("");
  const [tipoEvent, setTipoEvent] = useState([]);
  const [descricaoEvent, setDescricaoEvent] = useState("");
  const [tituloEvent, setTituloEvent] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [cidade, setCidade] = useState("");
  const [telefone, setTelefone] = useState("");
  const [numeroSocio, setNumeroSocio] = useState("");

  const onChangeRangeEvent = (value, dateString) => {
    console.log(value);
    console.log(dateString);
    setDataEvent([dateString[0], dateString[1]]);
  };

  const handleContact = (e) => {
    e.preventDefault();

    var bodyFormData = new FormData();

    bodyFormData.append("email", email);
    bodyFormData.append("nome", nome);
    bodyFormData.append(
      "is_nispap",
      window.location.href.indexOf("nispap") > -1
    );
    bodyFormData.append("subject", subject);
    if (props.nispap) {
      if (subject == "evento") {
        bodyFormData.append("area", areaEvent);
        bodyFormData.append("localizacao", localizacaoEvent);
        if (dataEvent.length) {
          bodyFormData.append("data_inicio", dataEvent[0]);
          bodyFormData.append("data_fecho", dataEvent[1]);
        }
        bodyFormData.append("titulo", tituloEvent);
        bodyFormData.append("tipo", tipoEvent);
        bodyFormData.append("descricao", descricaoEvent);
        if (fileList.length) {
          bodyFormData.append("file", fileList[0]);
        }
      } else {
        bodyFormData.append("mensagem", mensagem);
      }
    } else {
      bodyFormData.append("numero_socio", numeroSocio);
      bodyFormData.append("cidade", cidade);
      bodyFormData.append("telefone", telefone);

      if (subject == "evento") {
        bodyFormData.append("area", areaEvent);
        if (dataEvent.length) {
          bodyFormData.append("data_inicio", dataEvent[0]);
          bodyFormData.append("data_fecho", dataEvent[1]);
        }
        bodyFormData.append("localizacao", localizacaoEvent);
        bodyFormData.append("titulo", tituloEvent);
        bodyFormData.append("tipo", tipoEvent);
        bodyFormData.append("descricao", descricaoEvent);
        if (fileList.length) {
          bodyFormData.append("file", fileList[0]);
        }
      } else {
        bodyFormData.append("mensagem", mensagem);
      }
    }

    axios
      .post("https://api.spap.pt/api/send-contact", bodyFormData)
      .then((res) => {
        console.log(res);
        let secondsToGo = 5;
        if (res.status == 200) {
          document.getElementById("contact-form").reset();
          setModalOpen(true);
          setTimeout(() => {
            setModalOpen(false);
          }, 3000);
        }
      })
      .catch((error) => {
        /* if( error.response ){
      //   notification.error({description: 'Erro ao atualizar o perfil'});
       }*/
      });
  };

  const beforeUpload = (file) => {
    const image =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/svg";
    if (!image) {
      notification.error({
        description: "Apenas pode fazer upload de imagens.",
      });
      return image || Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      notification.error({
        description: "Faça o upload de imagens menos pesadas.",
      });
      return isLt2M || Upload.LIST_IGNORE;
    }

    setFileList(file);
    return image && isLt2M;
  };

  const prop = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  return (
    <Wrapper id={props.id} className={props.className}>
      <Container className="padding-section">
        <FlexInRow gap="1.167vw" placeself="center">
          <h2 className="title">Entre em contacto através deste formulário</h2>
          <Form
            method="post"
            action="#"
            id="contact-form"
            nispap={props.nispap}
            onSubmit={(e) => handleContact(e)}
          >
            <FlexInRow gap="8px">
              <Row gutter={[8, 8]}>
                <Col span={8}>
                  <Select
                    value={subject}
                    onChange={(e) => setSubject(e)}
                    className="select-form"
                    placeholder="Assunto*"
                    required
                  >
                    <option value="evento">Sugerir uma publicação</option>
                    <option value="outro">Outro assunto</option>
                  </Select>
                </Col>
                <Col span={8}>
                  <input
                    type="text"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                    name="name"
                    placeholder="Nome*"
                    required
                  />
                </Col>
                <Col span={8}>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="E-mail*"
                    required
                  />
                </Col>
              </Row>
              {!props.nispap && (
                <FlexInColumn gap="8px" mobile="column">
                  <input
                    type="text"
                    name="phone"
                    value={telefone}
                    onChange={(e) => setTelefone(e.target.value)}
                    placeholder="Contacto telefónico"
                  />
                  <input
                    type="text"
                    name="city"
                    value={cidade}
                    onChange={(e) => setCidade(e.target.value)}
                    placeholder="Cidade"
                  />
                  <input
                    type="text"
                    name="spap_number"
                    value={numeroSocio}
                    onChange={(e) => setNumeroSocio(e.target.value)}
                    placeholder="Número de sócio SPAP"
                  />
                </FlexInColumn>
              )}
              {subject == "evento" ? (
                <>
                  <Row gutter={[8, 8]}>
                    <Col span={8}>
                      <Select
                        key={"tipo-evento"}
                        name="tipo"
                        placeholder="Tipo de publicação*"
                        value={tipoEvent}
                        onChange={(e) => setTipoEvent(e)}
                        className="select-form"
                        options={[
                          { value: "Eventos", label: "Eventos" },
                          { value: "Notícias", label: "Notícias" },
                          {
                            value: "Bolsas e Prémios",
                            label: "Bolsas e Prémios",
                          },
                          { value: "Caso do mês", label: "Caso do mês" },
                        ]}
                        required
                      ></Select>
                    </Col>
                    <Col span={8}>
                      <input
                        type="text"
                        name="name"
                        value={tituloEvent}
                        onChange={(e) => setTituloEvent(e.target.value)}
                        placeholder="Nome da publicação*"
                        required
                      />
                    </Col>
                    <Col span={8}>
                      <Select
                        key={"area-evento"}
                        placeholder="Área de Diferenciação (Opcional)"
                        name="area"
                        value={areaEvent}
                        style={{ border: "none" }}
                        onChange={(e) => setAreaEvent(e)}
                        options={[
                          {
                            value: "Patologia geral",
                            label: "Patologia geral",
                          },
                          { value: "Citologia", label: "Citologia" },
                          {
                            value: "Patologia cutânea",
                            label: "Patologia cutânea",
                          },
                          {
                            value: "Patologia da cabeça e pescoço",
                            label: "Patologia da cabeça e pescoço",
                          },
                          {
                            value: "Patologia das partes moles",
                            label: "Patologia das partes moles",
                          },
                          {
                            value: "Patologia do sistema nervosa",
                            label: "Patologia do sistema nervoso",
                          },
                          {
                            value: "Patologia digital",
                            label: "Patologia digital",
                          },
                          {
                            value: "Patologia endócrina",
                            label: "Patologia endócrina",
                          },
                          {
                            value: "Patologia fetoplacentar",
                            label: "Patologia fetoplacentar",
                          },
                          {
                            value: "Patologia gastrointestinal",
                            label: "Patologia gastrointestinal",
                          },
                          {
                            value: "Patologia ginecológica",
                            label: "Patologia ginecológica",
                          },
                          {
                            value: "Patologia hematolinfoide",
                            label: "Patologia hematolinfoide",
                          },
                          {
                            value: "Patologia hepatobiliar",
                            label: "Patologia hepatobiliar",
                          },
                          {
                            value: "Patologia mamária",
                            label: "Patologia mamária",
                          },
                          {
                            value: "Patologia molecular",
                            label: "Patologia molecular",
                          },
                          {
                            value: "Patologia osteoarticular",
                            label: "Patologia osteoarticular",
                          },
                          {
                            value: "Patologia pancreática",
                            label: "Patologia pancreática",
                          },
                          {
                            value: "Patologia pulmonar",
                            label: "Patologia pulmonar",
                          },
                          {
                            value: "Patologia urogenital",
                            label: "Patologia urogenital",
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[8, 8]}>
                    <Col span={8}>
                      <input
                        type="text"
                        name="localizacao"
                        value={localizacaoEvent}
                        onChange={(e) => setLocalizacaoEvent(e.target.value)}
                        placeholder="Localização do publicação (Opcional)"
                        required
                      />
                    </Col>
                    <Col span={8}>
                      <Upload {...prop} fileList={fileList}>
                        <Button
                          className="upload-btn"
                          icon={<UploadOutlined />}
                        >
                          Upload da imagem
                        </Button>
                      </Upload>
                    </Col>
                    <Col span={8}>
                      <RangePicker
                        name="rangepicker"
                        placeholder={["Dia de inicio", "Dia de fecho"]}
                        key={"range-data-event"}
                        format={"DD/MM/YYYY"}
                        showTime={false}
                        value={
                          dataEvent.length > 0
                            ? [
                                dayjs(dataEvent[0], "DD/MM/YYYY"),
                                dayjs(dataEvent[1], "DD/MM/YYYY"),
                              ]
                            : []
                        }
                        onChange={onChangeRangeEvent}
                        style={{ height: "45px" }}
                        placement="bottomRight"
                      />
                    </Col>
                  </Row>
                  <textarea
                    name="descricao"
                    placeholder="Descrição*"
                    value={descricaoEvent}
                    onChange={(e) => setDescricaoEvent(e.target.value)}
                    required
                  />
                </>
              ) : (
                <>
                  <textarea
                    name="message"
                    placeholder="Mensagem*"
                    value={mensagem}
                    onChange={(e) => setMensagem(e.target.value)}
                    required
                  />
                </>
              )}
              <FlexInColumn gap="5px">
                <input type="checkbox" name="confirm" required />
                <FlexInRow gap="0">
                  <label>
                    Ao submeter este formulário de contacto declaro conhecer a{" "}
                    <Link to="/politica-de-privacidade" target="_blank">
                      política de privacidade
                    </Link>{" "}
                    deste website bem como os{" "}
                    <Link to="/termos-e-condicoes" target="_blank">
                      termos e condições
                    </Link>{" "}
                    do mesmo.
                  </label>
                  <label>* Campos de preenchimento obrigatório.</label>
                </FlexInRow>
              </FlexInColumn>
              <input type="submit" name="submit" value="Enviar mensagem" />
            </FlexInRow>
          </Form>
        </FlexInRow>
      </Container>
      {/* {showResult &&  */}

      <Modal
        centered
        open={isModalOpen}
        footer={null}
        onCancel={() => setModalOpen(false)}
      >
        <Result
          style={{ background: "#fff" }}
          status="success"
          title="Mensagem enviada com sucesso!"
          subTitle="Obrigado pela sua mensagem. Entraremos em contacto consigo assim que possível!"
        />
      </Modal>
      {/* } */}
    </Wrapper>
  );
}
