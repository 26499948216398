import { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Card from "../Card";
import Title from "../Title";
import { ReactSortable } from "react-sortablejs";
import urlSlug from "url-slug";

import {
  Button,
  Divider,
  Space,
  Switch,
  Upload,
  Input,
  Radio,
  Select,
  Drawer,
  Col,
  Modal,
  Row,
  DatePicker,
  notification,
} from "antd";
import CardList from "../CardList";
import { ReactComponent as IconOk } from "../../../Assets/Images/Backoffice/Ok.svg";
import { ReactComponent as IconCalendar } from "../../../Assets/Images/Backoffice/Calendar.svg";
import { ReactComponent as IconClose } from "../../../Assets/Images/Backoffice/Close.svg";
import { ReactComponent as IconEdit } from "../../../Assets/Images/Backoffice/Edit.svg";
import { ReactComponent as IconHours } from "../../../Assets/Images/Backoffice/Hours.svg";
import { ReactComponent as IconUpload } from "../../../Assets/Images/Backoffice/Upload.svg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import dayjs from "dayjs";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
import { EyeOutlined } from "@ant-design/icons";
import axios, { all } from "axios";

const { RangePicker } = DatePicker;
const { Dragger } = Upload;
const { TextArea } = Input;

// import { useDrag } from 'react-dnd'
// import { ItemTypes } from './Constants'

const WrapperCardList = styled.section`
  // display: flex;
  width: 100% !important;
  flex-direction: row;

  p {
    color: #c2c4d4;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 20px;
    margin-bottom: 0% !important;
    text-align: left;
  }

  .text {
    color: #809fb8;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0px;
  }

  .text-small {
    color: #809fb8;
    text-align: left;
    font-size: 12px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0px;
  }

  .css-dev-only-do-not-override-j0nf2s .text {
    margin-bottom: 0 !important;
  }

  .icon-close {
    cursor: pointer;
  }

  .action-edit {
    cursor: pointer;
    color: #494d7d;
  }

  .flex-wrap {
    flex-wrap: wrap !important;
  }
`;

const WrapperCardApresentacao = styled.section`
  display: flex;
  flex-direction: column;

  .grid {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 20px;
  }

  .icon {
    width: 25px;
  }

  .size-eye svg {
    width: 25px;
  }

  .ant-upload-btn {
  min-height: 190px;
}

  .ant-radio-button-wrapper {
    height: 45px;
    border: 1px solid rgb(128, 159, 184);
  }

  .ant-radio-button-wrapper span {
    line-height: 45px; 
    font-size: 14px;s
  }
  .text {
    margin-bottom: 0% !important;
  }

  h4 {
    text-align: center;
    font: normal normal bold 20px/20px Roboto;
    letter-spacing: 0px;
    color: #050505;
  }
  

  .text {
    color: #809FB8;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0px;
  }

  .center {
    text-align: center;
  }

  .ant-upload-wrapper .ant-upload-drag {
    max-height: -webkit-fill-available;
    height: 91%;
    display: flex;
    justify-content: center;
  }

  .ant-upload-wrapper .ant-upload-drag .ant-upload-btn  {
    height: -webkit-fill-available;
  }

  .div-sortable {
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
  }

  .div-sortable .ant-col {
    padding: 5px;
  }

  .div-apresentacao{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .cta {
    display: block !important;
    font-size: 15px;
    font-weight: bold;
    height: 45px !important;
    line-height: 18px;

    i { margin: 0 3px; }

    a { text-decoration: none; }
  }

  


  .cta.publish {
    margin-right: 0;

    svg path {
      fill: #FFF;
    }
  }
`;

const ContentFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    display: block !important;
    font-size: 15px;
    font-weight: bold;
    height: 45px;
    line-height: 18px;

    i {
      margin: 0 3px;
    }
  }

  a {
    text-decoration: none;
  }
`;

var styles = {
  h6_label: {
    color: "#809FB8",
    fontFamily: "Roboto",
    fontSize: "15px",
    marginTop: "1%",
  },
  input_class_disabled: {
    marginBottom: "2%",
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
    opacity: 0.6,
    pointerApresentacaos: "none",
  },
  input_class: {
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
    width: "100%",
  },
  select_class: {
    borderRadius: "6px",
    height: "45px",
    color: "#0C1151",
    width: "100%",
  },
  textarea_class: {
    borderRadius: "6px",
    border: "1px solid #809FB8",
    color: "#0C1151",
  },
  switch_label: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    height: "45px",
    borderRadius: "8px",
    padding: "0px 16px",
    color: "#809FB8",
    border: "1px solid #809FB8",
    textAlign: "center",
  },
  upload: {
    width: "100%",
  },
};

const CardHeader = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto auto;
`;

export default function CardApresentacoes(props) {
  const session_storage = JSON.parse(localStorage.getItem("session_data"));

  const [fileApresentacaoList, setfileApresentacaoList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [tipoApresentacao, setTipoApresentacao] = useState("");
  const [descricaoApresentacao, setDescricaoApresentacao] = useState("");
  const [tituloApresentacao, setTituloApresentacao] = useState("");
  const [showApresentacaoDrawer, setShowApresentacaoDrawer] = useState(false);
  // editar Apresentacao
  const [fileApresentacaoListEdit, setfileApresentacaoListEdit] = useState([]);
  const [apresentacaoIdEdit, setapresentacaoIdEdit] = useState("");
  const [descricaoApresentacaoEdit, setDescricaoApresentacaoEdit] =
    useState("");
  const [tituloApresentacaoEdit, setTituloApresentacaoEdit] = useState("");
  const [imagemDestaqueEdit, setImagemDestaqueEdit] = useState("");
  const [idToRemove, setIdToRemove] = useState(false);
  const [titleToRemove, setTitleToRemove] = useState(false);
  const [removeApresentacaoModalOpen, setRemoveApresentacaoModalOpen] =
    useState(false);

  const [allItems, setAllItems] = useState([]);

  const [apresentacoes, setApresentacoes] = useState([]);

  const handleApresentacoes = (allApresentacoes) => {
    let apresentacoesToShow = [];
    allApresentacoes?.map((apresentacao) => {
      if (apresentacao != null) {
        let criado_em = new Date(apresentacao.created_at);
        apresentacao.created_at = criado_em.toLocaleDateString();
        apresentacao.slug = urlSlug(apresentacao.titulo);
        apresentacao.slug += "-" + apresentacao.id;
        apresentacoesToShow.push(apresentacao);
      }
    });

    setApresentacoes(apresentacoesToShow.sort((a, b) => a.ordem - b.ordem));
  };

  useEffect(() => {
    fetch(`https://api.spap.pt/api/all-apresentacoes`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar apresentacoes");
        }
        return response.json();
      })
      .then((data) => {
        handleApresentacoes(data.apresentacoes);
      })
      .catch((error) => console.error(error));
  }, []);

  const [currentItemsApresentacao, setCurrentItemsApresentacao] = useState(
    allItems.slice(0, 2)
  );

  const [itemOffsetApresentacao, setItemOffsetApresentacao] = useState(0);
  const itemsPerPageApresentacao = 2;

  const handlePageApresentacao = (page) => {
    const newOffset = ((page - 1) * itemsPerPageApresentacao) % allItems.length;
    setItemOffsetApresentacao(newOffset);
  };

  useEffect(() => {
    const endOffset = itemOffsetApresentacao + itemsPerPageApresentacao;
    setCurrentItemsApresentacao(
      allItems.slice(itemOffsetApresentacao, endOffset)
    );
  }, [itemOffsetApresentacao, allItems]);

  notification.config({
    placement: "bottomRight",
    bottom: 10,
    duration: 5,
    rtl: false,
  });

  const clickToApresentacaoDrawer = (item) => {
    setapresentacaoIdEdit(item.id);
    setDescricaoApresentacaoEdit(item.descricao);
    setTituloApresentacaoEdit(item.titulo);
    setImagemDestaqueEdit(item.imagem_destaque);
    setShowApresentacaoDrawer(true);
  };

  const propApresentacao = {
    onRemove: (file) => {
      const index = fileApresentacaoList.indexOf(file);
      const newfileApresentacaoList = fileApresentacaoList.slice();
      newfileApresentacaoList.splice(index, 1);
      setfileApresentacaoList(newfileApresentacaoList);
    },
    beforeUpload: (file) => {
      const isImage =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml";
      if (!isImage) {
        notification.error({
          description: "Apenas é possível fazer upload de imagens.",
        });

        return isImage || Upload.LIST_IGNORE;
      }
      setfileApresentacaoList([...fileApresentacaoList, file]);
      return false;
    },
    fileApresentacaoList,
  };

  const t = 1;
  const data = [];

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const handleApresentacao = (e) => {
    if (tituloApresentacao.length == 0 || descricaoApresentacao.length == 0) {
      notification.error({
        description:
          "Para criar uma nova apresentacão, preencha todos os campos.",
      });
      return;
    }

    const formData = new FormData();

    formData.append("titulo", tituloApresentacao);
    formData.append("file", fileApresentacaoList[0]);
    formData.append("descricao", descricaoApresentacao);

    // You can use any AJAX library you like
    fetch("https://api.spap.pt/api/admin/store-apresentacao", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            if (data.message == "already_exists") {
              notification.error({
                description:
                  "Já existe um Apresentacao com o mesmo nome neste clube!",
              });
            } else {
              session_storage.all_data.apresentacoes = data.apresentacoes;
              localStorage.setItem(
                "session_data",
                JSON.stringify(session_storage)
              );
              const endOffset =
                itemOffsetApresentacao + itemsPerPageApresentacao;
              setCurrentItemsApresentacao(
                allItems.slice(itemOffsetApresentacao, endOffset)
              );
              setTituloApresentacao("");
              setDescricaoApresentacao("");
              setfileApresentacaoList([]);
              handleApresentacoes(data.apresentacoes);
              notification.success({
                description: "A apresentação foi criada com sucesso!",
              });
            }
          })
          .catch((err) => {});
      })
      .then(() => {})
      .catch(() => {})
      .finally(() => {});
  };

  const propApresentacaoEdit = {
    onRemove: (file) => {
      fileApresentacaoListEdit([]);
    },
    beforeUpload: (file) => {
      const isImage =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml";
      if (!isImage) {
        notification.error({
          description: "Apenas é possível fazer upload de imagens.",
        });
        setfileApresentacaoListEdit((current) => []);
        return Upload.LIST_IGNORE;
      }
      setfileApresentacaoListEdit([...fileApresentacaoListEdit, file]);
    },
    fileApresentacaoListEdit,
  };

  const handleEditApresentacao = () => {
    if (
      descricaoApresentacaoEdit.length == 0 ||
      tituloApresentacaoEdit.length == 0
    ) {
      notification.error({
        description:
          "Para editar o Apresentacao, preencha os campos respetivos.",
      });
      return;
    }
    const formData = new FormData();

    if (fileApresentacaoListEdit.length) {
      formData.append("file", fileApresentacaoListEdit[0]);
    }

    formData.append("titulo", tituloApresentacaoEdit);
    formData.append("apresentacaoId", apresentacaoIdEdit);
    formData.append("descricao", descricaoApresentacaoEdit);

    setUploading(true);

    // You can use any AJAX library you
    fetch("https://api.spap.pt/api/admin/update-apresentacao", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            data.apresentacoes.map((apresentacao) => {
              setfileApresentacaoListEdit([]);
              if (apresentacao.id == apresentacaoIdEdit) {
                const newImagemDestaque = apresentacao.imagem_destaque;
                setImagemDestaqueEdit(newImagemDestaque);
              }
            });
            setShowApresentacaoDrawer(false);
            handleApresentacoes(data.apresentacoes);
            const endOffset = itemOffsetApresentacao + itemsPerPageApresentacao;
            setCurrentItemsApresentacao(
              allItems.slice(itemOffsetApresentacao, endOffset)
            );
          })
          .catch((err) => {});
      })
      .then(() => {
        setfileApresentacaoListEdit([]);
        notification.success({
          description: "A apresentação foi atualizado com sucesso!",
        });
      })
      .catch(() => {
        notification.error({
          description:
            "Já existe um Apresentacao com o mesmo nome neste clube!",
        });
      })
      .finally(() => {});
  };

  const handleRemoveApresentacao = () => {
    axios
      .post(
        "https://api.spap.pt/api/admin/remove-apresentacao",
        { idToRemove },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        setIdToRemove("");
        setRemoveApresentacaoModalOpen(false);
        if (res.status == 200) {
          session_storage.all_data.apresentacoes = res.data.apresentacoes;
          localStorage.setItem("session_data", JSON.stringify(session_storage));
          handleApresentacoes(res.data.apresentacoes);
          notification.success({
            description: "A apresentação foi removida com sucesso!",
          });
        }
      })
      .catch((error) => {
        // notification.error({description: 'Erro ao remover a imagem da galeria! Tente novamente.'});
      });
  };

  const openRemoveApresentacaoModal = (id, titulo) => {
    setIdToRemove(id);
    setTitleToRemove(titulo);
    setRemoveApresentacaoModalOpen(true);
  };

  const handleOrdem = (array, tipo) => {
    let ordemapresentacoes = [];
    array.map(function (item, index) {
      ordemapresentacoes.push({ ordem: index, id: item.id });
    });

    setApresentacoes(array);

    var bodyFormData = new FormData();

    bodyFormData.append("array", JSON.stringify(ordemapresentacoes));

    fetch("https://api.spap.pt/api/admin/set-ordem-apresentacao", {
      method: "POST",
      body: bodyFormData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    }).then((response) => {
      response.json().then((data) => {
        // handleApresentacoes(data.apresentacoes);
      });
    });
  };

  return (
    <WrapperCardApresentacao>
      <Card>
        <CardHeader>
          <Space direction="vertical" size={60}>
            <Title>Crie aqui as apresentações</Title>
            {/* Verificar props.idClube para inserir nome ou nao */}
          </Space>
        </CardHeader>
        <p className="text">
          Poderá ordenar as apresentações a partir do <i>drag and drop</i>
        </p>
        <Divider />
        <Row gutter={[12, 18]} align="bottom">
          <Col span={24}>
            <label className="text">Titulo da apresentação</label>
            <Input
              key={"titulo-apresentacao"}
              placeholder="Título"
              name="titulo"
              style={styles.input_class}
              value={tituloApresentacao}
              onChange={(e) => setTituloApresentacao(e.target.value)}
            />
          </Col>
        </Row>
        <Row gutter={[12, 12]} style={{ marginTop: "10px" }} align={"top"}>
          <Col span={6}>
            <p className="text" style={{ marginBottom: "0px" }} align="center">
              Tamanho máximo de upload (1 MB)
            </p>
            <Dragger
              {...propApresentacao}
              style={{ height: "100%" }}
              fileList={[...fileApresentacaoList]}
            >
              <p className="ant-upload-drag-icon">
                <IconUpload />
              </p>
              <p className="ant-upload-text text">
                Upload da imagem da apresentação
              </p>
              <p className="ant-upload-hint">Faça Drag and drop aqui</p>
            </Dragger>
          </Col>
          <Col span={18}>
            <label className="text" style={{ marginBottom: "0px" }}>
              Descrição da apresentação
            </label>

            <ReactQuill
              key={"descricao-apresentacao"}
              placeholder="Descrição"
              modules={modules}
              formats={formats}
              theme="snow"
              name="descricao"
              onChange={(e) => setDescricaoApresentacao(e)}
              value={descricaoApresentacao}
            />
          </Col>
        </Row>
        <Divider style={{ margin: "8px 0" }} />
        <Button
          onClick={handleApresentacao}
          type="primary"
          className="cta publish"
          align="end"
        >
          Publicar <IconOk className="icon icon-ok" />
        </Button>
      </Card>
      <WrapperCardList>
        <Divider>Apresentações</Divider>
        {apresentacoes?.length == 0 && (
          <p style={{ textAlign: "center" }}>
            Não existem apresentações disponíveis.
          </p>
        )}
        {apresentacoes?.length > 0 && (
          <>
            <ReactSortable
              list={apresentacoes}
              setList={(e) => handleOrdem(e, "apresentacoes")}
              className="div-sortable"
            >
              {apresentacoes.map((item, index) => {
                let textPreview = item.descricao
                  .replace(/<(h2|h3|img|b|strong)[^>]*>/g, "<p>")
                  .slice(0, 100);
                if (item.descricao.length > 120) textPreview += " (...)";
                return (
                  <Col span={12}>
                    <Card
                      key={"apresentacoes-" + item.id}
                      className="div-apresentacao"
                      style={{ height: "100%" }}
                    >
                      <div>
                        <CardHeader>
                          <Title>{item.titulo}</Title>
                          <IconClose
                            className="icon icon-close"
                            onClick={() =>
                              openRemoveApresentacaoModal(item.id, item.titulo)
                            }
                          />
                        </CardHeader>
                        <div
                          style={{ fontSize: "12px", lineHeight: "1.4" }}
                          dangerouslySetInnerHTML={{ __html: textPreview }}
                        ></div>
                      </div>
                      <Space direction="vertical" style={{ width: "100%" }}>
                        <Space
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            alignContent: "center",
                          }}
                        >
                          <Space size={5}>
                            <Space size={0}>
                              <IconCalendar className="icon icon-calendar" />
                              <span className="text-small">
                                Criado em {item.created_at}
                              </span>
                            </Space>
                          </Space>
                        </Space>
                      </Space>
                      <Space direction="vertical" style={{ width: "100%" }}>
                        <Space
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            alignContent: "center",
                          }}
                        >
                          <Space size={5}>
                            <Space
                              size={0}
                              className="action-edit"
                              key={"Apresentacao-" + index}
                              onClick={() => clickToApresentacaoDrawer(item)}
                            >
                              <IconEdit className="icon icon-edit" />
                              <span>Editar</span>
                            </Space>
                          </Space>
                          <div>
                            <Space className="action-edit">
                              <a
                                target="_blank"
                                href={
                                  "https://spap.pt/spap/apresentacoes/" +
                                  item.slug
                                }
                              >
                                <EyeOutlined
                                  className="size-eye"
                                  style={{ paddingRight: "5px", width: "30px" }}
                                />
                              </a>
                            </Space>
                          </div>
                        </Space>
                      </Space>
                    </Card>
                  </Col>
                );
              })}
            </ReactSortable>
          </>
        )}

        <Drawer
          key={"editar-apresentacao-" + apresentacaoIdEdit}
          title="Editar Apresentacao"
          width={1000}
          onClose={() => setShowApresentacaoDrawer(false)}
          open={showApresentacaoDrawer}
          bodyStyle={{
            paddingBottom: 80,
          }}
          extra={
            <Space>
              <Button onClick={() => setShowApresentacaoDrawer(false)}>
                Cancelar
              </Button>
              <Button
                onClick={handleEditApresentacao}
                type="submit"
                style={{
                  borderRadius: "6px",
                  background: "#494D7D",
                  color: "#fff",
                }}
              >
                Guardar
              </Button>
            </Space>
          }
        >
          <form layout="vertical" id="update-apresentacao-form">
            <Col>
              <Row align={"middle"}>
                <Col span={12}>
                  <h5 style={{ marginBottom: "10px", fontSize: "18px" }}>
                    Upload da imagem
                  </h5>
                  <p>
                    Poderá fazer o upload de uma nova imagem de destaque
                    clicando na imagem do lado direito.
                  </p>
                </Col>
                <Col span={12}>
                  <Dragger
                    {...propApresentacaoEdit}
                    fileList={[...fileApresentacaoListEdit]}
                    style={{
                      marginBottom: "5px",
                      width: "100%",
                      backgroundImage:
                        "url(https://api.spap.pt/apresentacoes/" +
                        apresentacaoIdEdit +
                        "/" +
                        imagemDestaqueEdit +
                        ")",
                      backgroundSize: "cover",
                    }}
                  >
                    <div style={{ opacity: 0 }}>
                      <p className="ant-upload-drag-icon">
                        <IconUpload />
                      </p>
                      <p className="ant-upload-text text">Upload da imagem </p>
                      <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                    </div>
                  </Dragger>
                </Col>
              </Row>
              <Divider
                style={{ background: "#fff", margin: "12px 0" }}
              ></Divider>
              <Row
                className="row-drawer"
                gutter={[8, 8]}
                style={{ flexFlow: "column !important" }}
              >
                <Col span={24}>
                  <h6 style={styles.h6_label}>Título</h6>
                  <Input
                    key={"titulo-apresentacao-" + apresentacaoIdEdit}
                    placeholder="Título"
                    name="titulo"
                    style={styles.input_class}
                    defaultValue={tituloApresentacaoEdit}
                    onChange={(e) => setTituloApresentacaoEdit(e.target.value)}
                    required
                  />
                </Col>
                <Col span={24}>
                  <h6 style={styles.h6_label}>Descrição</h6>
                  <ReactQuill
                    key={"descricao-apresentacao-" + apresentacaoIdEdit}
                    modules={modules}
                    formats={formats}
                    theme="snow"
                    name="descricao"
                    onChange={(e) => setDescricaoApresentacaoEdit(e)}
                    defaultValue={descricaoApresentacaoEdit}
                  />
                </Col>
              </Row>
            </Col>
          </form>
        </Drawer>

        <Modal
          key={"remove-apresentacao-" + idToRemove}
          title="Está prestes a eliminar um Apresentacao"
          open={removeApresentacaoModalOpen}
          onOk={handleRemoveApresentacao}
          onCancel={() => setRemoveApresentacaoModalOpen(false)}
          okText="Confirmar"
          cancelText="Cancelar"
        >
          <p>
            Tem a certeza que pretende remover o Apresentacao{" "}
            <b>'{titleToRemove}'</b>?
          </p>
        </Modal>
      </WrapperCardList>
    </WrapperCardApresentacao>
  );
}
