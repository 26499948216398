import styled from "styled-components";
import { Link } from "react-router-dom";
import Container from "../../../../Components/Container";
import PageTitle from "../../../../Components/PageTitle";
import PageText from "../../../../Components/PageText";
import FlexInColumn from "../../../../Components/FlexInColumn";
import FlexInRow from "../../../../Components/FlexInRow";
import SidebarMenu from "../../../../Components/SidebarMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { Space } from "antd";
import { Helmet } from "react-helmet";
const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin: 7.813vw 0 0;

  img {
    width: 100%;
  }

  .link {
    color: ${(props) => props.theme.orange};
    font: normal normal bold 18px/24px Roboto;
    font-weight: bold;
    place-self: flex-start;
    transition: 0.3s ease;
    text-align: left;
  }

  .link:hover {
    color: ${(props) => props.theme.orangeHover};
  }

  .link .icon {
    color: ${(props) => props.theme.blue};
    margin-right: 5px;
  }

  .link:hover .icon {
    color: ${(props) => props.theme.blueHover};
  }

  @media (max-width: 992px) {
    margin-top: 120px;
  }
`;

const SectionNucleoFluid = styled.section`
  background-color: #e8e9f3;
  margin-top: 80px;
  padding: 100px;

  h2 {
    color: #287092;
    font: normal normal bold 35px/42px Fira Sans;
    margin-bottom: 100px;
    text-align: center;
  }

  @media (max-width: 992px) {
    margin-top: 60px;
    padding: 60px 0;

    h2 {
      font: normal normal bold 24px/28px Fira Sans;
      margin-bottom: 30px;
    }
  }
`;

const SectionNucleo = styled.div`
  display: grid;
  grid-column-gap: 5.208vw;
  grid-row-gap: 2.604vw;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  .item {
    display: flex;
    flex-direction: column;
  }

  .item__image {
    overflow: hidden;
    width: 11.354vw;
    height: 11.3524vw;
    border: 2px solid ${(props) => props.theme.blue};
    border-radius: 360px;
    margin: 0 auto;

    img {
      border-radius: 100%;
    }
  }

  .item__title {
    text-align: center;
    letter-spacing: 0px;
    color: #97c1d5;
    font-size: 1.667vw;
    font-weight: bold;
    line-height: 1.979vw;
    margin: 1.25vw auto 0.313vw;
  }

  .item__institute,
  .item__subtitle {
    text-align: center;
    letter-spacing: 0px;
    font-size: 1.25vw;
    color: #97c1d5;
    line-height: inherit;
    margin-bottom: 20px;
  }

  .item__subtitle {
    color: #8688a8;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 30px;

    .item.mobile-none {
      display: none;
    }

    .item__image {
      height: 120px;
      width: 120px;
    }

    .item__title {
      font-size: 14px;
      line-height: 16px;
    }

    .item__institute,
    .item__subtitle {
      font-size: 12px;
      line-height: 14px;
    }
  }
`;

export default function QuemSomos(props) {
  const SidebarMenuLinks = [
    {
      title: "Quem somos?",
      link: "/nispap/sobre-nos/quem-somos",
    },
    {
      title: "Iniciativas",
      link: "/nispap/sobre-nos/iniciativas",
    },
  ];

  const NucleoItens = [
    {
      imageDesktop: require("../../../../Assets/Images/NISPAP/QuemSomos/Diana-A-Baptista.png"),
      imageMobile: require("../../../../Assets/Images/NISPAP/QuemSomos/Diana-A-Baptista.png"),
      imageAlt: "Diana A. Baptista",
      title: "Diana A. Baptista",
      institute: "Centro Hospitalar e Universitário de São João",
      subtitle: "Presidente",
    },
    {
      imageDesktop: require("../../../../Assets/Images/NISPAP/QuemSomos/Mariana-Brito-Pereira.png"),
      imageMobile: require("../../../../Assets/Images/NISPAP/QuemSomos/Mariana-Brito-Pereira.png"),
      imageAlt: "Mariana Brito Pereira",
      title: "Mariana Brito Pereira",
      institute: "Hospital CUF Descobertas",
      subtitle: "Vice-Presidente",
    },
    {
      imageDesktop: require("../../../../Assets/Images/NISPAP/QuemSomos/Ana-Lucia-Alves.png"),
      imageMobile: require("../../../../Assets/Images/NISPAP/QuemSomos/Ana-Lucia-Alves.png"),
      imageAlt: "Ana Lúcia Alves",
      title: "Ana Lúcia Alves",
      institute: "Centro Hospitalar e Universitário de Coimbra",
      subtitle: "Vice-Presidente",
    },
    {
      imageDesktop: require("../../../../Assets/Images/NISPAP/QuemSomos/Goncalo-Miranda.png"),
      imageMobile: require("../../../../Assets/Images/NISPAP/QuemSomos/Goncalo-Miranda.png"),
      imageAlt: "Gonçalo Ribeiro Miranda",
      title: "Gonçalo Ribeiro Miranda",
      institute: "Instituto Português de Oncologia do Porto",
      subtitle: "Vogal",
    },
    {
      imageDesktop: require("../../../../Assets/Images/NISPAP/QuemSomos/Carlota-Mora.png"),
      imageMobile: require("../../../../Assets/Images/NISPAP/QuemSomos/Carlota-Mora.png"),
      imageAlt: "Carlota Mora",
      title: "Carlota Mora",
      institute: "Instituto Português de Oncologia de Lisboa",
      subtitle: "Tesoureira",
    },
    {
      imageDesktop: require("../../../../Assets/Images/NISPAP/QuemSomos/Ana-Elisa-Teles.png"),
      imageMobile: require("../../../../Assets/Images/NISPAP/QuemSomos/Ana-Elisa-Teles.png"),
      imageAlt: "Ana Elisa Teles",
      title: "Ana Elisa Teles",
      institute: "Hospital Garcia de Orta",
      subtitle: "Vogal",
    },
    {
      imageDesktop: require("../../../../Assets/Images/NISPAP/QuemSomos/Ana-Pestana-Lopes.png"),
      imageMobile: require("../../../../Assets/Images/NISPAP/QuemSomos/Ana-Pestana-Lopes.png"),
      imageAlt: "Ana Pestana Lopes",
      title: "Ana Pestana Lopes",
      institute: "Hospital da Luz Lisboa",
      subtitle: "Vogal",
    },
    {
      imageDesktop: require("../../../../Assets/Images/NISPAP/QuemSomos/Diogo-Sa.png"),
      imageMobile: require("../../../../Assets/Images/NISPAP/QuemSomos/Diogo-Sa.png"),
      imageAlt: "Diogo Sá",
      title: "Diogo Sá",
      institute: "Centro Hospitalar e Universitário de Santo António",
      subtitle: "Vogal",
    },
    {
      imageDesktop: require("../../../../Assets/Images/NISPAP/QuemSomos/Filipe-Nogueira.png"),
      imageMobile: require("../../../../Assets/Images/NISPAP/QuemSomos/Filipe-Nogueira.png"),
      imageAlt: "Filipe Nogueira",
      title: "Filipe Nogueira",
      institute: "Hospital Garcia de Orta",
      subtitle: "Vogal",
    },
    {
      imageDesktop: require("../../../../Assets/Images/NISPAP/QuemSomos/Julia-Azevedo.png"),
      imageMobile: require("../../../../Assets/Images/NISPAP/QuemSomos/Julia-Azevedo.png"),
      imageAlt: "Júlia Azevedo",
      title: "Júlia Azevedo",
      institute: "Instituto Português de Oncologia do Porto",
      subtitle: "Vogal",
    },
    {
      imageDesktop: require("../../../../Assets/Images/NISPAP/QuemSomos/Leonor-Branquinho-Ruivo.png"),
      imageMobile: require("../../../../Assets/Images/NISPAP/QuemSomos/Leonor-Branquinho-Ruivo.png"),
      imageAlt: "Leonor Branquinho Ruivo",
      title: "Leonor Branquinho Ruivo",
      institute: "Centro Hospitalar de Lisboa Norte",
      subtitle: "Vogal",
    },
    {
      imageDesktop: require("../../../../Assets/Images/NISPAP/QuemSomos/Maria-Ana-Alzamora.png"),
      imageMobile: require("../../../../Assets/Images/NISPAP/QuemSomos/Maria-Ana-Alzamora.png"),
      imageAlt: "Maria Ana Alzamora",
      title: "Maria Ana Alzamora",
      institute: "Instituto Português de Oncologia do Porto",
      subtitle: "Vogal",
    },
    {
      imageDesktop: require("../../../../Assets/Images/NISPAP/QuemSomos/Sofia-Antunes.png"),
      imageMobile: require("../../../../Assets/Images/NISPAP/QuemSomos/Sofia-Antunes.png"),
      imageAlt: "Sofia Antunes",
      title: "Sofia Antunes",
      institute: "Hospital Garcia de Orta",
      subtitle: "Vogal",
    },
    {
      imageDesktop: require("../../../../Assets/Images/NISPAP/QuemSomos/Sofia-Verissimo-Pedrosa.png"),
      imageMobile: require("../../../../Assets/Images/NISPAP/QuemSomos/Sofia-Verissimo-Pedrosa.png"),
      imageAlt: "Sofia Veríssimo Pedrosa",
      title: "Sofia Veríssimo Pedrosa",
      institute: "Centro Hospitalar e Universitário de São João",
      subtitle: "Secretário geral",
    },
  ];

  return (
    <Wrapper>
      <Helmet>
        <title>
          Quem somos? | Núcleo de Internos Sociedade Portuguesa de Anatomia
          Patológica - NISPAP
        </title>
        <meta
          name="description"
          content="Fundado e eleito no 18º Congresso SPAP / 21º Congresso SPC 2021, o NI-SPAP tem como grande objectivo a promoção de uma contínua melhoria das condições formativas e de trabalho dos internos de Anatomia Patológica, através da promoção de uma cada vez maior transparência e cooperação entre as diferentes instituições formativas nacionais."
        />
        <meta
          name="keywords"
          content="nispap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      <Container>
        <PageTitle
          title="Quem somos?"
          nispap={true}
          path={[
            {
              text: "NISPAP",
              link: "/nispap",
            },
            {
              text: "Sobre nós",
              link: "/nispap/sobre-nos",
            },
            {
              text: "Quem somos?",
            },
          ]}
          imageDesktop={require("../../../../Assets/Images/NISPAP/img-quem-somos@2x.png")}
          imageMobile={require("../../../../Assets/Images/NISPAP/img-quem-somos.png")}
          imageAlt="Quem somos?"
          sidebar={<SidebarMenu navItens={SidebarMenuLinks} nispap={true} />}
        />

        <FlexInRow gap="60px">
          <FlexInColumn gap="12.396vw" mobile="column">
            <FlexInRow gap="0">
              <PageText title="NISPAP - Núcleo de Internos da Sociedade Portuguesa de Anatomia Patológica" />
              <PageText text="<b>Quem somos?</b>" />
              <PageText text="O <b>NISPAP - Núcleo de Internos da Sociedade Portuguesa de Anatomia Patológica</b> é a organização que dá voz a todos os médicos internos, constituída por elementos de norte a sul do país e unidos por uma missão comum: <b>representar, formar e inovar</b>. " />
              <PageText text="Fundado e eleito no 18º Congresso SPAP / 21º Congresso SPC de 2021, o NISPAP foi criado com o propósito de garantir que os objetivos e preocupações dos médicos internos fossem melhor representados na Sociedade Portuguesa de Anatomia Patológica, com quem trabalha em estreita colaboração e sinergia. " />
              <PageText text="Por acreditar que a <b>formação contínua</b> é fundamental para a excelência da especialidade, o NISPAP está comprometido em promover uma plataforma de oportunidades de desenvolvimento profissional para todos os médicos internos, assim como em fomentar a partilha de conhecimento interdisciplinar e intergeracional. " />
              <PageText text="Essa partilha associada às iniciativas de <b>promoção e colaboração científica</b> é outra das ambições que motiva o NISPAP a zelar por uma cultura de intercâmbio do saber e de iniciativas de investigação, de forma a fortalecer uma rede de apoio coletiva entre todos os médicos internos. " />
              <PageText text="O NISPAP assume igualmente a importância de cada membro ser <b>embaixador da Anatomia Patológica</b>, e está comprometido em destacar a sua importância perante a comunidade médica e perante a sociedade civil, procurando afirmar o papel central da nossa especialidade na atividade assistencial, no ensino e formação médica e na investigação translacional. " />
              <PageText text="Movido pelo futuro da Anatomia Patológica, o NISPAP será tão melhor sucedido quanto maior for a participação dos médicos internos na sua missão e atividade. " />
              <PageText text="<b>Junta-te a nós nesta emocionante jornada de aprendizagem, colaboração e avanço científico</b>." />
            </FlexInRow>
          </FlexInColumn>

          <FlexInColumn gap="12.396vw" mobile="column">
            <FlexInRow gap="0">
              <PageText title="Objectivos" />
              <PageText
                text='
                <ul>
                  <li>
                    Dar continuidade aos projetos iniciados pelos núcleos anteriores, como as "Conversas ao microscópio," o "Caso do Mês" e o "Encontro Nacional de Internos“;
                  </li>
                  <li>
                    Criação de sessão de acolhimento a novos internos; 
                  </li>
                  <li>
                    Promover webinars sobre a segurança no trabalho; 
                  </li>
                  <li>
                    Organização de eventos e cursos, com especial enfoque na patologia molecular e Citopatologia.
                  </li>
                </ul>'
              />
              <Space size={5} className="link">
                <FontAwesomeIcon icon={faBook} className="icon" />{" "}
                <Link className="link" to="/spap/estatutos-e-documentos">
                  Consultar estatutos{" "}
                </Link>
              </Space>
            </FlexInRow>
          </FlexInColumn>
        </FlexInRow>
      </Container>

      <SectionNucleoFluid>
        <Container>
          <h2>Constituição do Núcleo de Internos da SPAP</h2>

          <SectionNucleo>
            {NucleoItens.length > 0 &&
              NucleoItens.map((item, index) => {
                return (
                  <>
                    {index === 12 && <div className="item mobile-none"></div>}
                    <div className="item">
                      <picture className="item__image">
                        <source
                          srcSet={item.imageDesktop}
                          media="(min-width: 990px)"
                        />
                        <img src={item.imageMobile} alt={item.imageAlt} />
                      </picture>
                      <h3 className="item__title">{item.title}</h3>
                      <h4 className="item__institute">{item.institute}</h4>
                      <h4 className="item__subtitle">{item.subtitle}</h4>
                    </div>
                  </>
                );
              })}
          </SectionNucleo>
        </Container>
      </SectionNucleoFluid>
    </Wrapper>
  );
}
