import styled from "styled-components";
import { Alert, Space } from "antd";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

const Wrapper = styled.div`
  place-self: center flex-start;

  margin-left: 1.771vw;

  .item {
    background-color: #ffffff;
    border: none;
    border-radius: 16px;
    padding: 20px;
  }

  .ant-alert-icon {
    font-size: 24px;
  }

  .ant-alert-message {
    color: #000;
  }

  .ant-alert-close-icon {
    font-size: 12px;
    color: #384455;
  }
`;

export default function Notification(props) {
  const [newMsg, setNewMsg] = useState(false);

  useEffect(() => {
    function checkMsg() {
      if (localStorage.getItem("newmsg")) {
        setNewMsg(true);
        setTimeout(() => {
          localStorage.removeItem("newmsg");
          setNewMsg(false);
        }, 10000);
      }
    }

    window.addEventListener("storage", checkMsg);

    return () => {
      window.removeEventListener("storage", checkMsg);
    };
  }, []);

  const removeStorage = () => {
    localStorage.removeItem("newmsg");
  };
  return (
    <Wrapper>
      {newMsg && (
        <>
          <Alert
            message={
              <>
                Aviso. Recebeu uma nova mensagem. Aceda às suas{" "}
                <Link to="/dashboard/mensagens">conversas</Link> para interagir.
              </>
            }
            type="warning"
            showIcon
            closable
            className="item"
            onClose={removeStorage}
          />
        </>
      )}
    </Wrapper>
  );
}
