import styled from "styled-components";
import { Link } from "react-router-dom";

import Container from "../../../../Components/Container";
import FlexInRow from "../../../../Components/FlexInRow";
import FlexInColumn from "../../../../Components/FlexInColumn";
import PageTitle from "../../../../Components/PageTitle";
import RecommendedLinks from "../../../../Components/RecommendedLinks";

import IconSeeMore from "../../../../Assets/Images/icon-saber-mais.svg";
import IconShare from "../../../../Assets/Images/icon-partilhar.svg";
import IconReturn from "../../../../Assets/Images/icon-voltar.svg";
import { notification } from "antd";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  > .content {
    padding-bottom: 4vw;
  }

  img {
    width: 100%;
  }

  p {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0;
  }

  a {
    text-decoration: none;
    font-size: 0.938vw;
    font-style: italic;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #f88436;
    opacity: 1;
  }

  ul {
    list-style: none;
    width: 8.333vw;
    padding: 0;
  }

  li {
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
    gap: 0.729vw;
    text-align: left;
    font-size: 1.146vw;
    font-weight: bold;
    line-height: 1.51vw;
    letter-spacing: 0px;
    opacity: 1;
    margin: 1.042vw 0;

    img {
      width: 1.667vw;
    }
  }

  li a {
    color: #f88436;
    font-style: initial;
    text-decoration: none;
  }

  .icon {
    margin-right: 8px;
  }

  .icon-file {
    width: 3.438vw;
  }

  .share {
    cursor: pointer;
    color: ${(props) => props.theme.orange};
  }

  @media (max-width: 992px) {
    margin-top: 120px;
  }

  @media (min-width: 1920px) {
    p {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

const Title = styled.h2`
  text-align: left;
  font-size: 1.25vw;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;
  margin: 0;

  @media (max-width: 992px) {
  }
`;

const CardPicture = styled.picture`
  border: none;
  overflow: hidden;
  min-width: ${(props) => props.width};
  max-width: ${(props) => props.width};
  position: relative;

  img {
    height: 100%;
    object-fit: cover;
    object-position: top;
  }

  .overlay {
    background-color: #f88436;
    opacity: 0.2;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
  }
`;

const SectionOrganization = styled.section`
  margin-bottom: 2.344vw;

  p {
    text-align: left;
    font-size: 1.25vw;
    line-height: inherit;
    letter-spacing: 0px;
    color: #0c1151;
  }

  b {
    font-weight: bold;
  }
`;

export default function NoticiaPremios(props) {
  const handleShare = (link) => {
    navigator.clipboard.writeText(link);
    notification.info({ description: "Link copiado: " + link });
  };

  return (
    <Wrapper>
      <Container className="content">
        <PageTitle
          title="Prémios"
          path="Homepage • Profissionais da saúde • Notícias • Prémios"
          imageDesktop={require("../../../../Assets/Images/img-top-spap-bolsa-2022@2x.png")}
          imageMobile={require("../../../../Assets/Images/img-top-spap-bolsa-2022.png")}
          imageAlt="Prémios"
        />

        <FlexInRow gap="3.646vw">
          <Title>
            Melhor Comunicação Oral na área de Histologia (cheque-livro
            Livromédica no valor de 300€)
          </Title>

          <FlexInColumn gap="15.260vw">
            <CardPicture width="56.667vw">
              <source
                srcSet={require("../../../../Assets/Images/premios-01.png")}
                media="(min-width: 990px)"
              />
              <img
                src={require("../../../../Assets/Images/premios-01.png")}
                alt="Prémios"
              />
              <div className="overlay" />
            </CardPicture>
            <div>
              <ul>
                <li className="link-disabled">
                  <Link to="/">
                    <img
                      src={IconSeeMore}
                      alt="Icon See More"
                      className="icon icon-see-more"
                    />
                    Saber mais
                  </Link>
                </li>
                <li>
                  <span
                    className="share"
                    onClick={() => handleShare(window.location.href)}
                  >
                    <img
                      src={IconShare}
                      alt="Icon Share"
                      className="icon icon-share"
                    />
                    Partilhar
                  </span>
                </li>
                <li>
                  <a href="javascript:history.back()">
                    <img
                      src={IconReturn}
                      alt="Icon Return"
                      className="icon icon-return"
                    />
                    Voltar
                  </a>
                </li>
              </ul>
            </div>
          </FlexInColumn>

          <FlexInRow>
            <p>
              <i>“LIMB BODY WALL COMPLEX”</i>: DEFINIÇÃO E DISCUSSÃO DA
              ETIOPATOGENIA. UM ESTUDO TRI-CÊNTRICO DE 9 ANOS. LIMB BODY WALL
              COMPLEX: DEFINITION AND DISCUSSION OF ETIOPATHOGENESIS. A 9-YEAR
              TRICENTER STUDY Ana Costa Braga(1); Lucília Monteiro(2); Paula
              Borralho Nunes(3); Marco Ferreira(3); Sophie Collardeau-Frachon(4)
              / (1) Hospital Prof. Doutor Fernando Fonseca-(2) Serviço de
              Anatomia Patológica, Centro Hospitalar Lisboa Ocidental-(3)
              Serviço de Anatomia Patológica, Hospital Prof. Doutor Fernando
              Fonseca-(4) Institut de Pathologie EST, Centre Hospitalier et
              Universitaire de Lyon - Hôpital Femme-Mère-Enfant
            </p>
          </FlexInRow>
        </FlexInRow>
      </Container>

      <RecommendedLinks
        title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
        links={[
          {
            title: "História",
            link: "/spap/historia",
          },
          {
            title: "Órgãos sociais",
            link: "/spap/orgaos-sociais",
          },
          {
            title: "Bolsas e prémios",
            link: "/spap/bolsas-e-premios",
          },
          {
            title: "Vantagens de ser sócio",
            link: "/socios/conheca-as-vantagens",
          },
        ]}
      />
    </Wrapper>
  );
}
