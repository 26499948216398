import styled from "styled-components";
import { Link } from "react-router-dom";
import AuthService from "../../../Services/auth.service";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  DatePicker,
  Space,
  Select,
  Row,
  Col,
  Steps,
  theme,
  Button,
  notification,
} from "antd";
import {
  CheckCircleTwoTone,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 5px;

  .ant-steps.ant-steps-vertical {
    display: grid;
    justify-content: center;
  }

  .ant-select-multiple .ant-select-selection-item-remove > .anticon {
    vertical-align: 0.8em;
  }

  .ant-select-multiple .ant-select-selection-placeholder {
    position: absolute;
    top: 66%;
  }

  .ant-steps .ant-steps-item-icon .ant-steps-icon {
    top: -1.5px !important;
  }

  p {
    color: #809fb8;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0px;
    margin: 0;
    text-align: center;
  }

  p.left {
    text-align: left;
    align-items: center;
    display: inline-flex;
  }

  p > * {
    color: #809fb8;
    margin-left: 5px;
  }

  label {
    text-align: center;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0px;
    color: #0c1151;
  }

  .next-btn,
  .next-btn:hover {
    display: block !important;
    width: 100% !important;
    border: 1px solid #494d7d;
    background: #494d7d;
    color: #fff;
    height: 45px;
    border-radius: 6px;
  }

  input[type="date"]::placeholder,
  .ant-select-selection-placeholder {
    color: rgba(0, 0, 0, 0.45) !important;
  }

  .ant-select-selector .ant-select-selection-search-input {
    height: 42px !important;
  }

  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="text"],
  input[type="tel"],
  select,
  input[type="date"] {
    background-color: #d9e1e700;
    border: 1px solid rgb(128, 159, 184);
    border-radius: 6px;
    padding: 10px 10px;
    width: 100%;
    outline: 0;
    transition: 0.3s ease;
    text-align: center;
  }

  label {
    margin-top: 1%;
  }

  input[type="date"] {
    text-align: center;
  }

  input[type="tel"] {
    margin-bottom: 0px;
  }

  input[type="email"]:focus,
  input[type="password"]:focus,
  input[type="text"]:focus,
  input[type="tel"]:focus {
    box-shadow: 0 8px 24px #00000025;
  }

  input[type="checkbox"] {
    border: 2px solid #e0e7eb;
    border-radius: 0.417vw;
    cursor: pointer;
    height: 20px;
    margin-right: 11px;
    width: 20px;
  }

  .div-next {
    display: block;
    width: 50%;
    margin-left: auto !important;
    margin-top: 10px;
  }

  input[type="submit"] {
    background-color: ${(props) => props.theme.blueLight};
    border: none;
    border-radius: 6px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    height: 45px;
    line-height: 22px;
    letter-spacing: 0px;
    color: ${(props) => props.theme.white};
    opacity: 1;
    place-self: center;
    transition: 0.3s ease;
  }

  input[type="submit"]:hover {
    background-color: ${(props) => props.theme.blue};
    color: ${(props) => props.theme.whiteHover};
  }

  .link-checkbox {
    transition: 0.3s ease;
  }

  .link-checkbox:hover {
    color: rgb(73, 77, 125);
  }

  @media (max-width: 992px) {
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="text"],
    input[type="tel"] {
      border-radius: 8px;
      font-size: 16px;
      line-height: 20px;
      padding: 15px 20px;
    }

    .ant-picker input {
      border-radius: 8px;
    }

    .ant-row {
      display: block;
    }

    .ant-col,
    label {
      margin-top: 1%;
      min-width: 100%;
    }

    input[type="checkbox"] {
      height: 15px;
      width: 15px;
    }

    input[type="submit"] {
      border-radius: 8px;
      font-size: 16px;
      line-height: 22px;
    }
  }

  span[role="alert"] {
    color: red;
  }
`;

export default function RegisterForm(props) {
  document.title = "SPAP - Registar";

  const navigate = useNavigate();
  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [tipoUtilizador, setTipoUtilizador] = useState(
    "socio_nao_aprovado_especialista"
  );

  const [nomeCompleto, setNomeCompleto] = useState("");
  const [nomeFaturacao, setNomeFaturacao] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [area, setArea] = useState([]);
  const [instituicao, setInstituicao] = useState([]);
  const [grau, setGrau] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [cidade, setCidade] = useState([]);
  const [categoriaProfissional, setCategoriaProfissional] = useState("");
  const [sociosProponentes, setSociosProponentes] = useState("");
  const [celulaProfissional, setCelulaProfissional] = useState("");
  const [contribuinte, setContribuinte] = useState("");

  const [todasCidades, setTodasCidades] = useState("");

  useEffect(() => {
    fetch(`https://json.geoapi.pt/municipios/freguesias`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar cidade");
        }
        return response.json();
      })
      .then((data) => {
        setTodasCidades(data);
      })
      .catch((error) => {
        setTodasCidades([
          { nome: "Lisboa" },
          { nome: "Porto" },
          { nome: "Aves" },
          { nome: "Sintra" },
          { nome: "Vila Nova de Gaia" },
          { nome: "Cascais" },
          { nome: "Loures" },
          { nome: "Braga" },
          { nome: "Almada" },
          { nome: "Matosinhos" },
          { nome: "Amadora" },
          { nome: "Oeiras" },
          { nome: "Gondomar" },
          { nome: "Seixal" },
          { nome: "Guimarães" },
          { nome: "Odivelas" },
          { nome: "Coimbra" },
          { nome: "Vila Franca de Xira" },
          { nome: "Maia" },
          { nome: "Famalicão" },
          { nome: "Leiria" },
          { nome: "Setúbal" },
          { nome: "Barcelos" },
          { nome: "Funchal" },
          { nome: "Viseu" },
          { nome: "Viana do Castelo" },
          { nome: "Barreiro" },
          { nome: "Aveiro" },
          { nome: "Queluz" },
          { nome: "Ponta Delgada" },
          { nome: "Faro" },
          { nome: "Santarém" },
          { nome: "Évora" },
          { nome: "Castelo Branco" },
          { nome: "Vila Real" },
          { nome: "Rio Tinto" },
          { nome: "Amora" },
          { nome: "Corroios" },
          { nome: "Guarda" },
          { nome: "Mafamude" },
          { nome: "Ermezinde" },
          { nome: "Beja" },
          { nome: "Agualva" },
          { nome: "Bragança" },
          { nome: "Espinho" },
          { nome: "Póvoa de Santa Iria" },
          { nome: "Senhora da Hora" },
          { nome: "Massamá" },
          { nome: "Águas Santas" },
          { nome: "Estoril" },
          { nome: "Portalegre" },
          { nome: "São João da Madeira" },
          { nome: "Entroncamento" },
          { nome: "Feira" },
        ]);

        console.error("Erro na requisição: ", error);
      })
      .finally(() => {});
  }, []);

  const handleSubmitRegister = (e) => {
    e.preventDefault();

    if (
      !nomeCompleto.length ||
      !instituicao.length ||
      !dataNascimento.length ||
      !telefone.length ||
      !cidade.length ||
      !email.length ||
      !password.length ||
      !confirmPassword.length
    ) {
      setHasError(true);
      setErrorMessage("Por favor, preencha todos os campos.");
      setTimeout(() => {
        setHasError(false);
      }, 3000);
      return;
    }

    if (tipoUtilizador == "nao_socio") {
      if (!categoriaProfissional.length) {
        setHasError(true);
        setErrorMessage("Por favor, preencha a categoria profissional.");
        setTimeout(() => {
          setHasError(false);
        }, 3000);
        return;
      }
    } else {
      if (
        !celulaProfissional.length ||
        !nomeFaturacao.length ||
        !contribuinte.length ||
        !sociosProponentes.length
      ) {
        setHasError(true);
        setErrorMessage("Por favor, preencha todos os campos.");
        setTimeout(() => {
          setHasError(false);
        }, 3000);
        return;
      }

      if (tipoUtilizador == "socio_nao_aprovado_especialista") {
        if (!area.length) {
          setHasError(true);
          setErrorMessage("Por favor, preencha a área de diferenciação.");
          setTimeout(() => {
            setHasError(false);
          }, 3000);
          return;
        }
      }
    }

    if (password.length < 8) {
      setHasError(true);
      setErrorMessage("A password deve conter pelo menos 8 caracteres.");
      setTimeout(() => {
        setHasError(false);
      }, 3000);
      return;
    }

    if (password != confirmPassword) {
      setHasError(true);
      setErrorMessage("A password e a sua confirmação não coincidem.");
      setTimeout(() => {
        setHasError(false);
      }, 3000);
      return;
    }

    let tipoSocio = "";

    if (tipoUtilizador != "nao_socio") {
      //tipoSocio = (tipoUtilizador == 'socio_nao_aprovado_interno') ? 'NISPAP' : 'SPAP';
      tipoSocio = "Titular";
    }

    let areaDiferenciacao = "";
    let areaDiferenciacao2 = "";

    if (area.length >= 1) {
      areaDiferenciacao = area[0];
      if (area.length > 1) {
        areaDiferenciacao2 = area[1];
      }
    }

    const [year, month, day] = dataNascimento.split("-");
    let data = day + "/" + month + "/" + year;

    let primeiroNome = nomeCompleto.split(" ")[0];
    let apelido = nomeCompleto.split(" ").pop();

    AuthService.register(
      tipoUtilizador,
      tipoSocio,
      email,
      nomeCompleto,
      primeiroNome,
      apelido,
      password,
      telefone,
      areaDiferenciacao,
      areaDiferenciacao2,
      instituicao,
      grau,
      cidade,
      data,
      celulaProfissional,
      sociosProponentes,
      nomeFaturacao,
      contribuinte,
      categoriaProfissional
    )
      .then((data) => {
        setHasSuccess(true);
        setTimeout(() => {
          navigate("/");
        }, 3000);
      })
      .catch((data) => {
        setHasError(true);
        if (
          data.response.data.message ==
          "The password must be at least 8 characters."
        ) {
          setErrorMessage("A password deve conter pelo menos 8 caracteres.");
          setTimeout(() => {
            setHasError(false);
          }, 3000);
          return false;
        } else {
          setErrorMessage("O e-mail introduzido já se encontra registado.");
          setTimeout(() => {
            setHasError(false);
          }, 3000);
          return false;
        }
      });
  };

  const setCapitalize = (str) => {
    var splitStr = str.toLowerCase().split(" ");

    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you

      // Assign it back to the array

      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }

    // Directly return the joined string

    return splitStr.join(" ");
  };

  const steps =
    tipoUtilizador != "nao_socio"
      ? [
          {
            title: "Pessoal",
            content: "First-content",
          },
          {
            title: "Profissional",
            content: "Second-content",
          },

          {
            title: "Faturação",
            content: "Third-content",
          },
          {
            title: "Acesso",
            content: "Fourth-content",
          },
        ]
      : [
          {
            title: "Pessoal",
            content: "First-content",
          },

          {
            title: "Profissional",
            content: "Second-content",
          },
          {
            title: "Acesso",
            content: "Second-content",
          },
        ];
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    lineHeight: "260px",
    textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  return (
    <Form id="register-form" onSubmit={(e) => handleSubmitRegister(e)}>
      <p>Escolha a baixo o tipo de inscrição desejada</p>
      <Select
        defaultValue="socio_nao_aprovado_especialista"
        style={{
          width: "100%",
          marginBottom: "10px",
          borderRadius: "20px !important",
        }}
        onChange={(e) => setTipoUtilizador(e)}
        options={[
          { value: "nao_socio", label: "Utilizador não sócio" },
          {
            value: "socio_nao_aprovado_especialista",
            label: "Novo sócio SPAP (especialista)",
          },
          {
            value: "socio_nao_aprovado_interno",
            label: "Novo sócio NISPAP (interno)",
          },
        ]}
      />

      <p>Coloque a baixo os seus dados de inscrição</p>

      <Steps current={current} items={items} style={{ marginTop: "10px" }} />
      {current == 0 && (
        <>
          <label>
            Nome Completo *
            <input
              type="text"
              name="nome_completo"
              placeholder="Nome Completo"
              defaultValue={nomeCompleto}
              onChange={(e) => setNomeCompleto(e.target.value)}
              required
            />
          </label>
          <Row gutter={[8, 8]} align={"bottom"}>
            <Col span={12}>
              <label>
                Telemóvel *
                <input
                  type="tel"
                  name="telefone"
                  placeholder="Telemóvel"
                  defaultValue={telefone}
                  onChange={(e) => setTelefone(e.target.value)}
                  required
                />
              </label>
            </Col>
            <Col span={12}>
              <label>
                Data de Nascimento *
                <input
                  id="dateRequired"
                  type="date"
                  name="dateRequired"
                  defaultValue={dataNascimento}
                  onChange={(e) => setDataNascimento(e.target.value)}
                  required
                />
              </label>
            </Col>
          </Row>
          <Row gutter={[8, 8]} align={"bottom"}>
            <Col span={24}>
              <label style={{ width: "100%" }}>
                Cidade *
                <Select
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    height: "42px",
                  }}
                  onChange={(e) => setCidade(e)}
                  name="cidade"
                  placeholder="Selecione uma Cidade"
                  value={cidade}
                  showSearch
                  optionFilterProp="children"
                  required
                >
                  {Array.isArray(todasCidades) &&
                    todasCidades.map((cidade) => (
                      <Select.Option value={cidade.nome}>
                        {" "}
                        {cidade.nome}{" "}
                      </Select.Option>
                    ))}
                </Select>
              </label>
            </Col>
          </Row>
          <div className="div-next">
            <Button className="next-btn" onClick={() => next()}>
              Próximo
            </Button>
          </div>
        </>
      )}

      {current === 1 && tipoUtilizador == "nao_socio" && (
        <>
          <label>
            Categoria Profissional *
            <input
              type="text"
              name="categoria_profissional"
              placeholder="Categoria Profissional"
              defaultValue={categoriaProfissional}
              onChange={(e) => setCategoriaProfissional(e.target.value)}
              required
            />
          </label>
          <label>
            Instituição *
            <input
              type="text"
              name="instituicao"
              placeholder="Instituição"
              defaultValue={instituicao}
              onChange={(e) => setInstituicao(e.target.value)}
              required
            />
          </label>
          <Row gutter={[8, 8]} align="bottom" style={{ marginTop: "10px" }}>
            <Col span={12}>
              <Button
                style={{
                  width: "100%",
                  height: "45px",
                }}
                onClick={() => prev()}
              >
                Anterior
              </Button>
            </Col>
            <Col span={12}>
              <Button
                className="next-btn"
                style={{
                  width: "100%",
                  height: "45px",
                }}
                onClick={() => next()}
              >
                Próximo
              </Button>
            </Col>
          </Row>
        </>
      )}

      {current === 1 && tipoUtilizador != "nao_socio" && (
        <>
          <label>
            Nº. Célula profissional *
            <input
              type="text"
              name="celula_profissional"
              placeholder="Nº. de Célula Profissional"
              defaultValue={celulaProfissional}
              onChange={(e) => setCelulaProfissional(e.target.value)}
              required
            />
          </label>

          <label>
            Sócio(s) proponente(s) *
            <input
              type="text"
              name="socio_proponente"
              placeholder="Sócio(s) Proponente(s)"
              defaultValue={sociosProponentes}
              onChange={(e) => setSociosProponentes(e.target.value)}
              required
            />
          </label>
          {current === 1 &&
            tipoUtilizador == "socio_nao_aprovado_especialista" && (
              <>
                <label>
                  Áreas de Diferenciação *
                  <Select
                    key={"select-area"}
                    style={{
                      width: "100%",
                      marginBottom: "2px",
                      height: "42px",
                    }}
                    showSearch
                    placeholder="Área(s) de diferenciação"
                    value={area}
                    mode="multiple"
                    allowClear
                    onChange={(e) => setArea(e)}
                    options={[
                      { value: "Patologia geral", label: "Patologia geral" },
                      { value: "Citologia", label: "Citologia" },
                      {
                        value: "Patologia cutânea",
                        label: "Patologia cutânea",
                      },
                      {
                        value: "Patologia da cabeça e pescoço",
                        label: "Patologia da cabeça e pescoço",
                      },
                      {
                        value: "Patologia das partes moles",
                        label: "Patologia das partes moles",
                      },
                      {
                        value: "Patologia do sistema nervosa",
                        label: "Patologia do sistema nervoso",
                      },
                      {
                        value: "Patologia digital",
                        label: "Patologia digital",
                      },
                      {
                        value: "Patologia endócrina",
                        label: "Patologia endócrina",
                      },
                      {
                        value: "Patologia fetoplacentar",
                        label: "Patologia fetoplacentar",
                      },
                      {
                        value: "Patologia gastrointestinal",
                        label: "Patologia gastrointestinal",
                      },
                      {
                        value: "Patologia ginecológica",
                        label: "Patologia ginecológica",
                      },
                      {
                        value: "Patologia hematolinfoide",
                        label: "Patologia hematolinfoide",
                      },
                      {
                        value: "Patologia hepatobiliar",
                        label: "Patologia hepatobiliar",
                      },
                      {
                        value: "Patologia mamária",
                        label: "Patologia mamária",
                      },
                      {
                        value: "Patologia molecular",
                        label: "Patologia molecular",
                      },
                      {
                        value: "Patologia osteoarticular",
                        label: "Patologia osteoarticular",
                      },
                      {
                        value: "Patologia pancreática",
                        label: "Patologia pancreática",
                      },
                      {
                        value: "Patologia pulmonar",
                        label: "Patologia pulmonar",
                      },
                      {
                        value: "Patologia urogenital",
                        label: "Patologia urogenital",
                      },
                    ]}
                  />
                  <p style={{ textAlign: "left", fontSize: "12px" }}>
                    Selecione no máximo 2 áreas.
                  </p>
                </label>
              </>
            )}

          <label style={{ marginTop: "0" }}>
            <i>Instituição </i> *
            <Select
              key={"select-instituicao"}
              value={instituicao}
              style={{ width: "100%", marginBottom: "10px", height: "42px" }}
              placeholder="Instituição"
              showSearch
              allowClear
              onChange={(e) => setInstituicao(e)}
              options={[
                { value: "ARS ALGARVE", label: setCapitalize("ARS ALGARVE") },
                {
                  value: "BRITISH HOSPITAL LISBON XXI, S.A",
                  label: setCapitalize("BRITISH HOSPITAL LISBON XXI, S.A"),
                },
                { value: "CEDAP", label: "CEDAP" },
                {
                  value: "CENTRO DE MEDICINA E REABILITAÇÃO DE ALCOITÃO",
                  label: setCapitalize(
                    "CENTRO DE MEDICINA E REABILITAÇÃO DE ALCOITÃO"
                  ),
                },
                {
                  value: "CENTRO DE SAÚDE DE OLEIROS",
                  label: setCapitalize("CENTRO DE SAÚDE DE OLEIROS"),
                },
                {
                  value: "CENTRO DE SAÚDE PEDRÓGÃO GRANDE",
                  label: setCapitalize("CENTRO DE SAÚDE PEDRÓGÃO GRANDE"),
                },
                {
                  value: "CENTRO HOSPITALAR ALTO AVE E.P.E.",
                  label: setCapitalize("CENTRO HOSPITALAR ALTO AVE E.P.E."),
                },
                {
                  value: "CENTRO HOSPITALAR ALTO MINHO E.P.E.",
                  label: setCapitalize("CENTRO HOSPITALAR ALTO MINHO E.P.E."),
                },
                {
                  value: "CENTRO HOSPITALAR BAIXO ALENTEJO E.P.E.",
                  label: setCapitalize(
                    "CENTRO HOSPITALAR BAIXO ALENTEJO E.P.E."
                  ),
                },
                {
                  value: "CENTRO HOSPITALAR BAIXO VOUGA",
                  label: setCapitalize("CENTRO HOSPITALAR BAIXO VOUGA"),
                },
                {
                  value: "CENTRO HOSPITALAR BARLAVENTO ALGARVIO, E.P.E.",
                  label: setCapitalize(
                    "CENTRO HOSPITALAR BARLAVENTO ALGARVIO, E.P.E."
                  ),
                },
                {
                  value: "CENTRO HOSPITALAR BARREIRO MONTIJO E.P.E.",
                  label: setCapitalize(
                    "CENTRO HOSPITALAR BARREIRO MONTIJO E.P.E."
                  ),
                },
                {
                  value: "CENTRO HOSPITALAR CALDAS DA RAINHA E.P.E.",
                  label: setCapitalize(
                    "CENTRO HOSPITALAR CALDAS DA RAINHA E.P.E."
                  ),
                },
                {
                  value: "CENTRO HOSPITALAR CASCAIS E.P.E.",
                  label: setCapitalize("CENTRO HOSPITALAR CASCAIS E.P.E."),
                },
                {
                  value: "CENTRO HOSPITALAR COVA DA BEIRA, E.P.E.",
                  label: setCapitalize(
                    "CENTRO HOSPITALAR COVA DA BEIRA, E.P.E."
                  ),
                },
                {
                  value: "CENTRO HOSPITALAR DE LEIRIA, E.P.E.",
                  label: setCapitalize("CENTRO HOSPITALAR DE LEIRIA, E.P.E."),
                },
                {
                  value: "CENTRO HOSPITALAR DO ALGARVE",
                  label: setCapitalize("CENTRO HOSPITALAR DO ALGARVE"),
                },
                {
                  value: "CENTRO HOSPITALAR DO FUNCHAL",
                  label: setCapitalize("CENTRO HOSPITALAR DO FUNCHAL"),
                },
                {
                  value: "CENTRO HOSPITALAR DO OESTE",
                  label: setCapitalize("CENTRO HOSPITALAR DO OESTE"),
                },
                {
                  value: "CENTRO HOSPITALAR E UNIVERSITÁRIO DE COIMBRA",
                  label: setCapitalize(
                    "CENTRO HOSPITALAR E UNIVERSITÁRIO DE COIMBRA"
                  ),
                },
                {
                  value: "CENTRO HOSPITALAR E UNIVERSITÁRIO DE SÃO JOÃO E.P.E.",
                  label: setCapitalize(
                    "CENTRO HOSPITALAR E UNIVERSITÁRIO DE SÃO JOÃO E.P.E."
                  ),
                },
                {
                  value: "CENTRO HOSPITALAR E UNIVERSITÁRIO DO ALGARVE",
                  label: setCapitalize(
                    "CENTRO HOSPITALAR E UNIVERSITÁRIO DO ALGARVE"
                  ),
                },
                {
                  value: "CENTRO HOSPITALAR E UNIVERSITÁRIO DO PORTO E.P.E.",
                  label: setCapitalize(
                    "CENTRO HOSPITALAR E UNIVERSITÁRIO DO PORTO E.P.E."
                  ),
                },
                {
                  value: "CENTRO HOSPITALAR ENTRE DOURO E VOUGA E.P.E",
                  label: setCapitalize(
                    "CENTRO HOSPITALAR ENTRE DOURO E VOUGA E.P.E"
                  ),
                },
                {
                  value: "CENTRO HOSPITALAR LISBOA OCIDENTAL E.P.E.",
                  label: setCapitalize(
                    "CENTRO HOSPITALAR LISBOA OCIDENTAL E.P.E."
                  ),
                },
                {
                  value: "CENTRO HOSPITALAR MÉDIO - TEJO  E.P.E.",
                  label: setCapitalize(
                    "CENTRO HOSPITALAR MÉDIO - TEJO  E.P.E."
                  ),
                },
                {
                  value: "CENTRO HOSPITALAR MÉDIO AVE, E.P.E.",
                  label: setCapitalize("CENTRO HOSPITALAR MÉDIO AVE, E.P.E."),
                },
                {
                  value: "CENTRO HOSPITALAR MUNDIAL CONFIANÇA - ÉVORA E.P.E.",
                  label: setCapitalize(
                    "CENTRO HOSPITALAR MUNDIAL CONFIANÇA - ÉVORA E.P.E."
                  ),
                },
                {
                  value: "CENTRO HOSPITALAR NORDESTE, E.P.E.",
                  label: setCapitalize("CENTRO HOSPITALAR NORDESTE, E.P.E."),
                },
                {
                  value: "CENTRO HOSPITALAR PÓVOA DO VARZIM/VILA CONDE E.P.E.",
                  label: setCapitalize(
                    "CENTRO HOSPITALAR PÓVOA DO VARZIM/VILA CONDE E.P.E."
                  ),
                },
                {
                  value: "CENTRO HOSPITALAR PSIQUIATRICO DE COIMBRA",
                  label: setCapitalize(
                    "CENTRO HOSPITALAR PSIQUIATRICO DE COIMBRA"
                  ),
                },
                {
                  value: "CENTRO HOSPITALAR PSIQUIÁTRICO DE LISBOA E.P.E.",
                  label: setCapitalize(
                    "CENTRO HOSPITALAR PSIQUIÁTRICO DE LISBOA E.P.E."
                  ),
                },
                {
                  value: "CENTRO HOSPITALAR S. FRANCISCO,  E.P.E.",
                  label: setCapitalize(
                    "CENTRO HOSPITALAR S. FRANCISCO,  E.P.E."
                  ),
                },
                {
                  value: "CENTRO HOSPITALAR SETÚBAL, E.P.E.",
                  label: setCapitalize("CENTRO HOSPITALAR SETÚBAL, E.P.E."),
                },
                {
                  value: "CENTRO HOSPITALAR TÂMEGA E SOUSA E.P.E.",
                  label: setCapitalize(
                    "CENTRO HOSPITALAR TÂMEGA E SOUSA E.P.E."
                  ),
                },
                {
                  value: "CENTRO HOSPITALAR TONDELA VISEU E.P.E",
                  label: setCapitalize("CENTRO HOSPITALAR TONDELA VISEU E.P.E"),
                },
                {
                  value: "CENTRO HOSPITALAR TRÁS OS MONTES E ALTO DOURO E.P.E",
                  label: setCapitalize(
                    "CENTRO HOSPITALAR TRÁS OS MONTES E ALTO DOURO E.P.E"
                  ),
                },
                {
                  value: "CENTRO HOSPITALAR UNIVERSITÁRIO DE COIMBRA",
                  label: setCapitalize(
                    "CENTRO HOSPITALAR UNIVERSITÁRIO DE COIMBRA"
                  ),
                },
                {
                  value: "CENTRO HOSPITALAR UNIVERSITÁRIO DE SANTO ANTÓNIO",
                  label: setCapitalize(
                    "CENTRO HOSPITALAR UNIVERSITÁRIO DE SANTO ANTÓNIO"
                  ),
                },
                {
                  value:
                    "CENTRO HOSPITALAR UNIVERSITÁRIO LISBOA CENTRAL E.P.E.",
                  label: setCapitalize(
                    "CENTRO HOSPITALAR UNIVERSITÁRIO LISBOA CENTRAL E.P.E."
                  ),
                },
                {
                  value: "CENTRO HOSPITALAR UNIVERSITÁRIO LISBOA NORTE, E.P.E.",
                  label: setCapitalize(
                    "CENTRO HOSPITALAR UNIVERSITÁRIO LISBOA NORTE, E.P.E."
                  ),
                },
                {
                  value: "CENTRO HOSPITALAR VILA NOVA DE GAIA / ESPINHO E.P.E.",
                  label: setCapitalize(
                    "CENTRO HOSPITALAR VILA NOVA DE GAIA / ESPINHO E.P.E."
                  ),
                },
                {
                  value: "CENTRO HOSPITALAR VILA REAL/PESO DA RÉGUA S.ª E.P.E.",
                  label: setCapitalize(
                    "CENTRO HOSPITALAR VILA REAL/PESO DA RÉGUA S.ª E.P.E."
                  ),
                },
                {
                  value: "CLÍNICA DE STO ANTÓNIO",
                  label: setCapitalize("CLÍNICA DE STO ANTÓNIO"),
                },
                {
                  value: "CLÍNICA JOAQUIM CHAVES",
                  label: setCapitalize("CLÍNICA JOAQUIM CHAVES"),
                },
                {
                  value: "CLÍNICA LUSÍADAS ALMADA",
                  label: setCapitalize("CLÍNICA LUSÍADAS ALMADA"),
                },
                {
                  value: "CLÍNICA LUSÍADAS FARO",
                  label: setCapitalize("CLÍNICA LUSÍADAS FARO"),
                },
                {
                  value: "CLÍNICA LUSÍADAS FÓRUM ALGARVE",
                  label: setCapitalize("CLÍNICA LUSÍADAS FÓRUM ALGARVE"),
                },
                {
                  value: "CLÍNICA LUSÍADAS GAIA",
                  label: setCapitalize("CLÍNICA LUSÍADAS GAIA"),
                },
                {
                  value: "CLÍNICA LUSÍADAS ORIENTE",
                  label: setCapitalize("CLÍNICA LUSÍADAS ORIENTE"),
                },
                {
                  value: "CLÍNICA LUSÍADAS SACAVÉM",
                  label: setCapitalize("CLÍNICA LUSÍADAS SACAVÉM"),
                },
                {
                  value: "CLÍNICA MÉDICA DO PADRÃO",
                  label: setCapitalize("CLÍNICA MÉDICA DO PADRÃO"),
                },
                {
                  value: "CLÍNICA MÉDICA POVOA DO VARZIM - CLIPÓVOA",
                  label: setCapitalize(
                    "CLÍNICA MÉDICA POVOA DO VARZIM - CLIPÓVOA"
                  ),
                },
                {
                  value: "CUF ALMADA CLÍNICA",
                  label: setCapitalize("CUF ALMADA CLÍNICA"),
                },
                {
                  value: "CUF ALVALADE CLÍNICA",
                  label: setCapitalize("CUF ALVALADE CLÍNICA"),
                },
                {
                  value: "CUF BELÉM CLÍNICA",
                  label: setCapitalize("CUF BELÉM CLÍNICA"),
                },
                {
                  value: "CUF CASCAIS HOSPITAL",
                  label: setCapitalize("CUF CASCAIS HOSPITAL"),
                },
                {
                  value: "CUF COIMBRA HOSPITAL",
                  label: setCapitalize("CUF COIMBRA HOSPITAL"),
                },
                {
                  value: "CUF DESCOBERTAS HOSPITAL",
                  label: setCapitalize("CUF DESCOBERTAS HOSPITAL"),
                },
                {
                  value: "CUF INFANTE SANTO HOSPITAL",
                  label: setCapitalize("CUF INFANTE SANTO HOSPITAL"),
                },
                {
                  value: "CUF MAFRA CLÍNICA",
                  label: setCapitalize("CUF MAFRA CLÍNICA"),
                },
                {
                  value: "CUF MIRAFLORES CLÍNICA",
                  label: setCapitalize("CUF MIRAFLORES CLÍNICA"),
                },
                {
                  value: "CUF PORTO HOSPITAL",
                  label: setCapitalize("CUF PORTO HOSPITAL"),
                },
                {
                  value: "CUF PORTO INSTITUTO",
                  label: setCapitalize("CUF PORTO INSTITUTO"),
                },
                {
                  value: "CUF S. DOMINGOS RANA CLÍNICA",
                  label: setCapitalize("CUF S. DOMINGOS RANA CLÍNICA"),
                },
                {
                  value: "CUF S. JOÃO DA MADEIRA CLÍNICA",
                  label: setCapitalize("CUF S. JOÃO DA MADEIRA CLÍNICA"),
                },
                {
                  value: "CUF SANTARÉM HOSPITAL",
                  label: setCapitalize("CUF SANTARÉM HOSPITAL"),
                },
                {
                  value: "CUF SINTRA CLÍNICA",
                  label: setCapitalize("CUF SINTRA CLÍNICA"),
                },
                { value: "CUF TEJO", label: setCapitalize("CUF TEJO") },
                {
                  value: "CUF TORRES VEDRAS HOSPITAL",
                  label: setCapitalize("CUF TORRES VEDRAS HOSPITAL"),
                },
                {
                  value: "CUF VISEU HOSPITAL",
                  label: setCapitalize("CUF VISEU HOSPITAL"),
                },
                {
                  value: "FACULDADE DE MEDICINA DO PORTO",
                  label: setCapitalize("FACULDADE DE MEDICINA DO PORTO"),
                },
                {
                  value: "FUNDAÇÃO CHAMPALIMAUD",
                  label: setCapitalize("FUNDAÇÃO CHAMPALIMAUD"),
                },
                {
                  value: "GERMANO DE SOUSA - LABORATÓRIO DE ANÁLISES CLÍNICAS",
                  label: setCapitalize(
                    "GERMANO DE SOUSA - LABORATÓRIO DE ANÁLISES CLÍNICAS"
                  ),
                },
                {
                  value: "HOSPITAIS PRIVADOS DE PORTUGAL - PORTO",
                  label: setCapitalize(
                    "HOSPITAIS PRIVADOS DE PORTUGAL - PORTO"
                  ),
                },
                {
                  value: "HOSPITAL AGOSTINHO RIBEIRO",
                  label: setCapitalize("HOSPITAL AGOSTINHO RIBEIRO"),
                },
                {
                  value: "Hospital Albert Einstein",
                  label: setCapitalize("Hospital Albert Einstein"),
                },
                {
                  value: "HOSPITAL AMATO LUSITANO DE CASTELO BRANCO",
                  label: setCapitalize(
                    "HOSPITAL AMATO LUSITANO DE CASTELO BRANCO"
                  ),
                },
                {
                  value: "HOSPITAL ARCEBISPO JOÃO CRISÓSTOMO-CANTANHEDE",
                  label: setCapitalize(
                    "HOSPITAL ARCEBISPO JOÃO CRISÓSTOMO-CANTANHEDE"
                  ),
                },
                {
                  value: "HOSPITAL BARLAVENTO ALGARVIO, E.P.E.",
                  label: setCapitalize("HOSPITAL BARLAVENTO ALGARVIO, E.P.E."),
                },
                {
                  value: "HOSPITAL BEATRIZ ÂNGELO",
                  label: setCapitalize("HOSPITAL BEATRIZ ÂNGELO"),
                },
                {
                  value: "HOSPITAL BERNARDINO LOPES DE OLIVEIRA - ALCOBAÇA",
                  label: setCapitalize(
                    "HOSPITAL BERNARDINO LOPES DE OLIVEIRA - ALCOBAÇA"
                  ),
                },
                {
                  value: "HOSPITAL Cª SEG. MUNDIAL CONFIANÇA (PORTO)",
                  label: setCapitalize(
                    "HOSPITAL Cª SEG. MUNDIAL CONFIANÇA (PORTO)"
                  ),
                },
                {
                  value: "HOSPITAL CÂNDIDO DE FIGUEIREDO -TONDELA",
                  label: setCapitalize(
                    "HOSPITAL CÂNDIDO DE FIGUEIREDO -TONDELA"
                  ),
                },
                {
                  value: "HOSPITAL CENTRAL DO FUNCHAL",
                  label: setCapitalize("HOSPITAL CENTRAL DO FUNCHAL"),
                },
                {
                  value: "HOSPITAL CRUZ VERMELHA PORTUGUESA",
                  label: setCapitalize("HOSPITAL CRUZ VERMELHA PORTUGUESA"),
                },
                {
                  value: "HOSPITAL CURRY CABRAL",
                  label: setCapitalize("HOSPITAL CURRY CABRAL"),
                },
                {
                  value: "HOSPITAL D. ESTEFÂNIA",
                  label: setCapitalize("HOSPITAL D. ESTEFÂNIA"),
                },
                {
                  value: "HOSPITAL DA LIGA DE AMIGOS DOS HOSPITAIS - LISBOA",
                  label: setCapitalize(
                    "HOSPITAL DA LIGA DE AMIGOS DOS HOSPITAIS - LISBOA"
                  ),
                },
                {
                  value: "HOSPITAL DA LUZ ARRÁBIDA",
                  label: setCapitalize("HOSPITAL DA LUZ ARRÁBIDA"),
                },
                {
                  value: "HOSPITAL DA LUZ AVEIRO",
                  label: setCapitalize("HOSPITAL DA LUZ AVEIRO"),
                },
                {
                  value: "HOSPITAL DA LUZ CLÍNICA DA AMADORA",
                  label: setCapitalize("HOSPITAL DA LUZ CLÍNICA DA AMADORA"),
                },
                {
                  value: "HOSPITAL DA LUZ CLÍNICA DE ÁGUEDA",
                  label: setCapitalize("HOSPITAL DA LUZ CLÍNICA DE ÁGUEDA"),
                },
                {
                  value: "HOSPITAL DA LUZ CLÍNICA DE AMARANTE",
                  label: setCapitalize("HOSPITAL DA LUZ CLÍNICA DE AMARANTE"),
                },
                {
                  value: "HOSPITAL DA LUZ CLÍNICA DE CERVEIRA",
                  label: setCapitalize("HOSPITAL DA LUZ CLÍNICA DE CERVEIRA"),
                },
                {
                  value: "HOSPITAL DA LUZ CLÍNICA DE ODIVELAS",
                  label: setCapitalize("HOSPITAL DA LUZ CLÍNICA DE ODIVELAS"),
                },
                {
                  value: "HOSPITAL DA LUZ CLÍNICA DE OEIRAS",
                  label: setCapitalize("HOSPITAL DA LUZ CLÍNICA DE OEIRAS"),
                },
                {
                  value: "HOSPITAL DA LUZ CLÍNICA DE OIÃ",
                  label: setCapitalize("HOSPITAL DA LUZ CLÍNICA DE OIÃ"),
                },
                {
                  value: "HOSPITAL DA LUZ CLÍNICA DE VILA REAL",
                  label: setCapitalize("HOSPITAL DA LUZ CLÍNICA DE VILA REAL"),
                },
                {
                  value: "HOSPITAL DA LUZ CLÍNICA DO PORTO",
                  label: setCapitalize("HOSPITAL DA LUZ CLÍNICA DO PORTO"),
                },
                {
                  value: "HOSPITAL DA LUZ GUIMARÃES",
                  label: setCapitalize("HOSPITAL DA LUZ GUIMARÃES"),
                },
                {
                  value: "HOSPITAL DA LUZ LISBOA",
                  label: setCapitalize("HOSPITAL DA LUZ LISBOA"),
                },
                {
                  value: "HOSPITAL DA LUZ PÓVOA DE VARZIM",
                  label: setCapitalize("HOSPITAL DA LUZ PÓVOA DE VARZIM"),
                },
                {
                  value: "HOSPITAL DA LUZ SETÚBAL",
                  label: setCapitalize("HOSPITAL DA LUZ SETÚBAL"),
                },
                {
                  value: "HOSPITAL DA MARINHA",
                  label: setCapitalize("HOSPITAL DA MARINHA"),
                },
                {
                  value: "HOSPITAL DA MISERICÓRDIA DE ÉVORA",
                  label: setCapitalize("HOSPITAL DA MISERICÓRDIA DE ÉVORA"),
                },
                {
                  value: "HOSPITAL DA ORDEM TERCEIRA SÃO FRANCISCO DO PORTO",
                  label: setCapitalize(
                    "HOSPITAL DA ORDEM TERCEIRA SÃO FRANCISCO DO PORTO"
                  ),
                },
                {
                  value: "HOSPITAL DA PRELADA",
                  label: setCapitalize("HOSPITAL DA PRELADA"),
                },
                {
                  value:
                    "HOSPITAL DA SANTA CASA DA MISERICORDIA DE PONTE DA BARCA",
                  label: setCapitalize(
                    "HOSPITAL DA SANTA CASA DA MISERICORDIA DE PONTE DA BARCA"
                  ),
                },
                {
                  value: "HOSPITAL DA V. ORDEM TERCEIRA",
                  label: setCapitalize("HOSPITAL DA V. ORDEM TERCEIRA"),
                },
                {
                  value: "HOSPITAL DAS FORÇAS ARMADAS/ FORÇA AÉREA",
                  label: setCapitalize(
                    "HOSPITAL DAS FORÇAS ARMADAS/ FORÇA AÉREA"
                  ),
                },
                {
                  value: "HOSPITAL DE ALCANENA",
                  label: setCapitalize("HOSPITAL DE ALCANENA"),
                },
                {
                  value: "HOSPITAL DE BRAGA",
                  label: setCapitalize("HOSPITAL DE BRAGA"),
                },
                {
                  value: "HOSPITAL DE CASCAIS (PPP)",
                  label: setCapitalize("HOSPITAL DE CASCAIS (PPP)"),
                },
                {
                  value: "HOSPITAL DE CASCAIS DR. JOSÉ DE ALMEIDA",
                  label: setCapitalize(
                    "HOSPITAL DE CASCAIS DR. JOSÉ DE ALMEIDA"
                  ),
                },
                {
                  value: "HOSPITAL DE JESUS",
                  label: setCapitalize("HOSPITAL DE JESUS"),
                },
                {
                  value: "HOSPITAL DE LOULÉ",
                  label: setCapitalize("HOSPITAL DE LOULÉ"),
                },
                {
                  value: "HOSPITAL DE MAGALHÃES  LEMOS",
                  label: setCapitalize("HOSPITAL DE MAGALHÃES  LEMOS"),
                },
                {
                  value: "HOSPITAL DE SANTA CRUZ",
                  label: setCapitalize("HOSPITAL DE SANTA CRUZ"),
                },
                {
                  value: "Hospital de Santarém",
                  label: setCapitalize("Hospital de Santarém"),
                },
                {
                  value: "HOSPITAL DE SÃO LUÍS - LISBOA",
                  label: setCapitalize("HOSPITAL DE SÃO LUÍS - LISBOA"),
                },
                {
                  value: "HOSPITAL DISTRITAL DA FIGUEIRA DA FOZ, E.P.E.",
                  label: setCapitalize(
                    "HOSPITAL DISTRITAL DA FIGUEIRA DA FOZ, E.P.E."
                  ),
                },
                {
                  value: "HOSPITAL DISTRITAL DE ÁGUEDA",
                  label: setCapitalize("HOSPITAL DISTRITAL DE ÁGUEDA"),
                },
                {
                  value: "HOSPITAL DISTRITAL DE CHAVES",
                  label: setCapitalize("HOSPITAL DISTRITAL DE CHAVES"),
                },
                {
                  value: "HOSPITAL DISTRITAL DE LAMEGO",
                  label: setCapitalize("HOSPITAL DISTRITAL DE LAMEGO"),
                },
                {
                  value: "HOSPITAL DISTRITAL DE S. JOÃO DA MADEIRA",
                  label: setCapitalize(
                    "HOSPITAL DISTRITAL DE S. JOÃO DA MADEIRA"
                  ),
                },
                {
                  value: "HOSPITAL DISTRITAL DE SANTARÉM, E.P.E.",
                  label: setCapitalize(
                    "HOSPITAL DISTRITAL DE SANTARÉM, E.P.E."
                  ),
                },
                {
                  value: "HOSPITAL DISTRITAL DO FUNDÃO",
                  label: setCapitalize("HOSPITAL DISTRITAL DO FUNDÃO"),
                },
                {
                  value: "HOSPITAL DISTRITAL DO POMBAL",
                  label: setCapitalize("HOSPITAL DISTRITAL DO POMBAL"),
                },
                {
                  value: "HOSPITAL DIVINO ESPIRITO SANTO | PONTA DELGADA",
                  label: setCapitalize(
                    "HOSPITAL DIVINO ESPIRITO SANTO | PONTA DELGADA"
                  ),
                },
                {
                  value: "HOSPITAL DO ENTRONCAMENTO",
                  label: setCapitalize("HOSPITAL DO ENTRONCAMENTO"),
                },
                {
                  value: "HOSPITAL DO LITORAL ALENTEJANO",
                  label: setCapitalize("HOSPITAL DO LITORAL ALENTEJANO"),
                },
                {
                  value: "HOSPITAL DOS SAMS - LISBOA",
                  label: setCapitalize("HOSPITAL DOS SAMS - LISBOA"),
                },
                {
                  value: "HOSPITAL DOUTOR JOSÉ MARIA GRANDE - PORTALEGRE",
                  label: setCapitalize(
                    "HOSPITAL DOUTOR JOSÉ MARIA GRANDE - PORTALEGRE"
                  ),
                },
                {
                  value: "HOSPITAL PROF. DOUTOR FERNANDO FONSECA, E.P.E",
                  label: "Hospital Prof. Doutor Fernando Fonseca, E.P.E",
                },
                {
                  value: "HOSPITAL DR. FRANCISCO ZAGALO-OVAR",
                  label: setCapitalize("HOSPITAL DR. FRANCISCO ZAGALO-OVAR"),
                },
                {
                  value: "HOSPITAL DR. NÉLIO MENDONÇA - FUNCHAL",
                  label: setCapitalize("HOSPITAL DR. NÉLIO MENDONÇA - FUNCHAL"),
                },
                {
                  value: "HOSPITAL ESPÍRITO SANTO-ÉVORA",
                  label: setCapitalize("HOSPITAL ESPÍRITO SANTO-ÉVORA"),
                },
                {
                  value: "HOSPITAL GARCIA DE ORTA, E.P.E.",
                  label: setCapitalize("HOSPITAL GARCIA DE ORTA, E.P.E."),
                },
                {
                  value:
                    "HOSPITAL GERAL COLÓNIA PORTUGUESA DO BRASIL - C.H.CCOIMBRA",
                  label: setCapitalize(
                    "HOSPITAL GERAL COLÓNIA PORTUGUESA DO BRASIL - C.H.CCOIMBRA"
                  ),
                },
                {
                  value: "HOSPITAL INFANTE D. PEDRO, E.P.E.",
                  label: setCapitalize("HOSPITAL INFANTE D. PEDRO, E.P.E."),
                },
                {
                  value:
                    "HOSPITAL INFANTIL SÃO JOÃO DE DEUS - MONTEMOR -O-NOVO",
                  label: setCapitalize(
                    "HOSPITAL INFANTIL SÃO JOÃO DE DEUS - MONTEMOR -O-NOVO"
                  ),
                },
                {
                  value: "HOSPITAL INTERNACIONAL DOS AÇORES",
                  label: setCapitalize("HOSPITAL INTERNACIONAL DOS AÇORES"),
                },
                {
                  value: "HOSPITAL JOAQUIM URBANO",
                  label: setCapitalize("HOSPITAL JOAQUIM URBANO"),
                },
                {
                  value: "HOSPITAL JOSÉ LUCIANO DE CASTRO - ANADIA",
                  label: setCapitalize(
                    "HOSPITAL JOSÉ LUCIANO DE CASTRO - ANADIA"
                  ),
                },
                {
                  value: "HOSPITAL LUSÍADAS ALBUFEIRA",
                  label: setCapitalize("HOSPITAL LUSÍADAS ALBUFEIRA"),
                },
                {
                  value: "HOSPITAL LUSÍADAS AMADORA",
                  label: setCapitalize("HOSPITAL LUSÍADAS AMADORA"),
                },
                {
                  value: "HOSPITAL LUSÍADAS LISBOA",
                  label: setCapitalize("HOSPITAL LUSÍADAS LISBOA"),
                },
                {
                  value: "HOSPITAL LUSÍADAS PORTO",
                  label: setCapitalize("HOSPITAL LUSÍADAS PORTO"),
                },
                {
                  value: "HOSPITAL MILITAR PRINCIPAL",
                  label: setCapitalize("HOSPITAL MILITAR PRINCIPAL"),
                },
                {
                  value: "HOSPITAL MILITAR REGIONAL 4",
                  label: setCapitalize("HOSPITAL MILITAR REGIONAL 4"),
                },
                {
                  value: "HOSPITAL MILITAR REGIONAL Nº1",
                  label: setCapitalize("HOSPITAL MILITAR REGIONAL Nº1"),
                },
                {
                  value: "HOSPITAL MILITAR REGIONAL Nº2",
                  label: setCapitalize("HOSPITAL MILITAR REGIONAL Nº2"),
                },
                {
                  value: "HOSPITAL NARCISO FERREIRA",
                  label: setCapitalize("HOSPITAL NARCISO FERREIRA"),
                },
                {
                  value: "HOSPITAL NOSSA SENHORA DA AJUDA-ESPINHO",
                  label: setCapitalize(
                    "HOSPITAL NOSSA SENHORA DA AJUDA-ESPINHO"
                  ),
                },
                {
                  value: "HOSPITAL NOSSA SENHORA DA ARRÁBIDA",
                  label: setCapitalize("HOSPITAL NOSSA SENHORA DA ARRÁBIDA"),
                },
                {
                  value: "HOSPITAL NOSSA SENHORA DA ASSUNÇÃO - SEIA",
                  label: setCapitalize(
                    "HOSPITAL NOSSA SENHORA DA ASSUNÇÃO - SEIA"
                  ),
                },
                {
                  value: "HOSPITAL NOSSA SENHORA DA CONCEIÇÃO DE VALONGO",
                  label: setCapitalize(
                    "HOSPITAL NOSSA SENHORA DA CONCEIÇÃO DE VALONGO"
                  ),
                },
                {
                  value: "HOSPITAL NOSSA SENHORA DA GUIA - AVELAR",
                  label: setCapitalize(
                    "HOSPITAL NOSSA SENHORA DA GUIA - AVELAR"
                  ),
                },
                {
                  value: "HOSPITAL NOSSA SENHORA DO ROSÁRIO, E.P.E.",
                  label: setCapitalize(
                    "HOSPITAL NOSSA SENHORA DO ROSÁRIO, E.P.E."
                  ),
                },
                {
                  value: "HOSPITAL ORDEM DA LAPA",
                  label: setCapitalize("HOSPITAL ORDEM DA LAPA"),
                },
                {
                  value: "HOSPITAL ORDEM DO CARMO - PORTO",
                  label: setCapitalize("HOSPITAL ORDEM DO CARMO - PORTO"),
                },
                {
                  value: "HOSPITAL ORDEM TERCEIRA DA SS TRINDADE PORTO",
                  label: setCapitalize(
                    "HOSPITAL ORDEM TERCEIRA DA SS TRINDADE PORTO"
                  ),
                },
                {
                  value: "HOSPITAL ORTOPEDICO SANTANA",
                  label: setCapitalize("HOSPITAL ORTOPEDICO SANTANA"),
                },
                {
                  value: "HOSPITAL PARTICULAR DA MADEIRA",
                  label: setCapitalize("HOSPITAL PARTICULAR DA MADEIRA"),
                },
                {
                  value: "HOSPITAL PARTICULAR DE ALMADA",
                  label: setCapitalize("HOSPITAL PARTICULAR DE ALMADA"),
                },
                {
                  value: "HOSPITAL PARTICULAR DE LISBOA",
                  label: setCapitalize("HOSPITAL PARTICULAR DE LISBOA"),
                },
                {
                  value: "HOSPITAL PARTICULAR DO ALGARVE",
                  label: setCapitalize("HOSPITAL PARTICULAR DO ALGARVE"),
                },
                {
                  value: "HOSPITAL PRISIONAL S. JOÃO DE DEUS",
                  label: setCapitalize("HOSPITAL PRISIONAL S. JOÃO DE DEUS"),
                },
                {
                  value: "HOSPITAL PRIVADO DA BOAVISTA - HPP PORTO",
                  label: setCapitalize(
                    "HOSPITAL PRIVADO DA BOAVISTA - HPP PORTO"
                  ),
                },
                {
                  value: "HOSPITAL PRIVADO DOS CLÉRIGOS - HPP - PORTO",
                  label: setCapitalize(
                    "HOSPITAL PRIVADO DOS CLÉRIGOS - HPP - PORTO"
                  ),
                },
                {
                  value: "HOSPITAL PULIDO VALENTE, E.P.E.",
                  label: setCapitalize("HOSPITAL PULIDO VALENTE, E.P.E."),
                },
                {
                  value: "HOSPITAL REYNALDO DOS SANTOS-VILA FRANCA DE XIRA",
                  label: setCapitalize(
                    "HOSPITAL REYNALDO DOS SANTOS-VILA FRANCA DE XIRA"
                  ),
                },
                {
                  value: "HOSPITAL S. PEDRO PESCADOR",
                  label: setCapitalize("HOSPITAL S. PEDRO PESCADOR"),
                },
                {
                  value: "HOSPITAL SANTA CASA DA MISERICÓRIA DE BENAVENTE",
                  label: setCapitalize(
                    "HOSPITAL SANTA CASA DA MISERICÓRIA DE BENAVENTE"
                  ),
                },
                {
                  value: "HOSPITAL SANTA CECÍLIA",
                  label: setCapitalize("HOSPITAL SANTA CECÍLIA"),
                },
                {
                  value: "HOSPITAL SANTA LUZIA DE ELVAS",
                  label: setCapitalize("HOSPITAL SANTA LUZIA DE ELVAS"),
                },
                {
                  value: "HOSPITAL SANTA MARIA",
                  label: setCapitalize("HOSPITAL SANTA MARIA"),
                },
                {
                  value: "HOSPITAL SANTA MARIA (PORTO)",
                  label: setCapitalize("HOSPITAL SANTA MARIA (PORTO)"),
                },
                {
                  value: "HOSPITAL SANTA MARIA MAIOR, E.P.E.",
                  label: setCapitalize("HOSPITAL SANTA MARIA MAIOR, E.P.E."),
                },
                {
                  value: "HOSPITAL SANTA MARTA, E.P.E.",
                  label: setCapitalize("HOSPITAL SANTA MARTA, E.P.E."),
                },
                {
                  value: "HOSPITAL SANTO ANTÓNIO E.P.E. - PORTO",
                  label: setCapitalize("HOSPITAL SANTO ANTÓNIO E.P.E. - PORTO"),
                },
                {
                  value: "HOSPITAL SANTO ESPIRITO | ILHA TERCEIRA",
                  label: setCapitalize(
                    "HOSPITAL SANTO ESPIRITO | ILHA TERCEIRA"
                  ),
                },
                {
                  value: "HOSPITAL SÃO MIGUEL - OLIVEIRA DE AZEMEIS",
                  label: setCapitalize(
                    "HOSPITAL SÃO MIGUEL - OLIVEIRA DE AZEMEIS"
                  ),
                },
                {
                  value: "HOSPITAL SÃO PEDRO GONÇALVES TELMO-PENICHE",
                  label: setCapitalize(
                    "HOSPITAL SÃO PEDRO GONÇALVES TELMO-PENICHE"
                  ),
                },
                {
                  value: "HOSPITAL SÃO SEBASTIÃO, E.P.E.",
                  label: setCapitalize("HOSPITAL SÃO SEBASTIÃO, E.P.E."),
                },
                {
                  value: "HOSPITAL SÃO TEOTÓNIO, E.P.E.",
                  label: setCapitalize("HOSPITAL SÃO TEOTÓNIO, E.P.E."),
                },
                {
                  value: "HOSPITAL SENHORA DA OLIVEIRA - GUIMARÃES",
                  label: setCapitalize(
                    "HOSPITAL SENHORA DA OLIVEIRA - GUIMARÃES"
                  ),
                },
                {
                  value: "HOSPITAL SOUSA MARTINS - GUARDA",
                  label: setCapitalize("HOSPITAL SOUSA MARTINS - GUARDA"),
                },
                {
                  value: "HOSPITAL VALENTIM RIBEIRO",
                  label: setCapitalize("HOSPITAL VALENTIM RIBEIRO"),
                },
                {
                  value: "HOSPITAL VENERÁVEL IRMANDADE N.S. DA LAPA - PORTO",
                  label: setCapitalize(
                    "HOSPITAL VENERÁVEL IRMANDADE N.S. DA LAPA - PORTO"
                  ),
                },
                {
                  value:
                    "HOSPITAL VENERÁVEL IRMANDADE N.S. DO TERÇO DE CARIDADE - PORTO",
                  label: setCapitalize(
                    "HOSPITAL VENERÁVEL IRMANDADE N.S. DO TERÇO DE CARIDADE - PORTO"
                  ),
                },
                {
                  value: "HOSPITAL VISCONDE DE SALREU-ESTARREJA",
                  label: setCapitalize("HOSPITAL VISCONDE DE SALREU-ESTARREJA"),
                },
                {
                  value: "HOSPITAL VILA FRANCA DE XIRA",
                  label: "HOSPITAL VILA FRANCA DE XIRA",
                },
                {
                  value: "INSTITUTO CUF DE ONCOLOGIA - CLUSTER TEJO",
                  label: setCapitalize(
                    "INSTITUTO CUF DE ONCOLOGIA - CLUSTER TEJO"
                  ),
                },
                {
                  value: "INSTITUTO PORTUGUÊS DE ONCOLOGIA COIMBRA",
                  label: setCapitalize(
                    "INSTITUTO PORTUGUÊS DE ONCOLOGIA COIMBRA"
                  ),
                },
                {
                  value: "INSTITUTO PORTUGUÊS DE ONCOLOGIA LISBOA",
                  label: setCapitalize(
                    "INSTITUTO PORTUGUÊS DE ONCOLOGIA LISBOA"
                  ),
                },
                {
                  value: "INSTITUTO PORTUGUÊS DE ONCOLOGIA PORTO",
                  label: setCapitalize(
                    "INSTITUTO PORTUGUÊS DE ONCOLOGIA PORTO"
                  ),
                },
                {
                  value: "INSTITUTO PORTUGUÊS DE RITMO CARDÍACO",
                  label: setCapitalize("INSTITUTO PORTUGUÊS DE RITMO CARDÍACO"),
                },
                { value: "IPATIMUP", label: "IPATIMUP" },
                { value: "IPL / ESTeSL", label: setCapitalize("IPL / ESTeSL") },
                { value: "IMP DIAGNOSTICS", label: "IMP Diagnostics" },
                {
                  value: "JOAQUIM CHAVES SAÚDE - CASA DE SAÚDE DE CARNAXIDE",
                  label: setCapitalize(
                    "JOAQUIM CHAVES SAÚDE - CASA DE SAÚDE DE CARNAXIDE"
                  ),
                },
                {
                  value:
                    "JOAQUIM CHAVES SAÚDE - CENTRO ONCOLÓGICO DRA. NATÁLIA CHAVES",
                  label: setCapitalize(
                    "JOAQUIM CHAVES SAÚDE - CENTRO ONCOLÓGICO DRA. NATÁLIA CHAVES"
                  ),
                },
                {
                  value: "JOAQUIM CHAVES SAÚDE - CLINICA DE CARCAVELOS",
                  label: setCapitalize(
                    "JOAQUIM CHAVES SAÚDE - CLINICA DE CARCAVELOS"
                  ),
                },
                {
                  value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE CASCAIS",
                  label: setCapitalize(
                    "JOAQUIM CHAVES SAÚDE - CLÍNICA DE CASCAIS"
                  ),
                },
                {
                  value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE ENTRECAMPOS",
                  label: setCapitalize(
                    "JOAQUIM CHAVES SAÚDE - CLÍNICA DE ENTRECAMPOS"
                  ),
                },
                {
                  value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE FARO",
                  label: setCapitalize(
                    "JOAQUIM CHAVES SAÚDE - CLÍNICA DE FARO"
                  ),
                },
                {
                  value:
                    "JOAQUIM CHAVES SAÚDE - CLÍNICA DE GASTROENTEROLOGIA DE FARO",
                  label: setCapitalize(
                    "JOAQUIM CHAVES SAÚDE - CLÍNICA DE GASTROENTEROLOGIA DE FARO"
                  ),
                },
                {
                  value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE MIRAFLORES",
                  label: setCapitalize(
                    "JOAQUIM CHAVES SAÚDE - CLÍNICA DE MIRAFLORES"
                  ),
                },
                {
                  value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE OEIRAS",
                  label: setCapitalize(
                    "JOAQUIM CHAVES SAÚDE - CLÍNICA DE OEIRAS"
                  ),
                },
                {
                  value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE OLHÃO",
                  label: setCapitalize(
                    "JOAQUIM CHAVES SAÚDE - CLÍNICA DE OLHÃO"
                  ),
                },
                {
                  value:
                    "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIOLOGIA DE CASCAIS",
                  label: setCapitalize(
                    "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIOLOGIA DE CASCAIS"
                  ),
                },
                {
                  value:
                    "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIOLOGIA DE VILA FRANCA DE XIRA",
                  label: setCapitalize(
                    "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIOLOGIA DE VILA FRANCA DE XIRA"
                  ),
                },
                {
                  value:
                    "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DA MADEIRA",
                  label: setCapitalize(
                    "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DA MADEIRA"
                  ),
                },
                {
                  value:
                    "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DE SANTARÉM",
                  label: setCapitalize(
                    "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DE SANTARÉM"
                  ),
                },
                {
                  value:
                    "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DO ALGARVE",
                  label: setCapitalize(
                    "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DO ALGARVE"
                  ),
                },
                {
                  value:
                    "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DO PORTO",
                  label: setCapitalize(
                    "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DO PORTO"
                  ),
                },
                {
                  value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE SINTRA",
                  label: setCapitalize(
                    "JOAQUIM CHAVES SAÚDE - CLÍNICA DE SINTRA"
                  ),
                },
                {
                  value:
                    "JOAQUIM CHAVES SAÚDE - DR. JOAQUIM CHAVES, LABORATÓRIO DE ANÁLISE",
                  label: setCapitalize(
                    "JOAQUIM CHAVES SAÚDE - DR. JOAQUIM CHAVES, LABORATÓRIO DE ANÁLISES CLÍNICAS"
                  ),
                },
                {
                  value:
                    "JOAQUIM CHAVES SAÚDE - DR. JOAQUIM CHAVES, LABORATÓRIO DE ANÁLISE",
                  label: setCapitalize(
                    "JOAQUIM CHAVES SAÚDE - DR. JOAQUIM CHAVES, LABORATÓRIO DE ANÁLISES CLÍNICAS ALGARVE"
                  ),
                },
                {
                  value:
                    "JOAQUIM CHAVES SAÚDE - DR. JOAQUIM CHAVES, LABORATÓRIO DE ANATOMIA PATOLÓGICA",
                  label: setCapitalize(
                    "JOAQUIM CHAVES SAÚDE - DR. JOAQUIM CHAVES, LABORATÓRIO DE ANATOMIA PATOLÓGICA"
                  ),
                },
                {
                  value: "JOSE MELLO SAÚDE",
                  label: setCapitalize("JOSE MELLO SAÚDE"),
                },
                {
                  value: "JÚLIO TEIXEIRA, SA",
                  label: setCapitalize("JÚLIO TEIXEIRA, SA"),
                },
                { value: "LENICARE", label: setCapitalize("LENICARE") },
                {
                  value: "LENITUDES MEDICAL CENTER",
                  label: setCapitalize("LENITUDES MEDICAL CENTER"),
                },
                {
                  value:
                    "LISBON UNITED KINGDOM HOSPITAL, SERVIÇOS DE SAÚDE, S.ª",
                  label: setCapitalize(
                    "LISBON UNITED KINGDOM HOSPITAL, SERVIÇOS DE SAÚDE, S.ª"
                  ),
                },
                {
                  value: "MATERNIDADE DR. ALFREDO DA COSTA",
                  label: setCapitalize("MATERNIDADE DR. ALFREDO DA COSTA"),
                },
                {
                  value: "MATERNIDADE DR. DANIEL DE MATOS",
                  label: setCapitalize("MATERNIDADE DR. DANIEL DE MATOS"),
                },
                { value: "NEPHROCARE", label: setCapitalize("NEPHROCARE") },
                {
                  value: "OFFICIAL AGENCY",
                  label: setCapitalize("OFFICIAL AGENCY"),
                },
                { value: "Reino Unido", label: setCapitalize("Reino Unido") },
                {
                  value:
                    "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE ALMEIRIM",
                  label: setCapitalize(
                    "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE ALMEIRIM"
                  ),
                },
                {
                  value:
                    "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE AVINTES",
                  label: setCapitalize(
                    "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE AVINTES"
                  ),
                },
                {
                  value:
                    "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE ESTREMOZ",
                  label: setCapitalize(
                    "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE ESTREMOZ"
                  ),
                },
                {
                  value: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE MORA",
                  label: setCapitalize(
                    "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE MORA"
                  ),
                },
                {
                  value:
                    "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS FAMALICÃO",
                  label: setCapitalize(
                    "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS FAMALICÃO"
                  ),
                },
                {
                  value:
                    "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS JUIZ DE FORA",
                  label: setCapitalize(
                    "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS JUIZ DE FORA"
                  ),
                },
                {
                  value: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS PORTIMÃO",
                  label: setCapitalize(
                    "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS PORTIMÃO"
                  ),
                },
                {
                  value:
                    "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS RAINHA DONA LEONOR",
                  label: setCapitalize(
                    "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS RAINHA DONA LEONOR"
                  ),
                },
                {
                  value: "UNIDADE DE SAÚDE FAMILIAR AGUDA",
                  label: setCapitalize("UNIDADE DE SAÚDE FAMILIAR AGUDA"),
                },
                {
                  value: "UNIDADE DE SAÚDE FAMILIAR ALVES MARTINS",
                  label: setCapitalize(
                    "UNIDADE DE SAÚDE FAMILIAR ALVES MARTINS"
                  ),
                },
                {
                  value: "UNIDADE DE SAÚDE FAMILIAR BRIOSA",
                  label: setCapitalize("UNIDADE DE SAÚDE FAMILIAR BRIOSA"),
                },
                {
                  value: "UNIDADE DE SAÚDE FAMILIAR CELAS SAÚDE",
                  label: setCapitalize("UNIDADE DE SAÚDE FAMILIAR CELAS SAÚDE"),
                },
                {
                  value: "UNIDADE DE SAÚDE FAMILIAR COVA DA PIEDADE",
                  label: setCapitalize(
                    "UNIDADE DE SAÚDE FAMILIAR COVA DA PIEDADE"
                  ),
                },
                {
                  value: "UNIDADE DE SAÚDE FAMILIAR CSI SEIXAL",
                  label: setCapitalize("UNIDADE DE SAÚDE FAMILIAR CSI SEIXAL"),
                },
                {
                  value: "UNIDADE DE SAÚDE FAMILIAR DE SANTIAGO-LEIRIA",
                  label: setCapitalize(
                    "UNIDADE DE SAÚDE FAMILIAR DE SANTIAGO-LEIRIA"
                  ),
                },
                {
                  value: "UNIDADE DE SAÚDE FAMILIAR DE TORNADA",
                  label: setCapitalize("UNIDADE DE SAÚDE FAMILIAR DE TORNADA"),
                },
                {
                  value: "UNIDADE DE SAÚDE FAMILIAR DE VALBOM",
                  label: setCapitalize("UNIDADE DE SAÚDE FAMILIAR DE VALBOM"),
                },
                {
                  value: "UNIDADE DE SAÚDE FAMILIAR DE VALONGO",
                  label: setCapitalize("UNIDADE DE SAÚDE FAMILIAR DE VALONGO"),
                },
                {
                  value: "UNIDADE DE SAÚDE FAMILIAR DO MAR",
                  label: setCapitalize("UNIDADE DE SAÚDE FAMILIAR DO MAR"),
                },
                {
                  value: "UNIDADE DE SAÚDE FAMILIAR LAVRADIO",
                  label: setCapitalize("UNIDADE DE SAÚDE FAMILIAR LAVRADIO"),
                },
                {
                  value: "UNIDADE DE SAÚDE FAMILIAR LUSITÂNIA",
                  label: setCapitalize("UNIDADE DE SAÚDE FAMILIAR LUSITÂNIA"),
                },
                {
                  value: "UNIDADE DE SAÚDE FAMILIAR MACTAMÃ",
                  label: setCapitalize("UNIDADE DE SAÚDE FAMILIAR MACTAMÃ"),
                },
                {
                  value: "UNIDADE DE SAÚDE FAMILIAR RAMADA",
                  label: setCapitalize("UNIDADE DE SAÚDE FAMILIAR RAMADA"),
                },
                {
                  value: "UNIDADE DE SAÚDE FAMILIAR SANTO ANTÓNIO DA CHARNECA",
                  label: setCapitalize(
                    "UNIDADE DE SAÚDE FAMILIAR SANTO ANTÓNIO DA CHARNECA"
                  ),
                },
                {
                  value: "UNIDADE DE SAÚDE FAMILIAR SÃO GONÇALO",
                  label: setCapitalize("UNIDADE DE SAÚDE FAMILIAR SÃO GONÇALO"),
                },
                {
                  value: "UNIDADE DE SAÚDE FAMILIAR SENHORA DA LAPA",
                  label: setCapitalize(
                    "UNIDADE DE SAÚDE FAMILIAR SENHORA DA LAPA"
                  ),
                },
                {
                  value: "UNIDADE DE SAÚDE FAMILIAR TERRAS AZURARA",
                  label: setCapitalize(
                    "UNIDADE DE SAÚDE FAMILIAR TERRAS AZURARA"
                  ),
                },
                {
                  value: "UNIDADE DE SAÚDE FAMILIAR VALE DO VEZ",
                  label: setCapitalize("UNIDADE DE SAÚDE FAMILIAR VALE DO VEZ"),
                },
                {
                  value: "UNIDADE DE SAÚDE FAMILIAR VILLA LONGA",
                  label: setCapitalize("UNIDADE DE SAÚDE FAMILIAR VILLA LONGA"),
                },
                {
                  value: "UNIDADE DE SAÚDE PÚBLICA DE ÉVORA",
                  label: setCapitalize("UNIDADE DE SAÚDE PÚBLICA DE ÉVORA"),
                },
                {
                  value: "UNIDADE LOCAL DE SAÚDE DA GUARDA, EPE",
                  label: setCapitalize("UNIDADE LOCAL DE SAÚDE DA GUARDA, EPE"),
                },
                {
                  value: "UNIDADE LOCAL DE SAÚDE DE MATOSINHOS, S.A",
                  label: setCapitalize(
                    "UNIDADE LOCAL DE SAÚDE DE MATOSINHOS, S.A"
                  ),
                },
                {
                  value: "UNIDADE LOCAL DE SAÚDE DO ALTO MINHO",
                  label: setCapitalize("UNIDADE LOCAL DE SAÚDE DO ALTO MINHO"),
                },
                {
                  value: "UNIDADE LOCAL DE SAÚDE DO BAIXO ALENTEJO",
                  label: setCapitalize(
                    "UNIDADE LOCAL DE SAÚDE DO BAIXO ALENTEJO"
                  ),
                },
                {
                  value: "UNIDADE LOCAL DE SAÚDE DO LITORAL ALENTEJANO",
                  label: setCapitalize(
                    "UNIDADE LOCAL DE SAÚDE DO LITORAL ALENTEJANO"
                  ),
                },
                {
                  value: "UNIDADE LOCAL DE SAÚDE DO NORDESTE, E.P.E.",
                  label: setCapitalize(
                    "UNIDADE LOCAL DE SAÚDE DO NORDESTE, E.P.E."
                  ),
                },
                {
                  value: "UNIDADE LOCAL DE SAÚDE DO NORTE ALENTEJANO",
                  label: setCapitalize(
                    "UNIDADE LOCAL DE SAÚDE DO NORTE ALENTEJANO"
                  ),
                },
                { value: "Unilabs - LAP PORTO", label: "Unilabs - LAP Porto" },
                { value: "Synlab Porto", label: "Synlab Porto" },
                { value: "Outro", label: setCapitalize("Outro") },
              ]}
            />{" "}
          </label>
          <Row gutter={[8, 8]} align="bottom" style={{ marginTop: "10px" }}>
            <Col span={12}>
              <Button
                style={{
                  width: "100%",
                  height: "45px",
                }}
                onClick={() => prev()}
              >
                Anterior
              </Button>
            </Col>
            <Col span={12}>
              <Button
                className="next-btn"
                style={{
                  width: "100%",
                  height: "45px",
                }}
                onClick={() => next()}
              >
                Próximo
              </Button>
            </Col>
          </Row>
        </>
      )}

      {current == 2 && tipoUtilizador != "nao_socio" && (
        <>
          <label>
            Nome/entidade para faturação *
            <input
              type="text"
              name="nif"
              placeholder="Nome/entidade para faturação "
              defaultValue={nomeFaturacao}
              onChange={(e) => setNomeFaturacao(e.target.value)}
              required
            />
          </label>
          <label>
            NIF para faturação *
            <input
              type="text"
              name="nif"
              placeholder="NIF para faturação"
              minLength={9}
              maxLength={9}
              defaultValue={contribuinte}
              onChange={(e) => setContribuinte(e.target.value)}
              required
            />
          </label>
          <Row gutter={[8, 8]} align="bottom" style={{ marginTop: "10px" }}>
            <Col span={12}>
              <Button
                style={{
                  width: "100%",
                  height: "45px",
                }}
                onClick={() => prev()}
              >
                Anterior
              </Button>
            </Col>
            <Col span={12}>
              <Button
                className="next-btn"
                style={{
                  width: "100%",
                  height: "45px",
                }}
                onClick={() => next()}
              >
                Próximo
              </Button>
            </Col>
          </Row>
        </>
      )}

      {current == steps.length - 1 && (
        <>
          <label>
            Email *
            <input
              type="email"
              name="email"
              placeholder="Email"
              defaultValue={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </label>

          <label>
            <i>Password</i> *
            <input
              type="password"
              name="password"
              placeholder="Password"
              defaultValue={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </label>
          <label>
            <i>Confirmar Password</i> *
            <input
              type="password"
              name="confirm_password"
              placeholder="Password"
              defaultValue={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </label>
          <p className="left">
            <input type="checkbox" name="confirm_chk" required />
            Concordar com os{" "}
            <Link to="/termos-e-condicoes" target="_blank">
              termos e condições
            </Link>
          </p>
          {hasError && (
            <div
              style={{
                padding: "10px",
                background: "#ffc3c3",
                border: "1px solid #ffc3c3",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <ExclamationCircleOutlined twoToneColor="red" />{" "}
              <span style={{ paddingLeft: "10px", fontSize: "14px" }}>
                {errorMessage}
              </span>
            </div>
          )}
          {hasSuccess && (
            <div
              style={{
                padding: "10px",
                background: "#a1f9ab",
                border: "1px solid #a1f9ab",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <CheckCircleTwoTone twoToneColor="green" />{" "}
              <span style={{ paddingLeft: "10px", fontSize: "14px" }}>
                O registo foi feito com sucesso! Será redirecionado dentro de
                momentos.
              </span>
            </div>
          )}
          <Row gutter={[8, 8]} align="bottom" style={{ marginTop: "10px" }}>
            <Col span={12}>
              <Button
                style={{
                  width: "100%",
                  height: "45px",
                }}
                onClick={() => prev()}
              >
                Anterior
              </Button>
            </Col>
            <Col span={12}>
              <input
                type="submit"
                name="submit"
                value="Registar"
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
}
