import React from 'react';
import { saveAs } from 'file-saver';
import { Button } from 'antd';
import * as XLSX from 'xlsx';

class ExportButton extends React.Component {
  handleExport = () => {
    const { data, columns, fileName } = this.props;
    const exportData = [];

    const headers = columns.map(column => column.title);
    exportData.push(headers);

    data.forEach(row => {
      const rowData = columns.map(column => {
        let value = row[column.dataIndex || column.key];
        if (typeof value === 'object' && value?.props && value.props?.children) {
          value = value?.props?.children;
        }
        return value;
      });
      exportData.push(rowData);
    });

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(exportData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

    const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
    const file = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
    const now = new Date();
    const timestamp = `${now.getFullYear()}${padNumber(now.getMonth() + 1)}${padNumber(now.getDate())}_${padNumber(now.getHours())}${padNumber(now.getMinutes())}`;
    const fileNameWithTimestamp = fileName ? `${fileName}_${timestamp}.xlsx` : `spap_${timestamp}.xlsx`;
    saveAs(file, fileNameWithTimestamp);
  };

  render() {
    return (
      <Button type='primary' className='cta' onClick={this.handleExport}>
        Exportar Tabela
      </Button>
    );
  }
}

function padNumber(number) {
  return String(number).padStart(2, '0');
}

export default ExportButton;