import styled from 'styled-components'

const Wrapper = styled.section`
  display: grid;
  grid-template-grid: 1fr;
  gap: 2.604vw;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  padding: 4.166vw 0;

  .item {
    .item__image {
      display: inline-flex;
      position: relative;
    }

    .item__overlay {
      position: absolute;
      background-color: #FFFFFF;
      opacity: .5;
      width: 100%;
      height: 100%;
      transition: .3s ease;
    }

    .item__content {
      padding: 1.041vw;
    }

    .item__date {
      color: #0C1151;
      font-size: 1.562vw;
      font-family: 'Fira Sans', sans-serif;
      font-weight: bold;
      letter-spacing: 0px;
      line-height: 1.875vw;
      text-align: center;
      margin-bottom: 1.041vw;
    }

    .item__name {
      color: #000000;
      font-size: 0.937vw;
      font-weight: bold;
      letter-spacing: 0px;
      line-height: 1.25vw;
      text-align: center;
      margin-bottom: 0.26vw;
    }

    .item__local {
      color: #000000;
      font: normal normal normal 16px/30px Roboto;
      font-size: 0.833vw;
      letter-spacing: 0px;
      line-height: 1.562vw;
      text-align: center;
      margin: 0;
    }
  }

  .item:hover .item__overlay {
    opacity: 0;
  }

  .item:nth-child(odd) {
    background-color: #D6D7E2;
  }

  .item:nth-child(even) {
    background-color: #E8E9F3;
  }

  ${props => props.className === 'row' && `
    grid-template-columns: 1fr;
    padding: 0;

    .item {
      padding: 0 7.291vw;
      display: grid;
      grid-template-columns: 16.041vw 1fr;
    }
  `}

  @media (min-width: 992px) {

    ${props => props.className === 'row' ? `
      .item {
        align-items: center;

        .item__date, .item__name, .item__local {
          text-align: left ;
        }
      }      
    ` : `
      .item:nth-child(odd) {
        margin-top: -2.083vw;
        padding-bottom: 1.045vw;
      }
    
      .item:nth-child(even) {
        margin-bottom: -2.083vw;
      }
    `}
  }

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);

    .item {
      .item__date {
        font-size: 11px;        
        line-height: 16px;
      }

      .item__name {
        font-size: 3.333vw;
        line-height: 4.444vw;
      }

      .item__local {
        font-size: 2.888vw;
        line-height: 4.444vw;
      }
    }

    ${props => props.className === 'row' && `
      grid-template-columns: 50vw;
      margin: auto;
      
      .item {
        border-radius: 8px;
        overflow: hidden;
        padding: 0;
        grid-template-columns: 1fr;
      }
    `}
  }

`

export default function Presidents( props ) {

  const ListItens = [
    {
      'imageDesktop' : require('../../Assets/Images/img-presidente-01@2x.png'),
      'imageMobile' : require('../../Assets/Images/img-presidente-01.png'),
      'imageAlt' : 'Prof. Doutor Amândio Tavares',
      'date' : '1963-1968',
      'name' : 'Prof. Doutor Amândio Tavares',
      'local' : 'Porto'
    },
    {
      'imageDesktop' : require('../../Assets/Images/img-presidente-02@2x.png'),
      'imageMobile' : require('../../Assets/Images/img-presidente-02.png'),
      'imageAlt' : 'Prof. Doutor Jorge Horta',
      'date' : '1968-1972',
      'name' : 'Prof. Doutor Jorge Horta',
      'local' : 'Lisboa'
    },
    {
      'imageDesktop' : require('../../Assets/Images/img-presidente-03@2x.png'),
      'imageMobile' : require('../../Assets/Images/img-presidente-03.png'),
      'imageAlt' : 'Prof. Doutor Renato Trincão',
      'date' : '1972-1973',
      'name' : 'Prof. Doutor Renato Trincão',
      'local' : 'Coimbra'
    },
    {
      'imageDesktop' : require('../../Assets/Images/img-presidente-04@2x.png'),
      'imageMobile' : require('../../Assets/Images/img-presidente-04.png'),
      'imageAlt' : 'Dr. J. Pereira Guedes',
      'date' : '1973-1978',
      'name' : 'Dr. J. Pereira Guedes',
      'local' : 'Porto'
    },
    {
      'imageDesktop' : require('../../Assets/Images/img-presidente-05@2x.png'),
      'imageMobile' : require('../../Assets/Images/img-presidente-05.png'),
      'imageAlt' : 'Prof. Doutor Daniel Serrão',
      'date' : '1978-1982',
      'name' : 'Prof. Doutor Daniel Serrão',
      'local' : 'Porto'
    },
    {
      'imageDesktop' : require('../../Assets/Images/img-presidente-06@2x.png'),
      'imageMobile' : require('../../Assets/Images/img-presidente-06.png'),
      'imageAlt' : 'Prof. Doutor A. Viegas Mendonça',
      'date' : '1982-1986',
      'name' : 'Prof. Doutor A. Viegas Mendonça',
      'local' : 'Lisboa'
    },
    {
      'imageDesktop' : require('../../Assets/Images/img-presidente-07@2x.png'),
      'imageMobile' : require('../../Assets/Images/img-presidente-07.png'),
      'imageAlt' : 'Dr. Rogério Gonzaga',
      'date' : '1986-1990',
      'name' : 'Dr. Rogério Gonzaga',
      'local' : 'Porto'
    },
    {
      'imageDesktop' : require('../../Assets/Images/img-presidente-08@2x.png'),
      'imageMobile' : require('../../Assets/Images/img-presidente-08.png'),
      'imageAlt' : 'Dra. Maria Irene Martins',
      'date' : '1991-1993',
      'name' : 'Dra. Maria Irene Martins',
      'local' : 'Coimbra'
    },
    {
      'imageDesktop' : require('../../Assets/Images/img-presidente-09@2x.png'),
      'imageMobile' : require('../../Assets/Images/img-presidente-09.png'),
      'imageAlt' : 'Prof. Doutor Carlos Lopes',
      'date' : '1993-1997',
      'name' : 'Prof. Doutor Carlos Lopes',
      'local' : 'Lisboa'
    },
    {
      'imageDesktop' : require('../../Assets/Images/img-presidente-10@2x.png'),
      'imageMobile' : require('../../Assets/Images/img-presidente-10.png'),
      'imageAlt' : 'Prof. Doutor Jorge Soares',
      'date' : '1997-1999',
      'name' : 'Prof. Doutor Jorge Soares',
      'local' : 'Lisboa'
    },
    {
      'imageDesktop' : require('../../Assets/Images/img-presidente-11@2x.png'),
      'imageMobile' : require('../../Assets/Images/img-presidente-11.png'),
      'imageAlt' : 'Prof. Doutor Afonso Fernandes',
      'date' : '1999-2001',
      'name' : 'Prof. Doutor Afonso Fernandes',
      'local' : 'Lisboa'
    },
    {
      'imageDesktop' : require('../../Assets/Images/img-presidente-12@2x.png'),
      'imageMobile' : require('../../Assets/Images/img-presidente-12.png'),
      'imageAlt' : 'Dr. Fernando Pardal',
      'date' : '2001-2004',
      'name' : 'Dr. Fernando Pardal',
      'local' : 'Braga'
    },
    {
      'imageDesktop' : require('../../Assets/Images/img-presidente-13@2x.png'),
      'imageMobile' : require('../../Assets/Images/img-presidente-13.png'),
      'imageAlt' : 'Dra. Maria Fernanda Xavier da Cunha',
      'date' : '2004-2007',
      'name' : 'Dra. Maria Fernanda Xavier da Cunha',
      'local' : 'Coimbra'
    },
    {
      'imageDesktop' : require('../../Assets/Images/img-presidente-14@2x.png'),
      'imageMobile' : require('../../Assets/Images/img-presidente-14.png'),
      'imageAlt' : 'Prof. Doutora Isabel Fonseca',
      'date' : '2007-2010',
      'name' : 'Prof. Doutora Isabel Fonseca',
      'local' : 'Lisboa'
    },
    {
      'imageDesktop' : require('../../Assets/Images/img-presidente-15@2x.png'),
      'imageMobile' : require('../../Assets/Images/img-presidente-15.png'),
      'imageAlt' : 'Prof. Doutor Rui Henrique',
      'date' : '2010 - 2013',
      'name' : 'Prof. Doutor Rui Henrique',
      'local' : 'Porto'
    },
    {
      'imageDesktop' : require('../../Assets/Images/img-presidente-16@2x.png'),
      'imageMobile' : require('../../Assets/Images/img-presidente-16.png'),
      'imageAlt' : 'Prof. Doutora Paula Borralho',
      'date' : '2013-2021',
      'name' : 'Prof. Doutora Paula Borralho',
      'local' : 'Lisboa'
    },
    {
      'imageDesktop' : require('../../Assets/Images/img-presidente-18@2x.png'),
      'imageMobile' : require('../../Assets/Images/img-presidente-18.png'),
      'imageAlt' : 'Dra. Maria Augusta Cipriano',
      'date' : '2021-2024',
      'name' : 'Dra. Maria Augusta Cipriano',
      'local' : 'Coimbra'
    }
  ]

  const ListItensFeatured = [
    {
      'imageDesktop' : require('../../Assets/Images/foto-carla-bartosch.jpg'),
      'imageMobile' : require('../../Assets/Images/foto-carla-bartosch.jpg'),
      'imageAlt' : 'Dra. Carla Bartosch',
      'date' : '2024-2027',
      'name' : 'Dra. Carla Bartosch',
      'local' : 'Porto'
    }
  ]
  
  return(
    <Wrapper>
      <Grid>
        {
          ListItens.map((item, index) => {
            return(
              <div className={'item item-' + index}>
                <picture className='item__image'>
                  <source srcSet={item.imageDesktop} media="(min-width: 990px)" />
                  <img src={item.imageMobile} alt={item.imageAlt} />
                  <div className='item__overlay' />
                </picture>
                <div className='item__content'>
                  <h5 className='item__date'>{item.date}</h5>
                  <h4 className='item__name'>{item.name}</h4>
                  <p className='item__local'>{item.local}</p>
                </div>
              </div>
            )
          })
        }
      </Grid>
      <Grid className='row'>
        {
          ListItensFeatured.map((item, index) => {
            return(
              <div className={'item item-' + index}>
                <picture className='item__image'>
                  <source srcSet={item.imageDesktop} media="(min-width: 990px)" />
                  <img src={item.imageMobile} alt={item.imageAlt} />
                  <div className='item__overlay' />
                </picture>
                <div className='item__content'>
                  <h5 className='item__date'>{item.date}</h5>
                  <h4 className='item__name'>{item.name}</h4>
                  <p className='item__local'>{item.local}</p>
                </div>
              </div>
            )
          })
        }
      </Grid>
    </Wrapper>
  )
}