import axios from "axios";
import authHeader from "./auth-header";
import authService from "./auth.service";

const API_URL = "https://api.spap.pt/api/user/";

class UserService {
  getPublicContent() {
    return axios.get(API_URL + "all");
  }

  getUserProfile() {
    return true;
    //  return axios.get(API_URL + 'profile', { headers: authHeader() });
  }

  getAdminProfile() {
    return axios
      .get("https://api.spap.pt/api/dashboard/perfil")
      .catch(function (error) {
        console.log(error);
      });
  }

  async updateProfile(accessToken, user_id, post_data) {
    const response = await axios
      .post(
        "https://api.spap.pt/api/update-profile",
        {
          user_id,
          post_data,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          return error.response;
        }
      });

    console.log(response);

    let storage = JSON.parse(localStorage.getItem("user"));

    if (response.data) {
      storage["user"] = response.data.user;
      localStorage.setItem("user", JSON.stringify(storage));
    }

    // if (response.data.token) {
    //     localStorage.setItem("user", JSON.stringify(response.data));
    // }

    return response;
  }

  // async uploadAvatar(image) {
  //     const response = await axios.post('https://api.spap.pt/api/upload-avatar', {
  //         'data' : image

  //     });

  //     console.log(response);

  // }

  // getAllQuotas() {

  //     // create a promise for the axios request
  //     const request = axios.get('https://api.spap.pt/api/list-quotas')
  //         .then((response) => {
  //             localStorage.setItem('quotas', response.data.quotas);
  //          });
  // }
}

export default new UserService();
