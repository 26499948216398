import styled from 'styled-components'

import Container from '../../../Components/Container'
import PageTitle from '../../../Components/PageTitle'
import PageText from '../../../Components/PageText'
import AboutUs from '../../../Components/SpapAboutUs'
import RecommendedLinks from '../../../Components/RecommendedLinks'
import { Helmet } from "react-helmet";

const Wrapper = styled.main`
  background-color: #FFFFFF;
  color: #FFFFFF;
  font-size: 16px !important;
  letter-spacing: 0px;
  line-height: 1.5;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;
  
  img {
    width: 100%;
  }

  @media (max-width: 992px) {
    margin-top: 120px;
  }
`

export default function QuemSomos( props ) {
  
  return(
    <Wrapper>
      <Helmet>
        <title>Quem somos? | Sociedade Portuguesa de Anatomia Patológica - SPAP</title>
        <meta
            name="description"
            content="A Sociedade Portuguesa de Anatomia Patológica foi fundada em 25 de Outubro de 1963, sendo abreviadamente designada por SPAP."
        />
        <meta
            name="keywords"
            content="spap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      <Container>
        <PageTitle
          title="Quem somos?"
          path={[
            {
              'text' : 'Homepage',
              'link' : '/'
            },
            {
              'text' : 'SPAP',
              'link' : '/spap'
            },
            {
              'text' : 'Quem somos?'
            }
          ]}
          imageDesktop={require('../../../Assets/Images/img-quem-somos@2x.png')}
          imageMobile={require('../../../Assets/Images/img-quem-somos.png')}
          imageAlt="Quem somos"
        />

        <PageText 
          title="A Sociedade Portuguesa de Anatomia Patológica foi fundada em 25 de Outubro de 1963, sendo abreviadamente designada por SPAP."
          text="A SPAP é uma Associação de direito privado, de índole científica e cultural, sem fins lucrativos, que visa o aperfeiçoamento técnico-científico da especialidade de Anatomia Patológica bem como dos seus associados."
        />

        <AboutUs />

      </Container>

      <RecommendedLinks
        title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
        links={
          [
            {
              'title' : 'História',
              'link' : '/spap/historia'
            },
            {
              'title' : 'Órgãos sociais',
              'link' : '/spap/orgaos-sociais'
            },
            {
              'title' : 'Bolsas e prémios',
              'link' : '/spap/bolsas-e-premios'
            },
            {
              'title' : 'Vantagens de ser sócio',
              'link' : '/socios/conheca-as-vantagens'
            }
          ]
        }
      />
    </Wrapper>
  )
}
