import styled from "styled-components";
import { Link } from "react-router-dom";

import Container from "../../../Components/Container";
import FlexInRow from "../../../Components/FlexInRow";
import FlexInColumn from "../../../Components/FlexInColumn";
import PageTitle from "../../../Components/PageTitle";
import RecommendedLinks from "../../../Components/RecommendedLinks";

import IconSeeMore from "../../../Assets/Images/icon-saber-mais.svg";
import IconShare from "../../../Assets/Images/icon-partilhar.svg";
import IconReturn from "../../../Assets/Images/icon-voltar.svg";
import { notification } from "antd";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  > .content {
    padding-bottom: 4vw;
  }

  img {
    width: 100%;
  }

  p {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0;
  }

  a {
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #f88436;
    opacity: 1;
    display: initial;
    transition: 0.3s ease-out;
  }

  a:hover {
    text-decoration: none;
  }

  .orange {
    color: #f88436;
  }

  ul {
    list-style: none;
    width: 8.333vw;
    padding: 0;
  }

  li {
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
    gap: 0.729vw;
    text-align: left;
    font-size: 1.146vw;
    font-weight: bold;
    line-height: 1.51vw;
    letter-spacing: 0px;
    opacity: 1;
    margin: 1.042vw 0;

    img {
      width: 1.667vw;
    }
  }

  li a {
    color: #f88436;
    font-style: initial;
    text-decoration: none;
  }

  .icon {
    margin-right: 8px;
  }

  .icon-file {
    width: 3.438vw;
  }

  .share {
    cursor: pointer;
    color: ${(props) => props.theme.orange};
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    ul {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    li {
      margin: 20px 0;
    }

    li,
    li a {
      font-size: 2.333vw;
      line-height: 3.444vw;
    }

    li img {
      width: 20px;
    }

    p,
    p a {
      font-size: 3.333vw;
      line-height: 4.444vw;
    }
  }

  @media (min-width: 1920px) {
    p {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

const Title = styled.h2`
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;
  margin: 0;

  @media (max-width: 992px) {
    font-size: 4.444vw;
    line-height: 5.555vw;
  }
`;

const CardPicture = styled.picture`
  border: none;
  overflow: hidden;
  min-width: ${(props) => props.width};
  max-width: ${(props) => props.width};

  img {
    height: 100%;
    object-fit: cover;
    object-position: top;
  }

  @media (max-width: 992px) {
    min-width: 100%;
    max-width: 100%;
  }
`;

const SectionOrganization = styled.section`
  margin-bottom: 2.344vw;

  p {
    text-align: left;
    font-size: 16px;
    line-height: inherit;
    letter-spacing: 0px;
    color: #0c1151;
  }

  b {
    font-weight: bold;
  }

  @media (max-width: 992px) {
    p,
    p a {
      font-size: 3.333vw;
      line-height: 4.444vw;
    }
  }
`;

export default function Noticia(props) {
  const handleShare = (link) => {
    navigator.clipboard.writeText(link);
    notification.info({ description: "Link copiado: " + link });
  };

  return (
    <Wrapper>
      <Container className="content">
        <PageTitle
          title="SPAP Bolsa 2022"
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "SPAP",
              link: "/spap",
            },
            {
              text: "Bolsas e Prémios",
              link: "/spap/bolsas-e-premios",
            },
            {
              text: "SPAP Bolsa 2022",
            },
          ]}
          imageDesktop={require("../../../Assets/Images/img-top-spap-bolsa-2022@2x.png")}
          imageMobile={require("../../../Assets/Images/img-top-spap-bolsa-2022.png")}
          imageAlt="SPAP Bolsa 2022"
        />

        <FlexInRow gap="3.646vw">
          <Title>Oferta de bolsas para Internos de Anatomia Patológica</Title>

          <FlexInColumn gap="15.260vw" mobile="column">
            <FlexInRow>
              <p>
                Caros Sócios, A SPAP propõe a oferta de bolsas de formação para
                Internos que façam estágios em Portugal ou no exterior, tendo
                como objetivo promover a mobilidade e a troca de experiências
                entre serviços através dos seus membros mais jovens.
              </p>
              <p>
                As bolsas são concedidas para estágios de pelo menos um mês, que
                façam parte do currículo obrigatório ou sejam estágios
                opcionais, de acordo com o interesse do Interno ou do serviço de
                onde ele é proveniente.
              </p>
              <p>
                Podem descarregar o{" "}
                <a
                  href="https://spap.pt/wp-content/uploads/2022/08/SPAPbolsa_regulamento.pdf"
                  target="_blank"
                  className="orange"
                >
                  regulamento
                </a>{" "}
                e o{" "}
                <Link to="/spap/contactos" target="_blank">
                  formulário
                </Link>{" "}
                para a inscrição, que deverá ser efetuada por email (
                <a href="mailto:site@spap.pt" rel="noreferrer">
                  site@spap.pt
                </a>
                ), na data definida aquando da divulgação do concurso.
              </p>
              <p>
                <b>
                  O último concurso foi aberto a 16 de Agosto, com email para a
                  lista de sócios, e a deadline para candidatura fixada em 30 de
                  Setembro. Foram recebidas apenas 7 candidaturas, todas
                  aceites. Como não houve nenhum candidato do 1º ano (o que se
                  explica por não terem tido oportunidade de serem recebidos
                  como sócios da SPAP), a referida bolsa foi entregue ao segundo
                  colocado do 4º ano. Queremos que as próximas edições tenham
                  maior aderência, e por isto pedimos que nos enviem sugestões
                  para melhorar o concurso. Será elaborado um regulamento
                  específico, a ser aprovado na próxima Assembleia, e que
                  servirá de guia para as próximas edições.
                </b>
              </p>
              <p>
                VENCEDORES: <br />
                4º ano – João Lobo – IPO Porto <br />
                4º ano – Catarina Alves do Vale – CUF Lisboa <br />
                3º ano – Miguel Ângelo Rodrigues Oliveira – H.D. Santarém <br />
                2º ano – Gonçalo Ribeiro Miranda – U.L.S. Matosinhos <br />
                1º ano – sem candidatos (bolsa dada ao 4º ano)
              </p>
              <p>
                <b>
                  O resultado foi divulgado na Assembléia Geral em 25 de
                  Novembro de 2022. <br />
                  Os vencedores serão contactados nos próximos dias pela direção
                  da SPAP. <br />O período de contestação do concurso terminou
                  no dia 5 de Dezembro.
                </b>
              </p>
              <SectionOrganization>
                <p>Comissão organizadora:</p>
                <p>
                  <b>Catrina Eloy - </b>Vice-presidente
                </p>
                <p>
                  <b>Raquel Ilgenfritz - </b>Secretária Geral
                </p>
                <p>
                  Contacto:{" "}
                  <a href="mailto:site@spap.pt" rel="noreferrer">
                    site@spap.pt
                  </a>
                </p>
              </SectionOrganization>
            </FlexInRow>
            <div>
              <ul>
                <li className="link-disabled">
                  <Link to="/">
                    <img
                      src={IconSeeMore}
                      alt="Icon See More"
                      className="icon icon-see-more"
                    />
                    Saber mais
                  </Link>
                </li>
                <li>
                  <span
                    className="share"
                    onClick={() => handleShare(window.location.href)}
                  >
                    <img
                      src={IconShare}
                      alt="Icon Share"
                      className="icon icon-share"
                    />
                    Partilhar
                  </span>
                </li>
                <li>
                  <a href="javascript:history.back()">
                    <img
                      src={IconReturn}
                      alt="Icon Return"
                      className="icon icon-return"
                    />
                    Voltar
                  </a>
                </li>
              </ul>
            </div>
          </FlexInColumn>
        </FlexInRow>
      </Container>

      <RecommendedLinks
        title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
        links={[
          {
            title: "História",
            link: "/spap/historia",
          },
          {
            title: "Órgãos sociais",
            link: "/spap/orgaos-sociais",
          },
          {
            title: "Bolsas e prémios",
            link: "/spap/bolsas-e-premios",
          },
          {
            title: "Vantagens de ser sócio",
            link: "/socios/conheca-as-vantagens",
          },
        ]}
      />
    </Wrapper>
  );
}
