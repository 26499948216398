import styled from 'styled-components'
import { Divider, Space, Button, Switch, Input } from 'antd'
import Card from '../../../Components/Backoffice/Card'
import Title from '../../../Components/Backoffice/Title'

const { TextArea } = Input

import { ReactComponent as IconEmail } from '../../../Assets/Images/Backoffice/email.svg'
import { ReactComponent as IconNotifications } from '../../../Assets/Images/Backoffice/Notifications.svg'
import { ReactComponent as IconCalendar } from '../../../Assets/Images/Backoffice/Calendar.svg'
import { ReactComponent as IconOk } from '../../../Assets/Images/Backoffice/Ok.svg'

const CardHeader = styled.div`
  align-items: center;
  display: grid;
  gap: 50px;
  grid-template-columns: 1fr auto auto auto;
  opacity: 0.6;
  pointer-events: none;
`

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 30px 0;
  pointer-events: none;
  opacity: 0.6;

  p {
    color: #809FB8;
    font-size: 16px;
    font-style: italic;
    line-height: 25px;
    letter-spacing: 0px;
    margin: 0;
  }
`

const CardFooter = styled.div`
  align-items: center;  
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  opacity: 0.6;
  pointer-events: none;

  .cta {
    align-items: center;
    border-radius: 12px;
    height: 45px;

    path {
      fill: #FFFFFF;
    }
  }
`

export default function DashboardMessages () {
  return(
    <Card style={{ opacity: '0.6' }}>
      <CardHeader>
        <Title>Envio de mensagens aos Sócios</Title>
        <Space>
          <IconEmail className='icon icon-email' />
          Email
          <Switch />
        </Space>
        <Space>
          <IconNotifications className='icon icon-notification' />
          Notificação
          <Switch />
        </Space>
        <Button>
          <Space>
            <span>Agendar envio</span>
            <IconCalendar className='icon icon-calendar' />
          </Space>
        </Button>
      </CardHeader>
      <CardContent>
        <p>Crie aqui mensagens rápidas para serem lidas pelos associados, via email e/ou por notificação.</p>
        <TextArea
          style={{ color: '#809FB8', fontStyle: 'italic', border: '2px solid #D9E1E7', borderRadius: '16px' }}
          placeholder="Escreva aqui a sua mensagem..."
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
        <p>Selecione abaixo grupos específicos de sócios para enviar mensagens segmentadas.</p>
      </CardContent>
      <Divider />
      <CardFooter>
        <Space size={50}>
          <Space>
            <Switch /> Direcção
          </Space>
          <Space>
            <Switch /> Gestores de Clubes
          </Space>
          <Space>
            <Switch /> NISPAP
          </Space>
          <Space>
            <Switch /> Todos os Sócios
          </Space>
        </Space>
        <Button type="primary" className='cta'>
          <Space>Enviar <IconOk /></Space>
        </Button>
      </CardFooter>
    </Card>
  )
}
