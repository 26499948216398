import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import {
  Button,
  Tour,
  notification,
  message,
  Space,
  Input,
  Radio,
  Table,
  Modal,
  Upload,
  Avatar,
  Typography,
} from "antd";
import { UserOutlined, CheckOutlined, InboxOutlined } from "@ant-design/icons";
import { usePapaParse } from "react-papaparse";

import Sidebar from "../../../Components/Backoffice/Sidebar";
import Card from "../../../Components/Backoffice/Card";
import Title from "../../../Components/Backoffice/Title";
import Header from "../../../Components/Backoffice/Header";
import Footer from "../../../Components/Backoffice/Footer";
import UserService from "../../../Services/user.service";
import DrawerPerfil from "../Socios/drawerPerfil";
import { ReactComponent as IconOptions } from "../../../Assets/Images/Backoffice/Options.svg";
import { ReactComponent as IconSearch } from "../../../Assets/Images/Backoffice/Search.svg";
import { ReactComponent as IconUpload } from "../../../Assets/Images/Backoffice/Upload-off.svg";
import ExportButton from "../../../Components/Backoffice/ExportButton";
import DrawerExportTable from "../../../Components/Backoffice/Export/DrawerExportTable";

const { Dragger } = Upload;
const { Text } = Typography;
const { TextArea } = Input;

const Wrapper = styled.main`
  background-color: #e8e9f3;
  display: grid;
  grid-template-columns: 7.083vw 1fr;
  min-height: 100vh;

  @media (max-width: 992px) {
  }
`;

const Content = styled.div`
  padding: 1.771vw;
`;

const CardHeader = styled.div`
  align-items: center;
  display: grid;
  gap: 60px;
  grid-template-columns: 1fr auto auto;

  .icon-upload {
    cursor: pointer;
    height: 46px;
    width: 46px;
  }
`;

const Search = styled.div`
  position: relative;

  input {
    background-color: #f1f5f700;
    border: 2px solid #d9e1e7;
    border-radius: 16px;
    color: #8688a8;
    font-size: 16px;
    font-style: italic;
    letter-spacing: 0px;
    line-height: 18px;
    margin: 0;
    padding: 12px 58px 12px 26px;
    outline: 0;
  }

  .icon {
    position: absolute;
    right: 26px;
    top: 8px;
  }
`;

const RadioGroup = styled.div`
  button {
    background-color: #494d7d;
    border: none;
    color: #ffffff;
    transition: 0.3s ease;
  }

  button:hover {
    background-color: #1ad598;
    color: #ffffff !important;
  }
`;

const CardContent = styled.div`
  margin: 15px 0 30px;
  position: relative;
`;

const PreGrid = styled.div`
  border-bottom: 1px solid #f1f4f9;
  border-top: 1px solid #f1f4f9;
  padding-top: 10px;
`;

const Grid = styled.div`
  display: grid;
  gap: 6.5px;
  grid-template-columns: repeat(6, 1fr);
  max-height: 400px;
  overflow: auto;
  padding-bottom: 40px;
  padding-right: 6.5px;

  &::-webkit-scrollbar {
    background-color: #cccccc88;
    border-radius: 20px;
    width: 10px;
    padding: 10px 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #0c115188;
    border-radius: 20px;
  }

  .ant-checkbox-checked span.ant-checkbox-inner,
  .ant-checkbox-checked:hover span.ant-checkbox-inner {
    background-color: #1ad598 !important;
    border-color: #1ad598 !important;
  }

  .ant-checkbox:hover span.ant-checkbox-inner,
  .ant-checkbox-wrapper.item__checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-wrapper.item__checkbox:hover .ant-checkbox-checked {
    border-color: #1ad598 !important;
  }

  .item {
    padding: 6.5px 18px;
  }

  .item.checked {
    border: 1px solid #494d7d;
    border-radius: 12px;
  }

  .item__name {
    color: #494d7d;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 22px;
    text-align: left;
  }

  .item__number {
    color: #8688a8;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 22px;
    text-align: left;
  }

  @media (max-width: 1400px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1080px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const CardFooter = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 235px);
  justify-content: space-between;

  button {
    border-radius: 12px;
    height: 50px;
    width: 100%;
  }
`;
export default function Socios(props) {
  document.title = "SPAP - Sócios";
  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const profile = UserService.getUserProfile();

  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const [open, setOpen] = useState(false);

  const { readString } = usePapaParse();
  const [listUsersToShow, setListUsersToShow] = useState([]);
  const [listUsersBkp, setListUsersBkp] = useState([]);
  const [usersList, setUsersList] = useState(session_storage.all_data.users);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [userDrawer, setUserDrawer] = useState([]);
  const [columns, setColumns] = useState([]);
  const [clubes, setClubes] = useState([]);
  const [membrosClubes, setMembrosClubes] = useState([]);
  const [isOpenExport, setIsOpenExport] = useState(false);
  const [proccessQuotas, setProccessQuotas] = useState(false);
  const [tablePagination, setTablePagination] = useState(25);
  const selectHandleChange = (value) => {
    setTablePagination(value);
  };

  const handleColumns = async (clubes) => {
    let defaultColumns = [
      {
        title: "ID",
        dataIndex: "id",
      },
      {
        title: "Número de Sócio",
        dataIndex: "numero_socio",
      },
      {
        title: "Nome Clínico",
        dataIndex: "name",
      },
      {
        title: "Apelido Clínico",
        dataIndex: "apelido",
      },
      {
        title: "Nome Completo",
        dataIndex: "nome_completo",
      },
      {
        title: "Data de Nascimento",
        dataIndex: "data_nascimento",
      },
      {
        title: "Idade",
        dataIndex: "",
      },
      {
        title: "Instituição",
        dataIndex: "instituicao",
      },
      {
        title: "Tipo de Sócio",
        dataIndex: "tipo_socio",
      },
      {
        title: "Grau 1",
        dataIndex: "grau",
      },
      {
        title: "Grau 2",
        dataIndex: "grau_2",
      },
      {
        title: "Número de Célula Profissional",
        dataIndex: "numero_celula",
      },
      {
        title: "Número ESP",
        dataIndex: "numero_esp",
      },
      {
        title: "E-mail",
        dataIndex: "email",
      },
      {
        title: "Telefone",
        dataIndex: "telefone",
      },
      {
        title: "Proponente(s)",
        dataIndex: "socio_titular",
      },
      {
        title: "Orcid",
        dataIndex: "orcid",
      },
      {
        title: "NIF",
        dataIndex: "numero_contribuinte",
      },
    ];
    if (clubes?.length > 0) {
      clubes.forEach((clube) => {
        defaultColumns.push({
          title: "Clube " + clube.name,
          dataIndex: "Clube " + clube.name,
        });
      });
    }

    var currentYear = new Date().getFullYear();
    while (currentYear > 2020) {
      defaultColumns.push({
        title: "Data Pagamento " + currentYear,
        dataIndex: "pagamento" + currentYear,
      });
      defaultColumns.push({
        title: "Valor quotas pagas " + currentYear,
        dataIndex: "valorQuota" + currentYear,
      });
      defaultColumns.push({
        title: "SPAP " + currentYear,
        dataIndex: "quotaSPAP" + currentYear,
      });
      defaultColumns.push({
        title: "IAP " + currentYear,
        dataIndex: "quotaIAP" + currentYear,
      });
      defaultColumns.push({
        title: "ESP " + currentYear,
        dataIndex: "quotaESP" + currentYear,
      });
      currentYear--;
    }
    setColumns(defaultColumns);
  };

  useEffect(() => {
    fetch(`https://api.spap.pt/api/all-membros-clubes`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0) {
          console.log("nenhum clube");
        }

        setClubes(data.clubes);
        setMembrosClubes(data.membrosClubes);
        setProccessQuotas(true);
        handleColumns(data.clubes);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (proccessQuotas) {
      fetch(`https://api.spap.pt/api/all-users`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + session_storage.token,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Erro ao buscar users");
          }
          return response.json();
        })
        .then((data) => {
          if (data.users.length === 0) {
            return;
          }

          const socios = data.users.filter((user) => {
            /* 
        este if comentado é para termos o numero de utilizador de igual com o apresentado no dashboard, 
        sem os users "nao definidos"
        */
            // if(user.tipo_utilizador == 'socio_aprovado' && user.tipo_socio != 'Não definido') {
            if (
              user.tipo_utilizador == "socio_aprovado" ||
              user.tipo_utilizador == "admin"
            ) {
              return user;
            }
          });
          for (let i = 0; i <= socios.length - 1; i++) {
            socios[i].number = socios[i].numero_socio
              ? socios[i].numero_socio
              : "N/A";
              socios[i].avatarName = <><Space
                        align="center"
                        size={14}
                      >
                        {socios[i].avatar && (
                          <Avatar
                            src={
                              <img
                                src={
                                  "https://api.spap.pt/avatars/" + socios[i].avatar
                                }
                              />
                            }
                          />
                        )}
                        {!socios[i].avatar && <Avatar icon={<UserOutlined />} />}
                        <Space.Compact direction="vertical">
                          <Text strong className="item__name">
                            <a onClick={() => clickToDrawer(socios[i])}>
                              {socios[i].name} {socios[i].apelido ? socios[i].apelido : ""}
                            </a>
                          </Text>
                          {/* <Text className="item__number">
                            {" "}
                            Sócio: {socios[i].number}
                          </Text> */}
                        </Space.Compact>
                      </Space></>;
          }
          setListUsersBkp(socios);
          setListUsersToShow(socios);
          if (socios) {
            fetch(`https://api.spap.pt/api/all-quotas`, {
              method: "GET",
              headers: {
                "Access-Control-Allow-Origin": "*",
                Accept: "application/json",
              },
            })
              .then((response) => {
                if (!response.ok) {
                  throw new Error("Erro ao buscar anticorpos");
                }
                return response.json();
              })
              .then((data) => {
                if (data.length === 0) {
                  console.log("nenhum evento");
                }

                let processedData = processUserData(data.quotas);
                // addYearsAndValuesToSocios(socios, processedData);
                handleQuotas(socios, data?.quotas, data?.plans);
              })
              .catch((error) => console.error(error));
          }
        })
        .catch((error) => console.error(error));
    }
  }, [proccessQuotas]);

  function handleQuotas(socios, quotas, plans) {
    socios.forEach((socio) => { clubes.map((clube) => {
        let membrosClube = membrosClubes?.filter((membro) => {
          if (membro.club_id == clube.id) {
            return membro;
          }
        });
        let isMembro = membrosClube.filter((membro) => {
          return membro.user_id == socio.id;
        });
        socio["Clube " + clube.name] = isMembro.length > 0 ? "Sim" : "Não";
      });

      let currentYear = new Date().getFullYear();
      if (new Date().getMonth() == 11) {
        currentYear = new Date().getFullYear() + 1;
      }
      while (currentYear > 2020) {
        let quotaByYear = quotas.filter((quota) => {
          return quota.ano == currentYear && quota.user_email == socio.email;
        });
        let currentPlan = plans.filter(plan => { if (plan.year == currentYear) return true;})[0];
        if (quotaByYear.length) {
          let valor = 0;
          quotaByYear.map((quota) => {
            if (quota.status == "pago") {
              if (quota.tipo == "SPAP") {
                if (!quota.valor.length && quota.valor != 'FALTA' && quota.valor != 'x') {
                  quota.valor = Number(currentPlan.spap);
                }
                let valorIAP = quota.valor * 0.2;
                let valorSPAP = quota.valor * 0.8;
                socio[`quotaSPAP${currentYear}`] = "PAGO (" + valorSPAP + ")";
                socio[`quotaIAP${currentYear}`] = "PAGO (" + valorIAP + ")";
                socio[`pagamento${currentYear}`] = (quota.payment_valid_date != null && quota.payment_valid_date != 'NULL' && quota.payment_valid_date?.length) ? quota.payment_valid_date : 'Não Definido';
                valor += Number(quota.valor);
              }
              if (quota.tipo == "ESP") {

                if (quota.valor != 'FALTA' && quota.valor != 'x' || !quota.valor.length) {
                  quota.valor = currentPlan.esp;
                }
                socio[`quotaESP${currentYear}`] = "PAGO (" + quota.valor + ")";
                valor += Number(quota.valor);
              }
            } else {
              if (quota.tipo == "SPAP") {
                if (quota.valor != 'FALTA' && quota.valor != 'x' || !quota.valor.length) {
                  quota.valor = currentPlan.spap;
                }
                let valorIAP = quota.valor * 0.2;
                let valorSPAP = quota.valor * 0.8;
                socio[`quotaSPAP${currentYear}`] = "POR PAGAR (" + valorSPAP + ")";
                socio[`quotaIAP${currentYear}`] = "POR PAGAR (" + valorIAP + ")";
              }
              if (quota.tipo == "ESP") {
                if (quota.valor != 'FALTA' && quota.valor != 'x' || !quota.valor.length) {
                  quota.valor = currentPlan.esp;
                }
                socio[`quotaESP${currentYear}`] = "POR PAGAR (40)";
              }
            }
          });
          socio[`valorQuota${currentYear}`] = valor;
        }
        currentYear--;
      }
    });

    setListUsersToShow(socios);
    setListUsersBkp(socios);
  }

  function processUserData(data) {
    const groupedData = {};
    data.forEach((item) => {
      const { user_email, ano, valor, data_pag } = item;
      if (!groupedData[user_email]) {
        groupedData[user_email] = {
          quotasAnos_2024: "",
          quotasValores_2024: "",
          quotasAnos_2023: "",
          quotasValores_2023: "",
          quotasAnos_2022: "",
          quotasValores_2022: "",
          quotasAnos_2021: "",
          quotasValores_2021: "",
          data_pag: "",
        };
      }
      let newValor = valor;
      if (newValor == 24 || newValor == 40) {
        newValor = 30;
      }

      if (ano == "2024") {
        groupedData[user_email].quotasAnos_2024 = "2024";
        groupedData[user_email].quotasValores_2024 = newValor;
        groupedData[user_email].data_pag = data_pag;
      }
      if (ano == "2023") {
        groupedData[user_email].quotasAnos_2023 = "2023";
        groupedData[user_email].quotasValores_2023 = newValor;
      }
      if (ano == "2022") {
        groupedData[user_email].quotasAnos_2022 = "2022";
        groupedData[user_email].quotasValores_2022 = newValor;
      }
      if (ano == "2021") {
        groupedData[user_email].quotasAnos_2021 = "2021";
        groupedData[user_email].quotasValores_2021 = newValor;
      }
    });
    return Object.keys(groupedData).map((user_email) => {
      return {
        user_email,
        quotasAnos_2024: groupedData[user_email].quotasAnos_2024
          ? groupedData[user_email].quotasAnos_2024
          : "",
        quotasValores_2024: groupedData[user_email].quotasValores_2024
          ? groupedData[user_email].quotasValores_2024
          : "",
        quotasAnos_2023: groupedData[user_email].quotasAnos_2023
          ? groupedData[user_email].quotasAnos_2023
          : "",
        quotasValores_2023: groupedData[user_email].quotasValores_2023
          ? groupedData[user_email].quotasValores_2023
          : "",
        quotasAnos_2022: groupedData[user_email].quotasAnos_2022
          ? groupedData[user_email].quotasAnos_2022
          : "",
        quotasValores_2022: groupedData[user_email].quotasValores_2022
          ? groupedData[user_email].quotasValores_2022
          : "",
        quotasAnos_2021: groupedData[user_email].quotasAnos_2021
          ? groupedData[user_email].quotasAnos_2021
          : "",
        quotasValores_2021: groupedData[user_email].quotasValores_2021
          ? groupedData[user_email].quotasValores_2021
          : "",
        data_pag: groupedData[user_email].data_pag
          ? groupedData[user_email].data_pag
          : "",
      };
    });
  }

  function addYearsAndValuesToSocios(socios, userData) {
    const emailDataMap = {};

    // Primeiro, mapear os dados de usuário por e-mail
    userData.forEach(
      ({
        user_email,
        quotasAnos_2024,
        quotasValores_2024,
        quotasAnos_2023,
        quotasValores_2023,
        quotasAnos_2022,
        quotasValores_2022,
        quotasAnos_2021,
        quotasValores_2021,
        data_pag,
      }) => {
        if (!emailDataMap[user_email]) {
          emailDataMap[user_email] = {
            quotasAnos_2024,
            quotasValores_2024,
            quotasAnos_2023,
            quotasValores_2023,
            quotasAnos_2022,
            quotasValores_2022,
            quotasAnos_2021,
            quotasValores_2021,
            data_pag,
          };
        }
      }
    );

    // Depois, adicionar os anos e valores aos sócios correspondentes
    socios.forEach((socio) => {
      const emailData = emailDataMap[socio.email];
      if (emailData) {
        //socio.quotasAnos = Array.from(emailData.anos).join(", ");
        //socio.quotasValores = emailData.valores.join(", ");

        socio.quotasAnos_2024 = emailData.quotasAnos_2024;
        socio.quotasValores_2024 = emailData.quotasValores_2024;
        socio.quotasAnos_2023 = emailData.quotasAnos_2023;
        socio.quotasValores_2023 = emailData.quotasValores_2023;
        socio.quotasAnos_2022 = emailData.quotasAnos_2022;
        socio.quotasValores_2022 = emailData.quotasValores_2022;
        socio.quotasAnos_2021 = emailData.quotasAnos_2021;
        socio.quotasValores_2021 = emailData.quotasValores_2021;
        socio.data_pag = emailData.data_pag;
      }
    });
  }

  notification.config({
    placement: "bottomRight",
    bottom: 10,
    duration: 5,
    rtl: false,
  });

  const handleSearch = (event) => {
    let allUsers = listUsersBkp;

    if (event.length == 0) {
      setListUsersToShow(allUsers);
      return;
    }

    let input = event.toLowerCase();

    let novosUsersAListar = allUsers.filter((user) => {
      if (
        (user.nome_completo && user.nome_completo.toLowerCase().includes(input)) ||
        (user.apelido && user.apelido.toLowerCase().includes(input)) ||
        (user.name && user.name.toLowerCase().includes(input)) ||
        (user.email && user.email.toLowerCase().includes(input)) ||
        (user.numero_socio &&
          user.numero_socio.toLowerCase().includes(input)) ||
        (user.telefone && user.telefone.toLowerCase().includes(input)) ||
        (user.orcid && user.orcid.toLowerCase().includes(input)) ||
        (user.socio_titular &&
          user.socio_titular.toLowerCase().includes(input))
      ) {
        return user;
      }
    });

    setListUsersToShow(novosUsersAListar);
  };

  const clickToDrawer = (user) => {
    
    setUserDrawer(user);
  };

  useEffect(() => {
    console.log(userDrawer);
    if (Object.keys(userDrawer).length > 0) {
      setShowDrawer(true);
    }
  }, [userDrawer]);

  const handleCheckboxChange = (index) => {
    const newCheckboxes = [...checkboxes];
    newCheckboxes[index] = !newCheckboxes[index];
    setCheckboxes(newCheckboxes);
  };

  const handleCancelClick = () => {
    setCheckboxes([]);
  };

  const prop = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const hideModal = () => {
    console.log("Clicked cancel button");
    setIsModalOpen(false);
  };

  const handleUpload = () => {
    readString(fileList[0], {
      worker: true,
      complete: (results) => {
        results.data.shift();

        if (results.data.length == 0) {
          notification.error({
            description: "O Ficheiro não tem sócios para inserir.",
          });
        } else {
          var bodyFormData = new FormData();
          bodyFormData.append("array", JSON.stringify(results.data));

          setUploading(true);

          fetch("https://api.spap.pt/api/admin/upload-users", {
            method: "POST",
            body: bodyFormData,
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + session_storage.token,
            },
          }).then((response) => {
            response.json().then((data) => {
              if (data.message == "array_error") {
                notification.error({
                  description: "ERRO! O Ficheiro tem campos por preencher.",
                });
              } else {
                if (data?.users?.length > 0) {
                  const socios = data.users.filter((user) => {
                    if (user.tipo_utilizador == "socio_aprovado") {
                      return user;
                    }
                  });

                  for (let i = 0; i <= socios.length - 1; i++) {
                    socios[i].number = socios[i].numero_socio
                      ? socios[i].numero_socio
                      : "N/A";
                  }
                  setListUsersBkp(socios);

                  setListUsersToShow(socios);
                }
                notification.success({
                  description: "O ficheiro foi inserido com sucesso!",
                });
              }
              setFileList([]);
              setUploading(false);
            });
          });
        }
      },
    });
  };

  const [value, setValue] = useState(1);
  const radioChange = (e) => {
    setValue(e.target.value);
  };

  const handleMenuClick = (e) => {
    message.info("Click on menu item.");
    console.log("click", e);
  };

  const items = [
    {
      label: (
        <RadioGroup>
          <Radio.Group onChange={radioChange} value={value}>
            <Space direction="vertical" className="space-align-block">
              <Radio value={1}>Aviso de cobrança</Radio>
              <Radio value={2}>Aviso de certificado</Radio>
              <Radio value={3}>Recibo de compras</Radio>
              <Radio value={4}>Ativar utilizador</Radio>
              <Radio value={5}>Desativar utilizador</Radio>
              <Button onClick={handleMenuClick}>
                <Space>
                  Enviar
                  <CheckOutlined />
                </Space>
              </Button>
            </Space>
          </Radio.Group>
        </RadioGroup>
      ),
    },
  ];
  const menuProps = {
    items,
    onClick: false,
  };

  /*
    #TODO adicionar array de usuarios aqui
  */

  const handleOpenExport = () => {
    setIsOpenExport(true);
  };

  const handleCloseExport = () => {
    setIsOpenExport(false);
  };

  const handleUsers = (usersUpdated) => {
    const socios = usersUpdated.filter((user) => {
      if (user.tipo_utilizador == "socio_aprovado") {
        return user;
      }
    });

    for (let i = 0; i <= socios.length - 1; i++) {
      socios[i].number = socios[i].numero_socio
        ? socios[i].numero_socio
        : "N/A";

      socios[i].avatarName = <><Space
                        align="center"
                        size={14}
                      >
                        {socios[i].avatar && (
                          <Avatar
                            src={
                              <img
                                src={
                                  "https://api.spap.pt/avatars/" + socios[i].avatar
                                }
                              />
                            }
                          />
                        )}
                        {!socios[i].avatar && <Avatar icon={<UserOutlined />} />}
                        <Space.Compact direction="vertical">
                          <Text strong className="item__name">
                            <a onClick={() => clickToDrawer(socios[i])}>
                              {socios[i].name} {socios[i].apelido ? socios[i].apelido : ""}
                            </a>
                          </Text>
                          <Text className="item__number">
                            {" "}
                            Sócio: {socios[i].number}
                          </Text>
                        </Space.Compact>
                      </Space></>;
    }
    setListUsersBkp(socios);

    setListUsersToShow(socios);
  };

  const steps = [
    {
      title: "Como fazer o upload dos sócios?",
      description:
        "É necessário seguir a ordem das colunas do CSV em formato UTF-8 do ficheiro fornecido em cima.",
      cover: (
        <div style={{ marginBottom: "10px" }}>
          <a
            href="https://api.spap.pt/uploads/ficheiro-template-users.csv"
            style={{
              padding: "12px 40px",
              textDecoration: "none",
              borderRadius: "6px",
              backgroundColor: "#494D7D",
              color: "#fff",
            }}
            download
          >
            Download do ficheiro template
          </a>
        </div>
      ),
      target: () => ref1.current,
    },
    {
      title: "Momento de fazer upload",
      description:
        "Assim que desejar fazer o upload dos sócios, basta clicar neste botão e será aberta uma modal com um campo para arrastar o ficheiro ou clicar para o introduzir manualmente.",
      target: () => ref2.current,
    },
    {
      title: "Fim",
      description: "Espero que tenha sido util!",
      target: () => ref2.current,
    },
  ];

  const mainColumns = [
    {
      title: "Nome",
      dataIndex: "avatarName",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Tipo de sócio",
      dataIndex: "tipo_socio",
    },
    {
      title: "Grau",
      dataIndex: "grau",
    },
    {
      title: "Nº de sócio",
      dataIndex: "numero_socio",
    },
  ];

  return (
    <Wrapper>
      <DrawerExportTable
        columns={columns}
        open={isOpenExport}
        data={listUsersToShow.filter((item) => ![21,24].includes(item.id))}
        close={handleCloseExport}
        type={"Utilizadores"}
      />
      <div>
        <Sidebar />
      </div>
      <Content>
        <Header />
        <Card>
          <CardHeader>
            <Title>Sócios</Title>
            <Search>
              <input
                type="text"
                placeholder="Procurar..."
                onChange={(e) => handleSearch(e.target.value)}
              />
              <IconSearch className="icon icon-search" />
            </Search>
            <Space size={28}>
            <Button className="export-btn" onClick={() => handleOpenExport()}>Exportar tabela </Button>
              {/* <ExportButton
                data={listUsersToShow.filter((item) => ![21].includes(item.id))}
                columns={columns}
                fileName="lista_socios"
              /> */}
              <IconOptions
                className="icon icon-options"
                ref={ref1}
                onClick={() => setOpen(true)}
              />
              <IconUpload
                className="icon icon-upload"
                ref={ref2}
                onClick={() => setIsModalOpen(true)}
              />
            </Space>
          </CardHeader>
          <CardContent>
            {/* <PreGrid>
              <Grid>
                {listUsersToShow.length > 0 &&
                  listUsersToShow.map((item, index) => {
                    return (
                      <Space
                        className={
                          checkboxes[item.id]
                            ? "item item-" + item.id + " checked"
                            : "item item-" + item.id
                        }
                        align="center"
                        size={14}
                      >
                        {item.avatar && (
                          <Avatar
                            src={
                              <img
                                src={
                                  "https://api.spap.pt/avatars/" + item.avatar
                                }
                              />
                            }
                          />
                        )}
                        {!item.avatar && <Avatar icon={<UserOutlined />} />}
                        <Space.Compact direction="vertical">
                          <Text strong className="item__name">
                            <a onClick={() => clickToDrawer(item.id)}>
                              {item.name} {item.apelido ? item.apelido : ""}
                            </a>
                          </Text>
                          <Text className="item__number">
                            {" "}
                            Sócio: {item.number}
                          </Text>
                        </Space.Compact>
                      </Space>
                    );
                  })}
                {listUsersToShow.length == 0 && (
                  <span>Não foram encontrados sócios.</span>
                )}
              </Grid>
            </PreGrid> */}
            <Table
              columns={mainColumns}
              dataSource={listUsersToShow}
              pagination={{ pageSize: tablePagination }}
            />
          </CardContent>
        </Card>
        <Footer />
      </Content>
      <Modal
        open={isModalOpen}
        width={300}
        onCancel={hideModal}
        footer={[
          <Button
            type="primary"
            onClick={handleUpload}
            disabled={fileList.length === 0}
            loading={uploading}
            style={{
              borderRadius: "12px",
              background: "#494D7D",
              width: "100%",
              height: "45px",
            }}
          >
            {uploading ? "Uploading" : "Start Upload"}
          </Button>,
        ]}
      >
        <h5
          style={{
            marginBottom: 0,
            fontSize: "18px",
            textAlign: "center",
            paddingTop: "20px",
            paddingBottom: "10px",
          }}
        >
          Faça upload do ficheiro
        </h5>

        <p
          style={{
            marginBottom: 0,
            fontSize: "14px",
            textAlign: "center",
            color: "#809FB8",
            paddingBottom: "2px",
          }}
        ></p>
        <Dragger {...prop} style={{ borderRadius: "12px" }}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-txext">
            Clique ou arraste para aqui o ficheiro relativo aos sócios que
            pretende associar.
          </p>
        </Dragger>
      </Modal>
      <Tour open={open} onClose={() => setOpen(false)} steps={steps} />
      {/* { showDrawer && <DrawerPerfil user={userDrawer} onChange={(e) => setShowDrawer(e) }/>} */}
      <DrawerPerfil
        afterOpenChange={userDrawer}
        user={userDrawer}
        show={showDrawer}
        onUpdate={(e) => handleUsers(e)}
        onChange={(e) => setShowDrawer(e)}
      />
    </Wrapper>
  );
}
