import styled from "styled-components";
import { Helmet } from "react-helmet";
import Container from "../../../../Components/Container";
import PageTitle from "../../../../Components/PageTitle";
import PageText from "../../../../Components/PageText";
import FlexInColumn from "../../../../Components/FlexInColumn";
import FlexInRow from "../../../../Components/FlexInRow";
import SidebarMenu from "../../../../Components/SidebarMenu";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin: 7.813vw 0 4.375vw;

  img {
    width: 100%;
  }

  @media (max-width: 992px) {
    margin-top: 120px;
  }
`;

const SectionGroups = styled.section`
  .title {
    text-align: left;
    color: ${(props) => props.theme.blue};
    opacity: 1;
    font-size: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: inherit;
    margin: 0;
  }

  .bar {
    background-color: #97c1d5;
    height: 1px;
    width: 75vw;
    margin: auto 0;
  }

  .image {
    width: 11.979vw;
  }

  p {
    color: #000000;
    font-size: 0.938vw;
    letter-spacing: 0px;
    line-height: 1.25vw;
    text-align: left;
  }

  .gallery-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: relative;

    .icon {
      position: absolute;
      width: 3.385vw;
    }
  }

  @media (max-width: 992px) {
    margin-top: 40px;

    .title {
      font-size: 4.333vw;
      line-height: 5.444vw;
    }

    .bar {
      width: 0;
    }

    .image {
      width: 180px;
    }

    p {
      font-size: 3.333vw;
      line-height: 4.444vw;
    }

    .gallery-item .icon {
      width: 30px;
    }
  }
`;

export default function Iniciativas(props) {
  const SidebarMenuLinks = [
    {
      title: "Quem somos?",
      link: "/nispap/sobre-nos/quem-somos",
    },
    {
      title: "Iniciativas",
      link: "/nispap/sobre-nos/iniciativas",
    },
  ];

  return (
    <Wrapper id="nspap">
      <Helmet>
        <title>
          Iniciativas | Núcleo de Internos Sociedade Portuguesa de Anatomia
          Patológica - NISPAP
        </title>
        <meta
          name="description"
          content="O Núcleo de Internos da Sociedade de Anatomia Patológica está receptivo a colaborar em projetos, que visem melhorar a formação durante o internato."
        />
        <meta
          name="keywords"
          content="nispap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      <Container>
        <PageTitle
          title="Iniciativas"
          nispap={true}
          path={[
            {
              text: "NISPAP",
              link: "/nispap",
            },
            {
              text: "Sobre nós",
              link: "/nispap/sobre-nos",
            },
            {
              text: "Iniciativas",
            },
          ]}
          imageDesktop={require("../../../../Assets/Images/NISPAP/img-iniciativa@2x.png")}
          imageMobile={require("../../../../Assets/Images/NISPAP/img-iniciativa.png")}
          imageAlt="Iniciativas"
          sidebar={<SidebarMenu navItens={SidebarMenuLinks} nispap={true} />}
        />

        <FlexInRow gap="3.333vw">
          <FlexInColumn gap="12.396vw" mobile="column">
            <FlexInRow gap="0">
              <PageText text="O Núcleo de Internos da Sociedade de Anatomia Patológica está receptivo a colaborar em projetos, que visem melhorar a formação durante o internato." />
            </FlexInRow>
          </FlexInColumn>

          <SectionGroups>
            <FlexInRow gap="2.083vw">
              <FlexInColumn gap="1.042vw">
                <h3 className="title">Xerpa MD</h3>
                <div className="bar"></div>
              </FlexInColumn>
              <picture className="image">
                <source
                  srcSet={require("../../../../Assets/Images/NISPAP/xerpa-md-logotipo@2x.png")}
                  media="(min-width: 990px)"
                />
                <img
                  src={require("../../../../Assets/Images/NISPAP/xerpa-md-logotipo.png")}
                  alt={"Xerpa MD Logo"}
                />
              </picture>
              <FlexInColumn gap="1.667vw" mobile="column">
                <FlexInRow gap="0">
                  <p>
                    <b>
                      O Núcleo de internos da Sociedade de Anatomia Patológica
                    </b>{" "}
                    tem trabalhado com o Dr. João Gancho e com o Dr. Ricardo
                    Veiga numa plataforma médica de gestão / organização de
                    análises, produção cientifica e atividade formativa.
                  </p>
                  <p>
                    Um dos nossos <b>objetivos</b> é rentabilizar o tempo dos
                    internos, facilitando a organização dos diagnósticos em
                    tabelas que podem ser exportadas para ficheiros de Excel e
                    editadas de acordo com as preferências dos
                    anátomo-patologistas ou dos Serviços de Anatomia Patológica.
                  </p>
                  <p>
                    Foi criada uma base de dados com as entidades tumorais e
                    não-tumorais, de acordo com a bibliografia usada para o
                    Curriculum Vitae no Exame Final do Internato da Anatomia
                    Patológica (Surgical Pathology Rosai and Ackerman’s e WHO
                    Classification of Tumours).
                  </p>
                  <p>
                    Atualmente, estamos a finalizar a plataforma e em breve será
                    testada.
                  </p>
                </FlexInRow>
                <FlexInColumn
                  gap="0.833vw"
                  placeself="flex-start"
                  mobile="column"
                >
                  <div className="gallery-item">
                    <picture className="image">
                      <source
                        srcSet={require("../../../../Assets/Images/NISPAP/xerpa-img-01@2x.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../../Assets/Images/NISPAP/xerpa-img-01.png")}
                        alt={"Gallery 01"}
                      />
                    </picture>
                    <picture className="icon">
                      <source
                        srcSet={require("../../../../Assets/Images/NISPAP/bt-lupa@2x.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../../Assets/Images/NISPAP/bt-lupa.png")}
                        alt={"Gallery Icon"}
                      />
                    </picture>
                  </div>
                  <div className="gallery-item">
                    <picture className="image">
                      <source
                        srcSet={require("../../../../Assets/Images/NISPAP/xerpa-img-02@2x.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../../Assets/Images/NISPAP/xerpa-img-02.png")}
                        alt={"Gallery 02"}
                      />
                    </picture>
                    <picture className="icon">
                      <source
                        srcSet={require("../../../../Assets/Images/NISPAP/bt-lupa@2x.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../../Assets/Images/NISPAP/bt-lupa.png")}
                        alt={"Gallery Icon"}
                      />
                    </picture>
                  </div>
                </FlexInColumn>
              </FlexInColumn>
            </FlexInRow>
          </SectionGroups>
        </FlexInRow>
      </Container>
    </Wrapper>
  );
}
