import styled from "styled-components";
import React, { useState, useEffect } from "react";
import Sidebar from "../../../Components/Backoffice/Sidebar";
import Title from "../../../Components/Backoffice/Title";
import Header from "../../../Components/Backoffice/Header";
import Footer from "../../../Components/Backoffice/Footer";
import Card from "../../../Components/Backoffice/Card";
import {
  Col,
  Row,
  Button,
  notification,
  Form,
  Input,
  Dropdown,
  message,
  Space,
  Tooltip,
  Radio,
  Checkbox,
  Modal,
  Message,
  Table,
  Upload,
  Select,
  Pagination,
  Divider,
} from "antd";

import { ReactComponent as IconSearch } from "../../../Assets/Images/Backoffice/Search.svg";
import { ReactComponent as IconOptions } from "../../../Assets/Images/Backoffice/Options.svg";
import ExportButton from "../../../Components/Backoffice/ExportButton";

const Wrapper = styled.main`
  background-color: #e8e9f3;
  display: grid;
  grid-template-columns: 7.083vw 1fr;
  min-height: 100vh;

  .btn-descricao {
    margin-top: 10px;
    height: 45px !important;
    padding: 0 15px;
    font-weight: bold;
  }

  .div-banner-img {
    height: 150px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-size: cover;
    background-position: center center;
  }

  #newClube {
    background: #e8e9f3 0% 0% no-repeat padding-box;
    border: 4px dashed #d6d7e2;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;

    .cta {
      border-radius: 12px;
      height: 46px;
    }
  }

  .icon-edit {
    cursor: pointer;
  }

  .cta.publish {
    margin-right: 0;

    svg path {
      fill: #fff;
    }
  }

  @media (max-width: 992px) {
  }
`;

const TableFooter = styled.div`
  bottom: 16px;
  left: 0;
  position: absolute;
`;

const Search = styled.div`
  position: relative;

  input {
    background-color: #f1f5f700;
    border: 2px solid #d9e1e7;
    border-radius: 16px;
    color: #8688a8;
    font-size: 16px;
    font-style: italic;
    letter-spacing: 0px;
    line-height: 18px;
    margin: 0;
    padding: 12px 58px 12px 26px;
    outline: 0;
  }

  .icon {
    position: absolute;
    right: 26px;
    top: 8px;
  }
`;

const Content = styled.div`
  padding: 1.771vw;
`;

const CardHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  // gap: 60px;
  grid-template-columns: 1fr auto auto;
`;

const CardContent = styled.div`
  margin: 40px 0;
  position: relative;

  input,
  button {
    min-height: 46px;
  }

  table th {
    color: #809fb8 !important;
  }

  table td {
    color: #494d7d;
  }

  .ant-checkbox-checked span.ant-checkbox-inner,
  .ant-checkbox-checked:hover span.ant-checkbox-inner {
    background-color: #1ad598 !important;
    border-color: #1ad598 !important;
  }

  .ant-checkbox:hover span.ant-checkbox-inner {
    border-color: #1ad598 !important;
  }

  li.ant-pagination-item {
    border: 1px solid #d9e1e7cc;
  }

  li.ant-pagination-item.ant-pagination-item-active {
    background-color: #8688a8;
    border-color: #8688a8;
    color: #ffffff;
  }
`;

export default function DashboardLogs() {
  document.title = "SPAP - Logs";
  const [logs, setLogs] = useState([]);
  const [users, setUsers] = useState([]);
  const [dataToShow, setDataToShow] = useState([]);
  const [tablePagination, setTablePagination] = useState(20);
  const selectHandleChange = (value) => {
    setTablePagination(value);
  };

  const tableOnChange = () => {};

  const getLogsUserName = (logs, users) => {
    logs?.map((log) => {
      let userLog = log.user_id;
      users.map((user) => {
        if (user.id == userLog) {
          let primeiroNome = user.name;
          let apelido = user.apelido?.length > 0 ? user.apelido : "";
          let nomeAMostrar =
            primeiroNome + " " + apelido + " (" + user.id + ")";
          log.user = nomeAMostrar;
        }
      });

      let dataCriacao = new Date(log.created_at);
      let dia = dataCriacao.toLocaleDateString();
      let hora = dataCriacao.getHours();
      let minutos = dataCriacao.getMinutes();

      log.data = dia + " " + hora + "h" + minutos + "m";
    });

    logs?.sort((a, b) => b.id - a.id);

    let data = [];

    console.log(logs);

    for (let i = 0; i < logs?.length; i++) {
      data.push({
        key: i,
        descricao: logs[i].descricao,
        user: <b>{logs[i].user}</b>,
        created: logs[i].data,
      });
    }

    return data;
  };

  useEffect(() => {
    fetch(`https://api.spap.pt/api/all-logs`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar logs");
        }
        return response.json();
      })
      .then((data) => {
        if (data?.length === 0) {
          console.log("nenhum evento");
        }
        setUsers(data.users);
        setLogs(data.logs);
        setDataToShow(() => getLogsUserName(data.logs, data.users));
      })
      .catch((error) => console.error(error));
  }, []);

  const handleSearch = (event) => {
    let allLogs = getLogsUserName(logs, users);

    if (event?.length == 0) {
      setDataToShow(allLogs);
      return;
    }

    let input = event.toLowerCase();

    let novosLogsAListar = allLogs.filter((log) => {
      console.log(log);
      if (
        (log.user && log.user.props?.children.toLowerCase().includes(input)) ||
        (log.descricao && log.descricao.toLowerCase().includes(input))
      ) {
        return log;
      }
    });

    setDataToShow(novosLogsAListar);
  };

  const columns = [
    {
      title: "Descrição",
      dataIndex: "descricao",
      sorter: false,
    },
    {
      title: "Utilizador",
      dataIndex: "user",
      sorter: false,
    },
    ,
    {
      title: "Data",
      dataIndex: "created",
      sorter: false,
    },
    // {
    //   title: (<Dropdown menu={menuProps}>
    //   <Button>
    //     <Space>
    //       Ações
    //       <DownOutlined />
    //     </Space>
    //   </Button>
    // </Dropdown>),
    //   dataIndex: 'action'
    // }
  ];

  return (
    <Wrapper>
      <div>
        <Sidebar />
      </div>
      <Content>
        <Header />
        <Card className="table-section">
          <CardHeader>
            <Title>Logs</Title>
            <Space size={30}>
              <Search>
                <input
                  type="text"
                  placeholder="Procurar..."
                  onChange={(e) => handleSearch(e.target.value)}
                />
                <IconSearch className="icon icon-search" />
              </Search>
              <ExportButton
                data={dataToShow}
                columns={columns}
                fileName="logs"
              />
            </Space>
          </CardHeader>
          <CardContent>
            <Table
              columns={columns}
              dataSource={dataToShow}
              onChange={() => tableOnChange}
              pagination={{ pageSize: tablePagination }}
            />
            <TableFooter>
              <Select
                defaultValue={tablePagination}
                style={{
                  width: 120,
                }}
                onChange={selectHandleChange}
                options={[
                  {
                    value: "5",
                    label: "5",
                  },
                  {
                    value: "10",
                    label: "10",
                  },
                  {
                    value: "20",
                    label: "20",
                  },
                  {
                    value: "50",
                    label: "50",
                  },
                ]}
              />
            </TableFooter>
          </CardContent>
        </Card>
        <Footer />
      </Content>
    </Wrapper>
  );
}
