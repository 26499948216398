import styled from "styled-components";

import Container from "../../../../../Components/Container";
import PageTitle from "../../../../../Components/PageTitle";
import PageText from "../../../../../Components/PageText";
import FlexInColumn from "../../../../../Components/FlexInColumn";
import FlexInRow from "../../../../../Components/FlexInRow";
import SidebarMenu from "../../../../../Components/SidebarMenu";
import ContactForm from "../../../../../Components/ContactForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  img {
    width: 100%;
  }

  .link {
    color: ${(props) => props.theme.orange};
    font-weight: bold;
    margin-top: 0.854vw;
    place-self: flex-start;
    transition: 0.3s ease;
    text-decoration: none;
    text-align: left;
  }

  .link:hover {
    color: ${(props) => props.theme.orangeHover};
  }

  .link .icon {
    color: ${(props) => props.theme.blue};
    font-size: 1.354vw;
    margin-right: 5px;
    width: 1.667vw;
  }

  .link .icon path {
    fill: ${(props) => props.theme.blue};
  }

  .link:hover .icon {
    color: ${(props) => props.theme.blueHover};
  }

  .gallery-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: relative;
    width: 13.542vw;

    .icon {
      position: absolute;
      width: 3.385vw;
    }
  }

  .contact-form {
    margin-top: 50px;
  }

  @media (min-width: 992px) {
    .sidebar-menu {
      margin-left: auto;
    }
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    .link {
      font-size: 3.333vw;
      line-height: 4.444vw;
    }

    .gallery-item {
      width: 100%;

      .icon {
        width: 40px;
      }
    }
  }
`;

export default function Reuniao(props) {
  const SidebarMenuLinks = [
    {
      title: "Estágios",
      link: "/nispap/utilitarios/estagios",
    },
    {
      title: "Reuniões interserviços",
      link: "/nispap/utilitarios/reunioes-interservicos",
      selected: true,
    },
    {
      title: "Links úteis",
      link: "/nispap/utilitarios/links-uteis",
    },
  ];

  return (
    <Wrapper>
      <Container>
        <PageTitle
          title="Junho 2022"
          nispap={true}
          path={[
            {
              text: "NISPAP",
              link: "/nispap",
            },
            {
              text: "Utilitários",
              link: "/nispap/utilitarios",
            },
            {
              text: "Reuniões Interserviços",
              link: "/nispap/utilitarios/reunioes-interservicos",
            },
            {
              text: "Junho 2022",
            },
          ]}
          imageDesktop={require("../../../../../Assets/Images/NISPAP/img-reuniao-interservicos@2x.png")}
          imageMobile={require("../../../../../Assets/Images/NISPAP/img-reuniao-interservicos.png")}
          imageAlt="Junho 2022"
          sidebar={<SidebarMenu navItens={SidebarMenuLinks} />}
        />

        <FlexInRow gap="6.146vw">
          <FlexInColumn gap="12.396vw" mobile="column">
            <FlexInRow gap="2.604vw">
              <PageText title="Reveja os conteúdos da reunião interserviços - junho 2022" />
              <PageText text="Aceda aos documentos desta edição:" />

              <FlexInColumn
                gap="0.833vw"
                placeself="flex-start"
                mobile="column"
              >
                <div className="gallery-item">
                  <picture className="image">
                    <source
                      srcSet={require("../../../../../Assets/Images/NISPAP/doc-001@2x.png")}
                      media="(min-width: 990px)"
                    />
                    <img
                      src={require("../../../../../Assets/Images/NISPAP/doc-001.png")}
                      alt={"Gallery 01"}
                    />
                  </picture>
                  <picture className="icon">
                    <source
                      srcSet={require("../../../../../Assets/Images/NISPAP/bt-lupa@2x.png")}
                      media="(min-width: 990px)"
                    />
                    <img
                      src={require("../../../../../Assets/Images/NISPAP/bt-lupa.png")}
                      alt={"Gallery Icon"}
                    />
                  </picture>
                </div>
                <div className="gallery-item">
                  <picture className="image">
                    <source
                      srcSet={require("../../../../../Assets/Images/NISPAP/doc-002@2x.png")}
                      media="(min-width: 990px)"
                    />
                    <img
                      src={require("../../../../../Assets/Images/NISPAP/doc-002.png")}
                      alt={"Gallery 02"}
                    />
                  </picture>
                  <picture className="icon">
                    <source
                      srcSet={require("../../../../../Assets/Images/NISPAP/bt-lupa@2x.png")}
                      media="(min-width: 990px)"
                    />
                    <img
                      src={require("../../../../../Assets/Images/NISPAP/bt-lupa.png")}
                      alt={"Gallery Icon"}
                    />
                  </picture>
                </div>
                <div className="gallery-item">
                  <picture className="image">
                    <source
                      srcSet={require("../../../../../Assets/Images/NISPAP/doc-003@2x.png")}
                      media="(min-width: 990px)"
                    />
                    <img
                      src={require("../../../../../Assets/Images/NISPAP/doc-003.png")}
                      alt={"Gallery 03"}
                    />
                  </picture>
                  <picture className="icon">
                    <source
                      srcSet={require("../../../../../Assets/Images/NISPAP/bt-lupa@2x.png")}
                      media="(min-width: 990px)"
                    />
                    <img
                      src={require("../../../../../Assets/Images/NISPAP/bt-lupa.png")}
                      alt={"Gallery Icon"}
                    />
                  </picture>
                </div>
              </FlexInColumn>

              <a href="mailto:nucleointernosspap@gmail.com" className="link">
                <FontAwesomeIcon icon={faEnvelope} className="icon" />{" "}
                nucleointernosspap@gmail.com
              </a>
            </FlexInRow>
          </FlexInColumn>
        </FlexInRow>
      </Container>

      <ContactForm nispap={true} className="contact-form" />
    </Wrapper>
  );
}
