import styled from "styled-components";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Sidebar from "../../../Components/Backoffice/Sidebar";
import Card from "../../../Components/Backoffice/Card";
import Title from "../../../Components/Backoffice/Title";
import Header from "../../../Components/Backoffice/Header";
import Footer from "../../../Components/Backoffice/Footer";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
  Col,
  Row,
  Button,
  notification,
  Form,
  Input,
  Dropdown,
  message,
  Space,
  Tooltip,
  Radio,
  Checkbox,
  Modal,
  Message,
  Table,
  Upload,
  Select,
  Pagination,
  Divider,
} from "antd";

import axios from "axios";

import { ReactComponent as IconUsersSolid } from "../../../Assets/Images/Backoffice/Users-Solid.svg";
import { ReactComponent as IconDigestiva } from "../../../Assets/Images/img-default-grupo-de-estudo.svg";
import { tab } from "@testing-library/user-event/dist/tab";

const Wrapper = styled.main`
  background-color: #e8e9f3;
  display: grid;
  grid-template-columns: 7.083vw 1fr;
  min-height: 100vh;

  #newClube {
    background: #e8e9f3 0% 0% no-repeat padding-box;
    border: 4px dashed #d6d7e2;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 470px;

    .cta {
      border-radius: 12px;
      height: 46px;
    }
  }

  a {
    text-decoration: none;
  }

  @media (max-width: 992px) {
  }
`;

const Content = styled.div`
  padding: 1.771vw;
`;

const ContentHeader = styled.div`
  align-items: center;
  display: grid;
  gap: 22px;
  grid-template-columns: 1fr auto;
`;

const Grid = styled.div`
  display: grid;
  gap: 18px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 25px 0;

  .columnRow {
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    height: 100%;
    gap: 20px;
  }

  .item {
    background-color: #d6d7e2;
    border: none;
    border-radius: 16px;
    color: #8688a8;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    padding: 12px 0.625vw;
  }

  .space-gestor {
    min-height: 100px !important;
  }

  .item.disable {
    opacity: 0.5;
  }

  .item__image {
    position: relative;
    align-items: center;
    justify-content: center;
    border: 3px solid #0c1151;
    border-radius: 16px;
    max-height: 180px;
    display: flex;
    padding: 0;

    * {
      color: #0c1151;
      font-family: "Fira Sans";
      font-size: 20px;
      font-weight: bold;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .item__status {
    background-color: #ea3a3d;
    margin-bottom: 10px;
    color: #ffffff;
    padding: 6px 15px;
    border-radius: 8px;
    place-self: flex-start;
    width: min-content;
  }

  .item__status.active {
    background-color: #1ad598;
  }

  .item__cta {
    margin-right: auto;
  }

  .link {
    text-decoration: none;
  }

  @media (max-width: 1280px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 990px) {
    grid-template-columns: 1fr 1fr;
  }
`;

var styles = {
  h6_label: {
    color: "#809FB8",
    fontFamily: "Roboto",
    fontSize: "15px",
    marginTop: "1%",
  },
  input_class: {
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
    marginBottom: "1%",
  },
  switch_label: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    height: "45px",
    borderRadius: "8px",
    padding: "0px 16px",
    color: "#809FB8",
    border: "1px solid #809FB8",
    textAlign: "center",
  },
  upload: {
    width: "100%",
  },
};

export default function TodosClubes() {
  document.title = "SPAP - Todos Clubes";

  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const [value, setValue] = useState("");
  const [clubes, setClubes] = useState([]);
  const [modalNovoClubeOpen, setModalNovoClubeOpen] = useState(false);

  // const getClubes = () => {
  //   let allClubes = session_storage.all_allClubes;

  //   let membrosClubes = session_storage.all_data.membrosClubes;

  //   if (allClubes) {
  //     allClubes.forEach(clube => {
  //       clube.icon = '';
  //       clube.title = clube.name;
  //       clube.status = clube.status;

  //       let membrosClube = membrosClubes.filter((membrosClube) => {
  //         if(membrosClube.club_id == clube.id) {
  //           return membrosClube;
  //         }
  //       })

  //       clube.members = membrosClube.length;

  //       clube.gestores = [];

  //       membrosClube.map((membro) => {
  //         session_storage.all_data.users.map((user) => {
  //           if((user.id == membro.user_id) && (membro.member_type == 2)) {
  //             let nome = user.name;
  //             let apelido = user.apelido ? user.apelido : '';
  //             clube.gestores.push(nome + ' ' + apelido);
  //           }
  //         });
  //       })

  //       clube.image = clube.url ? 'https://api.spap.pt/clubes/' + clube.url : 'https://api.spap.pt/clubes/clube-estudo-default.svg';

  //     });
  //   }

  //   return allClubes;
  // };

  useEffect(() => {
    fetch(`https://api.spap.pt/api/all-membros-clubes`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0) {
          console.log("nenhum clube");
        }
        d;
        let allClubes = data.clubes;

        if (allClubes?.length > 0) {
          allClubes.forEach((clube) => {
            clube.icon = "";
            clube.title = clube.name;
            clube.status = clube.status;

            let membrosClube = data.membrosClubes?.filter((membrosClube) => {
              if (membrosClube.club_id == clube.id) {
                return membrosClube;
              }
            });

            clube.members = membrosClube.length;

            clube.gestores = [];

            membrosClube?.map((membro) => {
              data.users?.map((user) => {
                if (user.id == membro.user_id && membro.member_type == 2) {
                  let nome = user.name;
                  let apelido = user.apelido ? user.apelido : "";
                  clube.gestores.push(nome + " " + apelido);
                }
              });
            });

            clube.image = clube.url
              ? "https://api.spap.pt/clubes/" + clube.url
              : "https://api.spap.pt/clubes/clube-estudo-default.svg";
          });
        }

        setClubes(allClubes);
      })
      .catch((error) => console.error(error));
  }, []);

  const handleCancelModalNovoClube = () => {
    setModalNovoClubeOpen(false);
  };

  const handleFormNovoClube = (e) => {
    e.preventDefault();

    let nome = e.target.nome.value;

    axios
      .post(
        "https://api.spap.pt/api/admin/store-clube",
        {
          nome,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        if (res.allClubes.length) {
          session_storage.all_allClubes = res.allClubes;
          localStorage.setItem("session_data", JSON.stringify(session_storage));
          setClubes(getClubes);
          setModalNovoClubeOpen(false);
          notification.success({
            description: 'O clube "' + nome + '" foi criado com sucesso',
          });
        }
      })
      .catch((error) => {
        setModalNovoClubeOpen(false);
        notification.error({
          description:
            'O Clube "' +
            nome +
            '" já existe. Por favor, escolha outro nome para o clube.',
        });
      });
  };

  return (
    <Wrapper>
      <div>
        <Sidebar />
      </div>
      <Content>
        <Header />
        <ContentHeader>
          <Title>CLUBE(S) DE ESTUDO SPAP</Title>
          <Link onClick={() => setModalNovoClubeOpen(true)} className="link">
            <Button type="primary" className="cta">
              Criar novo clube
            </Button>
          </Link>
        </ContentHeader>
        <Grid>
          <Link onClick={() => setModalNovoClubeOpen(true)} id="newClube">
            <Button type="primary" className="cta">
              Criar novo clube
            </Button>
          </Link>
          {clubes &&
            clubes.length > 0 &&
            clubes.map((item, index) => {
              return (
                <div
                  className={
                    item.status == 1
                      ? "item item-" + index
                      : "item disable item-" + index
                  }
                  key={item.id}
                >
                  <div className="columnRow">
                    <div>
                      {item.image && (
                        <div
                          className="item__image"
                          style={{
                            backgroundPosition: "center center",
                            backgroundImage: "url(" + item.image + ")",
                            backgroundSize: "cover",
                            height: "180px",
                          }}
                        >
                          <Space direction="vertical" align="center">
                            {/* <img src={item.image}  style={{ width: '100%', height:'100%', borderRadius: '15px'}}/> */}

                            <div
                              style={{
                                position: "absolute",
                                width: "100%",
                                bottom: "0",
                                left: "0%",
                              }}
                            >
                              {item.title}
                            </div>
                          </Space>
                        </div>
                      )}
                      <div className="item__name">
                        <Space direction="vertical" className="space-gestor">
                          <h5 style={{ paddingTop: "10px" }}>
                            GESTOR(ES) DO CLUBE
                          </h5>
                          {item.gestores.length >= 1 ? (
                            <>
                              <Space.Compact direction="vertical">
                                {item.gestores.map((name, index) => {
                                  return <span key={index}>{name}</span>;
                                })}
                              </Space.Compact>
                            </>
                          ) : (
                            <>
                              <Space.Compact direction="vertical">
                                <span key={index}>Por atribuir</span>
                              </Space.Compact>
                            </>
                          )}
                        </Space>
                      </div>
                    </div>
                    <div>
                      <div
                        className={
                          item.status ? "item__status active" : "item__status"
                        }
                      >
                        {item.status ? "ATIVO" : "INATIVO"}
                      </div>
                      <div className="item__members">
                        <Space direction="vertical" size={0}>
                          <h5>MEMBROS</h5>
                          <Space>
                            <IconUsersSolid /> {item.members}
                          </Space>
                        </Space>
                      </div>
                      <div className="item__cta">
                        <Link
                          to={"/dashboard/clubes/" + item.id}
                          className="link"
                        >
                          <Button type="primary" className="cta">
                            Aceder ao clube
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </Grid>
        <Footer />
      </Content>

      <Modal
        className="modal-novo-clube"
        open={modalNovoClubeOpen}
        title="Adicionar novo Clube"
        onCancel={handleCancelModalNovoClube}
      >
        {" "}
        <form onSubmit={handleFormNovoClube}>
          <Input
            type="text"
            required
            name="nome"
            style={styles.input_class}
            placeholder="Nome"
          />
          <Input
            type="submit"
            value="Guardar"
            style={{
              marginInlineStart: "0",
              background: "#494D7D",
              color: "#fff",
              borderRadius: "6px",
              width: "100%",
              height: "45px",
              marginTop: "2%",
            }}
          />
        </form>
        <Button
          onClick={handleCancelModalNovoClube}
          style={{
            marginInlineStart: "0",
            background: "#fff",
            color: "#494D7D",
            border: "1px solid #494D7D",
            borderRadius: "6px",
            width: "100%",
            height: "45px",
            marginTop: "2%",
          }}
        >
          Voltar
        </Button>
      </Modal>
    </Wrapper>
  );
}
