import styled from "styled-components";

import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Container from "../../../../Components/Container";
import FlexInRow from "../../../../Components/FlexInRow";
import FlexInColumn from "../../../../Components/FlexInColumn";
import PageTitle from "../../../../Components/PageTitle";
import SupportedBySpap from "../../../../Components/SupportedBySpap";

import IconFile from "../../../../Assets/Images/icon-awesome-file-alt.svg";
import { Button, Divider, Space, Image, Row, Col } from "antd";
import JuntarseClube from "../../../../Components/JuntarseClube";
import SidebarMenu from "../../../../Components/SidebarMenu";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #000000;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: left;
  width: 100%;
  margin-top: 7.813vw;

  > .content {
    padding-bottom: 5.208vw;
  }

  img {
    width: 100%;
  }

  .link {
    color: ${(props) => props.theme.orange};
    transition: 0.3s ease;
  }

  .link:hover {
    color: ${(props) => props.theme.orangeHover};
  }

  .cta {
    background-color: ${(props) => props.theme.orange};
    border: none;
    color: ${(props) => props.theme.white};
    transition: 0.3s ease;
  }

  .cta:hover {
    background-color: ${(props) => props.theme.orangeHover};
    color: ${(props) => props.theme.whiteHover};
  }

  .row-wrap {
    flex-wrap: wrap !important;
  }

  .sidebar__cta {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0.521vw;
    color: #0c1151;
    opacity: 1;
    text-decoration: none;
    text-align: center;
    letter-spacing: 0px;
    transition: 0.3s ease;
    font-size: 1.146vw;
    font-weight: bold;
    line-height: 1.51vw;
    border: 0.156vw solid #0c1151;
    padding: 0.781vw 1.563vw;
  }

  .sidebar__cta:hover {
    background-color: #0c1151;
    border-color: transparent;
    color: #e5e5e5;
  }

  .sidebar__cta.blue {
    background: #0c1151 0% 0% no-repeat padding-box;
    border: 0.156vw solid transparent;
    color: #ffffff;
  }

  .sidebar__cta.blue:hover {
    background-color: #8688a8;
    color: #e5e5e5;
  }

  .bar {
    height: 1px;
    width: 66.854vw;
    background-color: #d6cdf7;
    opacity: 1;
    margin: auto;
  }

  .manager__image {
    display: block;
    width: 89px;
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    span {
      line-height: 14px;
    }
  }
`;

const Title = styled.h2`
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;

  @media (max-width: 992px) {
    font-size: 3.333vw;
    line-height: 4.444vw;
  }
`;

const ListPosts = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 3.021vw;
  grid-row-gap: 3.646vw;
  padding: 0 0 5.208vw;
  width: 100%;

  .item {
    border: 3px solid #0c1151;
    border-top: 12px solid #0c1151;
    border-radius: 0px 0px 20px 20px;
    opacity: 1;
  }

  .item__content {
    color: #0c1151;
    display: flex;
    flex-direction: column;
    gap: 1.458vw;
    padding: 1.042vw 1.042vw 1.563vw;
    text-align: left;
  }

  .item__title {
    align-items: center;
    justify-content: flex-start;
    gap: 1.042vw;
    display: flex;
    flex-direction: row;

    img {
      width: 5.208vw;
      margin-top: -2.604vw;
    }

    .title {
      text-align: left;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0px;
      line-height: inherit;
      letter-spacing: 0px;
      color: #0c1151;
      opacity: 1;
    }
  }

  p {
    text-align: left;
    font-size: 0.938vw;
    letter-spacing: 0px;
    line-height: 1.25vw;
    color: #000000;
    margin: 0;
  }

  .item__cta {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    gap: 2.604vw;
    text-align: center;

    a {
      background: #f88436 0% 0% no-repeat padding-box;
      border: none;
      border-radius: 0.521vw;
      opacity: 1;
      font-size: 1.146vw;
      font-weight: bold;
      letter-spacing: 0px;
      line-height: 1.51vw;
      color: #ffffff;
      opacity: 1;
      padding: 0.677vw 1.563vw;
      max-width: 8.281vw;
      transition: 0.3s ease;
      text-decoration: none;
    }

    a:hover {
      background-color: #e87426;
      color: #ececec;
    }
  }

  @media (max-width: 992px) {
    p,
    .item__title .title,
    .item__cta a {
      font-size: 3.333vw;
      line-height: 4.444vw;
    }
  }
`;

const SectionCards = styled.div`
  display: grid;
  gap: 2.604vw;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 5.208vw;

  .item {
    border: 0.156vw solid #494d7d;
    border-radius: 1.042vw;
    justify-content: space-between;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr auto;
  }

  .item__content {
    padding: 1.042vw;
  }

  .item__image {
    border: none;
    overflow: hidden;
    width: 13.542vw;

    img {
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }

  .item__title {
    text-align: left;
    font-size: 16px;
    line-height: inherit;
    font-weight: bold;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    margin: 1.094vw 0;
  }

  .item__text {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 0.729vw;
  }

  .item__cta {
    align-items: center;
    justify-content: center;
    display: flex;
    background: #0c1151 0% 0% no-repeat padding-box;
    border-radius: 0.521vw;
    opacity: 1;
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    text-decoration: none;
    color: #ffffff;
    opacity: 1;
    width: 8.021vw;
    height: 2.396vw;
    transition: 0.3s ease;
  }

  .item__cta:hover {
    background-color: #8688a8;
    color: #e5e5e5;
  }

  .item__date {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.521vw;
  }

  .item__date svg {
    font-size: 1.302vw;
  }

  @media (max-width: 992px) {
    .item__text,
    .item__cta,
    .item__date,
    .item__date svg {
    }
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  p {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  a {
    justify-content: flex-start;
    margin-top: 0.938vw;
    font-size: 0.938vw;
    font-weight: bold;
    line-height: 1.25vw;
    text-align: left;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    text-decoration: none;
  }

  .icon {
    margin-right: 8px;
  }

  .icon-file {
    width: 3.438vw;
  }

  @media (max-width: 992px) {
    p,
    a {
      font-size: 2.833vw;
      line-height: 3.944vw;
    }

    .icon-file {
      width: 35px;
    }
  }
`;

const GalleryImage = styled.div`
  display: grid;
  grid-template-columns: 307px;
  gap: 10px;

  @media (max-width: 992px) {
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 80px;

  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export default function ClubeNovoDocumentos(props) {
  const { slug } = useParams();
  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const [filesPDF, setFilesPDF] = useState("");
  const [clube, setClube] = useState("");

  useEffect(() => {
    fetch(`https://api.spap.pt/api/all-ficheiros-clubes`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.clubes.length === 0) {
          return;
        }

        getClube(data.clubes);
        getFilesPDF(data.ficheiros);
      })
      .catch((error) => console.error(error));
  }, []);

  const getClube = (clubes) => {
    let myClube = [];
    clubes?.length > 0 &&
      clubes.map((clube) => {
        if (clube.id == slug.split("-").pop()) {
          myClube.push(clube);
          clube.url = clube.url
            ? "https://api.spap.pt/clubes/" + clube.url
            : "https://api.spap.pt/clubes/clube-estudo-default.svg";
        }
      });

    setClube(myClube[0]);
  };

  const getFilesPDF = (ficheiros) => {
    if (!ficheiros.length) {
      return [];
    }

    let clubeFiles = ficheiros.filter((file) => {
      if (file.club_id == slug.split("-").pop() && file.status == 1)
        return file;
    });
    clubeFiles.map((clubeFile) => {
      let date = clubeFile.updated_at.substring(0, 10);
      let [year, month, day] = date.split("-");
      clubeFile.dataToShow = day + "/" + month + "/" + year;
    });

    setFilesPDF(clubeFiles);
  };

  const ListDocuments = [
    {
      title: "a",
      link: "link",
    },
    {
      title: "a",
      link: "link",
    },
  ];

  const SidebarMenuLinks = [
    {
      title: clube.name,
      link: "/socios/clubes-de-estudo/" + slug,
    },
    {
      title: "Membros",
      link: "/socios/clubes-de-estudo/" + slug + "/membros",
    },
    {
      title: "Eventos",
      link: "/socios/clubes-de-estudo/" + slug + "/eventos",
    },
    {
      title: "Galeria",
      link: "/socios/clubes-de-estudo/" + slug + "/galeria",
    },
    {
      title: "Documentos",
      link: "/socios/clubes-de-estudo/" + slug + "/documentos",
    },
  ];

  return (
    <Wrapper>
      <Container className="content">
        <PageTitle
          title={clube.name}
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "Sócios",
              link: "/socios",
            },
            {
              text: "Clubes de estudo",
              link: "/socios/clubes-de-estudo",
            },
            {
              text: clube.name,
              link: "/socios/clubes-de-estudo/" + slug + "",
            },
            {
              text: "Documentos",
            },
          ]}
          imageDesktop={clube.url}
          imageMobile={clube.url}
          imageAlt={clube.name}
          border={false}
          sidebar={
            <SidebarMenu navItens={SidebarMenuLinks} className="sidebar" />
          }
          clubes={true}
        />

        <FlexInRow>
          <Row gutter={[30, 8]}>
            <Col span={24}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Divider orientation="left" orientationMargin="0">
                  <Title>
                    {filesPDF.length > 0
                      ? "Recursos para si"
                      : "Nenhum recurso disponível"}
                  </Title>
                </Divider>
                <Row align="initial" className="row-wrap" gutter={[20, 30]}>
                  {filesPDF.length > 0
                    ? filesPDF.map((item, index) => {
                        return (
                          <Col xs={24} lg={8} md={12} style={{ width: "100%" }}>
                            <Space direction="vertical" size={10}>
                              <Title>{item.titulo}</Title>
                              <Text>
                                <img
                                  src={IconFile}
                                  alt="Icon File"
                                  className="icon icon-file"
                                />
                                <p>
                                  {item.descricao}
                                  <br></br>
                                  <a
                                    href={
                                      "https://api.spap.pt/clubes/" +
                                      slug.split("-").pop() +
                                      "/ficheiros/" +
                                      item.url
                                    }
                                    target="_blank"
                                  >
                                    Clique para visualizar
                                  </a>
                                </p>
                              </Text>
                            </Space>
                          </Col>
                        );
                      })
                    : ""}
                </Row>
              </Space>
            </Col>
          </Row>
        </FlexInRow>
      </Container>

      <SupportedBySpap title="Veja Também" />
    </Wrapper>
  );
}
