import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Card from "../Card";
import Title from "../Title";
import {
  Button,
  Space,
  message,
  Upload,
  Input,
  Form,
  Pagination,
  Switch,
  notification,
} from "antd";

import { ReactComponent as IconUpload } from "../../../Assets/Images/Backoffice/Upload.svg";
import { ReactComponent as IconCalendar } from "../../../Assets/Images/Backoffice/Calendar.svg";
import { ReactComponent as IconClose } from "../../../Assets/Images/Backoffice/Close.svg";
import { ReactComponent as IconEdit } from "../../../Assets/Images/Backoffice/Edit.svg";
import { ReactComponent as IconUsersSolid } from "../../../Assets/Images/Backoffice/Users-Solid.svg";
import { ReactComponent as IconInfo } from "../../../Assets/Images/Backoffice/Notifications/Info.svg";
import { all } from "axios";

const { Dragger } = Upload;
const { TextArea } = Input;

const props = {
  name: "file",
  multiple: true,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 40px;

  h4 {
    text-align: left;
    font: normal normal bold 20px/20px Roboto;
    letter-spacing: 0px;
    color: #050505;
  }

  .text {
    color: #809fb8;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0px;
  }

  .center {
    text-align: center;
  }

  .cta {
    display: block !important;
    font-size: 15px;
    font-weight: bold;
    height: 45px;
    line-height: 18px;

    i {
      margin: 0 3px;
    }

    a {
      text-decoration: none;
    }
  }

  .cta.publish {
    margin-right: 0;

    svg path {
      fill: #fff;
    }
  }
`;

const AlertMesssage = styled.div`
  background: #f1f5f7;
  border: 2px solid #ffffff;
  border-radius: 16px;
  text-align: left;
  letter-spacing: 0px;
  padding: 10px;
  color: #0c1151;
  font-style: italic;

  circle {
    fill: #0c1151;
  }

  svg path {
    fill: #fff;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 18px;

  .form {
    place-self: flex-start;
    width: 100%;
  }
`;

const GridCards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 18px;

  .item {
    margin: 0;
  }

  .item__title {
    color: #494d7d;
    letter-spacing: 0px;
    font-size: 18px;
    font-weight: bold;
    line-height: 18px;
    text-align: left;
  }

  .item__text {
    color: #c2c4d4;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  grid-template-columns: 1fr auto;
`;

const ContentFooter = styled.div`
  align-items: center;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  margin-top: 18px;

  button {
    display: block !important;
    font-size: 15px;
    font-weight: bold;
    height: 45px;
    line-height: 18px;

    i {
      margin: 0 3px;
    }
  }

  a {
    text-decoration: none;
  }
`;

export default function RecursosParaSi(props) {
  const session_storage = JSON.parse(localStorage.getItem("session_data"));

  const [fileUploadList, setFileUploadList] = useState("");
  const [tituloPDF, setTituloPDF] = useState("");
  const [descricaoPDF, setDescricaoPDF] = useState("");

  const [filesPDF, setFilesPDF] = useState([]);

  const propPDF = {
    onRemove: (file) => {
      const index = fileUploadList.indexOf(file);
      const newFileList = fileUploadList.slice();
      newFileList.splice(index, 1);
      setFileUploadList(newFileList);
    },
    beforeUpload: (file) => {
      const isPDF = file.type === "application/pdf";
      if (!isPDF) {
        message.error(`${file.name} não é um ficheiro PDF`);
        setFileUploadList([]);
        return isCSV || Upload.LIST_IGNORE;
      }

      setFileUploadList([...fileUploadList, file]);
      return false;
    },
    fileUploadList,
  };

  const ListEvents = [
    {
      id: 1,
      title: "21st Meeting of the EAHP",
      text: "The European Association for Haematopathology (EAHP) is a professional society whose aim is to study diseases of the haematopoietic and lymphoid systems and to promote (…)",
      date: "28/11/2022",
    },
    {
      id: 2,
      title: "Evento 02",
      text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.",
      date: "28/11/2022",
    },
    {
      id: 3,
      title: "Evento 03",
      text: "The European Association for Haematopathology (EAHP) is a professional society whose aim is to study diseases of the haematopoietic and lymphoid systems and to promote (…)",
      date: "28/11/2022",
    },
    {
      id: 4,
      title: "Evento 04",
      text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.",
      date: "28/11/2022",
    },
    {
      id: 5,
      title: "Evento 05",
      text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.",
      date: "28/11/2022",
    },
    {
      id: 6,
      title: "21st Meeting of the EAHP",
      text: "The European Association for Haematopathology (EAHP) is a professional society whose aim is to study diseases of the haematopoietic and lymphoid systems and to promote (…)",
      date: "28/11/2022",
    },
    {
      id: 7,
      title: "Evento 02",
      text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.",
      date: "28/11/2022",
    },
    {
      id: 8,
      title: "Evento 03",
      text: "The European Association for Haematopathology (EAHP) is a professional society whose aim is to study diseases of the haematopoietic and lymphoid systems and to promote (…)",
      date: "28/11/2022",
    },
    {
      id: 9,
      title: "Evento 04",
      text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.",
      date: "28/11/2022",
    },
    {
      id: 10,
      title: "Evento 05",
      text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.",
      date: "28/11/2022",
    },
  ];

  const changeFiles = (e) => {
    const newFiles = props.onFilesChange(e);
    console.log(newFiles);
    setAllItems(newFiles);
    console.log(allItems);
  };

  console.log("teste files");
  console.log(props.files);

  const [allItems, setAllItems] = useState(props.files);

  const [currentItems, setCurrentItems] = useState(allItems.slice(0, 8));
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 8;

  const handlePage = (page) => {
    const newOffset = ((page - 1) * itemsPerPage) % allItems.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(allItems.slice(itemOffset, endOffset));
  }, [itemOffset, allItems]);

  const [itemSwitch, setItemSwitch] = useState([]);
  const onChange = (checked, id) => {
    console.log(`switch to ${checked}`);
    let newItemSwitch = itemSwitch;
    newItemSwitch[id] = checked;
    setItemSwitch(newItemSwitch);
  };

  const handleSubmitPDF = (e) => {
    if (!fileUploadList.length || !tituloPDF.length || !descricaoPDF.length) {
      notification.error({
        description:
          "Para guardar um novo documento, preencha todos os campos e faça o upload do ficheiro.",
      });
      return;
    }

    const formData = new FormData();

    formData.append("titulo", tituloPDF);
    formData.append("file", fileUploadList[0]);
    formData.append("descricao", descricaoPDF);
    formData.append("clubeId", props.clubeId);

    // You can use any AJAX library you like
    fetch("https://api.spap.pt/api/admin/store-clube-file", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            console.log(data);
            session_storage.all_data.ficheirosClubes.push(data.ficheiro);
            localStorage.setItem(
              "session_data",
              JSON.stringify(session_storage)
            );
            setFileUploadList([]);
            setTituloPDF("");
            setDescricaoPDF("");
            changeFiles(data.ficheiros);
          })
          .catch((err) => {});
      })
      .then(() => {
        setFileUploadList([]);
        notification.success({
          description: "O ficheiro foi submetido com sucesso!",
        });
      })
      .catch(() => {
        notification.error({
          description: "Erro em submeter o ficheiro! Tente novamente.",
        });
      });
  };

  return (
    <Wrapper>
      <Space size={60}>
        <Title>
          RECURSOS PARA SI | Recursos disponíveis para download na página do
          clube
        </Title>
        <AlertMesssage>
          {/* <Space size={8}>
            <IconInfo className='icon icon-info' />
            <span>Para poder alterar a ordem dos PDF´s na página do clube basta fazer "drag and drop" na listagem acima</span>
          </Space> */}
        </AlertMesssage>
      </Space>
      <Grid>
        <Card className="form" key={"new-pdf"}>
          <Space direction="vertical" className="w-100">
            <h4>Faça upload do seu PDF</h4>
            <label className="text">Titulo do PDF</label>
            <Input
              name="titulo_pdf"
              onChange={(e) => setTituloPDF(e.target.value)}
            />
            <label className="text">Descrição do PDF</label>
            <TextArea
              name="descricao_pdf"
              rows={4}
              onChange={(e) => setDescricaoPDF(e.target.value)}
            />
            <Dragger {...propPDF}>
              <p className="ant-upload-drag-icon">
                <IconUpload />
              </p>
              <p className="ant-upload-text text">Upload de novo ficheiro</p>
              <p className="ant-upload-hint">Faça Drag and drop aqui</p>
            </Dragger>
            <p className="text center" align="center">
              Tamanho máximo de upload (2 MB)
            </p>
            <Input
              onClick={handleSubmitPDF}
              type="button"
              className="cta w-100"
              value="Upload"
            />
          </Space>
        </Card>
        {allItems.length > 0 && (
          <Space direction="vertical">
            <GridCards>
              {currentItems.map((item, index) => {
                return (
                  <Card
                    className={"item item-" + index}
                    key={"ficheiros-" + item.id}
                  >
                    <CardHeader>
                      <Title className="item__title">{item.titulo}</Title>
                      <IconClose
                        className="icon icon-close"
                        onClick={(e) => alert("excluir?")}
                      />
                    </CardHeader>
                    <Space direction="vertical">
                      <p className="item__text">{item.descricao}</p>
                      <Space size={30}>
                        <Space>
                          <IconCalendar className="icon icon-calendar" />
                          <span className="text">{item.updated_at}</span>
                        </Space>
                        {/*
                          #TODO esta interferindo no layout, ver se é necessario por ja estar na pagina do clube
                        }
                        <Space>
                          <IconUsersSolid className='icon icon-hours' />
                          <span className='text'>Patologia Gastroentestinal</span>
                        </Space>
                        {
                          */}
                        <Space>
                          <Switch
                            checked={itemSwitch[item.id]}
                            onChange={(e) => onChange(e, item.id)}
                          />
                          <span className="text">
                            {itemSwitch[item.id] ? "Ativo" : "Desativo"}
                          </span>
                        </Space>
                        <Space
                          className="action-edit"
                          onClick={(e) => alert("editar?")}
                        >
                          <IconEdit className="icon icon-edit" />
                          <span>Editar</span>
                        </Space>
                      </Space>
                    </Space>
                  </Card>
                );
              })}
            </GridCards>
            <ContentFooter>
              <Pagination
                defaultCurrent={1}
                total={allItems.length}
                pageSize={itemsPerPage}
                onChange={() => handlePage}
              />
            </ContentFooter>
          </Space>
        )}
      </Grid>
    </Wrapper>
  );
}
