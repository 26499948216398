import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  notification,
  Flex,
  Drawer,
  Input,
  Upload,
  Typography,
  Space,
  Row,
  Col,
  Divider,
  Form,
  Button,
  Steps,
  Radio,
  Statistic,
} from "antd";
const { Dragger } = Upload;
const { TextArea } = Input;
import axios from "axios";
import CarEmpty from "../../Assets/Images/carrinho-illustration-empty.png";
import CheckMark from "../../Assets/Images/icons8-check.gif";
import EventoTeste from "../../Assets/Images/evento-teste.jpg";
import styled from "styled-components";
import { DeleteOutlined } from "@ant-design/icons";
import MultibancoImage from "../../Assets/Images/Pagamentos/Multibanco.svg";
import MBImage from "../../Assets/Images/Pagamentos/MB.svg";
import MBWayImage from "../../Assets/Images/Pagamentos/MB-Way.svg";
import {
  EuroCircleOutlined,
  SolutionOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { parse } from "date-fns";
import urlSlug from "url-slug";
import { act } from "react";
import dayjs from "dayjs";
const { Countdown } = Statistic;

const Section = styled.div`
  padding: 15px;
  * {
    color: #494d7d;
  }

  h5 {
    font-size: 20px !important;
    margin-bottom: 0;
  }

  .apply-code-btn {
    border-radius: 12px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .apply-code-btn,
  .inputCode {
    border: 1px solid #494d7d !important;
    color: #494d7d;
    height: 35px;
  }

  .inputDados {
    border: 1px solid #dfdfe4 !important;
    color: #494d7d;
    height: 35px;
  }

  .radio-btn {
    height: 50px;
    line-height: 50px;
  }

  .voucher-info {
    background: #b7eb8f;
    border-radius: 10px;
    font-size: 14px;
    color: rgb(12, 17, 81);
    padding: 10px 20px;
    margin-bottom: 10px;
  }

  .remove-voucher {
    margin-top: 5px;
    background: #f5f5f5;
    border-radius: 6px;
    padding: 2px 5px;
  }

  .remove-voucher:hover {
    background: #dcdcdc;
  }

  .voucher-discount {
    background: #b7eb8f;
    border-radius: 10px;
    font-size: 12px;
    color: rgb(12, 17, 81);
    padding: 4px 20px;
    margin-bottom: 10px;
    margin-left: 10px;
  }

  .discount {
    font-size: 16px;
    margin-bottom: 0;
    text-align: right;
    margin-bottom: 10px;
  }

  .subtotal {
    font-size: 16px;
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: bold;
    text-align: right;
  }

  .total {
    font-size: 20px;
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: bold;
    text-align: right;
  }

  h6 {
    font-size: 16px;
  }

  .subtitle {
    font-size: 17px;
  }

  .view-btn {
    color: #fff;
    background: #494d7d;
    padding: 10px 30px;
    border-radius: 5px;
    text-decoration: none !important;
    margin-top: 20px;
  }

  .view-more {
    text-decoration: underline;
  }

  .delete-icon {
    font-size: 15px;
  }

  .margin-vertical-auto {
    margin: auto 0;
  }

  .next-btn,
  .next-btn span {
    background: #494d7d !important;
    color: #ffffff !important;
  }

  .pre-btn,
  .pre-btn span {
    background: #ffffff !important;
    color: #494d7d !important;
  }

  .next-btn,
  pre-btn {
    height: auto !important;
  }

  .ant-btn {
    height: 35px !important;
  }

  @media (max-width: 600px) {
    padding: 0 !important;

    .mbway-image {
      width: 100% !important;
      text-align: center;
      margin-bottom: 10px !important;
    }

    .p-l-0-mobile {
      padding-left: 0 !important;
    }

    .center-mobile {
      text-align: center !important;
    }

    .col-event {
      display: none !important;
    }

    .voucher-discount {
      width: 100%;
      margin-top: 10px;
      margin-left: 0;
    }
    .row-detail {
      flex-direction: column !important;
    }

    .apply-code-btn {
      width: 100% !important;
    }

    .paymentSubmit {
      margin-top: 0 !important;
    }
  }
`;

const Card = styled.div`
  background-color: #e8e9f3;
  border-radius: 16px;
  margin-bottom: 50px;
  padding: 30px;
  width: 100%;

  h3 {
    text-align: left;
    font: normal normal bold 22px/32px Roboto;
    letter-spacing: 0px;
    color: #494d7d;
  }

  .paymentSubmit {
    margin-top: 10px;
    background: #f88436 0% 0% no-repeat padding-box;
    border-radius: 10px;
    text-align: left;
    font: normal normal bold 18px/22px Roboto;
    letter-spacing: 0px;
    color: #ffffff;
    border: none;
    height: 52px !important;
    padding: 0px 25px;

    span,
    :hover {
      color: #efefef;
    }
  }

  .cancelPayment {
    margin-top: 10px;
    background: transparent !important;
    border-radius: 10px;
    text-align: left;
    font: normal normal bold 18px/22px Roboto;
    border: 1px solid #f88436 !important;
    letter-spacing: 0px;
    color: #f88436 !important;
    height: 52px !important;
    padding: 0px 25px;

    span,
    :hover {
      color: #f88436;
    }
  }

  p {
    text-align: left;
    font: normal normal normal 14px/17px Roboto;
    letter-spacing: 0px;
    color: #494d7d;
    margin: 0;
  }

  p.info {
    text-align: left;
    font: normal normal normal 12px/14px Roboto;
    letter-spacing: 0px;
    color: #494d7d;
  }

  .paymentValue {
    text-align: center;
    font: normal normal bold 60px/24px Roboto;
    letter-spacing: 0px;
    color: #2ecc71;
    margin: 15px 0;
  }

  .paymentSubmit {
    background: #f88436 0% 0% no-repeat padding-box;
    border-radius: 10px;
    text-align: left;
    font: normal normal bold 18px/22px Roboto;
    letter-spacing: 0px;
    color: #ffffff;
    border: none;
    height: 45px !important;
    padding: 0 25px;

    :hover {
      color: #efefef;
    }
  }

  .cancelPayment {
    background: transparent !important;
    border-radius: 10px;
    text-align: left;
    font: normal normal bold 18px/22px Roboto;
    border: 1px solid #f88436 !important;
    letter-spacing: 0px;
    color: #f88436 !important;
    height: 45px !important;
    padding: 0 25px;
  }

  &.white {
    background-color: #ffffff;
    border-radius: 8px;
  }

  &.flex {
    display: flex;
    flex-direction: column;
    gap: 14px;

    p {
      margin: auto;
      text-align: center;
    }
  }

  &.boxShadow {
    box-shadow: 0px 3px 10px #00000029;
  }

  &.radio {
    margin-bottom: 20px;
    padding: 12px 16px;
  }

  &.paymentAlert {
    padding-top: 60px;
  }
`;
const Step = styled.div`
  margin-top: 30px;
`;

const { Title } = Typography;
const { Text } = Typography;

export default function EventShoppingCart(props) {
  let userDrawer = props.user;
  const customFormat = "dd/MM/yyyy";
  const [form] = Form.useForm();

  const [loadings, setLoadings] = useState([]);
  const [loadingsCancel, setLoadingsCancel] = useState([]);
  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const [isEmpty, setIsEmpty] = useState(false);
  const cartLocalStorage = JSON.parse(localStorage.getItem("cartList") || "[]");
  const [cartList, setCartList] = useState(cartLocalStorage);
  const [eventos, setEventos] = useState([]);
  const [eventosList, setEventosList] = useState([]);
  const [eventosPagos, setEventosPagos] = useState([]);
  const [vouchers, setVouchers] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [tipoSocio, setTipoSocio] = useState("");
  const [show, setShow] = useState(false);
  const [cartTotal, setCartTotal] = useState(0);
  const [activeVoucher, setActiveVoucher] = useState([]);
  const [promoPrice, setPromoPrice] = useState(0);
  const [allPurchases, setAllPurchases] = useState([]);
  const [paymentType, setPaymentType] = useState("");
  const [pendentAlert, setPendentAlert] = useState({});
  const [countdown, setCountdown] = useState();
  const [confirmPayment, setConfirmPayment] = useState(true);

  const [current, setCurrent] = useState(0);

  useEffect(() => {
    setConfirmPayment(false);
    setOpenDrawer(props.show);
    if (props.show) {
      setCurrent(0);
      getEventos();
      verifyPendentPayment();
    }
  }, [props.show]);

  useEffect(() => {
    if (pendentAlert && Object?.keys(pendentAlert)?.length) {
      console.log(pendentAlert);
      var pendingDate = new Date(pendentAlert.payment_date);
      var expiryDate = new Date(pendentAlert.payment_date);
      expiryDate.setHours(expiryDate.getHours() + 1);
      if (pendentAlert.payment_type == "Multibanco") {
        expiryDate.setDate(expiryDate.getDate() + 3);
      } else {
        expiryDate.setMinutes(expiryDate.getMinutes() + 5);
      }
      console.log(new Date());
      console.log(expiryDate);
      // Add five days to current date
      if (new Date() >= expiryDate) {
        fetchPaymentStatus(pendentAlert.payment_id, pendentAlert);
      } else {
        setCurrent(2);
        setCountdown(expiryDate.getTime());
      }x
    }
  }, [pendentAlert]);

  const handleListEventos = (
    allEventos,
    allVouchers,
    allPaymentDetails,
    myPurchases,
    currentUser
  ) => {
    let cartList = JSON.parse(localStorage.getItem("cartList"));
    if (cartList == null || !cartList.length) {
      localStorage.setItem("countCartItems", 0);
      setIsEmpty(true);
      setShow(true);
      return;
    }
    let thisEventos = allEventos.filter((evento) => {
      return cartList.includes(evento.id);
    });
    let eventosAMostrar = [];
    let total = 0;
    for (let i = 0; i < thisEventos.length; i++) {
      const evento = thisEventos[i];
      let eventTickets = allPaymentDetails?.filter((eventPayment) => {
        return eventPayment.event_id == evento.id;
      });
      if (!eventTickets.length) {
        continue;
      }
      if (myPurchases.length > 0 && myPurchases.includes(evento.id)) {
        continue;
      }
      let tickets = JSON.parse(eventTickets[0].tickets);
      let available = checkIfReachedMaxTickets(
        eventTickets[0].count_tickets,
        tickets
      );
      if (!available) {
        continue;
      }
      let price = handlePrecoEventosByCargo(eventTickets[0], currentUser);
      if (price.priceWithoutPromo == 0) {
        continue;
      }
      evento.lastPrice = "";
      evento.discountMessage = "";
      evento.currentPrice = price.current;
      evento.hasDiscount = price.hasDiscount;
      evento.priceWithoutPromo = price.priceWithoutPromo;
      total += evento.hasDiscount
        ? evento.currentPrice
        : evento.priceWithoutPromo;
      evento.slug = urlSlug(evento.titulo);
      evento.slug += "-" + evento.id;
      evento.url = evento.imagem_destaque
        ? "https://api.spap.pt/eventos/" +
          evento.id +
          "/" +
          evento.imagem_destaque
        : "https://spap.pt/clubes/clube-estudo-default.svg";
      eventosAMostrar.push(evento);
    }

    if (!eventosAMostrar.length) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }

    setEventosList(eventosAMostrar);
    setCartTotal(total.toFixed(2));
    localStorage.setItem("countCartItems", eventosAMostrar.length);
    handlePromoCodePrice(eventosAMostrar, allVouchers);
    setShow(true);
  };

  const checkIfReachedMaxTickets = (count_tickets, tickets) => {
    let qtt = tickets.reduce((n, { qtd }) => n + qtd, 0);
    if (count_tickets >= qtt) {
      return false;
    }

    return true;
  };

  const handlePrecoEventosByCargo = (eventPaymentDetails, currentUser) => {
    let hasDiscount = false;
    let price = 0;
    let currentPrice = 0;
    let tickets = JSON.parse(eventPaymentDetails.tickets);

    let allUsers = tickets.some((e) => e.cargo == "todos");
    if (allUsers) {
      price = tickets.filter((e) => e.cargo == "todos")[0].preco;
      let isPromo = eventPaymentDetails.is_promo;
      currentPrice = price;
      if (isPromo && checkPromoIsAvailable(eventPaymentDetails)) {
        hasDiscount = true;
        let valor = eventPaymentDetails.valor;
        let tipo = eventPaymentDetails.tipo_valor;

        if (tipo == "perc") {
          currentPrice = price - (price * valor) / 100;
        } else {
          currentPrice = price - valor;
        }
      }
    } else {
      if (!session_storage?.all_data || session_storage?.all_data.user_session.tipo_utilizador == 'nao_socio') {
        let naoSocio = tickets.some((e) => e.cargo == "nao_socio");
        if (naoSocio) {
          price = tickets.filter((e) => e.cargo == "nao_socio")[0].preco;
          let isPromo = eventPaymentDetails.is_promo;
          currentPrice = price;
          if (isPromo && checkPromoIsAvailable(eventPaymentDetails)) {
            hasDiscount = true;
            let valor = eventPaymentDetails.valor;
            let tipo = eventPaymentDetails.tipo_valor;

            if (tipo == "perc") {
              currentPrice = price - (price * valor) / 100;
            } else {
              currentPrice = price - valor;
            }
          }
        }
        return {
          priceWithoutPromo: price,
          current: currentPrice,
          hasDiscount: hasDiscount,
        };
      }
      if (
        tickets.some((e) => e.cargo == currentUser?.tipoSocio?.toLowerCase())
      ) {
        price = tickets.filter(
          (e) => e.cargo == currentUser?.tipoSocio?.toLowerCase()
        )[0].preco;
        currentPrice = price;
        let isPromo = eventPaymentDetails.is_promo;
        if (isPromo && checkPromoIsAvailable(eventPaymentDetails)) {
          hasDiscount = true;
          let valor = eventPaymentDetails.valor;
          let tipo = eventPaymentDetails.tipo_valor;

          if (tipo == "perc") {
            currentPrice = price - (price * valor) / 100;
          } else {
            currentPrice = price - valor;
          }
        }
      } else if (
        tickets.some((e) => e.cargo == currentUser?.grau?.toLowerCase())
      ) {
        price = tickets.filter(
          (e) => e.cargo == currentUser?.grau?.toLowerCase()
        )[0].preco;
        let isPromo = eventPaymentDetails.is_promo;
        if (isPromo && checkPromoIsAvailable(eventPaymentDetails)) {
          hasDiscount = true;
          let valor = eventPaymentDetails.valor;
          let tipo = eventPaymentDetails.tipo_valor;

          if (tipo == "perc") {
            currentPrice = price - (price * valor) / 100;
          } else {
            currentPrice = price - valor;
          }
        }
      }
    }

    return {
      priceWithoutPromo: price,
      current: currentPrice,
      hasDiscount: hasDiscount,
    };
  };

  const checkPromoIsAvailable = (eventPaymentDetails) => {
    let dataInicioCmp = parse(
      eventPaymentDetails.data_inicio,
      customFormat,
      new Date()
    );
    let dataFechoCmp = parse(
      eventPaymentDetails.data_fecho,
      customFormat,
      new Date()
    );
    let todayCmp = parse(
      new Date().toLocaleDateString(),
      customFormat,
      new Date()
    );
    if (!(dataInicioCmp <= todayCmp && dataFechoCmp >= todayCmp)) {
      return false;
    }

    return true;
  };

  const getEventos = () => {
    if (eventos.length > 0) {
      handleListEventos(
        eventos,
        vouchers,
        eventosPagos,
        allPurchases,
        currentUser
      );
      return;
    }
    let dataToPass = session_storage?.all_data
      ? session_storage?.all_data?.user_session?.id
      : "";

    let url = !dataToPass
      ? "https://api.spap.pt/api/all-eventos"
      : "https://api.spap.pt/api/all-eventos/" + dataToPass;

    fetch(url, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        setCurrentUser(data.user);
        setEventos(data.eventos);
        setVouchers(data.vouchers);
        setEventosPagos(data.eventPayments);
        setAllPurchases(data.purchases);
        handleListEventos(
          data.eventos,
          data.vouchers,
          data.eventPayments,
          data.purchases,
          data.user
        );
      })
      .catch((error) => console.error(error));
  };
  const next = () => {
    if (current == 1) {
      if (!checkBillingData()) {
        return;
      }
    } else {
      setCurrent(current + 1);
    }
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const preparePayment = (fields) => {
    fields.orderId = `${dayjs().format("YYYYMMDDHHmmss")}${
      currentUser.id ?? fields.email
    }`;
    fields.amount = cartTotal;
    fields.userId = currentUser.id;
    let arrayEvents = [];
    eventosList.map((eventoo) => {
      let price =
        eventoo.lastPrice == "" ? eventoo.currentPrice : eventoo.lastPrice;
      arrayEvents.push({ id: eventoo.id, price: price });
    });
    fields.events = JSON.stringify(arrayEvents);
    fields.promoCode = activeVoucher.length > 0 ? activeVoucher[0].id : "";
    let paymentRequest = fields.paymentType == "mb" ? "Multibanco" : "MBWay";
    fields.loggedIn = session_storage?.all_data ? true : false;

    axios
      .post(
        "https://api.spap.pt/api/purchaseEvent" + paymentRequest,
        {
          data: fields,
        },
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((result) => {
        if (result.data.Message == "Declined") {
          if (fields.paymentType != "mb") {
            notification.error({
              description:
                "Não é possível seguir com o pagamento MBWay a partir do nº de telemóvel introduzido.",
            });
            return;
          } else {
            notification.error({
              description:
                "Não foi possível criar dados para pagamento Multibanco. Por favor, tente novamente.",
            });
            return;
          }
        }
        notification.success({
          description: "Para completar a compra, efetue o pagamento.",
        });
        verifyPendentPayment();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const verifyPendentPayment = () => {
    let url = session_storage?.all_data
      ? "https://api.spap.pt/api/event-purchases-by-userid/"
      : "https://api.spap.pt/api/event-purchases-by-email/";

    if (!localStorage.getItem("billing_email") && !session_storage?.all_data) {
      setPendentAlert({});
      return;
    }
    let userData = session_storage?.all_data
      ? session_storage?.all_data?.user_session?.id
      : localStorage.getItem("billing_email");
    fetch(url + userData, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        let dataPendent = data.purchases?.filter((purchase) => {
          return (
            purchase.payment_id != "" &&
            purchase.payment_id != null &&
            purchase.status == "nao pago"
          );
        });
        if (!dataPendent.length) {
          setPendentAlert({});
          getEventos();
          return;
        }
        if (Array.isArray(dataPendent) && dataPendent.length) {
          fetchPaymentStatus(dataPendent[0].payment_id, dataPendent[0]);
        }
      })
      .catch((error) => console.error(error));
  };

  const fetchPaymentStatus = async (requestId, dataPendent) => {
    const url = `https://ifthenpay.com/api/spg/payment/mbway/status?mbWayKey=YUH-195600&requestId=${requestId}`;
    const response = await fetch(url);
    const data = await response.json();
    let status = data.Status; // Ou algum status específico da resposta
    let message = data.Message;

    if (message == "Success") {
      axios
        .post("https://api.spap.pt/api/success-payment", {
          payment_id: requestId,
          email: dataPendent.billing_email,
          name: dataPendent.billing_name,
        })
        .then((response) => {
          notification.success({
            message: `Sucesso`,
            description: "Pagamento efetuado com sucesso",
          });
          setPendentAlert({});
          confirmPaymentStep();
        })
        .catch((error) => {
          console.error("Erro na requisição:", error);
        });
    } else if (status == "020" || status == "101" || status == "122") {
      axios
        .post("https://api.spap.pt/api/remove-payment", {
          payment_id: requestId,
        })
        .then((response) => {
          notification.warning({
            message: `Sucesso`,
            description: "O seu pagamento expirou. Por favor, tente de novo.",
          });
          setPendentAlert({});
          setCurrent(0);
          getEventos();
        })
        .catch((error) => {
          console.error("Erro na requisição:", error);
        });
    } else {
      console.log(dataPendent);
      setIsEmpty(false);
      setPendentAlert(dataPendent);
    }
  };

  const checkBillingData = () => {
    let allFields = form.getFieldsValue(true);
    let emptyFields = Object.values(allFields).some(
      (o) => o == null || o == ""
    );
    if (
      allFields.name == "" ||
      allFields.email == "" ||
      allFields.nif == "" ||
      allFields.phone == "" ||
      allFields.paymentType == ""
    ) {
      notification.error({
        description: "Preencha todos os campos para seguir para o pagamento.",
      });

      return false;
    }

    localStorage.setItem("billing_email", allFields.email);

    preparePayment(allFields);

    return true;
  };

  const handleStatus = (index) => {
    let status = "wait";
    if (index == current) {
      status = "process";
    } else if (index < current) {
      status = "finish";
    }
    return status;
  };

  const steps = [
    {
      key: "step1",
      title: "Carrinho",
      status: handleStatus(0),
      icon: <ShoppingCartOutlined />,
    },
    {
      key: "step2",
      title: "Dados",
      status: handleStatus(1),
      icon: <SolutionOutlined />,
      content: "teste",
    },
    {
      key: "step3",
      title: "Pagamento",
      status: handleStatus(2),
      icon: <EuroCircleOutlined />,
      content: "teste",
    },
  ];

  const [api, contextHolder] = notification.useNotification();

  notification.config({
    placement: "bottomRight",
    bottom: 10,
    duration: 3,
    rtl: false,
  });

  const handleClose = () => {
    props.onChange(false);
  };

  const checkPromoCode = () => {
    let code = document.querySelector('input[name="promoCode"]').value;
    if (!code.length) {
      notification.error({
        description: "Insira um código promocional válido.",
      });
      return;
    }
    if (localStorage.getItem("promoCode") != null) {
      notification.error({
        description: "Já se encontra aplicado um código promocional.",
      });
      return;
    }
    if (!vouchers.length) {
      notification.error({
        description: "O código inserido não se encontra disponível.",
      });
      return;
    }
    let availableVoucher = vouchers.filter((voucher) => {
      return voucher.codigo == code;
    });
    if (!availableVoucher.length) {
      notification.error({
        description: "O código inserido não se encontra disponível.",
      });
      return;
    }

    let dataInicioCmp = parse(
      availableVoucher[0].data_inicio,
      customFormat,
      new Date()
    );
    let dataFechoCmp = parse(
      availableVoucher[0].data_fecho,
      customFormat,
      new Date()
    );
    let todayCmp = parse(
      new Date().toLocaleDateString(),
      customFormat,
      new Date()
    );
    if (!(dataInicioCmp <= todayCmp && dataFechoCmp >= todayCmp)) {
      notification.error({
        description: "Este código promocional expirou.",
      });
      return;
    }

    localStorage.setItem("promoCode", availableVoucher[0].codigo);

    notification.success({
      description: "O código promocional foi aplicado.",
    });

    document.querySelector('input[name="promoCode"]').value = "";

    handlePromoCodePrice(eventosList, vouchers);
  };

  const deleteAtiveVoucher = () => {
    // delete promoCode from storage
    localStorage.removeItem("promoCode");
    // update useState
    setActiveVoucher([]);
    // notification
    notification.success({
      description: "O código promocional foi removido.",
    });

    handleListEventos(
      eventos,
      vouchers,
      eventosPagos,
      allPurchases,
      currentUser
    );
  };

  const handlePromoCodePrice = (allEventos, allVouchers) => {
    if (localStorage.getItem("promoCode") == null) {
      setActiveVoucher([]);
      return;
    }
    let voucher = allVouchers.filter((voucher) => {
      return voucher.codigo == localStorage.getItem("promoCode");
    });
    if (!voucher.length) {
      localStorage.removeItem("promoCode");
      setActiveVoucher([]);
      return;
    }

    let dataInicioCmp = parse(voucher[0].data_inicio, customFormat, new Date());
    let dataFechoCmp = parse(voucher[0].data_fecho, customFormat, new Date());
    let todayCmp = parse(
      new Date().toLocaleDateString(),
      customFormat,
      new Date()
    );
    if (!(dataInicioCmp <= todayCmp && dataFechoCmp >= todayCmp)) {
      localStorage.removeItem("promoCode");
      setActiveVoucher([]);
      return;
    }

    setActiveVoucher(voucher);

    let promoPrice = 0;

    allEventos.map((evento) => {
      evento.discountMessage = "";
      evento.discountValue = "";
      let priceWithPromoCode =
        evento.currentPrice != 0
          ? Number(evento.currentPrice)
          : evento.priceWithoutPromo;
      if (voucher[0].events?.length > 0) {
        if (voucher[0].events.includes(evento.id)) {
          if (voucher[0].tipo == "perc") {
            priceWithPromoCode =
              Number(priceWithPromoCode) -
              Number((priceWithPromoCode * voucher[0].valor) / 100);
          } else {
            priceWithPromoCode =
              Number(priceWithPromoCode) - Number(voucher[0].valor);
          }
          evento.discountValue = "-" + voucher[0].valor;
          evento.discountValue += voucher[0].tipo == "euro" ? "€" : "%";
          evento.discountMessage =
            "Foi aplicado um desconto de " + voucher[0].valor;
          evento.discountMessage += voucher[0].tipo == "euro" ? "€" : "%";
        }
        evento.lastPrice = priceWithPromoCode;
      } else {
        if (voucher[0].tipo == "perc") {
          priceWithPromoCode =
            Number(priceWithPromoCode) -
            Number((priceWithPromoCode * voucher[0].valor) / 100);
        } else {
          priceWithPromoCode =
            Number(priceWithPromoCode) - Number(voucher[0].valor);
        }
        evento.discountValue = "-" + voucher[0].valor;
        evento.discountValue += voucher[0].tipo == "euro" ? "€" : "%";
        evento.discountMessage =
          "Foi aplicado um desconto de " + voucher[0].valor;
        evento.discountMessage += voucher[0].tipo == "euro" ? "€" : "%";
        evento.lastPrice = priceWithPromoCode;
      }

      promoPrice += priceWithPromoCode;
    });

    setEventosList(allEventos);
    localStorage.setItem("countCartItems", allEventos.length);
    setCartTotal(promoPrice.toFixed(2));
  };

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 3000);
  };

  const enterLoadingCancel = (index) => {
    setLoadingsCancel((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadingsCancel((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 3000);
  };

  const confirmPaymentStep = () => {
    localStorage.removeItem("cartList");
    localStorage.removeItem("promoCode");
    localStorage.removeItem("countCartItems");
    setConfirmPayment(true);
  };

  const onConfirmOrCancelPayment = (label) => async () => {
    const url = `https://ifthenpay.com/api/spg/payment/mbway/status?mbWayKey=YUH-195600&requestId=${pendentAlert.payment_id}`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      let status = data.Status; // Ou algum status específico da resposta
      let message = data.Message;
      if (message == "Success") {
        confirmPaymentStep();
        setPendentAlert({});
        axios
          .post("https://api.spap.pt/api/success-payment", {
            payment_id: pendentAlert.payment_id,
            email: pendentAlert.billing_email,
            name: pendentAlert.billing_name,
          })
          .then((response) => {
            notification.success({
              message: `Sucesso`,
              description: "Pagamento efetuado com sucesso",
            });
          })
          .catch((error) => {
            console.error("Erro na requisição:", error);
          });
        return;
      } else {
        if (label == "confirm") {
          notification.error({
            message: `Erro`,
            description: "O pagamento ainda não foi efetuado.",
          });
        } else if ((label = "expired")) {
          axios
            .post("https://api.spap.pt/api/remove-payment", {
              payment_id: pendentAlert.payment_id,
            })
            .then((response) => {
              notification.warning({
                message: `Warning`,
                description:
                  "O seu pagamento expirou. Por favor, tente de novo.",
              });
              setPendentAlert({});
              setCurrent(0);
              getEventos();
            })
            .catch((error) => {
              console.error("Erro na requisição:", error);
            });
        }
      }
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
      return null; // ou outro valor representando falha
    }
  };

  const backToFirstStep = () => {
    getEventos();
  };

  const cancelPendent = async () => {
    enterLoadingCancel(1);
    const url = `https://ifthenpay.com/api/spg/payment/mbway/status?mbWayKey=YUH-195600&requestId=${pendentAlert.payment_id}`;
    const response = await fetch(url);
    const data = await response.json();
    let status = data.Status; // Ou algum status específico da resposta
    let message = data.Message;
    if (message == "Success") {
      axios
        .post("https://api.spap.pt/api/success-payment", {
          payment_id: pendentAlert.payment_id,
          email: pendentAlert.billing_email,
          name: pendentAlert.billing_name,
        })
        .then((response) => {
          notification.success({
            message: `Sucesso`,
            description: "Pagamento efetuado com sucesso",
          });
          setPendentAlert({});
          confirmPaymentStep();
        })
        .catch((error) => {
          console.error("Erro na requisição:", error);
        });
    } else {
      axios
        .post("https://api.spap.pt/api/remove-payment", {
          payment_id: pendentAlert.payment_id,
        })
        .then((response) => {
          notification.success({
            message: `Sucesso`,
            description: "O seu pagamento foi cancelado com sucesso.",
          });
          setPendentAlert({});
          setCurrent(0);
          getEventos();
        })
        .catch((error) => {
          console.error("Erro na requisição:", error);
        });
    }
  };

  const deleteEventFromCart = (id) => {
    let allEvents = JSON.parse(localStorage.getItem("cartList"));
    let newEvents = allEvents.filter((eventId) => {
      return eventId != id;
    });
    localStorage.setItem("cartList", JSON.stringify(newEvents));
    notification.success({
      message: `Sucesso`,
      description: "O evento foi removido do seu carrinho.",
    });
    getEventos();
  };

  return (
    <>
      <Drawer
        title="Carrinho"
        width={720}
        onClose={handleClose}
        open={props.show}
      >
        {show && (
          <Section>
            {isEmpty && (
              <Flex justify={"center"} vertical align="center">
                <img src={CarEmpty} width={"40%"} />
                <h1>O seu carrinho está vazio</h1>
                <p className="subtitle">
                  Conheça os nossos eventos e adicione-os às suas compras
                </p>
                <Link to="/agenda" className="view-btn">
                  Ver eventos
                </Link>
              </Flex>
            )}
            {!isEmpty && (
              <>
                <Steps current={current} items={steps} />
                {current == 0 && (
                  <>
                    <Step>
                      {eventosList.map((evento) => {
                        return (
                          <>
                            <Row gutter={16} align={"center"}>
                              <Col
                                className="col-event"
                                span={4}
                                align="left"
                                style={{
                                  width: "100%",
                                  backgroundImage: "url(" + evento.url + ")",
                                  backgroundSize: "cover",
                                }}
                              ></Col>
                              <Col xl={15} xs={24}>
                                <div>
                                  <h6>{evento.titulo}</h6>
                                  <Row className="row-detail">
                                    <Link
                                      to={"/agenda/evento/" + evento.slug}
                                      className="view-more-details-btn"
                                    >
                                      Ver evento
                                    </Link>
                                    {evento.discountMessage?.length > 0 && (
                                      <div className="voucher-discount">
                                        {evento.discountMessage}
                                      </div>
                                    )}
                                  </Row>
                                </div>
                              </Col>
                              <Col
                                span={4}
                                className="margin-vertical-auto"
                                align="right"
                                style={{ padding: 0 }}
                              >
                                <Space
                                  size={10}
                                  style={{ alignItems: "baseline" }}
                                >
                                  <Space direction="vertical" size={0}>
                                    <Space
                                      size={5}
                                      style={{ alignItems: "baseline" }}
                                    >
                                      {evento.hasDiscount ? (
                                        <>
                                          <h6
                                            style={{
                                              textDecoration: "line-through",
                                            }}
                                          >
                                            {evento.priceWithoutPromo}€
                                          </h6>
                                          <h5>{evento.currentPrice}€</h5>
                                        </>
                                      ) : (
                                        <>
                                          <h5>{evento.priceWithoutPromo}€</h5>
                                        </>
                                      )}
                                    </Space>

                                    {evento.discountMessage?.length > 0 && (
                                      <>
                                        <p style={{ textAlign: "right" }}>
                                          {evento.discountValue}
                                        </p>
                                      </>
                                    )}
                                  </Space>

                                  <DeleteOutlined
                                    className="delete-icon"
                                    onClick={() =>
                                      deleteEventFromCart(evento.id)
                                    }
                                  />
                                </Space>
                              </Col>
                            </Row>
                            <Divider />
                          </>
                        );
                      })}
                      <Row gutter={[16, 8]} align={"top"}>
                        {activeVoucher.length > 0 && (
                          <Col span={24}>
                            <div className="voucher-info">
                              Foi aplicado um desconto com o código{" "}
                              <b>{activeVoucher[0]?.codigo}</b>
                            </div>
                          </Col>
                        )}
                        <Col xl={11} xs={24}>
                          <Input
                            name="promoCode"
                            className="inputCode"
                            placeholder="Código promocional"
                          ></Input>
                          {activeVoucher.length > 0 && (
                            <div
                              className="remove-voucher"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span>{activeVoucher[0]?.codigo}</span>
                              <DeleteOutlined onClick={deleteAtiveVoucher} />
                            </div>
                          )}
                        </Col>
                        <Col xl={8} xs={24}>
                          <Button
                            className="apply-code-btn"
                            onClick={checkPromoCode}
                          >
                            Aplicar promoção
                          </Button>
                        </Col>
                        <Col span={5} align="right">
                          <p className="total">Total</p>
                          <p className="total">{cartTotal}€</p>
                        </Col>
                      </Row>
                    </Step>
                  </>
                )}
                {current == 1 && (
                  <>
                    <Step>
                      <h5 style={{ marginBottom: "10px" }}>
                        Dados de Faturação
                      </h5>
                      <p>
                        Insira os seus dados de faturação para procedermos à
                        emissão do seu recibo.
                      </p>

                      <Form
                        form={form}
                        name="dynamic_form_item"
                        initialValues={{
                          name: currentUser.nome_completo,
                          email: currentUser.email,
                          phone: currentUser.telefone,
                          nif: currentUser.numero_contribuinte,
                          cargo: currentUser.grau ?? currentUser.tipo_socio,
                          paymentType: paymentType,
                        }}
                      >
                        <Row gutter={[16, 16]}>
                          <Col xl={12} xs={24}>
                            <label>Nome</label>
                            <Form.Item noStyle name={"name"}>
                              <Input
                                className="inputDados"
                                placeholder="Nome"
                              ></Input>
                            </Form.Item>
                          </Col>
                          <Col xl={12} xs={24}>
                            <label>E-mail</label>
                            <Form.Item noStyle name={"email"}>
                              <Input
                                className="inputDados"
                                placeholder="Email"
                              ></Input>
                            </Form.Item>
                          </Col>
                          <Col xl={8} xs={24}>
                            <label>Telemóvel</label>
                            <Form.Item noStyle name={"phone"}>
                              <Input
                                className="inputDados"
                                placeholder="Telemóvel"
                              ></Input>
                            </Form.Item>
                          </Col>
                          <Col xl={8} xs={24}>
                            <label>NIF</label>
                            <Form.Item noStyle name={"nif"}>
                              <Input
                                className="inputDados"
                                placeholder="NIF"
                              ></Input>
                            </Form.Item>
                          </Col>
                          <Col xl={8} xs={24}>
                            <label>Cargo</label>
                            <Form.Item noStyle name={"cargo"}>
                              <Input
                                className="inputDados"
                                placeholder="Cargo"
                              ></Input>
                            </Form.Item>
                          </Col>
                        </Row>
                        <h5 style={{ marginTop: "30px", marginBottom: "10px" }}>
                          Método de pagamento
                        </h5>
                        <p>
                          Escolha a forma de pagamento que pretende para pagar
                          o(s) seu(s) evento(s).
                        </p>

                        <Row>
                          <Col span={24}>
                            <Form.Item noStyle name={"paymentType"}>
                              <Radio.Group>
                                <Radio.Button
                                  value={"mbway"}
                                  className="radio-btn"
                                >
                                  <img
                                    src={MBWayImage}
                                    alt="Pagamento com MBWay"
                                  />
                                </Radio.Button>
                                <Radio.Button
                                  value={"mb"}
                                  className="radio-btn"
                                >
                                  <img
                                    src={MultibancoImage}
                                    alt="Pagamento com MBWay"
                                  />
                                </Radio.Button>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </Step>
                  </>
                )}
                {current == 2 && (
                  <>
                    <Step>
                      {!confirmPayment ? (
                        <>
                          {" "}
                          {pendentAlert.payment_type === "Multibanco" ? (
                            <>
                              <Countdown
                                title="Tempo que falta"
                                value={countdown}
                                format="HH:mm:ss"
                                style={{ textAlign: "right" }}
                                onFinish={onConfirmOrCancelPayment("expired")}
                              />
                              <Card className="white boxShadow">
                                <Row>
                                  <p>
                                    <b>Dados fornecidos para pagamento:</b>
                                  </p>
                                </Row>
                                <Row
                                  style={{
                                    padding: "30px",
                                    backgroundColor: "#F3F6F9",
                                    marginTop: "20px",
                                  }}
                                >
                                  <Col xl={4} xs={24} className="mbway-image">
                                    <img src={MBImage} alt="Pagamento via MB" />
                                  </Col>
                                  <Col
                                    width={"80%"}
                                    xl={20}
                                    xs={24}
                                    className="p-l-0-mobile"
                                    style={{ paddingLeft: "20px" }}
                                  >
                                    <p style={{ marginBottom: "8px" }}>
                                      <b>Entidade</b> {pendentAlert.entity}
                                    </p>
                                    <p style={{ marginBottom: "8px" }}>
                                      <b>Referência</b> {pendentAlert.reference}
                                    </p>
                                    <p>
                                      <b>Montante</b> {pendentAlert.amount} €
                                    </p>
                                  </Col>
                                </Row>
                                <Row gutter={[8, 8]} justify={"end"}>
                                  {/* {!pendent?.length > 0 && ( */}
                                  <Col xl={24} xs={24} sm={24}>
                                    {paymentType !== "debit" && (
                                      <>
                                        <Space
                                          className="row-detail"
                                          style={{
                                            width: "100%",
                                            justifyContent: "flex-end",
                                          }}
                                        >
                                          <Button
                                            className="cancelPayment"
                                            onClick={cancelPendent}
                                            loading={loadingsCancel[1]}
                                          >
                                            Cancelar pagamento
                                          </Button>
                                          <Button
                                            className="paymentSubmit"
                                            onClick={onConfirmOrCancelPayment(
                                              "confirm"
                                            )}
                                            loading={loadings[1]}
                                          >
                                            Confirmo que paguei
                                          </Button>
                                        </Space>
                                      </>
                                    )}
                                  </Col>
                                  {/* )} */}
                                </Row>
                              </Card>
                            </>
                          ) : pendentAlert.payment_type == "MBWay" ? (
                            <>
                              <Countdown
                                title="Tempo que falta"
                                value={countdown}
                                format="HH:mm:ss"
                                style={{ textAlign: "right" }}
                                onFinish={onConfirmOrCancelPayment("expired")}
                              />
                              <Card className="white boxShadow">
                                <Row>
                                  <p style={{ fontSize: "16px" }}>
                                    Efetue o pagamento para concluir a sua
                                    compra.
                                  </p>
                                </Row>
                                <Row
                                  style={{
                                    padding: "30px",
                                    backgroundColor: "#F3F6F9",
                                    marginTop: "20px",
                                  }}
                                >
                                  <Col xl={4} xs={24} className="center-mobile">
                                    <img
                                      className="mbway-image"
                                      width={"80%"}
                                      src={MBWayImage}
                                      alt="Pagamento via MBWay"
                                    />
                                  </Col>
                                  <Col
                                    xl={20}
                                    xs={24}
                                    className="p-l-0-mobile"
                                    style={{ paddingLeft: "20px" }}
                                  >
                                    <p style={{ marginBottom: "8px" }}>
                                      <b>Número de telemóvel</b>
                                    </p>
                                    <Input
                                      placeholder="Número de telemóvel"
                                      value={pendentAlert.billing_phone}
                                      disabled
                                      style={{
                                        marginBottom: "8px",
                                        padding: "10px 8px",
                                        border: "2px solid #C2C4D4",
                                        background: "#D9E1E700",
                                      }}
                                    />
                                    <p>
                                      <b>Quantia</b> {pendentAlert.amount} €
                                    </p>
                                  </Col>
                                </Row>
                                <Row gutter={[8, 8]} justify={"end"}>
                                  {/* {!pendent?.length > 0 && ( */}
                                  <Col xl={24} xs={24} sm={24}>
                                    {paymentType !== "debit" && (
                                      <>
                                        <Space
                                          className="row-detail"
                                          style={{
                                            width: "100%",
                                            justifyContent: "flex-end",
                                          }}
                                        >
                                          <Button
                                            className="cancelPayment"
                                            onClick={cancelPendent}
                                            loading={loadingsCancel[1]}
                                          >
                                            Cancelar pagamento
                                          </Button>
                                          <Button
                                            className="paymentSubmit"
                                            onClick={onConfirmOrCancelPayment(
                                              "confirm"
                                            )}
                                            loading={loadings[1]}
                                          >
                                            Confirmo que paguei
                                          </Button>
                                        </Space>
                                      </>
                                    )}
                                  </Col>
                                  {/* )} */}
                                </Row>
                              </Card>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        <>
                          <Flex
                            justify={"center"}
                            vertical
                            align="center"
                            style={{ minHeight: "60vh" }}
                          >
                            <img
                              src={CheckMark}
                              width={"8%"}
                              style={{ marginBottom: "20px" }}
                            />
                            <h2>A sua compra foi concluida com sucesso!</h2>
                            <p className="subtitle">
                              Para verificar os seus eventos, clique no botão a
                              baixo.
                            </p>
                            <Link
                              to={"/socios/meus-eventos"}
                              className="view-btn"
                            >
                              Os meus eventos
                            </Link>
                            <a
                              onClick={() => backToFirstStep()}
                              style={{
                                textDecoration: "underline",
                                marginTop: "10px",
                              }}
                            >
                              Voltar para o carrinho
                            </a>
                          </Flex>
                        </>
                      )}
                    </Step>
                  </>
                )}
                <div
                  style={{
                    float: "right",
                    marginTop: "30px",
                  }}
                >
                  <Space size={10}>
                    {current == 1 && (
                      <Button className="pre-btn" onClick={() => prev()}>
                        Anterior
                      </Button>
                    )}
                    {current < steps.length - 1 && (
                      <Button className="next-btn" onClick={() => next()}>
                        Próximo
                      </Button>
                    )}
                  </Space>
                </div>
              </>
            )}
          </Section>
        )}
      </Drawer>
    </>
  );
}
