import React from "react"
import {
  useParams
} from "react-router-dom"

import Blank from '../Blank'
import QuemSomos from './QuemSomos'
import AnatomiaPatologica from './AnatomiaPatologica'
import Glossario from './Glossario'
import ConhecaUmPatologista from './ConhecaUmPatologista'
import OrgaosSociais from './OrgaosSociais'
import EstatutosDocumentos from './EstatutosDocumentos'

function SwitchContent (location) {
  switch(location) {
    case 'quem-somos':
      return <QuemSomos />
    case 'anatomia-patologica':
      return <AnatomiaPatologica />
    case 'glossario':
      return <Glossario />
    case 'conheca-um-patologista':
      return <ConhecaUmPatologista />
    case 'orgaos-sociais':
      return <OrgaosSociais />
    case 'historia':
      return <Blank pageTitle="História" />
    case 'bolsas-e-premios':
      return <Blank pageTitle="Bolsas e Prémios" />
    case 'estatutos-e-documentos':
      return <EstatutosDocumentos />
    case 'contactos':
      return <Blank pageTitle="Contactos" />
      
    default: return true
  }
}

export default function Spap( props ) {

  let { slug } = useParams()
  
  return(
    SwitchContent( slug )
  )
}
