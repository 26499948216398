import { React, useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import urlSlug from "url-slug";

import Container from "../../../Components/Container";
import FlexInRow from "../../../Components/FlexInRow";
import FlexInColumn from "../../../Components/FlexInColumn";
import PageTitle from "../../../Components/PageTitle";
import RecommendedLinks from "../../../Components/RecommendedLinks";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import { Space } from "antd";
import PageText from "../../../Components/PageText";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  > .content {
    padding-bottom: 4vw;
  }

  img {
    width: 100%;
  }

  @media (max-width: 992px) {
    margin-top: 120px;
  }
`;

const Grid3 = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 2.9166vw;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    gap: 50px;
  }

  @media (min-width: 1920px) {
    gap: 56px;
    grid-template-columns: 792px 1fr;
  }
`;

const Grid = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-wrap: wrap;
  gap: 2.9166vw;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    gap: 50px;
  }

  @media (min-width: 1920px) {
    gap: 56px;
    grid-template-columns: 792px 1fr;
  }
`;

const FeaturedItem = styled.div`
  border: 3px solid #494d7d;
  border-radius: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  .item {
    border: 3px solid #494d7d !important;
  }

  .item__image {
    height: 21.354vw;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .item__title {
    text-align: left;
    font-size: 16px;
    line-height: inherit;
    font-weight: bold;
    letter-spacing: 0px;
    color: ${(props) => props.theme.blue};
    opacity: 1;
    margin: 0 20px 0.417vw;
  }

  .item__title.noDate {
    margin-bottom: 2.697vw;
  }

  .item__content p {
    text-align: left;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0 20px 20px;
  }

  .item__cta {
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #0c1151;
    border-radius: 0.521vw;
    opacity: 1;
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    text-decoration: none;
    color: #ffffff;
    opacity: 1;
    width: 8.021vw;
    height: 2.396vw;
    transition: 0.3s ease;
    margin: 0 20px 20px;
  }

  .item__cta:hover {
    background-color: ${(props) => props.theme.blueHover};
    color: #e5e5e5;
  }

  .item__date {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    color: ${(props) => props.theme.blue};
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.417vw;
    margin: 0 20px 1.042vw;
  }

  .item__date svg {
    font-size: 0.938vw;
  }

  @media (max-width: 992px) {
    .item__image {
      height: auto;
    }

    .item__title {
      font-size: 18px;
      line-height: 26px;
    }

    .item__text,
    .item__cta,
    .item__date,
    .item__date svg {
      font-size: 3.333vw;
      line-height: 4.444vw;
    }

    .item__cta {
      border-radius: 8px;
      height: 30px;
      width: 150px;
    }
  }

  @media (min-width: 1920px) {
    .item__content p,
    .item__cta,
    .item__date,
    .item__date svg {
      font-size: 18px;
      line-height: 24px;
    }

    .item__title {
      font-size: 24px;
      line-height: 28px;
    }

    .item__image {
      height: 410px;
    }

    .item__cta {
      border-radius: 10px;
      font-size: 18px;
      line-height: 24px;
      padding: 15px 30px;
      max-width: 214px;
    }
  }
`;

const SectionCards = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.354vw;

  .item {
    opacity: 1;
  }

  .item__content {
    padding: 0.781vw 1.042vw;
  }

  .item__image {
    border: none;
    overflow: hidden;
    width: 13.541vw;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .item__title {
    text-align: left;
    font-size: 16px;
    line-height: inherit;
    font-weight: bold;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    margin-top: 10px;
  }

  .item__text {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .item__cta {
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #0c1151;
    border-radius: 0.521vw;
    opacity: 1;
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    text-decoration: none;
    color: #ffffff;
    opacity: 1;
    width: 8.021vw;
    height: 2.396vw;
    transition: 0.3s ease;
  }

  .item__cta:hover {
    background-color: #8688a8;
    color: #e5e5e5;
  }

  .item__date {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.417vw;
  }

  .item__date svg {
    font-size: 0.938vw;
  }

  @media (min-width: 992px) {
    .item__content {
      width: 25.625vw;
    }
  }

  @media (max-width: 992px) {
    gap: 20px;

    .item__content {
      padding: 10px;
    }

    .item__image {
      margin-bottom: 20px;
      width: 100%;
      height: 160px;
    }

    .item__title {
      font-size: 18px;
      line-height: 26px;
    }

    .item__text,
    .item__cta,
    .item__date,
    .item__date svg {
      font-size: 3.333vw;
      line-height: 4.444vw;
    }

    .item__cta {
      border-radius: 8px;
      height: 30px;
      width: 150px;
    }
  }

  @media (min-width: 1920px) {
    .item__text,
    .item__cta,
    .item__date,
    .item__date svg {
      font-size: 18px;
      line-height: 24px;
    }

    .item__content {
      padding: 19px;
      width: 532px;
    }

    .item__title {
      font-size: 24px;
      line-height: 28px;
    }

    .item__cta {
      border-radius: 10px;
      font-size: 18px;
      line-height: 24px;
      width: 184px;
      padding: 15px 30px;
      max-height: 48px;
    }

    .item__image {
      min-height: 222px;
      width: 260px;
    }
  }
`;

const SeeMore = styled.div`
  padding-top: 50px;
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;

  a {
    text-decoration: none;
    color: ${(props) => props.theme.orange};
    transition: 0.3s ease;
    font-size: 16px;
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }

  @media (max-width: 992px) {
    padding-bottom: 50px;

    a {
      font-size: 18px;
      line-height: 26px;
    }
  }
`;

const Grid2 = styled.div`
  border-top: 1px solid #707070;
  display: grid;
  margin-top: 50px;
  padding-top: 50px;
  grid-template-columns: 1fr 1fr;
  gap: 3.125vw;

  .item {
    display: grid;
    grid-template-columns: 8.229vw 1fr;
    gap: 15px;
  }

  .item__image {
    background-color: #f4f4f7;
    border: 1px solid #707070;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .item__title {
    text-align: left;
    font-family: "Fira Sans", sans-serif;
    font-size: 0.8333vw;
    font-weight: bold;
    line-height: 0.9895vw;
    letter-spacing: 0px;
    color: #0c1151;
    display: block;
  }

  .item__text {
    text-align: left;
    font-size: 0.7291vw;
    line-height: 0.9895vw;
    letter-spacing: 0px;
    color: #000000;
    display: block;
  }

  .item__cta {
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #0c1151;
    border-radius: 0.521vw;
    opacity: 1;
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    text-decoration: none;
    color: #ffffff;
    opacity: 1;
    width: 8.021vw;
    height: 2.396vw;
    transition: 0.3s ease;
  }

  .item__cta:hover {
    background-color: ${(props) => props.theme.blueHover};
    color: #e5e5e5;
  }

  @media (max-width: 992px) {
    margin-top: 30px;
    padding-top: 20px;
    gap: 20px;
    grid-template-columns: 1fr;

    .item {
      display: grid;
      grid-template-columns: 1fr;
    }

    .item__image {
      height: 50vw;
    }

    .item__title {
      font-size: 16px;
      line-height: 20px;
    }

    .item__text {
      font-size: 18px;
      line-height: 16px;
    }

    .item__cta {
      font-size: 14px;
      line-height: 18px;
      width: 180px;
      height: 40px;
      border-radius: 10px;
    }
  }

  @media (min-width: 1920px) {
    gap: 60px;
    grid-template-columns: 1fr 1fr 1fr;

    .item__cta {
      border-radius: 10px;
      font-size: 18px;
      line-height: 24px;
      width: 184px;
      padding: 15px 30px;
      max-height: 48px;
    }
  }
`;

export default function Destaques(props) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [eventos, setEventos] = useState([]);

  const month = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  const [noResults, setNoResults] = useState("");

  const getEventos = (allEventos) => {
    let eventosToShow = [];

    let today = new Date().toLocaleDateString();

    if (allEventos?.length > 0) {
      allEventos.map((evento) => {
        evento.slug = urlSlug(evento.titulo);

        evento.slug += "-" + evento.id;

        console.log(evento.slug);

        let mostraEvento = true;

        if (evento.agendar_post != null) {
          let date = evento.agendar_post.trim();

          if (date > today) {
            mostraEvento = false;
          }
        }

        if (mostraEvento) {
          if (evento.data_inicio && evento.is_noticia == 1) {
            const [dateInicioValues, timeInicioValues] =
              evento.data_inicio.split(" ");
            const [dayInicio, monthInicio, yearInicio] =
              dateInicioValues.split("/");
            const [dateFechoValues, timeFechoValues] =
              evento.data_fecho.split(" ");
            const [dayFecho, monthFecho, yearFecho] =
              dateFechoValues.split("/");

            const monthInicioToString = month[Number(monthInicio - 1)];
            const monthFechoToString = month[Number(monthFecho - 1)];

            evento.monthInicio = monthInicio;
            evento.yearInicio = yearInicio;

            evento.url = evento.imagem_destaque
              ? "https://api.spap.pt/eventos/" +
                evento.id +
                "/" +
                evento.imagem_destaque
              : "https://api.spap.pt/clubes/clube-estudo-default.svg";

            if (
              monthInicio == monthFecho &&
              yearInicio === yearFecho &&
              dayInicio === dayFecho
            ) {
              evento.date =
                dayInicio + " " + monthInicioToString + " " + yearInicio;
            } else if (monthInicio == monthFecho && yearInicio === yearFecho) {
              evento.date =
                dayInicio +
                "-" +
                dayFecho +
                " " +
                monthInicioToString +
                " " +
                yearInicio;
            } else if (
              dayInicio == dayFecho &&
              monthInicio == monthFecho &&
              yearInicio === yearFecho
            ) {
              evento.date =
                dayInicio + " " + monthInicioToString + " " + yearInicio;
            } else if (monthInicio != monthFecho) {
              evento.date =
                dayInicio +
                " " +
                monthInicioToString +
                " a " +
                dayFecho +
                " " +
                monthFechoToString +
                " " +
                yearFecho;
            }

            //if(evento.data_inicio > today) {
            eventosToShow.push(evento);
            //}
          }
        }
      });
    }

    const eventosOrdenados = eventosToShow.sort((a, b) => a.ordem - b.ordem);

    setEventos(eventosOrdenados);
  };

  useEffect(() => {
    fetch(`https://api.spap.pt/api/all-eventos`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0) {
          setNoResults("Não há conteúdo Destaques no momento");
          console.log("nenhum evento");
        }
        getEventos(data.eventos);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  let destaqueTextPreview = eventos[0]?.descricao
    .replace(/<(h2|h3|img|b|strong)[^>]*>/g, "<p>")
    .slice(0, 120);
  if (eventos[0]?.descricao.length > 120) destaqueTextPreview += " (...)";

  return (
    <Wrapper>
      <Helmet>
        <title>
          Destaques e Notícias | Sociedade Portuguesa de Anatomia Patológica -
          SPAP
        </title>
        <meta
          name="description"
          content="Fique a par de todas as Destaques e Notícias da Sociedade Portuguesa de Anatomia Patológica - SPAP"
        />
        <meta
          name="keywords"
          content="spap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>

      <Container className="content">
        <PageTitle
          title="Destaques e notícias"
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "Sócios",
              link: "/socios",
            },
            {
              text: "Destaques e Notícias",
            },
          ]}
          imageDesktop={require("../../../Assets/Images/img-destaques@2x.png")}
          imageMobile={require("../../../Assets/Images/img-destaques.png")}
          imageAlt="Destaques"
        />
        {eventos?.length > 0 ? (
          <>
            {eventos?.length >= 4 ? (
              <>
                <Grid style={{ width: "100%" }}>
                  <FeaturedItem>
                    <div
                      className="item__content"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%",
                      }}
                    >
                      <picture className="item__image">
                        <source
                          srcSet={eventos[0]?.url}
                          media="(min-width: 990px)"
                        />
                        <img src={eventos[0]?.url} alt={eventos[0]?.titulo} />
                      </picture>
                      <div className="item__date" style={{ marginTop: "20px" }}>
                        <FontAwesomeIcon icon={faCalendarDay} />
                        {eventos[0]?.date}
                      </div>
                      <h3 className={"item__title"}>{eventos[0]?.titulo}</h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: destaqueTextPreview,
                        }}
                      ></div>
                      <div>
                        <Link
                          to={"/agenda/evento/" + eventos[0]?.slug}
                          className="item__cta"
                        >
                          Saber mais »
                        </Link>
                      </div>
                    </div>
                  </FeaturedItem>
                  <SectionCards>
                    {eventos.slice(1, 4).map((item, index) => {
                      let titlePreview = item.titulo?.slice(0, 50);
                      if (item.titulo.length > 50) titlePreview += " (...)";
                      let textPreview = item.descricao
                        .replace(/<(h2|h3|img|b|strong)[^>]*>/g, "<p>")
                        .slice(0, 120);
                      if (item.descricao.length > 120) textPreview += " (...)";
                      return (
                        <FlexInColumn className={"item item-" + index}>
                          <div
                            style={{
                              border: "2px solid #0C1151",
                              overflow: "hidden",
                              width: "100%",
                              borderRadius: "10px",
                              display: "flex",
                            }}
                          >
                            {windowWidth > 992 && (
                              <div
                                className="item__image"
                                style={{
                                  backgroundImage: "url(" + item.url + ")",
                                  backgroundSize: "cover",
                                  backgroundPosition: "center center",
                                }}
                              >
                                {/* <picture style={{opacity: '0'}}>
                                <source srcSet={item.url} media="(min-width: 990px)"  />
                                <img src={item.url} alt={item.alt} />
                              </picture>        */}{" "}
                              </div>
                            )}
                            <div
                              className="item__content"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <div className="item__date">
                                  <FontAwesomeIcon icon={faCalendarDay} />
                                  {item.date}
                                </div>
                                <h3 className="item__title">{titlePreview}</h3>
                                <div
                                  style={{
                                    fontSize: "14px",
                                    lineHeight: "1.4",
                                    color: "#212529",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: textPreview,
                                  }}
                                ></div>
                                {windowWidth <= 992 && (
                                  <div
                                    className="item__image"
                                    style={{
                                      backgroundImage: "url(" + item.url + ")",
                                      backgroundSize: "cover",
                                      backgroundPosition: "center center",
                                    }}
                                  >
                                    {/* <picture style={{opacity: '0'}}>
                                <source srcSet={item.url} media="(min-width: 990px)"  />
                                <img src={item.url} alt={item.alt} />
                              </picture>          */}{" "}
                                  </div>
                                )}
                              </div>
                              <div>
                                <Link
                                  to={"/agenda/evento/" + item.slug}
                                  className="item__cta"
                                >
                                  Saber mais »
                                </Link>
                              </div>
                            </div>
                          </div>
                        </FlexInColumn>
                      );
                    })}
                  </SectionCards>
                </Grid>
              </>
            ) : (
              <>
                <Grid3>
                  <SectionCards style={{ display: "flex", flexWrap: "wrap" }}>
                    {eventos.map((item, index) => {
                      let textPreview = item.descricao
                        .replace(/<(h2|h3|img|b|strong)[^>]*>/g, "<p>")
                        .slice(0, 120);
                      if (item.descricao.length > 120) textPreview += " (...)";
                      return (
                        <FlexInColumn className={"item item-" + index}>
                          <div
                            style={{
                              border: "2px solid #0C1151",
                              width: "100%",
                              borderRadius: "10px",
                              display: "flex",
                            }}
                          >
                            {windowWidth > 992 && (
                              <div
                                className="item__image"
                                style={{
                                  backgroundImage: "url(" + item.url + ")",
                                  backgroundSize: "cover",
                                  backgroundPosition: "center center",
                                }}
                              >
                                {/* <picture style={{opacity: '0'}}>
                                <source srcSet={item.url} media="(min-width: 990px)"  />
                                <img src={item.url} alt={item.alt} />
                              </picture>        */}{" "}
                              </div>
                            )}
                            <div
                              className="item__content"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <div className="item__date">
                                  <FontAwesomeIcon icon={faCalendarDay} />
                                  {item.date}
                                </div>
                                <h3 className="item__title">{item.titulo}</h3>
                                <div
                                  style={{
                                    fontSize: "12px",
                                    lineHeight: "1.4",
                                    color: "#212529",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: textPreview,
                                  }}
                                ></div>
                                {windowWidth <= 992 && (
                                  <div
                                    className="item__image"
                                    style={{
                                      backgroundImage: "url(" + item.url + ")",
                                      backgroundSize: "cover",
                                      backgroundPosition: "center center",
                                    }}
                                  >
                                    {/* <picture style={{opacity: '0'}}>
                                <source srcSet={item.url} media="(min-width: 990px)"  />
                                <img src={item.url} alt={item.alt} />
                              </picture>          */}{" "}
                                  </div>
                                )}
                              </div>
                              <div>
                                <Link
                                  to={"/agenda/evento/" + item.slug}
                                  className="item__cta"
                                >
                                  Saber mais »
                                </Link>
                              </div>
                            </div>
                          </div>
                        </FlexInColumn>
                      );
                    })}
                  </SectionCards>
                </Grid3>
              </>
            )}
            <Grid2>
              {eventos.slice(4, 10).map((item, index) => {
                let titlePreview = item.titulo.slice(0, 40);
                if (item.titulo.length > 40) titlePreview += " (...)";
                let textPreview = item.descricao
                  .replace(/<(h2|h3|img|b|strong)[^>]*>/g, "<p>")
                  .slice(0, 80);
                if (item.descricao.length > 80) textPreview += " (...)";
                return (
                  <div className="item">
                    <div className="item__image">
                      <picture>
                        <source srcSet={item.url} media="(min-width: 990px)" />
                        <img src={item.url} alt={item.titulo} />
                      </picture>
                    </div>
                    <Space direction="vertical">
                      <span className="item__title">{titlePreview}</span>
                      <div
                        style={{
                          fontSize: "12px",
                          lineHeight: "1.4",
                          color: "#212529",
                        }}
                        dangerouslySetInnerHTML={{ __html: textPreview }}
                      ></div>
                      <Link
                        to={"/agenda/evento/" + item.slug}
                        className="item__cta"
                      >
                        Saber mais »
                      </Link>
                    </Space>
                  </div>
                );
              })}
            </Grid2>
            {eventos?.length > 10 && (
              <SeeMore>
                <Link to="/socios/destaques/ver-todos">Ver Todos</Link>
              </SeeMore>
            )}
          </>
        ) : (
          <PageText title={noResults} />
        )}
      </Container>

      <RecommendedLinks
        title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
        links={[
          {
            title: "História",
            link: "/spap/historia",
          },
          {
            title: "Órgãos sociais",
            link: "/spap/orgaos-sociais",
          },
          {
            title: "Bolsas e prémios",
            link: "/spap/bolsas-e-premios",
          },
          {
            title: "Vantagens de ser sócio",
            link: "/socios/conheca-as-vantagens",
          },
        ]}
      />
    </Wrapper>
  );
}
