import styled from "styled-components";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Container from "../../../../Components/Container";
import PageTitle from "../../../../Components/PageTitle";
import PageText from "../../../../Components/PageText";
import FlexInColumn from "../../../../Components/FlexInColumn";
import FlexInRow from "../../../../Components/FlexInRow";
import SidebarMenu from "../../../../Components/SidebarMenu";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin: 7.813vw 0 5.208vw;

  img {
    width: 100%;
  }

  .link {
    color: ${(props) => props.theme.orange};
    font-weight: bold;
    place-self: flex-start;
    transition: 0.3s ease;
    text-decoration: none;
    text-align: left;
  }

  .link:hover {
    color: ${(props) => props.theme.orangeHover};
  }

  .link .icon {
    color: ${(props) => props.theme.blue};
    margin-right: 5px;
  }

  .link:hover .icon {
    color: ${(props) => props.theme.blueHover};
  }

  @media (max-width: 992px) {
    margin-top: 120px;
  }
`;

const SectionGroups = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2.604vw;
  margin-top: 30px;

  .grid {
    gap: 1.563vw;
    display: grid;
    grid-template-columns: auto 1fr;
  }

  .title {
    text-align: left;
    color: ${(props) => props.theme.blue};
    opacity: 1;
    font-size: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: inherit;
    margin: 0;
  }

  .bar {
    background-color: #97c1d5;
    height: 1px;
    margin: auto 0;
  }

  .image {
    width: 11.979vw;
  }

  p {
    color: #000000;
    font-size: 0.938vw;
    letter-spacing: 0px;
    line-height: 1.25vw;
    text-align: left;
  }

  .gallery-item {
    cursor: pointer;
    position: relative;
    min-width: 27.917vw;
    max-width: 27.917vw;

    .cta {
      background-color: #f88436;
      bottom: 1.042vw;
      border-radius: 360px;
      left: 1.042vw;
      opacity: 0.8;
      padding: 0.417vw 0.625vw;
      position: absolute;
      text-transform: uppercase;
    }
  }

  @media (max-width: 992px) {
    p {
    }
  }
`;

const GroupCards = styled.div`
  display: grid;
  grid-column-gap: 11.979vw;
  grid-row-gap: 3.646vw;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: flex-start;

  .item {
    border: 3px solid ${(props) => props.theme.blue};
    border-radius: 0px 0px 1.042vw 1.042vw;
    text-decoration: none;
    overflow: hidden;
  }

  .item__image {
    background-color: #acacac;
    width: 100%;
    display: block;
    margin-bottom: 1.042vw;
  }

  .item__title {
    text-align: center;
    font-size: 1.25vw;
    font-weight: bold;
    line-height: inherit;
    letter-spacing: 0px;
    color: #287092;
    margin-bottom: 1.042vw;
    padding: 0 1.042vw;
  }

  .item__text {
    text-align: center;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    padding: 0 1.042vw;
  }

  .item__cta {
    background-color: ${(props) => props.theme.blue};
    border: 1px solid ${(props) => props.theme.blue};
    border-radius: 0;
    text-align: center;
    font-size: 1.146vw;
    font-weight: bold;
    line-height: 1.51vw;
    letter-spacing: 0px;
    color: ${(props) => props.theme.white};
    width: 100%;
    transition: 0.3s ease;
    padding: 0.781vw;
    margin-top: 1.042vw;
  }

  .item__cta:hover {
    background-color: ${(props) => props.theme.blueHover};
    color: ${(props) => props.theme.whiteHover};
  }

  @media (max-width: 992px) {
    grid-row-gap: 20px;
    grid-template-columns: 1fr;
    margin-top: 40px;

    .item {
      border-radius: 0px 0px 8px 8px;
    }

    .item__title {
      font-size: 4.333vw;
      line-height: 5.444vw;
    }

    .item__text {
      font-size: 3.333vw;
      line-height: 4.444vw;
    }

    .item__cta {
      font-size: 3.333vw;
      line-height: 4.444vw;
      padding: 10px;
    }
  }
`;

export default function Publicacoes(props) {
  const ListCards = [
    {
      title:
        "HER2 TESTING IN BREAST CANCER: ASCO/CAP CLINICAL PRACTICE GUIDELINE FOCUSED UPDATE",
      text: "Wolff AC, Hammond MEH, Allison KH, Harvey BE, Mangu PB, Bartlett JMS, Bilous M, Ellis IO, Fitzgibbons P, Hanna W, Jenkins RB, Press MF, Spears PA, Vance GH, Viale G, McShane LM, Dowsett M",
      doi: "10.5858/arpa.2018-0902-SA",
      link: "https://www.ncbi.nlm.nih.gov/pubmed/29846122",
      imageDesktop: require("../../../../Assets/Images/Publicacoes/Archives-Patology@2x.png"),
      imageMobile: require("../../../../Assets/Images/Publicacoes/Archives-Patology.png"),
      imageAlt: "",
    },
    {
      title:
        "HER2 TESTING AND CLINICAL DECISION MAKING IN GE ADC: GUIDELINE FROM CAP, ASCP, AND ASCO",
      text: "Bartley AN, Washington MK, Colasacco C, Ventura CB, Ismaila N, Benson AB, Carrato A, Gulley ML, Jain D, Kakar S, Mackay HJ, Streutker C, Tang L, Troxell M, Ajani JA.",
      doi: "10.1200/JCO.2016.69.4836",
      link: "https://pubmed.ncbi.nlm.nih.gov/28129524/",
      imageDesktop: require("../../../../Assets/Images/Publicacoes/Archives-Patology@2x.png"),
      imageMobile: require("../../../../Assets/Images/Publicacoes/Archives-Patology.png"),
      imageAlt: "",
    },
    {
      title: "HPV TESTING IN HEAD AND NECK CARCINOMAS: GUIDELINE FROM CAP",
      text: "Lewis JS Jr, Beadle B, Bishop JA, Chernock RD, Colasacco C, Lacchetti C, Moncur JT, Rocco JW, Schwartz MR, Seethala RR, Thomas NE, Westra WH, Faquin WC",
      doi: "10.5858/arpa.2017-0286-CP",
      link: "https://pubmed.ncbi.nlm.nih.gov/29251996/",
      imageDesktop: require("../../../../Assets/Images/Publicacoes/Archives-Patology@2x.png"),
      imageMobile: require("../../../../Assets/Images/Publicacoes/Archives-Patology.png"),
      imageAlt: "",
    },
    {
      title:
        "A COMMON CLASSIFICATION FRAMEWORK FOR NE NEOPLASMS: AN IARC AND WHO EXPERT CONSENSUS PROPOSAL",
      text: "Rindi G, Klimstra DS, Abedi-Ardekani B, Asa SL, Bosman FT, Brambilla E, Busam KJ, de Krijger RR, Dietel M, El-Naggar AK, Fernandez-Cuesta L, Klöppel G, McCluggage WG, Moch H, Ohgaki H, Rakha EA, Reed NS, Rous BA, Sasano H, Scarpa A, Scoazec JY, Travis WD, Tallini G, Trouillas J, van Krieken JH, Cree IA",
      doi: "10.1038/s41379-018-0110-y",
      link: "https://pubmed.ncbi.nlm.nih.gov/30140036/",
      imageDesktop: require("../../../../Assets/Images/Publicacoes/Archives-Patology@2x.png"),
      imageMobile: require("../../../../Assets/Images/Publicacoes/Archives-Patology.png"),
      imageAlt: "",
    },
    {
      title:
        "UPDATED MOLECULAR TESTING GUIDELINE FOR THE SELECTION OF LUNG CANCER PATIENTS FOR TREATMENT WITH TKI: GUIDELINE FROM CAP, IASLC, AND AMP",
      text: "Lindeman NI, Cagle PT, Aisner DL, Arcila ME, Beasley MB, Bernicker EH, Colasacco C, Dacic S, Hirsch FR, Kerr K, Kwiatkowski DJ, Ladanyi M, Nowak JA, Sholl L, Temple-Smolkin R, Solomon B, Souter LH, Thunnissen E, Tsao MS, Ventura CB, Wynes MW, Yatabe Y",
      doi: "10.5858/arpa.2017-0388-CP",
      link: "https://pubmed.ncbi.nlm.nih.gov/29355391/",
      imageDesktop: require("../../../../Assets/Images/Publicacoes/Archives-Patology@2x.png"),
      imageMobile: require("../../../../Assets/Images/Publicacoes/Archives-Patology.png"),
      imageAlt: "",
    },
    {
      title:
        "A CONSENSUS FOR CLASSIFICATION AND REPORTING OF PSEUDOMYXOMA PERITONEI AND ASSOCIATED APPENDICEAL NEOPLASIA: RESULTS OF PSOGI MODIFIED DELPHI PROCESS",
      text: "Carr NJ, Cecil TD, Mohamed F, Sobin LH, Sugarbaker PH, González-Moreno S, Taflampas P, Chapman S, Moran BJ; Peritoneal Surface Oncology Group International",
      doi: "10.1097/PAS.0000000000000535",
      link: "https://pubmed.ncbi.nlm.nih.gov/26492181/",
      imageDesktop: require("../../../../Assets/Images/Publicacoes/the-american-journal-of-surgical-pathology@2x.png"),
      imageMobile: require("../../../../Assets/Images/Publicacoes/the-american-journal-of-surgical-pathology.png"),
      imageAlt: "",
    },
    {
      title:
        "RECOMMENDATIONS FOR REPORTING TUMOR BUDDING IN COLORECTAL CANCER BASED ON THE INTERNATIONAL TUMOR BUDDING CONSENSUS CONFERENCE (ITBCC) 2016",
      text: "Lugli A, Kirsch R, Ajioka Y, Bosman F, Cathomas G, Dawson H, El Zimaity H, Fléjou JF, Hansen TP, Hartmann A, Kakar S, Langner C, Nagtegaal I, Puppa G, Riddell R, Ristimäki A, Sheahan K, Smyrk T, Sugihara K, Terris B, Ueno H, Vieth M, Zlobec I, Quirke ",
      doi: "10.1038/modpathol.2017.46",
      link: "https://www.ncbi.nlm.nih.gov/pubmed/28548122",
      imageDesktop: require("../../../../Assets/Images/Publicacoes/Modern-Patology@2x.png"),
      imageMobile: require("../../../../Assets/Images/Publicacoes/Modern-Patology.png"),
      imageAlt: "",
    },
  ];

  return (
    <Wrapper>
      <Helmet>
        <title>
          Publicações | Sociedade Portuguesa de Anatomia Patológica - SPAP
        </title>
        <meta
          name="description"
          content="Publicações | Sociedade Portuguesa de Anatomia Patológica - SPAP"
        />
        <meta
          name="keywords"
          content="spap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>

      <Container>
        <PageTitle
          title="Publicações"
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "Ferramentas úteis",
              link: "/ferramentas-uteis",
            },
            {
              text: "Publicações",
            },
          ]}
          imageDesktop={require("../../../../Assets/Images/Publicacoes@2x.png")}
          imageMobile={require("../../../../Assets/Images/Publicacoes.png")}
          iamgeAlt="Publicações"
        />

        <FlexInRow gap="6.146vw">
          <SectionGroups>
            <FlexInRow gap="2.604vw">
              {ListCards.length > 0 && (
                <GroupCards>
                  {ListCards.map((item, index) => {
                    return (
                      <Link
                        to={item.link}
                        target="_blank"
                        className={"item item-" + index}
                      >
                        <picture className="item__image">
                          <source
                            srcSet={item.imageDesktop}
                            media="(min-width: 990px)"
                          />
                          <img src={item.imageMobile} alt={item.imageAlt} />
                        </picture>
                        <h3 className="item__title">{item.title}</h3>
                        <p className="item__text">{item.text}</p>
                        <p className="item__text">DOI: {item.doi}</p>
                        <button className="item__cta">Ver publicação</button>
                      </Link>
                    );
                  })}
                </GroupCards>
              )}
            </FlexInRow>
          </SectionGroups>
        </FlexInRow>
      </Container>
    </Wrapper>
  );
}
