import styled from "styled-components";
import { useRef, useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Popconfirm,
  Drawer,
  DatePicker,
  notification,
  Divider,
  Button,
  Dropdown,
  message,
  Space,
  Switch,
  Tooltip,
  Input,
  Radio,
  Checkbox,
  Modal,
  Message,
  Table,
  Upload,
  Select,
  Pagination,
} from "antd";
import {
  DownOutlined,
  UserOutlined,
  CheckOutlined,
  LoadingOutlined,
  PlusOutlined,
  InboxOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Sidebar from "../../../Components/Backoffice/Sidebar";
import Card from "../../../Components/Backoffice/Card";
import Title from "../../../Components/Backoffice/Title";
import Header from "../../../Components/Backoffice/Header";
import Footer from "../../../Components/Backoffice/Footer";
import axios, { all } from "axios";

import { ReactComponent as IconUpload } from "../../../Assets/Images/Backoffice/Upload-off.svg";
import { ReactComponent as IconInfo } from "../../../Assets/Images/Backoffice/Notifications/Info.svg";
import { ReactComponent as IconClose } from "../../../Assets/Images/Backoffice/Close.svg";
import { ReactComponent as IconEdit } from "../../../Assets/Images/Backoffice/Edit.svg";
import { ReactComponent as IconCalendar } from "../../../Assets/Images/Backoffice/Calendar.svg";

import { EyeOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const { Dragger } = Upload;
const { RangePicker } = DatePicker;

const Wrapper = styled.main`
  background-color: #e8e9f3;
  display: grid;
  grid-template-columns: 7.083vw 1fr;
  min-height: 100vh;

  .btn-descricao {
    margin-top: 10px;
    height: 45px !important;
    padding: 0 15px;
    font-weight: bold;
  }

  .icon-edit {
    cursor: pointer;
  }

  .cta.publish {
    margin-right: 0;

    svg path {
      fill: #fff;
    }
  }

  .text {
    margin-bottom: 0 !important;
  }

  @media (max-width: 992px) {
  }
`;

const Content = styled.div`
  padding: 1.771vw;
`;

const CardHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  // gap: 60px;
  grid-template-columns: 1fr auto auto;
`;

const CardContent = styled.div`
  margin: 40px 0;
  position: relative;

  table,
  .ant-table-cell p {
    text-align: center;
  }

  table th,
  table li {
    color: #809fb8 !important;
    text-align: center !important;
  }

  .ant-checkbox-checked span.ant-checkbox-inner,
  .ant-checkbox-checked:hover span.ant-checkbox-inner {
    background-color: #1ad598 !important;
    border-color: #1ad598 !important;
  }

  .ant-checkbox:hover span.ant-checkbox-inner,
  .ant-checkbox-wrapper.item__checkbox:hover .ant-checkbox-inner {
    border-color: #1ad598 !important;
  }

  .user-name {
    color: #494d7d;
  }

  .icon-upload {
    cursor: pointer;
    height: 46px;
    width: 46px;
  }

  .user-number {
    border-color: #d9e1e7cc;
    color: #809fb8;
    text-align: center;
  }

  .user-active {
    background-color: #1ad5984d;
    border: none;
    color: #1ad598;
  }

  .user-desactive {
    background-color: #ea3a3d4d;
    border: none;
    color: #ea3a3d;
  }

  .user-disabled {
    background-color: #d9e1e7;
    border: none;
    color: #99b2c6;
  }

  li.ant-pagination-item {
    border: 1px solid #d9e1e7cc;
  }

  li.ant-pagination-item.ant-pagination-item-active {
    background-color: #8688a8;
    border-color: #8688a8;
    color: #ffffff;
  }
`;

const UploadButtonStyle = styled.button`
  background-color: #494d7d;
  border: none;
  color: #ffffff;
  transition: 0.3s ease;
  font-size: 14px;
  height: 40px;
  padding: 4px 15px;
  border-radius: 6px;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);

  :hover {
    background-color: #1ad598;
    color: #ffffff !important;
  }
`;

const AlertMesssage = styled.div`
  background: #f1f5f700 0% 0% no-repeat padding-box;
  border: 2px solid #ffffff;
  border-radius: 16px;
  text-align: left;
  letter-spacing: 0px;
  padding: 10px;
  color: #0c1151;
  font-style: italic;
  width: 100%;

  circle {
    fill: #0c1151;
  }

  svg path {
    fill: #fff;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 18px;

  .form {
    place-self: flex-start;
    width: 100%;
  }
`;

const GridCards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 18px;

  .item {
    margin: 0;
  }

  .disabled {
    opacity: 0.7 !important;
  }

  .item__title {
    color: #494d7d;
    letter-spacing: 0px;
    font-size: 18px;
    font-weight: bold;
    line-height: 18px;
    text-align: left;
  }

  .item__text {
    color: #c2c4d4;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }

  .card-gallery {
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .header-gallery-div {
    display: block;
  }

  .gallery-title {
    display: flex;
    justify-content: space-between;
  }
`;

const ContentFooter = styled.div`
  align-items: center;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  margin-top: 18px;

  button {
    display: block !important;
    font-size: 15px;
    font-weight: bold;
    height: 45px;
    line-height: 18px;

    i {
      margin: 0 3px;
    }
  }

  a {
    text-decoration: none;
  }
`;

const Files = styled.section`
  display: flex;
  flex-direction: column;

  h4 {
    text-align: left;
    font: normal normal bold 20px/20px Roboto;
    letter-spacing: 0px;
    color: #050505;
  }

  .text {
    color: #809fb8;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0px;
  }

  .center {
    text-align: center;
  }

  .cta {
    display: block !important;
    font-size: 15px;
    font-weight: bold;
    height: 45px;
    line-height: 18px;

    i {
      margin: 0 3px;
    }

    a {
      text-decoration: none;
    }
  }

  .cta.publish {
    margin-right: 0;

    svg path {
      fill: #fff;
    }
  }
`;

var styles = {
  h6_label: {
    color: "#809FB8",
    fontFamily: "Roboto",
    fontSize: "15px",
    marginTop: "1%",
  },
  input_class_disabled: {
    marginBottom: "2%",
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
    opacity: 0.6,
    pointerEvents: "none",
  },
  input_class: {
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
    width: "100%",
  },
  textarea_class: {
    borderRadius: "6px",
    border: "1px solid #809FB8",
    color: "#0C1151",
  },
  switch_label: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    height: "45px",
    borderRadius: "8px",
    padding: "0px 16px",
    color: "#809FB8",
    border: "1px solid #809FB8",
    textAlign: "center",
  },
  upload: {
    width: "100%",
  },
};

export default function DashboardFicheiros() {
  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const [filesPDF, setFilesPDF] = useState([]);
  const [fileUploadList, setFileUploadList] = useState("");
  const [tituloPDF, setTituloPDF] = useState("");
  const [descricaoPDF, setDescricaoPDF] = useState("");
  const [showPDFDrawer, setShowPDFDrawer] = useState(false);
  const [pdfFileEdit, setPDFFileEdit] = useState("");
  const [tituloPDFEdit, setTituloPDFEdit] = useState("");
  const [descricaoPDFEdit, setDescricaoPDFEdit] = useState("");
  const [removePDFModalOpen, setRemovePDFModalOpen] = useState(false);
  const [idToRemove, setIdToRemove] = useState(false);
  const [titleToRemove, setTitleToRemove] = useState(false);
  const [pdfIdEdit, setPdfIdEdit] = useState("");

  notification.config({
    placement: "bottomRight",
    bottom: 10,
    duration: 5,
    rtl: false,
  });

  const clickToPDFDrawer = (item) => {
    setPdfIdEdit(item.id);
    setDescricaoPDFEdit(item.descricao);
    setTituloPDFEdit(item.titulo);
    setPDFFileEdit(item.file);
    setShowPDFDrawer(true);
  };

  useEffect(() => {
    fetch(`https://api.spap.pt/api/all-ficheiros`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.ficheiros.length === 0) {
          console.log("nenhum ficheiros");
          return;
        }

        getFiles(data);
      })
      .catch((error) => console.error(error));
  }, []);

  const getFiles = (data) => {
    data.ficheiros.filter((file) => {
      if (file != null) {
        let date = file.updated_at.substring(0, 10);
        let [year, month, day] = date.split("-");
        file.dataToShow = day + "/" + month + "/" + year;

        return file;
      }
    });

    setFilesPDF(data.ficheiros);
  };

  const propPDF = {
    onRemove: (file) => {
      const index = fileUploadList.indexOf(file);
      const newFileList = fileUploadList.slice();
      newFileList.splice(index, 1);
      setFileUploadList(newFileList);
    },
    beforeUpload: (file) => {
      setFileUploadList([...fileUploadList, file]);
      return false;
    },
    fileUploadList,
  };

  const handleSubmitPDF = (e) => {
    if (!fileUploadList.length || !tituloPDF.length || !descricaoPDF.length) {
      notification.error({
        description:
          "Para guardar um novo documento, preencha todos os campos e faça o upload do ficheiro.",
      });
      return;
    }

    const formData = new FormData();

    formData.append("titulo", tituloPDF);
    formData.append("file", fileUploadList[0]);
    formData.append("descricao", descricaoPDF);

    // You can use any AJAX library you like
    fetch("https://api.spap.pt/api/admin/store-file", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            setFileUploadList([]);
            setTituloPDF("");
            setDescricaoPDF("");
            getFiles(data);
          })
          .catch((err) => {});
      })
      .then(() => {
        setFileUploadList([]);
        notification.success({
          description: "O ficheiro foi submetido com sucesso!",
        });
      })
      .catch(() => {
        notification.error({
          description: "Erro ao submeter o ficheiro! Tente novamente.",
        });
      });
  };

  const [currentItemsPDF, setCurrentItemsPDF] = useState(filesPDF.slice(0, 8));
  const [itemOffsetPDF, setItemOffsetPDF] = useState(0);
  const itemsPerPagePDF = 8;

  const handlePagePDF = (page) => {
    const newOffset = ((page - 1) * itemsPerPagePDF) % filesPDF.length;
    setItemOffsetPDF(newOffset);
  };

  useEffect(() => {
    const endOffset = itemOffsetPDF + itemsPerPagePDF;

    setCurrentItemsPDF(filesPDF.slice(itemOffsetPDF, endOffset));
  }, [itemOffsetPDF, filesPDF]);

  const onChangePDFStatus = (checked, id) => {
    let status = checked ? 1 : 0;

    axios
      .post(
        "https://api.spap.pt/api/admin/set-pdf-status",
        { status, id },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        if (res.data.ficheiros.length) {
          getFiles(data);
        }
      })
      .catch((error) => {});
  };

  const openRemovePDFModal = (id, titulo) => {
    setIdToRemove(id);
    setTitleToRemove(titulo);
    setRemovePDFModalOpen(true);
  };

  const handleRemoveFile = (e) => {
    axios
      .post(
        "https://api.spap.pt/api/admin/remove-file",
        { idToRemove },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        setIdToRemove("");
        setRemovePDFModalOpen(false);
        if (res.status == 200) {
          getFiles(data);
          notification.success({
            description: "O Ficheiro foi removida com sucesso!",
          });
        }
      })
      .catch((error) => {
        // notification.error({description: 'Erro ao remover a imagem da galeria! Tente novamente.'});
      });
  };

  const handleEditPDF = () => {
    if (!tituloPDFEdit.length || !descricaoPDFEdit.length) {
      notification.error({
        description: "Para editar o PDF, preencha os campos respetivos.",
      });
      return;
    }

    const formData = new FormData();

    if (fileUploadList.length) {
      formData.append("file", fileUploadList[0]);
    }

    formData.append("fileId", pdfIdEdit);
    formData.append("titulo", tituloPDFEdit);
    formData.append("descricao", descricaoPDFEdit);

    // You can use any AJAX library you like
    fetch("https://api.spap.pt/api/admin/update-file", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            getFiles(data);
          })
          .catch((err) => {});
      })
      .then(() => {
        setShowPDFDrawer(false);
        notification.success({
          description: "O PDF foi atualizado com sucesso!",
        });
      })
      .catch(() => {
        notification.error({
          description: "Já existe um ficheiro com o mesmo nome neste Clube!",
        });
      })
      .finally(() => {
        setUploading(false);
      });
  };

  return (
    <Wrapper>
      <div>
        <Sidebar />
      </div>
      <Content>
        <Header />
        <Files>
          <Row align={"left"} style={{ marginBottom: "10px" }}>
            <Col span={12}>
              <Title>Ficheiros</Title>
            </Col>
          </Row>
          <Grid>
            <Card
              className="form"
              style={{ position: "sticky !important", top: "100px" }}
            >
              <Space direction="vertical" className="w-100">
                <h4>Faça upload do seu documento</h4>
                <label className="text">Título do Documento</label>
                <Input
                  key={"titulo_pdf"}
                  style={styles.input_class}
                  name="titulo_pdf"
                  placeholder="Título do PDF"
                  defaultValue={tituloPDF}
                  onChange={(e) => setTituloPDF(e.target.value)}
                />
                <label className="text">Descrição do Documento</label>
                <TextArea
                  key={"descricao_pdf"}
                  name="descricao_pdf"
                  placeholder="Desrição do PDF"
                  rows={4}
                  defaultValue={descricaoPDF}
                  onChange={(e) => setDescricaoPDF(e.target.value)}
                />
                <Dragger
                  {...propPDF}
                  fileList={[...fileUploadList]}
                  key={"dragger-pdf"}
                >
                  <p className="ant-upload-drag-icon">
                    <IconUpload />
                  </p>
                  <p className="ant-upload-text text">
                    Upload de novo ficheiro
                  </p>
                  <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                </Dragger>
                <p className="text center" align="center">
                  Tamanho máximo de upload (2 MB)
                </p>
                <Input
                  onClick={handleSubmitPDF}
                  type="button"
                  className="cta w-100"
                  value="Upload"
                />
              </Space>
            </Card>
            {filesPDF.length > 0 && (
              <Space direction="vertical">
                <GridCards>
                  {currentItemsPDF.map((item, index) => {
                    return (
                      <Card
                        className={
                          item.status == 0
                            ? "item disabled item-" + index
                            : "item item-" + index
                        }
                        key={"ficheiros-" + item.id}
                      >
                        <CardHeader>
                          <Title className="item__title">{item.titulo}</Title>
                          <IconClose
                            className="icon icon-close"
                            onClick={() =>
                              openRemovePDFModal(item.id, item.titulo)
                            }
                          />
                        </CardHeader>
                        <Space direction="vertical" style={{ width: "100%" }}>
                          <p className="item__text">{item.descricao}</p>
                          <Space
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              alignContent: "center",
                            }}
                          >
                            <Space size={20}>
                              <Space>
                                <IconCalendar className="icon icon-calendar" />
                                <span className="text">{item.dataToShow}</span>
                              </Space>
                              <Space>
                                {item.status == 1 ? (
                                  <>
                                    {" "}
                                    <Switch
                                      defaultChecked
                                      onChange={(e) =>
                                        onChangePDFStatus(e, item.id)
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Switch
                                      onChange={(e) =>
                                        onChangePDFStatus(e, item.id)
                                      }
                                    />
                                  </>
                                )}
                                <span className="text">
                                  {item.status == 1 ? "Ativo" : "Inativo"}
                                </span>
                              </Space>
                              <Space
                                className="action-edit"
                                onClick={() => clickToPDFDrawer(item)}
                              >
                                <IconEdit className="icon icon-edit" />
                                <span>Editar</span>
                              </Space>
                            </Space>
                            <div>
                              <Space className="action-edit">
                                <a
                                  target="_blank"
                                  href={
                                    "https://api.spap.pt/ficheiros/" +
                                    item.id +
                                    "/" +
                                    item.file
                                  }
                                >
                                  <EyeOutlined
                                    style={{ paddingRight: "5px" }}
                                  />
                                </a>
                              </Space>
                            </div>
                          </Space>
                        </Space>
                      </Card>
                    );
                  })}
                </GridCards>
                <ContentFooter>
                  <Pagination
                    defaultCurrent={1}
                    total={filesPDF.length}
                    pageSize={itemsPerPagePDF}
                    onChange={handlePagePDF}
                  />
                </ContentFooter>
              </Space>
            )}
          </Grid>
        </Files>
        <Divider />
        <Footer />
      </Content>
      <Modal
        key={"remove-file-" + idToRemove}
        title="Está prestes a eliminar um ficheiro"
        open={removePDFModalOpen}
        onOk={handleRemoveFile}
        onCancel={() => setRemovePDFModalOpen(false)}
        okText="Confirmar"
        cancelText="Cancelar"
      >
        <p>
          Tem a certeza que pretende remover o ficheiro <b>'{titleToRemove}'</b>
          ?
        </p>
      </Modal>
      <Drawer
        key={"editar-pdf-" + pdfIdEdit}
        title="Editar PDF"
        width={400}
        onClose={() => setShowPDFDrawer(false)}
        open={showPDFDrawer}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button onClick={setShowPDFDrawer}>Cancelar</Button>
            <Button
              onClick={handleEditPDF}
              type="submit"
              style={{
                borderRadius: "6px",
                background: "#494D7D",
                color: "#fff",
              }}
            >
              Guardar
            </Button>
          </Space>
        }
      >
        <form layout="vertical" id="update-event-form">
          <Col>
            <Col span={24}>
              <h5
                style={{
                  marginBottom: "10px",
                  fontSize: "18px",
                  textAlign: "center",
                }}
              >
                Visualizar o ficheiro PDF
              </h5>
              <p style={{ textAlign: "center", marginBottom: "1%" }}>
                <a
                  href={
                    "https://api.spap.pt/ficheiros/" +
                    pdfIdEdit +
                    "/" +
                    pdfFileEdit
                  }
                  target="_blank"
                >
                  <IconUpload />
                </a>
              </p>
              <p style={{ textAlign: "center" }}>{pdfFileEdit}</p>
            </Col>
            <Col span={24}>
              <Dragger
                key={"dragger-pdf" + pdfIdEdit}
                {...propPDF}
                style={{ marginBottom: "5px", width: "100%" }}
              >
                <div>
                  <p className="ant-upload-drag-icon">
                    <IconUpload />
                  </p>
                  <p className="ant-upload-text text">
                    Upload do novo Ficheiro{" "}
                  </p>
                  <p className="ant-upload-hint">
                    Se pretende atualizar o ficheiro, faça drag and drop ou
                    clique nesta caixa.
                  </p>
                </div>
              </Dragger>
            </Col>
            <Divider style={{ background: "#fff", margin: "12px 0" }}></Divider>
            <Row
              className="row-drawer"
              gutter={[8, 8]}
              style={{ flexFlow: "column !important" }}
            >
              <Col span={24}>
                <h6 style={styles.h6_label}>Título</h6>
                <Input
                  key={"titulo-pdf" + pdfIdEdit}
                  placeholder="Título"
                  name="titulo"
                  style={styles.input_class}
                  defaultValue={tituloPDFEdit}
                  onChange={(e) => setTituloPDFEdit(e.target.value)}
                  required
                />
              </Col>
              <Col span={24}>
                <h6 style={styles.h6_label}>Descrição</h6>
                <Input
                  key={"descricao-pdf" + pdfIdEdit}
                  placeholder="Descrição"
                  name="descricao"
                  style={styles.input_class}
                  defaultValue={descricaoPDFEdit}
                  onChange={(e) => setDescricaoPDFEdit(e.target.value)}
                  required
                />
              </Col>
            </Row>
          </Col>
        </form>
      </Drawer>
    </Wrapper>
  );
}
