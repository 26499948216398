import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Badge,
  Avatar,
  Divider,
  Button,
  Space,
  Input,
  Typography,
  Drawer,
  Form,
  notification,
} from "antd";
import axios from "axios";
// import CarEmpty from "../../Assets/Images/carrinho-illustration-empty.png";
// import CheckMark from "../../Assets/Images/icons8-check.gif";
// import EventoTeste from "../../Assets/Images/evento-teste.jpg";
import styled from "styled-components";
import ReactQuill from "react-quill";

import {
  MessageOutlined,
  SendOutlined,
  FieldTimeOutlined,
  UserOutlined,
  EditOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { parse } from "date-fns";
import urlSlug from "url-slug";
import { act } from "react";
import dayjs from "dayjs";

const Cont = styled.main`
  .mb-100 {
    margin-bottom: 100px !important;
  }

  .avatar-thread {
    aspect-ratio: 1 / 1 !important;
  }

  .send-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .ant-btn-primary.cta {
    display: flex;
    background-color: rgb(12, 17, 81) !important;
    padding: 0 30px;
    height: 40px;
    color: #fff !important;
  }

  .user-item {
    height: min-content;
  }

  .h-250 {
    height: 250px !important;
  }

  .grid-users {
    gap: 10px;
    grid-template-columns: repeat(3, 3fr);
    overflow-y: scroll;
    max-height: 250px !important;
  }

  .ql-editor * {
    font-size: 14px !important;
  }

  .click-msg {
    margin-top: 10px;
    font-size: 16px !important;
  }

  .not-new-msg-color {
    background: transparent;
    border: 1px solid #e8e9f3;
  }

  .new-msg-color {
    background: #d2d5eb !important;
  }

  .new-msg-color .msg-thread {
    color: #fff !important;
  }

  .msg-thread,
  .msg-thread * {
    font-size: 14px !important;
    line-height: 1.4;
    color: "#C2C4D4";
  }

  .msg-thread p {
    margin-bottom: 2px !important;
  }

  .chat-box .ant-avatar {
    width: 75%;
    text-align: center;
    height: auto;
  }

  .chat-box p {
    font-size: 13px !important;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 15px;
    background-color: lightgrey !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #fff !important;
  }

  .ant-input {
    height: 40px;
  }

  .card-msg {
    padding: 0 !important;
    margin-bottom: 0 !important;
  }

  .ant-btn-primary.cta {
    background: rgb(12, 17, 81) !important;
  }

  .span-last-msg * {
    color: rgb(12, 17, 81);
  }

  .card-message {
    margin-bottom: 20px;
  }

  .go-to-msg {
    color: rgb(12, 17, 81);
    font-size: 13px;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-30 {
    margin-bottom: 30px;
  }

  ::-webkit-scrollbar * {
    background: transparent !important; // manage scrollbar background color here
  }

  .card-header {
    grid-template-columns: 1fr auto !important;
  }

  .chat-div {
    height: 87vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .chat-div .ant-row:not() {
    margin-bottom: 20px;
  }

  .cta {
    display: flex !important;
    background: rgb(12, 17, 81) !important;
    padding: 0 30px;
    height: 40px;
    color: #fff !important;
  }

  .profile-box {
    grid-template-columns: auto auto !important;
    -webkit-box-pack: center;
    justify-content: end !important;
  }

  .chat-box {
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
    height: 65vh;
  }

  .row-message {
    margin-bottom: 20px;
  }

  .message-div .ql-editor,
  .ql-container {
    min-height: 75px !important;
  }

  .new-msg-div .ql-editor {
    min-height: 200px !important;
  }

  .message-div .ql-toolbar,
  .new-msg-div .ql-toolbar {
    background: #c2c4d4 !important;
    border-color: #c2c4d4 !important;
  }

  .quill > .ql-container > .ql-editor.ql-blank::before {
    font-size: 15px;
    color: #ccc;
  }

  .ql-toolbar.ql-snow {
    border-left: 0px !important;
    border-right: 0px !important;
    border-top: 0px !important;
    border-radius: 0px !important;
  }

  .ql-container {
    border: 0 !important;
  }

  .cancel-cta {
    height: 40px;
    padding: 0 30px;
    border: 1px solid rgb(12, 17, 81) !important;
  }

  .message-div .ql-container,
  .new-msg-div .ql-container {
    background: #fff !important;
    border-color: #fff !important;
    height: max-content !important;
  }

  .message-div .quill,
  .new-msg-div .quill {
    margin-top: 10px;
  }

  .message-from-me {
    background: #e8e9f3;
    padding: 15px !important;
    border-radius: 6px;
    color: #0c1151;
    font-size: 12px;
  }

  .message-from-me * {
    color: #0c1151 !important;
  }

  .space-message-time {
    font-size: 11px;
  }

  .space-last-msg {
    font-size: 12px !important;
    margin-left: 10px;
    color: #809fb8 !important;
  }

  p {
    margin-bottom: 5px;
  }

  .message-from-other {
    background: rgb(12, 17, 81);
    padding: 15px !important;
    border-radius: 6px;
    font-size: 12px;
  }

  .message-from-other * {
    color: #fff !important;
  }

  .message-div,
  .new-msg-div {
    background: #d6d7e2;
    border-radius: 16px;
    padding: 20px;
  }

  .subject {
    color: #06152b !important;
    font-size: 16px !important;
  }

  @media (max-width: 992px) {
  }
`;

const Profile = styled.div`
  align-items: center;
  display: grid;
  gap: 10px;
  grid-template-columns: auto 1fr;
  justify-content: center;

  .profile__image {
    border: 1px solid ${(props) => props.theme.blue};
    border-radius: 360px;
  }

  .profile__text {
    color: #0c1151;x
    font-size: 14px;
    font-weight: 20px;
    letter-spacing: 0px;
    line-height: bold;
    text-align: left;
  }
`;

const { Title } = Typography;
const { Text } = Typography;

export default function DrawerMensagens(props) {
  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const customFormat = "dd/MM/yyyy";
  const [form] = Form.useForm();
  const quillRef = useRef(null);

  const handleClose = () => {
    props.onChange(false);
  };

  const sendMessage = (toUser, thisSubject) => {
    if (!quillRef?.current?.value.length || !thisSubject) return;
    var bodyFormData = new FormData();
    bodyFormData.append("from_user", session_storage.all_data.user_session.id);
    bodyFormData.append("to_user", toUser);
    bodyFormData.append("message", quillRef.current.value);
    bodyFormData.append("subject", thisSubject);

    axios
      .post("https://api.spap.pt/api/messages", bodyFormData, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + session_storage.token,
        },
      })
      .then((response) => {
        notification.success({
          description: "Mensagem enviada com sucesso.",
        });
        props.onSave(response.data.messages);
        clearBodyMsg();
      });
  };

  const clearBodyMsg = () => {
    document.querySelectorAll(".ql-editor").forEach((elem) => {
      elem.innerHTML = "";
    });
  };

  return (
    <>
      <Drawer
        key={"drawer-thread"}
        className="overflow-h"
        title={
          <Row justify={"space-between"} align={"middle"}>
            <Col>
              <span className="subject">
                <b>Assunto:</b> "{props.subject}"
              </span>
            </Col>
            <Col>
              <Profile className="profile-box">
                <Badge className="profile__image">
                  {props.user?.avatar ? (
                    <Avatar
                      size={40}
                      src={"https://api.spap.pt/avatars/" + props.user?.avatar}
                    />
                  ) : (
                    <Avatar size={40} icon={<UserOutlined />} />
                  )}
                </Badge>
                <div className="profile__text">
                  Com: <br />
                  <b>{props.user?.name}</b>
                </div>
              </Profile>
            </Col>
          </Row>
        }
        width={550}
        onClose={handleClose}
        open={props.show}
      >
        <Cont>
          <Row className="card-message">
            <Col span={24} className="chat-div">
              <div className="chat-box">
                {props.thread.map((msg) => {
                  return (
                    <>
                      <Row
                        gutter={[8, 8]}
                        className="row-message"
                        key={"msg-" + msg.id}
                      >
                        {msg.from_user !=
                        session_storage.all_data.user_session.id ? (
                          <>
                            <Col span={2} align="right">
                              {props.user?.avatar ? (
                                <Avatar
                                  size={"100%"}
                                  src={
                                    "https://api.spap.pt/avatars/" +
                                    props.user?.avatar
                                  }
                                />
                              ) : (
                                <Avatar
                                  className="avatar-thread"
                                  size={"100%"}
                                  icon={<UserOutlined />}
                                />
                              )}
                            </Col>
                            <Col span={20} className="message-from-other">
                              <div
                                style={{
                                  fontSize: "12px",
                                  lineHeight: "1.4",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: msg.message,
                                }}
                              ></div>
                              <Space className="space-message-time">
                                <div>
                                  <CalendarOutlined />{" "}
                                  {
                                    new Date(msg.created_at)
                                      .toLocaleString()
                                      .split(",")[0]
                                  }
                                </div>
                                <div>
                                  <FieldTimeOutlined />{" "}
                                  {
                                    new Date(msg.created_at)
                                      .toLocaleString()
                                      .split(",")[1]
                                  }
                                </div>
                              </Space>
                            </Col>
                          </>
                        ) : (
                          <>
                            <Col
                              offset={2}
                              span={20}
                              className="message-from-me"
                            >
                              <div
                                style={{
                                  fontSize: "12px",
                                  lineHeight: "1.4",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: msg.message,
                                }}
                              ></div>
                              <Space className="space-message-time">
                                <div>
                                  <CalendarOutlined />{" "}
                                  {
                                    new Date(msg.created_at)
                                      .toLocaleString()
                                      .split(",")[0]
                                  }
                                </div>
                                <div>
                                  <FieldTimeOutlined />{" "}
                                  {
                                    new Date(msg.created_at)
                                      .toLocaleString()
                                      .split(",")[1]
                                  }
                                </div>
                              </Space>
                            </Col>
                            <Col span={2} align="start">
                              {session_storage.all_data.user_session.avatar ? (
                                <Avatar
                                  size={"100%"}
                                  src={
                                    "https://api.spap.pt/avatars/" +
                                    session_storage.all_data.user_session.avatar
                                  }
                                />
                              ) : (
                                <Avatar
                                  className="avatar-thread"
                                  size={"100%"}
                                  icon={<UserOutlined />}
                                />
                              )}
                            </Col>
                          </>
                        )}
                      </Row>
                    </>
                  );
                })}
              </div>
              <ReactQuill
                ref={quillRef}
                key={"update-thread"}
                placeholder="Introduzir corpo de mensagem"
              ></ReactQuill>
              <div className="send-btn">
                <Button
                  key={"send-msg-drawer"}
                  type="primary"
                  className="cta"
                  onClick={() => sendMessage(props.user.id, props.subject)}
                >
                  Enviar <SendOutlined />
                </Button>
              </div>
            </Col>
          </Row>
        </Cont>
      </Drawer>
    </>
  );
}
