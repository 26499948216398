import React, { useEffect } from 'react'
import { loadRecaptcha } from './loadRecaptcha';

export default function Recaptcha({ sitekey, onToken }) {
  useEffect(() => {
    loadRecaptcha(sitekey);
  }, [sitekey]);

  useEffect(() => {
    const handleToken = (token) => {
      onToken(token);
    };

    if (window.grecaptcha && window.grecaptcha.enterprise) {
      window.grecaptcha.enterprise.ready(() => {
        window.grecaptcha.enterprise.execute(sitekey, { action: 'login' })
          .then(handleToken);
      });
    }
  }, [sitekey, onToken]);

  return null;
}
