import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  Divider,
  Checkbox,
  Modal,
  Upload,
  DatePicker,
  Result,
  Input,
  Space,
  notification,
  Switch,
  Select,
  Row,
  Col,
  Button,
} from "antd";
const { TextArea } = Input;
const { Dragger } = Upload;
import frontcss from "../../../../src/Assets/css/front.css";
import authService from "../../../Services/auth.service";
import { ReactComponent as LogoPhoto } from "../../../Assets/Images/Backoffice/Photo.svg";
import {
  ExclamationCircleOutlined,
  InboxOutlined,
  EditOutlined,
} from "@ant-design/icons";
import makeAnimated from "react-select/animated";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";

import Container from "../../../Components/Container";
import FlexInRow from "../../../Components/FlexInRow";
import FlexInColumn from "../../../Components/FlexInColumn";
import PageTitle from "../../../Components/PageTitle";
import SidebarMenu from "../../../Components/SidebarMenu";
import RecommendedLinks from "../../../Components/RecommendedLinks";
import { UserOutlined } from "@ant-design/icons";

import BgAreaSocio from "../../../Assets/Images/bg-area-de-socio@2x.png";
import PageText from "../../../Components/PageText";

import axios from "axios";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #000000;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  width: 100%;
  margin-top: 7.813vw;

  .salvar-btn {
    border-radius: 10px;
    background: #f88436;
    color: #fff;
    height: 42px;
    border: 1px solid #f88436;
    padding: 0px 20px;
    font-weight: bold;
  }

  img {
    width: 100%;
  }

  .ant-space-item {
    color: #000;
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    .row-btns {
      justify-content: center;
    }

    .row-btns button {
      margin-bottom: 10px;
    }
  }

  h5 {
    text-align: left;
  }

  .dados-perfil .ant-select-selection-item {
    padding-top: 8px;
    font-size: 14px;
  }
`;

const LoginMessage = styled.span`
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  line-height: initial;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;
`;

const SectionHighlightsCards = styled.section`
  padding: 20px 0;

  .link {
    color: ${(props) => props.theme.orange};
    transition: 0.3s ease;
  }

  .link:hover {
    color: ${(props) => props.theme.orangeHover};
  }

  @media (max-width: 992px) {
    .sidebar-menu {
      margin: auto;
      place-self: center;
    }
  }
`;

const Title = styled.h2`
  color: #0c1151;
  font-size: 2.083vw;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 2.5vw;
  opacity: 1;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 992px) {
    font-size: 5.556vw;
    line-height: 6.667vw;
  }
`;

const HighlightsCards = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 5.938vw;
  grid-row-gap: 4.167vw;

  .card {
    border: none;
    border-radius: 1.042vw;
    overflow: hidden;
    position: relative;
    /* max-height: 17.188vw; */
  }

  .card .item__hover {
    opacity: 0;
    transition: 0.5s ease;
    width: 100%;
    padding: 0;
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
  }

  .card:hover .item__hover {
    opacity: 1;
  }
`;

const StudyActions = styled.section`
  background-image: url(${BgAreaSocio});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 11.198vw 0;
  margin-top: -11.198vw;

  .title {
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    line-height: inherit;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    margin-bottom: 1.042vw;
    padding-top: 5.599vw;
  }

  .text {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 1.875vw;
    width: 53.125vw;
  }

  a {
    background: #eeeeee 0% 0% no-repeat padding-box;
    border: 3px solid #0c1151;
    border-radius: 20px;
    padding: 20px;
    opacity: 1;
    text-align: center;
    font-size: 1.042vw;
    font-weight: bold;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #0c1151;
    text-decoration: none;
    text-transform: uppercase;
    flex-direction: column;
    opacity: 1;
    width: 13.542vw;
    min-height: 10.417vw;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.938vw;
    transition: 0.3s ease;

    img {
      width: 4.583vw;
    }
  }

  a.orange {
    background: #f88436 0% 0% no-repeat padding-box;
    border: 3px solid #0c1151;
    border-radius: 20px;
    opacity: 1;
  }

  a:hover {
    color: #0c1151;
    box-shadow: 0 8px 24px #00000055;
  }

  @media (max-width: 992px) {
    .text {
    }
  }

  @media (min-width: 1920px) {
    .text {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  ${(props) =>
    props.gap
      ? `
    gap: ${props.gap};
  `
      : `
    gap: 5.208vw;
  `}

  .ant-avatar.ant-avatar-icon {
    width: 100%;
    height: 100%;
    font-size: 44px;
    line-height: 1.5;
    border: 1px solid #0c1151;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    gap: 50px;
  }
`;

var styles = {
  h6_label: {
    color: "rgb(12, 17, 81)",
    fontFamily: "Roboto",
    fontSize: "14px",
    marginBottom: "0",
  },
  h5_label: {
    fontFamily: "Fira Sans, Condensed",
    fontSize: "18px",
    color: "#0C1151",
    marginBottom: "0",
  },
  input_class: {
    width: "100%",
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #707070",
    color: "#0C1151",
  },

  select_class: {
    borderRadius: "6px",
    height: "45px",
    color: "#0C1151",
    width: "100%",
  },
  switch_label: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    height: "45px",
    borderRadius: "8px",
    padding: "0px 16px",
    color: "#809FB8",
    border: "1px solid #809FB8",
    textAlign: "center",
  },
  upload: {
    width: "100%",
  },
  colPadding: {
    padding: "0px 10px",
  },
  input_class2: {
    width: "100%",
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #ccc",
    color: "#0C1151",
  },
};
const animatedComponents = makeAnimated();
export default function DadosDePerfil(props) {
  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const setCapitalize = (str) => {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  };
  const [instituicaoOptions, setInstituicaoOptions] = useState(() => {
    let inst = [
      { value: "ARS ALGARVE", label: "ARS ALGARVE" },
      {
        value: "BRITISH HOSPITAL LISBON XXI, S.A",
        label: "BRITISH HOSPITAL LISBON XXI, S.A",
      },
      { value: "CEDAP", label: "CEDAP" },
      {
        value: "CENTRO DE MEDICINA E REABILITAÇÃO DE ALCOITÃO",
        label: "CENTRO DE MEDICINA E REABILITAÇÃO DE ALCOITÃO",
      },
      {
        value: "CENTRO DE SAÚDE DE OLEIROS",
        label: "CENTRO DE SAÚDE DE OLEIROS",
      },
      {
        value: "CENTRO DE SAÚDE PEDRÓGÃO GRANDE",
        label: "CENTRO DE SAÚDE PEDRÓGÃO GRANDE",
      },
      {
        value: "CENTRO HOSPITALAR ALTO AVE E.P.E.",
        label: "CENTRO HOSPITALAR ALTO AVE E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR ALTO MINHO E.P.E.",
        label: "CENTRO HOSPITALAR ALTO MINHO E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR BAIXO ALENTEJO E.P.E.",
        label: "CENTRO HOSPITALAR BAIXO ALENTEJO E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR BAIXO VOUGA",
        label: "CENTRO HOSPITALAR BAIXO VOUGA",
      },
      {
        value: "CENTRO HOSPITALAR BARLAVENTO ALGARVIO, E.P.E.",
        label: "CENTRO HOSPITALAR BARLAVENTO ALGARVIO, E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR BARREIRO MONTIJO E.P.E.",
        label: "CENTRO HOSPITALAR BARREIRO MONTIJO E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR CALDAS DA RAINHA E.P.E.",
        label: "CENTRO HOSPITALAR CALDAS DA RAINHA E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR CASCAIS E.P.E.",
        label: "CENTRO HOSPITALAR CASCAIS E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR COVA DA BEIRA, E.P.E.",
        label: "CENTRO HOSPITALAR COVA DA BEIRA, E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR DE LEIRIA, E.P.E.",
        label: "CENTRO HOSPITALAR DE LEIRIA, E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR DO ALGARVE",
        label: "CENTRO HOSPITALAR DO ALGARVE",
      },
      {
        value: "CENTRO HOSPITALAR DO FUNCHAL",
        label: "CENTRO HOSPITALAR DO FUNCHAL",
      },
      {
        value: "CENTRO HOSPITALAR DO OESTE",
        label: "CENTRO HOSPITALAR DO OESTE",
      },
      {
        value: "CENTRO HOSPITALAR E UNIVERSITÁRIO DE COIMBRA",
        label: "CENTRO HOSPITALAR E UNIVERSITÁRIO DE COIMBRA",
      },
      {
        value: "CENTRO HOSPITALAR E UNIVERSITÁRIO DE SÃO JOÃO E.P.E.",
        label: "CENTRO HOSPITALAR E UNIVERSITÁRIO DE SÃO JOÃO E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR E UNIVERSITÁRIO DO ALGARVE",
        label: "CENTRO HOSPITALAR E UNIVERSITÁRIO DO ALGARVE",
      },
      {
        value: "CENTRO HOSPITALAR E UNIVERSITÁRIO DO PORTO E.P.E.",
        label: "CENTRO HOSPITALAR E UNIVERSITÁRIO DO PORTO E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR ENTRE DOURO E VOUGA E.P.E",
        label: "CENTRO HOSPITALAR ENTRE DOURO E VOUGA E.P.E",
      },
      {
        value: "CENTRO HOSPITALAR LISBOA OCIDENTAL E.P.E.",
        label: "CENTRO HOSPITALAR LISBOA OCIDENTAL E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR MÉDIO - TEJO  E.P.E.",
        label: "CENTRO HOSPITALAR MÉDIO - TEJO  E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR MÉDIO AVE, E.P.E.",
        label: "CENTRO HOSPITALAR MÉDIO AVE, E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR MUNDIAL CONFIANÇA - ÉVORA E.P.E.",
        label: "CENTRO HOSPITALAR MUNDIAL CONFIANÇA - ÉVORA E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR NORDESTE, E.P.E.",
        label: "CENTRO HOSPITALAR NORDESTE, E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR PÓVOA DO VARZIM/VILA CONDE E.P.E.",
        label: "CENTRO HOSPITALAR PÓVOA DO VARZIM/VILA CONDE E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR PSIQUIATRICO DE COIMBRA",
        label: "CENTRO HOSPITALAR PSIQUIATRICO DE COIMBRA",
      },
      {
        value: "CENTRO HOSPITALAR PSIQUIÁTRICO DE LISBOA E.P.E.",
        label: "CENTRO HOSPITALAR PSIQUIÁTRICO DE LISBOA E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR S. FRANCISCO,  E.P.E.",
        label: "CENTRO HOSPITALAR S. FRANCISCO,  E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR SETÚBAL, E.P.E.",
        label: "CENTRO HOSPITALAR SETÚBAL, E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR TÂMEGA E SOUSA E.P.E.",
        label: "CENTRO HOSPITALAR TÂMEGA E SOUSA E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR TONDELA VISEU E.P.E",
        label: "CENTRO HOSPITALAR TONDELA VISEU E.P.E",
      },
      {
        value: "CENTRO HOSPITALAR TRÁS OS MONTES E ALTO DOURO E.P.E",
        label: "CENTRO HOSPITALAR TRÁS OS MONTES E ALTO DOURO E.P.E",
      },
      {
        value: "CENTRO HOSPITALAR UNIVERSITÁRIO DE COIMBRA",
        label: "CENTRO HOSPITALAR UNIVERSITÁRIO DE COIMBRA",
      },
      {
        value: "CENTRO HOSPITALAR UNIVERSITÁRIO DE SANTO ANTÓNIO",
        label: "CENTRO HOSPITALAR UNIVERSITÁRIO DE SANTO ANTÓNIO",
      },
      {
        value: "CENTRO HOSPITALAR UNIVERSITÁRIO LISBOA CENTRAL E.P.E.",
        label: "CENTRO HOSPITALAR UNIVERSITÁRIO LISBOA CENTRAL E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR UNIVERSITÁRIO LISBOA NORTE, E.P.E.",
        label: "CENTRO HOSPITALAR UNIVERSITÁRIO LISBOA NORTE, E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR VILA NOVA DE GAIA / ESPINHO E.P.E.",
        label: "CENTRO HOSPITALAR VILA NOVA DE GAIA / ESPINHO E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR VILA REAL/PESO DA RÉGUA S.ª E.P.E.",
        label: "CENTRO HOSPITALAR VILA REAL/PESO DA RÉGUA S.ª E.P.E.",
      },
      {
        value: "CLÍNICA DE STO ANTÓNIO",
        label: "CLÍNICA DE STO ANTÓNIO",
      },
      {
        value: "CLÍNICA JOAQUIM CHAVES",
        label: "CLÍNICA JOAQUIM CHAVES",
      },
      {
        value: "CLÍNICA LUSÍADAS ALMADA",
        label: "CLÍNICA LUSÍADAS ALMADA",
      },
      {
        value: "CLÍNICA LUSÍADAS FARO",
        label: "CLÍNICA LUSÍADAS FARO",
      },
      {
        value: "CLÍNICA LUSÍADAS FÓRUM ALGARVE",
        label: "CLÍNICA LUSÍADAS FÓRUM ALGARVE",
      },
      {
        value: "CLÍNICA LUSÍADAS GAIA",
        label: "CLÍNICA LUSÍADAS GAIA",
      },
      {
        value: "CLÍNICA LUSÍADAS ORIENTE",
        label: "CLÍNICA LUSÍADAS ORIENTE",
      },
      {
        value: "CLÍNICA LUSÍADAS SACAVÉM",
        label: "CLÍNICA LUSÍADAS SACAVÉM",
      },
      {
        value: "CLÍNICA MÉDICA DO PADRÃO",
        label: "CLÍNICA MÉDICA DO PADRÃO",
      },
      {
        value: "CLÍNICA MÉDICA POVOA DO VARZIM - CLIPÓVOA",
        label: "CLÍNICA MÉDICA POVOA DO VARZIM - CLIPÓVOA",
      },
      {
        value: "CUF ALMADA CLÍNICA",
        label: "CUF ALMADA CLÍNICA",
      },
      {
        value: "CUF ALVALADE CLÍNICA",
        label: "CUF ALVALADE CLÍNICA",
      },
      {
        value: "CUF BELÉM CLÍNICA",
        label: "CUF BELÉM CLÍNICA",
      },
      {
        value: "CUF CASCAIS HOSPITAL",
        label: "CUF CASCAIS HOSPITAL",
      },
      {
        value: "CUF COIMBRA HOSPITAL",
        label: "CUF COIMBRA HOSPITAL",
      },
      {
        value: "CUF DESCOBERTAS HOSPITAL",
        label: "CUF DESCOBERTAS HOSPITAL",
      },
      {
        value: "CUF INFANTE SANTO HOSPITAL",
        label: "CUF INFANTE SANTO HOSPITAL",
      },
      {
        value: "CUF MAFRA CLÍNICA",
        label: "CUF MAFRA CLÍNICA",
      },
      {
        value: "CUF MIRAFLORES CLÍNICA",
        label: "CUF MIRAFLORES CLÍNICA",
      },
      {
        value: "CUF PORTO HOSPITAL",
        label: "CUF PORTO HOSPITAL",
      },
      {
        value: "CUF PORTO INSTITUTO",
        label: "CUF PORTO INSTITUTO",
      },
      {
        value: "CUF S. DOMINGOS RANA CLÍNICA",
        label: "CUF S. DOMINGOS RANA CLÍNICA",
      },
      {
        value: "CUF S. JOÃO DA MADEIRA CLÍNICA",
        label: "CUF S. JOÃO DA MADEIRA CLÍNICA",
      },
      {
        value: "CUF SANTARÉM HOSPITAL",
        label: "CUF SANTARÉM HOSPITAL",
      },
      {
        value: "CUF SINTRA CLÍNICA",
        label: "CUF SINTRA CLÍNICA",
      },
      { value: "CUF TEJO", label: "CUF TEJO" },
      {
        value: "CUF TORRES VEDRAS HOSPITAL",
        label: "CUF TORRES VEDRAS HOSPITAL",
      },
      {
        value: "CUF VISEU HOSPITAL",
        label: "CUF VISEU HOSPITAL",
      },
      {
        value: "FACULDADE DE MEDICINA DO PORTO",
        label: "FACULDADE DE MEDICINA DO PORTO",
      },
      {
        value: "FUNDAÇÃO CHAMPALIMAUD",
        label: "FUNDAÇÃO CHAMPALIMAUD",
      },
      {
        value: "GERMANO DE SOUSA - LABORATÓRIO DE ANÁLISES CLÍNICAS",
        label: "GERMANO DE SOUSA - LABORATÓRIO DE ANÁLISES CLÍNICAS",
      },
      {
        value: "HOSPITAIS PRIVADOS DE PORTUGAL - PORTO",
        label: "HOSPITAIS PRIVADOS DE PORTUGAL - PORTO",
      },
      {
        value: "HOSPITAL AGOSTINHO RIBEIRO",
        label: "HOSPITAL AGOSTINHO RIBEIRO",
      },
      {
        value: "Hospital Albert Einstein",
        label: "Hospital Albert Einstein",
      },
      {
        value: "HOSPITAL AMATO LUSITANO DE CASTELO BRANCO",
        label: "HOSPITAL AMATO LUSITANO DE CASTELO BRANCO",
      },
      {
        value: "HOSPITAL ARCEBISPO JOÃO CRISÓSTOMO-CANTANHEDE",
        label: "HOSPITAL ARCEBISPO JOÃO CRISÓSTOMO-CANTANHEDE",
      },
      {
        value: "HOSPITAL BARLAVENTO ALGARVIO, E.P.E.",
        label: "HOSPITAL BARLAVENTO ALGARVIO, E.P.E.",
      },
      {
        value: "HOSPITAL BEATRIZ ÂNGELO",
        label: "HOSPITAL BEATRIZ ÂNGELO",
      },
      {
        value: "HOSPITAL BERNARDINO LOPES DE OLIVEIRA - ALCOBAÇA",
        label: "HOSPITAL BERNARDINO LOPES DE OLIVEIRA - ALCOBAÇA",
      },
      {
        value: "HOSPITAL Cª SEG. MUNDIAL CONFIANÇA (PORTO)",
        label: "HOSPITAL Cª SEG. MUNDIAL CONFIANÇA (PORTO)",
      },
      {
        value: "HOSPITAL CÂNDIDO DE FIGUEIREDO -TONDELA",
        label: "HOSPITAL CÂNDIDO DE FIGUEIREDO -TONDELA",
      },
      {
        value: "HOSPITAL CENTRAL DO FUNCHAL",
        label: "HOSPITAL CENTRAL DO FUNCHAL",
      },
      {
        value: "HOSPITAL CRUZ VERMELHA PORTUGUESA",
        label: "HOSPITAL CRUZ VERMELHA PORTUGUESA",
      },
      {
        value: "HOSPITAL CURRY CABRAL",
        label: "HOSPITAL CURRY CABRAL",
      },
      {
        value: "HOSPITAL D. ESTEFÂNIA",
        label: "HOSPITAL D. ESTEFÂNIA",
      },
      {
        value: "HOSPITAL DA LIGA DE AMIGOS DOS HOSPITAIS - LISBOA",
        label: "HOSPITAL DA LIGA DE AMIGOS DOS HOSPITAIS - LISBOA",
      },
      {
        value: "HOSPITAL DA LUZ ARRÁBIDA",
        label: "HOSPITAL DA LUZ ARRÁBIDA",
      },
      {
        value: "HOSPITAL DA LUZ AVEIRO",
        label: "HOSPITAL DA LUZ AVEIRO",
      },
      {
        value: "HOSPITAL DA LUZ CLÍNICA DA AMADORA",
        label: "HOSPITAL DA LUZ CLÍNICA DA AMADORA",
      },
      {
        value: "HOSPITAL DA LUZ CLÍNICA DE ÁGUEDA",
        label: "HOSPITAL DA LUZ CLÍNICA DE ÁGUEDA",
      },
      {
        value: "HOSPITAL DA LUZ CLÍNICA DE AMARANTE",
        label: "HOSPITAL DA LUZ CLÍNICA DE AMARANTE",
      },
      {
        value: "HOSPITAL DA LUZ CLÍNICA DE CERVEIRA",
        label: "HOSPITAL DA LUZ CLÍNICA DE CERVEIRA",
      },
      {
        value: "HOSPITAL DA LUZ CLÍNICA DE ODIVELAS",
        label: "HOSPITAL DA LUZ CLÍNICA DE ODIVELAS",
      },
      {
        value: "HOSPITAL DA LUZ CLÍNICA DE OEIRAS",
        label: "HOSPITAL DA LUZ CLÍNICA DE OEIRAS",
      },
      {
        value: "HOSPITAL DA LUZ CLÍNICA DE OIÃ",
        label: "HOSPITAL DA LUZ CLÍNICA DE OIÃ",
      },
      {
        value: "HOSPITAL DA LUZ CLÍNICA DE VILA REAL",
        label: "HOSPITAL DA LUZ CLÍNICA DE VILA REAL",
      },
      {
        value: "HOSPITAL DA LUZ CLÍNICA DO PORTO",
        label: "HOSPITAL DA LUZ CLÍNICA DO PORTO",
      },
      {
        value: "HOSPITAL DA LUZ GUIMARÃES",
        label: "HOSPITAL DA LUZ GUIMARÃES",
      },
      {
        value: "HOSPITAL DA LUZ LISBOA",
        label: "HOSPITAL DA LUZ LISBOA",
      },
      {
        value: "HOSPITAL DA LUZ PÓVOA DE VARZIM",
        label: "HOSPITAL DA LUZ PÓVOA DE VARZIM",
      },
      {
        value: "HOSPITAL DA LUZ SETÚBAL",
        label: "HOSPITAL DA LUZ SETÚBAL",
      },
      {
        value: "HOSPITAL DA MARINHA",
        label: "HOSPITAL DA MARINHA",
      },
      {
        value: "HOSPITAL DA MISERICÓRDIA DE ÉVORA",
        label: "HOSPITAL DA MISERICÓRDIA DE ÉVORA",
      },
      {
        value: "HOSPITAL DA ORDEM TERCEIRA SÃO FRANCISCO DO PORTO",
        label: "HOSPITAL DA ORDEM TERCEIRA SÃO FRANCISCO DO PORTO",
      },
      {
        value: "HOSPITAL DA PRELADA",
        label: "HOSPITAL DA PRELADA",
      },
      {
        value: "HOSPITAL DA SANTA CASA DA MISERICORDIA DE PONTE DA BARCA",
        label: "HOSPITAL DA SANTA CASA DA MISERICORDIA DE PONTE DA BARCA",
      },
      {
        value: "HOSPITAL DA V. ORDEM TERCEIRA",
        label: "HOSPITAL DA V. ORDEM TERCEIRA",
      },
      {
        value: "HOSPITAL DAS FORÇAS ARMADAS/ FORÇA AÉREA",
        label: "HOSPITAL DAS FORÇAS ARMADAS/ FORÇA AÉREA",
      },
      {
        value: "HOSPITAL DE ALCANENA",
        label: "HOSPITAL DE ALCANENA",
      },
      {
        value: "HOSPITAL DE BRAGA",
        label: "HOSPITAL DE BRAGA",
      },
      {
        value: "HOSPITAL DE CASCAIS (PPP)",
        label: "HOSPITAL DE CASCAIS (PPP)",
      },
      {
        value: "HOSPITAL DE CASCAIS DR. JOSÉ DE ALMEIDA",
        label: "HOSPITAL DE CASCAIS DR. JOSÉ DE ALMEIDA",
      },
      {
        value: "HOSPITAL DE JESUS",
        label: "HOSPITAL DE JESUS",
      },
      {
        value: "HOSPITAL DE LOULÉ",
        label: "HOSPITAL DE LOULÉ",
      },
      {
        value: "HOSPITAL DE MAGALHÃES  LEMOS",
        label: "HOSPITAL DE MAGALHÃES  LEMOS",
      },
      {
        value: "HOSPITAL DE SANTA CRUZ",
        label: "HOSPITAL DE SANTA CRUZ",
      },
      {
        value: "Hospital de Santarém",
        label: "Hospital de Santarém",
      },
      {
        value: "HOSPITAL DE SÃO LUÍS - LISBOA",
        label: "HOSPITAL DE SÃO LUÍS - LISBOA",
      },
      {
        value: "HOSPITAL DISTRITAL DA FIGUEIRA DA FOZ, E.P.E.",
        label: "HOSPITAL DISTRITAL DA FIGUEIRA DA FOZ, E.P.E.",
      },
      {
        value: "HOSPITAL DISTRITAL DE ÁGUEDA",
        label: "HOSPITAL DISTRITAL DE ÁGUEDA",
      },
      {
        value: "HOSPITAL DISTRITAL DE CHAVES",
        label: "HOSPITAL DISTRITAL DE CHAVES",
      },
      {
        value: "HOSPITAL DISTRITAL DE LAMEGO",
        label: "HOSPITAL DISTRITAL DE LAMEGO",
      },
      {
        value: "HOSPITAL DISTRITAL DE S. JOÃO DA MADEIRA",
        label: "HOSPITAL DISTRITAL DE S. JOÃO DA MADEIRA",
      },
      {
        value: "HOSPITAL DISTRITAL DE SANTARÉM, E.P.E.",
        label: "HOSPITAL DISTRITAL DE SANTARÉM, E.P.E.",
      },
      {
        value: "HOSPITAL DISTRITAL DO FUNDÃO",
        label: "HOSPITAL DISTRITAL DO FUNDÃO",
      },
      {
        value: "HOSPITAL DISTRITAL DO POMBAL",
        label: "HOSPITAL DISTRITAL DO POMBAL",
      },
      {
        value: "HOSPITAL DIVINO ESPIRITO SANTO | PONTA DELGADA",
        label: "HOSPITAL DIVINO ESPIRITO SANTO | PONTA DELGADA",
      },
      {
        value: "HOSPITAL DO ENTRONCAMENTO",
        label: "HOSPITAL DO ENTRONCAMENTO",
      },
      {
        value: "HOSPITAL DO LITORAL ALENTEJANO",
        label: "HOSPITAL DO LITORAL ALENTEJANO",
      },
      {
        value: "HOSPITAL DOS SAMS - LISBOA",
        label: "HOSPITAL DOS SAMS - LISBOA",
      },
      {
        value: "HOSPITAL DOUTOR JOSÉ MARIA GRANDE - PORTALEGRE",
        label: "HOSPITAL DOUTOR JOSÉ MARIA GRANDE - PORTALEGRE",
      },
      {
        value: "HOSPITAL PROF. DOUTOR FERNANDO FONSECA, E.P.E",
        label: "Hospital Prof. Doutor Fernando Fonseca, E.P.E",
      },
      {
        value: "HOSPITAL DR. FRANCISCO ZAGALO-OVAR",
        label: "HOSPITAL DR. FRANCISCO ZAGALO-OVAR",
      },
      {
        value: "HOSPITAL DR. NÉLIO MENDONÇA - FUNCHAL",
        label: "HOSPITAL DR. NÉLIO MENDONÇA - FUNCHAL",
      },
      {
        value: "HOSPITAL ESPÍRITO SANTO-ÉVORA",
        label: "HOSPITAL ESPÍRITO SANTO-ÉVORA",
      },
      {
        value: "HOSPITAL GARCIA DE ORTA, E.P.E.",
        label: "HOSPITAL GARCIA DE ORTA, E.P.E.",
      },
      {
        value: "HOSPITAL GERAL COLÓNIA PORTUGUESA DO BRASIL - C.H.CCOIMBRA",
        label: "HOSPITAL GERAL COLÓNIA PORTUGUESA DO BRASIL - C.H.CCOIMBRA",
      },
      {
        value: "HOSPITAL INFANTE D. PEDRO, E.P.E.",
        label: "HOSPITAL INFANTE D. PEDRO, E.P.E.",
      },
      {
        value: "HOSPITAL INFANTIL SÃO JOÃO DE DEUS - MONTEMOR -O-NOVO",
        label: "HOSPITAL INFANTIL SÃO JOÃO DE DEUS - MONTEMOR -O-NOVO",
      },
      {
        value: "HOSPITAL INTERNACIONAL DOS AÇORES",
        label: "HOSPITAL INTERNACIONAL DOS AÇORES",
      },
      {
        value: "HOSPITAL JOAQUIM URBANO",
        label: "HOSPITAL JOAQUIM URBANO",
      },
      {
        value: "HOSPITAL JOSÉ LUCIANO DE CASTRO - ANADIA",
        label: "HOSPITAL JOSÉ LUCIANO DE CASTRO - ANADIA",
      },
      {
        value: "HOSPITAL LUSÍADAS ALBUFEIRA",
        label: "HOSPITAL LUSÍADAS ALBUFEIRA",
      },
      {
        value: "HOSPITAL LUSÍADAS AMADORA",
        label: "HOSPITAL LUSÍADAS AMADORA",
      },
      {
        value: "HOSPITAL LUSÍADAS LISBOA",
        label: "HOSPITAL LUSÍADAS LISBOA",
      },
      {
        value: "HOSPITAL LUSÍADAS PORTO",
        label: "HOSPITAL LUSÍADAS PORTO",
      },
      {
        value: "HOSPITAL MILITAR PRINCIPAL",
        label: "HOSPITAL MILITAR PRINCIPAL",
      },
      {
        value: "HOSPITAL MILITAR REGIONAL 4",
        label: "HOSPITAL MILITAR REGIONAL 4",
      },
      {
        value: "HOSPITAL MILITAR REGIONAL Nº1",
        label: "HOSPITAL MILITAR REGIONAL Nº1",
      },
      {
        value: "HOSPITAL MILITAR REGIONAL Nº2",
        label: "HOSPITAL MILITAR REGIONAL Nº2",
      },
      {
        value: "HOSPITAL NARCISO FERREIRA",
        label: "HOSPITAL NARCISO FERREIRA",
      },
      {
        value: "HOSPITAL NOSSA SENHORA DA AJUDA-ESPINHO",
        label: "HOSPITAL NOSSA SENHORA DA AJUDA-ESPINHO",
      },
      {
        value: "HOSPITAL NOSSA SENHORA DA ARRÁBIDA",
        label: "HOSPITAL NOSSA SENHORA DA ARRÁBIDA",
      },
      {
        value: "HOSPITAL NOSSA SENHORA DA ASSUNÇÃO - SEIA",
        label: "HOSPITAL NOSSA SENHORA DA ASSUNÇÃO - SEIA",
      },
      {
        value: "HOSPITAL NOSSA SENHORA DA CONCEIÇÃO DE VALONGO",
        label: "HOSPITAL NOSSA SENHORA DA CONCEIÇÃO DE VALONGO",
      },
      {
        value: "HOSPITAL NOSSA SENHORA DA GUIA - AVELAR",
        label: "HOSPITAL NOSSA SENHORA DA GUIA - AVELAR",
      },
      {
        value: "HOSPITAL NOSSA SENHORA DO ROSÁRIO, E.P.E.",
        label: "HOSPITAL NOSSA SENHORA DO ROSÁRIO, E.P.E.",
      },
      {
        value: "HOSPITAL ORDEM DA LAPA",
        label: "HOSPITAL ORDEM DA LAPA",
      },
      {
        value: "HOSPITAL ORDEM DO CARMO - PORTO",
        label: "HOSPITAL ORDEM DO CARMO - PORTO",
      },
      {
        value: "HOSPITAL ORDEM TERCEIRA DA SS TRINDADE PORTO",
        label: "HOSPITAL ORDEM TERCEIRA DA SS TRINDADE PORTO",
      },
      {
        value: "HOSPITAL ORTOPEDICO SANTANA",
        label: "HOSPITAL ORTOPEDICO SANTANA",
      },
      {
        value: "HOSPITAL PARTICULAR DA MADEIRA",
        label: "HOSPITAL PARTICULAR DA MADEIRA",
      },
      {
        value: "HOSPITAL PARTICULAR DE ALMADA",
        label: "HOSPITAL PARTICULAR DE ALMADA",
      },
      {
        value: "HOSPITAL PARTICULAR DE LISBOA",
        label: "HOSPITAL PARTICULAR DE LISBOA",
      },
      {
        value: "HOSPITAL PARTICULAR DO ALGARVE",
        label: "HOSPITAL PARTICULAR DO ALGARVE",
      },
      {
        value: "HOSPITAL PRISIONAL S. JOÃO DE DEUS",
        label: "HOSPITAL PRISIONAL S. JOÃO DE DEUS",
      },
      {
        value: "HOSPITAL PRIVADO DA BOAVISTA - HPP PORTO",
        label: "HOSPITAL PRIVADO DA BOAVISTA - HPP PORTO",
      },
      {
        value: "HOSPITAL PRIVADO DOS CLÉRIGOS - HPP - PORTO",
        label: "HOSPITAL PRIVADO DOS CLÉRIGOS - HPP - PORTO",
      },
      {
        value: "HOSPITAL PULIDO VALENTE, E.P.E.",
        label: "HOSPITAL PULIDO VALENTE, E.P.E.",
      },
      {
        value: "HOSPITAL REYNALDO DOS SANTOS-VILA FRANCA DE XIRA",
        label: "HOSPITAL REYNALDO DOS SANTOS-VILA FRANCA DE XIRA",
      },
      {
        value: "HOSPITAL S. PEDRO PESCADOR",
        label: "HOSPITAL S. PEDRO PESCADOR",
      },
      {
        value: "HOSPITAL SANTA CASA DA MISERICÓRIA DE BENAVENTE",
        label: "HOSPITAL SANTA CASA DA MISERICÓRIA DE BENAVENTE",
      },
      {
        value: "HOSPITAL SANTA CECÍLIA",
        label: "HOSPITAL SANTA CECÍLIA",
      },
      {
        value: "HOSPITAL SANTA LUZIA DE ELVAS",
        label: "HOSPITAL SANTA LUZIA DE ELVAS",
      },
      {
        value: "HOSPITAL SANTA MARIA",
        label: "HOSPITAL SANTA MARIA",
      },
      {
        value: "HOSPITAL SANTA MARIA (PORTO)",
        label: "HOSPITAL SANTA MARIA (PORTO)",
      },
      {
        value: "HOSPITAL SANTA MARIA MAIOR, E.P.E.",
        label: "HOSPITAL SANTA MARIA MAIOR, E.P.E.",
      },
      {
        value: "HOSPITAL SANTA MARTA, E.P.E.",
        label: "HOSPITAL SANTA MARTA, E.P.E.",
      },
      {
        value: "HOSPITAL SANTO ANTÓNIO E.P.E. - PORTO",
        label: "HOSPITAL SANTO ANTÓNIO E.P.E. - PORTO",
      },
      {
        value: "HOSPITAL SANTO ESPIRITO | ILHA TERCEIRA",
        label: "HOSPITAL SANTO ESPIRITO | ILHA TERCEIRA",
      },
      {
        value: "HOSPITAL SÃO MIGUEL - OLIVEIRA DE AZEMEIS",
        label: "HOSPITAL SÃO MIGUEL - OLIVEIRA DE AZEMEIS",
      },
      {
        value: "HOSPITAL SÃO PEDRO GONÇALVES TELMO-PENICHE",
        label: "HOSPITAL SÃO PEDRO GONÇALVES TELMO-PENICHE",
      },
      {
        value: "HOSPITAL SÃO SEBASTIÃO, E.P.E.",
        label: "HOSPITAL SÃO SEBASTIÃO, E.P.E.",
      },
      {
        value: "HOSPITAL SÃO TEOTÓNIO, E.P.E.",
        label: "HOSPITAL SÃO TEOTÓNIO, E.P.E.",
      },
      {
        value: "HOSPITAL SENHORA DA OLIVEIRA - GUIMARÃES",
        label: "HOSPITAL SENHORA DA OLIVEIRA - GUIMARÃES",
      },
      {
        value: "HOSPITAL SOUSA MARTINS - GUARDA",
        label: "HOSPITAL SOUSA MARTINS - GUARDA",
      },
      {
        value: "HOSPITAL VALENTIM RIBEIRO",
        label: "HOSPITAL VALENTIM RIBEIRO",
      },
      {
        value: "HOSPITAL VENERÁVEL IRMANDADE N.S. DA LAPA - PORTO",
        label: "HOSPITAL VENERÁVEL IRMANDADE N.S. DA LAPA - PORTO",
      },
      {
        value: "HOSPITAL VENERÁVEL IRMANDADE N.S. DO TERÇO DE CARIDADE - PORTO",
        label: "HOSPITAL VENERÁVEL IRMANDADE N.S. DO TERÇO DE CARIDADE - PORTO",
      },
      {
        value: "HOSPITAL VISCONDE DE SALREU-ESTARREJA",
        label: "HOSPITAL VISCONDE DE SALREU-ESTARREJA",
      },
      {
        value: "HOSPITAL VILA FRANCA DE XIRA",
        label: "HOSPITAL VILA FRANCA DE XIRA",
      },
      {
        value: "INSTITUTO CUF DE ONCOLOGIA - CLUSTER TEJO",
        label: "INSTITUTO CUF DE ONCOLOGIA - CLUSTER TEJO",
      },
      {
        value: "INSTITUTO PORTUGUÊS DE ONCOLOGIA COIMBRA",
        label: "INSTITUTO PORTUGUÊS DE ONCOLOGIA COIMBRA",
      },
      {
        value: "INSTITUTO PORTUGUÊS DE ONCOLOGIA LISBOA",
        label: "INSTITUTO PORTUGUÊS DE ONCOLOGIA LISBOA",
      },
      {
        value: "INSTITUTO PORTUGUÊS DE ONCOLOGIA PORTO",
        label: "INSTITUTO PORTUGUÊS DE ONCOLOGIA PORTO",
      },
      {
        value: "INSTITUTO PORTUGUÊS DE RITMO CARDÍACO",
        label: "INSTITUTO PORTUGUÊS DE RITMO CARDÍACO",
      },
      { value: "IPATIMUP", label: "IPATIMUP" },
      { value: "IPL / ESTeSL", label: "IPL / ESTeSL" },
      { value: "IMP DIAGNOSTICS", label: "IMP DIAGNOSTICS" },
      {
        value: "JOAQUIM CHAVES SAÚDE - CASA DE SAÚDE DE CARNAXIDE",
        label: "JOAQUIM CHAVES SAÚDE - CASA DE SAÚDE DE CARNAXIDE",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CENTRO ONCOLÓGICO DRA. NATÁLIA CHAVES",
        label: "JOAQUIM CHAVES SAÚDE - CENTRO ONCOLÓGICO DRA. NATÁLIA CHAVES",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLINICA DE CARCAVELOS",
        label: "JOAQUIM CHAVES SAÚDE - CLINICA DE CARCAVELOS",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE CASCAIS",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE CASCAIS",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE ENTRECAMPOS",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE ENTRECAMPOS",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE FARO",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE FARO",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE GASTROENTEROLOGIA DE FARO",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE GASTROENTEROLOGIA DE FARO",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE MIRAFLORES",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE MIRAFLORES",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE OEIRAS",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE OEIRAS",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE OLHÃO",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE OLHÃO",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIOLOGIA DE CASCAIS",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIOLOGIA DE CASCAIS",
      },
      {
        value:
          "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIOLOGIA DE VILA FRANCA DE XIRA",
        label:
          "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIOLOGIA DE VILA FRANCA DE XIRA",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DA MADEIRA",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DA MADEIRA",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DE SANTARÉM",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DE SANTARÉM",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DO ALGARVE",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DO ALGARVE",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DO PORTO",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DO PORTO",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE SINTRA",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE SINTRA",
      },
      {
        value:
          "JOAQUIM CHAVES SAÚDE - DR. JOAQUIM CHAVES, LABORATÓRIO DE ANÁLISE",
        label:
          "JOAQUIM CHAVES SAÚDE - DR. JOAQUIM CHAVES, LABORATÓRIO DE ANÁLISES CLÍNICAS",
      },
      {
        value:
          "JOAQUIM CHAVES SAÚDE - DR. JOAQUIM CHAVES, LABORATÓRIO DE ANÁLISE",
        label:
          "JOAQUIM CHAVES SAÚDE - DR. JOAQUIM CHAVES, LABORATÓRIO DE ANÁLISES CLÍNICAS ALGARVE",
      },
      {
        value:
          "JOAQUIM CHAVES SAÚDE - DR. JOAQUIM CHAVES, LABORATÓRIO DE ANATOMIA PATOLÓGICA",
        label:
          "JOAQUIM CHAVES SAÚDE - DR. JOAQUIM CHAVES, LABORATÓRIO DE ANATOMIA PATOLÓGICA",
      },
      {
        value: "JOSE MELLO SAÚDE",
        label: "JOSE MELLO SAÚDE",
      },
      {
        value: "JÚLIO TEIXEIRA, SA",
        label: "JÚLIO TEIXEIRA, SA",
      },
      { value: "LENICARE", label: "LENICARE" },
      {
        value: "LENITUDES MEDICAL CENTER",
        label: "LENITUDES MEDICAL CENTER",
      },
      {
        value: "LISBON UNITED KINGDOM HOSPITAL, SERVIÇOS DE SAÚDE, S.ª",
        label: "LISBON UNITED KINGDOM HOSPITAL, SERVIÇOS DE SAÚDE, S.ª",
      },
      {
        value: "MATERNIDADE DR. ALFREDO DA COSTA",
        label: "MATERNIDADE DR. ALFREDO DA COSTA",
      },
      {
        value: "MATERNIDADE DR. DANIEL DE MATOS",
        label: "MATERNIDADE DR. DANIEL DE MATOS",
      },
      { value: "NEPHROCARE", label: "NEPHROCARE" },
      {
        value: "OFFICIAL AGENCY",
        label: "OFFICIAL AGENCY",
      },
      { value: "Reino Unido", label: "Reino Unido" },
      {
        value: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE ALMEIRIM",
        label: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE ALMEIRIM",
      },
      {
        value: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE AVINTES",
        label: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE AVINTES",
      },
      {
        value: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE ESTREMOZ",
        label: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE ESTREMOZ",
      },
      {
        value: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE MORA",
        label: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE MORA",
      },
      {
        value: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS FAMALICÃO",
        label: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS FAMALICÃO",
      },
      {
        value: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS JUIZ DE FORA",
        label: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS JUIZ DE FORA",
      },
      {
        value: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS PORTIMÃO",
        label: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS PORTIMÃO",
      },
      {
        value: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS RAINHA DONA LEONOR",
        label: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS RAINHA DONA LEONOR",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR AGUDA",
        label: "UNIDADE DE SAÚDE FAMILIAR AGUDA",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR ALVES MARTINS",
        label: "UNIDADE DE SAÚDE FAMILIAR ALVES MARTINS",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR BRIOSA",
        label: "UNIDADE DE SAÚDE FAMILIAR BRIOSA",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR CELAS SAÚDE",
        label: "UNIDADE DE SAÚDE FAMILIAR CELAS SAÚDE",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR COVA DA PIEDADE",
        label: "UNIDADE DE SAÚDE FAMILIAR COVA DA PIEDADE",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR CSI SEIXAL",
        label: "UNIDADE DE SAÚDE FAMILIAR CSI SEIXAL",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR DE SANTIAGO-LEIRIA",
        label: "UNIDADE DE SAÚDE FAMILIAR DE SANTIAGO-LEIRIA",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR DE TORNADA",
        label: "UNIDADE DE SAÚDE FAMILIAR DE TORNADA",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR DE VALBOM",
        label: "UNIDADE DE SAÚDE FAMILIAR DE VALBOM",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR DE VALONGO",
        label: "UNIDADE DE SAÚDE FAMILIAR DE VALONGO",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR DO MAR",
        label: "UNIDADE DE SAÚDE FAMILIAR DO MAR",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR LAVRADIO",
        label: "UNIDADE DE SAÚDE FAMILIAR LAVRADIO",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR LUSITÂNIA",
        label: "UNIDADE DE SAÚDE FAMILIAR LUSITÂNIA",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR MACTAMÃ",
        label: "UNIDADE DE SAÚDE FAMILIAR MACTAMÃ",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR RAMADA",
        label: "UNIDADE DE SAÚDE FAMILIAR RAMADA",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR SANTO ANTÓNIO DA CHARNECA",
        label: "UNIDADE DE SAÚDE FAMILIAR SANTO ANTÓNIO DA CHARNECA",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR SÃO GONÇALO",
        label: "UNIDADE DE SAÚDE FAMILIAR SÃO GONÇALO",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR SENHORA DA LAPA",
        label: "UNIDADE DE SAÚDE FAMILIAR SENHORA DA LAPA",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR TERRAS AZURARA",
        label: "UNIDADE DE SAÚDE FAMILIAR TERRAS AZURARA",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR VALE DO VEZ",
        label: "UNIDADE DE SAÚDE FAMILIAR VALE DO VEZ",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR VILLA LONGA",
        label: "UNIDADE DE SAÚDE FAMILIAR VILLA LONGA",
      },
      {
        value: "UNIDADE DE SAÚDE PÚBLICA DE ÉVORA",
        label: "UNIDADE DE SAÚDE PÚBLICA DE ÉVORA",
      },
      {
        value: "UNIDADE LOCAL DE SAÚDE DA GUARDA, EPE",
        label: "UNIDADE LOCAL DE SAÚDE DA GUARDA, EPE",
      },
      {
        value: "UNIDADE LOCAL DE SAÚDE DE MATOSINHOS, S.A",
        label: "UNIDADE LOCAL DE SAÚDE DE MATOSINHOS, S.A",
      },
      {
        value: "UNIDADE LOCAL DE SAÚDE DO ALTO MINHO",
        label: "UNIDADE LOCAL DE SAÚDE DO ALTO MINHO",
      },
      {
        value: "UNIDADE LOCAL DE SAÚDE DO BAIXO ALENTEJO",
        label: "UNIDADE LOCAL DE SAÚDE DO BAIXO ALENTEJO",
      },
      {
        value: "UNIDADE LOCAL DE SAÚDE DO LITORAL ALENTEJANO",
        label: "UNIDADE LOCAL DE SAÚDE DO LITORAL ALENTEJANO",
      },
      {
        value: "UNIDADE LOCAL DE SAÚDE DO NORDESTE, E.P.E.",
        label: "UNIDADE LOCAL DE SAÚDE DO NORDESTE, E.P.E.",
      },
      {
        value: "UNIDADE LOCAL DE SAÚDE DO NORTE ALENTEJANO",
        label: "UNIDADE LOCAL DE SAÚDE DO NORTE ALENTEJANO",
      },
      {
        value: "Unilabs - LAP PORTO",
        label: "Unilabs - LAP Porto",
      },
      { value: "Synlab Porto", label: "Synlab Porto" },
      { value: "Outro", label: "Outro" },
    ];

    inst.map((i) => {
      i.label = setCapitalize(i.label);
    });

    return inst;
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const [user, setUser] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalUploadOpen, setIsModalUploadOpen] = useState(false);
  const [nomeCompleto, setNomeCompleto] = useState("");
  const [tipoSocio, setTipoSocio] = useState("");
  const [numeroSocio, setNumeroSocio] = useState("");
  const [nomeFaturacao, setNomeFaturacao] = useState("");
  const [nome, setNome] = useState("");
  const [apelido, setApelido] = useState("");
  const [instituicao, setInstituicao] = useState("");
  const [instituicao2, setInstituicao2] = useState("");
  const [grau, setGrau] = useState("");
  const [grau2, setGrau2] = useState("");
  const [area, setArea] = useState("");
  const [area2, setArea2] = useState("");
  const [area3, setArea3] = useState("");
  const [telefone, setTelefone] = useState("");
  const [resumoProfissional, setResumoProfissional] = useState("");
  const [socioTitular, setSocioTitular] = useState("");
  const [orcid, setOrcid] = useState("");
  const [paginaWeb1, setPaginaWeb1] = useState("");
  const [paginaWeb2, setPaginaWeb2] = useState("");
  const [cartaoCidadao, setCartaoCidadao] = useState("");
  const [numeroContribuinte, setNumeroContribuinte] = useState("");
  const [morada, setMorada] = useState("");
  const [codigoPostal, setCodigoPostal] = useState("");
  const [pais, setPais] = useState("");
  const [cidade, setCidade] = useState("");
  const [numeroSocioESP, setNumeroSocioESP] = useState("");
  const [numeroCelula, setNumeroCelula] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [avatar, setAvatar] = useState("");
  const [mostraApresentacaoPublico, setMostraApresentacaoPublico] =
    useState("");
  const [mostraApresentacaoSocios, setMostraApresentacaoSocios] = useState("");
  const [mostraDadosProfissionaisPublico, setMostraDadosProfissionaisPublico] =
    useState("");
  const [mostraDadosProfissionaisSocios, setMostraDadosProfissionaisSocios] =
    useState("");
  const [mostraDadosPublico, setMostraDadosPublico] = useState("");
  const [mostraDadosSocios, setMostraDadosSocios] = useState("");
  const [mostraResumoPublico, setMostraResumoPublico] = useState("");
  const [mostraResumoSocios, setMostraResumoSocios] = useState("");
  const [mostraLinksPublico, setMostraLinksPublico] = useState("");
  const [mostraLinksSocios, setMostraLinksSocios] = useState("");
  const [mostraSegundaOpiniao, setMostraSegundaOpiniao] = useState("");
  const [nifInstituicao, setNifInstituicao] = useState("");
  const ref = useRef(null);

  notification.config({
    placement: "bottomRight",
    bottom: 10,
    duration: 5,
    rtl: false,
  });

  useEffect(() => {
    fetch(
      `https://api.spap.pt/api/get-user/` +
        session_storage.all_data.user_session.id,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + session_storage.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar users");
        }
        return response.json();
      })
      .then((data) => {
        if (data.user.length === 0) {
          return;
        }

        let userData = data.user;
        setTipoSocio(userData.tipo_socio);
        setNomeCompleto(userData.nome_completo);
        setNumeroSocio(userData.numero_socio);
        setNomeFaturacao(userData.nome_faturacao);
        setNome(userData.name);
        setApelido(userData.apelido);
        setInstituicao(userData.instituicao);
        setInstituicao2(userData.instituicao_2);
        setGrau(userData.grau);
        setGrau2(userData.grau_2);
        setArea(userData.area_subespecializacao);
        setArea2(userData.area_subespecializacao_2);
        setArea3(userData.area_diferenciacao_3);
        setTelefone(userData.telefone);
        setResumoProfissional(userData.resumo_profissional);
        setSocioTitular(userData.socio_titular);
        setOrcid(userData.orcid);
        setPaginaWeb1(userData.pagina_web_1);
        setPaginaWeb2(userData.pagina_web_2);
        setCartaoCidadao(userData.cartao_cidadao);
        setNumeroContribuinte(userData.numero_contribuinte);
        setNifInstituicao(userData.nif_instituicao);
        setMorada(userData.morada);
        setCodigoPostal(userData.codigo_postal);
        setPais(userData.pais);
        setCidade(userData.cidade);
        setNumeroSocioESP(userData.numero_esp);
        setNumeroCelula(userData.numero_celula);
        setPassword("");
        setConfirmPassword("");
        if (userData?.data_nascimento != null) {
          let parsedDate = dayjs(userData?.data_nascimento, "DD/MM/YYYY", true);

          if (parsedDate.isValid()) {
            setDataNascimento(userData?.data_nascimento);
          } else {
            setDataNascimento(
              dayjs(userData?.data_nascimento).format("DD/MM/YYYY")
            );
          }
        } else {
          setDataNascimento(null);
        }

        setAvatar(() => {
          if (userData.avatar != null) {
            return "https://api.spap.pt/avatars/" + userData.avatar;
          } else {
            return "https://api.spap.pt/avatars/default-avatar.png";
          }
        });

        console.log(userData);
        setMostraApresentacaoPublico(userData.mostra_apresentacao_publico);
        setMostraApresentacaoSocios(userData.mostra_apresentacao_socios);
        setMostraDadosProfissionaisPublico(
          userData.mostra_dados_profissionais_publico
        );
        setMostraDadosProfissionaisSocios(
          userData.mostra_dados_profissionais_socios
        );
        setMostraDadosPublico(userData.mostra_dados_publico);
        setMostraDadosSocios(userData.mostra_dados_socios);
        setMostraResumoPublico(userData.mostra_resumo_publico);
        setMostraResumoSocios(userData.mostra_resumo_socios);
        setMostraLinksPublico(userData.mostra_resumo_publico);
        setMostraLinksSocios(userData.mostra_resumo_socios);
        setMostraSegundaOpiniao(userData.segunda_opiniao);
      })
      .catch((error) => console.error(error));
  }, []);

  const onChangeData = (date, dateString) => {
    setDataNascimento(dateString);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (confirmPassword != password) {
      setHasError(true);
      setErrorMessage(
        "Para alterar a password, é necessário ambos os campos coincidirem."
      );
      setTimeout(() => {
        setHasError(false);
      }, 3000);
      return;
    }
    if (
      password != "" &&
      confirmPassword != "" &&
      (password.length < 8 || confirmPassword.length < 8)
    ) {
      setHasError(true);
      setErrorMessage("A password deve ter pelo menos 8 caracteres.");
      setTimeout(() => {
        setHasError(false);
      }, 3000);
      return;
    }

    event.preventDefault();

    let data_to_send = {
      name: nome,
      email: session_storage.all_data.user_session.email,
      numero_socio: numeroSocio,
      tipo_socio: tipoSocio,
      instituicao: instituicao,
      instituicao_2: instituicao2,
      grau: grau,
      grau_2: grau2,
      area_subespecializacao: area,
      area_subespecializacao_2: area2,
      area_diferenciacao_3: area3,
      telefone: telefone,
      resumo_profissional: resumoProfissional,
      socio_titular: socioTitular,
      orcid: orcid,
      pagina_web_1: paginaWeb1,
      pagina_web_2: paginaWeb2,
      cartao_cidadao: cartaoCidadao,
      numero_contribuinte: numeroContribuinte,
      morada: morada,
      codigo_postal: codigoPostal,
      pais: pais,
      cidade: cidade,
      mostra_apresentacao_publico: mostraApresentacaoPublico,
      mostra_apresentacao_socios: mostraApresentacaoSocios,
      mostra_dados_profissionais_publico: mostraDadosProfissionaisPublico,
      mostra_dados_profissionais_socios: mostraDadosProfissionaisSocios,
      mostra_dados_publico: mostraDadosPublico,
      mostra_dados_socios: mostraDadosSocios,
      mostra_resumo_publico: mostraResumoPublico,
      mostra_resumo_socios: mostraResumoSocios,
      mostra_links_publico: mostraLinksPublico,
      mostra_links_socios: mostraLinksSocios,
      segunda_opiniao: mostraSegundaOpiniao,
      nif_instituicao: nifInstituicao,
      password: password,
      nome_completo: nomeCompleto,
      nome_faturacao: nomeFaturacao,
      numero_esp: numeroSocioESP,
      numero_celula: numeroCelula,
      data_nascimento: dataNascimento,
    };
    let user_id = session_storage.all_data.user_session.id;
    let post_data = JSON.stringify(data_to_send);

    //let response = UserService.updateProfile(session_storage.token.accessToken, session_storage.all_data.user_session.id, JSON.stringify(data_to_send));
    axios
      .post(
        "https://api.spap.pt/api/update-profile",
        {
          user_id,
          post_data,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          session_storage.all_data.user_session.name = nome;
          setIsModalOpen(true);
          setHasError(false);
          setPassword("");
          setConfirmPassword("");
          setTimeout(() => {
            setIsModalOpen(false);
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          notification.error({ description: "Erro ao atualizar o perfil" });
        }
      });
  };

  const prop = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const SidebarMenuLinks = [
    {
      title: "Área pessoal",
      link: "/socios/area-de-socio",
    },
    {
      title: "Dados de perfil",
      link: "/socios/dados-de-perfil/",
    },
    {
      title: "Quotas",
      link: "/socios/quotas",
    },
    {
      title: "Pagamentos",
      link: "/socios/pagamentos",
    },
    {
      title: "Meus Eventos",
      link: "/socios/meus-eventos",
    },
    {
      title: "Mensagens",
      link: "/socios/mensagens",
    },
  ];
  const handleUpload = () => {
    const formData = new FormData();

    formData.append("file", fileList[0]);

    setUploading(true);
    // You can use any AJAX library you like
    fetch("https://api.spap.pt/api/admin/upload-avatar", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            session_storage.all_data.user_session.avatar = data.url;
            localStorage.setItem(
              "session_data",
              JSON.stringify(session_storage)
            );
            setAvatar("https://api.spap.pt/avatars/" + data.url);
          })
          .catch((err) => {});
      })
      .then(() => {
        setFileList([]);
        message.success("upload successfully.");
      })
      .catch(() => {
        message.error("upload failed.");
      })
      .finally(() => {
        setUploading(false);
      });
  };

  return (
    <Wrapper>
      <Container>
        <Row gutter={[24, 24]}>
          <Col xl={19} xs={24}>
            <PageTitle
              title="Dados de Perfil"
              path={[
                {
                  text: "Homepage",
                  link: "/",
                },
                {
                  text: "Sócios",
                  link: "/socios",
                },
                {
                  text: "Área de sócio",
                  link: "/socios/area-de-socio",
                },
                {
                  text: "Dados de Perfil",
                },
              ]}
              imageDesktop={require("../../../Assets/Images/img-dados-de-perfil@2x.png")}
              imageMobile={require("../../../Assets/Images/img-dados-de-perfil@2x.png")}
              imageAlt="Sócios - Dados de Perfil"
            />

            <FlexInRow gap="0">
              <LoginMessage style={{ marginTop: "10px" }}>
                Personalize o seu perfil para que outros sócios SPAP possam
                entrar em contacto consigo
              </LoginMessage>
            </FlexInRow>

            <SectionHighlightsCards>
              <Grid gap="50px">
                <form onSubmit={handleSubmit}>
                  <FlexInRow gap="1.3vw">
                    <Row>
                      <span style={{ textAlign: "left" }}>
                        Poderá personalizar os campos do seu perfil, bem com as
                        definições de visualização de cada área. Por definição o
                        seu perfil irá surgir na listagem de sócios
                      </span>
                    </Row>
                    <Row
                      style={{
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                      align={"middle"}
                    >
                      <Col
                        xs={8}
                        xl={3}
                        style={{ textAlign: "left" }}
                        className="mt-10-mobile"
                      >
                        <div
                          style={{
                            borderRadius: "80px",
                            border: "1px solid #494D7D",
                            aspectRatio: "1/1",
                            width: "100%",
                            height: "auto",
                            float: "right",
                          }}
                          onClick={() => setIsModalUploadOpen(true)}
                        >
                          <img
                            src={avatar}
                            style={{
                              borderRadius: "100px",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      </Col>
                      <Col xs={16} xl={7} style={{ paddingLeft: "10px" }}>
                        <h6
                          style={{
                            color: "rgb(12, 17, 81)",
                            paddingLeft: "7px",
                            marginBottom: "0",
                          }}
                        >
                          Fotografia de perfil
                        </h6>
                        <Row
                          style={{
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <Col>
                            <LogoPhoto className="logo" />
                          </Col>
                          <Col>
                            <Button
                              style={{
                                color: "#494D7D",
                                textDecoration: "underline",
                                border: "none",
                                boxShadow: "none",
                                padding: "0",
                                fontSize: "12px",
                              }}
                              onClick={() => setIsModalUploadOpen(true)}
                            >
                              Upload de nova imagem
                            </Button>
                          </Col>
                          {/* <Upload {...props} style={{ display: 'block' }}> */}

                          {/* </Upload> */}
                        </Row>
                      </Col>
                      <Col
                        style={{ marginRight: "15px" }}
                        className="mt-10-mobile"
                      >
                        <h6 style={styles.h6_label}>Tipo de sócio</h6>
                        <Input
                          disabled
                          className="input-socio pointer-none"
                          style={{
                            color: "rgb(12, 17, 81)",
                            padding: "0px 5px",
                            height: "45px",
                            fontWeight: "bold",
                            textAlign: "center",
                            fontSize: "16px",
                            width: "100px",
                            border: "3px solid rgb(12, 17, 81) !important",
                          }}
                          value={tipoSocio}
                        />
                      </Col>
                      <Col
                        style={{ marginLeft: "15px" }}
                        className="numero-socio-input mt-10-mobile"
                      >
                        <h6 style={styles.h6_label}>Nº. de Sócio</h6>
                        <Row>
                          <Input
                            disabled
                            className="input-socio pointer-none"
                            style={{
                              color: "rgb(12, 17, 81)",
                              padding: "0px 5px",
                              height: "45px",
                              fontWeight: "bold",
                              textAlign: "center",
                              fontSize: "16px",
                              width: "100px",
                              border: "3px solid rgb(12, 17, 81) !important",
                            }}
                            value={numeroSocio}
                          />
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <span style={{ paddingRight: "15px" }}>
                        Caso pretenda surgir também como patologista para{" "}
                        <u style={{ color: "#0C1151", fontWeight: "bold" }}>
                          pedido de 2ª opinião
                        </u>
                        , selecione a seguinte opção:
                      </span>
                      <Switch
                        name="segundaOpiniao"
                        checked={mostraSegundaOpiniao == 1 ? true : false}
                        onChange={(e) => setMostraSegundaOpiniao(e)}
                      />
                    </Row>

                    <Row gutter={[8, 8]} align={"middle"}>
                      <Col span={12}>
                        <span
                          style={{
                            paddingRight: "15px",
                            color: "#809FB8",
                            fontWeight: "bold",
                          }}
                        >
                          Primeiro nome
                        </span>
                        <Input
                          name="nome"
                          placeholder="Primeiro nome"
                          style={styles.input_class}
                          value={nome}
                          onChange={(e) => setNome(e.target.value)}
                        />
                      </Col>
                      <Col span={12}>
                        <span
                          style={{
                            paddingRight: "15px",
                            color: "#809FB8",
                            fontWeight: "bold",
                          }}
                        >
                          Apelido
                        </span>
                        <Input
                          name="apelido"
                          placeholder="Apelido"
                          style={styles.input_class}
                          value={apelido}
                          onChange={(e) => setApelido(e.target.value)}
                        />
                      </Col>
                    </Row>
                    <Divider
                      orientation="left"
                      orientationMargin="0"
                      style={{ margin: "0" }}
                    >
                      <h5 style={styles.h5_label}>Apresentação</h5>
                    </Divider>
                    <Row>
                      <Col span={24} style={{ textAlign: "left" }}>
                        <span
                          style={{
                            paddingRight: "15px",
                            color: "#809FB8",
                            fontWeight: "bold",
                          }}
                        >
                          Esta secção estará visível para:
                        </span>{" "}
                        <Switch
                          checked={!!mostraApresentacaoSocios}
                          onChange={(e) => setMostraApresentacaoSocios(e)}
                        />{" "}
                        <span style={{ color: "#494D7D" }}></span>Sócios SPAP{" "}
                        <Switch
                          style={{ marginLeft: "10px" }}
                          checked={!!mostraApresentacaoPublico}
                          onChange={(e) => setMostraApresentacaoPublico(e)}
                        />{" "}
                        <span style={{ color: "#494D7D" }}></span>Público geral
                      </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={12}>
                        <span
                          style={{
                            paddingRight: "15px",
                            color: "#809FB8",
                            fontWeight: "bold",
                          }}
                        >
                          Selecione a sua instituição
                        </span>
                        <Select
                          id="select-instituicao"
                          value={instituicao ? instituicao : ""}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          showSearch
                          // optionFilterProp="children"
                          style={styles.select_class}
                          placeholder="Selecione a sua instituição"
                          onChange={(e) => setInstituicao(e)}
                          options={instituicaoOptions}
                        />
                      </Col>
                      <Col span={12}>
                        <span
                          style={{
                            paddingRight: "15px",
                            color: "#809FB8",
                            fontWeight: "bold",
                          }}
                        >
                          Outra instituição
                        </span>
                        <Input
                          key={"instituicao2"}
                          name="instituicao2"
                          placeholder="Outra instituição"
                          style={styles.input_class2}
                          value={instituicao2}
                          onChange={(e) => setInstituicao2(e.target.value)}
                        />
                      </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={8}>
                        <span
                          style={{
                            paddingRight: "15px",
                            color: "#809FB8",
                            fontWeight: "bold",
                          }}
                        >
                          Área de Diferenciação
                        </span>
                        <Select
                          value={area ? area : ""}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          showSearch
                          style={styles.select_class}
                          placeholder="Área de Diferenciação"
                          onChange={(e) => setArea(e)}
                          options={[
                            {
                              value: "Patologia geral",
                              label: "Patologia geral",
                            },
                            { value: "Citologia", label: "Citologia" },
                            {
                              value: "Patologia cutânea",
                              label: "Patologia cutânea",
                            },
                            {
                              value: "Patologia da cabeça e pescoço",
                              label: "Patologia da cabeça e pescoço",
                            },
                            {
                              value: "Patologia das partes moles",
                              label: "Patologia das partes moles",
                            },
                            {
                              value: "Patologia do sistema nervosa",
                              label: "Patologia do sistema nervoso",
                            },
                            {
                              value: "Patologia digital",
                              label: "Patologia digital",
                            },
                            {
                              value: "Patologia endócrina",
                              label: "Patologia endócrina",
                            },
                            {
                              value: "Patologia fetoplacentar",
                              label: "Patologia fetoplacentar",
                            },
                            {
                              value: "Patologia gastrointestinal",
                              label: "Patologia gastrointestinal",
                            },
                            {
                              value: "Patologia ginecológica",
                              label: "Patologia ginecológica",
                            },
                            {
                              value: "Patologia hematolinfoide",
                              label: "Patologia hematolinfoide",
                            },
                            {
                              value: "Patologia hepatobiliar",
                              label: "Patologia hepatobiliar",
                            },
                            {
                              value: "Patologia mamária",
                              label: "Patologia mamária",
                            },
                            {
                              value: "Patologia molecular",
                              label: "Patologia molecular",
                            },
                            {
                              value: "Patologia osteoarticular",
                              label: "Patologia osteoarticular",
                            },
                            {
                              value: "Patologia pancreática",
                              label: "Patologia pancreática",
                            },
                            {
                              value: "Patologia pulmonar",
                              label: "Patologia pulmonar",
                            },
                            {
                              value: "Patologia urogenital",
                              label: "Patologia urogenital",
                            },
                          ]}
                        />
                      </Col>
                      <Col span={8}>
                        <span
                          style={{
                            paddingRight: "15px",
                            color: "#809FB8",
                            fontWeight: "bold",
                          }}
                        >
                          Outra área de diferenciação
                        </span>
                        <Select
                          value={area2 ? area2 : ""}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          showSearch
                          style={styles.select_class}
                          placeholder="Outra área de diferenciação"
                          onChange={(e) => setArea2(e)}
                          options={[
                            {
                              value: "Patologia geral",
                              label: "Patologia geral",
                            },
                            { value: "Citologia", label: "Citologia" },
                            {
                              value: "Patologia cutânea",
                              label: "Patologia cutânea",
                            },
                            {
                              value: "Patologia da cabeça e pescoço",
                              label: "Patologia da cabeça e pescoço",
                            },
                            {
                              value: "Patologia das partes moles",
                              label: "Patologia das partes moles",
                            },
                            {
                              value: "Patologia do sistema nervosa",
                              label: "Patologia do sistema nervoso",
                            },
                            {
                              value: "Patologia digital",
                              label: "Patologia digital",
                            },
                            {
                              value: "Patologia endócrina",
                              label: "Patologia endócrina",
                            },
                            {
                              value: "Patologia fetoplacentar",
                              label: "Patologia fetoplacentar",
                            },
                            {
                              value: "Patologia gastrointestinal",
                              label: "Patologia gastrointestinal",
                            },
                            {
                              value: "Patologia ginecológica",
                              label: "Patologia ginecológica",
                            },
                            {
                              value: "Patologia hematolinfoide",
                              label: "Patologia hematolinfoide",
                            },
                            {
                              value: "Patologia hepatobiliar",
                              label: "Patologia hepatobiliar",
                            },
                            {
                              value: "Patologia mamária",
                              label: "Patologia mamária",
                            },
                            {
                              value: "Patologia molecular",
                              label: "Patologia molecular",
                            },
                            {
                              value: "Patologia osteoarticular",
                              label: "Patologia osteoarticular",
                            },
                            {
                              value: "Patologia pancreática",
                              label: "Patologia pancreática",
                            },
                            {
                              value: "Patologia pulmonar",
                              label: "Patologia pulmonar",
                            },
                            {
                              value: "Patologia urogenital",
                              label: "Patologia urogenital",
                            },
                          ]}
                        />
                      </Col>
                      <Col span={8}>
                        <span
                          style={{
                            paddingRight: "15px",
                            color: "#809FB8",
                            fontWeight: "bold",
                          }}
                        >
                          3ª área de diferenciação
                        </span>
                        <Input
                          key={"area3"}
                          name="area3"
                          placeholder="3ª área de diferenciação"
                          style={styles.input_class}
                          value={area3}
                          onChange={(e) => setArea3(e.target.value)}
                        />
                      </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={12}>
                        <span
                          style={{
                            paddingRight: "15px",
                            color: "#809FB8",
                            fontWeight: "bold",
                          }}
                        >
                          Grau
                        </span>
                        <Select
                          value={grau ? grau : ""}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          showSearch
                          style={styles.select_class}
                          placeholder="Grau"
                          onChange={(e) => setGrau(e)}
                          options={[
                            { value: "Especialista", label: "Especialista" },
                            { value: "Interno", label: "Interno" },
                          ]}
                        />
                      </Col>
                      <Col span={12}>
                        <span
                          style={{
                            paddingRight: "15px",
                            color: "#809FB8",
                            fontWeight: "bold",
                          }}
                        >
                          Outro Grau
                        </span>
                        <Input
                          key={"grau2"}
                          name="grau2"
                          placeholder="Outro Grau"
                          style={styles.input_class}
                          value={grau2}
                          onChange={(e) => setGrau2(e.target.value)}
                        />
                      </Col>
                    </Row>
                    <Divider
                      orientation="left"
                      orientationMargin="0"
                      style={{ margin: "0" }}
                    >
                      <h5 style={styles.h5_label}>Dados de Contacto</h5>
                    </Divider>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Col span={24} style={{ textAlign: "left" }}>
                        <span
                          style={{
                            paddingRight: "15px",
                            color: "#809FB8",
                            fontWeight: "bold",
                          }}
                        >
                          Esta secção estará visível para:
                        </span>{" "}
                        <Switch
                          checked={!!mostraDadosSocios}
                          onChange={(e) => setMostraDadosSocios(e)}
                        />{" "}
                        <span style={{ color: "#494D7D" }}></span>Email{" "}
                        <Switch
                          style={{ marginLeft: "10px" }}
                          checked={!!mostraDadosPublico}
                          onChange={(e) => setMostraDadosPublico(e)}
                        />{" "}
                        <span style={{ color: "#494D7D" }}></span>Telefone
                      </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={12}>
                        <span
                          style={{
                            paddingRight: "15px",
                            color: "#809FB8",
                            fontWeight: "bold",
                          }}
                        >
                          Email
                        </span>
                        <Input
                          name="email"
                          placeholder="Email"
                          style={styles.input_class}
                          value={session_storage.all_data.user_session.email}
                        />
                      </Col>
                      <Col span={12}>
                        <span
                          style={{
                            paddingRight: "15px",
                            color: "#809FB8",
                            fontWeight: "bold",
                          }}
                        >
                          Telefone
                        </span>
                        <Input
                          name="telefone"
                          placeholder="Telefone"
                          style={styles.input_class}
                          value={telefone}
                          onChange={(e) => setTelefone(e.target.value)}
                        />
                      </Col>
                    </Row>
                    <Divider
                      orientation="left"
                      orientationMargin="0"
                      style={{ margin: "0" }}
                    >
                      <h5 style={styles.h5_label}>Resumo Profissional</h5>
                    </Divider>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Col span={24} style={{ textAlign: "left" }}>
                        <span
                          style={{
                            paddingRight: "15px",
                            color: "#809FB8",
                            fontWeight: "bold",
                          }}
                        >
                          Esta secção estará visível para:
                        </span>{" "}
                        <Switch
                          checked={!!mostraResumoSocios}
                          onChange={(e) => setMostraResumoSocios(e)}
                        />{" "}
                        <span style={{ color: "#494D7D" }}></span>Sócios SPAP{" "}
                        <Switch
                          style={{ marginLeft: "10px" }}
                          checked={!!mostraResumoPublico}
                          onChange={(e) => setMostraResumoPublico(e)}
                        />{" "}
                        <span style={{ color: "#494D7D" }}></span>Público geral
                      </Col>
                    </Row>
                    <Row style={{ padding: "1px 0" }}>
                      <TextArea
                        style={{
                          color: "#809FB8",
                          fontStyle: "italic",
                          border: "1px solid #707070",
                          borderRadius: "10px",
                        }}
                        placeholder="Escreva aqui a sua mensagem..."
                        autoSize={{ minRows: 3, maxRows: 5 }}
                        value={resumoProfissional}
                        onChange={(e) => setResumoProfissional(e.target.value)}
                      />
                    </Row>
                    <Row style={{ padding: "1px 0" }}>
                      <Col span={24}>
                        <span
                          style={{
                            paddingRight: "15px",
                            color: "#809FB8",
                            fontWeight: "bold",
                          }}
                        >
                          ORCID
                        </span>
                        <Input
                          name="orcid"
                          placeholder="ORCID"
                          style={styles.input_class}
                          value={orcid}
                          onChange={(e) => setOrcid(e.target.value)}
                        />
                      </Col>
                    </Row>
                    {session_storage.all_data.user_session.tipo_utilizador !=
                      "nao_socio" && (
                      <>
                        <Divider
                          orientation="left"
                          orientationMargin="0"
                          style={{ margin: "0" }}
                        >
                          <h5 style={styles.h5_label}>
                            Sócios titulares proponentes
                          </h5>
                        </Divider>
                        {/* <Row style={{ display: 'flex', justifyContent: 'space-between'}}>
                  <Col span={24} style={{textAlign: 'left'}}> */}
                        <Col span={24} style={{ textAlign: "left" }}>
                          <span
                            style={{
                              paddingRight: "15px",
                              color: "#809FB8",
                              fontWeight: "bold",
                            }}
                          >
                            Esta secção estará visível para:
                          </span>{" "}
                          <Switch
                            key="mostraDadosProfissionaisSocios"
                            checked={!!mostraDadosProfissionaisSocios}
                            onChange={(e) =>
                              setMostraDadosProfissionaisSocios(e)
                            }
                          />{" "}
                          <span style={{ color: "#494D7D" }}></span>Sócios SPAP{" "}
                          <Switch
                            key="mostraDadosProfissionaisSocios"
                            style={{ marginLeft: "10px" }}
                            checked={!!mostraDadosProfissionaisPublico}
                            onChange={(e) =>
                              setMostraDadosProfissionaisPublico(e)
                            }
                          />{" "}
                          <span style={{ color: "#494D7D" }}></span>Público
                          geral
                        </Col>
                        {/* 
                  </Col>
                </Row> */}

                        <Row id="password" gutter={[8, 8]}>
                          <Col span={24}>
                            <span
                              style={{
                                paddingRight: "15px",
                                color: "#809FB8",
                                fontWeight: "bold",
                              }}
                            >
                              Sócio titular proponente
                            </span>
                            <Input
                              name="socio_titular"
                              placeholder="Sócio titular proponente"
                              style={styles.input_class}
                              value={socioTitular}
                              onChange={(e) => setSocioTitular(e.target.value)}
                            />
                          </Col>
                        </Row>
                        <Divider
                          orientation="left"
                          orientationMargin="0"
                          style={{ margin: "0" }}
                        >
                          <h5 style={styles.h5_label}>
                            Links de páginas web próprias
                          </h5>
                        </Divider>
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Col span={24} style={{ textAlign: "left" }}>
                            <span
                              style={{
                                paddingRight: "15px",
                                color: "#809FB8",
                                fontWeight: "bold",
                              }}
                            >
                              Esta secção estará visível para:
                            </span>{" "}
                            <Switch
                              key="mostraLinksSocios"
                              checked={!!mostraLinksSocios}
                              onChange={(e) => setMostraLinksSocios(e)}
                            />{" "}
                            <span style={{ color: "#494D7D" }}></span>Sócios
                            SPAP{" "}
                            <Switch
                              key="mostraLinksPublico"
                              checked={!!mostraLinksPublico}
                              onChange={(e) => setMostraLinksPublico(e)}
                              style={{ marginLeft: "10px" }}
                            />{" "}
                            <span style={{ color: "#494D7D" }}></span>Público
                            geral
                          </Col>
                        </Row>
                        <Row gutter={[8, 8]}>
                          <Col span={12}>
                            <span
                              style={{
                                paddingRight: "15px",
                                color: "#809FB8",
                                fontWeight: "bold",
                              }}
                            >
                              Página web #1
                            </span>
                            <Input
                              name="pagina_web_1"
                              placeholder="Página web #1"
                              style={styles.input_class}
                              value={paginaWeb1}
                              onChange={(e) => setPaginaWeb1(e.target.value)}
                            />
                          </Col>
                          <Col span={12}>
                            <span
                              style={{
                                paddingRight: "15px",
                                color: "#809FB8",
                                fontWeight: "bold",
                              }}
                            >
                              Página web #2
                            </span>
                            <Input
                              name="pagina_web_2"
                              placeholder="Página web #2"
                              style={styles.input_class}
                              value={paginaWeb2}
                              onChange={(e) => setPaginaWeb2(e.target.value)}
                            />
                          </Col>
                          {/* <Col span={6} offset={1}>
                    <h6 style={styles.h6_label}>Página web #3</h6>
                    <Input placeholder="Página web #3" style={styles.input_class } value={orcid} onChange={(e) => setOrcid(e.target.value)}/>
                  </Col> */}
                        </Row>
                        <Divider
                          orientation="left"
                          orientationMargin="0"
                          style={{ margin: "0" }}
                        >
                          <h5 style={styles.h5_label}>Dados Administrativos</h5>
                        </Divider>
                        <p style={{ marginBottom: "0" }}>
                          Esta secção é privada, e apenas a administração SPAP
                          tem acesso a ver os seus dados
                        </p>
                        <Row gutter={[8, 8]}>
                          <Col span={12}>
                            <span
                              style={{
                                paddingRight: "15px",
                                color: "#809FB8",
                                fontWeight: "bold",
                              }}
                            >
                              Nome/Entidade de faturação
                            </span>
                            <Input
                              name="entidade_faturacao"
                              placeholder="Nome/Entidade de faturação"
                              style={styles.input_class}
                              value={nomeFaturacao}
                              onChange={(e) => setNomeFaturacao(e.target.value)}
                            />
                          </Col>
                          <Col span={12}>
                            <span
                              style={{
                                paddingRight: "15px",
                                color: "#809FB8",
                                fontWeight: "bold",
                              }}
                            >
                              NIF de faturação
                            </span>
                            <Input
                              name="nif_faturacao"
                              placeholder="NIF de faturação"
                              style={styles.input_class}
                              value={nifInstituicao}
                              onChange={(e) =>
                                setNifInstituicao(e.target.value)
                              }
                            />
                          </Col>
                        </Row>
                        <Row gutter={[8, 8]}>
                          <Col span={12}>
                            <span
                              style={{
                                paddingRight: "15px",
                                color: "#809FB8",
                                fontWeight: "bold",
                              }}
                            >
                              Nº. Célula Profissional
                            </span>
                            <Input
                              name="numero_celula"
                              placeholder="Nº. Célula Profissional"
                              style={styles.input_class}
                              value={numeroCelula}
                              onChange={(e) => setNumeroCelula(e.target.value)}
                            />
                          </Col>
                          <Col span={12}>
                            <span
                              style={{
                                paddingRight: "15px",
                                color: "#809FB8",
                                fontWeight: "bold",
                              }}
                            >
                              Nº. de Sócio ESP
                            </span>
                            <Input
                              name="numero_socio_esp"
                              placeholder="Nº. de Sócio ESP"
                              style={styles.input_class}
                              value={numeroSocioESP}
                              onChange={(e) =>
                                setNumeroSocioESP(e.target.value)
                              }
                            />
                          </Col>
                        </Row>
                        <Row gutter={[8, 8]}>
                          <Col span={12}>
                            <span
                              style={{
                                paddingRight: "15px",
                                color: "#809FB8",
                                fontWeight: "bold",
                              }}
                            >
                              Cartão de cidadão
                            </span>
                            <Input
                              name="cartao_cidadao"
                              placeholder="Cartão de cidadão"
                              style={styles.input_class}
                              value={cartaoCidadao}
                              onChange={(e) => setCartaoCidadao(e.target.value)}
                            />
                          </Col>
                          <Col span={12}>
                            {/*}
                    <Input
                      ref={ref}
                      name="data_nascimento"
                      placeholder="Data de nascimento"
                      type="text"
                      onChange={(e) => setDataNascimento(e.target.value)}
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) => (e.target.value != '' ? e.target.type = "date" : e.target.type = "text")}
                      style={styles.input_class } defaultValue={dataNascimento ? dataNascimento : ''} />    
                      {*/}
                            <span
                              style={{
                                paddingRight: "15px",
                                color: "#809FB8",
                                fontWeight: "bold",
                              }}
                            >
                              Data de nascimento
                            </span>
                            <DatePicker
                              onChange={onChangeData}
                              format="DD/MM/YYYY"
                              placeholder="Data de nascimento"
                              style={styles.input_class}
                              value={
                                dataNascimento
                                  ? dayjs(dataNascimento, "DD/MM/YYYY")
                                  : null
                              }
                            />
                          </Col>
                        </Row>
                        <Row gutter={[8, 8]}>
                          <Col span={12}>
                            <span
                              style={{
                                paddingRight: "15px",
                                color: "#809FB8",
                                fontWeight: "bold",
                              }}
                            >
                              Morada
                            </span>
                            <Input
                              name="morada"
                              placeholder="Morada"
                              style={styles.input_class}
                              value={morada}
                              onChange={(e) => setMorada(e.target.value)}
                            />
                          </Col>
                          <Col span={12}>
                            <span
                              style={{
                                paddingRight: "15px",
                                color: "#809FB8",
                                fontWeight: "bold",
                              }}
                            >
                              Selecione uma Cidade
                            </span>
                            <Select
                              style={{ lineheight: "45px" }}
                              key={"cidade"}
                              onChange={(e) => setCidade(e)}
                              name="cidade"
                              showSearch
                              placeholder="Selecione uma Cidade"
                              value={cidade ? cidade : ""}
                              optionFilterProp="children"
                              required
                            >
                              {Array.isArray(session_storage.cidades) &&
                                session_storage.cidades.map((cidade) => (
                                  <Select.Option value={cidade.nome}>
                                    {" "}
                                    {cidade.nome}{" "}
                                  </Select.Option>
                                ))}
                            </Select>
                          </Col>
                        </Row>
                        <Row gutter={[8, 8]}>
                          <Col span={12}>
                            <span
                              style={{
                                paddingRight: "15px",
                                color: "#809FB8",
                                fontWeight: "bold",
                              }}
                            >
                              Código Postal
                            </span>
                            <Input
                              name="codigo_postal"
                              placeholder="Código Postal"
                              style={styles.input_class}
                              value={codigoPostal}
                              onChange={(e) => setCodigoPostal(e.target.value)}
                            />
                          </Col>
                          <Col span={12}>
                            <span
                              style={{
                                paddingRight: "15px",
                                color: "#809FB8",
                                fontWeight: "bold",
                              }}
                            >
                              País
                            </span>
                            <Input
                              name="pais"
                              placeholder="País"
                              style={styles.input_class}
                              value={pais}
                              onChange={(e) => setPais(e.target.value)}
                            />
                          </Col>
                        </Row>
                        <Row gutter={[8, 8]}>
                          <Col span={12}>
                            <span
                              style={{
                                paddingRight: "15px",
                                color: "#809FB8",
                                fontWeight: "bold",
                              }}
                            >
                              Nome Completo
                            </span>
                            <Input
                              name="nome_completo"
                              placeholder="Nome Completo"
                              style={styles.input_class}
                              value={nomeCompleto}
                              onChange={(e) => setNomeCompleto(e.target.value)}
                            />
                          </Col>
                          <Col span={12}>
                            <span
                              style={{
                                paddingRight: "15px",
                                color: "#809FB8",
                                fontWeight: "bold",
                              }}
                            >
                              NIF Pessoal
                            </span>
                            <Input
                              name="numero_contribuinte"
                              placeholder="NIF Pessoal"
                              style={styles.input_class}
                              value={numeroContribuinte}
                              onChange={(e) =>
                                setNumeroContribuinte(e.target.value)
                              }
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                    <Divider
                      orientation="left"
                      orientationMargin="0"
                      style={{ marginTop: "10px", marginBottom: "0px" }}
                    >
                      <h5 style={styles.h5_label}>Password</h5>
                    </Divider>

                    <p style={{ marginBottom: "0" }}>
                      Poderá alterar a sua password nesta secção.
                    </p>
                    <Col
                      style={{
                        padding: "25px 20px",
                        borderRadius: "16px",
                        background: "#D6D7E2",
                      }}
                    >
                      <Row
                        style={{ padding: "0" }}
                        align="bottom"
                        gutter={[8, 8]}
                      >
                        <Col span={12}>
                          <h6 style={styles.h6_label}>Nova Password</h6>
                          <Input
                            key={"new-password"}
                            name="new-password"
                            placeholder="Nova password"
                            style={styles.input_class}
                            type="text"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </Col>
                        <Col span={12}>
                          <h6 style={styles.h6_label}>Repetir a Password</h6>
                          <Input
                            type="text"
                            key={"repetir-password"}
                            name="confirm_new_password"
                            placeholder="Confirmar nova password"
                            style={styles.input_class}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                        </Col>
                      </Row>
                      {hasError && (
                        <div
                          style={{
                            marginTop: "10px",
                            padding: "10px",
                            background: "#ffc3c3",
                            border: "1px solid #ffc3c3",
                            borderRadius: "10px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <ExclamationCircleOutlined twoToneColor="red" />{" "}
                          <span
                            style={{ paddingLeft: "10px", fontSize: "14px" }}
                          >
                            {errorMessage}
                          </span>
                        </div>
                      )}
                    </Col>
                    <Row style={{ margin: "10px 0" }} className="row-btns">
                      <button type="submit" className="salvar-btn">
                        Salvar Alterações
                      </button>
                      {session_storage.all_data.user_session.tipo_utilizador ==
                        "socio_aprovado" && (
                        <Link
                          to={
                            "/socios/perfil/" +
                            session_storage.all_data.user_session.id
                          }
                        >
                          <button
                            style={{
                              borderRadius: "10px",
                              background: "#fff",
                              color: "#494D7D",
                              height: "42px",
                              border: "2px solid #494D7D",
                              marginLeft: "15px",
                              padding: "0px 20px",
                              fontWeight: "bold",
                            }}
                          >
                            Visualizar a minha página de sócio
                          </button>
                        </Link>
                      )}
                    </Row>

                    <Col span={24}>
                      <p style={{ fontSize: "12px" }}>
                        Conheça a{" "}
                        <a
                          style={{
                            color: "#0C1151",
                            fontWeight: "bold",
                            display: "initial",
                          }}
                          href=""
                          target={"_blank"}
                          className="link"
                        >
                          política de privacidade
                        </a>{" "}
                        deste website bem como os{" "}
                        <a
                          style={{
                            color: "#0C1151",
                            fontWeight: "bold",
                            display: "initial",
                          }}
                          href=""
                          target={"_blank"}
                          className="link"
                        >
                          termos e condições
                        </a>{" "}
                        do mesmo. <br />* Campos de preenchimento obrigatório.
                      </p>
                    </Col>
                  </FlexInRow>
                </form>
              </Grid>
            </SectionHighlightsCards>
          </Col>
          <Col xl={5} xs={24}>
            <SidebarMenu navItens={SidebarMenuLinks} alert={1} />
          </Col>
        </Row>
      </Container>
      <Modal
        centered
        open={isModalOpen}
        footer={null}
        onCancel={() => setIsModalOpen(false)}
      >
        <Result
          style={{ background: "#fff" }}
          status="success"
          title="Perfil atualizado com sucesso!"
          subTitle="É importante atualizar o seu perfil com os seus dados."
        />
      </Modal>
      <Modal
        open={isModalUploadOpen}
        width={300}
        onCancel={() => setIsModalUploadOpen(false)}
        footer={[
          <Button
            type="primary"
            onClick={handleUpload}
            disabled={fileList.length === 0}
            loading={uploading}
            style={{
              borderRadius: "12px",
              background: "#494D7D",
              width: "100%",
              height: "45px",
            }}
          >
            {uploading ? "Uploading" : "Start Upload"}
          </Button>,
        ]}
      >
        <h5
          style={{
            marginBottom: 0,
            fontSize: "18px",
            textAlign: "center",
            paddingTop: "20px",
            paddingBottom: "10px",
          }}
        >
          Faça upload da sua imagem
        </h5>
        <p
          style={{
            marginBottom: 0,
            fontSize: "14px",
            textAlign: "center",
            color: "#809FB8",
            paddingBottom: "20px",
          }}
        >
          Tamanho máximo de upload (2 MB)
        </p>
        <Dragger {...prop} style={{ borderRadius: "12px" }}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p
            className="ant-upload-text"
            style={{ padding: "0px 10px", fontSize: "14px" }}
          >
            Clique ou arraste para esta área a imagem de perfil
          </p>
        </Dragger>
      </Modal>

      <RecommendedLinks
        title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
        links={[
          {
            title: "Lista de sócios",
            link: "/socios/lista-de-socios",
          },
          {
            title: "Pedir uma 2ª opinião",
            link: "/ferramentas-uteis/pedir-uma-segunda-opiniao",
          },
          {
            title: "Área pessoal",
            link: "/socios/area-de-socio",
          },
          {
            title: "Contactos",
            link: "/spap/contactos",
          },
        ]}
      />
    </Wrapper>
  );
}
