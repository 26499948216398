import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Wrapper = styled.footer`
  background-color: #E8E9F3;
  color: #0C1151;
  display: flex;
  justify-content: space-between;
  padding: 50px 0;

  a {
    color: ${props => props.theme.orange};
    text-decoration: underline;
    transition: .3s ease;
  }

  a:hover {
    color: ${props => props.theme.orangeHover};
  }

  @media (max-width: 992px) {
    width: 100%;
  }
`

export default function Footer ( props ) {
  return(
    <Wrapper className={props.className}>
      <span>SPAP © Copyright 2023</span>
      <span>
        Todos os direitos reservados | <Link to="/termos-e-condicoes" target="_blank">Termos e condições</Link> | <Link to="/politica-de-privacidade" target="_blank">Política de privacidade</Link>
      </span>
    </Wrapper>
  )
}