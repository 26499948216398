import styled from "styled-components";

import Container from "../../../Components/Container";
import FlexInRow from "../../../Components/FlexInRow";
import FlexInColumn from "../../../Components/FlexInColumn";
import PageTitle from "../../../Components/PageTitle";
import PageText from "../../../Components/PageText";
import ContactForm from "../../../Components/ContactForm";
import Newsletter from "../../../Components/Newsletter";
import { Helmet } from "react-helmet";
import { Col, Row } from "antd";
import PatologistaExplicaForm from "../../../Components/PatologistaExplicaForm";
const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  .content {
    padding: 25px 0 45px 0;
  }

  .div-bg {
    background-position: 115% bottom;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .blue {
    color: #0C1151;
  }

  .black {
    color: #000;
  }

  .div-video-rtp {
    background: #E8E9F3;
    border-radius: 5px;
    padding: 20px;
    text-align: left !important;
  }

  .div-video-rtp img {
    margin-bottom: 10px;
  }

  .image {
    margin: 0 auto auto;
    max-width: 32.292vw;
  }

  img {
    width: 100%;
  }

  p {
    text-align: left;
    font-size: 16px;
    line-height: inherit;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0;
  }

  a {
    text-align: left;
    font-size: 16px;
    line-height: 1.563vw;
    letter-spacing: 0px;
    color: #f88436;
    opacity: 1;
    place-self: flex-start;
    transition: 0.3s ease-out;
    display: inline;
  }

  a:hover {
    text-decoration: none;
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    p,
    a {
      font-size: 14px;
      line-height: 18px;
    }

    .div-bg {
      background-size: 50% !important;
    }

    .content {
      padding: 0px 0 50px 0 !important;
    }
  }


  @media (max-width: 600px) {
    .div-bg {
      background-size: 100% !important;
    }

    .content {
      padding: 0px 0 190px 0 !important;
    }
  }


  @media (min-width: 1920px) {
    .image {
      width: 330px;
      max-width: 330px;
    }
  }
`;

export default function PatologistaExplica(props) {
  const ContactItens = [
    {
      name: "M. Augusta Cipriano - Presidente",
      email: ["direcao@spap.pt"],
    },
  ];

  return (
    <Wrapper>
      <Helmet>
        <title>
          O Patologista explica | Sociedade Portuguesa de Anatomia Patológica - SPAP
        </title>
        <meta
          name="description"
          content="A Sociedade Portuguesa de Anatomia Patológica está sempre ao seu dispor. Entre em contacto e coloque-nos as suas dúvidas."
        />
        <meta
          name="keywords"
          content="spap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      <div className="div-bg" style={{backgroundImage: 'url(' + require("../../../Assets/Images/Patologista.png") + ')'}}>
      <Container className="content" >
        <PageTitle
          title={<>O PATOLOGISTA<br/> EXPLICA</>}
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "SPAP",
              link: "/spap",
            },
            {
              text: "O Patologista explica",
            },
          ]}
          imageDesktop={require("../../../Assets/Images/O-patologist-explica.png")}
          imageMobile={require("../../../Assets/Images/O-patologist-explica.png")}
          imageAlt="Contactos"
        />

        <Row gutter={[8,8]}>
            <Col md={12} sm={24}>
                  <p className="mt-10">Bem-vindo à plataforma "O Patologista Explica"!<br/> <b>O nosso objetivo é proporcionar um espaço onde qualquer pessoa possa esclarecer dúvidas sobre diagnósticos, relatórios e procedimentos da Anatomia Patológica.</b> Acreditamos que um paciente bem informado é um membro ativo da equipa de saúde, e estamos aqui para apoiar essa jornada. </p>
                  
                  <p className="mt-10">Nesta plataforma, um médico patologista experiente está disponível para responder às suas perguntas de forma clara e acessível, ajudando a desmistificar conceitos e a promover uma melhor compreensão da importância da Anatomia Patológica na saúde.</p>
            </Col>
            <Col md={12} sm={24} className="mt-10-mobile">
              {/* <div className="div-video-rtp">
                <a target="_blank" href="https://www.rtp.pt/play/p9539/e587783/a-arte-da-cura"><img src={require("../../../Assets/Images/Video-Off@2x.png")} 
                  onMouseOver={(e) => e.currentTarget.src = require('../../../Assets/Images/Video-On.png')}
                  onMouseOut={(e) => e.currentTarget.src = require('../../../Assets/Images/Video-Off@2x.png')}/></a>
                <small className="blue"><b>Vídeo @RTP Play // https://www.rtp.pt/play/p9539/e587783/a-arte-da-cura</b></small><br/>
                <small className="black">A Arte da Cura | Leonardo Da Vinci ao Raio X|Ep. 423 dez. 2021</small>
              </div> */}

            </Col>
          </Row>
      </Container>
      </div>

      <PatologistaExplicaForm />

      {/* <Newsletter /> */}
    </Wrapper>
  );
}
