import styled from "styled-components";

import Container from "../../../../Components/Container";
import FlexInRow from "../../../../Components/FlexInRow";
import FlexInColumn from "../../../../Components/FlexInColumn";
import PageTitle from "../../../../Components/PageTitle";
import PageText from "../../../../Components/PageText";
import SidebarMenu from "../../../../Components/SidebarMenu";
import Presidents from "../../../../Components/HistoriaPresidents";
import { Helmet } from "react-helmet";
const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin: 7.813vw 0 4.792vw;
  overflow-x: hidden;

  > .content {
    padding-bottom: 4vw;
  }

  .image {
    margin-left: auto;
    max-width: 32.292vw;
  }

  img {
    width: 100%;
  }

  p {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0;
  }

  ul {
    padding-left: 1rem;
  }

  li {
    text-align: left;
    font-size: 1.354vw;
    font-weight: medium;
    line-height: 1.771vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    p,
    li {
      font-size: 3.333vw;
      line-height: 4.444vw;
    }
  }

  @media (min-width: 1920px) {
    p {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

const Title = styled.h2`
  text-align: left;
  font-size: 1.25vw;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;
  margin: 0;
  width: 100%;

  @media (max-width: 992px) {
    font-size: 4.333vw;
    line-height: 5.444vw;
  }

  @media (min-width: 1920px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 5.208vw;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    gap: 50px;
  }

  @media (min-width: 1920px) {
    gap: 234px;
  }
`;

const Picture = styled.picture`
  min-width: ${(props) => props.width};
  max-width: ${(props) => props.width};
`;

export default function Presidentes(props) {
  const SidebarMenuLinks = [
    {
      title: "História",
      link: "/spap/historia",
    },
    {
      title: "Presidentes",
      link: "/spap/presidentes",
    },
    {
      title: "Congressos...",
      link: "/spap/congressos",
    },
  ];

  return (
    <Wrapper>
      <Helmet>
        <title>
          Presidentes | Sociedade Portuguesa de Anatomia Patológica - SPAP
        </title>
        <meta
          name="description"
          content="Conheça Todos Os Presidentes Da SPAP Desde A Sua Fundação"
        />
        <meta
          name="keywords"
          content="spap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      <FlexInRow gap="80px">
        <Container className="content">
          <PageTitle
            title="Presidentes"
            path={[
              {
                text: "Homepage",
                link: "/",
              },
              {
                text: "SPAP",
                link: "/spap",
              },
              {
                text: "História",
                link: "/spap/historia",
              },
              {
                text: "Presidentes",
              },
            ]}
            imageDesktop={require("../../../../Assets/Images/img-titulo-historia@2x.png")}
            imageMobile={require("../../../../Assets/Images/img-titulo-historia@2x.png")}
            imageAlt="Presidentes"
            sidebar={
              <SidebarMenu navItens={SidebarMenuLinks} className="sidebar" />
            }
          />

          <FlexInRow gap="2.604vw">
            <Grid>
              <Title>
                Conheça todos os presidentes da SPAP desde a sua fundação
              </Title>
            </Grid>
          </FlexInRow>
        </Container>

        <Presidents />
      </FlexInRow>
    </Wrapper>
  );
}
