import styled from "styled-components";
import { useRef, useState, useEffect } from "react";

import Container from "../../../Components/Container";
import FlexInColumn from "../../../Components/FlexInColumn";
import FlexInRow from "../../../Components/FlexInRow";
import PageTitle from "../../../Components/PageTitle";
import PageText from "../../../Components/PageText";
import RecommendedLinks from "../../../Components/RecommendedLinks";
import { Helmet } from "react-helmet";
import IconFile from "../../../Assets/Images/icon-awesome-file-alt.svg";
import { ReactComponent as IconEstatutos } from "../../../Assets/Images/estatutos-documentos.svg";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  img {
    width: 100%;
  }

  p a {
    display: initial;
    transition: 0.3s ease-out;
  }

  p a:hover {
    text-decoration: none;
  }

  .content {
    padding-bottom: 4.688vw;
  }

  .text {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 0;
  }

  .team {
    padding-bottom: 3.125vw;
    gap: 40px;
  }

  .team__title {
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    line-height: inherit;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
  }

  .team .bar {
    height: 1px;
    width: 66.854vw;
    background-color: #d6cdf7;
    opacity: 1;
    margin: auto;
  }

  .team__items {
    display: grid;
    grid-column-gap: 3.125vw;
    grid-row-gap: 3.646vw;
    grid-template-columns: 1fr 1fr;
  }

  .team__items b {
    text-align: left;
    font-size: 0.938vw;
    font-weight: bold;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .team__items a {
    text-align: left;
    text-decoration: none;
    font-size: 0.938vw;
    font-weight: bold;
    line-height: 1.25vw;
    color: #0c1151;
    letter-spacing: 0px;
    opacity: 1;
    margin-right: auto;
  }

  .team .item__title {
    font-size: 1.042vw;
    font-weight: bold;
    line-height: 1.25vw;
    text-align: left;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
  }

  .team .item__image {
    width: 3.438vw;
    box-sizing: content-box;
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    .text {
      font-size: 3.333vw;
      line-height: 3.333vw;
    }

    .team__items {
      grid-template-columns: 1fr;
    }

    .team__title {
      font-size: 4.444vw;
      line-height: 5.555vw;
    }

    .team .item__image {
      width: 30px;
    }

    .team__items p,
    .team__items b,
    .team__items a {
      font-size: 12px;
      line-height: 16px;
    }
  }

  @media (min-width: 1920px) {
    .text,
    .team__items b,
    .team__items a {
      font-size: 18px;
      line-height: 24px;
    }

    .team__title {
      font-size: 24px;
      line-height: 28px;
    }

    .team .bar {
      width: 800px;
    }

    .team .bar.large {
      width: 1200px;
    }

    .team .item__image {
      width: 66px;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  .image-destaque {
    width: 100%;

    svg {
      width: 100%;
    }
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;

    .image-destaque {
      display: none;
    }
  }
`;

export default function EstatutosDocumentos(props) {
  const session_storage = JSON.parse(localStorage.getItem("session_data"));

  const [filesPDF, setFilesPDF] = useState([]);

  useEffect(() => {
    fetch(`https://api.spap.pt/api/all-estatutos-docs`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0) {
          console.log("nenhum evento");
        }
        setFilesPDF(() => getFilesPDF(data.estatutosdocs));
      })
      .catch((error) => console.error(error));
  }, []);

  const getFilesPDF = (files) => {
    if (!files) {
      return [];
    }
    if (!files.length) {
      return [];
    }

    let myFiles = files.filter((file) => {
      if (file != null) {
        let date = file.updated_at.substring(0, 10);
        let [year, month, day] = date.split("-");
        file.dataToShow = day + "/" + month + "/" + year;

        return file;
      }
    });

    let tiposDocsToShow = [];
    myFiles?.map((estatutosdoc) => {
      tiposDocsToShow.push(estatutosdoc.tipo_doc);
    });

    const tipoDocs = tiposDocsToShow.filter((value, index) => {
      const _value = JSON.stringify(value);
      return (
        index ===
        tiposDocsToShow.findIndex((obj) => {
          return JSON.stringify(obj) === _value;
        })
      );
    });

    let docsToShow = [];
    tipoDocs.map((tipo_doc) => {
      let docs = myFiles?.filter((file) => {
        if (file.tipo_doc == tipo_doc) return file;
      });
      docsToShow.push({ tipo_doc: tipo_doc, docs: docs });
    });

    return docsToShow;
  };

  return (
    <Wrapper>
      <Helmet>
        <title>
          Estatutos e Documentos | Sociedade Portuguesa de Anatomia Patológica -
          SPAP
        </title>
        <meta
          name="description"
          content="A SPAP é feita de muito trabalho, invisível de muitas maneiras. Os sócios apercebem-se quando há um convite, uma novidade, uma oferta de bolsa. Mas há muito mais a acontecer, muito antes de qualquer email."
        />
        <meta
          name="keywords"
          content="spap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>

      <Container className="content">
        <PageTitle
          title="ESTATUTOS E DOCUMENTOS"
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "SPAP",
              link: "/spap",
            },
            {
              text: "Parcerias e Projetos",
            },
          ]}
          imageDesktop={require("../../../Assets/Images/img-o-que-e-anatomia@2x.png")}
          imageMobile={require("../../../Assets/Images/img-o-que-e-anatomia.png")}
          imageAlt="Estatutos e Documentos"
        />

        <FlexInRow gap="15px">
          <Grid>
            <FlexInRow gap="0">
              <PageText title="A SPAP é feita de muito trabalho, invisível de muitas maneiras." />
              <p className="text">
                Os sócios apercebem-se quando há um convite, uma novidade, uma
                oferta de bolsa. <br />
                Mas há muito mais a acontecer, muito antes de qualquer email.
              </p>
              <p className="text">
                Nesta secção queremos que o trabalho fique um pouco mais visível
                para todos, que sejam transparentes os nossos esforços,
                dificuldades e conquistas. <br />
                Que as contas possam ser revisadas, e escrutinadas, e que dêem
                frutos. <br />
                Que os planos sejam acolhidos, melhorados, ou alterados, sempre
                que necessário.
              </p>
              <p className="text">
                Os estatutos são a nossa base, e foram criados em outros tempos,
                por isso o nosso esforço em atualizá-los. Existem leis que os
                enquadram, por isso não se pode ter tudo à nossa maneira. Mas
                podemos nos adaptar, e adaptar o que for possível à nossa
                realidade.
              </p>
              <p className="text">
                Obrigada por ter interesse. Estamos abertos a qualquer dúvida ou
                sugestão. <br />
                <a href="mail:site@spap.pt">site@spap.pt</a>
              </p>
              {/* <p className="text">
                A Direção da SPAP aprovou no dia 6 de junho de 2024 o{" "}
                <b>
                  REGULAMENTO INTERNO <br />
                  PARA ATRIBUIÇÃO DE PATROCÍNIO CIENTÍFICO.
                </b>
              </p>
              <p className="text">
                Antes de solicitar o patrocínio, consulte{" "}
                <a
                  target="_blank"
                  href="https://api.spap.pt/estatutosdocs/68/estatutos-doc-1718102281.pdf"
                >
                  aqui
                </a>{" "}
                para saber se reúne as condições necessárias.
              </p> */}
            </FlexInRow>

            <FlexInRow
              placeself="flex-end"
              className="image-destaque"
              mobile={true}
            >
              <picture>
                <source
                  srcSet={require("../../../Assets/Images/estatutos-documentos@2x.png")}
                  media="(min-width: 990px)"
                />
                <img
                  src={require("../../../Assets/Images/estatutos-documentos.png")}
                  alt="Seja um Membro"
                />
              </picture>
            </FlexInRow>
          </Grid>

          <FlexInRow gap="60px" className="team">
            {filesPDF.map((item, index) => {
              return (
                <>
                  <FlexInColumn gap="1.042vw">
                    <h2 className="team__title">{item.tipo_doc}</h2>
                    <div className="bar large"></div>
                  </FlexInColumn>

                  <div className="team__items">
                    {item.docs.map((doc, index) => {
                      return (
                        <>
                          <FlexInRow gap="0.521vw" className="item">
                            <FlexInColumn gap="1.042vw">
                              <img
                                src={IconFile}
                                alt="Icon File"
                                className="item__image icon icon-file"
                              />
                              <FlexInRow gap="0.521vw">
                                <b>{doc.titulo}</b>
                                {session_storage != null &&
                                session_storage?.all_data?.user_session
                                  .tipo_utilizador != "socio_nao_aprovado" &&
                                session_storage?.all_data?.user_session
                                  .tipo_utilizador !=
                                  "socio_nao_aprovado_especialista" &&
                                session_storage?.all_data?.user_session
                                  .tipo_utilizador != "nao_socio" ? (
                                  <a
                                    target="_blank"
                                    href={
                                      "https://api.spap.pt/estatutosdocs/" +
                                      doc.id +
                                      "/" +
                                      doc.url
                                    }
                                  >
                                    Clique para visualizar
                                  </a>
                                ) : (
                                  <p
                                    style={{
                                      color: "#EE8585",
                                      cursor: "not-allowed",
                                    }}
                                  >
                                    Documento visível apenas para sócios
                                  </p>
                                )}
                              </FlexInRow>
                            </FlexInColumn>
                          </FlexInRow>
                        </>
                      );
                    })}
                  </div>
                </>
              );
            })}
          </FlexInRow>
        </FlexInRow>
      </Container>

      <RecommendedLinks
        title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
        links={[
          {
            title: "História",
            link: "/spap/historia",
          },
          {
            title: "Órgãos sociais",
            link: "/spap/orgaos-sociais",
          },
          {
            title: "Bolsas e prémios",
            link: "/spap/bolsas-e-premios",
          },
          {
            title: "Vantagens de ser sócio",
            link: "/socios/conheca-as-vantagens",
          },
        ]}
      />
    </Wrapper>
  );
}
