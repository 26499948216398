import styled from "styled-components";
import { Link } from "react-router-dom";
import Container from "../../../Components/Container";
import FlexInRow from "../../../Components/FlexInRow";
import FlexInColumn from "../../../Components/FlexInColumn";
import PageTitle from "../../../Components/PageTitle";
import PageText from "../../../Components/PageText";
import { Helmet } from "react-helmet";
import IconChecked from "../../../Assets/Images/icon-verificado.svg";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  > .content {
    padding-bottom: 4vw;
  }

  img {
    width: 100%;
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    .title-socios * {
      font-size: 4.444vw;
      line-height: 5.555vw;
    }
  }
`;

const Title = styled.h2`
  color: #0c1151;
  text-align: center;
  font-size: 2.083vw;
  font-weight: bold;
  line-height: 2.5vw;
  letter-spacing: 0px;
  text-transform: uppercase;
  opacity: 1;

  @media (max-width: 992px) {
    font-size: 5.556vw;
    line-height: 6.667vw;
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.604vw;

  .icon {
    min-width: 4.948vw;
    max-width: 4.948vw;
  }

  p {
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-size: 1.042vw;
    line-height: inherit;
    margin: auto 0;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    gap: 30px;

    .icon {
      min-width: 40px;
      max-width: 40px;
    }

    p {
      font-size: 3.333vw;
      line-height: 4.444vw;
    }
  }
`;

const SectionCtaLogin = styled.section`
  background-image: url(${require("../../../Assets/Images/banner-conheca-vantagens-botton@2x.png")});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 5.833vw 0;

  > .content {
    align-items: center;
    display: grid;
    justify-content: flex-start;
  }

  .title {
    text-align: left;
    font-size: 2.76vw;
    font-weight: bold;
    line-height: 3.333vw;
    letter-spacing: 0px;
    color: #494d7d;
    text-transform: uppercase;
    opacity: 1;
  }

  .subtitle {
    text-align: left;
    font-size: 4.219vw;
    font-weight: bold;
    line-height: 5.052vw;
    letter-spacing: 0px;
    color: #494d7d;
    text-transform: uppercase;
    opacity: 1;
  }

  .button {
    text-align: left;
    font-size: 1.146vw;
    font-weight: bold;
    line-height: 1.51vw;
    letter-spacing: 0px;
    padding: 0.781vw 1.563vw;
    color: #ffffff;
    background-color: #f88436;
    text-decoration: none;
    border-radius: 0.521vw;
    opacity: 1;
    transition: 0.3s ease;
  }

  .button:hover {
    background-color: #e87426;
    color: #ececec;
  }

  @media (max-width: 992px) {
    background-position: 100%;

    .title {
      font-size: 7.444vw;
      line-height: 8.555vw;
    }

    .button {
      border-radius: 8px;
      font-size: 3.333vw;
      line-height: 4.444vw;
      padding: 10px;
    }
  }
`;

export default function ConhecaVantagens(props) {
  return (
    <Wrapper>
      <Helmet>
        <title>
          Conheça as vantagens | Sociedade Portuguesa de Anatomia Patológica -
          SPAP
        </title>
        <meta
          name="description"
          content="Ao ser sócio da Sociedade Portuguesa de Anatomia Patológica (SPAP) integra uma associação que tem como objetivo promover o aperfeiçoamento e o progresso técnico-científico da especialidade de Anatomia Patológica e consequentemente o de todos os seus associados."
        />
        <meta
          name="keywords"
          content="spap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      <Container className="content">
        <PageTitle
          title="Conheça as Vantagens"
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "Sócios",
              link: "/socios",
            },
            {
              text: "Conheça as Vantagens",
            },
          ]}
          imageDesktop={require("../../../Assets/Images/img-conheca-as-vantagens@2x.png")}
          imageMobile={require("../../../Assets/Images/img-conheca-as-vantagens.png")}
          imageAlt="Conheça as Vantagens"
        />

        <FlexInRow gap="3.646vw">
          <PageText
            className="title-socios"
            title="Ao ser sócio da Sociedade Portuguesa de Anatomia Patológica (SPAP) integra uma associação que tem como objetivo promover o aperfeiçoamento e o progresso técnico-científico da especialidade de Anatomia Patológica e consequentemente o de todos os seus associados."
          />

          <FlexInRow gap="2.604vw">
            <Title>Ser Sócio da SPAP Permite:</Title>
            <Content>
              <FlexInColumn gap="1.250vw">
                <picture className="icon icon-checked">
                  <source srcSet={IconChecked} media="(min-width: 990px)" />
                  <img src={IconChecked} alt="Icon Checked" />
                </picture>
                <p>
                  Ser membro da International Academy of Pathology (IAP), com
                  consequente reconhecimento internacional e descontos em
                  congressos, conferências e cursos organizados pela IAP
                </p>
              </FlexInColumn>
              <FlexInColumn gap="1.250vw">
                <picture className="icon icon-checked">
                  <source srcSet={IconChecked} media="(min-width: 990px)" />
                  <img src={IconChecked} alt="Icon Checked" />
                </picture>
                <p>
                  Descontos em inscrições em congressos, conferências e cursos,
                  organizados pela SPAP e /ou promovidos pela SPAP
                </p>
              </FlexInColumn>
              <FlexInColumn gap="1.250vw">
                <picture className="icon icon-checked">
                  <source srcSet={IconChecked} media="(min-width: 990px)" />
                  <img src={IconChecked} alt="Icon Checked" />
                </picture>
                <p>
                  Descontos na anuidade das quotas da European Society of
                  Pathology (ESP)
                </p>
              </FlexInColumn>
              <FlexInColumn gap="1.250vw">
                <picture className="icon icon-checked">
                  <source srcSet={IconChecked} media="(min-width: 990px)" />
                  <img src={IconChecked} alt="Icon Checked" />
                </picture>
                <p>Maior acesso a bolsas para formação específica</p>
              </FlexInColumn>
              <FlexInColumn gap="1.250vw">
                <picture className="icon icon-checked">
                  <source srcSet={IconChecked} media="(min-width: 990px)" />
                  <img src={IconChecked} alt="Icon Checked" />
                </picture>
                <p>
                  Inscrição na área privada do site da SPAP, tendo acesso a
                  conteúdos específicos
                </p>
              </FlexInColumn>
              <FlexInColumn gap="1.250vw">
                <picture className="icon icon-checked">
                  <source srcSet={IconChecked} media="(min-width: 990px)" />
                  <img src={IconChecked} alt="Icon Checked" />
                </picture>
                <p>Incorporar grupos de trabalho / clubes de patologia</p>
              </FlexInColumn>
              <FlexInColumn gap="1.250vw">
                <picture className="icon icon-checked">
                  <source srcSet={IconChecked} media="(min-width: 990px)" />
                  <img src={IconChecked} alt="Icon Checked" />
                </picture>
                <p>
                  Estar a par das iniciativas da SPAP através dos emails e
                  newsletter
                </p>
              </FlexInColumn>
              <FlexInColumn gap="1.250vw">
                <picture className="icon icon-checked">
                  <source srcSet={IconChecked} media="(min-width: 990px)" />
                  <img src={IconChecked} alt="Icon Checked" />
                </picture>
                <p>
                  Participar nas assembleias de sócios e votar nos órgãos
                  dirigentes.
                </p>
              </FlexInColumn>
            </Content>
          </FlexInRow>
        </FlexInRow>
      </Container>

      <SectionCtaLogin>
        <Container className="content">
          <FlexInRow gap="4.167vw">
            <div>
              <h2 className="title">Junte-se a nós</h2>
              <h3 className="subtitle">Juntos conseguimos</h3>
            </div>
            <FlexInColumn gap="2.865vw" mobile="column">
              <Link to="/register" className="button">
                Inscreva-se aqui
              </Link>
              <Link to="/login" className="button">
                Faça login no website
              </Link>
            </FlexInColumn>
          </FlexInRow>
        </Container>
      </SectionCtaLogin>
    </Wrapper>
  );
}
