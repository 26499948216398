import { Link } from "react-router-dom";
import styled from "styled-components";

import Container from "../../Components/Container";
import FlexInRow from "../../Components/FlexInRow";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #000000;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;
  overflow-x: hidden;
  padding: 20px 0;

  img {
    width: 100%;
  }

  @media (max-width: 992px) {
    margin-top: 12.222vw;
  }
`;

export default function InformacoesPagamentos(props) {
  return (
    <Wrapper>
      <Container>
        <FlexInRow>
          <h1>Informações sobre pagamentos</h1>
          <h2></h2>
          <p>Em breve informações sobre pagamentos aqui.</p>
        </FlexInRow>
      </Container>
    </Wrapper>
  );
}
