import styled from "styled-components";
import { Link } from "react-router-dom";

import Container from "../../../Components/Container";
import FlexInColumn from "../../../Components/FlexInColumn";
import FlexInRow from "../../../Components/FlexInRow";
import PageTitle from "../../../Components/PageTitle";
import PageText from "../../../Components/PageText";
import RecommendedLinks from "../../../Components/RecommendedLinks";

import { Helmet } from "react-helmet";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  .orgaos {
    gap: 5px;
  }

  .orgaos b {
    font-size: 16px !important;
  }
  .orgaos p {
    font-size: 15px !important;
  }

  .page-text {
    display: none !important;
  }

  img {
    width: 100%;
  }

  > .content {
    padding-bottom: 4.688vw;
  }

  .text {
    text-align: left;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 2px;
  }

  .text a {
    color: #f88436;
    transition: 0.3s ease-out;

    :hover {
      text-decoration: none;
    }
  }

  .team {
    padding-bottom: 3.125vw;
  }

  .team__title {
    text-align: left;
    font-size: 1.25vw;
    font-weight: bold;
    line-height: inherit;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
  }

  .team .bar {
    height: 1px;
    width: 66.854vw;
    background-color: #d6cdf7;
    opacity: 1;
    margin: auto;
  }

  .team__items {
    display: grid;
    gap: 3.125vw;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .team__items b {
    text-align: left;
    font-size: 0.938vw;
    font-weight: bold;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .team__items p {
    text-align: left;
    font-size: 0.833vw;
    line-height: 1.563vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0;
  }

  .team .item__title {
    font-size: 1.042vw;
    font-weight: bold;
    line-height: 1.25vw;
    text-align: left;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
  }

  .team .item__image {
    overflow: hidden;
    width: 8.333vw;
    box-sizing: content-box;
  }

  .team .item__image img {
    object-fit: cover;
    aspect-ratio: 1/1;
    width: 100%;
    border: 2px solid #0c1151;
    border-radius: 0.26vw;
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    .team__items {
      grid-template-columns: 1fr;
    }

    .team__title {
      font-size: 20px;
      line-height: 5.555vw;
    }

    .team .item__image {
      width: 80px;
    }

    .team__items p,
    .team__items b,
    .team__items a {
      font-size: 18px;
      line-height: 16px;
    }

    .text,
    .team .item__title {
      font-size: 20px;
      line-height: 1.5;
    }
  }

  @media (min-width: 1920px) {
    .text {
      font-size: 18px;
      line-height: 24px;
    }

    .team {
      gap: 30px;
      padding-bottom: 100px;
    }

    .team__title {
      font-size: 24px;
      line-height: 28px;
    }

    .team .item__title {
      font-size: 20px;
      line-height: 24px;
    }

    .team .item__image {
      width: 160px;
    }

    .team__items b {
      font-size: 18px;
      line-height: 24px;
    }

    .team__items p {
      font-size: 16px;
      line-height: 30px;
    }

    .team .bar {
      width: 1244px;
    }
  }
`;

const SectionMembers = styled.section`
  margin: 0 auto;
  width: 41.458vw;

  @media (max-width: 992px) {
    width: 100%;
  }

  @media (min-width: 1920px) {
    width: 796px;
  }
`;

const SectionMembersContent = styled.div`
  border: 0.156vw solid #0c1151;
  border-radius: 1.042vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "image title"
    "image text"
    "image cta";
  opacity: 1;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "title"
      "image"
      "text"
      "cta";
    gap: 10px;
  }
`;

const SectionMembersImage = styled.div`
  border-radius: 1.042vw 0px 0px 1.042vw;
  grid-area: image;
  overflow: hidden;
`;

const SectionMembersTitle = styled.h3`
  grid-area: title;
  text-align: left;
  font-size: 1.25vw;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;
  margin: 1.042vw 1.042vw 0;
  place-self: flex-end flex-start;

  @media (max-width: 992px) {
    font-size: 24px;
    line-height: 32px;
    place-self: center;
  }

  @media (min-width: 1920px) {
    font-size: 24px;
    line-height: 28px;
    margin: 20px 20px 0;
  }
`;

const SectionMembersText = styled.p`
  grid-area: text;
  text-align: left;
  font-size: 0.938vw;
  line-height: 1.25vw;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin: 1.042vw;
  place-self: center flex-start;

  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 22px;
    place-self: center;
  }

  @media (min-width: 1920px) {
    font-size: 18px;
    line-height: 24px;
    margin: 20px;
  }
`;

const SectionMembersCta = styled.div`
  grid-area: cta;
  place-self: flex-start;
  align-items: center;
  justify-content: center;
  margin: 1.042vw;

  a {
    text-align: center;
    font-size: 0.938vw;
    font-weight: bold;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    background-color: #090d3d;
    border-radius: 0.521vw;
    opacity: 1;
    transition: 0.3s ease;
    text-decoration: none;
    padding: 0.677vw 1.563vw;
  }

  a:hover {
    background-color: #8688a8;
    color: #e5e5e5;
  }

  @media (max-width: 992px) {
    place-self: center;
    margin-bottom: 20px;

    a {
      border-radius: 8px;
      font-size: 16px;
      line-height: 22px;
      padding: 10px;
      margin: auto;
    }
  }

  @media (min-width: 1920px) {
    margin: 20px;

    a {
      border-radius: 10px;
      font-size: 18px;
      line-height: 24px;
      padding: 15px 30px;
    }
  }
`;

export default function OrgaosSociais(props) {
  return (
    <Wrapper>
      <Helmet>
        <title>
          Orgãos Sociais | Sociedade Portuguesa de Anatomia Patológica - SPAP
        </title>
        <meta
          name="description"
          content="Mandato 2024-2027. A atual direção da sociedade foi eleita em Setembro de 2024, com elementos de todo o país, com um programa de mandato bastante ambicioso voltado para a valorização da Qualidade, formação e condições de trabalho."
        />
        <meta
          name="keywords"
          content="spap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      <Container className="content">
        <PageTitle
          title="ÓRGÃOS SOCIAIS"
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "SPAP",
              link: "/spap",
            },
            {
              text: "Órgãos sociais",
            },
          ]}
          imageDesktop={require("../../../Assets/Images/img-circle-orgaos-sociais@2x.png")}
          imageMobile={require("../../../Assets/Images/img-circle-orgaos-sociais.png")}
          imageAlt="Orgãos Sociais"
        />

        <FlexInRow gap="48px">
          <FlexInRow gap="0">
            <PageText
              title="Mandato 2024-2027"
              className="p-none"
            />
            <p className="text">
              A atual direção da sociedade foi eleita em Setembro de 2024, com elementos de todo o país, com um <a href="https://api.spap.pt/estatutosdocs/84/Outros-1725887675.pdf" target="_blank">programa de mandato</a> bastante ambicioso voltado para a valorização da Qualidade, formação e condições de trabalho.
              </p><p className="text">Acreditamos que com a participação de todos os associados, contribuindo com as suas ideias, experiências e dedicação, conseguiremos não apenas atingir os objetivos, mas também superar as expectativas. 
              </p><p className="text">Juntos, conseguiremos enfrentar os desafios e aproveitar as oportunidades, assegurando que a Anatomia Patológica continue a desempenhar um papel central na Saúde em Portugal.
            </p>
          </FlexInRow>

          <FlexInRow gap="3.125vw" className="team">
            <FlexInColumn gap="1.042vw">
              <h2 className="team__title">Direção</h2>
              <div className="bar"></div>
            </FlexInColumn>

            <div className="team__items">
              <FlexInRow gap="1.042vw">
                <FlexInRow gap="0.521vw" className="item">
                  <h3 className="item__title">Presidente</h3>
                  <FlexInColumn gap="1.042vw">
                    <picture className="item__image">
                      <source
                        srcSet={require("../../../Assets/Images/foto-carla-bartosch.jpg")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/foto-carla-bartosch.jpg")}
                        alt="Carla Bartosch"
                      />
                    </picture>
                    <FlexInRow gap="0.521vw" className="orgaos">
                      <b>Carla Bartosch</b>
                      <p>IPO Porto</p>
                    </FlexInRow>
                  </FlexInColumn>
                </FlexInRow>
              </FlexInRow>
            </div>

            <div className="team__items">
              <FlexInRow gap="1.042vw">
                <FlexInRow gap="0.521vw" className="item">
                  <h3 className="item__title">Vice - Presidentes</h3>
                  <FlexInColumn gap="1.042vw">
                    <picture className="item__image">
                      <source
                        srcSet={require("../../../Assets/Images/Marco-Ferreira.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/Marco-Ferreira.png")}
                        alt="Marco Ferreira"
                      />
                    </picture>
                    <FlexInRow gap="0.521vw" className="orgaos">
                      <b>Marco Ferreira</b>
                      <p>ULS Amadora Sintra</p>
                    </FlexInRow>
                  </FlexInColumn>
                </FlexInRow>
                <FlexInRow gap="0.521vw" className="item">
                  <FlexInColumn gap="1.042vw">
                    <picture className="item__image">
                      <source
                        srcSet={require("../../../Assets/Images/Vitor-Sousa.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/Vitor-Sousa.png")}
                        alt="Vitor Sousa"
                      />
                    </picture>
                    <FlexInRow gap="0.521vw" className="orgaos">
                      <b>Vitor Sousa</b>
                      <p>ULS Coimbra</p>
                    </FlexInRow>
                  </FlexInColumn>
                </FlexInRow>
              </FlexInRow>
              <FlexInRow gap="1.042vw">
                <FlexInRow gap="0.521vw" className="item">
                  <h3 className="item__title">Secretária Geral</h3>
                  <FlexInColumn gap="1.042vw">
                    <picture className="item__image">
                      <source
                        srcSet={require("../../../Assets/Images/Elisabete-Rios.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/Elisabete-Rios.png")}
                        alt="Raquel Ilgenfritz"
                      />
                    </picture>
                    <FlexInRow gap="0.521vw" className="orgaos">
                      <b>Elisabete Rios</b>
                      <p>ULS São João, Porto</p>
                    </FlexInRow>
                  </FlexInColumn>
                </FlexInRow>
                <FlexInRow gap="0.521vw" className="item">
                  <h3 className="item__title">Vogal Tesoureira</h3>
                  <FlexInColumn gap="1.042vw">
                    <picture className="item__image">
                      <source
                        srcSet={require("../../../Assets/Images/foto-raquel-ilgenfritz.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/foto-raquel-ilgenfritz.png")}
                        alt="Raquel Ilgenfritz"
                      />
                    </picture>
                    <FlexInRow gap="0.521vw" className="orgaos">
                      <b>Raquel Ilgenfritz</b>
                      <p>CUF Descobertas, Lisboa</p>
                    </FlexInRow>
                  </FlexInColumn>
                </FlexInRow>
              </FlexInRow>
              <FlexInRow gap="1.042vw">
                <FlexInRow gap="0.521vw" className="item">
                  <h3 className="item__title">Vogais</h3>
                  <FlexInColumn gap="1.042vw">
                    <picture className="item__image">
                      <source
                        srcSet={require("../../../Assets/Images/foto-daniel-pinto.jpg")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/foto-daniel-pinto.jpg")}
                        alt="Daniel Pinto"
                      />
                    </picture>
                    <FlexInRow gap="0.521vw" className="orgaos">
                      <b>Daniel Pinto</b>
                      <p>HGO-Almada</p>
                    </FlexInRow>
                  </FlexInColumn>
                </FlexInRow>
                <FlexInRow gap="0.521vw" className="item">
                  <FlexInColumn gap="1.042vw">
                    <picture className="item__image">
                      <source
                        srcSet={require("../../../Assets/Images/Rita-Luis.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/Rita-Luis.png")}
                        alt="Rui Caetano Oliveira"
                      />
                    </picture>
                    <FlexInRow gap="0.521vw" className="orgaos">
                      <b>Rita Luís</b>
                      <p>
                        ULS São José, Lisboa
                      </p>
                    </FlexInRow>
                  </FlexInColumn>
                </FlexInRow>
              </FlexInRow>
            </div>

            <FlexInColumn gap="1.042vw">
              <h2 className="team__title">Assembleia-Geral</h2>
              <div className="bar"></div>
            </FlexInColumn>

            <div className="team__items">
              <FlexInRow gap="1.042vw">
                <FlexInRow gap="0.521vw" className="item">
                  <h3 className="item__title">Presidente</h3>
                  <FlexInColumn gap="1.042vw">
                    <picture className="item__image">
                      <source
                        srcSet={require("../../../Assets/Images/foto-germano.jpg")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/foto-germano.jpg")}
                        alt="Rui Caetano Oliveira"
                      />
                    </picture>
                    <FlexInRow gap="0.521vw" className="orgaos">
                      <b>Rui Caetano Oliveira</b>
                      <p>Germano Sousa | CEDAP, Coimbra</p>
                    </FlexInRow>
                  </FlexInColumn>
                </FlexInRow>
              </FlexInRow>
              <FlexInRow gap="1.042vw">
                <FlexInRow gap="0.521vw" className="item">
                  <h3 className="item__title">Vice-Presidente</h3>
                  <FlexInColumn gap="1.042vw">
                    <picture className="item__image">
                      <source
                        srcSet={require("../../../Assets/Images/Rui-Almeida.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/Rui-Almeida.png")}
                        alt="Rui Almeida"
                      />
                    </picture>
                    <FlexInRow gap="0.521vw" className="orgaos">
                      <b>Rui Almeida</b>
                      <p>ULS Coimbra</p>
                    </FlexInRow>
                  </FlexInColumn>
                </FlexInRow>
              </FlexInRow>
              <FlexInRow gap="1.042vw">
                <FlexInRow gap="0.521vw" className="item">
                  <h3 className="item__title">Secretária</h3>
                  <FlexInColumn gap="1.042vw">
                    <picture className="item__image">
                      <source
                        srcSet={require("../../../Assets/Images/Joana-Pardal.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/Joana-Pardal.png")}
                        alt="Rita Luís"
                      />
                    </picture>
                    <FlexInRow gap="0.521vw" className="orgaos">
                      <b>Joana Pardal</b>
                      <p>ULS Braga</p>
                    </FlexInRow>
                  </FlexInColumn>
                </FlexInRow>
              </FlexInRow>
            </div>

            <FlexInColumn gap="1.042vw">
              <h2 className="team__title">Conselho Fiscal</h2>
              <div className="bar"></div>
            </FlexInColumn>

            <div className="team__items">
              <FlexInRow gap="1.042vw">
                <FlexInRow gap="0.521vw" className="item">
                  <h3 className="item__title">Presidente</h3>
                  <FlexInColumn gap="1.042vw">
                    <picture className="item__image">
                      <source
                        srcSet={require("../../../Assets/Images/Miguel-Rito.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/Miguel-Rito.png")}
                        alt="Miguel Rito"
                      />
                    </picture>
                    <FlexInRow gap="0.521vw" className="orgaos">
                      <b>Miguel Rito</b>
                      <p>IPO Lisboa</p>
                    </FlexInRow>
                  </FlexInColumn>
                </FlexInRow>
              </FlexInRow>
              <FlexInRow gap="1.042vw">
                <FlexInRow gap="0.521vw" className="item">
                  <h3 className="item__title">Vogal</h3>
                  <FlexInColumn gap="1.042vw">
                    <picture className="item__image">
                      <source
                        srcSet={require("../../../Assets/Images/foto-andre-coelho.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/foto-andre-coelho.png")}
                        alt="Daniel Pinto"
                      />
                    </picture>
                    <FlexInRow gap="0.521vw" className="orgaos">
                      <b>André Coelho</b>
                      <p>ULS Santo António, Porto</p>
                    </FlexInRow>
                  </FlexInColumn>
                </FlexInRow>
              </FlexInRow>
              <FlexInRow gap="1.042vw">
                <FlexInRow gap="0.521vw" className="item">
                  <h3 className="item__title">Vogal</h3>
                </FlexInRow>
                <FlexInRow gap="0.521vw" className="item">
                  <FlexInColumn gap="1.042vw">
                    <picture className="item__image">
                      <source
                        srcSet={require("../../../Assets/Images/Raquel-Neves.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/Raquel-Neves.png")}
                        alt="Rui Caetano Oliveira"
                      />
                    </picture>
                    <FlexInRow gap="0.521vw" className="orgaos">
                      <b>Raquel Neves</b>
                      <p>
                        ULS Matosinhos, Porto
                      </p>
                    </FlexInRow>
                  </FlexInColumn>
                </FlexInRow>
              </FlexInRow>
            </div>
          </FlexInRow>

          <SectionMembers>
            <SectionMembersContent>
              <SectionMembersImage>
                <picture>
                  <source
                    srcSet={require("../../../Assets/Images/img-contacte-nos-footer@2x.png")}
                    media="(min-width: 990px)"
                  />
                  <img
                    src={require("../../../Assets/Images/img-contacte-nos-footer.png")}
                    alt="Seja um Membro"
                  />
                </picture>
              </SectionMembersImage>
              <SectionMembersTitle>Contacte-nos</SectionMembersTitle>
              <SectionMembersText>
                Para algum assunto relacionado com a SPAP, adesões de sócios e
                outros temas, envie-nos uma mensagem.
              </SectionMembersText>
              <SectionMembersCta>
                <Link to="/spap/contactos">Saber mais »</Link>
              </SectionMembersCta>
            </SectionMembersContent>
          </SectionMembers>
        </FlexInRow>
      </Container>

      <RecommendedLinks
        title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
        links={[
          {
            title: "História",
            link: "/spap/historia",
          },
          {
            title: "Órgãos sociais",
            link: "/spap/orgaos-sociais",
          },
          {
            title: "Bolsas e prémios",
            link: "/spap/bolsas-e-premios",
          },
          {
            title: "Vantagens de ser sócio",
            link: "/socios/conheca-as-vantagens",
          },
        ]}
      />
    </Wrapper>
  );
}
