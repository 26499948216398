import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Space,
  Pagination,
  Button,
  Alert,
  Modal,
  notification,
  Row,
  Col,
} from "antd";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";
import Title from "../../../Components/Backoffice/Title";
import axios from "axios";

import { ReactComponent as IconOk } from "../../../Assets/Images/Backoffice/Ok.svg";
import { ReactComponent as IconClose } from "../../../Assets/Images/Backoffice/Close.svg";

const Wrapper = styled.section`
  margin-top: 60px;
`;

const CardContent = styled.div`
  background-color: #d6d7e2;
  border-radius: 16px;
  margin-top: 15px;
  padding: 28px;
`;

const Grid = styled.div`
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 20px;

  .item {
    background-color: #FFFFFF;
    border: none;
    color: #8688A8;
  }

  .ant-alert-message {
    color: #06152B;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0px;
  }

  .ant-alert-close-icon {
    font-size: 12px;
    color: #384455;
  }

  .item .message {
    margin-bottom: 18px;
  }

  .item .icon {
    border: 2px solid #D9E1E7;
    border-radius: 360px;
    cursor: pointer;
    transition; 3s ease;
  }

  .item .icon:hover {
    background-color: #D9E1E7;
  }

  .item .icon path {
    fill: #809FB8;
  }

  .item .icon:hover path {
    fill: #FEFEFE;
  }  
`;

const ContentFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    display: block !important;
    font-size: 15px;
    font-weight: bold;
    height: 45px;
    line-height: 18px;

    i {
      margin: 0 3px;
    }
  }

  a {
    text-decoration: none;
  }
`;

export default function Warnings() {
  const session_storage = JSON.parse(localStorage.getItem("session_data"));

  const [warnings, setWarnings] = useState([]);
  const [users, setUsers] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);

  const getWarnings = (data) => {
    let ListWarnings = [];

    let usersToApprove = data.users.filter((user) => {
      if (
        user.tipo_utilizador == "socio_nao_aprovado_especialista" ||
        user.tipo_utilizador == "socio_nao_aprovado_interno"
      ) {
        return user;
      }
    });

    console.log(usersToApprove);
    if (usersToApprove) {
      usersToApprove.forEach((user) => {
        let newWarning = {};
        newWarning.type = "newsocio";
        newWarning.action = true;
        newWarning.nome = user.name;
        newWarning.email = user.email;
        newWarning.message = (
          <Space.Compact direction="vertical" className="message">
            <span>
              <b>Nome: </b>
              {user.nome_completo ? user.nome_completo : user.name}
            </span>
            <span>
              <b>Email: </b>
              {user.email}
            </span>
          </Space.Compact>
        );
        ListWarnings.push(newWarning);
      });
    }

    setUsers(data.users);
    setWarnings(ListWarnings);
    setAllItems(ListWarnings);
    setCurrentItems(ListWarnings.slice(0, 3));
  };

  useEffect(() => {
    fetch(`https://api.spap.pt/api/all-warnings`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data.warnings.length === 0) {
          return;
        }

        getWarnings(data);
      })
      .catch((error) => console.error(error));
  }, []);

  const [openApproveUser, setOpenApproveUser] = useState(false);
  const [openDeclineUser, setOpenDeclineUser] = useState(false);
  const [userModal, setUserModal] = useState("");
  const [approveUser, setApproveUser] = useState(false);
  const [warningToUpdate, setWarningToUpdate] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 3;

  const handlePage = (page) => {
    const newOffset = ((page - 1) * itemsPerPage) % allItems.length;
    setItemOffset(newOffset);
  };

  const hideModal = () => {
    setOpenApproveUser(false);
    setOpenDeclineUser(false);
  };

  const atualizaSocio = () => {
    let emailToUpdate = userModal.email;
    let nomeToUpdate = userModal.name;

    axios
      .post(
        "https://api.spap.pt/api/admin/update-socio-status",
        {
          emailToUpdate,
          approveUser,
          warningToUpdate,
          nomeToUpdate,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        notification.success({
          description:
            'O estado do utilizador "' +
            nomeToUpdate +
            '" foi atualizado com sucesso.',
        });
        getWarnings(res.data);
        console.log(res.data);
        hideModal();
      })
      .catch((error) => {});
    hideModal();
  };

  const showModalConfirm = (email, nome, estado, warningId) => {
    let thisUser = users.filter((user) => {
      return user.email == email;
    });
    setUserModal(thisUser[0]);
    setApproveUser(estado);
    setWarningToUpdate(warningId);
    estado == true ? setOpenApproveUser(true) : setOpenDeclineUser(true);
  };

  const handleAlert = (id) => {
    const newArray = allItems;
    const newArrayItems = newArray.filter((elem) => elem.id !== id);
    setAllItems(newArrayItems);
  };

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(allItems.slice(itemOffset, endOffset));
  }, [itemOffset, allItems]);

  return (
    allItems.length > 0 && (
      <Wrapper>
        <Title>Warnings:</Title>
        <CardContent>
          <Grid>
            {currentItems.map((item, index) => {
              return (
                <Alert
                  key={"alerta-" + item.id}
                  message={
                    item.type === "success"
                      ? "Sucesso."
                      : item.type === "error"
                      ? "Erro."
                      : item.type === "newsocio"
                      ? "Nova adesão a sócio."
                      : "Aviso."
                  }
                  description={
                    item.action ? (
                      <Space.Compact direction="vertical">
                        {item.message}
                        <Space>
                          <b>Ações:</b>
                          <Space>
                            <IconOk
                              key={"icon-accept-" + item.id}
                              className="icon icon-ok"
                              onClick={() =>
                                showModalConfirm(
                                  item.email,
                                  item.nome,
                                  true,
                                  item.id
                                )
                              }
                            />{" "}
                            Aprovar
                          </Space>
                          <Space>
                            <IconClose
                              key={"icon-decline-" + item.id}
                              className="icon icon-close"
                              onClick={() =>
                                showModalConfirm(
                                  item.email,
                                  item.nome,
                                  false,
                                  item.id
                                )
                              }
                            />{" "}
                            Declinar
                          </Space>
                        </Space>
                      </Space.Compact>
                    ) : (
                      item.message
                    )
                  }
                  type={
                    item.type === "success"
                      ? "success"
                      : item.type === "error"
                      ? "error"
                      : "warning"
                  }
                  showIcon
                  closable
                  // afterClose={() => handleAlert(item.id)}
                  className={"item item-" + index}
                />
              );
            })}
          </Grid>
          <ContentFooter>
            <Pagination
              defaultCurrent={1}
              total={allItems.length}
              pageSize={itemsPerPage}
              onChange={handlePage}
            />
          </ContentFooter>
        </CardContent>

        <Modal
          title="Nova adesão a sócio"
          open={openApproveUser}
          onOk={atualizaSocio}
          onCancel={hideModal}
          okText="Confirmar"
          cancelText="Cancelar"
        >
          <p>
            Tem a certeza que pretende aceitar a adesão do utilizador{" "}
            <b>{userModal.name}</b> a sócio?
          </p>
          <Row
            gutter={[12, 18]}
            style={{
              padding: "20px",
              borderRadius: "10px",
              border: "2px solid rgb(73, 77, 125)",
              flexDirection: "column",
            }}
          >
            <div>
              <span>
                <b>Email: </b>
              </span>{" "}
              {userModal.email}
            </div>
            <div>
              <span>
                <b>Nome Completo: </b>
              </span>{" "}
              {userModal.nome_completo}
            </div>
            <div>
              <span>
                <b>Telefone: </b>
              </span>{" "}
              {userModal.telefone}
            </div>
            <div>
              <span>
                <b>Instituição: </b>
              </span>{" "}
              {userModal.instituicao}
            </div>
            <div>
              <span>
                <b>Cidade: </b>
              </span>{" "}
              {userModal.cidade}
            </div>
            <div>
              <span>
                <b>Nº. de Célula Profissional: </b>
              </span>{" "}
              {userModal.numero_celula}
            </div>
            <div>
              <span>
                <b>Sócio(s) Proponente(s): </b>
              </span>{" "}
              {userModal.socio_titular}
            </div>
          </Row>
        </Modal>
        <Modal
          width={500}
          className="modal-decline-user"
          title="Nova adesão a sócio"
          open={openDeclineUser}
          onOk={atualizaSocio}
          onCancel={hideModal}
          okText="Confirmar"
          cancelText="Cancelar"
        >
          <p>
            Tem a certeza que pretende rejeitar a adesão do utilizador{" "}
            <b>{userModal.name}</b> a sócio?
          </p>
          <Row
            gutter={[12, 18]}
            style={{
              padding: "20px",
              borderRadius: "10px",
              border: "2px solid rgb(73, 77, 125)",
              flexDirection: "column",
            }}
          >
            <div>
              <span>
                <b>Email: </b>
              </span>{" "}
              {userModal.email}
            </div>
            <div>
              <span>
                <b>Nome Completo: </b>
              </span>{" "}
              {userModal.nome_completo}
            </div>
            <div>
              <span>
                <b>Telefone: </b>
              </span>{" "}
              {userModal.telefone}
            </div>
            <div>
              <span>
                <b>Instituição: </b>
              </span>{" "}
              {userModal.instituicao}
            </div>
            <div>
              <span>
                <b>Cidade: </b>
              </span>{" "}
              {userModal.cidade}
            </div>
            <div>
              <span>
                <b>Nº. de Célula Profissional: </b>
              </span>{" "}
              {userModal.numero_celula}
            </div>
            <div>
              <span>
                <b>Sócio(s) Proponente(s): </b>
              </span>{" "}
              {userModal.socio_titular}
            </div>
          </Row>
        </Modal>
      </Wrapper>
    )
  );
}
