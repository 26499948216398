import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
//import { Collapse } from 'react-collapse';
import { Helmet } from "react-helmet";
import Container from "../../../../Components/Container";
import PageTitle from "../../../../Components/PageTitle";
import PageText from "../../../../Components/PageText";
import FlexInColumn from "../../../../Components/FlexInColumn";
import FlexInRow from "../../../../Components/FlexInRow";
import SidebarMenu from "../../../../Components/SidebarMenu";
import { Collapse } from "antd";
const { Panel } = Collapse;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin: 7.813vw 0 5.208vw;

  img {
    width: 100%;
  }

  .link {
    color: ${(props) => props.theme.orange};
    font-weight: bold;
    place-self: flex-start;
    transition: 0.3s ease;
    text-align: left;
  }

  .link:hover {
    color: ${(props) => props.theme.orangeHover};
  }

  .link .icon {
    color: ${(props) => props.theme.blue};
    margin-right: 5px;
  }

  .link:hover .icon {
    color: ${(props) => props.theme.blueHover};
  }

  @media (max-width: 992px) {
    margin-top: 120px;
  }
`;

const Text = styled.p`
  color: #000000;
  font-size: 0.938vw;
  letter-spacing: 0px;
  line-height: 1.25vw;
  margin: 0;
  text-align: left;

  a {
    color: ${(props) => props.theme.orange};
    display: initial;
    transition: 0.3s ease;
  }

  a:hover {
    color: ${(props) => props.theme.orangeHover};
  }

  @media (max-width: 992px) {
    font-size: 3.333vw;
    line-height: 4.444vw;
  }
`;

const SectionCollapseContent = styled.section`
  background-color: #97c1d5;

  .ant-collapse-expand-icon {
    place-self: center;

    svg {
      font-size: 18px;
    }
  }

  .ant-collapse-header-text {
    text-align: left;
    font-size: 1.25vw;
    font-weight: bold;
    line-height: inherit;
    letter-spacing: 0px;
    color: #287092;
    padding: 1.25vw 1.042vw;
    cursor: pointer;

    .icon {
      transition: 0.3s ease;
    }

    @media (max-width: 992px) {
      font-size: 3.333vw;
      line-height: 4.444vw;
    }

    @media (min-width: 1920px) {
      font-size: 18px;
      line-height: 22px;
      padding: 20px;
    }
  }

  .ant-collapse-content {
    background-color: #cbe0ea;
    text-align: left;
    font-size: 0.938vw;
    font-weight: bold;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;

    .ant-collapse-content-box {
      padding: 1.563vw 3.333vw;
    }

    @media (max-width: 992px) {
      font-size: 2.333vw;
      line-height: 2.444vw;
    }

    @media (min-width: 1920px) {
      font-size: 14px;
      line-height: 18px;

      .ant-collapse-content-box {
        padding: 30px;
      }
    }
  }
`;

const CCText = styled.div``;

const CCList = styled.ul`
  li {
    text-align: left;
    font-size: 0.938vw;
    font-weight: bold;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
  }

  @media (max-width: 992px) {
    li {
      font-size: 3.333vw;
      line-height: 4.444vw;
    }
  }
`;

export default function Estagios(props) {
  const SidebarMenuLinks = [
    {
      title: "Estágios",
      link: "/nispap/utilitarios/estagios",
    },
    {
      title: "Reuniões interserviços",
      link: "/nispap/utilitarios/reunioes-interservicos",
    },
    {
      title: "Links úteis",
      link: "/nispap/utilitarios/links-uteis",
    },
  ];

  const [open, setOpen] = useState(false);

  const onChange = (key) => {
    console.log(key);
  };

  const listEstagios = [
    {
      title: "Área de Especialização: Citologia, Patologia da mama",
      text: (
        <>
          <p>
            Instituição e país: Institute Curie, Paris, França                  {" "}
            <p>Avaliação global do estágio: 4</p>                 {" "}
            <p>
              Especialista Orientador: Anne Vincent-Salomon e Jerzy Klijanienko
            </p>
                              <p>Língua: Francês</p>                 {" "}
            <p>Duração (meses): 3</p>                 {" "}
            <p>Antecedência com que se marcou o estágio: 6 meses</p>           
                 {" "}
            <p>
              Modo de contacto: E-mail publicamente disponível (especialista /
              instituição)
            </p>
                              <p>Financiamento: Conta própria</p>               
             {" "}
            <p>
              Pessoa com quem se dava saídas dos casos (maioritariamente): Era
              variável, com o especialista e o grupo do especialista
            </p>
                             {" "}
            <p>
              Formato da observação de casos: Observação de casos
              simultaneamente com o especialista/ Preparação prévia de casos e
              saídas posteriormente (sem escrita de relatório) / Preparação
              prévia de casos e saídas posteriormente (com escrita de relatório)
            </p>
                             {" "}
            <p>
              Atividades desenvolvidas durante o estágio: Observação de casos de
              rotina e de coleção/ Escrita{" "}
            </p>
            científica/ Participação em reuniões
          </p>
                           {" "}
          <p>
            Tipo de reuniões habituais: Multidisciplinares / Decisão
            Terapêutica/ Lâminas / Casos clínicos
          </p>
                            <p>Número de reuniões semanais: 4</p>               
           {" "}
          <p>
            Produção científica: Desenvolvi um projeto (ideia do especialista)
          </p>
                           {" "}
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 4
          </p>
                           {" "}
          <p>
            Estímulo para realização de comunicações orais, posters ou capítulos
            de livros: 2
          </p>
                           {" "}
          <p>Oportunidade de frequentar cursos, congressos ou workshops: Sim</p>
                           {" "}
          <p>
            Outros comentários: Participei num curso de mama de 3 dias que a
            especialista coordenou
          </p>
                           {" "}
          <p>Alojamento: Alojamento local (AirBNB, classificados, etc.)</p>     
                      <p>Facilidade de encontrar alojamento: 3</p>             
              <p>Custo mensal: 750 Euros</p>                 {" "}
          <p>Sessões / eventos sociais: Não</p>                 {" "}
          <p>Acolhimento pela instituição: 4</p>                 {" "}
          <p>
            Comentários acerca do custo e da qualidade de vida: custo aceitável,
            boa qualidade de vida.
          </p>
                           {" "}
          <p>Quantidade/qualidade de tempo passado com o especialista: 3</p>   
                       {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 4
          </p>
                           {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 4
          </p>
        </>
      ),
    },
    {
      title: "Área de Especialização: Patologia genitourinária",
      text: (
        <>
          <p>
            Instituição e país: Addenbrooke's Hospital – Cambridge, Reino Unido
          </p>
                            <p>Avaliação global do estágio: 4</p>               
            <p>Especialista Orientador: Anne Warren</p>                 {" "}
          <p>Língua: Inglês</p>                  <p>Duração (meses): 1</p>     
                      <p>Antecedência com que se marcou o estágio: 6 meses</p> 
                         {" "}
          <p>Modo de contacto: Por intermédio de um especialista</p>           
                <p>Financiamento: Conta própria</p>                 {" "}
          <p>Número de casos de diagnóstico observados: 100</p>                 {" "}
          <p>
            Pessoa com quem se dava saídas dos casos (maioritariamente): Era
            variável, com o especialista e o grupo do especialista
          </p>
                           {" "}
          <p>
            Formato da observação de casos: Observação de casos simultaneamente
            com o especialista/ Preparação prévia de casos e saídas
            posteriormente (sem escrita de relatório)
          </p>
                           {" "}
          <p>
            Atividades desenvolvidas durante o estágio: Observação de casos de
            rotina e de coleção/ Participação em reuniões
          </p>
                           {" "}
          <p>
            {" "}
            de reuniões habituais: Multidisciplinares / Decisão Terapêutica/
            Lâminas / Casos clínicos, Journal Club
          </p>
                            <p>Número de reuniões semanais: 5</p>               
            <p>Produção científica: Não participei em produção científica</p>   
                       {" "}
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 2
          </p>
                           {" "}
          <p>
            Estímulo para realização de comunicações orais, posters ou capítulos
            de livros: 1
          </p>
                           {" "}
          <p>
            Oportunidade de frequentar cursos, congressos ou workshops: Não
            frequentei
          </p>
                           {" "}
          <p>Alojamento: Alojamento local (AirBNB, classificados, etc.)</p>     
                      <p>Facilidade de encontrar alojamento: 4</p>             
              <p>Custo mensal do alojamento: 2000</p>                 {" "}
          <p>Sessões / eventos sociais: Não</p>                 {" "}
          <p>Acolhimento pela instituição: 3</p>                 {" "}
          <p>Quantidade/qualidade de tempo passado com o especialista: 2</p>   
                       {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 3
          </p>
                           {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 1
          </p>
        </>
      ),
    },
    {
      title: "Área de Especialização: Patologia hepática",
      text: (
        <>
          <p>Instituição e país: Instituto de Patologia – Basileia, Suiça</p>   
                        <p>Avaliação global do estágio: 5</p>                 {" "}
          <p>Especialista Orientador: prof. Doutor Luigi Terracciano</p>       
                    <p>Língua: Inglês</p>                 {" "}
          <p>Duração do Estágio (meses): 3</p>                 {" "}
          <p>
            Antecedência com que se marcou o estágio: Cerca de um ano e meio
          </p>
                           {" "}
          <p>
            Modo de contacto: E-mail publicamente disponível (especialista /
            instituição)
          </p>
                            <p>Financiamento: Giordano Fellowship</p>           
                <p>Número de casos de diagnóstico observados: 200</p>           
               {" "}
          <p>
            Pessoa com quem se dava saídas dos casos (maioritariamente): O
            especialista responsável pelo estágio
          </p>
                           {" "}
          <p>
            Formato da observação de casos: Observação de casos simultaneamente
            com o especialista/ Preparação prévia de casos e saídas
            posteriormente (sem escrita de relatório)
          </p>
                           {" "}
          <p>
            Atividades desenvolvidas durante o estágio: Observação de casos de
            rotina e de coleção/ Participação em reuniões
          </p>
                           {" "}
          <p>
            Tipo de reuniões habituais: Multidisciplinares / Decisão
            Terapêutica/ Lâminas / Casos clínicos/ Journal Club
          </p>
                            <p>Número de reuniões semanais: 4</p>               
           {" "}
          <p>
            Produção científica: Participei num projeto a decorrer no serviço,
            Desenvolvi um projeto (ideia pessoal)
          </p>
                           {" "}
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 4
          </p>
                           {" "}
          <p>
            Estímulo para realização de comunicações orais, posters ou capítulos
            de livros: 4
          </p>
                           {" "}
          <p>Oportunidade de frequentar cursos, congressos ou workshops: Sim</p>
                           {" "}
          <p>Alojamento: Alojamento local (AirBNB, classificados, etc.)</p>     
                      <p>Facilidade de encontrar alojamento: 2</p>             
              <p>Custo mensal do alojamento: 700</p>                 {" "}
          <p>Sessões / eventos sociais: Não</p>                 {" "}
          <p>Acolhimento pela instituição: 4</p>                 {" "}
          <p>
            Comentários acerca do custo e da qualidade de vida: Era um país com
            um custo de vida muito elevado. A Giordano Fellowship ajudou, mas
            mesmo assim foi necessário "ginástica financeira"
          </p>
                           {" "}
          <p>Quantidade/qualidade de tempo passado com o especialista: 4</p>   
                       {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 4
          </p>
                           {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 4
          </p>
        </>
      ),
    },
    {
      title: "Área de Especialização: Neuropatologia e Patologia do músculo",
      text: (
        <>
          <p>Instituição e país: UCL, Reino Unido</p>                 {" "}
          <p>Avaliação global do estágio: 4</p>                 {" "}
          <p>Especialista Orientador: Janice Holton</p>                 {" "}
          <p>Língua: Inglês</p>                 {" "}
          <p>Duração do Estágio (meses): 2</p>                 {" "}
          <p>Antecedência com que se marcou o estágio: 8 meses</p>             
              <p>Modo de contacto: Por intermédio de um especialista</p>       
                    <p>Financiamento: Conta própria</p>                 {" "}
          <p>Número de casos de diagnóstico observados: 260</p>                 {" "}
          <p>
            Pessoa com quem se dava saídas dos casos (maioritariamente): O
            especialista responsável pelo estágio
          </p>
                           {" "}
          <p>
            Formato da observação de casos: Preparação prévia de casos e saídas
            posteriormente (sem escrita de relatório)
          </p>
                           {" "}
          <p>
            Atividades desenvolvidas durante o estágio: Observação de casos de
            rotina e de coleção/ Participação em reuniões
          </p>
                           {" "}
          <p>
            Tipo de reuniões habituais: Multidisciplinares / Decisão
            Terapêutica/ Lâminas / Casos clínicos
          </p>
                            <p>Número de reuniões semanais: 5</p>               
            <p>Produção científica: Não participei em produção científica</p>   
                       {" "}
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 3
          </p>
                           {" "}
          <p>
            Estímulo para realização de comunicações orais, posters ou capítulos
            de livros: 1
          </p>
                           {" "}
          <p>Oportunidade de frequentar cursos, congressos ou workshops: Sim</p>
                           {" "}
          <p>Alojamento: Alojamento local (AirBNB, classificados, etc.)</p>     
                      <p>Facilidade de encontrar alojamento: 3</p>             
              <p>Custo mensal do alojamento: 1100</p>                 {" "}
          <p>Sessões / eventos sociais: livre agregação</p>                 {" "}
          <p>Acolhimento pela instituição: 3</p>                 {" "}
          <p>Quantidade/qualidade de tempo passado com o especialista: 3</p>   
                       {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 4
          </p>
                           {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 2
          </p>
        </>
      ),
    },
    {
      title: "Área de Especialização: patologia genitourinária",
      text: (
        <>
          <p>Instituição e país: Johns Hopkins Hospital, EUA</p>               
            <p>Avaliação global do estágio: 5</p>                 {" "}
          <p>Especialista Orientador: Dr. Jonathan I. Epstein</p>               
            <p>Língua: Inglês</p>                 {" "}
          <p>Duração do Estágio (meses): 2</p>                 {" "}
          <p>Antecedência com que se marcou o estágio: 1 ano</p>               
            <p>Modo de contacto: Por intermédio de um especialista</p>         
                  <p>Financiamento: Conta própria</p>                 {" "}
          <p>Número de casos de diagnóstico observados: 1200</p>               
           {" "}
          <p>
            Pessoa com quem se dava saídas dos casos (maioritariamente): O
            especialista responsável pelo estágio
          </p>
                           {" "}
          <p>
            Formato da observação de casos: Observação de casos simultaneamente
            com o especialista/ Preparação prévia de casos e saídas
            posteriormente (sem escrita de relatório)
          </p>
                           {" "}
          <p>
            Atividades desenvolvidas durante o estágio: Observação de casos de
            rotina e de coleção/ Escrita científica/ Participação em reuniões
          </p>
                           {" "}
          <p>
            Tipo de reuniões habituais: Multidisciplinares / Decisão
            Terapêutica, Lâminas / Casos clínicos/ Journal Club/ Controlo de
            qualidade
          </p>
                            <p>Número de reuniões semanais: 7</p>               
           {" "}
          <p>
            Produção científica: Participei num projeto a decorrer no serviço
          </p>
                           {" "}
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 3
          </p>
                           {" "}
          <p>
            Estímulo para realização de comunicações orais, posters ou capítulos
            de livros: 2
          </p>
                           {" "}
          <p>
            Oportunidade de frequentar cursos, congressos ou workshops: Não
            aplicável no período de estágio
          </p>
                           {" "}
          <p>Alojamento: Alojamento local (AirBNB, classificados, etc.)</p>     
                      <p>Facilidade de encontrar alojamento: 2</p>             
              <p>Custo mensal do alojamento: 1000</p>                 {" "}
          <p>Sessões / eventos sociais: Não</p>                 {" "}
          <p>Acolhimento pela instituição: 4</p>                 {" "}
          <p>Quantidade/qualidade de tempo passado com o especialista: 4</p>   
                       {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 4
          </p>
                           {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 2
          </p>
        </>
      ),
    },
    {
      title: "Área de Especialização: Patologia torácica",
      text: (
        <>
          <p>Instituição e país do Estágio: Royal Brompton, Reino Unido</p>     
                      <p>Avaliação global do estágio: 4</p>                 {" "}
          <p>Especialista Orientador: Prof. Andrew Nicholson</p>               
            <p>Língua do Estágio: Inglês</p>                 {" "}
          <p>Duração do Estágio (meses): 2</p>                 {" "}
          <p>Antecedência com que se marcou o estágio: Cerca de 1-2anos</p>     
                     {" "}
          <p>
            Modo de contacto: E-mail publicamente disponível (especialista /
            instituição)
          </p>
                            <p>Financiamento: Conta própria</p>                 {" "}
          <p>Número de casos de diagnóstico observados: 222</p>                 {" "}
          <p>
            Pessoa com quem se dava saídas dos casos (maioritariamente): O
            especialista responsável pelo estágio
          </p>
                           {" "}
          <p>
            Formato da observação de casos: Observação de casos simultaneamente
            com o especialista/ Preparação prévia de casos e saídas
            posteriormente (sem escrita de relatório)
          </p>
                           {" "}
          <p>
            Atividades desenvolvidas durante o estágio: Observação de casos de
            rotina e de coleção/ Escrita científica/ Participação em reuniões
          </p>
                           {" "}
          <p>
            Tipo de reuniões habituais: Multidisciplinares / Decisão Terapêutica
          </p>
                            <p>Número de reuniões semanais: 2</p>               
            <p>Estímulo para a participação ativa em reuniões: 4</p>           
               {" "}
          <p>
            Produção científica: Participei num projeto a decorrer no serviço
          </p>
                           {" "}
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 4
          </p>
                           {" "}
          <p>
            Estímulo para realização de comunicações orais, posters ou capítulos
            de livros: 4
          </p>
                           {" "}
          <p>
            Outros comentários: Frequentei o curso de patologia torácica
            lecionado todos anos pela instituição. Arquivo muito extenso e
            completo digital e em lâminas. Tratam de toda a patologia torácica
            neoplásica e não neoplásica.
          </p>
                           {" "}
          <p>
            Alojamento: Residência hospitalar, Residência estudantil, Hotel,
            Alojamento local (AirBNB, classificados, etc.)
          </p>
                            <p>Financiamento: Não</p>                 {" "}
          <p>Facilidade de encontrar alojamento: 5</p>                 {" "}
          <p>Custo mensal do alojamento: 800 (fiquei em casa de amigos)</p>     
                      <p>Sessões / eventos sociais: Não</p>                 {" "}
          <p>Acolhimento pela instituição: 3</p>                 {" "}
          <p>
            Comentários acerca do custo e da qualidade de vida: O custo de vida
            em Londres é muito elevado e o hospital fica no centro da cidade
            tornando os custos maiores.
          </p>
                           {" "}
          <p>Quantidade/qualidade de tempo passado com o especialista: 4</p>   
                       {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 4
          </p>
                           {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 3
          </p>
        </>
      ),
    },
    {
      title:
        "Área de Especialização: Biologia molecular, Patologia do sistema reprodutor feminino, e Patologia da mama",
      text: (
        <>
          <p>Instituição e país do Estágio: Institut Bergoni, França</p>       
                    <p>Avaliação global do estágio: 5</p>                 {" "}
          <p>Especialista Orientador: Gaetan Macgrogan</p>                 {" "}
          <p>Língua: Inglês</p>                 {" "}
          <p>Duração do estágio (meses): 2</p>                 {" "}
          <p>Antecedência com que se marcou o estágio: Variável</p>             
              <p>Modo de contacto: Por intermédio de um especialista</p>       
                   {" "}
          <p>Financiamento: Conta própria, a minha instituição formativa</p>   
                       {" "}
          <p>
            Pessoa com quem se dava saídas dos casos (maioritariamente): Era
            variável, com o especialista e o grupo do especialista
          </p>
                           {" "}
          <p>
            Formato da observação de casos: Preparação prévia de casos e saídas
            posteriormente (sem escrita de relatório)
          </p>
                           {" "}
          <p>
            Atividades desenvolvidas durante o estágio: Observação de casos de
            rotina e de coleção/ Participação em reuniões
          </p>
                           {" "}
          <p>
            Tipo de reuniões habituais: Multidisciplinares / Decisão
            Terapêutica; Lâminas/ Casos clínicos
          </p>
                            <p>Número de reuniões semanais: 1</p>               
            <p>Estímulo para a participação ativa em reuniões: 4</p>           
               {" "}
          <p>Produção científica: Não participei em produção científica</p>     
                     {" "}
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 2
          </p>
                           {" "}
          <p>
            Estímulo para realização de comunicações orais, posters ou capítulos
            de livros: 2
          </p>
                           {" "}
          <p>Oportunidade de frequentar cursos, congressos ou workshops: Sim</p>
                           {" "}
          <p>
            Alojamento: Residência hospitalar, Residência estudantil, Alojamento
            local (AirBNB, classificados, etc.)
          </p>
                            <p>Financiamento: Não</p>                 {" "}
          <p>Facilidade de encontrar alojamento: 4</p>                 {" "}
          <p>Custo mensal do alojamento: 400</p>                 {" "}
          <p>Sessões / eventos sociais: Sim</p>                 {" "}
          <p>Acolhimento pela instituição: 4</p>                 {" "}
          <p>Quantidade/qualidade de tempo passado com o especialista: 4</p>   
                       {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 4
          </p>
                           {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 2
          </p>
        </>
      ),
    },
    {
      title: "Área de Especialização: Patologia do tubo digestivo",
      text: (
        <>
          <p>Instituição e páis: Brigham and Women's Hospital, EUA</p>         
                  <p>Avaliação global do estágio: 5</p>                 {" "}
          <p>Especialista Orientador: Robert Odze</p>                 {" "}
          <p>Língua: inglês</p>                 {" "}
          <p>Duração do Estágio (meses): 3</p>                 {" "}
          <p>Antecedência com que se marcou o estágio: No mínimo 1 ano</p>     
                     {" "}
          <p>Modo de contacto: Contacto pessoal em cursos / congressos</p>     
                      <p>Financiamento: Conta própria</p>                 {" "}
          <p>Número de casos de diagnóstico observados: 4000</p>               
           {" "}
          <p>
            Pessoa com quem se dava saídas dos casos (maioritariamente): O
            especialista responsável pelo estágio
          </p>
                           {" "}
          <p>
            Formato da observação de casos: Preparação prévia de casos e saídas
            posteriormente (sem escrita de relatório)
          </p>
                           {" "}
          <p>
            Atividades desenvolvidas durante o estágio: Observação de casos de
            rotina e de coleção/ Participação em reuniões
          </p>
                           {" "}
          <p>
            Tipo de reuniões habituais: Multidisciplinares / Decisão
            Terapêutica/ Lâminas / Casos clínicos/ Journal Club
          </p>
                            <p>Número de reuniões semanais: 7</p>               
            <p>Estímulo para a participação ativa em reuniões: 4</p>           
               {" "}
          <p>Produção científica: Não participei em produção científica</p>     
                     {" "}
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 3
          </p>
                           {" "}
          <p>
            Estímulo para realização de comunicações orais, posters ou capítulos
            de livros: 3
          </p>
                           {" "}
          <p>Oportunidade de frequentar cursos, congressos ou workshops: Sim</p>
                           {" "}
          <p>Alojamento: Alojamento local (AirBNB, classificados, etc.)</p>     
                      <p>Facilidade de encontrar alojamento: 2</p>             
              <p>Custo mensal do alojamento: mais de 2000 euros por mês</p>     
                      <p>Sessões / eventos sociais: Sim</p>                 {" "}
          <p>Acolhimento pela instituição: 4</p>                 {" "}
          <p>Quantidade/qualidade de tempo passado com o especialista: 4</p>   
                       {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 4
          </p>
                           {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 3
          </p>
        </>
      ),
    },
    {
      title: "Área de Especialização: Patologia da mama",
      text: (
        <>
          <p>
            Instituição e país: Beth Israel Deaconess Medical Center, Boston,
            EUA
          </p>
                            <p>Avaliação global do estágio: 5</p>               
            <p>Especialista Orientador: Stuart Schnitt</p>                 {" "}
          <p>Língua: Inglês</p>                 {" "}
          <p>Duração do Estágio (meses): 1</p>                 {" "}
          <p>Antecedência com que se marcou o estágio: 6 meses - 1ano</p>       
                    <p>Modo de contacto: Por intermédio de um especialista</p> 
                          <p>Financiamento: Conta própria</p>                 {" "}
          <p>Número de casos de diagnóstico observados: 200</p>                 {" "}
          <p>
            Pessoa com quem se dava saídas dos casos (maioritariamente): O grupo
            do especialista responsável pelo estágio
          </p>
                           {" "}
          <p>
            Formato da observação de casos: Observação de casos simultaneamente
            com o especialista/ Preparação prévia de casos e saídas
            posteriormente (sem escrita de relatório)
          </p>
                           {" "}
          <p>
            Atividades desenvolvidas durante o estágio: Observação de casos de
            rotina e de coleção/ Participação em reuniões
          </p>
                           {" "}
          <p>
            Tipo de reuniões habituais: Multidisciplinares / Decisão
            Terapêutica/ Lâminas / Casos clínicos
          </p>
                            <p>Número de reuniões semanais: 4</p>               
            <p>Estímulo para a participação ativa em reuniões: 4</p>           
               {" "}
          <p>
            Produção científica: Não decorreu investigação científica na
            instituição
          </p>
                           {" "}
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 1
          </p>
                           {" "}
          <p>
            Estímulo para realização de comunicações orais, posters ou capítulos
            de livros: 1
          </p>
                           {" "}
          <p>Oportunidade de frequentar cursos, congressos ou workshops: Não</p>
                           {" "}
          <p>Alojamento: Alojamento local (AirBNB, classificados, etc.)</p>     
                      <p>Facilidade de encontrar alojamento: 3</p>             
              <p>Custo mensal do alojamento: 750</p>                 {" "}
          <p>Sessões / eventos sociais: Não</p>                 {" "}
          <p>Acolhimento pela instituição: 3</p>                 {" "}
          <p>Quantidade/qualidade de tempo passado com o especialista: 4</p>   
                       {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 4
          </p>
                           {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 1
          </p>
        </>
      ),
    },
    {
      title: "Área de Especialização: Patologia do tubo digestivo",
      text: (
        <>
          <p>Instituição e país: Medical University of Graz, Áustria</p>       
                    <p>Avaliação global do estágio: 5</p>                 {" "}
          <p>Especialista Orientador: Dr. Cord Langner</p>                 {" "}
          <p>Língua: Inglês</p>                 {" "}
          <p>Duração do Estágio (meses): 3</p>                 {" "}
          <p>
            Antecedência com que se marcou o estágio: Marquei com 2 anos de
            antecedência, mas será possível mais "em cima", mediante
            disponibilidade
          </p>
                           {" "}
          <p>Modo de contacto: Por intermédio de um especialista</p>           
                <p>Financiamento: Conta própria</p>                 {" "}
          <p>Número de casos de diagnóstico observados: 10000</p>               
           {" "}
          <p>
            Pessoa com quem se dava saídas dos casos (maioritariamente): O
            especialista responsável pelo estágio
          </p>
                           {" "}
          <p>
            Formato da observação de casos: Observação de casos simultaneamente
            com o especialista
          </p>
                           {" "}
          <p>
            Atividades desenvolvidas durante o estágio: Observação de casos de
            rotina e de coleção/ Escrita científica
          </p>
                           {" "}
          <p>Tipo de reuniões habituais: Não decorriam reuniões</p>             
             {" "}
          <p>
            Produção científica: Desenvolvi um projeto (ideia do especialista)
          </p>
                           {" "}
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 4
          </p>
                           {" "}
          <p>
            Estímulo para realização de comunicações orais, posters ou capítulos
            de livros: 3
          </p>
                           {" "}
          <p>Oportunidade de frequentar cursos, congressos ou workshops: Sim</p>
                           {" "}
          <p>
            Outros comentários: Participei no Encontro de Primavera da Sociedade
            Austríaca de Anatomia Patológica
          </p>
                            <p>Alojamento: Residência estudantil</p>           
                <p>Facilidade de encontrar alojamento: 4</p>                 {" "}
          <p>Custo mensal do alojamento: 600</p>                 {" "}
          <p>
            Comentários acerca do custo e da qualidade de vida: Fiquei alojado
            na residência estudantil MILESTONE; recomendo pela boa relação
            qualidade-preço. Fica a 20 minutos do hospital, de tram. O passe
            mensal tem um custo de aproximadamente 50€. Na Estíria e organização
            CINT Club Internacional dá apoio a estrangeiros a trabalhar em Graz
            e, bastando um contacto por e-mail, poderá recomendar outras opções
            de alojamento.
          </p>
          <p>Sessões / eventos sociais: Sim</p>                 {" "}
          <p>Acolhimento pela instituição: 3</p>                 {" "}
          <p>Quantidade/qualidade de tempo passado com o especialista: 4</p>   
                       {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 4
          </p>
                           {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 4
          </p>
        </>
      ),
    },
    {
      title: "Área de Especialização: Patologia da mama e Patologia  geral",
      text: (
        <>
          <p>Instituição e país: ACCamargo Câncer Center, Brasil</p>           
                <p>Avaliação global do estágio: 5</p>                 {" "}
          <p>Especialista Orientador: Dr. Felipe Costa</p>                 {" "}
          <p>Língua: Portuguesa</p>                 {" "}
          <p>Duração do Estágio (meses): 2</p>                 {" "}
          <p>Antecedência com que se marcou o estágio: 6 meses</p>             
              <p>Modo de contacto: Por intermédio de um especialista</p>       
                    <p>Financiamento: Conta própria</p>                 {" "}
          <p>Número de casos de diagnóstico observados: 1500</p>               
           {" "}
          <p>
            Pessoa com quem se dava saídas dos casos (maioritariamente): Era
            variável, com o especialista e o grupo do especialista
          </p>
                           {" "}
          <p>
            Formato da observação de casos: Preparação prévia de casos e saídas
            posteriormente (sem escrita de relatório)
          </p>
                           {" "}
          <p>
            Atividades desenvolvidas durante o estágio: Observação de casos de
            rotina e de coleção/ Participação em reuniões
          </p>
                           {" "}
          <p>
            Tipo de reuniões habituais: Multidisciplinares / Decisão
            Terapêutica, Lâminas / Casos clínicos, Lâminas de congelação
          </p>
                            <p>Número de reuniões semanais: 3</p>               
            <p>Estímulo para a participação ativa em reuniões: 4</p>           
               {" "}
          <p>Produção científica: Não participei em produção científica</p>     
                     {" "}
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 2
          </p>
                           {" "}
          <p>
            Estímulo para realização de comunicações orais, posters ou capítulos
            de livros: 2
          </p>
                           {" "}
          <p>Oportunidade de frequentar cursos, congressos ou workshops: Sim</p>
                           {" "}
          <p>Alojamento: Alojamento local (AirBNB, classificados, etc.)</p>     
                      <p>Facilidade de encontrar alojamento: 5</p>             
              <p>Custo mensal do alojamento: 500</p>                 {" "}
          <p>Sessões / eventos sociais: Sim</p>                 {" "}
          <p>Acolhimento pela instituição: 4</p>                 {" "}
          <p>Quantidade/qualidade de tempo passado com o especialista: 4</p>   
                       {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 4
          </p>
                           {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 3
          </p>
        </>
      ),
    },
    {
      title: "Área de Especialização: Patologia torácica",
      text: (
        <>
          <p>Instituição e país: Cleveland Clinic Foundation, EUA</p>           
                <p>Avaliação global do estágio: 4</p>                 {" "}
          <p>Especialista Orientador: Carol Farver, MD</p>                 {" "}
          <p>Língua: Inglês</p>                 {" "}
          <p>Duração do Estágio (meses): 2</p>                 {" "}
          <p>Antecedência com que se marcou o estágio: 6 meses</p>             
              <p>Modo de contacto: Contacto pessoal em cursos / congressos</p> 
                         {" "}
          <p>
            Financiamento: A minha instituição formativa/ Liga Portuguesa Contra
            o Cancro
          </p>
                           {" "}
          <p>Número de casos de diagnóstico observados: 600</p>                 {" "}
          <p>
            Pessoa com quem se dava saídas dos casos (maioritariamente): Era
            variável, com o especialista e o grupo do especialista
          </p>
                           {" "}
          <p>
            Formato da observação de casos: Preparação prévia de casos e saídas
            posteriormente (sem escrita de relatório)
          </p>
                           {" "}
          <p>
            Atividades desenvolvidas durante o estágio: Observação de casos de
            rotina e de coleção/ Escrita científica/ Participação em reuniões
          </p>
                           {" "}
          <p>
            Tipo de reuniões habituais: Multidisciplinares / Decisão
            Terapêutica/ Lâminas / Casos clínicos
          </p>
                            <p>Número de reuniões semanais: 7</p>               
            <p>Estímulo para a participação ativa em reuniões: 4</p>           
               {" "}
          <p>
            Produção científica: Participei num projeto a decorrer no serviço,
            desenvolvi um projeto (ideia pessoal)
          </p>
                           {" "}
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 3
          </p>
                           {" "}
          <p>
            Estímulo para realização de comunicações orais, posters ou capítulos
            de livros: 3
          </p>
                           {" "}
          <p>Oportunidade de frequentar cursos, congressos ou workshops: Sim</p>
                            <p>Alojamento: Residência estudantil</p>           
                <p>Facilidade de encontrar alojamento: 1</p>                 {" "}
          <p>Custo mensal do alojamento: 800</p>                 {" "}
          <p>Sessões / eventos sociais: Sim</p>                 {" "}
          <p>Acolhimento pela instituição: 3</p>                 {" "}
          <p>Quantidade/qualidade de tempo passado com o especialista: 3</p>   
                       {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 3
          </p>
                           {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 3
          </p>
        </>
      ),
    },
    {
      title: "Área de Especialização: Patologia do sistema reprodutor feminino",
      text: (
        <>
          <p>Instituição e país: MGH, EUA</p>                 {" "}
          <p>Avaliação global do estágio: 5</p>                 {" "}
          <p>Especialista Orientador: Esther Oliva</p>                 {" "}
          <p>Língua: Inglês</p>                 {" "}
          <p>Duração do Estágio (meses): 2</p>                 {" "}
          <p>Antecedência com que se marcou o estágio: 1,5/2 anos</p>           
                <p>Modo de contacto: Por intermédio de um especialista</p>     
                     {" "}
          <p>
            Financiamento: Conta própria/ A minha instituição
            formativa/sindicatos médicos/Outras organizações /programas
            nacionais
          </p>
                           {" "}
          <p>
            Taxa de inscrição: Sim, paguei aproximadamente 3000 euros (uma taxa
            de inscrição e uma propina mensal).
          </p>
                           {" "}
          <p>Número de casos de diagnóstico observados: 400</p>                 {" "}
          <p>
            Pessoa com quem dava saídas dos casos (maioritariamente): O
            especialista responsável pelo estágio
          </p>
                           {" "}
          <p>
            Formato da observação de casos: Observação de casos simultaneamente
            com o especialista/ Preparação prévia de casos e saídas
            posteriormente (sem escrita de relatório)
          </p>
                           {" "}
          <p>
            Atividades desenvolvidas durante o estágio: Observação de casos de
            rotina e de coleção/ Escrita científica/ Participação em reuniões
          </p>
                           {" "}
          <p>
            Tipo de reuniões habituais: Multidisciplinares / Decisão
            Terapêutica, Lâminas / Casos clínicos/ Journal Clubs/ Correlação
            cito-histológica/ Lâminas de congelação
          </p>
                            <p>Número de reuniões semanais: 9</p>               
            <p>Estímulo para a participação ativa em reuniões: 4</p>           
                <p>Produção científica: Escrita de um artigo de revisão.</p>   
                       {" "}
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 4
          </p>
                           {" "}
          <p> de frequentar cursos, congressos ou workshops: Não</p>           
               {" "}
          <p>
            Outros comentários: Durante o estágio observei 400 casos de rotina
            (ginecologia e urologia) e 700 casos de coleção (sobretudo
            ginecologia). Tive oportunidade de assistir às múltiplas reuniões de
            lâminas dos Internos do MGH de todas as áreas da Patologia, que
            recomendo!
          </p>
                           {" "}
          <p>Alojamento: Alojamento local (AirBNB, classificados, etc.)</p>     
                      <p>Facilidade de encontrar alojamento: 4</p>             
              <p>Custo mensal do alojamento: 1300</p>                 {" "}
          <p>
            Comentários acerca do custo e da qualidade de vida: Os custos com o
            alojamento são altos em Boston. Como optei por ficar num apartamento
            e não num quarto e numa zona que me permitia ir a pé para o
            hospital, há certamente opções mais baratas. Outros fatores a ter em
            conta são o custo dos transportes (os hospitais de Boston
            disponibilizam um shuttle gratuito entre eles, ficar no trajeto pode
            ser uma boa maneira de poupar dinheiro nisso) e o facto destes
            poderem falhar, sobretudo com neve no inverno (importante em
            estágios curtos).
          </p>
                            <p>Sessões / eventos sociais: Não</p>               
            <p>Acolhimento pela instituição: 3</p>                 {" "}
          <p>Quantidade/qualidade de tempo passado com o especialista: 4</p>   
                       {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 4
          </p>
                           {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 3
          </p>
        </>
      ),
    },
    {
      title: "Área de Especialização: Patologia dos tecidos moles",
      text: (
        <>
          <p>Instituição e país: A.C. Camargo Cancer Center, Brasil</p>         
                  <p>Avaliação global do estágio: 4</p>                 {" "}
          <p>Especialista Orientador: Dr. António Nascimento</p>               
            <p>Língua: Português</p>                 {" "}
          <p>Duração do Estágio (meses): 3</p>                 {" "}
          <p>Antecedência com que se marcou o estágio: 6 meses</p>             
              <p>Modo de contacto: Por intermédio de um especialista</p>       
                   {" "}
          <p>Financiamento: Conta própria/ Liga Portuguesa Contra o Cancro</p> 
                          <p>Taxa de inscrição: Não</p>                 {" "}
          <p>Número de casos de diagnóstico observados: 1500</p>               
           {" "}
          <p>
            Pessoa com quem dava saídas dos casos (maioritariamente): O
            especialista responsável pelo estágio
          </p>
                           {" "}
          <p>
            Formato da observação de casos: Preparação prévia de casos e saídas
            posteriormente (sem escrita de relatório)
          </p>
                           {" "}
          <p>
            Atividades desenvolvidas durante o estágio: Observação de casos de
            rotina e de coleção/ Participação em reuniões
          </p>
                           {" "}
          <p>
            Tipo de reuniões habituais: Multidisciplinares / Decisão
            Terapêutica, Lâminas / Casos clínicos/ Journal Club
          </p>
                            <p>Número de reuniões semanais: 4</p>               
            <p>Estímulo para a participação ativa em reuniões: 4</p>           
               {" "}
          <p>Produção científica: Não participei em produção científica</p>     
                     {" "}
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 2
          </p>
                           {" "}
          <p>
            Estímulo para realização de comunicações orais, posters ou capítulos
            de livros: 2
          </p>
                           {" "}
          <p>Oportunidade de frequentar cursos, congressos ou workshops: Sim</p>
                           {" "}
          <p>Alojamento: Alojamento local (AirBNB, classificados, etc.)</p>     
                      <p>Facilidade de encontrar alojamento: 2</p>             
              <p>Custo mensal do alojamento: 700</p>                 {" "}
          <p>Sessões / eventos sociais: Sim</p>                 {" "}
          <p>Acolhimento pela instituição: 4</p>                 {" "}
          <p>Quantidade/qualidade de tempo passado com o especialista: 4</p>   
                       {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 3
          </p>
                           {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 2
          </p>
                           {" "}
          <p>
            Sentes-te confortável para enviar casos de consulta / revisão ao
            especialista? Sim
          </p>
        </>
      ),
    },
    {
      title: "Área de Especialização: Patologia do tubo digestivo",
      text: (
        <>
          <p>
            Instituição e país: Insituto de Patologia da Universidade de Graz,
            Áustria
          </p>
                            <p>Avaliação global do estágio: 4</p>               
            <p>Especialista Orientador: Cord Langner</p>                 {" "}
          <p>Língua: Ingles/alemão</p>                 {" "}
          <p>Duração do Estágio (meses): 3</p>                 {" "}
          <p>Antecedência com que se marcou o estágio: 1 ano e uns meses</p>   
                       {" "}
          <p>Modo de contacto: Por intermédio de um especialista</p>           
                <p>Financiamento: Conta própria</p>                 {" "}
          <p>Taxa de inscrição: Não</p>                 {" "}
          <p>Número de casos de diagnóstico observados: 1000</p>               
           {" "}
          <p>
            Pessoa com quem dava saídas dos casos (maioritariamente): O
            especialista responsável pelo estágio
          </p>
                           {" "}
          <p>
            Formato da observação de casos: Observação de casos simultaneamente
            com o especialista
          </p>
                           {" "}
          <p>
            Atividades desenvolvidas durante o estágio: Observação de casos de
            rotina e de coleção/ Escrita científica
          </p>
                           {" "}
          <p>
            Tipo de reuniões habituais: Multidisciplinares / Decisão
            Terapêutica/ Lâminas / Casos clínicos/
          </p>
                            <p>Lâminas de congelação</p>                 {" "}
          <p>Número de reuniões semanais: 3</p>                 {" "}
          <p>Estímulo para a participação ativa em reuniões: 2</p>             
             {" "}
          <p>
            Produção científica: Desenvolvi um projeto (ideia do especialista)
          </p>
                           {" "}
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 4
          </p>
                           {" "}
          <p>
            Estímulo para realização de comunicações orais, posters ou capítulos
            de livros: 4
          </p>
                           {" "}
          <p>Oportunidade de frequentar cursos, congressos ou workshops: Sim</p>
                           {" "}
          <p>Alojamento: Alojamento local (AirBNB, classificados, etc.)</p>     
                      <p>Facilidade de encontrar alojamento: 5</p>             
              <p>Custo mensal do alojamento: 1500</p>                 {" "}
          <p>Sessões / eventos sociais: Não</p>                 {" "}
          <p>Acolhimento pela instituição: 3</p>                 {" "}
          <p>Quantidade/qualidade de tempo passado com o especialista: 3</p>   
                       {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 4
          </p>
                           {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 4
          </p>
                           {" "}
          <p>
            Sentes-te confortável para enviar casos de consulta / revisão ao
            especialista? Sim
          </p>
        </>
      ),
    },
    {
      title: "Área de Especialização: Dermatopatologia",
      text: (
        <>
          <p>Instituição e país: VUmc, Holanda</p>                 {" "}
          <p>Avaliação global do estágio: 5</p>                 {" "}
          <p>Especialista Orientador: Prof. Wolter Mooi</p>                 {" "}
          <p>Língua: Inglês</p>                 {" "}
          <p>Duração do Estágio (meses): 3</p>                 {" "}
          <p>
            Antecedência com que se marcou o estágio: Foi mais de um ano, mas
            entretanto já se reformou
          </p>
                           {" "}
          <p>Modo de contacto: Por intermédio de um especialista</p>           
                <p>Financiamento: Conta própria</p>                 {" "}
          <p>
            Taxa de inscrição: Não me recordo, mas penso que sim (poucas
            centenas de euros)
          </p>
                           {" "}
          <p>Número de casos de diagnóstico observados: 300</p>                 {" "}
          <p>
            Pessoa com quem se dava saídas dos casos (maioritariamente): O
            especialista responsável pelo estágio
          </p>
                           {" "}
          <p>
            Formato da observação de casos: Discussão de casos de arquivo com
            patologista além de serviço previamente resolvido
          </p>
                           {" "}
          <p>
            Atividades desenvolvidas durante o estágio: Observação de casos de
            rotina e de coleção/ Escrita científica/ Participação em reuniões
          </p>
                           {" "}
          <p>
            Tipo de reuniões habituais: Multidisciplinares / Decisão
            Terapêutica/ Lâminas / Casos clínicos
          </p>
                            <p>Número de reuniões semanais: 10</p>             
              <p>Estímulo para a participação ativa em reuniões: 3</p>         
                 {" "}
          <p>
            Produção científica: Desenvolvi um projeto (ideia do especialista)
          </p>
                           {" "}
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 3
          </p>
                           {" "}
          <p>
            Estímulo para realização de comunicações orais, posters ou capítulos
            de livros: 2
          </p>
                           {" "}
          <p>Oportunidade de frequentar cursos, congressos ou workshops: Não</p>
                           {" "}
          <p>
            Alojamento: Residência estudantil, Alojamento local (AirBNB,
            classificados, etc.)
          </p>
                            <p>Facilidade de encontrar alojamento: 4</p>       
                    <p>Custo mensal do alojamento: 1000 euros</p>               
           {" "}
          <p>
            Comentários acerca do custo e da qualidade de vida: Além de
            Amsterdão ser uma cidade com qualidade de vida muito dispendiosa, e
            habitação muito cara, não é nada fácil arranjar alojamentos por
            curta duração (menos de 6-12meses). Tive muita sorte na casa que
            arranjei, pois não descobria alternativas economicamente viáveis
            (tendo ponderado desistir do estágio por esse motivo).
          </p>
                            <p>Sessões / eventos sociais: Não</p>               
            <p>Acolhimento pela instituição: 2</p>                 {" "}
          <p>Quantidade/qualidade de tempo passado com o especialista: 3</p>   
                       {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 4
          </p>
                           {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 3
          </p>
                           {" "}
          <p>
            Sentes-te confortável para enviar casos de consulta / revisão ao
            especialista? Não
          </p>
        </>
      ),
    },
    {
      title: "Área de Especialização: Biologia Molecular e Neuropatologia",
      text: (
        <>
          <p>
            Instituição e país: Hospital Universitário de Heidelberg, Alemanha
          </p>
                            <p>Avaliação global do estágio: 5</p>               
            <p>Especialista Orientador: Prof. Andreas von Deimling</p>         
                  <p>Língua: Inglês (com domínio do alemão seria melhor)</p>   
                        <p>Duração do Estágio (meses): 3</p>                 {" "}
          <p>Antecedência com que se marcou o estágio: 6 meses</p>             
              <p>Modo de contacto: Por intermédio de um especialista</p>       
                    <p>Financiamento: Conta própria</p>                 {" "}
          <p>Taxa de inscrição: Não</p>                 {" "}
          <p>Número de casos de diagnóstico observados: 500</p>                 {" "}
          <p>
            Pessoa com quem se dava saídas dos casos (maioritariamente): Era
            variável, com o especialista e o grupo do especialista
          </p>
                           {" "}
          <p>
            Formato da observação de casos: Preparação prévia de casos e saídas
            posteriormente (com escrita de relatório)
          </p>
                           {" "}
          <p>
            Atividades desenvolvidas durante o estágio: Observação de casos de
            rotina e de coleção/ Escrita científica/ Participação em reuniões/
            Realização de relatórios de biologia molecular
          </p>
                           {" "}
          <p>
            Tipo de reuniões habituais: Lâminas / Casos clínicos/ Discussão de
            casos de correlação entre molecular e histologia
          </p>
                            <p>Número de reuniões semanais: 1</p>               
            <p>Estímulo para a participação ativa em reuniões: 3</p>           
               {" "}
          <p>Produção científica: Desenvolvi um projeto (ideia pessoal)</p>     
                     {" "}
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 3
          </p>
                           {" "}
          <p>
            Estímulo para realização de comunicações orais, posters ou capítulos
            de livros: 1
          </p>
                           {" "}
          <p>
            Oportunidade de frequentar cursos, congressos ou workshops:
            Participação em reuniões formativas semanais
          </p>
                           {" "}
          <p>Alojamento: Alojamento local (AirBNB, classificados, etc.)</p>     
                      <p>Facilidade de encontrar alojamento: 4</p>             
              <p>Custo mensal do alojamento: 750</p>                 {" "}
          <p>Sessões / eventos sociais: Não</p>                 {" "}
          <p>Acolhimento pela instituição: 3</p>                 {" "}
          <p>
            Comentários acerca do custo e da qualidade de vida: Custo do
            alojamento elevado na cidade, foi necessário ficar algo longe
          </p>
                           {" "}
          <p>Quantidade/qualidade de tempo passado com o especialista: 3</p>   
                       {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 4
          </p>
                           {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 3
          </p>
                           {" "}
          <p>
            Sentes-te confortável para enviar casos de consulta / revisão ao
            especialista? Não
          </p>
        </>
      ),
    },
    {
      title: "Área de Especialização: Dermatopatologia",
      text: (
        <>
          <p>Instituição e país: Universidade Médica de Graz, Áustria</p>       
                    <p>Avaliação global do estágio: 5</p>                 {" "}
          <p>Especialista Orientador: Prof. Lorenzo Cerroni</p>                 {" "}
          <p>Língua: Inglês</p>                 {" "}
          <p>Duração do Estágio (meses): 3</p>                 {" "}
          <p>Antecedência com que se marcou o estágio: 1 ano ou mais</p>       
                    <p>Modo de contacto: Por intermédio de um especialista</p> 
                          <p>Financiamento: Conta própria</p>                 {" "}
          <p>Taxa de inscrição: Não</p>                 {" "}
          <p>Número de casos de diagnóstico observados: 2000</p>               
           {" "}
          <p>
            Pessoa com quem se dava saídas dos casos (maioritariamente): O
            especialista responsável pelo estágio
          </p>
                           {" "}
          <p>
            Formato da observação de casos: Observação de casos simultaneamente
            com o especialista/ Preparação prévia de casos e saídas
            posteriormente (sem escrita de relatório)
          </p>
                           {" "}
          <p>
            Atividades desenvolvidas durante o estágio: Observação de casos de
            rotina, Observação de casos de coleção, Participação em reuniões
          </p>
                           {" "}
          <p>Tipo de reuniões habituais: Lâminas / Casos clínicos</p>           
                <p>Estímulo para a participação ativa em reuniões: 4</p>       
                   {" "}
          <p>Produção científica: Não participei em produção científica</p>     
                     {" "}
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 1
          </p>
                           {" "}
          <p>
            Estímulo para realização de comunicações orais, posters ou capítulos
            de livros: 1
          </p>
                           {" "}
          <p>Oportunidade de frequentar cursos, congressos ou workshops: Sim</p>
                           {" "}
          <p>
            Alojamento: Residência estudantil, Hotel, Alojamento local (AirBNB,
            classificados, etc.)
          </p>
                            <p>Facilidade de encontrar alojamento: 5</p>       
                    <p>Sessões / eventos sociais: Sim</p>                 {" "}
          <p>Acolhimento pela instituição: 4</p>                 {" "}
          <p>Quantidade/qualidade de tempo passado com o especialista: 4</p>   
                       {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 4
          </p>
                           {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 2
          </p>
                           {" "}
          <p>
            Sentes-te confortável para enviar casos de consulta / revisão ao
            especialista? Sim
          </p>
        </>
      ),
    },
    {
      title: "Área de Especialização: Hematopatologia  e Dermatopatologia",
      text: (
        <>
          <p>Instituição e país: Hospital Henri-Mondor, França</p>             
              <p>Avaliação global do estágio: 5</p>                 {" "}
          <p>Especialista Orientador: Philippe Gaulard e Nicolas Ortonne</p>   
                        <p>Língua: Francês</p>                 {" "}
          <p>Duração do Estágio (meses): 6</p>                 {" "}
          <p>Antecedência com que se marcou o estágio: Poucos meses</p>         
                  <p>Modo de contacto: Por intermédio de um especialista</p>   
                       {" "}
          <p>Financiamento: Organização de acolhimento (estágio pago)</p>       
                    <p>Taxa de inscrição: Não</p>                 {" "}
          <p>Número de casos de diagnóstico observados: 1000</p>               
           {" "}
          <p>
            Pessoa com quem dava saídas dos casos (maioritariamente): O
            especialista responsável pelo estágio
          </p>
                           {" "}
          <p>
            Formato da observação de casos: Preparação prévia de casos e saídas
            posteriormente (com escrita de relatório)
          </p>
                           {" "}
          <p>
            Atividades desenvolvidas durante o estágio: Observação de casos de
            rotina/ Escrita científica/ Participação em reuniões
          </p>
                           {" "}
          <p>
            Tipo de reuniões habituais: Multidisciplinares / Decisão
            Terapêutica, Lâminas / Casos clínicos/ Journal Club
          </p>
                            <p>Número de reuniões semanais: 4</p>               
            <p>Estímulo para participação em reuniões: 4</p>                 {" "}
          <p>
            Produção científica: Desenvolvi um projeto (ideia do especialista)
          </p>
                           {" "}
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 3
          </p>
                           {" "}
          <p>
            Estímulo para realização de comunicações orais, posters ou capítulos
            de livros: 1
          </p>
                           {" "}
          <p>Oportunidade de frequentar cursos, congressos ou workshops: Sim</p>
                           {" "}
          <p>Alojamento: Residência hospitalar, Residência estudantil</p>       
                    <p>Facilidade de encontrar alojamento: 1</p>               
            <p>Custo mensal do alojamento: 700</p>                 {" "}
          <p>Sessões / eventos sociais: Sim</p>                 {" "}
          <p>Acolhimento pela instituição: 4</p>                 {" "}
          <p>Quantidade/qualidade de tempo passado com o especialista: 4</p>   
                       {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 4
          </p>
                           {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 2
          </p>
                           {" "}
          <p>
            Sentes-te confortável para enviar casos de consulta / revisão ao
            especialista? Sim
          </p>
        </>
      ),
    },
    {
      title: "Área de Especialização: Patologia da mama",
      text: (
        <>
          <p>Instituição e país: Candiolo cancer institute – IRCC, Itália</p>   
                        <p>Avaliação global do estágio: 4</p>                 {" "}
          <p>Especialista Orientador: Prof. Caterina Marchiò</p>               
            <p>Língua: Inglês</p>                 {" "}
          <p>Duração do estágio (meses): 3</p>                 {" "}
          <p>Antecedência com que se marcou o estágio: Um ano</p>               
           {" "}
          <p>
            Modo de contacto: E-mail publicamente disponível (especialista /
            instituição)
          </p>
                            <p>Financiamento: Giordano Fellowship</p>           
                <p>Taxa de inscrição: Não</p>                 {" "}
          <p>Número de casos de diagnóstico observados: 500</p>                 {" "}
          <p>
            Pessoa com quem se dava saídas dos casos (maioritariamente): O
            especialista responsável pelo estágio
          </p>
                           {" "}
          <p>
            Formato da observação de casos: Preparação prévia de casos e saídas
            posteriormente (com escrita de relatório)
          </p>
                           {" "}
          <p>
            Atividades desenvolvidas durante o estágio: Observação de casos de
            rotina e de coleção/ Escrita científica/Participação em reuniões
          </p>
                           {" "}
          <p>
            Tipo de reuniões habituais: Multidisciplinares / Decisão Terapêutica
          </p>
                            <p>Número de reuniões semanais: 1</p>               
            <p>Estímulo para a participação ativa em reuniões: 4</p>           
               {" "}
          <p>
            Produção científica: Participei num projeto a decorrer no serviço
          </p>
                           {" "}
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 4
          </p>
                           {" "}
          <p>
            Estímulo para realização de comunicações orais, posters ou capítulos
            de livros: 3
          </p>
                           {" "}
          <p>Oportunidade de frequentar cursos, congressos ou workshops: Sim</p>
                            <p>Alojamento: Hotel</p>                 {" "}
          <p>Facilidade de encontrar alojamento: 2</p>                 {" "}
          <p>Custo mensal do alojamento: 750</p>                 {" "}
          <p>Sessões / eventos sociais: Não</p>                 {" "}
          <p>Acolhimento pela instituição: 4</p>                 {" "}
          <p>Quantidade/qualidade de tempo passado com o especialista: 3</p>   
                       {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 3
          </p>
                           {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 4
          </p>
                           {" "}
          <p>
            Sentes-te confortável para enviar casos de consulta / revisão ao
            especialista? Sim
          </p>
        </>
      ),
    },
    {
      title: "Área de Especialização: Dermatopatologia",
      text: (
        <>
          <p>Instituição e país: Hospital 12 de Octubre (Madrid),Espanha</p>   
                        <p>Avaliação global do estágio: 5</p>                 {" "}
          <p>Especialista Orientador: Dr. Jose Luis Peralto</p>                 {" "}
          <p>Língua: Espanhol</p>                 {" "}
          <p>Duração do Estágio (meses): 2</p>                 {" "}
          <p>Antecedência com que se marcou o estágio: 6 meses</p>             
              <p>Modo de contacto: Contacto pessoal em cursos / congressos</p> 
                          <p>Financiamento: Conta própria</p>                 {" "}
          <p>Taxa para inscrição: não</p>                 {" "}
          <p>Número de casos de diagnóstico observados: 1900</p>               
           {" "}
          <p>
            Pessoa com quem se dava saídas dos casos (maioritariamente): O
            especialista responsável pelo estágio
          </p>
                           {" "}
          <p>
            Formato da observação de casos: Observação de casos simultaneamente
            com o especialista
          </p>
                           {" "}
          <p>
            Atividades desenvolvidas durante o estágio: Observação de casos de
            rotina e de coleção/ Participação em reuniões/ Fazer a macroscopia
            das biópsias e peças cutâneas
          </p>
                           {" "}
          <p>
            Tipo de reuniões habituais: Multidisciplinares / Decisão
            Terapêutica, Lâminas / Casos clínicos/ Journal Club
          </p>
                            <p>Número de reuniões semanais: 5</p>               
            <p>Estímulo para a participação ativa em reuniões: 4</p>           
               {" "}
          <p>
            Produção científica: Participei num projeto a decorrer no serviço
          </p>
                           {" "}
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 2
          </p>
                           {" "}
          <p>
            Estímulo para realização de comunicações orais, posters ou capítulos
            de livros: 2
          </p>
                           {" "}
          <p>Oportunidade de frequentar cursos, congressos ou workshops: Sim</p>
                           {" "}
          <p>
            Comentários acerca do custo e da qualidade de vida: Bom ambiente de
            trabalho, disponibilidade para ensino, casuística variada,
            participação em reuniões de todas as áreas de anatomia patológica
          </p>
                           {" "}
          <p>
            Alojamento: Residência estudantil/ Hotel/ Alojamento local (AirBNB,
            classificados, etc.)
          </p>
                            <p>Facilidade de encontrar alojamento: 1</p>       
                    <p>Sessões / eventos sociais: Sim</p>                 {" "}
          <p>Acolhimento pela instituição: 4</p>                 {" "}
          <p>Quantidade/qualidade de tempo passado com o especialista: 4</p>   
                       {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 4
          </p>
                           {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 2
          </p>
                           {" "}
          <p>
            Sentes-te confortável para enviar casos de consulta / revisão ao
            especialista? Sim
          </p>
        </>
      ),
    },
    {
      title: "Área de Especialização: Citologia",
      text: (
        <>
          <p>Instituição e país: Brigham and Women's Hospital, EUA</p>         
                  <p>Avaliação global do estágio: 5</p>                 {" "}
          <p>Especialista Orientador: Dr. Edmund Cibas</p>                 {" "}
          <p>Língua: Inglês</p>                 {" "}
          <p>Duração do Estágio (meses): 3</p>                 {" "}
          <p>Antecedência com que se marcou o estágio: Um ou dois anos</p>     
                      <p>Modo de contacto: Por intermédio de um especialista</p>
                            <p>Financiamento: Conta própria</p>                 {" "}
          <p>Taxa de inscrição: O BWH não requer o pagamento de fees</p>       
                    <p>Número de casos de diagnóstico observados: 1000</p>     
                     {" "}
          <p>
            Pessoa com quem se dava saídas dos casos (maioritariamente): O grupo
            do especialista responsável pelo estágio
          </p>
                           {" "}
          <p>
            Formato da observação de casos: Preparação prévia de casos e saídas
            posteriormente (sem escrita de relatório)/ Preparação prévia de
            casos e saídas posteriormente (com escrita de relatório)
          </p>
                           {" "}
          <p>
            Atividades desenvolvidas durante o estágio: Observação de casos de
            rotina e de coleção/ Escrita científica/ Participação em reuniões
          </p>
                           {" "}
          <p>
            Tipo de reuniões habituais: Lâminas / Casos clínicos/ Journal Club/
            Correlação cito-histológica/ Controlo de qualidade
          </p>
                            <p>Número de reuniões semanais: 8</p>               
            <p>Estímulo para a participação ativa em reuniões: 4</p>           
               {" "}
          <p>
            Produção científica: Desenvolvi um projeto (ideia do especialista)
          </p>
                           {" "}
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 2
          </p>
                           {" "}
          <p>
            Estímulo para realização de comunicações orais, posters ou capítulos
            de livros: 2
          </p>
                           {" "}
          <p>Oportunidade de frequentar cursos, congressos ou workshops: Sim</p>
                           {" "}
          <p>Alojamento: Alojamento local (AirBNB, classificados, etc.)</p>     
                      <p>Facilidade de encontrar alojamento: 4</p>             
              <p>Custo mensal do alojamento: 1500</p>                 {" "}
          <p>Sessões / eventos sociais: Sim</p>                 {" "}
          <p>Acolhimento pela instituição: 4</p>                 {" "}
          <p>
            Comentários acerca do custo e da qualidade de vida: Em termos de
            desvantagens, Boston é uma cidade cara para os padrões Europeus e o
            Inverno torna-a aparentemente pouco acolhedora. Mas há imenso para
            fazer em termos culturais, desportivos, científicos, turísticos,
            etc., para além que está muito perto de outras excelentes cidades da
            costa este, como Nova Iorque. Recomendo vivamente, em qualquer
            altura do ano.
          </p>
                           {" "}
          <p>Quantidade/qualidade de tempo passado com o especialista: 3</p>   
                       {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 4
          </p>
                           {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 2
          </p>
                           {" "}
          <p>
            Sentes-te confortável para enviar casos de consulta / revisão ao
            especialista? Sim
          </p>
        </>
      ),
    },
    {
      title: "Área de Especialização: Patologia do sistema digestivo",
      text: (
        <>
          <p>Instituição e país: Universidade Médica de Graz, Áustria</p>       
                    <p>Avaliação global do estágio: 5</p>                 {" "}
          <p>Especialista Orientador: Prof. Lorenzo Cerroni</p>                 {" "}
          <p>Língua: Inglês</p>                 {" "}
          <p>Duração do Estágio (meses): 3</p>                 {" "}
          <p>Antecedência com que se marcou o estágio: 1 ano ou mais</p>       
                    <p>Modo de contacto: Por intermédio de um especialista</p> 
                          <p>Financiamento: Conta própria</p>                 {" "}
          <p>Taxa de inscrição: Não</p>                 {" "}
          <p>Número de casos de diagnóstico observados: 2000</p>               
           {" "}
          <p>
            Pessoa com quem se dava saídas dos casos (maioritariamente): O
            especialista responsável pelo estágio
          </p>
                           {" "}
          <p>
            Formato da observação de casos: Observação de casos simultaneamente
            com o especialista/ Preparação prévia de casos e saídas
            posteriormente (sem escrita de relatório)
          </p>
                           {" "}
          <p>
            Atividades desenvolvidas durante o estágio: Observação de casos de
            rotina, Observação de casos de coleção, Participação em reuniões
          </p>
                           {" "}
          <p>Tipo de reuniões habituais: Lâminas / Casos clínicos</p>           
                <p>Estímulo para a participação ativa em reuniões: 4</p>       
                   {" "}
          <p>Produção científica: Não participei em produção científica</p>     
                     {" "}
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 1
          </p>
                           {" "}
          <p>
            Estímulo para realização de comunicações orais, posters ou capítulos
            de livros: 1
          </p>
                           {" "}
          <p>Oportunidade de frequentar cursos, congressos ou workshops: Sim</p>
                           {" "}
          <p>
            Alojamento: Residência estudantil, Hotel, Alojamento local (AirBNB,
            classificados, etc.)
          </p>
                            <p>Facilidade de encontrar alojamento: 5</p>       
                    <p>Sessões / eventos sociais: Sim</p>                 {" "}
          <p>Acolhimento pela instituição: 4</p>                 {" "}
          <p>Quantidade/qualidade de tempo passado com o especialista: 4</p>   
                       {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 4
          </p>
                           {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 2
          </p>
                           {" "}
          <p>
            Sentes-te confortável para enviar casos de consulta / revisão ao
            especialista? Sim
          </p>
        </>
      ),
    },
    {
      title: "Área de Especialização: Hematopatologia  e Dermatopatologia",
      text: (
        <>
          <p>Instituição e país: Hospital Henri-Mondor, França</p>             
              <p>Avaliação global do estágio: 5</p>                 {" "}
          <p>Especialista Orientador: Philippe Gaulard e Nicolas Ortonne</p>   
                        <p>Língua: Francês</p>                 {" "}
          <p>Duração do Estágio (meses): 6</p>                 {" "}
          <p>Antecedência com que se marcou o estágio: Poucos meses</p>         
                  <p>Modo de contacto: Por intermédio de um especialista</p>   
                       {" "}
          <p>Financiamento: Organização de acolhimento (estágio pago)</p>       
                    <p>Taxa de inscrição: Não</p>                 {" "}
          <p>Número de casos de diagnóstico observados: 1000</p>               
           {" "}
          <p>
            Pessoa com quem dava saídas dos casos (maioritariamente): O
            especialista responsável pelo estágio
          </p>
                           {" "}
          <p>
            Formato da observação de casos: Preparação prévia de casos e saídas
            posteriormente (com escrita de relatório)
          </p>
                           {" "}
          <p>
            Atividades desenvolvidas durante o estágio: Observação de casos de
            rotina/ Escrita científica/ Participação em reuniões
          </p>
                           {" "}
          <p>
            Tipo de reuniões habituais: Multidisciplinares / Decisão
            Terapêutica, Lâminas / Casos clínicos/ Journal Club
          </p>
                            <p>Número de reuniões semanais: 4</p>               
            <p>Estímulo para participação em reuniões: 4</p>                 {" "}
          <p>
            Produção científica: Desenvolvi um projeto (ideia do especialista)
          </p>
                           {" "}
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 3
          </p>
                           {" "}
          <p>
            Estímulo para realização de comunicações orais, posters ou capítulos
            de livros: 1
          </p>
                           {" "}
          <p>Oportunidade de frequentar cursos, congressos ou workshops: Sim</p>
                           {" "}
          <p>Alojamento: Residência hospitalar, Residência estudantil</p>       
                    <p>Facilidade de encontrar alojamento: 1</p>               
            <p>Custo mensal do alojamento: 700</p>                 {" "}
          <p>Sessões / eventos sociais: Sim</p>                 {" "}
          <p>Acolhimento pela instituição: 4</p>                 {" "}
          <p>Quantidade/qualidade de tempo passado com o especialista: 4</p>   
                       {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 4
          </p>
                           {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 2
          </p>
                           {" "}
          <p>
            Sentes-te confortável para enviar casos de consulta / revisão ao
            especialista? Sim
          </p>
        </>
      ),
    },
    {
      title: "Área de Especialização: Patologia da mama",
      text: (
        <>
          <p>Instituição e país: Candiolo cancer institute – IRCC, Itália</p>   
                        <p>Avaliação global do estágio: 4</p>                 {" "}
          <p>Especialista Orientador: Prof. Caterina Marchiò</p>               
            <p>Língua: Inglês</p>                 {" "}
          <p>Duração do estágio (meses): 3</p>                 {" "}
          <p>Antecedência com que se marcou o estágio: Um ano</p>               
           {" "}
          <p>
            Modo de contacto: E-mail publicamente disponível (especialista /
            instituição)
          </p>
                            <p>Financiamento: Giordano Fellowship</p>           
                <p>Taxa de inscrição: Não</p>                 {" "}
          <p>Número de casos de diagnóstico observados: 500</p>                 {" "}
          <p>
            Pessoa com quem se dava saídas dos casos (maioritariamente): O
            especialista responsável pelo estágio
          </p>
                           {" "}
          <p>
            Formato da observação de casos: Preparação prévia de casos e saídas
            posteriormente (com escrita de relatório)
          </p>
                           {" "}
          <p>
            Atividades desenvolvidas durante o estágio: Observação de casos de
            rotina e de coleção/ Escrita científica/Participação em reuniões
          </p>
                           {" "}
          <p>
            Tipo de reuniões habituais: Multidisciplinares / Decisão Terapêutica
          </p>
                            <p>Número de reuniões semanais: 1</p>               
            <p>Estímulo para a participação ativa em reuniões: 4</p>           
               {" "}
          <p>
            Produção científica: Participei num projeto a decorrer no serviço
          </p>
                           {" "}
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 4
          </p>
                           {" "}
          <p>
            Estímulo para realização de comunicações orais, posters ou capítulos
            de livros: 3
          </p>
                           {" "}
          <p>Oportunidade de frequentar cursos, congressos ou workshops: Sim</p>
                            <p>Alojamento: Hotel</p>                 {" "}
          <p>Facilidade de encontrar alojamento: 2</p>                 {" "}
          <p>Custo mensal do alojamento: 750</p>                 {" "}
          <p>Sessões / eventos sociais: Não</p>                 {" "}
          <p>Acolhimento pela instituição: 4</p>                 {" "}
          <p>Quantidade/qualidade de tempo passado com o especialista: 3</p>   
                       {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 3
          </p>
                           {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 4
          </p>
                           {" "}
          <p>
            Sentes-te confortável para enviar casos de consulta / revisão ao
            especialista? Sim
          </p>
        </>
      ),
    },
    {
      title: "Área de Especialização: Dermatopatologia",
      text: (
        <>
          <p>Instituição e país: Hospital 12 de Octubre (Madrid),Espanha</p>   
                        <p>Avaliação global do estágio: 5</p>                 {" "}
          <p>Especialista Orientador: Dr. Jose Luis Peralto</p>                 {" "}
          <p>Língua: Espanhol</p>                 {" "}
          <p>Duração do Estágio (meses): 2</p>                 {" "}
          <p>Antecedência com que se marcou o estágio: 6 meses</p>             
              <p>Modo de contacto: Contacto pessoal em cursos / congressos</p> 
                          <p>Financiamento: Conta própria</p>                 {" "}
          <p>Taxa para inscrição: não</p>                 {" "}
          <p>Número de casos de diagnóstico observados: 1900</p>               
           {" "}
          <p>
            Pessoa com quem se dava saídas dos casos (maioritariamente): O
            especialista responsável pelo estágio
          </p>
                           {" "}
          <p>
            Formato da observação de casos: Observação de casos simultaneamente
            com o especialista
          </p>
                           {" "}
          <p>
            Atividades desenvolvidas durante o estágio: Observação de casos de
            rotina e de coleção/ Participação em reuniões/ Fazer a macroscopia
            das biópsias e peças cutâneas
          </p>
                           {" "}
          <p>
            Tipo de reuniões habituais: Multidisciplinares / Decisão
            Terapêutica, Lâminas / Casos clínicos/ Journal Club
          </p>
                            <p>Número de reuniões semanais: 5</p>               
            <p>Estímulo para a participação ativa em reuniões: 4</p>           
               {" "}
          <p>
            Produção científica: Participei num projeto a decorrer no serviço
          </p>
                           {" "}
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 2
          </p>
                           {" "}
          <p>
            Estímulo para realização de comunicações orais, posters ou capítulos
            de livros: 2v
          </p>
                           {" "}
          <p>Oportunidade de frequentar cursos, congressos ou workshops: Sim</p>
                           {" "}
          <p>
            Comentários acerca do custo e da qualidade de vida: Bom ambiente de
            trabalho, disponibilidade para ensino, casuística variada,
            participação em reuniões de todas as áreas de anatomia patológica
          </p>
                           {" "}
          <p>
            Alojamento: Residência estudantil/ Hotel/ Alojamento local (AirBNB,
            classificados, etc.)
          </p>
                            <p>Facilidade de encontrar alojamento: 1</p>       
                    <p>Sessões / eventos sociais: Sim</p>                 {" "}
          <p>Acolhimento pela instituição: 4</p>                 {" "}
          <p>Quantidade/qualidade de tempo passado com o especialista: 4</p>   
                       {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 4
          </p>
                           {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 2
          </p>
          <p>
            Sentes-te confortável para enviar casos de consulta / revisão ao
            especialista? Sim
          </p>
        </>
      ),
    },
    {
      title: "Área de Especialização: Citologia",
      text: (
        <>
          <p>Instituição e país: Brigham and Women's Hospital, EUA</p>         
                  <p>Avaliação global do estágio: 5</p>                 {" "}
          <p>Especialista Orientador: Dr. Edmund Cibas</p>                 {" "}
          <p>Língua: Inglês</p>                 {" "}
          <p>Duração do Estágio (meses): 3</p>                 {" "}
          <p>Antecedência com que se marcou o estágio: Um ou dois anos</p>     
                      <p>Modo de contacto: Por intermédio de um especialista</p>
                            <p>Financiamento: Conta própria</p>                 {" "}
          <p>Taxa de inscrição: O BWH não requer o pagamento de fees</p>       
                    <p>Número de casos de diagnóstico observados: 1000</p>     
                     {" "}
          <p>
            Pessoa com quem se dava saídas dos casos (maioritariamente): O grupo
            do especialista responsável pelo estágio
          </p>
                           {" "}
          <p>
            Formato da observação de casos: Preparação prévia de casos e saídas
            posteriormente (sem escrita de relatório)/ Preparação prévia de
            casos e saídas posteriormente (com escrita de relatório)
          </p>
                           {" "}
          <p>
            Atividades desenvolvidas durante o estágio: Observação de casos de
            rotina e de coleção/ Escrita científica/ Participação em reuniões
          </p>
                           {" "}
          <p>
            Tipo de reuniões habituais: Lâminas / Casos clínicos/ Journal Club/
            Correlação cito-histológica/ Controlo de qualidade
          </p>
                            <p>Número de reuniões semanais: 8</p>               
            <p>Estímulo para a participação ativa em reuniões: 4</p>           
               {" "}
          <p>
            Produção científica: Desenvolvi um projeto (ideia do especialista)
          </p>
                           {" "}
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 2
          </p>
                           {" "}
          <p>
            Estímulo para realização de comunicações orais, posters ou capítulos
            de livros: 2
          </p>
                           {" "}
          <p>Oportunidade de frequentar cursos, congressos ou workshops: Sim</p>
                           {" "}
          <p>Alojamento: Alojamento local (AirBNB, classificados, etc.)</p>     
                      <p>Facilidade de encontrar alojamento: 4</p>             
              <p>Custo mensal do alojamento: 1500</p>                 {" "}
          <p>Sessões / eventos sociais: Sim</p>                 {" "}
          <p>Acolhimento pela instituição: 4</p>                 {" "}
          <p>
            Comentários acerca do custo e da qualidade de vida: Em termos de
            desvantagens, Boston é uma cidade cara para os padrões Europeus e o
            Inverno torna-a aparentemente pouco acolhedora. Mas há imenso para
            fazer em termos culturais, desportivos, científicos, turísticos,
            etc., para além que está muito perto de outras excelentes cidades da
            costa este, como Nova Iorque. Recomendo vivamente, em qualquer
            altura do ano.
          </p>
                           {" "}
          <p>Quantidade/qualidade de tempo passado com o especialista: 3</p>   
                       {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 4
          </p>
                           {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 2
          </p>
                           {" "}
          <p>
            Sentes-te confortável para enviar casos de consulta / revisão ao
            especialista? Sim
          </p>
        </>
      ),
    },
    {
      title: "Área de Especialização: Patologia do sistema digestivo",
      text: (
        <>
          <p>Instituição e país: Memorial Sloan Kettering Cancer Center, EUA</p>
                            <p>Avaliação global do estágio: 5</p>               
            <p>Especialista Orientador: Klimstra D, Tang L, Barturk O</p>       
                    <p>Língua: Inglês</p>                 {" "}
          <p>Duração do Estágio (meses): 1</p>                 {" "}
          <p>Antecedência com que se marcou o estágio: 9 meses</p>             
              <p>Modo de contacto: Por intermédio de um especialista</p>       
                   {" "}
          <p>
            Financiamento: Conta própria/ outras organizações/ programas
            nacionais
          </p>
                           {" "}
          <p>Taxa de inscrição: No meu caso não foi necessário</p>             
              <p>Número de casos de diagnóstico observados: 1400</p>           
               {" "}
          <p>
            Pessoa com quem se dava saídas dos casos (maioritariamente): O grupo
            do especialista responsável pelo estágio
          </p>
                           {" "}
          <p>
            Formato da observação de casos: Observação de casos simultaneamente
            com o especialista/ Preparação prévia de casos e saídas
            posteriormente (sem escrita de relatório)
          </p>
                           {" "}
          <p>
            Atividades desenvolvidas durante o estágio: Observação de casos de
            rotina e de coleção/ Escrita científica/ Participação em reuniões
          </p>
                           {" "}
          <p>
            Tipo de reuniões habituais: Lâminas / Casos clínicos/ Journal Club/
            Aulas téoricas e teórico-práticas diárias
          </p>
                            <p>Número de reuniões semanais: 5</p>               
            <p>Estímulo para a participação ativa em reuniões: 4</p>           
               {" "}
          <p>
            Produção científica: Participei num projeto a decorrer no serviço/
            Desenvolvi um projeto (ideia pessoal)
          </p>
                           {" "}
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 4
          </p>
                           {" "}
          <p>
            Estímulo para realização de comunicações orais, posters ou capítulos
            de livros: 4
          </p>
                           {" "}
          <p>Oportunidade de frequentar cursos, congressos ou workshops: Sim</p>
                           {" "}
          <p>
            Outros comentários: O MSKCC é uma das maiores instituições para
            diagnóstico e tratamento de cancro no mundo. Todos os tumores são
            submetidos a análise molecular pormenorizada (RNAseq, whole-genome
            sequencing, MSI, tumor mutation burden, methylation profiling),
            assim como análise do DNA não tumoral (em sangue periférico) para
            pesquisa de variantes germinativas. A quantidade de dados
            disponíveis para analisar, como se pode imaginar, é enorme, assim
            como o número de casos diários, em todas as áreas oncológicas. Os
            dados produzidos são apresentados ciclicamente para todos as áreas.
          </p>
                           {" "}
          <p>
            Observei casos raros, todos os dias, e tive a oportunidade de me
            focar no estudo da patologia pancreática.
          </p>
                           {" "}
          <p>
            Cada área tem vários especialistas responsáveis para o "sign-out",
            que acontece todos os dias a partir das 7.30/08.00 até ao meio-dia.
            Todos os especialistas foram super disponíveis e ensinaram-me mesmo
            muito (tive com oito patologistas de gastro, todos os dias, e cada
            um transmitiu-me o próprio "estilo" e conceitos). O único "se não" é
            o tempo máximo de estágio, limitado a um mês para as observerships.
            Mas recomendo 100%, nem que depois se complemente com outro estágio.
          </p>
                           {" "}
          <p>Alojamento: Alojamento local (AirBNB, classificados, etc.)</p>     
                      <p>Facilidade de encontrar alojamento: 2</p>             
              <p>Custo mensal do alojamento: 1400</p>                 {" "}
          <p>
            Comentários acerca do custo e da qualidade de vida: Manhattan é
            super caro (cerca de 5000€ por um quarto), recomendo procurar
            alojamento noutra localidade fora de Manhattan. Eu fiquei em Astoria
            (40min de metro do hospital). Os transportes são muito eficientes e
            seguros, também a noite.
          </p>
                            <p>Sessões / eventos sociais: Sim</p>               
            <p>Acolhimento pela instituição: 4</p>                 {" "}
          <p>Quantidade/qualidade de tempo passado com o especialista: 4</p>   
                       {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 4
          </p>
                           {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 3
          </p>
                           {" "}
          <p>
            Sentes-te confortável para enviar casos de consulta / revisão ao
            especialista? Sim
          </p>
        </>
      ),
    },
    {
      title: "Área de Especialização: Patologia do sistema digestivo",
      text: (
        <>
          <p>Instituição e país: John Hopkins Medicine Hospital, EUA</p>       
                    <p>Avaliação global do estágio: 3</p>                 {" "}
          <p>Especialista Orientador: Montgomery E</p>                 {" "}
          <p>Língua: Inglês</p>                 {" "}
          <p>Duração do Estágio (meses): 1</p>                 {" "}
          <p>Antecedência com que se marcou o estágio: 6 meses</p>             
              <p>Modo de contacto: Por intermédio de um especialista</p>       
                   {" "}
          <p>
            Financiamento: Conta própria/ outras organizações/programas
            nacionais
          </p>
                            <p>Taxa de inscrição: Não</p>                 {" "}
          <p>Número de casos de diagnóstico observados: 1200</p>               
           {" "}
          <p>
            Pessoa com quem se dava saídas dos casos (maioritariamente): Era
            variável, com o especialista e o grupo do especialista
          </p>
                           {" "}
          <p>
            Formato da observação de casos: Observação de casos simultaneamente
            com o especialista/
          </p>
                           {" "}
          <p>
            Preparação prévia de casos e saídas posteriormente (sem escrita de
            relatório)
          </p>
                           {" "}
          <p>
            Atividades desenvolvidas durante o estágio: Observação de casos de
            rotina e de coleção/ Participação em reuniões
          </p>
                           {" "}
          <p>
            Tipo de reuniões habituais: Lâminas / Casos clínicos/ Journal Club/
            Unknown Case Conference
          </p>
                            <p>Número de reuniões semanais: 8</p>               
            <p>Estímulo para participação ativa em reuniões: 4</p>             
              <p>Produção científica: Não participei em produção científica</p> 
                         {" "}
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 1
          </p>
                           {" "}
          <p>
            Estímulo para realização de comunicações orais, posters ou capítulos
            de livros: 1
          </p>
                           {" "}
          <p>Oportunidade de frequentar cursos, congressos ou workshops: Sim</p>
                           {" "}
          <p>
            Outros comentários: Só estive um mês nesta instituição. O "sign-out"
            muda drasticamente de semana em semana: houve semanas em que vi os
            casos com a Dra EM, mas semanas em que só estavam disponíveis recém
            especialistas (do primeiro e do segundo ano) que, como é óbvio, não
            sabiam resolver da melhor forma os casos mais complicados. O estágio
            inclui também patologia tumoral dos tecidos moles (casos de consulta
            que a Dra EM recebe todos os dias). Consegui aprofundar o meu
            conhecimento na patologia hepática tumoral e inflamatória, tendo
            visto a maioria dos casos com o Dr Anders, super disponível e
            preparado. A reuniões são muitas e interessantes, sobretudo a
            reunião dos casos "unknown" (também disponíveis online no website do
            JH), que pode ser visualizada no dia anterior (pedindo com alguma
            frequência). O grupo da EM organiza uma reunião de casos
            interessantes da gastro cada semana.
          </p>
                           {" "}
          <p>Alojamento: Alojamento local (AirBNB, classificados, etc.)</p>     
                      <p>Facilidade de encontrar alojamento: 1</p>             
              <p>Custo mensal do alojamento: 900</p>                 {" "}
          <p>
            Comentários acerca do custo e da qualidade de vida: A zona do
            Hospital não é segura, é muito pouco recomendado andar sozinhos após
            o por de sol. Há guardas em todos os cantos do hospital, às quais
            pode ser pedido ajuda para qualquer problema ou até para ir para
            casa.
          </p>
                            <p>Sessões / eventos sociais: Não</p>               
            <p>Acolhimento pela instituição: 2</p>                 {" "}
          <p>Quantidade/qualidade de tempo passado com o especialista: 3</p>   
                       {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 3
          </p>
                           {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 1
          </p>
                           {" "}
          <p>
            Sentes-te confortável para enviar casos de consulta / revisão ao
            especialista? Sim
          </p>
        </>
      ),
    },
    {
      title: "Área de Especialização: Patologia hepática",
      text: (
        <>
          <p>Instituição e país: Royal Free Hospital, Reino unido</p>
          <p>Avaliação global do estágio: 5</p>
          <p>Especialista Orientador: Professor Alberto Quaglia</p>
          <p>Língua: Inglês</p>
          <p>Duração do Estágio (meses): 1</p>
          <p>Antecedência com que se marcou o estágio: 6 meses</p>
          <p>Modo de contacto: Por intermédio de um especialista</p>
          <p>Financiamento: Conta própria</p>
          <p>Número de casos de diagnóstico observados: 300</p>
          <p>
            Pessoa com quem se dava saídas dos casos (maioritariamente): O
            especialista responsável pelo estágio
          </p>
          <p>
            Formato da observação de casos: Observação de casos simultaneamente
            com o especialista/ Preparação prévia de casos e saídas
            posteriormente (sem escrita de relatório)
          </p>
          <p>
            Atividades desenvolvidas durante o estágio: Observação de casos de
            rotina e de coleção/ Escrita científica/ Participação em reuniões
          </p>
          <p>
            Tipo de reuniões habituais: Multidisciplinares / Decisão
            Terapêutica, Lâminas / Casos clínicos
          </p>
          <p>Número de reuniões semanais: 5</p>
          <p>Estímulo para a participação ativa em reuniões: 4</p>
          <p>
            Produção científica: Participei num projeto a decorrer no serviço
          </p>
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 3
          </p>
          <p>
            Estímulo para realização de comunicações orais, posters ou capítulos
            de livros: 1
          </p>
          <p>Oportunidade de frequentar cursos, congressos ou workshops: Sim</p>
          <p>Alojamento: Alojamento local (AirBNB, classificados, etc.)</p>
          <p>Facilidade de encontrar alojamento: 1</p>
          <p>Custo mensal do alojamento: 1000</p>
          <p>Sessões / eventos sociais: Não</p>
          <p>Acolhimento pela instituição: 3</p>
          <p>Quantidade/qualidade de tempo passado com o especialista: 4</p>
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 3
          </p>
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 2
          </p>
          <p>
            Sentes-te confortável para enviar casos de consulta / revisão ao
            especialista? Sim
          </p>
        </>
      ),
    },
    {
      title: "Área de Especialização: Patologia do sistema reprodutor feminino",
      text: (
        <>
          <p>Instituição e país: MGH, Boston</p>                 {" "}
          <p>Avaliação global do estágio: 5</p>                 {" "}
          <p>Especialista Orientador: Dra Esther Oliva</p>                 {" "}
          <p>Língua do Estágio: Inglês</p>                 {" "}
          <p>Duração do Estágio (meses): 2</p>                 {" "}
          <p>Antecedência com que se marcou o estágio: 1 ano</p>               
            <p>Modo de contacto: Por intermédio de um especialista</p>         
                 {" "}
          <p>Financiamento: Conta própria e a minha instituição formativa</p>   
                        <p>Taxa de inscrição: 1500 dólares por mês</p>         
                  <p>Número de casos de diagnóstico observados: 500</p>         
                 {" "}
          <p>
            Pessoa com quem se dava saídas dos casos (maioritariamente): O
            especialista responsável pelo estágio
          </p>
                           {" "}
          <p>
            Formato da observação de casos: Preparação prévia de casos e saídas
            posteriormente (sem escrita de relatório)
          </p>
                           {" "}
          <p>
            Atividades desenvolvidas durante o estágio: Observação de casos de
            rotina e de coleção/ Escrita científica/ Participação em reuniões
          </p>
                           {" "}
          <p>
            Tipo de reuniões habituais: Multidisciplinares / Decisão
            Terapêutica, Lâminas / Casos clínicos/ Journal Club/ Lâminas de
            congelação
          </p>
                            <p>Número de reuniões semanais: 7</p>               
            <p>Estímulo para a participação ativa em reuniões: 1</p>           
               {" "}
          <p>
            Produção científica: Participei num projeto a decorrer no serviço
          </p>
                           {" "}
          <p>
            Estímulo para escrita científica / publicação de artigos
            científicos: 3
          </p>
                           {" "}
          <p>
            Estímulo para realização de comunicações orais, posters ou capítulos
            de livros: 3
          </p>
                           {" "}
          <p>Oportunidade de frequentar cursos, congressos ou workshops: Sim</p>
                           {" "}
          <p>Alojamento: Alojamento local (AirBNB, classificados, etc.)</p>     
                      <p>Facilidade de encontrar alojamento: 5</p>             
              <p>Custo mensal do alojamento: 1500</p>                 {" "}
          <p>Sessões / eventos sociais: Não</p>                 {" "}
          <p>Acolhimento pela instituição: 3</p>                 {" "}
          <p>Quantidade/qualidade de tempo passado com o especialista: 4</p>   
                       {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade diagnóstica: 4
          </p>
                           {" "}
          <p>
            Contributo do estágio para a melhoria da capacidade de escrita
            científica: 4
          </p>
                           {" "}
          <p>
            Sentes-te confortável para enviar casos de consulta / revisão ao
            especialista? Sim
          </p>
        </>
      ),
    },
  ];

  return (
    <Wrapper>
      <Helmet>
        <title>
          Estagios NISPAP | Núcleo de Internos Sociedade Portuguesa de Anatomia
          Patológica - NISPAP
        </title>
        <meta
          name="description"
          content="Links úteis. Tudo o que queríamos saber sobre os estágios internacionais. Apresentamos de seguida uma listagem internacional de instituições que poderás contactar para realizar estágio."
        />
        <meta
          name="keywords"
          content="nispap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      <Container>
        <PageTitle
          title="Estágios"
          nispap={true}
          path={[
            {
              text: "NISPAP",
              link: "/nispap",
            },
            {
              text: "Utilitários",
              link: "/nispap/utilitarios",
            },
            {
              text: "Estágios",
            },
          ]}
          imageDesktop={require("../../../../Assets/Images/NISPAP/img-estagio@2x.png")}
          imageMobile={require("../../../../Assets/Images/NISPAP/img-estagio.png")}
          sidebar={<SidebarMenu navItens={SidebarMenuLinks} nispap={true} />}
        />

        <FlexInRow gap="6.146vw">
          <FlexInColumn gap="12.396vw" mobile="column">
            <FlexInRow>
              <PageText title="Tudo o que queríamos saber sobre os estágios internacionais" />
              <Text>
                Apresentamos de seguida uma listagem internacional de
                instituições que poderás contactar para realizar estágio.
              </Text>
              <Text>
                Caso tenhas realizado um estágio em Anatomia Patológica colabora
                connoscoe e preenche o{" "}
                <Link to="/nispap/contactos">formulário</Link> apresentado no
                fim desta página! O teu contributo é fundamental para a melhoria
                da formação de todos os internos.
              </Text>
            </FlexInRow>
          </FlexInColumn>

          <SectionCollapseContent>
            <Collapse defaultActiveKey={["1"]} onChange={onChange}>
              {listEstagios.map((item, index) => {
                return (
                  <Panel header={item.title} key={"panel" + index}>
                    {item.text}
                  </Panel>
                );
              })}
            </Collapse>
          </SectionCollapseContent>
        </FlexInRow>
      </Container>
    </Wrapper>
  );
}
