import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import Container from "../../../Components/Container";
import FlexInRow from "../../../Components/FlexInRow";
import FlexInColumn from "../../../Components/FlexInColumn";
import PageTitle from "../../../Components/PageTitle";
import SupportedBySpap from "../../../Components/SupportedBySpap";
import { useState, useEffect } from "react";

import { Button, Divider, Space, Image, Typography, Row, Col } from "antd";
import JuntarseClube from "../../../Components/JuntarseClube";
import SidebarMenu from "../../../Components/SidebarMenu";

const { Paragraph } = Typography;

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #000000;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: left;
  width: 100%;
  margin-top: 7.813vw;

  > .content {
    padding-bottom: 5.208vw;
  }

  img {
    width: 100%;
  }

  .clube-description img {
    max-width: 400px;
    display: inline-block;
    margin-right: auto;
  }

  .clube-description iframe {
    height: 30vw;
    width: 60%;
  }

  .link {
    color: ${(props) => props.theme.orange};
    transition: 0.3s ease;
  }

  .link:hover {
    color: ${(props) => props.theme.orangeHover};
  }

  .cta {
    background-color: ${(props) => props.theme.orange};
    border: none;
    color: ${(props) => props.theme.white};
    transition: 0.3s ease;
  }

  .cta:hover {
    background-color: ${(props) => props.theme.orangeHover};
    color: ${(props) => props.theme.whiteHover};
  }

  .sidebar__cta {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0.521vw;
    color: #0c1151;
    opacity: 1;
    text-decoration: none;
    text-align: center;
    letter-spacing: 0px;
    transition: 0.3s ease;
    font-size: 1.146vw;
    font-weight: bold;
    line-height: 1.51vw;
    border: 0.156vw solid #0c1151;
    padding: 0.781vw 1.563vw;
  }

  .sidebar__cta:hover {
    background-color: #0c1151;
    border-color: transparent;
    color: #e5e5e5;
  }

  .sidebar__cta.blue {
    background: #0c1151 0% 0% no-repeat padding-box;
    border: 0.156vw solid transparent;
    color: #ffffff;
  }

  .sidebar__cta.blue:hover {
    background-color: #8688a8;
    color: #e5e5e5;
  }

  .bar {
    height: 1px;
    width: 66.854vw;
    background-color: #d6cdf7;
    opacity: 1;
    margin: auto;
  }

  .manager__image {
    display: block;
    width: 89px;
  }

  .card-returns {
    padding: 30px 0;

    picture {
      display: block;
      height: 200px;
      max-width: 300px;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    .clube-description iframe {
      height: 400px;
      width: 100%;
    }

    .clube-description img {
      display: block;
    }

    span {
      line-height: 14px;
    }
  }
`;

const Title = styled.h2`
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;

  @media (max-width: 992px) {
    font-size: 3.333vw;
    line-height: 4.444vw;
  }
`;

export default function ClubeNovo(props) {
  const { slug } = useParams();
  const [clube, setClube] = useState("");
  const [isModalSuccessOpen, setModalSuccessOpen] = useState(false);
  const [isModalEnrollOpen, setModalEnrollOpen] = useState(false);
  const [isModalNoEnrollOpen, setModalNoEnrollOpen] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [subtitleModal, setSubtitleModal] = useState(false);
  const [userEnrolled, setUserEnrolled] = useState("");
  const [membrosClube, setMembrosClube] = useState("");
  const [gestoresClube, setGestoresClube] = useState("");
  const [tornarMembro, setTornarMembro] = useState(false);

  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const checkUserEnrolled = (membrosClubes) => {
    let myUser = session_storage.all_data.user_session;
    let encontrouUser = false;
    membrosClubes.length > 0 &&
      membrosClubes.map((membro) => {
        if (membro.user_id == myUser.id) {
          encontrouUser = true;
        }
      });
    setUserEnrolled(encontrouUser);
  };

  useEffect(() => {
    fetch(`https://api.spap.pt/api/get-clube/` + slug.split("-").pop(), {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0) {
          console.log("nenhum clube");
        }

        getClube(data.clube);
        checkUserEnrolled(data.membrosClube);
        getGestoresClube(data.membrosClube, data.users);
      })
      .catch((error) => console.error(error));
  }, []);

  const getClube = (thisClub) => {
    let myClube = thisClub[0];
    myClube.url = myClube.url
      ? "https://api.spap.pt/clubes/" + myClube.url
      : "https://api.spap.pt/clubes/clube-estudo-default.svg";

    setClube(myClube);
  };
  const getGestoresClube = (gestores, users) => {
    setGestoresClube([]);
    let gestoresDesteClube = [];

    gestores.sort((a, b) => a.ordem - b.ordem);
    gestores &&
      gestores.length > 0 &&
      gestores.map((gestor) => {
        users &&
          users.length > 0 &&
          users.map((user) => {
            if (user.id == gestor.user_id && gestor.member_type == 2) {
              gestoresDesteClube.push(user);
            }
          });
      });

    const myOrdem = gestoresDesteClube.sort((a, b) => a.ordem - b.ordem);

    setGestoresClube(myOrdem);
  };

  const setCapitalize = (str) => {
    var splitStr = str.toLowerCase().split(" ");

    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you

      // Assign it back to the array

      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }

    // Directly return the joined string

    return splitStr.join(" ");
  };

  const SidebarMenuLinks = userEnrolled
    ? [
        {
          title: "Membros",
          link: "/socios/clubes-de-estudo/" + slug + "/membros",
        },
        {
          title: "Eventos",
          link: "/socios/clubes-de-estudo/" + slug + "/eventos",
        },
        {
          title: "Galeria",
          link: "/socios/clubes-de-estudo/" + slug + "/galeria",
        },
        {
          title: "Documentos",
          link: "/socios/clubes-de-estudo/" + slug + "/documentos",
        },
      ]
    : [
        {
          title: "Membros",
          link: "/socios/clubes-de-estudo/" + slug + "/membros",
        },
        {
          title: "Eventos",
          link: "/socios/clubes-de-estudo/" + slug + "/eventos",
        },
        {
          title: "Galeria",
          link: "/socios/clubes-de-estudo/" + slug + "/galeria",
        },
        {
          title: "Documentos",
          link: "/socios/clubes-de-estudo/" + slug + "/documentos",
        },
      ];

  const handleUserEnrolled = (e) => {
    setUserEnrolled(e);
  };

  const handleClickTornarMembro = () => {
    if (!userEnrolled) {
      setTornarMembro(true);
    }
  };

  return (
    <Wrapper>
      <Container className="content">
        <PageTitle
          title={clube.name}
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "Sócios",
              link: "/socios",
            },
            {
              text: "Clubes de estudo",
              link: "/socios/clubes-de-estudo",
            },
            {
              text: clube.name,
            },
          ]}
          imageDesktop={clube.url}
          imageMobile={clube.url}
          imageAlt={clube.name}
          border={false}
          sidebar={
            <SidebarMenu
              enrolled={(e) => handleUserEnrolled(e)}
              navItens={SidebarMenuLinks}
              className="sidebar"
              clube={true}
              margin="-120px"
              eventClick={tornarMembro}
              cancelClick={(e) => setTornarMembro(false)}
            />
          }
          clubes={true}
        />

        <FlexInRow>
          <Row gutter={[30, 8]}>
            <Col span={24}>
              <Space direction="vertical" style={{ width: "100%" }}>
                {gestoresClube.length > 0 && (
                  <Row>
                    <Col span={19}>
                      <Divider orientation="left" orientationMargin="0">
                        <Title>Equipa coordenadora</Title>
                      </Divider>

                      <Row
                        align="initial"
                        className="row-wrap"
                        gutter={[20, 20]}
                      >
                        {gestoresClube.map((item, index) => {
                          return (
                            <Col xs={24} lg={8} md={8} key={index}>
                              <Space align="start">
                                {item.avatar ? (
                                  <>
                                    <div
                                      style={{
                                        borderRadius: "7px",
                                        border: "3px solid #0C1151",
                                        backgroundPosition: "center center",
                                        backgroundSize: "cover",
                                        backgroundImage:
                                          "url(https://api.spap.pt/avatars/" +
                                          item.avatar +
                                          ")",
                                        width: "85px",
                                        height: "85px",
                                      }}
                                    ></div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      style={{
                                        borderRadius: "7px",
                                        border: "3px solid #0C1151",
                                        backgroundPosition: "center center",
                                        backgroundSize: "contain",
                                        backgroundImage: `url(https://api.spap.ptsocio-default.svg)`,
                                        width: "85px",
                                        height: "85px",
                                      }}
                                    ></div>
                                  </>
                                )}
                                <Space direction="vertical">
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {item.name}{" "}
                                    {item.apelido ? item.apelido : ""}
                                  </span>
                                  <span style={{ fontSize: "12px" }}>
                                    {setCapitalize(item.instituicao)}
                                  </span>
                                </Space>
                              </Space>
                            </Col>
                          );
                        })}
                      </Row>
                    </Col>
                  </Row>
                )}
                <Divider orientation="left" orientationMargin="0">
                  <Title>Participe!</Title>
                </Divider>

                <Row gutter={[30, 20]} className="card-returns">
                  <Col span={24}>
                    <Row gutter={[30, 30]}>
                      <Col span={12} xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Space align="end">
                          <picture>
                            <source
                              srcSet={require("../../../Assets/Images/img-fetoplacentar-01@2x.png")}
                              media="(min-width: 990px)"
                            />
                            <img
                              src={require("../../../Assets/Images/img-fetoplacentar-01.png")}
                            />
                          </picture>
                          <Button
                            className="cta"
                            onClick={(e) => handleClickTornarMembro()}
                          >
                            {userEnrolled
                              ? "Já pertence a este clube!"
                              : "Tornar-se membro"}
                          </Button>
                        </Space>
                      </Col>
                      <Col span={12} xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Space align="end">
                          <picture>
                            <source
                              srcSet={require("../../../Assets/Images/img-fetoplacentar-02@2x.png")}
                              media="(min-width: 990px)"
                            />
                            <img
                              src={require("../../../Assets/Images/img-fetoplacentar-02.png")}
                            />
                          </picture>
                          <Space direction="vertical">
                            <Title>Contacte-nos!</Title>
                            {clube.name == "Patologia Digestiva" && (
                              <>
                                <span>
                                  Email:{" "}
                                  <a
                                    href="mailto:clubepatologiadigestiva@spap.pt"
                                    className="link"
                                  >
                                    clubepatologiadigestiva@spap.pt
                                  </a>
                                </span>
                              </>
                            )}
                            {clube.name == "Patologia Fetoplacentar" && (
                              <>
                                <span>
                                  Email:{" "}
                                  <a
                                    href="mailto:clubefetoplacentar@spap.pt"
                                    className="link"
                                  >
                                    clubefetoplacentar@spap.pt
                                  </a>
                                </span>
                              </>
                            )}
                            {clube.name == "Patologia Ginecológica" && (
                              <>
                                <span>
                                  Email:{" "}
                                  <a
                                    href="mailto:clubepatologiaginecologica@spap.pt"
                                    className="link"
                                  >
                                    clubepatologiaginecologica@spap.pt
                                  </a>
                                </span>
                              </>
                            )}
                            {clube.name == "Patologia Urogenital" && (
                              <>
                                <span>
                                  Email:{" "}
                                  <a
                                    href="mailto:clubepatologiaurogenital@spap.pt"
                                    className="link"
                                  >
                                    clubepatologiaurogenital@spap.pt
                                  </a>
                                </span>
                              </>
                            )}
                            {clube.name == "Sarcomas" && (
                              <>
                                <span>
                                  Email:{" "}
                                  <a
                                    href="mailto:clubesarcomas@spap.pt"
                                    className="link"
                                  >
                                    clubesarcomas@spap.pt
                                  </a>
                                </span>
                              </>
                            )}
                            {clube.name != "Sarcomas" &&
                              clube.name != "Patologia Fetoplacentar" &&
                              clube.name != "Patologia Digestiva" &&
                              clube.name != "Patologia Urogenital" &&
                              clube.name != "Patologia Ginecológica" && (
                                <>
                                  <span>
                                    Email:{" "}
                                    <a
                                      href="mailto:socios@spap.pt"
                                      className="link"
                                    >
                                      socios@spap.pt
                                    </a>
                                  </span>
                                </>
                              )}
                          </Space>
                        </Space>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row align="initial" className="row-wrap" gutter={[20, 20]}>
                  <Col span={24}>
                    <div
                      className="clube-description"
                      style={{ fontSize: "14px", lineHeight: "1.4" }}
                      dangerouslySetInnerHTML={{ __html: clube.descricao }}
                    ></div>
                  </Col>
                </Row>
              </Space>
            </Col>
          </Row>
        </FlexInRow>
      </Container>

      <SupportedBySpap title="Veja Também" />
    </Wrapper>
  );
}
