import styled from "styled-components";

const Wrapper = styled.div`
  margin-left: 7.292vw;
  margin-right: 7.292vw;
  padding-left: 0;
  padding-right: 0;

  @media (max-width: 992px) {
    margin-left: 20px;
    margin-right: 20px;
  }

  @media (min-width: 1920px) {
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 1640px !important;
  }
`

export default function Container( props ) {
  return <Wrapper className={props.className}>{props.children}</Wrapper>
}
