import { React, useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

import Container from "../Container";
import Navigation from "../Navigation";
import CtaLogin from "../CtaLogin";
import SubNavigation from "../SubNavigation";

import Logo2x from "../../Assets/Images/SPAP-logo-white@2x.png";
import Logo from "../../Assets/Images/SPAP-logo-white.png";
import { ReactComponent as LogoSpap } from "../../Assets/Images/SPAP-logo-white.svg";
import NispapLogo2x from "../../Assets/Images/NISPAP-logotipo@2x.png";
import NispapLogo from "../../Assets/Images/NISPAP-logotipo.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.header`
  background-color: ${(props) =>
    props.slug === "nispap" ? "#000000" : "#0C1151"};
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 888;
  box-shadow: 0px 8px 20px #00000025;

  > .content {
    display: grid;
    grid-column-gap: 3.385vw;
    grid-template-columns: 1fr 4fr 1fr;
    height: 90px;
  }

  picture {
    align-items: center;
    justify-content: center;
    display: flex;
  }

  img {
    width: 100%;
    ${(props) =>
      props.slug === "nispap" &&
      `
      width: 3.269vw;
    `}
  }

  .logo {
    display: flex;
    align-items: center !important;
  }

  .new-logo {
    width: 100%;
  }

  @media (min-width: 1920px) {
    > .content {
      gap: 92px;
      grid-template-columns: 265px 1fr 192px;
    }

    li {
      margin: 0 25px;
    }

    li:first-child {
      margin-left: 0;
    }

    li:last-child {
      margin-right: 0;
    }

    li a {
      font-size: 18px;
      line-height: 26px;
    }
  }

  @media (max-width: 992px) {
    > .content {
      grid-column-gap: 4.778vw;
      grid-template-columns: 35.556vw 1fr 8.611vw;
      padding: 1.667vw 0;
    }

    #openMenuIcon {
      font-size: 5.556vw;
      place-self: center flex-end;
      padding: 8px 4px 8px 8px;
    }

    #closeMenuIcon {
      font-size: 6.556vw;
      place-self: center flex-end;
      position: fixed;
      right: 15px;
      top: 15px;
      padding: 10px;
      z-index: 888;
      width: auto;
    }

    .logo-nispap {
      img {
        width: 33px;
      }
    }
  }
`;

export default function Header(props) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [openMenuStatus, setOpenMenuStatus] = useState(false);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    !(windowWidth > 992) && setOpenMenuStatus(false);
  }, [location.pathname]);

  return (
    <Wrapper slug={location.pathname.split("/")[1]}>
      <Container className="content">
        <Link
          className="logo"
          to={location.pathname.split("/")[1] === "nispap" ? "/nispap" : "/"}
        >
          {location.pathname.split("/")[1] === "nispap" ? (
            <picture
              className={
                location.pathname.split("/")[1] === "nispap" && "logo-nispap"
              }
            >
              <source
                srcSet={
                  location.pathname.split("/")[1] === "nispap"
                    ? NispapLogo2x
                    : LogoSpap
                }
                media="(min-width: 990px)"
              />
              <img
                src={
                  location.pathname.split("/")[1] === "nispap"
                    ? NispapLogo
                    : LogoSpap
                }
                alt={
                  location.pathname.split("/")[1] === "nispap"
                    ? "NISPAP Logo"
                    : "SPAP Logo"
                }
              />
            </picture>
          ) : (
            <LogoSpap className="new-logo" />
          )}
        </Link>
        <Navigation openMenu={openMenuStatus}>
          {windowWidth <= 992 && <SubNavigation />}
        </Navigation>
        {windowWidth > 992 ? (
          <CtaLogin />
        ) : (
          <>
            <CtaLogin mobile={true} />
            {openMenuStatus ? (
              <FontAwesomeIcon
                icon={faXmark}
                id="closeMenuIcon"
                onClick={(e) => {
                  setOpenMenuStatus(false);
                }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faBars}
                id="openMenuIcon"
                onClick={(e) => {
                  setOpenMenuStatus(true);
                }}
              />
            )}
          </>
        )}
      </Container>
      {windowWidth > 992 && <SubNavigation />}
    </Wrapper>
  );
}
