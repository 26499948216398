import styled from 'styled-components'
import Sidebar from '../../../Components/Backoffice/Sidebar'
import Header from '../../../Components/Backoffice/Header'
import Footer from '../../../Components/Backoffice/Footer'

import CardEventos from '../../../Components/Backoffice/CardEventos'

const Wrapper = styled.main`
  background-color: #e8e9f3;
  display: grid;
  grid-template-columns: 7.083vw 1fr;
  min-height: 100vh;

  @media (max-width: 992px) {
  }
`

const Content = styled.div`
  padding: 1.771vw;
`

export default function Eventos (props) {

  document.title = 'SPAP - Eventos'
  return (
    <Wrapper>
      <div>
        <Sidebar />
      </div>
      <Content>
        <Header />
        <CardEventos />
        <Footer />
      </Content>
    </Wrapper>
  )
}
