import { Link } from "react-router-dom";
import styled from "styled-components";

import Container from "../../Components/Container";
import FlexInRow from "../../Components/FlexInRow";
import { Helmet } from "react-helmet";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #000000;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;
  overflow-x: hidden;
  padding: 20px 0;

  img {
    width: 100%;
  }

  @media (max-width: 992px) {
    margin-top: 12.222vw;
  }
`;

export default function TermosCondicoes(props) {
  return (
    <Wrapper>
      <Helmet>
        <title>
          Termos e Condições | Sociedade Portuguesa de Anatomia Patológica -
          SPAP
        </title>
        <meta
          name="description"
          content="Anatomia Patológica, Nacionais | Anatomia Patológica, Internacionais | Clínicas, Nacionais"
        />
        <meta
          name="keywords"
          content="spap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      <Container>
        <FlexInRow>
          <h1>Termos e Condições</h1>
          <h2>Sociedade Portuguesa de Anatomia Patológica</h2>
          <p>1. Termos</p>
          <p>
            Ao acessar ao site Sociedade Portuguesa de Anatomia Patológica,
            concorda em cumprir estes termos de serviço, todas as leis e
            regulamentos aplicáveis e concorda que é responsável pelo
            cumprimento de todas as leis locais aplicáveis. Se você não
            concordar com algum desses termos, está proibido de usar ou acessar
            este site. Os materiais contidos neste site são protegidos pelas
            leis de direitos autorais e marcas comerciais aplicáveis.
          </p>
          <p>2. Uso de Licença</p>
          <p>
            É concedida permissão para baixar temporariamente uma cópia dos
            materiais (informações ou software) no site Sociedade Portuguesa de
            Anatomia Patológica, apenas para visualização transitória pessoal e
            não comercial. Esta é a concessão de uma licença, não uma
            transferência de título e, sob esta licença, você não pode: <br />
            modificar ou copiar os materiais; usar os materiais para qualquer
            finalidade comercial ou para exibição pública (comercial ou não
            comercial); tentar descompilar ou fazer engenharia reversa de
            qualquer software contido no site Sociedade Portuguesa de Anatomia
            Patológica remover quaisquer direitos autorais ou outras notações de
            propriedade dos materiais; ou transferir os materiais para outra
            pessoa ou ‘espelhe’ os materiais em qualquer outro servidor. Esta
            licença será automaticamente rescindida se você violar alguma dessas
            restrições e poderá ser rescindida por Sociedade Portuguesa de
            Anatomia Patológica a qualquer momento. Ao encerrar a visualização
            desses materiais ou após o término desta licença, você deve apagar
            todos os materiais baixados em sua posse, seja em formato eletrónico
            ou impresso.
          </p>
          <p>3. Isenção de responsabilidade</p>
          <p>
            Os materiais no site da Sociedade Portuguesa de Anatomia Patológica
            são fornecidos ‘como estão’. Sociedade Portuguesa de Anatomia
            Patológica não oferece garantias, expressas ou implícitas, e, por
            este meio, isenta e nega todas as outras garantias, incluindo, sem
            limitação, garantias implícitas ou condições de comercialização,
            adequação a um fim específico ou não violação de propriedade
            intelectual ou outra violação de direitos. Além disso, o Sociedade
            Portuguesa de Anatomia Patológica não garante ou faz qualquer
            representação relativa à precisão, aos resultados prováveis ou à
            confiabilidade do uso dos materiais em seu site ou de outra forma
            relacionado a esses materiais ou em sites vinculados a este site.
          </p>
          <p>4. Limitações</p>
          <p>
            Em nenhum caso o Sociedade Portuguesa de Anatomia Patológica ou seus
            fornecedores serão responsáveis por quaisquer danos (incluindo, sem
            limitação, danos por perda de dados ou lucro ou devido a interrupção
            dos negócios) decorrentes do uso ou da incapacidade de usar os
            materiais em Sociedade Portuguesa de Anatomia Patológica, mesmo que
            Sociedade Portuguesa de Anatomia Patológica ou um representante
            autorizado tenha sido notificado oralmente ou por escrito da
            possibilidade de tais danos. Como algumas jurisdições não permitem
            limitações em garantias implícitas, ou limitações de
            responsabilidade por danos conseqüentes ou incidentais, essas
            limitações podem não se aplicar a você.
          </p>
          <p>5. Precisão dos materiais</p>
          <p>
            Os materiais exibidos no site da Sociedade Portuguesa de Anatomia
            Patológica podem incluir erros técnicos, tipográficos ou
            fotográficos. Follow – The Healthcare Communication Group não
            garante que qualquer material em seu site seja preciso, completo ou
            atual. Follow – The Healthcare Communication Group pode fazer
            alterações nos materiais contidos em seu site a qualquer momento,
            sem aviso prévio. No entanto, Follow – The Healthcare Communication
            Group não se compromete a atualizar os materiais.
          </p>
          <p>6. Links</p>
          <p>
            A Sociedade Portuguesa de Anatomia Patológica não analisou todos os
            sites vinculados ao seu site e não é responsável pelo conteúdo de
            nenhum site vinculado. A inclusão de qualquer link não implica
            endosso por Sociedade Portuguesa de Anatomia Patológica do site. O
            uso de qualquer site vinculado é por conta e risco do usuário.
          </p>
          <p>Modificações</p>
          <p>
            A Sociedade Portuguesa de Anatomia Patológica pode revisar estes
            termos de serviço do site a qualquer momento, sem aviso prévio. Ao
            usar este site, você concorda em ficar vinculado à versão atual
            desses termos de serviço.
          </p>
          <p>Lei aplicável</p>
          <p>
            Estes termos e condições são regidos e interpretados de acordo com
            as leis do Sociedade Portuguesa de Anatomia Patológica e você se
            submete irrevogavelmente à jurisdição exclusiva dos tribunais
            naquele estado ou localidade.
          </p>
          <p>Esta política é efetiva a partir de Janeiro/2023.</p>
        </FlexInRow>
      </Container>
    </Wrapper>
  );
}
