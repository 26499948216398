import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'

import styled from 'styled-components'

const Wrapper = styled.section`
  display: grid;

  .pagination {
    place-self: center;
    display: flex;
    flex-direction: row;
    gap: 1.302vw;

    li a {
      border: 3px solid #0C1151;
      border-radius: 0.521vw;
      align-items: center;
      justify-content: center;
      display: flex;
      height: 2.969vw;
      width: 3.402vw;
      cursor: pointer;
      transition: .3s ease;
    }

    li a:hover ,
    li.selected a {
      background-color: #F88436;
      border-color: #F88436;
      color: #FFFFFF;
    }

    li a {
      font-size: 1.146vw;
      font-weight: bold;
      line-height: 1.510vw;
      letter-spacing: 0px;
      color: #0C1151;
      transition: .3s ease;
    }

    .previous, .next {
      display: none;
    }
  }

  @media (max-width: 992px) {
    .pagination {
      gap: 15px;

      li a {
        border-radius: 8px;
        font-size: 18px;
        height: 40px;
        line-height: 22px;
        width: 40px;
      }
    }
  }
`

export default function PaginatedItems( props ) {

  const [currentItems, setCurrentItems] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [itemsPerPage, setItemsPerPage] = useState(window.innerWidth > 990 ? 5 : 2)

  useEffect(() => {
    const endOffset = itemOffset + props.itemsPerPage
    setCurrentItems(props.items.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(props.items.length / props.itemsPerPage))
  }, [itemOffset, props.itemsPerPage, props.items.length])
  
  const handlePageClick = (event) => {
    const newOffset = event.selected * props.itemsPerPage % props.items.length
    setItemOffset(newOffset)
    props.onchange(props.items.slice(newOffset, (newOffset + props.itemsPerPage)))
  }

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth)
      if (windowWidth <= 1280) {
        if (windowWidth <= 990) {
          setItemsPerPage(2)
        } else {
          setItemsPerPage(3)
        }
      } else {
        setItemsPerPage(5)
      }
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  })

  return (
    <Wrapper>
      <ReactPaginate
        breakLabel="..."
        onPageChange={handlePageClick}
        pageRangeDisplayed={itemsPerPage}
        pageCount={pageCount}
        className="pagination"
      />
    </Wrapper>
  )
}