import React from "react"
import {
  BrowserRouter as Router
} from "react-router-dom"
import Routes from './Routes'
import { GlobalStyle } from './Config'

export default function App() {
  return (
    <Router>
      <GlobalStyle />
      <Routes />
    </Router>
  )
}
