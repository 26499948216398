import { createGlobalStyle } from 'styled-components'

export function styledTheme( location ) {
  const nispap = location.pathname.includes('nispap') ? true : false
  
  const theme = {
    blue : nispap ? '#225E79' : '#0C1151',
    blueHover : nispap ? '#124E69' : '#0A1031',
    blueLight : '#494D7D',
    orange : '#F88436',
    orangeHover : '#E87426',
    white : '#FFFFFF',
    whiteHover : '#ECECEC'
  }
  
  return theme
}

export const GlobalStyle = createGlobalStyle`
  h1, h2, h3, h4, h5 {
    font-family: 'Fira Sans', sans-serif;
  }

  p {
    text-align: left;
    color: #0000000;
  }

  .ant-btn-primary.cta {
    background-color: #494D7D;
    border: none;
    color: #FFFFFF;
    transition: .3s ease;
  }

  .ant-btn-primary.cta:hover {
    background-color: #1AD598 !important;
    color: #FFFFFF !important;
  }

  li.ant-pagination-item {
    border: 1px solid #D9E1E7CC;
  }

  li.ant-pagination-item.ant-pagination-item-active {
    background-color: #8688A8;
    border-color: #8688A8;
    color: #FFFFFF;
  }

  .dropdown-header-menu .ant-dropdown-menu {
    padding: 10px;
  }

  .dropdown-header-menu li.ant-dropdown-menu-item {
    margin: 5px 0 !important;
    text-align: center !important;
  }

  .dropdown-header-menu li.ant-dropdown-menu-item:first-child {
    margin-top: 5px !important;
  }

  .dropdown-header-menu li.ant-dropdown-menu-item:last-child {
    color: #EA5566;
    margin-bottom: 5px !important;
  }

  .dropdown-header-menu li.ant-dropdown-menu-item a {
    text-decoration: none;
  }

  .dropdown-header-menu li.ant-dropdown-menu-item:hover {
    background-color: #0C1151 !important;
    color: #FFFFFF;
  }

  .dropdown-header-menu li.ant-dropdown-menu-item:last-child:hover {
    background-color: #EA5566 !important;
  }

  .link-disabled {
    cursor: no-drop;
  }

  .link-disabled * {
    opacity: .4 !important;
    pointer-events: none;
  }

  // .ant-modal-footer button * {
  //   color: #FFFFFF;
  // }

  .recaptcha-left {
    left: 0;
  }
  
  @media (max-width: 992px) {  
    p,
    .text {
      
      
    }
  }

  @media (min-width: 1920px) {
    p,
    .text {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

export const BackofficeStyle = createGlobalStyle`
  .ant-switch .ant-switch-handle {
    height: 16px !important;
    width: 16px !important;
    top: 2px !important;
  }

  .ant-modal-footer .ant-btn-primary, .ant-modal-footer .ant-btn-primary:hover {
    background: #0C1151;
  }

  .ant-switch-inner {
    background: #FEFEFE !important;
    border: 2px solid #D9E1E7;
  }

  .ant-switch-handle:not(.ant-switch-checked)::before {
    background: #D9E1E7 !important;
    border: 2px solid #FEFEFE;
  }

  .ant-switch-checked .ant-switch-inner {
    background: #494D7D !important;
  }

  .ant-switch-checked .ant-switch-handle::before {
    background: #FEFEFE !important;
    border: 2px solid #494D7D;
  }
`