import { Link } from 'react-router-dom'
import styled from 'styled-components'

import FlexInRow from '../FlexInRow'

const Wrapper = styled.section`
  padding: 2.604vw 0;
`

const Title = styled.h2`
  text-align: center;
  color: #0C1151;
  opacity: 1;
  font-size: 1.250vw;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: inherit;

  @media (max-width: 992px) {
    font-size: 5.556vw;
    line-height: 6.667vw;
  }

  @media (min-width: 1920px) {
    font-size: 40px;
    line-height: 48px;
  }
`

const Content = styled.div`
  align-items: flex-start;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3.125vw;

  @media (max-width: 992px) {
    align-items: center;
    grid-template-columns: 1fr;
    gap: 7.500vw;
  }
`

const ContentItem =styled.div`
  display: grid;
  gap: 2.396vw;
  grid-template-columns: 7.813vw 1fr;

  @media (max-width: 992px) {
    gap: 7.500vw;
    grid-template-columns: 1fr;

    picture {
      margin: auto;
      width: 33.333vw;
    }
  }

  @media (min-width: 1920px) {
    gap: 46px;
    grid-template-columns: 200px 1fr;
  }
`

const Text = styled.p`
  text-align: left;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin: 0;

  @media (max-width: 992px) {
    font-size: 3.333vw;    
    line-height: 4.444vw;
    place-self: center;
    text-align: center;
  }

  @media (min-width: 1920px) {
    font-size: 20px;
    line-height: 26px;
  }
`

const SectionMembers = styled.section`
  padding: 5.208vw 0;

  .cta {
    grid-area: cta;
    text-align: left;
    font-size: 0.938vw;
    font-weight: bold;
    line-height: 1.250vw;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    background-color: #090D3D;
    border-radius: 0.521vw;
    opacity: 1;
    transition: .3s ease;
    margin: 0 1.042vw;
    place-self: flex-start;
    padding: 1.042vw 2.083vw;
    text-decoration: none;
    align-items: center;
    justify-content: center;

    :hover {
      background-color: #8688A8;
      color: #E5E5E5;
    }
  }

  @media (min-width: 992px) {
    margin: 0 7.604vw;
  }

  @media (max-width: 992px) {
    .cta {
      border-radius: 2.778vw;
      font-size: 3.889vw;
      line-height: 5.278vw;
      padding: 2.222vw 6.111vw;
      place-self: center;
      text-align: center;
    }
  }

  @media (min-width: 1920px) {
    padding: 80px 0;

    .cta {
      border-radius: 10px;
      font-size: 20px;
      line-height: 26px;
      padding: 15px 24px;
    }
  }
`

const SectionMembersContent = styled.div`
  border: 3px solid #0C1151;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
  "image title"
  "image text"
  "image cta";
  opacity: 1;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    grid-template-areas:
    "title"
    "image"
    "text"
    "cta";
    padding: 4.444vw;
    gap: 5.556vw;
  }
`

const SectionMembersImage = styled.div`
  border-radius: 20px 0px 0px 20px;
  grid-area: image;
  overflow: hidden;

  @media (max-width: 992px) {
    border-radius: 2.778vw;
  }
`

const SectionMembersTitle = styled.h3`
  grid-area: title;
  text-align: left;
  font-size: 1.250vw;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: #0C1151;
  opacity: 1;
  margin: 1.042vw 1.042vw 0;
  place-self: flex-end flex-start;

  @media (max-width: 992px) {
    font-size: 5.556vw;
    line-height: 6.667vw;
    place-self: center;
    text-align: center;
  }

  @media (min-width: 1920px) {
    font-size: 24px;
    line-height: 28px;
    margin: 20px 20px 0;
  }
`

const SectionMembersText = styled.p`
  grid-area: text;
  text-align: left;
  font-size: 13px;
  line-height: 1.5;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin: 1.042vw;
  place-self: center flex-start;

  @media (max-width: 992px) {
    font-size: 3.333vw;    
    line-height: 4.444vw;
    place-self: center;
    text-align: center;
  }

  @media (min-width: 1920px) {
    font-size: 18px;
    line-height: 24px;
    margin: 20px;
  }
`

export default function AboutUs( props ) {

  const ContentDefault = [
    {
      'imageDesktop' : require('../../Assets/Images/icon-quemsomos-01@2x.png'),
      'imageMobile' : require('../../Assets/Images/icon-quemsomos-01.png'),
      'imageAlt' : 'Image 1',
      'text' : 'Promover o aperfeiçoamento e progresso técnico-científico da especialidade de Anatomia Patológica e dos seus associados, reunindo os médicos anatomopatologistas, os formandos nesta área e, excepcionalmente, outras individualidades que possam concorrer para o mesmo fim.'
    },
    {
      'imageDesktop' : require('../../Assets/Images/icon-quemsomos-02@2x.png'),
      'imageMobile' : require('../../Assets/Images/icon-quemsomos-02.png'),
      'imageAlt' : 'Image 2',
      'text' : 'Representar em Portugal, sociedades internacionais congéneres e delas fazer parte como membro efetivo.'
    },
    {
      'imageDesktop' : require('../../Assets/Images/icon-quemsomos-03@2x.png'),
      'imageMobile' : require('../../Assets/Images/icon-quemsomos-03.png'),
      'imageAlt' : 'Image 3',
      'text' : 'Pugnar pelo desenvolvimento da Associação e dos seus associados, através da realização de sessões científicas e culturais, designadamente, congressos, conferências, cursos, debates e encontros.'
    },
    {
      'imageDesktop' : require('../../Assets/Images/icon-quemsomos-04@2x.png'),
      'imageMobile' : require('../../Assets/Images/icon-quemsomos-04.png'),
      'imageAlt' : 'Image 4',
      'text' : 'Assegurar, zelar e fiscalizar o cumprimento dos princípios e das regras vertidos no seu Estatuto e demais estatutos e diplomas legais aplicáveis à atividade médica.'
    }
  ]

  return (
    <Wrapper>
      <FlexInRow gap="4.167vw" placeself="center" >
        <Title>CONHEÇA ALGUNS DOS NOSSOS OBJETIVOS PRINCIPAIS</Title>

        <Content gap="3.125vw">
          {
            ContentDefault.map((item, index) => {
              return (
                <ContentItem>
                  <picture>
                    <source srcSet={item.imageDesktop} media="(min-width: 990px)" />
                    <img src={item.imageMobile} alt={item.imageAlt} />
                  </picture>
                  <Text>{item.text}</Text>
                </ContentItem>
              )
            })
          }
        </Content>

        <SectionMembers>
          <SectionMembersContent>
            <SectionMembersImage>
              <picture>
                <source srcSet={require('../../Assets/Images/img-contacte-nos-quem-somos@2x.png')} media="(min-width: 990px)" />
                <img src={require('../../Assets/Images/img-contacte-nos-quem-somos.png')} alt="Seja um Membro" />
              </picture>
            </SectionMembersImage>
            <SectionMembersTitle>
              Quem pode ser membro da SPAP?
            </SectionMembersTitle>
            <SectionMembersText>
              Médicos especialistas / formação específica em Anatomia Patológica (sócios titulares), médicos de outras especialidades ou outros profissionais de saúde que demonstram especial interesse (sócios agregados) e personalidades que tenham prestado serviço relevante à SPAP ou à área da Anatomia Patológica (sócios honorários).
            </SectionMembersText>
            <Link to="/socios/conheca-as-vantagens" className='cta'>
              Vantagens de ser sócio »
            </Link>
          </SectionMembersContent>
        </SectionMembers>

      </FlexInRow>
    </Wrapper>
  )
}