import styled from 'styled-components'

const Wrapper = styled.section`
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 18px;
  margin-bottom: 10px;

  ${props => props.width && `
    width: ${props.width};
  `}

  @media (max-width: 992px) {
    width: 100%;
  }

  .p-clube {
    font-style: italic;
    color: #809FB8;
    font-size: 18px;
  }

  .h-clube {
    font-weight: bold;
    font-size: 16px;
    color: #809FB8;
    margin-bottom: 2px;
  }
`

export default function Card ( props ) {
  return(
    <Wrapper id={props.id} className={props.className} width={props.width} style={props.style}>
      {props.children}
    </Wrapper>
  )
}