import styled from "styled-components";

import Container from "../../../../Components/Container";
import PageTitle from "../../../../Components/PageTitle";
import PageText from "../../../../Components/PageText";
import FlexInColumn from "../../../../Components/FlexInColumn";
import FlexInRow from "../../../../Components/FlexInRow";
import SidebarMenu from "../../../../Components/SidebarMenu";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin: 7.813vw 0 5.208vw;

  img {
    width: 100%;
  }

  .link {
    color: ${(props) => props.theme.orange};
    font-weight: bold;
    margin-bottom: 0.854vw;
    place-self: flex-start;
    transition: 0.3s ease;
    text-decoration: none;
    text-align: left;
  }

  .link:hover {
    color: ${(props) => props.theme.orangeHover};
  }

  .link .icon {
    color: ${(props) => props.theme.blue};
    font-size: 1.354vw;
    margin-right: 5px;
    width: 1.667vw;
  }

  .link .icon path {
    fill: ${(props) => props.theme.blue};
  }

  .link:hover .icon {
    color: ${(props) => props.theme.blueHover};
  }

  @media (max-width: 992px) {
    margin-top: 120px;
  }
`;

const SectionGroups = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2.604vw;

  .grid {
    gap: 1.563vw;
    display: grid;
    grid-template-columns: auto 1fr;
  }

  .title {
    text-align: left;
    color: ${(props) => props.theme.blue};
    opacity: 1;
    font-size: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: inherit;
    margin: 0;
  }

  .bar {
    background-color: #97c1d5;
    height: 1px;
    margin: auto 0;
  }

  .image {
    width: 11.979vw;
  }

  p {
    color: #000000;
    font-size: 0.938vw;
    letter-spacing: 0px;
    line-height: 1.25vw;
    text-align: left;
  }

  .gallery-item {
    cursor: pointer;
    position: relative;
    min-width: 27.917vw;
    max-width: 27.917vw;

    .cta {
      background-color: #f88436;
      bottom: 1.042vw;
      border-radius: 360px;
      left: 1.042vw;
      opacity: 0.8;
      padding: 0.417vw 0.625vw;
      position: absolute;
      text-transform: uppercase;
    }
  }

  @media (max-width: 992px) {
    p {
    }
  }
`;

const GroupCards = styled.div`
  display: grid;
  grid-column-gap: 11.979vw;
  grid-row-gap: 3.646vw;
  grid-template-columns: 1fr 1fr 1fr;

  .item {
    border-radius: 0px 0px 1.042vw 1.042vw;
  }

  @media (max-width: 992px) {
    grid-row-gap: 20px;
    grid-template-columns: 1fr;
    margin-top: 20px;

    .item__title {
      font-size: 4.333vw;
      line-height: 5.444vw;
    }
  }
`;

const Title = styled.h2`
  text-align: center;
  font-size: 2.083vw;
  font-weight: bold;
  line-height: 2.5vw;
  letter-spacing: 0px;
  color: #225e79;
  text-transform: uppercase;
  margin: 2.604vw 0 0;

  @media (max-width: 992px) {
    font-size: 4.444vw;
    line-height: 5.555vw;
  }
`;

export default function LinksUteis(props) {
  const SidebarMenuLinks = [
    {
      title: "Estágios",
      link: "/nispap/utilitarios/estagios",
    },
    {
      title: "Reuniões interserviços",
      link: "/nispap/utilitarios/reunioes-interservicos",
    },
    {
      title: "Links úteis",
      link: "/nispap/utilitarios/links-uteis",
    },
  ];

  const ListCards = [
    {
      link: "https://www.pathologyoutlines.com/",
      imageDesktop: require("../../../../Assets/Images/NISPAP/links-pathology@2x.png"),
      imageMobile: require("../../../../Assets/Images/NISPAP/links-pathology.png"),
      imageAlt: "Pathology Outlines",
    },
    {
      link: "https://schaberg.faculty.ucdavis.edu/",
      imageDesktop: require("../../../../Assets/Images/NISPAP/links-kurts@2x.png"),
      imageMobile: require("../../../../Assets/Images/NISPAP/links-kurts.png"),
      imageAlt: "Kurts Notes",
    },
    {
      link: "https://campusvirtual.seaponline.es/",
      imageDesktop: require("../../../../Assets/Images/NISPAP/links-cursos@2x.png"),
      imageMobile: require("../../../../Assets/Images/NISPAP/links-cursos.png"),
      imageAlt: "Cursos SEAP-IAP",
    },
  ];

  const ListCards2 = [
    {
      link: "https://clinicalconnection.hopkinsmedicine.org/johns-hopkins-clinical-alliance",
      imageDesktop: require("../../../../Assets/Images/NISPAP/links-johns@2x.png"),
      imageMobile: require("../../../../Assets/Images/NISPAP/links-johns.png"),
      imageAlt: "Johns Hopkins Medice",
    },
    {
      link: "https://www.bosnianpathology.org/teaching-cases/",
      imageDesktop: require("../../../../Assets/Images/NISPAP/links-bosnian@2x.png"),
      imageMobile: require("../../../../Assets/Images/NISPAP/links-bosnian.png"),
      imageAlt: "Bosnian Pathology",
    },
    {
      link: "https://campusvirtual.seaponline.es/",
      imageDesktop: require("../../../../Assets/Images/NISPAP/links-seap@2x.png"),
      imageMobile: require("../../../../Assets/Images/NISPAP/links-seap.png"),
      imageAlt: "SEAP-IAP",
    },
  ];

  return (
    <Wrapper>
      <Helmet>
        <title>
          Links Úteis NISPAP | Núcleo de Internos Sociedade Portuguesa de
          Anatomia Patológica - NISPAP
        </title>
        <meta
          name="description"
          content="Páginas que te poderão auxiliar no estudo. Aqui te propomos websites educativos para complementares a tua formação. Desde websites de sociedades nacionais e internacionais, webminares, cursos, casos mensais, arquivos de lâminas a páginas de redes sociais."
        />
        <meta
          name="keywords"
          content="nispap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>

      <Container>
        <PageTitle
          title="Links úteis"
          nispap={true}
          path={[
            {
              text: "NISPAP",
              link: "/nispap",
            },
            {
              text: "Utilitários",
              link: "/nispap/utilitarios",
            },
            {
              text: "Links úteis",
            },
          ]}
          imageDesktop={require("../../../../Assets/Images/NISPAP/img-estagio@2x.png")}
          imageMobile={require("../../../../Assets/Images/NISPAP/img-estagio.png")}
          sidebar={<SidebarMenu navItens={SidebarMenuLinks} nispap={true} />}
        />

        <FlexInRow gap="2.604vw">
          <FlexInColumn gap="12.396vw" mobile="column">
            <FlexInRow gap="0">
              <PageText title="Páginas que te poderão auxiliar no estudo" />
              <PageText text="Aqui te propomos websites educativos para complementares a tua formação. Desde websites de sociedades nacionais e internacionais, webminares, cursos, casos mensais, arquivos de lâminas a páginas de redes sociais. " />
              <PageText text="Clica nos links e mergulha na formação digital de Anatomia patológica." />
            </FlexInRow>
          </FlexInColumn>

          <SectionGroups>
            <FlexInRow gap="2.604vw">
              {ListCards.length > 0 && (
                <GroupCards>
                  {ListCards.map((item, index) => {
                    return (
                      <a
                        href={item.link}
                        target="_blank"
                        className={"item item-" + index}
                      >
                        <picture className="item__image">
                          <source
                            srcSet={item.imageDesktop}
                            media="(min-width: 990px)"
                          />
                          <img src={item.imageMobile} alt={item.imageAlt} />
                        </picture>
                        <h3 className="item__title">{item.title}</h3>
                      </a>
                    );
                  })}
                </GroupCards>
              )}
              {ListCards2.length > 0 && (
                <>
                  <Title>Explore casos semanais / mensais</Title>
                  <GroupCards>
                    {ListCards2.map((item, index) => {
                      return (
                        <a
                          href={item.link}
                          target="_blank"
                          className={"item item-" + index}
                        >
                          <picture className="item__image">
                            <source
                              srcSet={item.imageDesktop}
                              media="(min-width: 990px)"
                            />
                            <img src={item.imageMobile} alt={item.imageAlt} />
                          </picture>
                        </a>
                      );
                    })}
                  </GroupCards>
                </>
              )}
            </FlexInRow>
          </SectionGroups>
        </FlexInRow>
      </Container>
    </Wrapper>
  );
}
