import { Button, Row } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";

import styled from "styled-components";

const Wrapper = styled.nav`
  align-items: center;
  display: flex;
  justify-content: center;

  .logout-btn {
    font-size: 18px;
    border-radius: 10px;
    height: 40px;
    line-height: 12px;
    padding: 0 20px;
    margin-top: 20px;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  a {
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    line-height: inherit;
    text-decoration: none;
    transition: 0.3s ease;
  }

  li.selected a,
  li a:hover {
    color: #f88436;
  }

  @media (min-width: 992px) {
    li {
      display: inline-flex;
      margin: 0 1.302vw;
    }
  }

  @media (max-width: 992px) {
    background-color: ${(props) => (props.nispap ? "#225E79" : "#0C1151")};
    justify-content: flex-start;
    left: 0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    transform: ${(props) =>
      props.openMenu ? "translateX(0)" : "translateX(200%)"};
    transition: 0.3s ease;
    padding: 90px 20px 30px;
    align-items: flex-start;

    ul {
      margin: 0 auto;
      height: 100%;
      overflow-y: scroll;
    }

    li {
      margin: 4.444vw 0;
      text-align: center;
    }

    a {
      font-size: 20px;
      line-height: 28px;
    }
  }
`;

export default function Navigation(props) {
  const location = useLocation();

  const NavItems = [
    {
      title: "SPAP",
      link: "/spap/quem-somos",
    },
    {
      title: "Sócios",
      link: "/socios/area-de-socio",
    },
    {
      title: "Ferramentas Úteis",
      link: "/ferramentas-uteis/pedir-uma-segunda-opiniao",
    },
    {
      title: "Agenda",
      link: "/agenda",
    },
    {
      title: "Núcleo de Internos",
      link: "/nispap",
    },
  ];

  return (
    <Wrapper
      nispap={location.pathname.split("/")[1] === "nispap" ? true : false}
      openMenu={props.openMenu}
    >
      <ul>
        {NavItems.map((item, index) => {
          return (
            <li
              key={"navigation" + index}
              className={
                "item-" +
                (index + 1) +
                (item.link.split("/")[1] === location.pathname.split("/")[1]
                  ? " selected"
                  : "")
              }
            >
              <Link to={item.link}>{item.title}</Link>
              {item.link.split("/")[1] === location.pathname.split("/")[1] &&
                props.children}
            </li>
          );
        })}

        {localStorage.getItem("token") != null && (
          <Row justify={"center"} className="d-mobile">
            <Link to="/logout/">
              <Button className="logout-btn">Terminar sessão</Button>
            </Link>
          </Row>
        )}
      </ul>
    </Wrapper>
  );
}
