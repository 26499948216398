import { Button } from "antd";
import styled from "styled-components";
import image404 from "../../Assets/Images/img-error-404.png";
import { Link, useLocation } from "react-router-dom";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #333;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 125px 0;

  img {
    margin-top: 100px;
    width: 60%;
  }

  h4 {
    color: #8688a8;
    margin-top: 4%;
    font-family: "Helvetica Neue, Bold";
  }

  p {
    color: #0c1151;
    font-family: "Helvetica Neue, Medium";
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    line-height: 1.4;
  }

  .button-voltar {
    background: #f88436;
    color: #fff;
    border-radius: 10px;
    padding: 10px 60px;
    line-height: 24px;
    display: block;
    margin: auto auto;
    border: none;
  }

  .button-voltar:hover {
    background: #d8722d;
    color: #fff;
  }

  .ant-btn {
    height: auto;
  }

  .link-btn {
    text-decoration: none;
  }
`;

export default function Blank(props) {
  return (
    <Wrapper>
      <div className="div-404">
        {" "}
        <img src={image404} />
        <h4>Erro! Página não encontrada.</h4>
        <p>
          Desculpe, mas esta página não foi encontrada. <br />
          Por favor, volte para o início.
        </p>
        <Link to="/" className="link-btn">
          <Button className="button-voltar">Ir para a página principal</Button>
        </Link>
      </div>
    </Wrapper>
  );
}
